import React, { useEffect, useState, useMemo } from 'react';
import { Box } from '@mui/material';
import {
	Outlet,
	useOutletContext,
	useNavigate,
	useLocation,
} from 'react-router-dom';
import { Cell, Grid } from 'styled-css-grid';
import { useQuery } from 'urql';
import { isArray } from 'lodash';
import NavBar from '../components/features/navBar/NavBar';
import { defaultProjectToken } from '../components/features/navBar/SelectedMenu';
import { useAuth0 } from '@auth0/auth0-react';
import { useUserContext } from '../providers/UserProvider';
import { Project } from '../types/types';
import LeftMenu from 'components/features/navBar/leftMenu';
import useNumberParams from 'hooks/useNumberParams';
import PausedProvider from 'providers/PausedProvider';
import { getProjectIds } from 'utility/projects';
import User from 'types/user';
import { USER_TYPES } from 'constants/userTypes';
import { projectsNoRelationsQuery } from '../api/query/project.queries';

const shouldRedirectToProject = (pathname: string, search: string) => {
	// any utility pages
	if (
		pathname.endsWith('/users') ||
		pathname.endsWith('/projects-admin') ||
		pathname.endsWith('/profile')
	)
		return false;
	// View or edit comment
	return !(pathname.includes('/comments') && search.includes('commentId'));
};

export const Layout = () => {
	const [projects, setProjects] = useState<Array<Project>>([]);

	const navigate = useNavigate();
	const location = useLocation();
	const { isAuthenticated, logout } = useAuth0();
	const { user } = useUserContext();

	const projectIds = useMemo(
		() => getProjectIds(user as User, projects),
		[projects, user],
	);

	const { projectId = projectIds[0] } = useNumberParams();

	const [activeProject, setActiveProject] = useState(projectId || 0);

	const [{ data, fetching }] = useQuery({
		query: projectsNoRelationsQuery(`
		id
		customUrl
		name
		slug
		customUrlEnabledDate
		disabledDate
		archivedDate
		`),
	});

	useEffect(() => {
		if (
			activeProject &&
			fetching &&
			shouldRedirectToProject(location.pathname, location.search)
		) {
			const path = location.pathname.split(`/projects/${activeProject}`)[1];
			const search = location.search;
			navigate(`/projects/${activeProject}${path || ''}${search || ''}`, {
				replace: true,
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [activeProject, fetching]);

	useEffect(() => {
		if (data) {
			setProjects(data.projectsNoRelations);
		}
	}, [data]);

	useEffect(() => {
		if (projectIds && projectIds.length > 0 && !activeProject) {
			const defaultProjectId =
				localStorage.getItem(defaultProjectToken) || projectIds[0];
			setActiveProject(Number(defaultProjectId) || projectIds[0]);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [projectIds]);

	useEffect(() => {
		// Check current user is client and current site is disabled
		if (
			activeProject &&
			projects.find(
				(project) =>
					project.id === activeProject &&
					project.disabledDate &&
					user?.projectDetails?.map((x) => x.projectId).includes(project.id),
			) &&
			user?.userType === USER_TYPES.CLIENT
		) {
			// If there are at least one not disabled project - set is as active
			if (projects.find((project) => !project.disabledDate)) {
				const notDisabledProject = projects.findIndex(
					(project) =>
						!project.disabledDate &&
						user?.projectDetails?.map((x) => x.projectId).includes(project.id),
				);

				setActiveProject(projects[notDisabledProject].id);
			} else {
				// If not - logout client
				logout({
					returnTo: window.location.origin,
				});
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [activeProject, projects, user]);

	return (
		<PausedProvider>
			<Grid
				columns={'1fr'}
				rows={'auto 1fr auto'}
				style={{ height: '100%' }}
				gap="0"
			>
				<Cell>
					<NavBar activeProject={activeProject} />
				</Cell>
				<Cell
					style={{ overflowY: 'hidden', display: 'flex', position: 'relative' }}
				>
					{isAuthenticated && (
						<LeftMenu
							activeProject={activeProject}
							setActiveProject={setActiveProject}
							projects={isArray(projects) && projects.length ? projects : []}
							userProjects={
								isArray(projects) && projects.length
									? projects?.filter((x: Project) => projectIds.includes(x.id))
									: []
							}
						/>
					)}
					<Box flex={1} height="100%" sx={{ overflowY: 'auto' }}>
						<Outlet
							context={{
								setActiveProject,
								activeProjectDetails: projects.find(
									(project) => project.id === activeProject,
								),
							}}
						/>
					</Box>
				</Cell>
			</Grid>
		</PausedProvider>
	);
};

export type RouteContextType = {
	setActiveProject: (value: React.SetStateAction<number>) => void;
	activeProjectDetails: Project;
};

export function useRouteContext() {
	return useOutletContext<RouteContextType>();
}
