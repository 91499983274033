import {
	LinearProgress as MuiLinearProgress,
	linearProgressClasses,
	styled,
} from '@mui/material';

export const LinearProgress = styled(MuiLinearProgress)(({ theme }) => ({
	height: 20,
	borderRadius: 10,
	[`&.${linearProgressClasses.colorPrimary}`]: {
		backgroundColor: theme.customPalette.primaryColors.sky[4],
	},
	[`& .${linearProgressClasses.bar}`]: {
		borderRadius: 10,
		backgroundColor: theme.customPalette.primaryColors.sky[1],
	},
}));
