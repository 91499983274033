import { FC, useState } from 'react';
import { findIndex } from 'lodash';
import {
	Alert,
	Box,
	FormControl,
	FormControlLabel,
	FormHelperText,
	InputLabel,
	MenuItem,
	Radio,
	RadioGroup,
	Snackbar,
	Switch,
	Typography,
} from '@mui/material';
import { Cell, Grid } from 'styled-css-grid';
import { Field, Formik } from 'formik';
import * as yup from 'yup';
import { useNavigate } from 'react-router-dom';
import { useMutation, useQuery } from 'urql';
import { User } from 'types/types';
import { PROJECT_TYPE_LIST, PROJECT_TYPES } from 'constants/projectTypes';
import { createProjectMutation } from 'api/mutation/project.mutation';
import { usersByUserTypesQuery } from 'api/query/user.queries';
import Loading from 'components/common/Loading';
import Dialog from 'components/common/Dialog';
import { ProjectAddress } from 'components/features/project/ProjectAddress';
import { TitleCaseHyphenatedWord } from '../utility/titleCaseHyphenatedWord';
import OutlinedButton from 'components/common/styled/OutlinedButton';
import RegularButton from 'components/common/styled/RegularButton';
import RegularTextInput from 'components/common/styled/RegularTextInput';
import SelectInput from 'components/common/styled/SelectInput';
import { ProjectPhaseType } from 'types/projectPhase.type';
import Autocomplete from '@mui/material/Autocomplete';
import useNumberParams from 'hooks/useNumberParams';

interface ProjectCreateProps {}

type PrimaryUserType = 'existing' | 'new';

type NewProjectInput = {
	name: string;
	type: string;
	address1: string;
	city: string;
	state: string;
	country: string;
	postalCode: string;
	projectPhase: ProjectPhaseType;
	primaryUserType: PrimaryUserType;
	existingUser: string;
	newFirstName: string;
	newLastName: string;
	newEmail: string;
};

const newProjectInputSchema = yup.object({
	name: yup
		.string()
		.required('Project Name is required')
		.min(3, 'Must be at least 3 characters')
		.max(75, 'Cannot be over 75 characters'),
	type: yup.string().required('Project Type is required'),
	address1: yup.string().required('Location is required'),
	state: yup.string().required('State is required'),
	country: yup.string().required('Country is required'),
	postalCode: yup.string().required('Postal Code is required'),
	projectPhase: yup.string().required('Project Phase Code is required'),
	existingUser: yup.string().when('primaryUserType', {
		is: (primaryUserType: PrimaryUserType) => primaryUserType === 'existing',
		then: (schema) => schema.required('Primary User is required'),
	}),
	newFirstName: yup.string().when('primaryUserType', {
		is: (primaryUserType: PrimaryUserType) => primaryUserType === 'new',
		then: (schema) => schema.required('First Name is required'),
	}),
	newLastName: yup.string().when('primaryUserType', {
		is: (primaryUserType: PrimaryUserType) => primaryUserType === 'new',
		then: (schema) => schema.required('Last Name is required'),
	}),
	newEmail: yup.string().when('primaryUserType', {
		is: (primaryUserType: PrimaryUserType) => primaryUserType === 'new',
		then: (schema) =>
			schema.email('Invalid email address').required('Email is required'),
	}),
});

export const ProjectCreate: FC<ProjectCreateProps> = () => {
	const navigate = useNavigate();
	const { projectId } = useNumberParams();

	const [openDialogConfirm, setOpenDialogConfirm] = useState(false);
	const [snackbarOpen, setSnackbarOpen] = useState(false);
	const [saveError, setSaveError] = useState(false);

	const [{ data: userData, fetching: userFetching }] = useQuery<{
		usersByUserTypes: User[];
	}>({
		query: usersByUserTypesQuery,
		variables: { userTypes: ['client', 'internal'] },
	});

	const [, createProject] = useMutation(createProjectMutation('id'));

	const handleSnackbarClose = (
		event?: React.SyntheticEvent | Event,
		reason?: string,
	) => {
		if (reason === 'clickaway') {
			return;
		}
		setSnackbarOpen(false);
	};

	const onSubmit = async (
		data: NewProjectInput,
		{ setSubmitting }: { setSubmitting: (state: boolean) => void },
	) => {
		let primaryUserData = {
			firstName: data.newFirstName,
			lastName: data.newLastName,
			email: data.newEmail,
		};

		if (data.primaryUserType === 'existing' && userData) {
			const userId = Number(data.existingUser);
			const userIdx = findIndex(
				userData.usersByUserTypes,
				(item) => item.id === userId,
			);
			if (userIdx > -1) {
				primaryUserData.firstName =
					userData.usersByUserTypes[userIdx].firstName;
				primaryUserData.lastName = userData.usersByUserTypes[userIdx].lastName;
				primaryUserData.email = userData.usersByUserTypes[userIdx].email;
			}
		}

		const result = await createProject({
			newProjectData: {
				name: data.name,
				type: data.type,
				address1: data.address1,
				city: data.city,
				state: data.state,
				country: data.country,
				postalCode: data.postalCode,
				projectPhase: data.projectPhase,
			},
			primaryUserData,
		});

		if (result.error) {
			console.error('Error creating new project', result.error);
			setSaveError(true);
		} else {
			setSaveError(false);
		}

		setSnackbarOpen(true);
		setSubmitting(false);
		navigate(`/projects/${projectId}/projects-admin`);
	};

	if (userFetching) {
		return <Loading />;
	}

	return (
		<Box px={5} py={2}>
			<Formik
				initialValues={{
					name: '',
					type: PROJECT_TYPES.CRE,
					address1: '',
					state: '',
					country: 'US',
					city: '',
					postalCode: '',
					projectPhase: 'community-review' as ProjectPhaseType,
					primaryUserType: 'existing',
					existingUser: '',
					newFirstName: '',
					newLastName: '',
					newEmail: '',
				}}
				validationSchema={newProjectInputSchema}
				onSubmit={onSubmit}
			>
				{({
					errors,
					touched,
					values,
					handleChange,
					handleBlur,
					handleSubmit,
					setFieldValue,
				}) => (
					<form onSubmit={handleSubmit}>
						<Box
							display="flex"
							justifyContent="space-between"
							alignItems="center"
						>
							<h2>Add Project</h2>
							<Box>
								<OutlinedButton
									sx={{ mr: 2, px: 5 }}
									variant="outlined"
									onClick={() => setOpenDialogConfirm(true)}
								>
									Cancel
								</OutlinedButton>
								<RegularButton sx={{ px: 5 }} variant="contained" type="submit">
									Create
								</RegularButton>
							</Box>
						</Box>

						<Grid columns={2}>
							<Cell>
								<InputLabel htmlFor="name">Project Name</InputLabel>
								<Field
									id="name"
									name="name"
									as={RegularTextInput}
									variant="outlined"
									type="text"
									size="small"
									fullWidth
									error={Boolean(touched.name && errors.name)}
									helperText={touched.name ? errors.name : ''}
									onBlur={(e: { currentTarget: { value: any } }) => {
										e.currentTarget.value = e.currentTarget.value.trim();
										handleChange(e);
										handleBlur(e);
									}}
									onChange={(e: { currentTarget: { value: any } }) => {
										handleChange(e);
									}}
								/>
								<InputLabel sx={{ mt: 1 }} htmlFor="name">
									Project Type
								</InputLabel>
								<FormControl
									fullWidth
									size="small"
									error={touched.type && Boolean(errors.type)}
								>
									<SelectInput
										id="type"
										name="type"
										onChange={handleChange}
										onBlur={handleBlur}
										value={values.type}
									>
										{PROJECT_TYPE_LIST.map((type) => (
											<MenuItem key={type} value={type}>
												{type === PROJECT_TYPES.CRE
													? type.toUpperCase()
													: TitleCaseHyphenatedWord.transform(type)}
											</MenuItem>
										))}
									</SelectInput>
									<FormHelperText>
										{errors.type && touched.type && errors.type}
									</FormHelperText>
								</FormControl>

								<Typography
									sx={{ mt: 2 }}
									variant="subtitle1"
									gutterBottom
									component="div"
								>
									Project Location
								</Typography>

								<Grid columns={2}>
									<ProjectAddress
										values={{
											name: values.name,
											address1: values.address1,
											city: values.city,
											state: values.state,
											country: values.country,
											postalCode: values.postalCode,
											projectPhase: values.projectPhase,
										}}
										touched={touched}
										errors={errors}
										handleChange={handleChange}
										handleBlur={handleBlur}
									/>
								</Grid>
							</Cell>
							<Cell>
								<Typography variant="subtitle1" gutterBottom component="div">
									Primary User
								</Typography>
								<FormControl>
									<RadioGroup
										id="primaryUserType"
										name="primaryUserType"
										row
										value={values.primaryUserType}
										onChange={handleChange}
									>
										<FormControlLabel
											value="existing"
											control={<Radio />}
											label="User in system"
										/>
										<FormControlLabel
											value="new"
											control={<Radio />}
											label="New user"
										/>
									</RadioGroup>
								</FormControl>
								{values.primaryUserType === 'existing' ? (
									<FormControl
										fullWidth
										size="small"
										error={touched.existingUser && Boolean(errors.existingUser)}
									>
										<Autocomplete
											disablePortal
											id="existingUser"
											isOptionEqualToValue={(option, value) =>
												option.id === value.id
											}
											options={userData!.usersByUserTypes}
											getOptionLabel={(option) =>
												`${option.fullName} (${option.email})` || ''
											}
											renderOption={(props, option: any) => (
												<Box component="li" {...props} key={option.id}>
													{`${option.fullName} (${option.email})`}
												</Box>
											)}
											onChange={(e, value) => {
												setFieldValue(
													'existingUser',
													value !== null ? value.id : '',
												);
											}}
											sx={{ maxWidth: 450 }}
											renderInput={(params) => (
												<RegularTextInput
													{...params}
													size="small"
													label="Select User"
													error={
														touched.existingUser && Boolean(errors.existingUser)
													}
												/>
											)}
										/>
										<FormHelperText>
											{errors.existingUser &&
												touched.existingUser &&
												errors.existingUser}
										</FormHelperText>
									</FormControl>
								) : (
									<Grid columns={3}>
										<Cell width={1}>
											<InputLabel htmlFor="newFirstName">First Name</InputLabel>
											<Field
												id="newFirstName"
												name="newFirstName"
												value={values.newFirstName}
												error={Boolean(
													touched.newFirstName && errors.newFirstName,
												)}
												helperText={
													touched.newFirstName ? errors.newFirstName : ''
												}
												as={RegularTextInput}
												variant="outlined"
												size="small"
												fullWidth
											/>
										</Cell>
										<Cell width={1}>
											<InputLabel htmlFor="newLastName">Last Name</InputLabel>
											<Field
												id="newLastName"
												name="newLastName"
												as={RegularTextInput}
												variant="outlined"
												type="text"
												value={values.newLastName}
												error={Boolean(
													touched.newLastName && errors.newLastName,
												)}
												helperText={
													touched.newLastName ? errors.newLastName : ''
												}
												size="small"
												fullWidth
											/>
										</Cell>
										<Cell width={1}>
											<InputLabel htmlFor="newEmail">Email</InputLabel>
											<Field
												id="newEmail"
												name="newEmail"
												as={RegularTextInput}
												value={values.newEmail}
												error={Boolean(touched.newEmail && errors.newEmail)}
												helperText={touched.newEmail ? errors.newEmail : ''}
												variant="outlined"
												type="text"
												size="small"
												fullWidth
											/>
										</Cell>
									</Grid>
								)}

								<Typography
									sx={{ mt: 2 }}
									variant="subtitle1"
									gutterBottom
									component="div"
								>
									Project Features
								</Typography>

								<Box>
									<FormControlLabel
										control={<Switch defaultChecked />}
										label="Feedback Section"
									/>
								</Box>

								<FormControlLabel
									control={<Switch defaultChecked />}
									label="Following Enabled"
								/>
							</Cell>
						</Grid>
					</form>
				)}
			</Formik>
			<Snackbar
				autoHideDuration={3000}
				open={snackbarOpen}
				onClose={handleSnackbarClose}
			>
				<Alert
					onClose={handleSnackbarClose}
					severity={saveError ? 'error' : 'success'}
				>
					{saveError ? 'Project could not be created' : 'Created successfully'}
				</Alert>
			</Snackbar>

			<Dialog
				title="Exit?"
				open={openDialogConfirm}
				handleLeftButton={() => setOpenDialogConfirm(false)}
				handleRightButton={() =>
					navigate(`/projects/${projectId}/projects-admin`)
				}
				leftButtonLabel="Cancel"
				rightButtonLabel="Confirm"
			>
				Are you sure you want to exit?
			</Dialog>
		</Box>
	);
};
