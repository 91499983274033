import client from 'apisauce';

const graphqlConn = async (getToken: any, query: any, variables: object) => {
	const token = await getToken();

	const api = client.create({
		baseURL: process.env.REACT_APP_GRAPH_URI!,
		headers: {
			'Content-Type': 'application/json',
			Authorization: 'Bearer ' + token,
		},
	});

	const res = await api.post('', { query, variables });

	if (res.ok) return res.data as any;

	console.log(res.problem);
	return null;
};

export default graphqlConn;
