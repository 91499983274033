import {
	useState,
	useCallback,
	useRef,
	useMemo,
	createContext,
	ReactNode,
} from 'react';

import ActionConfirmationDialog from 'components/common/ActionConfirmationDialog';
import { ConfirmationDialogActions } from '../types/customTypes';

type UseActionDialogShow = {
	show: boolean;
	onShow: () => void;
	onHide: () => void;
};

const useActionDialogShow = (): UseActionDialogShow => {
	const [show, setShow] = useState(false);

	const handleOnShow = useCallback(() => {
		setShow(true);
	}, []);

	const handleOnHide = useCallback(() => {
		setShow(false);
	}, []);

	return {
		show,
		onShow: handleOnShow,
		onHide: handleOnHide,
	};
};

type ActionConfirmationContextType = {
	showConfirmationDialog: (
		content: ActionConfirmationDialogContent,
		action: ConfirmationDialogActions,
	) => Promise<boolean>;
};

type ActionConfirmationContextProviderProps = {
	children: React.ReactNode;
};

type ActionConfirmationDialogContent = {
	title?: string;
	message?: string | ReactNode;
	confirmLabel?: string;
	cancelLabel?: string;
};

const ActionConfirmationContext = createContext<ActionConfirmationContextType>(
	{} as ActionConfirmationContextType,
);

const ActionConfirmationContextProvider: React.FC<
	ActionConfirmationContextProviderProps
> = (props) => {
	const { show, onShow, onHide } = useActionDialogShow();
	const [content, setContent] = useState<ActionConfirmationDialogContent>();
	const [action, setAction] = useState<ConfirmationDialogActions>('save');
	const resolver = useRef<Function>();

	const handleShow = useCallback(
		(
			content: ActionConfirmationDialogContent,
			action: ConfirmationDialogActions,
		): Promise<boolean> => {
			setContent(content);
			setAction(action);
			onShow();

			return new Promise((resolve) => {
				resolver.current = resolve;
			});
		},
		[onShow],
	);

	const handleOk = useCallback(() => {
		resolver.current && resolver.current(true);
		onHide();
	}, [onHide]);

	const handleCancel = useCallback(() => {
		resolver.current && resolver.current(false);
		onHide();
	}, [onHide]);

	const actionConfirmationContext = useMemo<ActionConfirmationContextType>(
		() => ({
			showConfirmationDialog: handleShow,
		}),
		[handleShow],
	);

	const getConfirmLabel = () => {
		if (content?.confirmLabel) return content.confirmLabel;

		let label = '';

		switch (action) {
			case 'save':
				label = 'Save';
				break;
			case 'delete':
				label = 'Delete';
				break;
			case 'abandon':
				label = 'Abandon Changes';
				break;
			case 'select':
				label = 'Replace';
				break;
			case 'notify':
				label = 'Ok';
				break;
			case 'unPublish':
				label = 'Un-publish';
				break;
			default:
				break;
		}

		return label;
	};

	const getCancelLabel = () => {
		if (content?.cancelLabel) return content.cancelLabel;

		let label = '';

		switch (action) {
			case 'save':
				label = 'Cancel';
				break;
			case 'delete':
				label = 'Cancel';
				break;
			case 'select':
				label = 'Cancel';
				break;
			case 'abandon':
				label = 'Go Back';
				break;
			case 'notify':
				label = '';
				break;
			case 'unPublish':
				label = 'Cancel';
				break;
			default:
				break;
		}

		return label;
	};

	return (
		<ActionConfirmationContext.Provider value={actionConfirmationContext}>
			{props.children}
			<ActionConfirmationDialog
				action={action}
				open={show}
				title={content?.title}
				confirmLabel={getConfirmLabel()}
				cancelLabel={getCancelLabel()}
				onConfirm={handleOk}
				onCancel={handleCancel}
			>
				{content?.message}
			</ActionConfirmationDialog>
		</ActionConfirmationContext.Provider>
	);
};

export { ActionConfirmationContext };

export default ActionConfirmationContextProvider;
