import { useEffect, useState } from 'react';
import { useMutation, useQuery } from 'urql';
import { useSearchParams } from 'react-router-dom';
import { filteredUsers } from 'api/query/user.queries';
import Loading from '../../common/Loading';
import { ProjectUserManagerLayout } from './AdminUserManagerLayout';
import {
	passwordReset as passwordResetMutation,
	updateUserMutation,
} from 'api/mutation/users.mutation';
import { Alert, Snackbar } from '@mui/material';
import { useSnackBar } from '../../../providers/SnackBarProvider';
import { User } from 'types/types';
import useDebounce from 'hooks/useDebounce';

export const AdminUserManagerContainer = () => {
	const [params] = useSearchParams();

	const isEditMode = params.get('edit');

	const [snackbarOpen, setSnackbarOpen] = useState(false);
	const [saveError] = useState(false);
	const [userStatus, setUserStatus] = useState(() =>
		isEditMode ? 'all' : 'active',
	);
	const [userType, setUserType] = useState(() =>
		isEditMode ? 'all' : 'client',
	);
	const [searchTerm, setSearchTerm] = useState('');
	const [currentPage, setCurrentPage] = useState(0);
	const [recordsPerPage, setRecordsPerPage] = useState(25);
	const [orderBy, setOrderBy] = useState('');
	const [order, setOrder] = useState('');
	const [isLoading, setIsLoading] = useState(false);

	const [, updateUserData] = useMutation(updateUserMutation);
	const [, resetPasswordLink] = useMutation(passwordResetMutation);

	const { notify } = useSnackBar();

	const [{ data }, reexecuteQuery] = useQuery({
		query: filteredUsers,
		variables: {
			userStatus,
			userType,
			searchTerm: searchTerm,
			page: currentPage,
			recordsPerPage,
			order,
			orderBy,
		},
		pause: true,
	});

	const handleSnackbarClose = (
		event?: React.SyntheticEvent | Event,
		reason?: string,
	) => {
		if (reason === 'clickaway') {
			return;
		}

		setSnackbarOpen(false);
	};

	const debouncedSearch = useDebounce(() => {
		refresh();
	}, 100);

	useEffect(() => {
		setIsLoading(true);
		debouncedSearch();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		userType,
		userStatus,
		searchTerm,
		currentPage,
		recordsPerPage,
		order,
		orderBy,
	]);

	useEffect(() => {
		setCurrentPage(0);
		debouncedSearch();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [order, orderBy]);

	useEffect(() => {
		if (data) setIsLoading(false);
	}, [data]);

	const refresh = () => {
		reexecuteQuery({ requestPolicy: 'network-only' });
	};

	const handleInviteClick = async (
		UserType: string,
		UserStatus: string,
		EmailAddressOrName: string,
	) => {
		setCurrentPage(0);
		setUserType(UserType);
		setUserStatus(UserStatus);
		setSearchTerm(EmailAddressOrName);
	};

	const doPasswordReset = async (email: any) => {
		try {
			const res = await resetPasswordLink({ email });
			if (res.data) notify('Password Reset send successfully');
			else notify('Something went wrong! Could not send email to user');
		} catch (e) {
			notify('Something went wrong! Could not update user');
		}
	};

	const updateUser = async (user: User) => {
		try {
			if (user) {
				await updateUserData({
					updateUserData: {
						id: user.id,
						firstName: user.firstName,
						lastName: user.lastName,
						email: user.email,
						userType: user.userType,
						isActive: user.isActive,
						isDeleted: user.isDeleted,
					},
				});

				refresh();
				notify('User Updated successfully');

				// checking if there are no users in the current page after user update
				const noOfPossiblePages = Math.ceil(
					data?.filteredUsers?.length / recordsPerPage,
				);
				if (currentPage + 1 <= noOfPossiblePages) return;

				// on the contrary we want to go back to the previous page
				if (currentPage > 0) setCurrentPage(currentPage - 1);
			}
		} catch (e) {
			notify('Something went wrong! Could not update user');
		}
	};

	const users = data?.filteredUsers;
	if (!users) return <Loading />;

	return (
		<>
			<ProjectUserManagerLayout
				currentPage={currentPage}
				setCurrentPage={setCurrentPage}
				onUserUpdate={updateUser}
				total={users.length > 0 ? users[0].total : 0}
				onInviteClick={handleInviteClick}
				users={users}
				recordsPerPage={recordsPerPage}
				setRecordsPerPage={(records) => setRecordsPerPage(records)}
				onResetPassword={(email) => doPasswordReset(email)}
				orderStates={{ order, orderBy, setOrder, setOrderBy }}
				isLoading={isLoading}
			/>
			<Snackbar
				autoHideDuration={3000}
				open={snackbarOpen}
				onClose={handleSnackbarClose}
			>
				<Alert
					onClose={handleSnackbarClose}
					severity={saveError ? 'error' : 'success'}
				>
					{saveError
						? 'Project user could not be invited'
						: 'Project user invited'}
				</Alert>
			</Snackbar>
		</>
	);
};
