import {
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	IconButton,
	SxProps,
	Theme,
	Typography,
	Button,
	Stack,
} from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import React, { FC } from 'react';
import { ConfirmationDialogActions } from '../../types/customTypes';

const titlePadding = '60px 96px 18px 96px';
const contentPadding = '0 108px 20px 108px';
const actionsPadding = '34px 8px 64px 8px';

interface ActionConfirmationDialogProps {
	title?: string;
	open: boolean;
	action?: ConfirmationDialogActions;
	onConfirm: () => void;
	onCancel: () => void;
	cancelLabel?: string;
	confirmLabel?: string;
	disableBackdropClick?: boolean;
	style?: SxProps<Theme>;
	children?: React.ReactNode;
}

const defaultProps: Partial<ActionConfirmationDialogProps> = {
	open: false,
	action: 'save',
	cancelLabel: 'Cancel',
	confirmLabel: 'Save',
	title: 'Confirm Action',
	disableBackdropClick: true,
	style: {},
};

const ActionConfirmationDialog: FC<ActionConfirmationDialogProps> = (props) => {
	const {
		title,
		open,
		action,
		onConfirm,
		onCancel,
		cancelLabel,
		confirmLabel,
		disableBackdropClick,
		style,
	} = props;

	const handleClose = (e: React.MouseEvent, reason: string) => {
		if (disableBackdropClick && reason && reason === 'backdropClick') {
			return;
		}
		onCancel();
		e.stopPropagation();
	};

	const handleConfirm = (e: React.MouseEvent) => {
		onConfirm();
		e.stopPropagation();
	};

	return (
		<Dialog
			open={open}
			onClose={handleClose}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
			maxWidth="xl"
			PaperProps={{
				sx: { borderRadius: 6, ...style },
			}}
		>
			<IconButton
				sx={{
					position: 'absolute',
					top: '22px',
					right: '22px',
					width: '24px',
					height: '24px',
				}}
				onClick={onCancel}
			>
				<CloseIcon />
			</IconButton>
			<DialogTitle
				id="alert-dialog-title"
				sx={{
					fontWeight: 'bold',
					padding: titlePadding,
				}}
			>
				<Typography variant="h5" fontWeight={800} align="center">
					{title}
				</Typography>
			</DialogTitle>
			<DialogContent
				sx={{
					padding: contentPadding,
					opacity: 0.74,
					textAlign: 'center',
				}}
			>
				{props.children}
			</DialogContent>
			<DialogActions
				sx={{
					padding: actionsPadding,
				}}
			>
				<Stack
					direction="row"
					justifyContent="center"
					alignItems="center"
					spacing={2.5}
					sx={{ width: '100%', minWidth: 520 }}
				>
					{cancelLabel && (
						<Button
							sx={(theme) => ({
								fontWeight: 500,
								fontSize: '1rem',
								textTransform: 'capitalize',
								padding: `7px ${theme.spacing(2)}`,
								minWidth: '150px',
								borderWidth: '2px',
								borderColor: theme.customPalette.primaryColors.sky[1],
								'&:hover': {
									borderWidth: '2px',
									borderColor: theme.customPalette.primaryColors.sky[2],
									color: theme.customPalette.primaryColors.sky[2],
								},
								color: theme.customPalette.primaryColors.sky[1],
							})}
							variant="outlined"
							onClick={(e) => handleClose(e, '')}
							autoFocus
						>
							{cancelLabel}
						</Button>
					)}
					<Button
						sx={(theme) => ({
							fontWeight: 500,
							fontSize: '1rem',
							color: theme.customPalette.basicColors.light,
							textTransform: 'capitalize',
							padding: `7px ${theme.spacing(2)}`,
							minWidth: '150px',
							backgroundColor:
								action === 'save'
									? theme.customPalette.primaryColors.sky[1]
									: theme.customPalette.systemColors.error.main,
							'&:hover':
								action === 'save'
									? {
											backgroundColor: theme.customPalette.primaryColors.sky[2],
									  }
									: {},
						})}
						color={action === 'save' ? 'primary' : 'error'}
						variant="contained"
						onClick={handleConfirm}
					>
						{confirmLabel}
					</Button>
				</Stack>
			</DialogActions>
		</Dialog>
	);
};

ActionConfirmationDialog.defaultProps = defaultProps;

export default ActionConfirmationDialog;
