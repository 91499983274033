import React, { useState } from 'react';
import {
	Autocomplete,
	AutocompleteChangeReason,
	InputLabel,
} from '@mui/material';
import usePlacesAutocomplete from 'use-places-autocomplete';
import RegularTextInput from 'components/common/styled/RegularTextInput';

interface PlacesAutoCompleteProps {
	fullWidth: boolean;
	dropdownLabel?: string;
	disabled?: boolean;
	onLocationInputChange: (
		event: React.SyntheticEvent,
		value: string,
		reason: string,
	) => void;
	onSelectedLocation: (
		event: React.SyntheticEvent,
		value: PlaceOption | null,
		reason: AutocompleteChangeReason,
	) => void;
	inputAddress: string;
	setInputAddress: (val: string) => void;
}

export interface PlaceOption {
	key: string;
	value: string;
}

export const PlacesAutoComplete: React.FC<PlacesAutoCompleteProps> = ({
	onLocationInputChange,
	onSelectedLocation,
	disabled = false,
	dropdownLabel = 'Address',
	inputAddress,
	setInputAddress,
}) => {
	const {
		suggestions: { data },
		setValue,
	} = usePlacesAutocomplete({
		requestOptions: { componentRestrictions: { country: 'us' } },
		debounce: 300,
	});

	const [addressesList, setAddressesList] = useState<PlaceOption[]>([]);

	const changeOptionBaseOnValue = (value: string) => {
		setValue(value);
		setInputAddress(value);
		const suggestions = data.map((x) => {
			return { key: x.place_id, value: x.description };
		});
		setAddressesList(suggestions);
	};

	const onSelectionChange: PlacesAutoCompleteProps['onSelectedLocation'] = (
		event,
		value,
		reason,
	) => {
		if (value?.value) {
			setInputAddress(value?.value);
			onSelectedLocation(event, value, reason);
		}
	};

	return (
		<>
			<Autocomplete
				autoHighlight
				renderInput={(params) => {
					return (
						<form noValidate>
							<InputLabel
								style={{ marginTop: '10px' }}
								id="map-autocomplete-label"
							>
								{dropdownLabel}
							</InputLabel>
							<RegularTextInput {...params} autoComplete="off" size="small" />
						</form>
					);
				}}
				onInputChange={(
					event: React.SyntheticEvent,
					value: string,
					reason: string,
				) => {
					if (reason === 'input') {
						changeOptionBaseOnValue(value);
					}
					onLocationInputChange(event, value, reason);
				}}
				disabled={disabled}
				inputValue={inputAddress}
				onChange={onSelectionChange}
				getOptionLabel={(option) => option.value}
				options={addressesList}
			/>
		</>
	);
};
