import { TextField, TextFieldProps, styled } from '@mui/material';

const RegularTextInput = styled(TextField)<TextFieldProps>(({ theme }) => ({
	'& .MuiOutlinedInput-root': {
		borderRadius: '8px',
		color: theme.customPalette.systemColors.unchecked,
		borderColor: `${theme.customPalette.borderColors.dark}`,
		fontSize: '16px',
		width: 'auto',
		'&:focus': {
			borderColor: theme.customPalette.basicColors.light,
		},
		'&.Mui-error': {
			color: theme.customPalette.systemColors.error.main,
			backgroundColor: theme.customPalette.basicColors.light,
		},
		'&.Mui-disabled': {
			color: theme.customPalette.systemColors.unchecked,
			backgroundColor: '#F0F0F0',
		},
	},
}));

export default RegularTextInput;
