import isUrl from 'is-url';

import {
	Editor,
	Transforms,
	Element as SlateElement,
	Range,
	BaseEditor,
	Node,
} from 'slate';

import { ReactEditor } from 'slate-react';
import { ListType, withLists } from '@prezly/slate-lists';

type CustomElement = { type: string; children: CustomText[]; align?: string };
type CustomText = { text: string };

type EmptyText = {
	text: string;
};

export type ImageElement = {
	type: 'image';
	url: string;
	children: EmptyText[];
};

type EditorType = BaseEditor & ReactEditor;

declare module 'slate' {
	interface CustomTypes {
		Editor: EditorType;
		Element: CustomElement;
		Text: CustomText | EmptyText;
	}
}

const obj = {};

const LIST_TYPES = ['numbered-list', 'bulleted-list'];

enum Type {
	PARAGRAPH = 'paragraph',
	ORDERED_LIST = 'numbered-list',
	UNORDERED_LIST = 'bulleted-list',
	LIST_ITEM = 'list-item',
	LIST_ITEM_TEXT = 'paragraph',
}

const TEXT_ALIGN_TYPES = ['left', 'center', 'right', 'justify'];

const toPlainText = (nodes: any) => {
	return nodes ? nodes.map((n: any) => Node.string(n)).join(' ') : '';
};

const withInlines = (editor: EditorType) => {
	const { insertData, insertText, isInline } = editor;

	editor.isInline = (element: any) =>
		['link', 'button'].includes(element.type) || isInline(element);

	editor.insertText = (text: string) => {
		if (text && isUrl(text)) {
			wrapLink(editor, text);
		} else {
			insertText(text);
		}
	};

	editor.insertData = (data: any) => {
		const text = data.getData('text/plain');

		if (text && isUrl(text)) {
			wrapLink(editor, text);
		} else {
			insertData(data);
		}
	};

	return editor;
};

const currentSize = (editor: EditorType) => {
	const marks = Editor.marks(editor);
	// TODO : check better type than ( keyof typeof obj )
	return marks && marks['fontSize' as keyof typeof obj]
		? marks['fontSize' as keyof typeof obj]
		: false;
};

const isMarkActive = (editor: EditorType, format: string) => {
	const marks = Editor.marks(editor);
	return marks ? marks[format as keyof typeof obj] === true : false;
};

const isBlockActive = (
	editor: EditorType,
	format: string,
	blockType = 'type',
) => {
	const { selection } = editor;
	if (!selection) return false;

	const [match] = Array.from(
		Editor.nodes(editor, {
			at: Editor.unhangRange(editor, selection),
			match: (n) =>
				!Editor.isEditor(n) &&
				SlateElement.isElement(n) &&
				n[blockType as keyof CustomElement] === format,
		}),
	);

	return !!match;
};

const toggleBlock = (editor: EditorType, format: string) => {
	const isActive = isBlockActive(
		editor,
		format,
		TEXT_ALIGN_TYPES.includes(format) ? 'align' : 'type',
	);
	const isList = LIST_TYPES.includes(format);

	Transforms.unwrapNodes(editor, {
		match: (n) =>
			!Editor.isEditor(n) &&
			SlateElement.isElement(n) &&
			LIST_TYPES.includes(n.type) &&
			!TEXT_ALIGN_TYPES.includes(format),
		split: true,
	});
	let newProperties: Partial<SlateElement>;
	if (TEXT_ALIGN_TYPES.includes(format)) {
		newProperties = {
			align: isActive ? undefined : format,
		};
	} else {
		newProperties = {
			type: isActive ? 'paragraph' : isList ? 'list-item' : format,
		};
	}
	Transforms.setNodes<SlateElement>(editor, newProperties);

	if (!isActive && isList) {
		const block = { type: format, children: [] };
		Transforms.wrapNodes(editor, block);
	}
};

const unwrapLink = (editor: EditorType) => {
	Transforms.unwrapNodes(editor, {
		match: (n) =>
			!Editor.isEditor(n) && SlateElement.isElement(n) && n.type === 'link',
	});
};

const wrapLink = (editor: EditorType, url: string) => {
	const { selection } = editor;
	const isCollapsed = selection && Range.isCollapsed(selection);
	const link: any = {
		type: 'link',
		url,
		children: isCollapsed ? [{ text: url }] : [],
	};

	if (isCollapsed) {
		Transforms.insertNodes(editor, link);
	} else {
		Transforms.wrapNodes(editor, link, { split: true });
		Transforms.collapse(editor, { edge: 'end' });
	}
};

const insertBreak = (editor: EditorType) => {
	const { selection } = editor;
	if (selection) {
		const [elementMatch] = Array.from(
			Editor.nodes(editor, {
				match: (n) =>
					!Editor.isEditor(n) &&
					SlateElement.isElement(n) &&
					(n.type.toLowerCase().startsWith('heading') ||
						n.type.toLowerCase().startsWith('link')),
			}),
		);

		if (elementMatch) {
			Transforms.insertNodes(editor, {
				children: [{ text: '' }],
				type: 'paragraph',
			});
			return;
		}
	}
	editor.insertSoftBreak();
};

const insertLink = (editor: EditorType, url: string) => {
	let link = '';
	if (
		url.startsWith('http') ||
		url.startsWith('mailto') ||
		url.startsWith('tel')
	) {
		link = url;
	} else {
		link = url.startsWith('/') ? url : `/${url}`;
	}
	if (editor.selection) {
		wrapLink(editor, link);
	}
};

const insertImage = (editor: EditorType, url: string) => {
	const text = { text: '' };
	const image: ImageElement = { type: 'image', url, children: [text] };
	Transforms.insertNodes(editor, image);
};

const changeSize = (editor: EditorType, size: number) => {
	Editor.addMark(editor, 'fontSize', size);
};

const toggleMark = (editor: EditorType, format: string) => {
	const isActive = isMarkActive(editor, format);

	if (isActive) {
		Editor.removeMark(editor, format);
	} else {
		Editor.addMark(editor, format, true);
	}
};

const clearFormat = (editor: EditorType) => {
	for (let format of [
		'bold',
		'italic',
		'underline',
		'left',
		'center',
		'right',
		'justify',
		'fontSize',
	]) {
		Editor.removeMark(editor, format);
	}
	//remove any block formatting
	toggleBlock(editor, '');
	//force alignment left
	toggleBlock(editor, 'left');
};

const withImages = (editor: EditorType) => {
	const { isVoid } = editor;

	editor.isVoid = (element) => {
		return element.type === 'image' ? true : isVoid(element);
	};

	return editor;
};

const withListsPlugin = withLists({
	isConvertibleToListTextNode(node: Node) {
		return SlateElement.isElementType(node, Type.PARAGRAPH);
	},
	isDefaultTextNode(node: Node) {
		return SlateElement.isElementType(node, Type.PARAGRAPH);
	},
	isListNode(node: Node, type?: ListType) {
		if (type) {
			const nodeType =
				type === ListType.ORDERED ? Type.ORDERED_LIST : Type.UNORDERED_LIST;
			return SlateElement.isElementType(node, nodeType);
		}
		return (
			SlateElement.isElementType(node, Type.ORDERED_LIST) ||
			SlateElement.isElementType(node, Type.UNORDERED_LIST)
		);
	},
	isListItemNode(node: Node) {
		return SlateElement.isElementType(node, Type.LIST_ITEM);
	},
	isListItemTextNode(node: Node) {
		return SlateElement.isElementType(node, Type.LIST_ITEM_TEXT);
	},
	createDefaultTextNode(props = {}) {
		return { children: [{ text: '' }], ...props, type: Type.PARAGRAPH };
	},
	createListNode(type: ListType = ListType.UNORDERED, props = {}) {
		const nodeType =
			type === ListType.ORDERED ? Type.ORDERED_LIST : Type.UNORDERED_LIST;
		return { children: [{ text: '' }], ...props, type: nodeType };
	},
	createListItemNode(props = {}) {
		return { children: [{ text: '' }], ...props, type: Type.LIST_ITEM };
	},
	createListItemTextNode(props = {}) {
		return { children: [{ text: '' }], ...props, type: Type.LIST_ITEM_TEXT };
	},
});

export {
	unwrapLink,
	isBlockActive,
	insertLink,
	toggleBlock,
	isMarkActive,
	toggleMark,
	withInlines,
	insertImage,
	withImages,
	toPlainText,
	changeSize,
	currentSize,
	clearFormat,
	insertBreak,
	withListsPlugin,
};
