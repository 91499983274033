import { useEffect, useState } from 'react';
import {
	Box,
	Chip,
	Container,
	Grid,
	Stack,
	TextField,
	useTheme,
} from '@mui/material';
import { Add as AddIcon, SearchOutlined } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { useQuery } from 'urql';
import ProjectList from 'components/features/projectList/ProjectList';
import Loading from '../components/common/Loading';
import { projectsNoRelationsQuery } from 'api/query/project.queries';
import { Project } from 'types/types';
import { TitleCaseHyphenatedWord } from 'utility/titleCaseHyphenatedWord';
import OutlinedButton from 'components/common/styled/OutlinedButton';
import { useRouteContext } from './Layout';
import RegularButton from '../components/common/styled/RegularButton';
import useNumberParams from 'hooks/useNumberParams';

export enum ProjectStatus {
	ACTIVE = 'Active',
	ARCHIVED = 'Archived',
	ALL = 'All',
}

export const ProjectAdministration = () => {
	const navigate = useNavigate();
	const context = useRouteContext();
	const theme = useTheme();
	const { projectId } = useNumberParams();

	const [selectedGroupStatus, setSelectedGroupStatus] = useState(
		ProjectStatus.ACTIVE,
	);
	const [projects, setProjects] = useState<Project[]>([]);
	const [filteredProjects, setFilteredProjects] = useState<Project[]>([]);
	const [searchTerm, setSearchTerm] = useState('');
	const [{ data: projectData, fetching: projectsFetching }] = useQuery<{
		projectsNoRelations: Project[];
	}>({
		query: projectsNoRelationsQuery(`id
				name
				disabled
				archivedDate
				primaryImageUrl
				`),
	});

	useEffect(() => {
		if (projectData) {
			const allProjects = projectData.projectsNoRelations.filter(
				({ name }) => !name.match(/Template \d+ Demo/gm),
			);
			if (selectedGroupStatus === ProjectStatus.ACTIVE) {
				const activeProjects = allProjects?.filter(
					(item) => !item.disabled && !item.archivedDate,
				);
				setProjects(activeProjects);
				setFilteredProjects(activeProjects);
			} else if (selectedGroupStatus === ProjectStatus.ARCHIVED) {
				const archivedProjects = allProjects?.filter(
					(project) => project.archivedDate,
				);
				setProjects(archivedProjects);
				setFilteredProjects(archivedProjects);
			} else {
				setProjects(allProjects);
				setFilteredProjects(allProjects);
			}
		}
	}, [selectedGroupStatus, projectData]);

	if (projectsFetching) return <Loading />;

	const searchAction = () => {
		if (searchTerm.trim() !== '') {
			const filteredProjects = projects.filter((p) =>
				p.name.toLowerCase().includes(searchTerm.toLowerCase()),
			);
			setFilteredProjects(filteredProjects);
		} else {
			setFilteredProjects(projects);
		}
	};

	return (
		<Container>
			<Box
				display="flex"
				justifyContent="space-between"
				alignItems="center"
				py={3}
			>
				<h2>Projects</h2>

				<OutlinedButton
					variant="outlined"
					endIcon={<AddIcon />}
					onClick={() => navigate(`/projects/${projectId}/projects/add`)}
				>
					Add Project
				</OutlinedButton>
			</Box>
			<Grid container alignItems="center" spacing={3} py={2}>
				<Grid item xs="auto">
					<Stack direction="row" justifyContent="space-between" spacing={1}>
						<Chip
							sx={{
								backgroundColor:
									selectedGroupStatus === ProjectStatus.ACTIVE
										? theme.customPalette.primaryColors.sky['1']
										: 'default',
								color:
									selectedGroupStatus === ProjectStatus.ACTIVE
										? theme.customPalette.basicColors.light
										: theme.customPalette.textColors.dark,
								'&:hover': {
									filter: 'brightness(120%)',
								},
								'&:active': {
									filter: 'brightness(105%)',
								},
							}}
							clickable
							onClick={() => setSelectedGroupStatus(ProjectStatus.ACTIVE)}
							label={TitleCaseHyphenatedWord.transform(ProjectStatus.ACTIVE)}
						/>
						<Chip
							sx={{
								backgroundColor:
									selectedGroupStatus === ProjectStatus.ARCHIVED
										? theme.customPalette.primaryColors.sky['1']
										: 'default',
								color:
									selectedGroupStatus === ProjectStatus.ARCHIVED
										? theme.customPalette.basicColors.light
										: theme.customPalette.textColors.dark,
								'&:hover': {
									filter: 'brightness(120%)',
								},
								'&:active': {
									filter: 'brightness(105%)',
								},
							}}
							clickable
							onClick={() => setSelectedGroupStatus(ProjectStatus.ARCHIVED)}
							label={TitleCaseHyphenatedWord.transform(ProjectStatus.ARCHIVED)}
						/>
						<Chip
							sx={{
								backgroundColor:
									selectedGroupStatus === ProjectStatus.ALL
										? theme.customPalette.primaryColors.sky['1']
										: 'default',
								color:
									selectedGroupStatus === ProjectStatus.ALL
										? theme.customPalette.basicColors.light
										: theme.customPalette.textColors.dark,
								'&:hover': {
									filter: 'brightness(120%)',
								},
								'&:active': {
									filter: 'brightness(105%)',
								},
							}}
							clickable
							onClick={() => setSelectedGroupStatus(ProjectStatus.ALL)}
							label={TitleCaseHyphenatedWord.transform(ProjectStatus.ALL)}
						/>
					</Stack>
				</Grid>

				<Grid item xs="auto">
					<TextField
						size="small"
						label="Project Name"
						variant="outlined"
						onChange={(e) => setSearchTerm(e.target.value)}
						onKeyDown={(event) => {
							if (event.key === 'Enter') {
								searchAction();
							}
						}}
					/>
				</Grid>

				<Grid item xs="auto">
					<RegularButton
						variant="contained"
						size="small"
						startIcon={<SearchOutlined />}
						type="submit"
						onClick={searchAction}
					>
						Search
					</RegularButton>
				</Grid>
			</Grid>
			{projectData?.projectsNoRelations && (
				<ProjectList context={context} projects={filteredProjects} />
			)}
		</Container>
	);
};
