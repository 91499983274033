import { createContext, useContext } from 'react';
import { createContextualCan } from '@casl/react';
import { Ability } from '@casl/ability';
import defineAbilityFor from '../utility/defineAbilityFor';
import { useUserContext } from './UserProvider';

export const AbilityContext = createContext(new Ability());
export const Can = createContextualCan(AbilityContext.Consumer);

const CaslAbilityProvider = (props: any) => {
	const { user } = useUserContext();
	const ability = defineAbilityFor(user);
	return (
		<AbilityContext.Provider value={ability}>{props.children}</AbilityContext.Provider>
	);
};
export const useCaslAbilityContext = () => useContext(AbilityContext);
CaslAbilityProvider.displayName = 'CaslAbilityProvider';
export default CaslAbilityProvider;
