export const fileContentTypes = [
	{
		extensions: ['aac'],
		contentType: 'audio/aac',
	},
	{
		extensions: ['avi'],
		contentType: 'video/x-msvideo',
	},
	{
		extensions: ['bmp'],
		contentType: 'image/bmp',
	},
	{ extensions: ['csv'], contentType: 'text/csv' },
	{
		extensions: ['bmp'],
		contentType: 'image/bmp',
	},
	{ extensions: ['doc'], contentType: 'application/msword' },
	{
		extensions: ['docx'],
		contentType:
			'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
	},
	{ extensions: ['epub'], contentType: 'application/epub+zip' },
	{ extensions: ['gif'], contentType: 'image/gif' },
	{
		extensions: ['jpg', 'jpeg', 'jfif', 'pjpeg', 'pjp'],
		contentType: 'image/jpeg',
	},
	{ extensions: ['mkv'], contentType: 'video/x-matroska' },
	{ extensions: ['mp3'], contentType: 'audio/mpeg' },
	{ extensions: ['mp4'], contentType: 'video/mp4' },
	{ extensions: ['mpeg'], contentType: 'video/png' },
	{ extensions: ['pdf'], contentType: 'application/pdf' },
	{ extensions: ['png'], contentType: 'image/png' },
	{
		extensions: ['ppt'],
		contentType: 'application/vnd.ms-powerpoint',
	},
	{
		extensions: ['pptx'],
		contentType:
			'application/vnd.openxmlformats-officedocument.presentationml.presentation',
	},
	{
		extensions: ['rtf'],
		contentType: 'application/rtf',
	},
	{ extensions: ['svg'], contentType: 'image/svg+xml' },
	{
		extensions: ['webp'],
		contentType: 'image/webp',
	},
	{
		extensions: ['xls'],
		contentType: 'application/vnd.ms-excel',
	},
	{
		extensions: ['xlsx'],
		contentType:
			'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
	},
	{
		extensions: ['zip'],
		contentType: 'application/zip',
	},
	{
		extensions: ['7z'],
		contentType: 'application/x-7z-compressed',
	},
];
