import { FC } from 'react';
import { Box, SxProps, Tabs, Tab, Badge, Typography } from '@mui/material';
import { NavItem } from '../../../utility/commentData';

interface CommentNavProps {
	sx: SxProps;
	navItems: NavItem[];
	active: string;
	onNavigate: (active: string) => void;
}

const CommentNav: FC<CommentNavProps> = (props) => {
	const { sx, navItems, active, onNavigate } = props;

	const handleChange = (event: React.SyntheticEvent, newValue: string) => {
		onNavigate(newValue);
	};

	return (
		<Box sx={{ maxWidth: 'calc(100% + 80px)', mx: -5 }}>
			<Tabs
				value={active}
				sx={sx}
				onChange={handleChange}
				textColor="secondary"
				indicatorColor="secondary"
				variant="scrollable"
				scrollButtons
			>
				{navItems.map((nav) => (
					<Tab
						label={
							<Box
								display="flex"
								sx={{
									alignItems: 'center',
								}}
							>
								<Typography
									sx={{
										fontSize: {
											md: '0.75rem',
											lg: '0.875rem',
											xl: '1rem',
										},
									}}
								>
									{nav.title}
								</Typography>
								<Badge
									badgeContent={nav.count || 0}
									color="secondary"
									max={999}
									showZero
									sx={{
										'.MuiBadge-badge': {
											position: 'relative',
											transform: 'none',
											marginLeft: '5px',
											marginTop: '1px',
										},
									}}
								></Badge>
							</Box>
						}
						key={nav.name}
						value={nav.name}
						sx={{
							textTransform: 'none',
							maxWidth: {
								md: '110px',
								lg: '160px',
								xl: 'initial',
							},

							px: {
								md: '6px',
								xl: '14px',
							},
						}}
						wrapped
					/>
				))}
			</Tabs>
		</Box>
	);
};

export default CommentNav;
