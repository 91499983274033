import { FC, useState, useEffect, useCallback } from 'react';
import {
	DataGrid,
	GridColDef,
	GridRowsProp,
	GridValueFormatterParams,
	GridValueGetterParams,
	GridSortModel,
} from '@mui/x-data-grid';
import { useSearchParams } from 'react-router-dom';
import { Switch, Tooltip } from '@mui/material';
import { Person } from '@mui/icons-material';
import dayjs from 'dayjs';

import { User } from '../../../types/types';
import EditUserModal from '../../modules/components/EditUserModal';
import EditIcon from '../../icons/EditIcon';
import './AdminUserTable.css';
import { useTheme } from '@mui/material';
import { useQuery } from 'urql';
import { userQuery } from '../../../api/query/user.queries';

interface ProjectUserTableProps {
	users: User[];
	onUserUpdate: (user: any) => void;
	currentPage: number;
	setCurrentPage: (page: number) => void;
	onResetPassword: (email: string) => void;
	recordsPerPage: number;
	setRecordsPerPage: (page: number) => void;
	total?: number;
	orderStates?: any;
	isLoading?: boolean;
}

export const ProjectUserTable: FC<ProjectUserTableProps> = (
	props: ProjectUserTableProps,
) => {
	const [rowCountState, setRowCountState] = useState(props.total);

	const [showUserModal, setShowUserModal] = useState(false);
	const [selectedUser, setSelectedUser] = useState({});

	const { orderStates, isLoading } = props;
	const { order, orderBy, setOrderBy, setOrder } = orderStates;

	const [params] = useSearchParams();

	const editUserId = params.get('edit');

	const theme = useTheme();

	useEffect(() => {
		setRowCountState((prevRowCountState) =>
			props.total !== undefined ? props.total : prevRowCountState,
		);
	}, [props.total, setRowCountState]);

	let { users } = props;
	users = users.map((user) => {
		return {
			...user,
			edit: '',
		};
	});

	function formatProjectFollows(row: {
		projectFollows: { project: { id: number; name: string } }[];
	}) {
		if (row.projectFollows.length) {
			return row.projectFollows
				.map((pf: { project: { id: number; name: string } }) => {
					const {
						project: { name },
					} = pf;
					return name;
				})
				.join('\n');
		} else return null;
	}

	function formatProjectList(row: any, joinSep = '\n') {
		return row.projects
			.sort((a: { name: string }, b: { name: string }) =>
				a.name.toLowerCase() > b.name.toLowerCase() ? -1 : 1,
			)
			.map((p: { name: any }) => {
				return p.name;
			})
			.join(joinSep);
	}

	const columns: GridColDef[] = [
		{
			field: 'firstName',
			headerName: 'First Name',
			width: 120,
			sortable: true,
			headerClassName: 'adminHideRightSeparator',
		},
		{
			field: 'lastName',
			headerName: 'Last Name',
			width: 120,
			sortable: true,
			headerClassName: 'adminHideRightSeparator',
		},
		{
			field: 'email',
			headerName: 'Email',
			minWidth: 120,
			flex: 0.75,
			sortable: true,
			headerClassName: 'adminHideRightSeparator',
		},
		{
			field: 'lastLogin',
			headerName: 'Last Login',
			minWidth: 120,
			sortable: true,
			headerClassName: 'adminHideRightSeparator',
			valueFormatter: (params: GridValueFormatterParams) => {
				return params.value
					? dayjs(parseInt(params.value)).format('MMM DD YYYY')
					: 'Unavailable';
			},
		},
		{
			field: 'projects',
			headerName: 'Projects',
			minWidth: 150,
			flex: 1,
			sortable: false,
			headerClassName: 'adminHideRightSeparator',
			renderCell: ({ row }) => {
				if (row.projects.length) {
					return (
						<Tooltip
							title={
								<span style={{ whiteSpace: 'pre-line' }}>
									Project Access:{'\n'}
									{formatProjectList(row)}
								</span>
							}
							placement="bottom"
							arrow={true}
						>
							<span className="projectName">
								{formatProjectList(row, ', ')}
							</span>
						</Tooltip>
					);
				} else return null;
			},
		},
		{
			field: 'status',
			headerName: 'Tools',
			headerAlign: 'right',
			sortable: false,
			headerClassName: 'adminHideRightSeparator',
			valueGetter: (params: GridValueGetterParams<any, any>) => {
				const { row: user } = params;
				const invited = dayjs(parseInt(user.created)).format('MMM DD YYYY');
				return user.registered ? 'Registered' : `Invited ${invited}`;
			},
			renderCell: ({ row }) => {
				return (
					<div
						key={row.id}
						style={{
							width: '100%',
							height: '100%',
							alignItems: 'center',
							display: 'flex',
							justifyContent: 'flex-end',
						}}
					>
						{row.projectFollows.length > 0 ? (
							<div style={{ marginTop: '5px' }}>
								<Tooltip
									title={
										<span style={{ whiteSpace: 'pre-line' }}>
											Following:{'\n'}
											{formatProjectFollows(row)}
										</span>
									}
									placement="bottom"
									arrow={true}
								>
									<Person
										sx={{ color: theme.customPalette.primaryColors.sky[1] }}
									/>
								</Tooltip>
							</div>
						) : null}
						<div>
							<Tooltip
								title={`${row?.isActive ? 'Inactivate User' : 'Activate User'}`}
								placement="bottom"
								arrow={true}
							>
								<Switch
									onChange={(value) => {
										props.onUserUpdate({ ...row, isActive: !row.isActive });
									}}
									value={row.isActive}
									defaultChecked={row.isActive}
								/>
							</Tooltip>
						</div>
						<Tooltip title="Edit User" placement="bottom" arrow={true}>
							<div
								onClick={() => {
									setShowUserModal(true);
								}}
							>
								<EditIcon />
							</div>
						</Tooltip>
					</div>
				);
			},
		},
	];

	const rows: GridRowsProp = users;

	const [{ data: userData, fetching: userFetching }] = useQuery<{
		author: User;
	}>({
		query: userQuery,
		variables: { id: Number(editUserId) },
		pause: !editUserId,
	});

	useEffect(() => {
		if (editUserId && props.users) {
			const numericUserId = +editUserId;
			const user = props.users.find((user) => user.id === numericUserId);

			if (user) {
				// Only 'internal', 'client', 'public' users are editable.
				setSelectedUser(user as User);
				setShowUserModal(true);
			} else {
				if (!userFetching && userData?.author) {
					setSelectedUser(userData?.author as User);
					setShowUserModal(true);
				}
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [editUserId, props.users, userData, userFetching]);

	const handleSortModelChange = useCallback((sortModel: GridSortModel) => {
		if (sortModel.length > 0) {
			setOrder(sortModel[0].sort);
			setOrderBy(sortModel[0].field);
		} else {
			setOrder('');
			setOrderBy('');
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	console.log({ order, orderBy });

	return (
		<div
			style={{
				display: 'flex',
				width: '100%',
				height: '100%',
				paddingTop: '20px',
				paddingBottom: '40px',
			}}
		>
			<div style={{ flexGrow: 1 }}>
				<DataGrid
					getRowClassName={() => 'adminCellFormat'}
					sx={(theme) => ({
						'& .MuiDataGrid-columnHeaders': {
							backgroundColor: theme.customPalette.primaryColors.sky[1],
							color: 'white',
						},
						'& .MuiDataGrid-sortIcon': {
							fill: '#FFF',
						},
						'& .MuiIconButton-root:hover': {
							backgroundColor: 'rgb(255 255 255 / 12%)',
						},
						'& .MuiIconButton-root': {
							marginLeft: '3px',
						},
						'& .MuiDataGrid-columnHeader--sorted .MuiDataGrid-sortIcon': {
							fontSize: 20,
						},
					})}
					disableColumnMenu
					disableSelectionOnClick
					headerHeight={42}
					columns={columns}
					rows={rows}
					loading={isLoading}
					rowCount={rowCountState}
					rowsPerPageOptions={[5, 10, 25, 50, 100]}
					pagination
					autoHeight={true}
					page={props.currentPage}
					pageSize={props.recordsPerPage}
					paginationMode="server"
					onPageChange={(newPage) => props.setCurrentPage(newPage)}
					onPageSizeChange={(newPageSize) =>
						props.setRecordsPerPage(newPageSize)
					}
					onRowClick={({ row }) => setSelectedUser(row)}
					sortingMode="server"
					onSortModelChange={handleSortModelChange}
				/>

				<EditUserModal
					onResetPassword={props.onResetPassword}
					onUserUpdate={props.onUserUpdate}
					selectedUser={selectedUser}
					open={showUserModal}
					onClose={() => setShowUserModal(!showUserModal)}
				/>
			</div>
		</div>
	);
};
