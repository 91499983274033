import { slice, take } from 'lodash';

export const paginate = (
	items: any[],
	pageNumber: number,
	pageSize: number,
) => {
	const startIndex = (pageNumber - 1) * pageSize;
	const from = slice(items, startIndex);
	return take(from, pageSize);
};
