import React, { useState } from 'react';
import {
	List,
	ListItemButton,
	ListItemText,
	Menu,
	MenuItem,
	Theme,
	useTheme,
	ListItemIcon,
} from '@mui/material';
import useNumberParams from '../../../hooks/useNumberParams';
import { Link } from 'react-router-dom';
import { ArticleOutlined, ExpandLess, ExpandMore } from '@mui/icons-material';
import { Can } from '../../../providers/CaslAbilityProvider';
import {
	permissionAction,
	permissionSubject,
} from '../../../utility/defineAbilityFor';
import { subject } from '@casl/ability';
import { Page } from 'types/types';

interface PagePickerProps {
	pages: Array<Page>;
	projectId: number;
}

export const PagePicker = ({ pages, projectId }: PagePickerProps) => {
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const open = Boolean(anchorEl);
	const theme = useTheme();
	const styles = (theme: Theme) => ({
		pagePrefix: {
			color: theme.customPalette.primaryColors.sky[1],
			fontSize: '10px',
		},
		pageTitle: {
			color: theme.customPalette.primaryColors.sky[1],
			fontSize: '14px',
		},
		menuLink: {
			color: 'black',
			fontSize: '14px',
		},
	});

	const classes = styles(theme);
	const handleClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const { pageId } = useNumberParams();
	const currentPage = pages.find((p) => p.id === pageId);

	const handleMenuItemClick = () => {
		setAnchorEl(null);
	};

	return (
		<div>
			<List component="nav" aria-label="Page Navigation">
				<ListItemButton onClick={handleClick}>
					<ListItemText
						primary="Current Page"
						secondary={currentPage?.name}
						primaryTypographyProps={classes.pagePrefix}
						secondaryTypographyProps={classes.pageTitle}
					/>
					<ListItemIcon>
						{open ? (
							<ExpandLess
								style={{ color: theme.customPalette.primaryColors.sky[1] }}
							/>
						) : (
							<ExpandMore
								style={{ color: theme.customPalette.primaryColors.sky[1] }}
							/>
						)}
					</ListItemIcon>
				</ListItemButton>
			</List>
			<Menu
				open={open}
				onClose={handleClose}
				anchorEl={anchorEl}
				PaperProps={{
					style: {
						width: 250,
					},
				}}
			>
				{pages &&
					pages.map((page: Page, index: number) => (
						<Can
							I={permissionAction.UPDATE}
							a={subject(permissionSubject.PAGE, page as any)}
							key={`page_${index}`}
						>
							<MenuItem
								selected={page.id === currentPage?.id}
								component={Link}
								sx={classes.menuLink}
								to={`/projects/${projectId}/pages/${page.id}`}
								onClick={handleMenuItemClick}
							>
								<ArticleOutlined />
								<ListItemText>{page.name}</ListItemText>
							</MenuItem>
						</Can>
					))}
			</Menu>
		</div>
	);
};
