// ==============================|| OVERRIDES - BUTTON ||============================== //

import { Theme } from '@mui/material';

export default function Toolbar(theme: Theme) {
	return {
		MuiToolbar: {
			styleOverrides: {
				root: ({ ownerState }: any) => {
					return {
						...(ownerState.variant === 'dense' && {
							// backgroundColor: '#fff',
							justifyContent: 'space-between',
							minHeight: '55px',
						}),
					};
				},
			},
		},
		// MuiAppBar: {
		// 	styleOverrides: {
		// 		root: ({ ownerState }: any) => {
		// 			return {
		// 				backgroundColor: '#fff',
		// 			};
		// 		},
		// 	},
		// },
	};
}
