// ==============================|| OVERRIDES - MENU ||============================== //

import { Theme } from '@mui/material';

export default function Menu(theme: Theme) {
	return {
		MuiMenu: {
			styleOverrides: {
				root: {
					'& .MuiPaper-root': {
						background: theme.customPalette.basicColors.light,
						boxShadow:
							'0px 30px 84px rgba(0, 0, 0, 0.08), 0px 8px 32px rgba(0, 0, 0, 0.07), 0px 3px 14px rgba(19, 10, 46, 0.03)',
						borderRadius: '12px',
						'& .MuiMenuItem-root': {
							color: theme.customPalette.textColors.dark,
							'&:hover': {
								backgroundColor:
									theme.customPalette.primaryColors.grassGreen[5],
							},
							'& .MuiSvgIcon-root': {
								color: theme.customPalette.primaryColors.sky[1],
								marginRight: '10px',
							},
						},
						'& .MuiListItemText-primary': {
							color: theme.customPalette.textColors.dark,
							fontSize: '16px',
						},
					},
				},
			},
		},
	};
}
