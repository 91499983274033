import { KeyboardArrowLeft as KeyboardArrowLeftIcon } from '@mui/icons-material';
import { useTheme } from '@mui/material';

export function LeftArrowIcon(): JSX.Element {
	const theme = useTheme();
	return (
		<KeyboardArrowLeftIcon
			style={{ color: theme.customPalette.primaryColors.sky[1] }}
		/>
	);
}
