import { useEffect, useState } from 'react';
import { useMutation, useQuery } from 'urql';
import { ExistingProjectInput, Project } from './../../../types/types';
import { Mutation } from '../../../types/types';
import { projectByIdQuery } from '../../../api/query/project.queries';
import { updateProjectMutation } from '../../../api/mutation/project.mutation';
import _ from 'lodash';

const context = { additionalTypenames: ['Project'] };

const useProject = (projectId: number) => {
	const [project, setProject] = useState<Project | null>(null);
	const [{ data }] = useQuery({
		query: projectByIdQuery(),
		variables: { projectId },
		context,
	});

	useEffect(() => {
		if (data?.project) setProject(data?.project);
	}, [data?.project]);

	const [, updateProject] = useMutation<Mutation['updateProject']>(
		updateProjectMutation,
	);

	const handleProjectUpdate = (newProject: ExistingProjectInput) => {
		// @ts-ignore
		setProject({ ...project, ...newProject });
		//TODO: fix this smell
		const existingUpdate = _.omit(newProject, 'slug');
		return updateProject({ existingProjectData: existingUpdate });
	};

	return {
		project: project as Project,
		handleProjectUpdate,
	};
};

export default useProject;
