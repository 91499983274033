import React, { FC, useState } from 'react';
import UnpublishedIcon from '@mui/icons-material/Unpublished';
import { usePausedContext } from '@coUrbanize/community-modules';
import { Page } from 'types/types';
import { LoadingButton } from '@mui/lab';
import { useUnPublishActionConfirmation } from 'hooks/useActionConfirmation';
import { unPublishPageMutation } from 'api/mutation/page.mutation';
import { useMutation } from 'urql';

interface UnPublishPageProps {
	page: Page;
	onUnpublish: () => any;
}

export const UnPublishPage: FC<UnPublishPageProps> = (props) => {
	const { page, onUnpublish } = props;
	const { isPaused } = usePausedContext();
	const [isUnPublishing, setIsUnPublishing] = useState(false);
	const showUnpublishConfirmation = useUnPublishActionConfirmation();
	const [, unPublishPage] = useMutation(unPublishPageMutation('id'));

	const handleUnPublishPage = async (page: Page) => {
		setIsUnPublishing(true);
		await unPublishPage({ id: page.id });
		onUnpublish();
		setIsUnPublishing(false);
	};

	return (
		<>
			<LoadingButton
				disabled={!!isPaused || isUnPublishing}
				onClick={async () => {
					await showUnpublishConfirmation(
						{
							title: 'Unpublish Page',
							message: (
								<>
									Are you sure you want to unpublish this page?
									<br />
									<br />
									This will remove the page from the live site.
								</>
							),
						},
						() => handleUnPublishPage(page),
					);
				}}
				startIcon={<UnpublishedIcon />}
				loading={isUnPublishing}
				loadingPosition="start"
			>
				Un-publish
			</LoadingButton>
		</>
	);
};
