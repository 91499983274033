import React, { FC } from 'react';
import Dialog from '../../common/Dialog';
import { Box, Button, Grid, Link, Paper, Typography } from '@mui/material';
import { Field, Form, Formik, FormikValues } from 'formik';
import { TextField } from 'formik-mui';
import * as Yup from 'yup';
import { NewPageInput } from './AddPageContainer';
import { useSaveActionConfirmation } from 'hooks/useActionConfirmation';
interface AddPageModalProps {
	open: boolean;
	onClose: () => void;
	handleAddClick: (newPageInput: NewPageInput) => void;
}

const AddPageModal: FC<AddPageModalProps> = (props) => {
	const { open, onClose, handleAddClick } = props;

	// eslint-disable-next-line
	const URL = // eslint-disable-next-line
		/^https:\/\/(www.)?(((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:)*@)?(((\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5]))|((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?)(:\d*)?)(\/((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)+(\/(([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)*)*)?)?(\?((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|[\uE000-\uF8FF]|\/|\?)*)?(\#((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|\/|\?)*)?$/i;

	const saveActionConfirmation = useSaveActionConfirmation();
	const handleConfirmAddPage = async (values: FormikValues) => {
		if (!values.name) return;
		const name = values.name;
		const url = values.url || null;
		const newPageInput: NewPageInput = { name, url };
		await saveActionConfirmation(
			{
				title: 'Add Page Confirmation',
				message: (
					<>
						Are you sure you want to add <strong>{name}</strong> page?
						<br />
						{url ? (
							<>
								It will be published immediately and redirect to URL:{' '}
								<Link href={url} target="_blank">
									{url}
								</Link>
							</>
						) : (
							''
						)}
					</>
				),
				confirmLabel: 'Add',
			},
			() => handleAddClick(newPageInput),
			() => onClose(),
		);
	};

	return (
		<Dialog
			open={open}
			title="Add Page"
			buttonContainerStyle={{ pr: 3, pb: 3 }}
			maxWidth="sm"
			LeftButton={
				<Button variant="outlined" onClick={() => onClose()}>
					Close
				</Button>
			}
			RightButton={
				<Button form="add-page-form" type="submit" variant="outlined">
					Save
				</Button>
			}
		>
			<Formik
				validationSchema={Yup.object({
					name: Yup.string()
						.max(25, 'Must be 15 characters or less')
						.min(2, 'Must be 2 characters or more')
						.required('Required'),
					url: Yup.string()
						.transform((currentValue) => {
							const doesNotStartWithProtocol =
								currentValue &&
								!(
									currentValue.startsWith('http://') ||
									currentValue.startsWith('https://')
								);

							if (doesNotStartWithProtocol) {
								return `https://${currentValue}`;
							}
							return currentValue;
						})
						.matches(URL, 'Provide a valid url')
						.nullable(),
				})}
				initialValues={{
					name: '',
					url: null,
				}}
				onSubmit={async (values, { setSubmitting }) => {
					await handleConfirmAddPage(values);
					if (props.open) {
						console.log('onClose');
						props.onClose();
					}
					setSubmitting(false);
				}}
			>
				{({ values }) => (
					<Form id="add-page-form">
						<Box sx={{ width: '100%' }}>
							<Paper elevation={2} sx={{ padding: 2, marginTop: 2 }}>
								<Typography variant="body2" pb={4} color="secondary">
									Provide a brief name for your page.
									<br />
									Optionally add an URL to redirect to another site.
								</Typography>
								<Grid container rowGap={2} spacing={2}>
									<Grid item sm={6}>
										<Field
											component={TextField}
											label="Page Name"
											name="name"
											id="name"
											variant="outlined"
											size="small"
											value={values.name}
										/>
									</Grid>
									<Grid item sm={6}>
										<Field
											component={TextField}
											label="External Url Override"
											name="url"
											id="url"
											variant="outlined"
											size="small"
											value={values.url}
										/>
									</Grid>
								</Grid>
							</Paper>
						</Box>
					</Form>
				)}
			</Formik>
		</Dialog>
	);
};
export default AddPageModal;
