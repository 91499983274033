import { useEffect, useState } from 'react';
import { ImageContent } from '../components/modules/ImageModule';
import ModuleInstanceData from '../types/moduleInstanceData';
import { useModuleSaveData } from './useModuleSaveData';

const useCarousel = (moduleInstanceData: ModuleInstanceData) => {
	const { content, saveDataRef, config } = moduleInstanceData;
	const { updateModuleSaveData } = useModuleSaveData(saveDataRef);

	const [openUploadModal, setOpenUploadModal] = useState(false);
	const [openPreviewModal, setOpenPreviewModal] = useState(false);
	const [height, setHeight] = useState<number>(content?.height || 300);
	const [images, setImages] = useState<ImageContent[]>(content?.images);
	const [toDeleteImages, setToDeleteImages] = useState<any>([]);
	const [index, setIndex] = useState<number | null>(null);

	useEffect(() => {
		updateModuleSaveData({
			content: { height, images, toDeleteImages },
			config,
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [updateModuleSaveData, config]);

	const handleDoubleClick = (order: number) => {
		setIndex(order);
		setOpenUploadModal(true);
	};

	return {
		images,
		index,
		setIndex,
		height,
		openUploadModal,
		openPreviewModal,
		setImages,
		setHeight,
		setOpenUploadModal,
		setOpenPreviewModal,
		handleDoubleClick,
		setToDeleteImages,
	};
};

export default useCarousel;
