import { FC } from 'react';
import { Box, Typography } from '@mui/material';

const Feedback: FC = () => {
	return (
		<Box p={2} px={5}>
			<Box display="flex" alignItems="center" justifyContent="space-between">
				<Typography variant="h6" fontWeight="bold">
					Feedback Page
				</Typography>
			</Box>
		</Box>
	);
};

export default Feedback;
