import { FC, useState } from 'react';
import { NativeTypes } from 'react-dnd-html5-backend';
import { useDrop, DropTargetMonitor } from 'react-dnd';
import { Typography } from '@mui/material';

interface FileDragDropProps {
	handleDrop: (fileList: FileList) => void;
	styles?: React.CSSProperties;
	children?: React.ReactNode;
}

const FileDragDrop: FC<FileDragDropProps> = (props) => {
	const { handleDrop, styles } = props;
	const [error, setError] = useState('');

	const [{ canDrop, isOver }, drop] = useDrop(
		() => ({
			accept: [NativeTypes.FILE],
			drop(item: { files: FileList }) {
				if (handleDrop) {
					const files = item.files;

					if (files && files.length > 0) {
						var imageType = /image.*/;
						if (files[0].type.match(imageType)) {
							// it's an image, process it
							handleDrop(item.files);
						} else setError('Only Images are supported');
					}
				}
			},
			collect: (monitor: DropTargetMonitor) => ({
				isOver: monitor.isOver(),
				canDrop: monitor.canDrop(),
			}),
		}),
		[props],
	);

	const isActive = canDrop && isOver;

	return (
		<div
			style={{
				display: 'inline-block',
				position: 'relative',
				width: '100%',
				...styles,
			}}
			ref={drop}
		>
			{isActive && (
				<div
					style={{
						border: 'dashed grey 4px',
						backgroundColor: 'rgba(255,255,255,.8)',
						position: 'absolute',
						top: 0,
						bottom: 0,
						left: 0,
						right: 0,
						zIndex: 9999,
					}}
				>
					<div
						style={{
							position: 'absolute',
							top: '50%',
							right: 0,
							left: 0,
							textAlign: 'center',
							color: 'grey',
							fontSize: 36,
						}}
					>
						<div>drop here :)</div>
					</div>
				</div>
			)}
			{props.children}
			<Typography color="red" variant="caption">
				{error}
			</Typography>
		</div>
	);
};

export default FileDragDrop;
