import { gql } from 'urql';

export const getInfoAndPlansQuery = gql`
	query ($projectId: Int!) {
		informationPlansByProject(projectId: $projectId) {
			id
			title
			url
			ordinal
			file {
				url
				fileName
			}
		}
	}
`;
