import { gql } from 'urql';

export const removeQuestion = gql`
	mutation removeQuestion($questionGroupId: Int!, $questionId: Int!) {
		removeQuestion(questionGroupId: $questionGroupId, questionId: $questionId)
	}
`;

export const toggleHideQuestion = gql`
	mutation toggleHideQuestion($questionGroupId: Int!, $questionId: Int!) {
		toggleHideQuestion(questionGroupId: $questionGroupId, questionId: $questionId)
	}
`;

export const removeQuestionGroup = gql`
	mutation removeQuestionGroup($questionGroupId: Int!) {
		removeQuestionGroup(questionGroupId: $questionGroupId)
	}
`;

export const reorderQuestion = gql`
	mutation reorderQuestion(
		$questionGroupId: Int!
		$sourceIndex: Int!
		$destinationIndex: Int!
	) {
		reorderQuestion(
			questionGroupId: $questionGroupId
			sourceIndex: $sourceIndex
			destinationIndex: $destinationIndex
		)
	}
`;

export const createQuestionGroupMutation = gql`
	mutation createQuestionGroup(
		$ordinal: Int
		$projectId: Int!
		$themeName: String!
	) {
		createQuestionGroup(
			ordinal: $ordinal
			projectId: $projectId
			themeName: $themeName
		) {
			id
		}
	}
`;

export const updateQuestionGroupMutation = gql`
	mutation updateQuestionGroup(
		$questionGroupId: Int!
		$themeName: String
		$status: String
	) {
		updateQuestionGroup(
			questionGroupId: $questionGroupId
			themeName: $themeName
			status: $status
		)
	}
`;

export const addQuestionMutation = (s: string) => gql`
    mutation addQuestion($addQuestionData: AddQuestionInput!){
        addQuestion(addQuestionData: $addQuestionData) {
            ${s}
        }
    }
`;

export const updateQuestionMutation = gql`
	mutation updateQuestion($editQuestionData: EditQuestionInput!) {
		updateQuestion(editQuestionData: $editQuestionData)
	}
`;

export const reorderQuestionChoiceMutation = gql`
	mutation reorderQuestionChoice(
		$questionChoices: [OrderQuestionChoiceInput!]!
	) {
		reorderQuestionChoice(questionChoices: $questionChoices)
	}
`;

export const createQuestionChoiceMutation = gql`
	mutation createQuestionChoice($questionId: Int!) {
		createQuestionChoice(questionId: $questionId) {
			id
		}
	}
`;

export const updateQuestionChoiceMutation = gql`
	mutation updateQuestionChoice(
		$questionChoiceId: Int!
		$choiceText: String!
		$choiceType: String!
	) {
		updateQuestionChoice(
			questionChoiceId: $questionChoiceId
			choiceText: $choiceText
			choiceType: $choiceType
		)
	}
`;

export const deleteQuestionChoiceMutation = gql`
	mutation deleteQuestionChoice($questionChoiceId: Int!) {
		deleteQuestionChoice(questionChoiceId: $questionChoiceId)
	}
`;
