import { KeyboardArrowDown as KeyboardArrowDownIcon } from '@mui/icons-material';

import { useTheme } from '@mui/material';

export function OpenYearPickerIcon(): JSX.Element {
	const theme = useTheme();
	return (
		<KeyboardArrowDownIcon
			style={{ color: theme.customPalette.primaryColors.sky[1] }}
		/>
	);
}
