// ==============================|| OVERRIDES - CHECKBOX ||============================== //

import { Theme } from '@mui/material';

export default function Checkbox(theme: Theme) {
	return {
		MuiCheckbox: {
			styleOverrides: {
				root: {
					color: theme.customPalette.systemColors.unchecked,
					'&.Mui-checked': {
						color: theme.customPalette.primaryColors.sky[1],
					},
					'&.Mui-disabled': {
						color: theme.customPalette.systemColors.disabled,
					},
				},
			},
		},
	};
}
