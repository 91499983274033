import { gql } from 'urql';

export const formsByProject = gql`
	query ($projectId: Int!) {
		formsByProject(projectId: $projectId) {
			id
			formName
			module {
				id
				content
			}
			status
			mostRecentFormJson
			publishedModule {
				id
			}
		}
	}
`;
