import { ChangeEvent, FC, useState } from 'react';
import { Box, Button } from '@mui/material';
import { useMutation } from 'urql';
import UploadImage from '../components/UploadImage';
import { ImageContent, ImageProperty } from '../ImageModule';
import { fileUploadApi } from '../../../api/apiClient';
import { getUploadUrlMutation } from '../../../api/mutation/s3.mutations';
import { useSnackBar } from '../../../providers/SnackBarProvider';
import { useRouteContext } from '../../../routes/Layout';

interface FieldLabelImageConfigureProps {
	fieldLabelImage: ImageContent | undefined;
	onBackClick: () => void;
	onImageChange: (image: ImageContent) => void;
}

export const FieldLabelImageConfigure: FC<FieldLabelImageConfigureProps> = (
	props,
) => {
	const [imageContent, setImageContent] = useState<ImageContent>(
		props.fieldLabelImage!,
	);
	const [progress, setProgress] = useState(0);
	const [saveDisabled, setSaveDisabled] = useState(true);
	const { notify } = useSnackBar();
	const { activeProjectDetails } = useRouteContext();
	const [, getUploadUrl] = useMutation(getUploadUrlMutation);

	const handleChange = (
		property: ImageProperty,
		event?: ChangeEvent | Event,
		data?: any,
	) => {
		let value = data || (event?.target as HTMLInputElement).value;

		if (property === 'lightbox') {
			value = (event?.target as HTMLInputElement).checked;
		}
		setImageContent({ ...imageContent, [property]: value });
	};

	const uploadFilesToAws = async (files: FileList | null) => {
		if (!files) return;

		const file = files[0];
		const result = await getUploadUrl({
			folder: `${activeProjectDetails.slug}/forms/draft/labels`,
			filename: file.name,
		});

		if (result.error) return;

		const uploadURL = result.data.getUploadUrl;

		const res = await fileUploadApi(uploadURL, file, setProgress);
		if (!res) return notify('Failed to upload image!', 'error');
		const imageUrl = res.split('?')[0];

		// store in state
		setImageContent({ ...imageContent, imageUrl, fileName: file.name });
	};

	const handleSave = () => {
		console.log(imageContent);
		props.onImageChange(imageContent);
		props.onBackClick();
	};

	return (
		<Box>
			<UploadImage
				handleUpload={uploadFilesToAws}
				progress={progress}
				handleChange={handleChange}
				imageContent={imageContent.imageUrl ? imageContent : undefined}
				setConfirmDisabled={setSaveDisabled}
			/>
			<Button
				sx={(theme) => ({
					textTransform: 'capitalize',
					padding: '8px 16px',
					minWidth: '162px',
					borderWidth: '2px',
					borderColor: theme.customPalette.primaryColors.sky[1],
					'&:hover': {
						borderWidth: '2px',
						borderColor: theme.customPalette.primaryColors.sky[2],
						color: theme.customPalette.primaryColors.sky[2],
					},
					color: theme.customPalette.primaryColors.sky[1],
					position: 'absolute',
					bottom: 30,
					right: '51%',
				})}
				variant="outlined"
				onClick={props.onBackClick}
			>
				Cancel
			</Button>
			<Button
				sx={(theme) => ({
					color: theme.customPalette.basicColors.light,
					textTransform: 'capitalize',
					padding: '8px 16px',
					minWidth: '162px',
					backgroundColor: theme.customPalette.primaryColors.sky[1],
					'&:hover': {
						backgroundColor: theme.customPalette.primaryColors.sky[2],
					},
					'&.Mui-disabled': {
						color: '#fff',
						backgroundColor: theme.customPalette.systemColors.disabled,
					},
					position: 'absolute',
					bottom: 30,
					right: '25%',
				})}
				variant="outlined"
				onClick={handleSave}
				disabled={saveDisabled}
			>
				Save Image
			</Button>
		</Box>
	);
};
