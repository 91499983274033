import { Navigate, Outlet, useLocation, useOutletContext } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { RouterUtility } from '../utility/network/routerUtility';

export const ProtectedRoute = () => {
	const { isAuthenticated } = useAuth0();

	const path = window.location.pathname;
	const redirectTo = RouterUtility.getLoginURL(path);
	const location = useLocation();
	const context = useOutletContext();
	return isAuthenticated ? (
		<Outlet context={context} />
	) : (
		<Navigate to={redirectTo} state={{ from: location }} />
	);
};

export default ProtectedRoute;
