import { Box, useTheme } from '@mui/material';

export function ImagePlaceHolder(): JSX.Element {
	const theme = useTheme();
	return (
		<Box
			sx={{
				height: '200px',
				backgroundColor: theme.customPalette.systemColors.disabled,
			}}
			display="flex"
			alignItems="center"
			justifyContent="center"
		>
			<svg
				width="76"
				height="76"
				viewBox="0 0 76 76"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<path
					d="M75.5 67.1667V8.83333C75.5 4.25 71.75 0.5 67.1667 0.5L8.83333 0.5C4.25 0.5 0.5 4.25 0.5 8.83333L0.5 67.1667C0.5 71.75 4.25 75.5 8.83333 75.5H67.1667C71.75 75.5 75.5 71.75 75.5 67.1667ZM23.4167 44.25L33.8333 56.7917L48.4167 38L67.1667 63H8.83333L23.4167 44.25Z"
					fill="white"
				/>
			</svg>
		</Box>
	);
}
