export enum ParentOptionKey {
	KEY_METRICS = 'keyMetrics',
	KEY_THEMES = 'keyThemes',
	SENTIMENT = 'sentiment',
	COMMENTS = 'comments',
	POLLS = 'polls',
	FORMS = 'forms'
}

export enum KeyMetrics {
	TOTAL_FEEDBACK = 'totalFeedback',
	TOTAL_VISITORS = 'totalVisitors',
	TOTAL_FOLLOWERS = 'totalFollowers',
	POSITIVE_NEUTRAL_FOLLOWERS = 'positiveNeutralFollowers',
	IDEAS_ADDED_TO_PLAN = 'ideasAddedToPlan',
	LETTERS_OF_SUPPORT = 'lettersOfSupport',
	ENGAGEMENT_OVER_TIME = 'engagementOverTime'
}

export enum KeyThemes {
	COMMENT_SUMMARY = 'commentSummary',
	THEMES_AND_SUBTHEMES = 'themesAndSubthemes'
}

export enum DynamicChildOption {
	QUESTIONS,
	FORMS,
	POLLS
}

export interface ExportOption {
	key: string;
	title: string;
	checked: boolean | 'indeterminate';
	indeterminate?: boolean;
	children?: ExportOption[];
	dynamicChildrenKey?: DynamicChildOption;
	pdfOnly?: boolean;
}

export const initialOptions: ExportOption[] = [
	{ key: ParentOptionKey.KEY_METRICS, title: 'Key Metrics', checked: false, children: [
		{key: KeyMetrics.TOTAL_FEEDBACK, title: 'Total Feedback', checked: false},
		{key: KeyMetrics.TOTAL_VISITORS, title: 'Total Visitors', checked: false},
		{key: KeyMetrics.TOTAL_FOLLOWERS, title: 'Total Followers', checked: false},
		{key: KeyMetrics.POSITIVE_NEUTRAL_FOLLOWERS, title: 'Positive + Neutral Followers', checked: false},
		{key: KeyMetrics.IDEAS_ADDED_TO_PLAN, title: 'Ideas Added to Plan', checked: false},
		{key: KeyMetrics.LETTERS_OF_SUPPORT, title: 'Total Letters of Support', checked: false},
		{key: KeyMetrics.ENGAGEMENT_OVER_TIME, title: 'Engagement Over Time', checked: false, pdfOnly: true},
	]},
	{ key: ParentOptionKey.SENTIMENT, title: 'Sentiment Metrics', checked: true },
	{ key: ParentOptionKey.KEY_THEMES, title: 'Key Themes', checked: false, pdfOnly: true, children: [
		{ key: KeyThemes.COMMENT_SUMMARY, title: 'Comment Summary', pdfOnly: true, checked: false},
		{ key: KeyThemes.THEMES_AND_SUBTHEMES, title: 'Themes and Subthemes', pdfOnly: true, checked: false}
	]},
	{ key: ParentOptionKey.COMMENTS, title: 'Comment Responses', checked: true, dynamicChildrenKey: DynamicChildOption.QUESTIONS },
	{ key: ParentOptionKey.POLLS, title: 'Poll Responses', checked: true, dynamicChildrenKey: DynamicChildOption.POLLS },
	{ key: ParentOptionKey.FORMS, title: 'Forms/Letter of Support', checked: true, dynamicChildrenKey: DynamicChildOption.FORMS },
];
