import { FC } from 'react';
import dayjs from 'dayjs';
import { Box, Typography, Button, useTheme } from '@mui/material';
import {
	DeleteOutline,
	Visibility as VisibilityIcon,
} from '@mui/icons-material';
import { Update } from './useUpdate';
import EditIcon from '../../icons/EditIcon';

interface UpdateRowProps {
	update: Update | null;
	index?: number;
	copy?: boolean;
	onDelete?: (id: number, title: string) => void;
	onEdit: () => void;
	disabled?: boolean;
	isModule?: boolean;
	projectName?: string;
	customUrl?: string;
	isCustomUrl?: boolean;
}

const UpdateRow: FC<UpdateRowProps> = (props) => {
	const {
		update,
		index,
		copy,
		onDelete,
		onEdit,
		disabled,
		isModule,
		projectName,
		customUrl,
		isCustomUrl,
	} = props;
	const theme = useTheme();
	if (!update) return null;

	const previewUpdate = () => {
		const baseUrl = process.env.REACT_APP_SITE_BASE_URL;
		const secret = process.env.REACT_APP_SITES_API_SECRET;
		window.open(
			`${
				isCustomUrl ? `https://${customUrl}` : baseUrl
			}/api/preview-update?secret=${secret}&projectSlug=${projectName}&updateId=${
				update.id
			}&isCustomUrl=${isCustomUrl}`,
			'_blank',
		);
	};

	const globalBtnStyles = {
		textTransform: 'none',
		fontSize: 16,
		padding: '5px 12px',
		lineHeight: 1.2,
	};

	const draftBtnStyles = {
		borderColor: theme.customPalette.textColors.light,
		backgroundColor: theme.customPalette.surface.dark,
		color: theme.customPalette.textColors.static,
	};

	const publishedBtnStyles = {
		borderColor: theme.customPalette.primaryColors.grassGreen[6],
		backgroundColor: theme.customPalette.primaryColors.grassGreen[5],
		color: theme.customPalette.primaryColors.grassGreen[1],
	};

	const iconBtnStyles = {
		textTransform: 'none',
		fontWeight: 500,
		fontSize: '1rem',
		padding: '5px 17px',
	};

	return (
		<Box
			sx={{
				mb: 2,
				p: 3,
				backgroundColor: theme.customPalette.surface.light,
				boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.12)',
			}}
		>
			<Box display="flex" gap={1} mb={3}>
				{!isModule && (
					<Box
						sx={{
							width: 130,
							textDecoration: 'none',
							color: 'inherit',
						}}
					>
						<Button
							component="a"
							href="#editor"
							variant="outlined"
							onClick={onEdit}
							disabled={disabled}
							sx={
								update.published
									? { ...globalBtnStyles, ...publishedBtnStyles }
									: { ...globalBtnStyles, ...draftBtnStyles }
							}
						>
							{update.published && !copy
								? `Update #${index}`
								: copy
								? 'Copy'
								: 'Draft'}
						</Button>
					</Box>
				)}

				<Box>
					<Typography
						fontWeight="700"
						fontSize={16}
						color={theme.customPalette.basicColors.dark}
					>
						{update.title}
					</Typography>
					<Typography color="#000">
						{update.published ? 'Published ' : 'Edited '}
						{update.published &&
							dayjs(
								update.published.indexOf('-') > 0
									? update.published
									: Number(update.published),
							).format('MMM DD, YYYY')}
						{!update.published &&
							update.created &&
							dayjs(Number(update.created)).format('MMM DD, YYYY')}
					</Typography>
				</Box>
			</Box>
			{!copy && !isModule && (
				<Box display="flex" alignItems="center">
					<Button
						onClick={previewUpdate}
						startIcon={<VisibilityIcon />}
						sx={iconBtnStyles}
					>
						Preview
					</Button>
					<Button
						disabled={disabled}
						onClick={onEdit}
						startIcon={<EditIcon />}
						sx={iconBtnStyles}
					>
						Edit
					</Button>
					<Button
						disabled={disabled}
						onClick={() => {
							onDelete && onDelete(update.id!, update.title);
						}}
						startIcon={<DeleteOutline />}
						sx={iconBtnStyles}
					>
						Delete
					</Button>
				</Box>
			)}
		</Box>
	);
};

export default UpdateRow;
