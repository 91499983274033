import { useEffect, useState } from 'react';
import { DropResult } from 'react-beautiful-dnd';
import { Page, Template, TemplateInstance } from 'types/types';
import { useMutation, useQuery } from 'urql';
import {
	createTemplateInstanceMutation,
	removeTemplateInstanceMutation,
	updateTemplateInstanceMutation,
} from '../../../../api/mutation/template.mutation';
import { templateInstancesByPageQuery } from '../../../../api/query/template.queries';

const sortTemplateInstances = (templInstances: Array<TemplateInstance>) => {
	return [...templInstances].sort(
		(a: TemplateInstance, b: TemplateInstance) => a.ordinal - b.ordinal,
	);
};

const useTemplateInstance = (pageId: number) => {
	const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
	const [templateInstances, setTemplateInstances] = useState<
		TemplateInstance[]
	>([]);
	const [pickerOpen, setPickerOpen] = useState<string>('');

	const [, createTemplateInstance] = useMutation(
		createTemplateInstanceMutation,
	);
	const [, updateTemplateInstance] = useMutation(
		updateTemplateInstanceMutation,
	);
	const [, removeTemplateInstance] = useMutation(
		removeTemplateInstanceMutation,
	);

	const [{ data }] = useQuery({
		query: templateInstancesByPageQuery,
		variables: { pageId },
		pause: templateInstances && templateInstances?.length > 0,
	});

	useEffect(() => {
		if (data?.templateInstancesByPage)
			setTemplateInstances(
				sortTemplateInstances(data?.templateInstancesByPage),
			);
	}, [data?.templateInstancesByPage]);

	useEffect(() => {
		// @ts-ignore null -> to initiate loading
		setTemplateInstances(null);
	}, [pageId]);

	const handleDragEnd = async (result: DropResult) => {
		if (!result.destination) {
			return;
		}
		const { index: destinationIndex } = result.destination;
		const updatedTemplateInstances = [...templateInstances];

		// reorder in array
		const moved = updatedTemplateInstances.splice(result.source.index, 1)[0];
		updatedTemplateInstances.splice(destinationIndex, 0, moved);

		const orderedUpdatedTemplateInstances = updatedTemplateInstances.map(
			(ti, index) => {
				return { ...ti, ordinal: index + 1 };
			},
		);

		setTemplateInstances(orderedUpdatedTemplateInstances);

		for (let index = 0; index < updatedTemplateInstances.length; index++) {
			const ti = { ...updatedTemplateInstances[index] };
			ti.ordinal = index + 1;

			await updateTemplateInstance({
				existingTemplateInstanceData: {
					id: ti.id,
					ordinal: ti.ordinal,
				},
			});
		}
	};

	const handleCreateTemplateInstance = async (
		template: Template,
		ordinal?: number,
	) => {
		const res = await createTemplateInstance({
			newTemplateInstanceData: {
				pageId: pageId,
				templateId: template.id,
				ordinal,
			},
		});

		if (res.error) return;

		if (templateInstances) {
			let updatedTemplateInstances = [...templateInstances];

			if (ordinal) {
				updatedTemplateInstances = updatedTemplateInstances.map((TI) => {
					const updatedTI = { ...TI };
					if (ordinal <= updatedTI.ordinal) {
						updatedTI.ordinal += 1;
					}

					return updatedTI;
				});
			}

			updatedTemplateInstances.push({
				id: res.data?.createTemplateInstance?.id,
				page: { id: pageId } as Page,
				template,
				ordinal: ordinal || 1,
				moduleInstances: [],
			});

			setTemplateInstances(sortTemplateInstances(updatedTemplateInstances));
		}
	};

	const handleDeleteTemplateInstance = async (templateID: number) => {
		const res = await removeTemplateInstance({ id: templateID });
		if (res.error) return;

		const ordinal = templateInstances?.find(
			(TI) => TI.id === templateID,
		)?.ordinal;
		let filteredTIs = templateInstances?.filter((TI) => TI.id !== templateID);

		if (ordinal) {
			filteredTIs = filteredTIs.map((TI) => {
				const updatedTI = { ...TI };
				if (ordinal < updatedTI.ordinal) {
					updatedTI.ordinal -= 1;
				}

				return updatedTI;
			});
		}

		setTemplateInstances(filteredTIs);
	};

	return {
		anchorEl,
		setAnchorEl,
		pageId,
		handleDragEnd,
		handleCreateTemplateInstance,
		handleDeleteTemplateInstance,
		templateInstances,
		setTemplateInstances,
		pickerOpen,
		setPickerOpen,
	};
};

export default useTemplateInstance;
