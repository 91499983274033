import { Button, ButtonProps, styled } from '@mui/material';

const OutlinedButton = styled(Button)<ButtonProps>(({ theme }) => ({
	color: theme.customPalette.primaryColors.sky[1],
	backgroundColor: theme.customPalette.basicColors.light,
	borderColor: theme.customPalette.primaryColors.sky[1],
	'&.Mui-disabled': {
		color: theme.customPalette.systemColors.disabled,
		borderColor: theme.customPalette.systemColors.disabled,
	},
}));

export default OutlinedButton;
