import { FC } from 'react';
import * as Yup from 'yup';
import { Field, Form, Formik } from 'formik';
import { TextField } from 'formik-mui';
import { Box, Grid, Paper, Typography, useTheme } from '@mui/material';
import DropDownField from 'components/common/DropDownField';
import { useUserContext } from 'providers/UserProvider';

interface ProjectUserInviteFormProps {
	onUserUpdate: (user: any) => void;
	selectedUser?: any;
	onClose: () => void;
}

export const EditUserForm: FC<ProjectUserInviteFormProps> = (props) => {
	const { onUserUpdate, selectedUser } = props;
	const theme = useTheme();
	const { user } = useUserContext();

	return (
		<Formik
			validationSchema={Yup.object({
				firstName: Yup.string()
					.max(15, 'Must be 15 characters or less')
					.required('Required'),
				lastName: Yup.string()
					.max(20, 'Must be 20 characters or less')
					.required('Required'),
				email: Yup.string().email('Invalid email address').required('Required'),
			})}
			initialValues={{
				firstName: selectedUser.firstName,
				lastName: selectedUser.lastName,
				email: selectedUser.email,
				userType: selectedUser.userType,
			}}
			onSubmit={(values, { setSubmitting }) => {
				onUserUpdate({
					firstName: values.firstName,
					lastName: values.lastName,
					email: values.email,
					userType: values.userType,
					id: selectedUser.id,
				});
				props.onClose();
				setSubmitting(false);
			}}
		>
			{({ values }) => (
				<Form id="edit-user-form">
					<Box sx={{ width: '100%' }}>
						<Paper elevation={2} sx={{ padding: 2, marginTop: 2 }}>
							<Grid mt={1} container spacing={1} rowGap={2} columnGap={1}>
								<Grid item sm={5}>
									<Field
										component={TextField}
										label="First Name"
										name="firstName"
										id="firstName"
										variant="outlined"
										size="small"
									/>
								</Grid>
								<Grid item sm={6}>
									<Field
										component={TextField}
										label="Last Name"
										name="lastName"
										id="lastName"
										variant="outlined"
										size="small"
									/>
								</Grid>
								<Grid item sm={6}>
									<Field
										component={TextField}
										label="Email"
										name="email"
										id="email"
										variant="outlined"
										size="small"
									/>
								</Grid>
								<Grid item sm={6}>
									{selectedUser.userType === 'public' ? (
										<Typography
											ml={0.2}
											bgcolor={theme.customPalette.primaryColors.sky[1]}
											borderRadius={4}
											color={theme.customPalette.basicColors.light}
											width="max-content"
											p="4px 12px"
											fontSize={'14px'}
											sx={{
												fontSize: '14px',
												fontWeight: 'bold',
												letterSpacing: '.75px',
											}}
										>
											Public
										</Typography>
									) : (
										(user?.userType === 'internal' ||
											user?.userType === 'developer') && (
											<Field
												component={DropDownField}
												label="Role"
												name="userType"
												id="userType"
												variant="outlined"
												defaultValue={selectedUser.userType}
												items={[
													{ name: 'internal', title: 'internal' },
													{ name: 'client', title: 'client' },
												]}
											/>
										)
									)}
								</Grid>
							</Grid>
						</Paper>
					</Box>
				</Form>
			)}
		</Formik>
	);
};

export default EditUserForm;
