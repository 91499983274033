import { useState } from 'react';
import { InputLabel } from '@mui/material';
import './styles.css';
import RegularTextInput from '../styled/RegularTextInput';
interface AutoCompleteProps {
	onChangeText: (val: string) => void;
	suggestions: string[];
	groupName?: string;
	sx?: any;
}

const AutoComplete = ({
	suggestions,
	groupName,
	onChangeText,
	sx,
}: AutoCompleteProps) => {
	const [filteredSuggestions, setFilteredSuggestions] = useState<string[]>([]);
	const [activeSuggestionIndex, setActiveSuggestionIndex] = useState(0);
	const [showSuggestions, setShowSuggestions] = useState(false);
	const [input, setInput] = useState(groupName || '');

	const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const userInput = e.target.value;

		// Filter our suggestions that don't contain the user's input
		const unLinked = suggestions.filter(
			(suggestion: any) =>
				suggestion.toLowerCase().indexOf(userInput.toLowerCase()) > -1,
		);
		onChangeText(e.target.value);
		setInput(e.target.value);
		setFilteredSuggestions(unLinked);
		setActiveSuggestionIndex(0);
		setShowSuggestions(true);
	};

	const onClick = (e: any) => {
		setFilteredSuggestions([]);
		setInput(e.target.innerText);
		setActiveSuggestionIndex(0);
		setShowSuggestions(false);
		onChangeText(e.target.innerText);
	};

	const onKeyDown = (e: any) => {
		// User pressed the enter key
		if (e.keyCode === 13) {
			setInput(filteredSuggestions[activeSuggestionIndex]);
			setActiveSuggestionIndex(0);
			setShowSuggestions(false);
			onChangeText(filteredSuggestions[activeSuggestionIndex]);
		}
		// User pressed the up arrow
		else if (e.keyCode === 38) {
			if (activeSuggestionIndex === 0) {
				return;
			}

			setActiveSuggestionIndex(activeSuggestionIndex - 1);
		}
		// User pressed the down arrow
		else if (e.keyCode === 40) {
			if (activeSuggestionIndex - 1 === filteredSuggestions.length) {
				return;
			}

			setActiveSuggestionIndex(activeSuggestionIndex + 1);
		}
	};

	const SuggestionsListComponent = () => {
		return filteredSuggestions.length ? (
			<ul className="suggestions">
				{filteredSuggestions.map((suggestion, index) => {
					let className;

					// Flag the active suggestion with a class
					if (index === activeSuggestionIndex) {
						className = 'suggestion-active';
					}

					return (
						<li className={className} key={suggestion} onClick={onClick}>
							{suggestion}
						</li>
					);
				})}
			</ul>
		) : (
			<div className="no-suggestions">
				<em>sorry no suggestions</em>
			</div>
		);
	};

	return (
		<>
			<InputLabel style={{ marginTop: 10 }} id="groupName">
				Group Name
			</InputLabel>

			<RegularTextInput
				size="small"
				type="text"
				onChange={onChange}
				onKeyDown={onKeyDown}
				value={input}
				sx={sx}
			/>
			{showSuggestions && input && <SuggestionsListComponent />}
		</>
	);
};

export default AutoComplete;
