import { Box, Switch, Typography } from '@mui/material';
import { useModuleSaveData } from 'hooks/useModuleSaveData';
import { FC, useEffect, useState } from 'react';
import ModuleInstanceData from 'types/moduleInstanceData';

const SubscribeBoxModule: FC<ModuleInstanceData> = (props) => {
	const { content, saveDataRef, config } = props;
	const { updateModuleSaveData } = useModuleSaveData(saveDataRef);
	const [fullBleed, setFullBleed] = useState(content?.fullBleed);

	useEffect(() => {
		const newData = { content: { fullBleed }, config };
		updateModuleSaveData(newData);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [updateModuleSaveData, config]);

	return (
		<Box p={1} pt={0}>
			<Box display="flex" alignItems="center" gap={2}>
				<Typography fontWeight="bold">Display Full Bleed</Typography>
				<Switch
					checked={fullBleed}
					onChange={() => setFullBleed(!fullBleed)}
				/>
			</Box>
		</Box>
	);
};

export default SubscribeBoxModule;
