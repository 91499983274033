import { useTheme } from '@mui/material';
import { FC, CSSProperties } from 'react';

interface ShortResponseIconProps {
	style?: CSSProperties;
	size?: number;
	color?: string;
}

const ShortResponseIcon: FC<ShortResponseIconProps> = (props) => {
	const theme = useTheme();
	const {
		style,
		size = 24,
		color = theme.customPalette.basicColors.dark,
	} = props;

	return (
		<svg
			style={style}
			width={size}
			height={size}
			viewBox="0 0 18 18"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M5.01562 3.98193C4.46334 3.98193 4.01562 4.43285 4.01562 4.98513C4.01562 5.53742 4.46334 5.98833 5.01563 5.98833H12.9659C13.5182 5.98833 13.9659 5.53742 13.9659 4.98513C13.9659 4.43285 13.5182 3.98193 12.9659 3.98193H5.01562Z"
				fill={color}
			/>
			<path
				d="M4.01562 9.00003C4.01562 8.44774 4.46334 7.99683 5.01562 7.99683H12.9659C13.5182 7.99683 13.9659 8.44774 13.9659 9.00003C13.9659 9.55231 13.5182 10.0032 12.9659 10.0032H5.01563C4.46334 10.0032 4.01562 9.55231 4.01562 9.00003Z"
				fill={color}
			/>
			<path
				d="M5.0249 12.0122C4.47262 12.0122 4.0249 12.4631 4.0249 13.0154C4.0249 13.5677 4.47262 14.0186 5.0249 14.0186H12.9752C13.5275 14.0186 13.9752 13.5677 13.9752 13.0154C13.9752 12.4631 13.5275 12.0122 12.9752 12.0122H5.0249Z"
				fill={color}
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M0 3C0 1.34315 1.34315 0 3 0H15C16.6569 0 18 1.34315 18 3V15C18 16.6569 16.6569 18 15 18H3C1.34315 18 0 16.6569 0 15V3ZM3 2H15C15.5523 2 16 2.44772 16 3V15C16 15.5523 15.5523 16 15 16H3C2.44772 16 2 15.5523 2 15V3C2 2.44772 2.44772 2 3 2Z"
				fill={color}
			/>
		</svg>
	);
};

export default ShortResponseIcon;
