import { styled } from '@mui/material';

const AutocompleteRenderOption = styled('span')(({ theme }) => ({
	padding: '6px 16px',
	color: theme.customPalette.textColors.dark,
	'&:hover': {
		backgroundColor: `${theme.customPalette.primaryColors.grassGreen[5]} , !important`,
	},
}));

export default AutocompleteRenderOption;
