import { FC } from 'react';
import { Box, IconButton, Typography } from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import TemplateIcon from './TemplateIcon';
import templates, { UpdateTemplate } from './templates';
import useProject from '../editProject/useProject';

interface UpdateTemplatesProps {
	projectId: number;
	onClose: () => void;
	onSelect: (template: UpdateTemplate) => void;
}

const UpdateTemplates: FC<UpdateTemplatesProps> = (props) => {
	const { projectId, onClose, onSelect } = props;
	const { project } = useProject(projectId);

	return (
		<Box maxWidth={702} mb={4}>
			<Box display="flex" alignItems="center" justifyContent="space-between">
				<Typography fontWeight="800" fontSize={20}>
					Create from Template
				</Typography>
				<IconButton onClick={onClose} sx={{ mr: -1 }}>
					<CloseIcon />
				</IconButton>
			</Box>

			<Box
				display="flex"
				alignItems="center"
				gap={2}
				flexWrap="wrap"
				justifyContent="center"
				mt={3}
			>
				{templates(project?.name).map((template) => (
					<TemplateIcon
						key={template.name}
						Icon={<template.Icon />}
						name={template.name}
						onSelect={() => onSelect({ ...template, id: 0 } as UpdateTemplate)}
					/>
				))}
			</Box>
		</Box>
	);
};

export default UpdateTemplates;
