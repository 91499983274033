import { gql } from 'urql';

export const createInfoAndPlanMutation = gql`
  mutation createInformationPlan(
    $newInformationPlanData: NewInformationPlanInput!
  ) {
    createInformationPlan(newInformationPlanData: $newInformationPlanData) {
      id
    }
  }
`;

export const updateInfoAndPlanMutation = gql`
  mutation updateInformationPlan(
    $existingInformationPlanData: ExistingInformationPlanInput!
  ) {
    updateInformationPlan(
      existingInformationPlanData: $existingInformationPlanData
    ) {
      id
    }
  }
`;

export const removeInfoAndPlanMutation = gql`
  mutation removeInformationPlan($id: Int!) {
    removeInformationPlan(id: $id)
  }
`;

export const reorderInfoAndPlanMutation = gql`
  mutation reorderInformationPlans(
    $projectId: Int!
    $sourceIndex: Int!
    $destinationIndex: Int!
  ) {
    reorderInformationPlans(
      projectId: $projectId
      sourceIndex: $sourceIndex
      destinationIndex: $destinationIndex
    )
  }
`;
