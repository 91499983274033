import {
	FormControl,
	FormHelperText,
	InputLabel,
	MenuItem,
} from '@mui/material';
import RegularTextInput from 'components/common/styled/RegularTextInput';
import SelectInput from 'components/common/styled/SelectInput';
import { Field, FormikTouched, FormikErrors } from 'formik';
import { Cell } from 'styled-css-grid';
import { ProjectPhaseType } from 'types/projectPhase.type';
import { projectPhases } from 'utility/projectPhase';
import { unitedStates, canadaStates } from '../../common/States';
import React from 'react';

interface PropsType {
	values: {
		name: string;
		address1: string;
		city: string;
		state: string;
		country: string;
		postalCode: string;
		projectPhase: ProjectPhaseType;
	};
	touched: FormikTouched<{
		name: string;
		address1: string;
		city: string;
		state: string;
		country: string;
		postalCode: string;
		projectPhase: ProjectPhaseType;
	}>;
	errors: FormikErrors<{
		name: string;
		address1: string;
		city: string;
		state: string;
		country: string;
		postalCode: string;
		projectPhase: ProjectPhaseType;
	}>;
	handleChange: {
		(e: React.ChangeEvent<any>): void;
		<T = string | React.ChangeEvent<any>>(
			field: T,
		): T extends React.ChangeEvent<any>
			? void
			: (e: string | React.ChangeEvent<any>) => void;
	};
	handleBlur: {
		(e: React.FocusEvent<any>): void;
		<T = any>(fieldOrEvent: T): T extends string ? (e: any) => void : void;
	};
	disabled?: boolean;
}

export function ProjectAddress({
	values,
	touched,
	errors,
	handleChange,
	handleBlur,
	disabled,
}: PropsType) {
	const States = values.country === 'CA' ? canadaStates : unitedStates;

	return (
		<>
			<Cell width={2}>
				<InputLabel htmlFor="address1">Location</InputLabel>
				<Field
					id="address1"
					name="address1"
					size="small"
					value={values.address1}
					disabled={disabled}
					as={RegularTextInput}
					error={Boolean(touched.address1 && errors.address1)}
					helperText={touched.address1 ? errors.address1 : ''}
					fullWidth
				/>
			</Cell>
			<Cell>
				<InputLabel htmlFor="city">City</InputLabel>
				<Field
					id="city"
					name="city"
					size="small"
					value={values.city}
					disabled={disabled}
					as={RegularTextInput}
					error={Boolean(touched.city && errors.city)}
					helperText={touched.city ? errors.city : ''}
					fullWidth
				/>
			</Cell>
			<Cell>
				<InputLabel htmlFor="state">
					{values.country === 'CA' ? 'Province' : 'State'}
				</InputLabel>
				<FormControl fullWidth error={Boolean(touched.state && errors.state)}>
					<SelectInput
						id="state"
						name="state"
						value={values.state}
						onChange={handleChange}
						onBlur={handleBlur}
						size="small"
						disabled={disabled}
					>
						{States.map((state) => (
							<MenuItem key={state.abbreviation} value={state.abbreviation}>
								{state.name}
							</MenuItem>
						))}
					</SelectInput>
					{touched.state && errors.state && (
						<FormHelperText>
							{values.country === 'CA'
								? errors.state.replace('State', 'Province')
								: errors.state}
						</FormHelperText>
					)}
				</FormControl>
			</Cell>
			<Cell>
				<InputLabel htmlFor="postalCode">Postal Code</InputLabel>
				<Field
					id="postalCode"
					name="postalCode"
					size="small"
					value={values.postalCode}
					as={RegularTextInput}
					error={Boolean(touched.postalCode && errors.postalCode)}
					helperText={touched.postalCode ? errors.postalCode : ''}
					fullWidth
					disabled={disabled}
				/>
			</Cell>
			<Cell>
				<InputLabel htmlFor="country">Country</InputLabel>
				<FormControl
					fullWidth
					error={Boolean(touched.country && errors.country)}
				>
					<SelectInput
						id="country"
						name="country"
						value={values.country}
						onChange={handleChange}
						onBlur={handleBlur}
						size="small"
						disabled={disabled}
					>
						<MenuItem value="US">United States</MenuItem>
						<MenuItem value="CA">Canada</MenuItem>
					</SelectInput>
					{touched.country && errors.country && (
						<FormHelperText>{errors.country}</FormHelperText>
					)}
				</FormControl>
			</Cell>

			<Cell>
				<InputLabel htmlFor="projectPhase">Project Phase</InputLabel>
				<SelectInput
					id="projectPhase"
					name="projectPhase"
					value={values.projectPhase}
					labelId="projectPhase"
					onChange={handleChange}
					onBlur={handleBlur}
					fullWidth
					size="small"
					disabled={disabled}
				>
					{projectPhases.map((projectPhase) => (
						<MenuItem key={projectPhase.name} value={projectPhase.name}>
							{projectPhase.title}
						</MenuItem>
					))}
				</SelectInput>
			</Cell>
		</>
	);
}
