import { FC } from 'react';
import { Outlet, useOutletContext } from 'react-router-dom';
import { Box } from '@mui/material';
import { NotAuthorized } from '../components/common/NotAuthorized';
import useNumberParams from '../hooks/useNumberParams';
import { useUserContext } from '../providers/UserProvider';
import Loading from '../components/common/Loading';

type Predicate = 'and' | 'or';

interface RestrictedRouteProps {
	allowedRoleTypes?: string[];
	allowedUserTypes?: string[];
	predicate?: Predicate;
}

const defaultProps: RestrictedRouteProps = {
	predicate: 'or',
	allowedRoleTypes: [],
	allowedUserTypes: [],
};

const RestrictedRoute: FC<RestrictedRouteProps> = (props) => {
	const { user, isUserLoading } = useUserContext();
	const context = useOutletContext();
	const { allowedRoleTypes, allowedUserTypes, predicate } = props;
	const { projectId } = useNumberParams();

	const isAllowed = () => {
		let allowedByUserType,
			allowedByRoleType = false;

		if (allowedUserTypes!.length > 0) {
			allowedByUserType = allowedUserTypes!.includes(user?.userType as string);
		}
		if (allowedRoleTypes!.length > 0) {
			if (user?.projectDetails && projectId) {
				const projectDetail = user?.projectDetails?.find(
					(pd) => pd.projectId === projectId,
				);
				if (projectDetail) {
					allowedByRoleType = allowedRoleTypes!.includes(
						projectDetail.roleType,
					);
				}
			}
		}

		if (predicate === 'and') {
			return allowedByRoleType && allowedByUserType;
		} else {
			return allowedByRoleType || allowedByUserType;
		}
	};

	if (isUserLoading)
		return (
			<Box
				display="flex"
				justifyContent="center"
				alignItems="center"
				height="100%"
				width="100%"
			>
				<Loading />
			</Box>
		);

	return user && isAllowed() ? <Outlet context={context} /> : <NotAuthorized />;
};

RestrictedRoute.defaultProps = defaultProps;
export { RestrictedRoute };
