import { ChangeEvent, FC, useState } from 'react';
import {
	Box,
	Checkbox,
	FormControlLabel,
	FormGroup,
	InputLabel,
} from '@mui/material';
import RegularTextInput from 'components/common/styled/RegularTextInput';
import { validateUrl } from 'utility/string';
import TextButton from 'components/common/styled/TextButton';

interface EmbedContent {
	url: string;
	title: string;
	width: number;
	height: number;
	permissions: Permissions;
}

interface IframeUrlTabProps {
	embedInfo: EmbedContent;
	setEmbedInfo: (embedInfo: EmbedContent) => void;
}

interface Permissions {
	autoPlay: boolean;
	clipboardWrite: boolean;
	encryptedMedia: boolean;
	gyroscope: boolean;
	pictureInPicture: boolean;
	webShare: boolean;
}

const initPermissions = {
	autoPlay: true,
	clipboardWrite: true,
	encryptedMedia: true,
	gyroscope: true,
	pictureInPicture: true,
	webShare: true,
};

const permissionsArr: { key: keyof Permissions; label: string }[] = [
	{ key: 'autoPlay', label: 'Auto Play' },
	{ key: 'clipboardWrite', label: 'Clipboard Write' },
	{ key: 'encryptedMedia', label: 'Encrypted Media' },
	{ key: 'gyroscope', label: 'Gyroscope' },
	{ key: 'pictureInPicture', label: 'Picture In Picture' },
	{ key: 'webShare', label: 'Web Share' },
];

const IframeUrlTab: FC<IframeUrlTabProps> = (props) => {
	const { embedInfo, setEmbedInfo } = props;
	const [showAdvanceSettings, setShowAdvanceSettings] = useState(false);
	const [error, setError] = useState({
		url: '',
		title: '',
		width: '',
		height: '',
	});

	const iframeInfo: EmbedContent = {
		url: embedInfo?.url || '',
		title: embedInfo?.title || '',
		width: embedInfo?.width,
		height: embedInfo?.height,
		permissions: embedInfo?.permissions || initPermissions,
	};

	const handleChange = (
		value: string | boolean | number,
		property: keyof EmbedContent | keyof Permissions,
	) => {
		if (property === 'url') {
			if (
				!value ||
				(value as string).trim() === '' ||
				!validateUrl(value as string)
			)
				setError({ ...error, url: 'Invalid Url' });
			else setError({ ...error, url: '' });
		} else if (property === 'title') {
			if (!value || (value as string).trim() === '')
				setError({ ...error, title: 'Title must be set' });
			else setError({ ...error, title: '' });
		} else if (property === 'height' || property === 'width') {
			if (!!value && isNaN(Number(value as string))) {
				const errorObj =
					property === 'height'
						? { height: 'Height must be a number' }
						: { width: 'Width must be a number' };
				setError({ ...error, ...errorObj });
			} else if (value && value <= 0) {
				const errorObj =
					property === 'height'
						? { height: 'Height must be greater than zero' }
						: { width: 'Width must be greater than zero' };
				setError({ ...error, ...errorObj });
			} else setError({ ...error, ...{ width: '', height: '' } });
		}

		if (property in initPermissions)
			return setEmbedInfo({
				...iframeInfo,
				permissions: { ...iframeInfo.permissions, [property]: value },
			});

		setEmbedInfo({ ...iframeInfo, [property]: value });
	};

	return (
		<Box>
			<InputLabel id="title-label">Title</InputLabel>
			<RegularTextInput
				size="small"
				color="secondary"
				type="title"
				sx={{ mt: 1, mb: 1, width: 318 }}
				aria-describedby="title-error-text"
				error={!!error.title}
				helperText={error.title}
				value={iframeInfo?.title}
				FormHelperTextProps={{ error: !!error.title }}
				onChange={(e) => handleChange(e.target.value, 'title')}
			/>
			<InputLabel id="url-label">Url</InputLabel>
			<RegularTextInput
				size="small"
				color="secondary"
				type="url"
				sx={{ mt: 1, mb: 1, width: 318 }}
				aria-describedby="url-error-text"
				error={!!error.url}
				helperText={error.url}
				value={iframeInfo?.url}
				FormHelperTextProps={{ error: !!error.url }}
				onChange={(e) => handleChange(e.target.value, 'url')}
			/>

			<TextButton
				sx={{ display: 'block', mt: 2, textTransform: 'none' }}
				onClick={() => setShowAdvanceSettings(!showAdvanceSettings)}
			>
				{`${!showAdvanceSettings ? 'Show' : 'Hide'}`} advance settings
			</TextButton>
			{showAdvanceSettings && (
				<Box display="flex" justifyContent="space-between" mt={1}>
					<div>
						<InputLabel id="permission-label">Permissions</InputLabel>
						<FormGroup>
							{permissionsArr.map(({ key, label }) => (
								<FormControlLabel
									control={
										<Checkbox
											checked={iframeInfo?.permissions?.[key]}
											onChange={(e: ChangeEvent<HTMLInputElement>) =>
												handleChange(e.target.checked, key)
											}
										/>
									}
									label={label}
								/>
							))}
						</FormGroup>
					</div>

					<div>
						<InputLabel id="height-label">Height</InputLabel>
						<RegularTextInput
							size="small"
							color="secondary"
							type="text"
							defaultValue="100"
							sx={{ mt: 1, mb: 1, width: 100 }}
							value={iframeInfo?.height}
							aria-describedby="height-error-text"
							error={!!error.height}
							helperText={error.height}
							onChange={(e) => handleChange(e.target.value, 'height')}
						/>
						<InputLabel id="width-label">Width</InputLabel>
						<RegularTextInput
							size="small"
							color="secondary"
							type="text"
							defaultValue="100"
							sx={{ mt: 1, mb: 1, width: 100 }}
							value={iframeInfo?.width}
							aria-describedby="width-error-text"
							error={!!error.width}
							helperText={error.width}
							onChange={(e) => handleChange(e.target.value, 'width')}
						/>
					</div>
				</Box>
			)}
		</Box>
	);
};

export default IframeUrlTab;
