import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Auth0ProviderWithHistory } from './providers/Auth0Provider';
import { EnvProvider } from './providers/EnvProvider';

const container = document.getElementById('root') as HTMLElement;
const root = createRoot(container);

root.render(
	<React.StrictMode>
		<EnvProvider>
			<Auth0ProviderWithHistory>
				<BrowserRouter>
					<DndProvider backend={HTML5Backend}>{App}</DndProvider>
				</BrowserRouter>
			</Auth0ProviderWithHistory>
		</EnvProvider>
	</React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
