import React, { FC, useEffect } from 'react';
import ModuleInstanceData, {
	ModuleInstanceComponentSaveData,
} from '../../types/moduleInstanceData';
import { useModuleSaveData } from '../../hooks/useModuleSaveData';
import { TextEditor } from 'components/common/TextEditor';
import { toPlainText } from '../common/TextEditor/helpers';

export const FreeContentModule: FC<ModuleInstanceData> = (props) => {
	const { content, saveDataRef, config } = props,
		{ updateModuleSaveData } = useModuleSaveData(saveDataRef),
		[editorState, setEditorState] = React.useState(content?.richText);

	useEffect(() => {
		const parseContent = (): ModuleInstanceComponentSaveData => {
			let contentState = editorState;
			return {
				content: {
					plainText: toPlainText(contentState),
					richText: contentState,
				},
				config: config,
			};
		};
		updateModuleSaveData(parseContent());
	}, [editorState, updateModuleSaveData, config]);

	return (
		<TextEditor editorState={editorState} setEditorState={setEditorState} />
	);
};
