import React, { ReactNode, FC, MouseEvent } from 'react';
import {
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	SxProps,
	IconButton,
} from '@mui/material';
import TextButton from './styled/TextButton';
import { Breakpoint } from '@mui/system';
import { Close as CloseIcon } from '@mui/icons-material';

interface AppDialogProps {
	open: boolean;
	title?: string;
	titleStyle?: SxProps;
	LeftButton?: ReactNode;
	RightButton?: ReactNode;
	handleLeftButton?: (
		e: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>,
	) => void;
	handleRightButton?: (
		e: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>,
	) => void;
	handleClose?: (
		e: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>,
	) => void;
	leftButtonLabel?: string;
	rightButtonLabel?: string;
	style?: SxProps;
	fullScreen?: boolean;
	fullWidth?: boolean;
	maxWidth?: Breakpoint | false;
	buttonContainerStyle?: SxProps;
	showCloseIcon?: boolean;
	children?: React.ReactNode;
}

const AppDialog: FC<AppDialogProps> = (props) => {
	const {
		title,
		titleStyle,
		open,
		style,
		LeftButton,
		RightButton,
		handleLeftButton,
		handleRightButton,
		leftButtonLabel,
		rightButtonLabel,
		fullScreen,
		fullWidth,
		maxWidth = 'lg',
		buttonContainerStyle,
		showCloseIcon,
		handleClose,
		children, // this is the content of the dialog
	} = props;

	return (
		<div>
			<Dialog
				open={open}
				aria-labelledby="dialog-title"
				aria-describedby="dialog-content"
				maxWidth={maxWidth}
				fullWidth={fullWidth}
				sx={{
					...style,
					'& .MuiDialogContent-root': fullScreen
						? { padding: 0, overflowY: 'hidden' }
						: {},
				}}
				fullScreen={fullScreen}
				PaperProps={{
					sx: { padding: '5px', borderRadius: 6, ...style },
				}}
			>
				{showCloseIcon && (
					<IconButton
						sx={{
							position: 'absolute',
							top: '22px',
							right: '22px',
							width: '24px',
							height: '24px',
						}}
						onClick={handleClose}
					>
						<CloseIcon />
					</IconButton>
				)}
				<DialogTitle
					id="dialog-title"
					sx={{ fontWeight: '900', paddingBottom: '0px', ...titleStyle }}
				>
					{title}
				</DialogTitle>
				<DialogContent>{children}</DialogContent>
				<DialogActions sx={buttonContainerStyle}>
					{LeftButton ||
						(leftButtonLabel && (
							<TextButton onClick={handleLeftButton}>
								{leftButtonLabel || 'Cancel'}
							</TextButton>
						))}
					{RightButton ||
						(rightButtonLabel && (
							<TextButton onClick={handleRightButton}>
								{rightButtonLabel || 'Confirm'}
							</TextButton>
						))}
				</DialogActions>
			</Dialog>
		</div>
	);
};

export default AppDialog;
