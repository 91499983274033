import { gql } from 'urql';

export const createPublicBenefitMutation = ( s:string ) => gql`
    mutation createPublicBenefit($newPublicBenefitData: NewPublicBenefitInput!){
        createPublicBenefit(newPublicBenefitData: $newPublicBenefitData) {
            ${ s }
        }
    }
`;

export const removePublicBenefitMutation = gql`
    mutation removePublicBenefit($id: Int!){
        removePublicBenefit(id: $id)
    }
`;

export const updatePublicBenefitMutation = gql`
    mutation updatePublicBenefit($existingPublicBenefitData: ExistingPublicBenefitInput!) {
        updatePublicBenefit(existingPublicBenefitData: $existingPublicBenefitData){
            id
        }
    }
`;

export const reorderPublicBenefitsMutation = gql`
    mutation reorderPublicBenefits($projectId: Int!, $sourceIndex: Int!, $destinationIndex: Int!){
        reorderPublicBenefits(projectId: $projectId, sourceIndex: $sourceIndex, destinationIndex: $destinationIndex)
    }
`;
