import { gql } from 'urql';

export const questionGroupsByProjectQuery = gql`
	query ($projectId: Int!, $active: Boolean) {
		questionGroupsByProject(projectId: $projectId, active: $active) {
			id
			themeName
			status
			ordinal
			questions {
				id
				questionText
				type
				archived
				publicResponses
				ordinal
				deleted
				hidden
				image {
					id
					fileName
					imageUrl
					altTag
					description
					primaryImage
				}
				imageDescription
				imageScale
				boundingBoxPosition
				questionChoices {
					id
					choiceText
					choiceType
					ordinal
					smsChoiceNumber
				}
				questionAnswerCount
				created
				responsePhoneNumber
				responsePhoneNumberType
				questionAnswers {
					questionChoiceId
					questionChoiceText
					questionId
					rank
					createdBy
					created
					createdByUser {
						firstName
						email
					}
				}
			}
		}
	}
`;
