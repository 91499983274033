import { FC } from 'react';
import { useQuery } from 'urql';
import { TemplatePicker } from './TemplatePicker';
import Loading from '../../common/Loading';
import { Template } from '../../../types/types';

interface TemplatePickerContainerProps {
	anchorEl: any;
	onPickerClose: VoidFunction;
	onTemplateSelected: (template: Template) => void;
	pickerOpen: boolean;
}

export const TemplatePickerContainer: FC<TemplatePickerContainerProps> = (
	props,
) => {
	const { onTemplateSelected, anchorEl, onPickerClose, pickerOpen } = props;

	const templateQuery = `
        query{
            templates {
                id
                name
                ordinal
                layout {
                    gridTemplateColumns
                    gridTemplateRows
                    gridTemplateSections {
                        gridColumn
                        gridRow
                        sectionId
                    }
                }
            }
        }`;

	const [{ data }] = useQuery({
		query: templateQuery,
	});

	if (!data?.templates) return <Loading />;

	return (
		<TemplatePicker
			templates={data.templates}
			handleSelect={onTemplateSelected}
			anchorEl={anchorEl}
			onPickerClose={onPickerClose}
			open={pickerOpen}
		/>
	);
};
