import { AppState, Auth0Provider } from '@auth0/auth0-react';
import { PropsWithChildren } from 'react';
import { useEnv } from './EnvProvider';
import { useLastRedirectURL } from '../hooks/useLastRedirectUrl';

enum AUTH_ZERO_ERROR_FLAGS {
	LOGIN_REQUIRED = 'login_required',
	CONSENT_REQUIRED = 'consent_required',
}

const Auth0ProviderWithHistory = ({
	children,
}: PropsWithChildren<any>): JSX.Element | null => {
	const { auth0Domain, auth0ClientId, auth0Audience, baseUrl } = useEnv();
	const { clearLastRedirectURL, getLastRedirectURL } = useLastRedirectURL();

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const onRedirectCallback = (appState: AppState) => {
		try {
			const goto = getLastRedirectURL();

			clearLastRedirectURL();
			if (goto) {
				window.location.href = appState?.returnTo || goto;
			}
		} catch (e: any) {
			console.log(e);
		}
	};

	if (!(auth0Domain && auth0ClientId && auth0Audience)) {
		return null;
	}

	return (
		<Auth0Provider
			domain={ auth0Domain }
			clientId={ auth0ClientId }
			audience={ auth0Audience }
			redirectUri={ baseUrl }
			scope='openid offline_access email'
			// onRedirectCallback={ onRedirectCallback }
		>
			{children}
		</Auth0Provider>
	);
};

export { Auth0ProviderWithHistory, AUTH_ZERO_ERROR_FLAGS };
