import { FC } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { DropResult } from 'react-beautiful-dnd';
import {
	CommunityModuleInstance,
	usePausedContext,
} from '@coUrbanize/community-modules';
import { ModuleInstance, Project } from '../../../types/types';
import ModuleInstanceComponent from '../editorCanvas/ModuleInstance';
import DragDropContainer from '../../common/DragDropContainer';
import User from 'types/user';
import EmptyModuleAlert from './EmptyModuleAlert';
import { useQuery } from 'urql';

interface ModuleInstanceListProps {
	templateSectionId: number;
	handleDragEnd: (res: DropResult, tID: number) => void;
	modulesBySection: ModuleInstance[];
	handleUpdateModule: (id: number, newContent: any) => void;
	handleDeleteModuleInstance: (tID: number) => void;
	user: User | null;
	project: Project;
}

export const ModuleInstanceList: FC<ModuleInstanceListProps> = (props) => {
	const {
		templateSectionId,
		handleDragEnd,
		modulesBySection,
		handleUpdateModule,
		handleDeleteModuleInstance,
		user,
		project,
	} = props;

	const { getAccessTokenSilently: getToken } = useAuth0();
	const { isPaused } = usePausedContext();
	if (!modulesBySection.length) return <div style={{ minHeight: 100 }} />;

	const emptyPBorTimeline = (module: ModuleInstance) => {
		if (
			['public benefit', 'timeline'].includes(
				module.moduleType.name.trim().toLowerCase(),
			) &&
			!module.content.info
		)
			return true;
	};

	return (
		<DragDropContainer
			droppableId={templateSectionId}
			items={modulesBySection}
			keyExtractor={(module) => module.id}
			handleDragEnd={(res) => handleDragEnd(res, templateSectionId)}
			isDragDisabled={isPaused}
			DraggableComponent={(module, handle) => (
				<ModuleInstanceComponent
					moduleInstance={module}
					handle={handle}
					handleDeleteModuleInstance={handleDeleteModuleInstance}
					handleUpdateModule={handleUpdateModule}
					project={project}
					getToken={getToken}
					previewComponent={
						<div style={{ textAlign: 'left', padding: '8px 8px 0 8px' }}>
							{(module?.content === null ||
								module?.content?.data?.length === 0 ||
								emptyPBorTimeline(module)) && (
									<EmptyModuleAlert module={module} />
								)}

							<CommunityModuleInstance
								config={module.config}
								content={module.content}
								type={module.moduleType.name}
								user={user}
								useMutation={() => [null, () => ({ error: null })]}
								useQuery={useQuery}
								isAdminRendering={true}
								apiUrl={process.env.REACT_APP_API_URI}
							/>
						</div>
					}
				/>
			)}
		/>
	);
};
