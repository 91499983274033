import * as React from 'react';
import { FC } from 'react';
import * as yup from 'yup';
import { Field, Formik } from 'formik';
import { Cell, Grid } from 'styled-css-grid';
import { Card, InputLabel } from '@mui/material';
import { CreateOutlined } from '@mui/icons-material';
import { usePausedContext } from '@coUrbanize/community-modules';
import RegularTextInput from 'components/common/styled/RegularTextInput';
import RegularButton from 'components/common/styled/RegularButton';
import { Project } from '../../../types/types';
import { ProjectAddress } from './ProjectAddress';
import { ProjectPhaseType } from 'types/projectPhase.type';
import { FormControlLabel, Switch } from '@mui/material';

const validationSchema = yup.object({
	name: yup
		.string()
		.required('Project Name is required')
		.min(3, 'Must be at least 3 characters')
		.max(75, 'Cannot be over 75 characters'),
	address1: yup.string().required('Location is required'),
	state: yup.string().required('State is required'),
	country: yup.string().required('Country is required'),
	postalCode: yup.string().required('Postal Code is required'),
	projectPhase: yup.string().required('Project Phase is required'),
});

const PROJECT_NAME_CHARACTER_LIMIT = 75;

interface ProjectDetailsFormProps {
	initialValues: Project;
	onSubmitClick: (values: any) => void;
}

const ProjectDetailsForm: FC<ProjectDetailsFormProps> = (props) => {
	const { initialValues, onSubmitClick } = props;
	const { isPaused } = usePausedContext();

	return (
		<>
			<Cell>
				<Card
					sx={(theme) => ({
						padding: theme.spacing(3),
						display: 'block',
					})}
				>
					<Formik
						enableReinitialize
						validateOnChange={false}
						initialValues={{
							name: initialValues.name,
							displayNameHeader: initialValues.displayNameHeader ?? true,
							address1: initialValues.address1 ?? '',
							city: initialValues.city ?? '',
							state: initialValues.state ?? '',
							country: initialValues.country ?? 'US',
							postalCode: initialValues.postalCode ?? '',
							projectPhase:
								(initialValues.projectPhase as ProjectPhaseType) ??
								'community-review',
						}}
						validationSchema={validationSchema}
						onSubmit={(values, { setSubmitting }) => {
							const project: any = {
								id: initialValues.id!,
								name: values.name,
								displayNameHeader: values.displayNameHeader,
								address1: values.address1,
								city: values.city,
								state: values.state,
								country: values.country,
								postalCode: values.postalCode,
								projectPhase: values.projectPhase,
							};
							onSubmitClick(project);
							setSubmitting(false);
						}}
					>
						{({
							dirty,
							isValid,
							isSubmitting,
							errors,
							touched,
							values,
							handleChange,
							handleBlur,
							handleSubmit,
							setFieldValue,
						}) => {
							return (
								<form onSubmit={handleSubmit} noValidate autoComplete="off">
									<Grid columns={2}>
										<Cell width={2}>
											<InputLabel htmlFor="name">Project Name</InputLabel>
											<Field
												id="name"
												name="name"
												variant="outlined"
												size="small"
												disabled={isPaused}
												value={values.name}
												error={Boolean(touched.name && errors.name)}
												helperText={
													errors.name ||
													`${values.name.length}/${PROJECT_NAME_CHARACTER_LIMIT}`
												}
												InputLabelProps={{ shrink: true }}
												as={RegularTextInput}
												fullWidth
												autoFocus
											/>
										</Cell>

										<Cell
											width={2}
											style={{ marginLeft: 3, marginTop: -7, marginBottom: 5 }}
										>
											<FormControlLabel
												label="Remove from Header"
												control={
													<Switch
														name="displayNameHeader"
														onChange={() => {
															setFieldValue(
																'displayNameHeader',
																!values.displayNameHeader,
															);
														}}
														checked={!values.displayNameHeader}
													/>
												}
												labelPlacement="end"
											/>
										</Cell>

										<ProjectAddress
											values={{
												name: values.name,
												address1: values.address1,
												city: values.city,
												state: values.state,
												country: values.country,
												postalCode: values.postalCode,
												projectPhase: values.projectPhase,
											}}
											touched={touched}
											errors={errors}
											handleChange={handleChange}
											handleBlur={handleBlur}
											disabled={isPaused}
										/>

										<Cell width={2}>
											<RegularButton
												color="secondary"
												variant="contained"
												type="submit"
												disabled={
													isSubmitting || !(isValid && dirty) || !!isPaused
												}
												startIcon={<CreateOutlined />}
											>
												Save
											</RegularButton>
										</Cell>
									</Grid>
								</form>
							);
						}}
					</Formik>
				</Card>
			</Cell>
		</>
	);
};

export default ProjectDetailsForm;
