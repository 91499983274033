import { FC } from 'react';
import { Form, Formik } from 'formik';
import dayjs from 'dayjs';
import AppDialog from './Dialog';
import timelineSchema from '../schema/timelineFormSchema';
import { AnyVariables, OperationResult, useMutation } from 'urql';
import {
	createEventMutation,
	updateEventMutation,
} from '../../api/mutation/event.mutations';
import TimelineForm, {
	EventInfo,
	TimeLineFormFieldsEnum,
} from './TimelineForm';
import RegularButton from './styled/RegularButton';
import TextButton from './styled/TextButton';
import { Typography } from '@mui/material';

interface TimelineFormModalProps {
	open: boolean;
	onClose: () => void;
	event?: EventInfo;
	onSave?: (event: EventInfo) => void;
	timelineId: number;
}

type DateTypes = 'startDate' | 'startTime' | 'endDate' | 'endTime';

const TimelineFormModal: FC<TimelineFormModalProps> = (props) => {
	const { open, onClose, event, onSave, timelineId } = props;
	const [, createEvent] = useMutation(createEventMutation);
	const [, updateEvent] = useMutation(updateEventMutation);
	const {
		ID,
		NAME,
		DESCRIPTION,
		START_DATE,
		START_TIME,
		END_DATE,
		END_TIME,
		DISPLAY_DATE,
		MILESTONE_ICON,
		FILE,
	} = TimeLineFormFieldsEnum;
	const handleSubmit = async (event: EventInfo) => {
		const props: DateTypes[] = [START_DATE, START_TIME, END_DATE, END_TIME];
		props.forEach((prop: DateTypes) => {
			if (event[prop]) event[prop] = dayjs(event[prop]).format();
		});

		// --> save event to db/state
		let res: OperationResult<any, AnyVariables>;
		if (event.id) {
			res = await updateEvent({ existingEvent: event });
		} else {
			res = await createEvent({ newEvent: { ...event, timelineId } });
		}

		if (!res.error) {
			if (!event.id) event.id = res.data.createEvent.id;
			onSave && onSave(event);

			onClose();
		}
	};

	return (
		<Formik
			initialValues={
				{
					[ID]: event?.id,
					[NAME]: event?.name,
					[DESCRIPTION]: event?.description || '',
					[START_DATE]: event?.startDate || '',
					[START_TIME]: event?.startTime || '',
					[END_DATE]: event?.endDate || null,
					[END_TIME]: event?.endTime || null,
					[DISPLAY_DATE]: event?.displayDate,
					[MILESTONE_ICON]: event?.milestoneIcon,
					[FILE]: event?.file || { files: [] },
				} as EventInfo
			}
			validationSchema={timelineSchema}
			onSubmit={handleSubmit}
		>
			{({
				values,
				submitForm,
				setFieldValue,
				setFieldTouched,
				errors,
				touched,
			}) => (
				<Form>
					<AppDialog
						open={open}
						title="Timeline Event"
						RightButton={
							<RegularButton
								variant="contained"
								onClick={submitForm}
								autoFocus
								type="submit"
								sx={{
									textTransform: 'capitalize',
									fontSize: 16,
									mx: 1,
									width: 144,
								}}
							>
								Save event
							</RegularButton>
						}
						LeftButton={
							<TextButton
								variant="outlined"
								onClick={onClose}
								color="secondary"
								sx={{
									textTransform: 'capitalize',
									fontSize: 16,
									mx: 1,
									width: 144,
									height: 40,
									borderWidth: 2,
									borderColor: '#0076A0',
								}}
							>
								Cancel
							</TextButton>
						}
					>
						<Typography maxWidth={600} mt={1} mb={5} fontWeight="500">
							The Timeline should show both previous and upcoming project
							milestones, as well as important events. Use this tab to provide
							an overview of the entire process.
						</Typography>
						<TimelineForm
							setFieldValue={setFieldValue}
							setFieldTouched={setFieldTouched}
							event={values}
							touched={touched}
							errors={errors}
						/>
					</AppDialog>
				</Form>
			)}
		</Formik>
	);
};

export default TimelineFormModal;
