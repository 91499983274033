import React, { useEffect, useState } from 'react';
import AppDialog from 'components/common/Dialog';
import {
	Checkbox,
	Alert,
	FormControlLabel,
	FormGroup,
	Radio,
	RadioGroup,
	FormControl,
	Typography,
} from '@mui/material';
import useNumberParams from 'hooks/useNumberParams';
import { DynamicChildOption, ExportOption, initialOptions } from '../../../utility/exportAnalytics';
import { useAuth0 } from '@auth0/auth0-react';
import { LoadingButton } from '@mui/lab';
import { FileDownload } from '@mui/icons-material';
import { useSnackBar } from '../../../providers/SnackBarProvider';
import { AnalyticsExport } from '../../../types/types';
import { QuestionType } from '../questions/QuestionGroup';

export interface ExistingAnalyticsData {
	totalFeedback: number;
	totalVisitors: number;
	totalFollowers: number;
	positiveNeutralFollowers: number;
	ideasAddedToPlan: number;
	lettersOfSupport: number;
	engagementOverTime: [
		string | Date,
		number | string,
		number | string,
		number | string,
	][];
	positiveSentimentPercent: number;
	neutralSentimentPercent: number;
	negativeSentimentPercent: number;
}

interface ExportModalProps {
	open: boolean;
	setOpen: (fn: boolean) => void;
	exportData: AnalyticsExport | null;
	existingData: ExistingAnalyticsData | null;
}

enum ExportFormat {
	PDF = 'PDF',
	CSV = 'CSV',
}


export const ExportModal: React.FC<ExportModalProps> = (props) => {
	const { notify } = useSnackBar();
	const { open, setOpen, exportData, existingData } = props;

	const [options, setOptions] = useState<ExportOption[]>(initialOptions);
	const [exportFormat, setExportFormat] = useState<ExportFormat>(
		ExportFormat.CSV,
	);
	const [error, setError] = useState('');
	const [loadingRightButton, setLoadingRightButton] = useState(false);
	const { getAccessTokenSilently: getToken } = useAuth0();
	useEffect(() => {
		if (exportData) {
			const updatedOptions = initialOptions.map(option => {
				if (option.dynamicChildrenKey === DynamicChildOption.QUESTIONS) {
					return {
						...option,
						children: exportData.questions
							.filter(q => [QuestionType.LONG_ANSWER.toString(), QuestionType.SHORT_ANSWER.toString()].includes(q.type)) //comments are only for long and short response
							.map(q => ({
								key: q.id.toString(),
								title: q.questionText!,
								checked: true
						})),
					};
				} else if (option.dynamicChildrenKey === DynamicChildOption.FORMS) {
					return {
						...option,
						children: exportData.forms.map(form => ({
							key: form.id.toString(),
							title: form.formName,
							checked: true
						})),
					};
				} else if (option.dynamicChildrenKey === DynamicChildOption.POLLS) {
					return {
						...option,
						children: exportData.questions
							.filter(q => ![QuestionType.LONG_ANSWER.toString(), QuestionType.SHORT_ANSWER.toString()].includes(q.type)) //polls are only for NON long and short response
							.map(q => ({
								key: q.id.toString(),
								title: q.questionText!,
								checked: true
						})),
					};
				}
				
				return option;
			});
			setOptions(updatedOptions);
		}
	}, [exportData]);
	
	const handleChange = (e: React.ChangeEvent<HTMLInputElement>, option: ExportOption) => {
		const updatedOptions = [...options];
	
		// Recursively update the checked state of an option and its children
		const updateOption = (opt: ExportOption, checked: boolean) => {
			opt.checked = checked;
			opt.children?.forEach(child => updateOption(child, checked));
		};
	
		// Check if parent should be indeterminate
		const updateParentIndeterminate = (opt: ExportOption) => {
			if (opt.children) {
				const allChecked = opt.children.every(child => child.checked === true);
				const someChecked = opt.children.some(child => child.checked === true);
	
				if (allChecked) {
					opt.checked = true;
					opt.indeterminate = false;
				} else if (someChecked) {
					opt.checked = 'indeterminate';
				} else {
					opt.checked = false;
					opt.indeterminate = false;
				}
			}
		};
	
		// Recursively find the option being updated
		const findAndUpdateOption = (opts: ExportOption[]) => {
			return opts.map(opt => {
				if (opt.key === option.key) {
					// If it's a parent, update all children too
					updateOption(opt, e.target.checked);
				} else if (opt.children) {
					// Update children and check if the parent should be indeterminate
					opt.children = findAndUpdateOption(opt.children);
					updateParentIndeterminate(opt);
				}
				return opt;
			});
		};
	
		setOptions(findAndUpdateOption(updatedOptions));
	};
	
	const handleFormatChange = (e: any, k: string) => {
		setExportFormat((ExportFormat as any)[k]);
	};

	const { projectId } = useNumberParams();

	const generateReport = async () => {
		setLoadingRightButton(true);

		try {
			const token = await getToken();
			const response = await fetch(
				`${process.env.REACT_APP_API_URI}/processor/outreach-report/${projectId}`,
				{
					method: 'POST',
					headers: {
						'Content-Type': 'application/json',
						Authorization: `Bearer ${token}`,
					},
					body: JSON.stringify({
						options,
						existingData,
						exportFormat
					}),
				},
			);
			if (response.ok) {
				setOpen(false);
				notify(
					'Outreach report is generating and will be emailed to you when ready.',
				);
			}
			return;
		} catch (e) {
			console.log(e);
			setError('Error While Getting Data !');
		} finally {
			setLoadingRightButton(false);
		}
	};

	const renderOptions = (options: ExportOption[]) => {
		return options.map((option: ExportOption) => {
			return (
				<React.Fragment key={option.key}>
					<FormControlLabel
						control={
							<Checkbox
								checked={option.checked === true}
								indeterminate={option.checked === 'indeterminate'}
								onChange={(e) => handleChange(e, option)}
								disabled={exportFormat === ExportFormat.CSV && option.pdfOnly}
							/>
						}
						label={option.title}
					/>
					{/* If the option has children, render them recursively */}
					{option.children && (
						<div style={{ marginLeft: '20px', display: 'flex', flexDirection: 'column' }}>
							{renderOptions(option.children)}
						</div>
					)}
				</React.Fragment>
			);
		});
	};

	return (
		<AppDialog
			open={open}
			handleLeftButton={() => setOpen(false)}
			RightButton={
				<LoadingButton
					loading={loadingRightButton}
					loadingPosition="end"
					endIcon={<FileDownload />}
					onClick={generateReport}
				>
					Generate & Export
				</LoadingButton>
			}
			leftButtonLabel="Cancel"
			title={'Download Report'}
			fullWidth={true}
			maxWidth="sm"
		>
			<FormControl style={{ marginTop: '15px', marginBottom: '15px' }}>
				<RadioGroup
					id="exportFormat"
					name="exportFormat"
					row
					value={exportFormat}
					onChange={handleFormatChange}
				>
					<FormControlLabel
						value={ExportFormat.CSV}
						control={<Radio />}
						label="CSV"
					/>
					<FormControlLabel
						value={ExportFormat.PDF}
						control={<Radio />}
						label="PDF"
					/>
				</RadioGroup>
			</FormControl>
			<Typography
				variant="h6"
				sx={{ fontWeight: 'bold', marginBottom: '15px' }}
			>
				Choose Data to Export
			</Typography>
			<FormGroup>
				{ exportData && renderOptions(options) }
			</FormGroup>
			{error && (
				<Alert onClose={() => setError('')} severity="error">
					{error}
				</Alert>
			)}
		</AppDialog>
	);
};
