import { useState, ChangeEvent } from 'react';
import {
	Box,
	Card,
	CardContent,
	IconButton,
	TextField,
	Tooltip,
	useTheme,
} from '@mui/material';
import {
	AddPhotoAlternate,
	DragHandle as DragHandleIcon,
	DeleteOutline,
} from '@mui/icons-material';
import { useMutation } from 'urql';
import { PublicBenefit } from '../../../types/types';
import FormModal from '../../common/FormModal';
import { createImageMutation } from '../../../api/mutation/image.mutations';
import { getObjectKey } from '../../../utility/getObjectKey';
import {
	getUploadUrlMutation,
	removeFileMutation,
} from '../../../api/mutation/s3.mutations';
import { ImageInfo } from '../../../types/imageInfo';
import usePublicBenefit from '../publicBenefitDefaultList/hooks/usePublicBenefit';
import { fileUploadApi } from '../../../api/apiClient';
import ImagePlaceholderCard from '../../common/ImagePlaceholderCard';
import RegularButton from 'components/common/styled/RegularButton';
import UploadImage from 'components/modules/components/UploadImage';
import { ImageContent, ImageProperty } from 'types/imageInfo';
import { useDeleteActionConfirmation } from 'hooks/useActionConfirmation';
import { useSnackBar } from '../../../providers/SnackBarProvider';
import { useRouteContext } from '../../../routes/Layout';

interface PublicBenefitCardProps {
	publicBenefit: PublicBenefit;
	handle: any;
	handleUpdate: (
		publicBenefitId: number,
		name: string,
		description?: string | undefined,
	) => void;
	handleDelete: (id: number) => void;
	hideDelete?: boolean;
	handleImageUpdate: (
		id: number,
		imageId: number,
		name: string,
		description: string | undefined,
		imageInfo: ImageInfo,
	) => void;
	disabled?: boolean;
}

export const PublicBenefitCard = (props: PublicBenefitCardProps) => {
	const {
		publicBenefit: defaultPublicBenefit,
		handle,
		handleUpdate,
		handleDelete,
		handleImageUpdate,
		hideDelete,
		disabled,
	} = props;

	const theme = useTheme();

	const [publicBenefit, setPublicBenefit] =
		useState<PublicBenefit>(defaultPublicBenefit);
	const [open, setOpen] = useState(false);
	const [progress, setProgress] = useState(0);
	const [confirmDisabled, setConfirmDisabled] = useState(false);
	const [imageInfo, setImageInfo] = useState<ImageInfo | null>(null);
	const { handleImageDelete } = usePublicBenefit(
		defaultPublicBenefit.project.id,
	);

	const [, createImage] = useMutation(createImageMutation('id'));
	const [, removeFile] = useMutation(removeFileMutation);
	const [, getUploadUrl] = useMutation(getUploadUrlMutation);

	const { notify } = useSnackBar();
	const { activeProjectDetails } = useRouteContext();
	const showDeleteConfirmation = useDeleteActionConfirmation();

	const onUpdate = (publicBenefit: PublicBenefit) => {
		if (
			publicBenefit.name !== defaultPublicBenefit.name ||
			publicBenefit.description !== defaultPublicBenefit.description
		)
			handleUpdate(
				publicBenefit.id,
				publicBenefit.name,
				publicBenefit.description ?? undefined,
			);
	};

	const handleUpload = async (files: FileList | null) => {
		if (!files) return;

		const file = files[0];
		const result = await getUploadUrl({
			folder: `${activeProjectDetails.slug}/public-benefits`,
			filename: file.name,
		});

		if (result.error) return;

		const uploadURL = result.data.getUploadUrl;

		const res = await fileUploadApi(uploadURL, file, setProgress);
		if (!res) return notify('Failed to upload image!', 'error');
		const imageUrl = res.split('?')[0];

		const newImageData = {
			imageUrl,
			fileName: file.name,
			altTag: imageInfo?.altTag || '',
			projectId: publicBenefit.project.id,
		};
		setImageInfo(newImageData);
	};

	const confirmUpload = async () => {
		if (!imageInfo) return setOpen(false);

		const result = await createImage({ newImageData: imageInfo });
		const { id } = result.data.createImage!;

		setPublicBenefit({ ...publicBenefit, image: { ...imageInfo, id } });

		handleImageUpdate(
			publicBenefit.id,
			id,
			publicBenefit.name,
			publicBenefit.description ?? undefined,
			imageInfo,
		);
		setImageInfo(null);
		setOpen(false);
		// onUpdate(publicBenefit); // to Update boundingBoxPosition and imageScale options
		// TODO : edit handleUpdatePublicBenefit in usePublicBenefit to update boundingBoxPosition and imageScale options
	};

	const cancelUpload = async () => {
		if (!imageInfo) return setOpen(false);

		// delete image from bucket
		const objectKey = getObjectKey(imageInfo.imageUrl);
		await removeFile({ objectKey });

		setImageInfo(null);
		setOpen(false);
	};

	const handlePublicBenefitDelete = async () => {
		await showDeleteConfirmation(
			{
				title: 'Delete Public Benefit',
				message: `Are you sure you want to delete ${publicBenefit.name} benefit?`,
			},
			() => handleDelete(publicBenefit.id),
		);
	};

	const handleImageRemove = async () => {
		if (publicBenefit.image) {
			setPublicBenefit({ ...publicBenefit, image: null });
			await handleImageDelete(
				publicBenefit.id,
				publicBenefit.image?.id,
				publicBenefit.name,
				publicBenefit.image?.imageUrl,
				publicBenefit.description ?? undefined,
			);
		}
	};

	const imageFormDelete = async () => {
		const objectKey = getObjectKey(imageInfo?.imageUrl as string);
		await removeFile({ objectKey });
		setImageInfo(null);
	};

	const onImageDelete = async () => {
		await showDeleteConfirmation(
			{
				title: 'Image Delete',
				message: `Are you sure you want to delete ${
					imageInfo?.fileName || 'this'
				} image?`,
			},
			() => imageFormDelete(),
		);
	};

	const handleImageChange = (
		property: ImageProperty,
		event?: ChangeEvent | Event,
		data?: any,
	) => {
		const value = data || (event?.target as HTMLInputElement).value;

		if (['imageUrl', 'fileName', 'altTag'].includes(property)) {
			setImageInfo((prevImageInfo) => ({
				...(prevImageInfo as ImageInfo),
				[property]: value,
			}));
		} else {
			setPublicBenefit({ ...publicBenefit, [property]: value });
		}
	};

	return (
		<Box
			border="1px solid #E0E0E0"
			borderRadius={2}
			overflow="hidden"
			minHeight={200}
			marginBottom={2}
			sx={{ boxShadow: '0px 2px 3px rgba(0,0,0,0.20)' }}
		>
			<div
				{...handle}
				style={{
					backgroundColor: theme.customPalette.surface.light,
					height: '16px',
					textAlign: 'center',
				}}
			>
				<DragHandleIcon htmlColor="#B0AFAF" sx={{ fontSize: 16 }} />
			</div>
			<Card sx={{ border: 'none', boxShadow: 'none' }}>
				<CardContent>
					<Box
						display="flex"
						justifyContent="space-between"
						alignItems="center"
						py={1}
					>
						<TextField
							disabled={disabled}
							focused={publicBenefit.id === -1}
							placeholder={'Benefit Name'}
							sx={{
								minWidth: 100,
								maxWidth: 340,
								width: publicBenefit.name.length * 10,
								'& .public-DraftEditor-content': {
									maxHeight: '200px',
									overflow: 'auto',
								},
								fontFamily: 'lato',
								fontSize: '14px',
								'& .MuiOutlinedInput-root': {
									'& input': {
										textOverflow: 'ellipsis',
										fontSize: '14px',
										padding: '16px',
									},
									'& fieldset': { borderStyle: 'none' },
									'&:hover fieldset': {
										border: '1px solid #EFEFEF',
										borderStyle: 'solid',
									},
									'&.Mui-focused fieldset': {
										border: '1px solid lightblue',
										borderStyle: 'solid',
									},
								},
							}}
							inputProps={{
								style: { padding: '8px 0 8px 6px', fontSize: '1rem' },
							}}
							value={publicBenefit.name}
							onBlur={() => onUpdate(publicBenefit)}
							onChange={(event) =>
								setPublicBenefit({
									...publicBenefit,
									name: event.target.value,
								})
							}
						/>

						<Box display="flex" alignItems="center" gap={1}>
							{!publicBenefit?.image?.imageUrl ? (
								<RegularButton
									disabled={disabled}
									variant="contained"
									type="button"
									size="small"
									startIcon={<AddPhotoAlternate />}
									onClick={() => setOpen(true)}
								>
									Add Image
								</RegularButton>
							) : (
								<ImagePlaceholderCard
									image={publicBenefit.image}
									handleDelete={handleImageRemove}
									deleteDisabled={disabled}
								/>
							)}
							<Tooltip
								title={`Delete ${publicBenefit.name} public benefit`}
								placement="top"
								arrow={true}
							>
								<IconButton
									onClick={handlePublicBenefitDelete}
									sx={{
										display: hideDelete ? 'none' : 'inline',
										marginTop: '5px',
									}}
									disabled={disabled}
								>
									<DeleteOutline />
								</IconButton>
							</Tooltip>
						</Box>
					</Box>

					<TextField
						disabled={disabled}
						rows={5}
						multiline
						value={publicBenefit.description}
						fullWidth={true}
						onBlur={() => onUpdate(publicBenefit)}
						onChange={(event) =>
							setPublicBenefit({
								...publicBenefit,
								description: event.target.value,
							})
						}
					/>

					<FormModal
						open={open}
						fullWidth
						maxWidth="md"
						title="Benefit Image"
						subTitle="Add a photo to represent the selected public benefit"
						onConfirm={confirmUpload}
						onCancel={cancelUpload}
						confirmLabel="Upload"
						cancelLabel="Cancel"
						disabled={confirmDisabled}
						bottomDivider
					>
						<Box display="flex" flexDirection="column" px={4} py={2}>
							<UploadImage
								handleUpload={handleUpload}
								progress={progress}
								handleChange={handleImageChange}
								imageContent={
									{
										...imageInfo,
										imageScale: publicBenefit.imageScale,
										boundingBoxPosition: publicBenefit.boundingBoxPosition,
									} as ImageContent
								}
								setConfirmDisabled={setConfirmDisabled}
								handleDelete={onImageDelete}
								showLightBox={false}
							/>
						</Box>
					</FormModal>
				</CardContent>
			</Card>
		</Box>
	);
};
