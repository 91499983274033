import { gql } from 'urql';

export const createEventMutation = gql`
  mutation createEvent($newEvent: NewEventInput!) {
    createEvent(newEvent: $newEvent) {
      id
    }
  }
`;

export const updateEventMutation = gql`
  mutation updateEvent($existingEvent: ExistingEventInput!) {
    updateEvent(existingEvent: $existingEvent) {
      id
    }
  }
`;

export const deleteEventMutation = gql`
  mutation deleteEvent($id: Int!) {
    deleteEvent(id: $id)
  }
`;
