import { FC, useEffect, useState } from 'react';
import { Box } from '@mui/material';
import {
	Article as ArticleIcon, 
	DoNotDisturb as DoNotDisturbIcon, 
	AssignmentTurnedIn as AssignmentTurnedInIcon, 
	Construction as ConstructionIcon, 
	Groups as GroupsIcon, 
	CheckCircle as CheckCircleIcon 
} from '@mui/icons-material';
import { TextFieldProps } from 'formik-mui';
import MilestoneIcon from './MilestoneIcon';

export const milestoneIcons = [
	{ name: 'None', Icon: DoNotDisturbIcon },
	{ name: 'Application', Icon: ArticleIcon },
	{ name: 'Approved', Icon: AssignmentTurnedInIcon },
	{ name: 'Construction', Icon: ConstructionIcon, size: 'large' },
	{ name: 'Meeting', Icon: GroupsIcon, size: 'large' },
	{ name: 'Complete', Icon: CheckCircleIcon, bgColor: '#32B390' },
];

const MilestoneIconsContainer: FC<TextFieldProps> = (props) => {
	const [active, setActive] = useState(0);
	const {
		form: { setFieldValue },
		field: { name, value },
	} = props;

	useEffect(() => {
		if (!value) return;
		const currentIcon = milestoneIcons.findIndex((icon) => icon.name === value);
		setActive(currentIcon);
	}, [value]);

	return (
		<Box display="flex" alignItems="center" gap={1}>
			{milestoneIcons.map((icon, i) => (
				<MilestoneIcon
					key={icon.name}
					Icon={<icon.Icon />}
					name={icon.name}
					active={active === i}
					bgColor={icon.bgColor}
					onSelect={() => {
						setActive(i);
						setFieldValue(name, icon.name);
					}}
				/>
			))}
		</Box>
	);
};

export default MilestoneIconsContainer;
