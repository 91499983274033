import { FC } from 'react';
import {
	Autocomplete,
	Box,
	Button,
	Checkbox,
	FormGroup,
	FormControlLabel,
	TextField,
} from '@mui/material';
import useUserProjectForm from './hooks/useUserProjectForm';
import Dialog from '../../../../components/common/Dialog';
import { User } from '../../../../types/types';

interface UserProjectFormProps {
	user: User;
}

const UserProjectForm: FC<UserProjectFormProps> = ({ user }) => {
	const {
		openAdd,
		openDelete,
		current,
		setCurrent,
		setOpenAdd,
		setOpenDelete,
		selectedProject,
		userProjects,
		userProjectsNoRole,
		handleChange,
		handleAddUser,
		handleRemoveUser,
	} = useUserProjectForm(user.id);

	return (
		<Box>
			<Box display="flex" gap={4} mb={4}>
				<Button fullWidth variant="outlined" onClick={() => setOpenAdd(true)}>
					Add Project
				</Button>
				<Autocomplete
					id="free-solo-demo"
					value={selectedProject.name}
					freeSolo
					options={userProjectsNoRole.map((p) => p.name)}
					renderInput={(params) => (
						<TextField {...params} label="Search Projects" />
					)}
					sx={{ minWidth: 300 }}
					onChange={handleChange}
				/>
			</Box>

			<FormGroup
				sx={{ display: 'grid', gridTemplateColumns: '1fr 1fr', columnGap: 2 }}
			>
				{userProjects.map((p) => (
					<FormControlLabel
						key={p.id}
						control={
							<Checkbox
								checked
								onChange={() => {
									setCurrent(p);
									setOpenDelete(true);
								}}
							/>
						}
						label={p.name}
					/>
				))}
			</FormGroup>

			<Dialog
				open={openAdd}
				title="Add User To Project"
				handleLeftButton={() => setOpenAdd(false)}
				handleRightButton={handleAddUser}
				leftButtonLabel="No"
				rightButtonLabel="Yes"
				maxWidth="xs"
			>
				<p>
					Are you sure you want to give{' '}
					<b>
						{user.firstName} {user.lastName}
					</b>{' '}
					access to <b>{selectedProject.name}</b>?
				</p>
			</Dialog>

			<Dialog
				open={openDelete}
				title="Remove User From Project"
				handleLeftButton={() => setOpenDelete(false)}
				handleRightButton={() => handleRemoveUser(current)}
				leftButtonLabel="No"
				rightButtonLabel="Yes"
				maxWidth="xs"
			>
				<p>
					Are you sure you want to remove{' '}
					<b>
						{user.firstName} {user.lastName}
					</b>{' '}
					from <b>{current.name}</b>?
				</p>
			</Dialog>
		</Box>
	);
};

export default UserProjectForm;
