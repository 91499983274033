import { gql } from 'urql';

export const publishPageMutation = (s: string) => gql`
	mutation publishPage($id: Int!) {
		publishPage(pageId: $id) {
			id
		}
	}
`;

export const unPublishPageMutation = (s: string) => gql`
	mutation unPublishPage($id: Int!) {
		unPublishPage(pageId: $id) {
			id
		}
	}
`;

export const updatePageMutation = `
	mutation updatePage($existingPageData: ExistingPageInput!){
		updatePage(existingPageData: $existingPageData) {
			id
		}
	}
`;
