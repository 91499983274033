import React, { useState } from 'react';
import { useMutation } from 'urql';
import {
	Box,
	Dialog,
	DialogContent,
	DialogTitle,
	Divider,
	Grid,
	IconButton,
	Typography,
	styled,
} from '@mui/material';
import { Form, Formik } from 'formik';
import queryString from 'query-string';

import { Close as CloseIcon } from '@mui/icons-material';
import { useAuth0 } from '@auth0/auth0-react';
import { useLastRedirectURL } from '../hooks/useLastRedirectUrl';
import { LoginRedirect } from '../types/loginRedirect';
import {
	updateUserMutation,
	passwordReset,
} from '../api/mutation/users.mutation';
import { useUserContext } from '../providers/UserProvider';
import ImageDrop from '../components/common/ImageDrop';
import {
	createAvatarMutation,
	updateAvatarMutation,
} from '../api/mutation/avatar.mutation';
import { useSnackBar } from '../providers/SnackBarProvider';
import { fileUploadApi } from '../api/apiClient';
import { getUploadUrlMutation } from '../api/mutation/s3.mutations';
import RegularTextInput from '../components/common/styled/RegularTextInput';
import RegularButton from '../components/common/styled/RegularButton';
import ConfirmationDialog from '../components/common/ConfirmationDialog';
import PageHeader from '../components/common/PageHeader';
import UserAvatar from 'components/common/Avatar';

interface Name {
	firstName: string;
	lastName: string;
}

interface AvatarInput {
	imageUrl: string;
	fileName: string;
	userId: number;
}

const Label = styled('label')({
	margin: '10px 0 5px 0',
	fontSize: '90%',
	fontWeight: 700,
	cursor: 'pointer',
});

export default function Profile() {
	const { isAuthenticated } = useAuth0();
	const { user, userAvatar, updateAvatarImage } = useUserContext();
	const { notify } = useSnackBar();
	const { setLastRedirectURL } = useLastRedirectURL();
	const [, updateUser] = useMutation(updateUserMutation);
	const [, resetPasswordLink] = useMutation(passwordReset);
	const [progress, setProgress] = useState(0);
	const [open, setOpen] = useState(false);

	const [initialState, setInitialState] = useState({
		firstName: '',
		lastName: '',
	});
	const [passwordConfirmOpen, setPasswordConfirmOpen] = useState(false);

	const [, getUploadUrl] = useMutation(getUploadUrlMutation);
	const [, createAvatar] = useMutation(createAvatarMutation);
	const [, updateAvatar] = useMutation(updateAvatarMutation);

	React.useEffect(() => {
		if (user) {
			setInitialState((prevVals: Name) => ({
				...prevVals,
				firstName: user.firstName,
				lastName: user.lastName,
			}));
		}
	}, [user]);

	const handlePasswordChange = async () => {
		setPasswordConfirmOpen(false);
		if (user) {
			try {
				const res = await resetPasswordLink({ email: user.email });
				if (res.data) notify('Password Reset send successfully');
				else notify('Something went wrong! Could not send email to user');
			} catch (e) {
				notify('Something went wrong! Could not update user');
			}
		}
	};

	const handleSubmit = async (vals: Name) => {
		try {
			if (user) {
				const res = await updateUser({
					updateUserData: {
						id: user.id,
						firstName: vals.firstName,
						lastName: vals.lastName,
					},
				});
				if (res.data) notify('Name updated successfully');
			}
		} catch (e) {
			notify('Something went wrong! Could not update name');
		}
	};

	if (!isAuthenticated) {
		const parsedQueryString = queryString.parse(
			window.location.search,
		) as unknown as LoginRedirect;

		setLastRedirectURL(parsedQueryString.redirect);
	}

	const handleClose = () => {
		setOpen(false);
	};

	const handleUploadClick = () => {
		setProgress(0);
		setOpen(true);
	};

	const persistAvatarToUser = async (newAvatarData: AvatarInput) => {
		if (userAvatar) {
			await updateAvatar({
				existingAvatarData: { ...newAvatarData, id: userAvatar.id },
			});
		} else {
			await createAvatar({ newAvatarData: newAvatarData });
		}
	};

	const uploadFilesToAws = async (files: FileList | null) => {
		if (!files || !user) return;
		const regex = /[^0-9a-zA-Z]+/g; //remove special characters
		const userNameFolder = `${user?.firstName.replace(regex, '') ?? 'x'} ${
			user?.lastName.replace(regex, '')[0] ?? 'x'
		}`
			.replace(/\s+/g, '_')
			.toLowerCase();
		const file = files[0];
		const result = await getUploadUrl({
			folder: `profiles/${userNameFolder}`,
			filename: file.name,
		});

		if (result.error) return;

		const uploadURL = result.data.getUploadUrl;

		const res = await fileUploadApi(uploadURL, file, setProgress);
		if (!res) return notify('Failed to upload image!', 'error');
		const imageUrl = res.split('?')[0];

		updateAvatarImage(imageUrl);

		try {
			if (user?.id) {
				const newAvatarData = {
					imageUrl: imageUrl,
					fileName: file.name,
					userId: user.id,
				};
				await persistAvatarToUser(newAvatarData);
			}
			setOpen(false);
			notify('Profile avatar saved');
		} catch (e) {
			notify('Profile avatar failed to save!', 'error');
		}
	};

	return (
		<>
			<PageHeader sx={{ m: 2 }}>Edit Profile</PageHeader>
			<Grid container spacing={2} mt={2}>
				<Grid item sm={2}>
					<Grid container justifyContent="center">
						<UserAvatar
							sx={{ width: 120, height: 120, fontSize: '48px' }}
							variant="rounded"
						/>
					</Grid>
				</Grid>

				<Grid item sm={9} xs={12}>
					<Box sx={{ maxWidth: 650, pb: 2 }}>
						<Formik
							initialValues={{ ...initialState }}
							enableReinitialize={true}
							onSubmit={(values: Name) => {
								handleSubmit(values);
							}}
						>
							{({ values, handleChange, handleSubmit }) => (
								<Form onSubmit={handleSubmit}>
									<Grid item xs={12}>
										<Label htmlFor="firstName">First Name</Label>
									</Grid>
									<Grid item xs={12}>
										<RegularTextInput
											sx={{ width: '100%' }}
											value={values.firstName}
											name="firstName"
											onChange={handleChange}
											id="first-name"
											size="small"
										/>
									</Grid>
									<Grid item xs={12} mt={1}>
										<Label htmlFor="lastName">Last Name</Label>
									</Grid>
									<Grid item xs={12}>
										<RegularTextInput
											sx={{ width: '100%' }}
											value={values.lastName}
											name="lastName"
											onChange={handleChange}
											id="last-name"
											size="small"
										/>
									</Grid>
									<RegularButton type={'submit'} sx={{ my: 2 }}>
										Save
									</RegularButton>
								</Form>
							)}
						</Formik>
						<Divider light />
						<Typography
							sx={{
								margin: '10px 0 10px 0',
								fontSize: '90%',
								fontWeight: 700,
								cursor: 'pointer',
							}}
						>
							Profile Avatar
						</Typography>
						<UserAvatar sx={{ width: 105, height: 105, fontSize: '42px' }} />

						<Grid item xs={12} my={2}>
							<RegularButton onClick={handleUploadClick}>
								Choose image
							</RegularButton>
						</Grid>

						<Dialog
							open={open}
							onClose={handleClose}
							maxWidth="sm"
							fullWidth={true}
						>
							<DialogTitle
								id="alert-dialog-title"
								sx={{
									display: 'flex',
									justifyContent: 'space-between',
									alignItems: 'center',
									color: 'gray',
									fontWeight: 'bold',
								}}
							>
								Upload Avatar
								<IconButton onClick={handleClose}>
									<CloseIcon />
								</IconButton>
							</DialogTitle>
							<DialogContent>
								<ImageDrop
									progress={progress}
									handleUpload={uploadFilesToAws}
								/>
							</DialogContent>
						</Dialog>

						<Divider light />

						<Typography
							sx={{
								margin: '10px 0 10px 0',
								fontSize: '90%',
								fontWeight: 700,
								cursor: 'pointer',
							}}
						>
							Password
						</Typography>

						<Typography my={1} variant="body2">
							Send a password reset link to begin updating your password
						</Typography>

						<RegularButton
							onClick={() => setPasswordConfirmOpen(true)}
							sx={{ my: 1 }}
						>
							Password Reset
						</RegularButton>
					</Box>
				</Grid>
				<ConfirmationDialog
					open={passwordConfirmOpen}
					onCancel={() => setPasswordConfirmOpen(false)}
					onConfirm={handlePasswordChange}
					title="Password Change Confirm"
					confirmLabel="Confirm"
				>
					<p>Are you sure that you want to change your password ?</p>
				</ConfirmationDialog>
			</Grid>
		</>
	);
}
