import React, { FC, useState } from 'react';
import {
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	IconButton,
	SxProps,
	Theme,
	Typography,
	Button,
	Stack,
} from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';

const smallTitlePadding = '25px 43px 16px 43px';
const largeTitlePadding = '60px 96px 18px 96px';
const smallContentPadding = '0 49px 8px 49px';
const largeContentPadding = '0 108px 20px 108px';
const smallActionsPadding = '14px 8px 27px 8px';
const largeActionsPadding = '34px 8px 64px 8px';

export interface ConfirmationDialogRenderProps {
	setShowConfirm: React.Dispatch<React.SetStateAction<boolean>>;
	setShowCancel: React.Dispatch<React.SetStateAction<boolean>>;
	setDisabled: React.Dispatch<React.SetStateAction<boolean>>;
	disabled: boolean;
}

type ConfirmationRenderProps = (
	renderProps: ConfirmationDialogRenderProps,
) => React.ReactNode;
interface ConfirmationDialogProps {
	title?: string;
	open: boolean;
	action?: 'save' | 'abandon';
	paddingSize?: 'small' | 'large';
	confirmDisabled?: boolean;
	onConfirm: (() => void) | null;
	onCancel?: (() => void) | null;
	cancelLabel?: string;
	confirmLabel?: string;
	disableBackdropClick?: boolean;
	onClose?: () => void;
	style?: SxProps<Theme>;
	children?: ConfirmationRenderProps | React.ReactNode;
}

const defaultProps: ConfirmationDialogProps = {
	onConfirm: null,
	onCancel: null,
	open: false,
	action: 'save',
	paddingSize: 'large',
	confirmDisabled: false,
	cancelLabel: 'Cancel',
	confirmLabel: 'Save',
	title: 'Confirm Action',
	disableBackdropClick: false,
	style: {},
};

const ConfirmationDialog: FC<ConfirmationDialogProps> = (props) => {
	const {
		title,
		open,
		action,
		paddingSize,
		confirmDisabled,
		onConfirm,
		onCancel,
		onClose,
		cancelLabel,
		confirmLabel,
		disableBackdropClick,
		style,
	} = props;

	const [disabled, setDisabled] = useState(false);
	const [showCancel, setShowCancel] = useState(true);
	const [showConfirm, setShowConfirm] = useState(true);

	const handleClose = (e: React.MouseEvent, reason: string) => {
		if (disableBackdropClick && reason && reason === 'backdropClick') {
			return;
		}
		onCancel && onCancel();
		e.stopPropagation();
	};

	const handleConfirm = (e: React.MouseEvent) => {
		onConfirm && onConfirm();
		e.stopPropagation();
	};

	const smallPadding = paddingSize === 'small';

	return (
		<Dialog
			open={open}
			onClose={handleClose}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
			maxWidth="xl"
			PaperProps={{
				sx: { borderRadius: 6, ...style },
			}}
		>
			{(onClose || onCancel) && (
				<IconButton
					sx={{
						position: 'absolute',
						top: '22px',
						right: '22px',
						width: '24px',
						height: '24px',
					}}
					onClick={(onClose as () => void) || (onCancel as () => void)}
				>
					<CloseIcon />
				</IconButton>
			)}
			<DialogTitle
				id="alert-dialog-title"
				sx={{
					fontWeight: 'bold',
					padding: smallPadding ? smallTitlePadding : largeTitlePadding,
				}}
			>
				<Typography variant="h5" fontWeight={800} align="center">
					{title}
				</Typography>
			</DialogTitle>
			<DialogContent
				sx={{
					padding: smallPadding ? smallContentPadding : largeContentPadding,
				}}
			>
				{typeof props.children === 'function'
					? (props.children({
							setDisabled,
							setShowCancel,
							setShowConfirm,
							disabled,
					  }) as React.ReactNode)
					: (props.children as React.ReactNode)}
			</DialogContent>
			<DialogActions
				sx={{
					padding: smallPadding ? smallActionsPadding : largeActionsPadding,
				}}
			>
				<Stack
					direction="row"
					justifyContent="center"
					alignItems="center"
					spacing={2.5}
					sx={{ width: '100%' }}
				>
					{!!cancelLabel && showCancel && (
						<Button
							sx={(theme) => ({
								textTransform: 'capitalize',
								padding: '8px 16px',
								minWidth: '162px',
								borderWidth: '2px',
								borderColor: theme.customPalette.primaryColors.sky[1],
								'&:hover': {
									borderWidth: '2px',
									borderColor: theme.customPalette.primaryColors.sky[2],
									color: theme.customPalette.primaryColors.sky[2],
								},
								color: theme.customPalette.primaryColors.sky[1],
							})}
							variant="outlined"
							onClick={(e) => handleClose(e, '')}
							autoFocus
						>
							{cancelLabel}
						</Button>
					)}
					{!!confirmLabel && showConfirm && (
						<Button
							sx={(theme) => ({
								color: theme.customPalette.basicColors.light,
								textTransform: 'capitalize',
								padding: '8px 16px',
								minWidth: '162px',
								backgroundColor:
									action === 'save'
										? theme.customPalette.primaryColors.sky[1]
										: theme.customPalette.systemColors.error.main,
								'&:hover':
									action === 'save'
										? {
												backgroundColor:
													theme.customPalette.primaryColors.sky[2],
										  }
										: {},
							})}
							color={action === 'save' ? 'primary' : 'error'}
							variant="contained"
							disabled={disabled || confirmDisabled}
							onClick={handleConfirm}
						>
							{confirmLabel}
						</Button>
					)}
				</Stack>
			</DialogActions>
		</Dialog>
	);
};

ConfirmationDialog.defaultProps = defaultProps;

export default ConfirmationDialog;
