import { FC, MutableRefObject } from 'react';
import _ from 'lodash';
import ConfirmationDialog from 'components/common/ConfirmationDialog';
import FormModal, { FormModalRenderProps } from 'components/common/FormModal';
import GenericModule from 'components/modules/GenericModule';
import MODULE_NAMES, {
	MODULE_DIALOGS,
	MODULE_RENDER,
} from 'constants/moduleNames';
import { MODULE_TYPE } from 'types/moduleTypes';
import { ModuleInstance } from 'types/types';
import { ModuleInstanceComponentSaveData } from 'types/moduleInstanceData';

export interface ModuleDialogProps {
	open: boolean;
	moduleInstance: ModuleInstance;
	saveDataRef: MutableRefObject<ModuleInstanceComponentSaveData>;
	handleClose?: () => void;
	handleSaveModuleInstance: () => Promise<void>;
	handleCancel: () => void;
	handleUpdateModule?: (id: number, newContent: any) => void;
	cancelTrigger: number;
}

const smallPaddingModules = [MODULE_TYPE.MAP];

const ModuleDialog: FC<ModuleDialogProps> = (props) => {
	const {
		open,
		moduleInstance,
		saveDataRef,
		handleClose,
		handleSaveModuleInstance,
		handleCancel,
		handleUpdateModule,
		cancelTrigger,
	} = props;

	const { id, config, content } = moduleInstance;
	const moduleTypeName = moduleInstance.moduleType.name as MODULE_TYPE;
	const moduleRender = MODULE_RENDER[moduleTypeName];
	const title = MODULE_NAMES[moduleTypeName];
	const { subTitle, confirmLabel: label, cancelLabel } = moduleRender;

	const confirmLabel = _.isObject(content)
		? label.replace('Add', 'Update')
		: label;

	if (moduleRender.render === MODULE_DIALOGS.CONFIRMATION_DIALOG)
		return (
			<ConfirmationDialog
				open={open}
				onConfirm={handleSaveModuleInstance}
				onCancel={handleCancel}
				disableBackdropClick
				title={title}
				paddingSize={
					smallPaddingModules.includes(
						moduleInstance.moduleType.name as MODULE_TYPE,
					)
						? 'small'
						: 'large'
				}
			>
				{({ setDisabled }: FormModalRenderProps) => (
					<GenericModule
						id={id}
						setConfirmDisabled={setDisabled}
						type={moduleTypeName}
						content={content}
						config={config}
						saveDataRef={saveDataRef}
						handleClose={handleClose}
						handleUpdateModule={handleUpdateModule}
						cancelTrigger={cancelTrigger}
					/>
				)}
			</ConfirmationDialog>
		);
	else if (moduleRender.render === MODULE_DIALOGS.NONE) return null;

	return (
		<FormModal
			title={title}
			subTitle={subTitle}
			open={open}
			onConfirm={handleSaveModuleInstance}
			onCancel={handleCancel}
			confirmLabel={confirmLabel}
			cancelLabel={cancelLabel}
			fullWidth
			maxWidth={`${
				!['Image', 'Hero Image'].includes(moduleTypeName) ? 'md' : 'xl'
			}`}
			bottomDivider
		>
			{({ setDisabled }: FormModalRenderProps) => (
				<GenericModule
					id={id}
					setConfirmDisabled={setDisabled}
					type={moduleTypeName}
					content={content}
					config={config}
					saveDataRef={saveDataRef}
					handleClose={handleClose}
					cancelTrigger={cancelTrigger}
				/>
			)}
		</FormModal>
	);
};

export default ModuleDialog;
