import { FC } from 'react';
import { Box, Typography } from '@mui/material';
import { useQuery } from 'urql';
import { userFollowingProjectsQuery } from '../../../../api/query/project.queries';
import { Project } from '../../../../types/types';

interface UserFollowedProjectsProps {
	userId: number;
}

const UserFollowedProjects: FC<UserFollowedProjectsProps> = ({ userId }) => {
	const [{ data }] = useQuery({
		query: userFollowingProjectsQuery,
		variables: { userId },
	});

	const userFollowingProjects = data?.userFollowingProjects as Project[];

	return (
		<Box>
			<Typography mb={2} fontWeight="bold">
				{userFollowingProjects?.length
					? 'These are the projects the user follows.'
					: 'This user follows no project.'}
			</Typography>

			<Box display="grid" gridTemplateColumns="1fr 1fr" gap={1}>
				{userFollowingProjects?.map((p) => (
					<Typography
						key={p.id}
						sx={{ border: '1px solid #ccc', p: 1, borderRadius: 5 }}
					>
						{p.name}
					</Typography>
				))}
			</Box>
		</Box>
	);
};

export default UserFollowedProjects;
