import { FC } from 'react';
import { Box, Skeleton, useTheme } from '@mui/material';

const AppSkeleton: FC = () => {
	const theme = useTheme();
	return (
		<Box height="100%" width="100%">
			<Box
				maxHeight={64}
				py={2}
				px={4}
				display="flex"
				justifyContent="space-between"
				alignItems="center"
				sx={{ bgcolor: theme.customPalette?.surface.dark }}
			>
				<Skeleton height={20} width={160} variant="rectangular" />

				<Box display="flex" alignItems="center" gap={2}>
					<Skeleton height={32} width={32} variant="rectangular" />
					<Skeleton width={100} variant="text" />
				</Box>
			</Box>

			<Box
				height="calc(100% - 152px)"
				width="100%"
				display="flex"
				gap={2}
				py={2}
			>
				<Skeleton width={301} height="100%" variant="rectangular" />

				<Box height="100%" width="calc(100% -  301px)" mr={2}>
					<Box display="flex" justifyContent="space-between" mb={2}>
						<Skeleton width={100} height={20} variant="rectangular" />
						<Box display="flex" gap={1}>
							<Skeleton width={100} height={20} variant="rectangular" />
							<Skeleton width={100} height={20} variant="rectangular" />
						</Box>
					</Box>

					<Skeleton
						width="100%"
						height="calc(100% - 36px)"
						variant="rectangular"
					/>
				</Box>
			</Box>

			<Skeleton height={64} width="100%" variant="rectangular" />
		</Box>
	);
};

export default AppSkeleton;
