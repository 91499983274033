import { Avatar, SxProps, useTheme } from '@mui/material';
import { useUserContext } from 'providers/UserProvider';
import { FC } from 'react';

interface UserOverride {
	fullName: string;
	avatarUrl?: string | null | undefined;
}
interface AvatarProps {
	sx?: SxProps;
	variant?: 'circular' | 'rounded' | 'square' | undefined;
	userOverride?: UserOverride;
}

const UserAvatar: FC<AvatarProps> = ({
	sx,
	variant = 'circular',
	userOverride,
}) => {
	const { user, userAvatar, isUserLoading } = useUserContext();
	const theme = useTheme();

	if ((!user && !userOverride) || isUserLoading) return null;

	const overrideFirstInitial = userOverride?.fullName?.substring(0, 1);
	const overrideSecondInitial = userOverride?.fullName
		?.split(' ')[1]
		.substring(0, 1);

	if (userOverride?.avatarUrl || (!userOverride && userAvatar?.imageUrl))
		return (
			<Avatar
				alt={userOverride?.fullName ?? user?.fullName}
				variant={variant}
				src={userOverride?.avatarUrl ?? userAvatar?.imageUrl}
				sx={{ width: 36, height: 36, mr: 1, ...sx }}
			/>
		);

	const contextUserInitials = user
		? user?.firstName?.substring(0, 1) + user?.lastName?.substring(0, 1)
		: '';
	const userOverrideInitials = userOverride
		? `${overrideFirstInitial ?? 'X'}${overrideSecondInitial}`
		: undefined;

	return (
		<Avatar
			variant={variant}
			alt={userOverride?.fullName ?? user?.fullName}
			sx={{
				width: 36,
				height: 36,
				backgroundColor: theme.customPalette.primaryColors.grassGreen[1],
				color: 'white',
				marginRight: '10px',
				'&:hover': { cursor: 'pointer' },
				fontWeight: 400,
				fontSize: '12px',
				...sx,
			}}
		>
			{userOverrideInitials ?? contextUserInitials}
		</Avatar>
	);
};

export default UserAvatar;
