import { MenuItem, SxProps, TextField } from '@mui/material';
import { FC } from 'react';
import { TitleCaseHyphenatedWord } from '../../utility/titleCaseHyphenatedWord';

export interface OutlinedDropDownProps {
	sx?: SxProps;
	onChange: (value: any) => void;
	label?: string;
	defaultValue?: string;
	items: { name: any; title: string }[];
	disabled?: boolean;
}

const OutlinedDropDown: FC<OutlinedDropDownProps> = (props) => {
	const { sx, onChange, defaultValue, disabled, items, label } = props;
	return (
		<TextField
			id="outlined-select"
			select
			label={label}
			defaultValue={defaultValue}
			disabled={disabled}
			size="small"
			sx={sx}
		>
			{items?.map(({ name, title }, index) => (
				<MenuItem key={index} value={name} onClick={() => onChange(name)}>
					{TitleCaseHyphenatedWord.transform(title)}
				</MenuItem>
			))}
		</TextField>
	);
};

export default OutlinedDropDown;
