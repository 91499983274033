import { Grid } from '@mui/material';
import { FC } from 'react';
import { QuestionGroupsContainer } from '../components/routes/questions/QuestionGroupsContainer';

export const Questions: FC = () => {
	return (
		<Grid px={5} py={2} rowSpacing={2} container>
			<QuestionGroupsContainer />
		</Grid>
	);
};
