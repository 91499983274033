import { FC } from 'react';
import {
	Theme as ITheme,
	StyledEngineProvider,
	CssBaseline,
	ThemeProvider,
	createTheme,
} from '@mui/material';

import componentsOverride from './overrides';
import { IPalette as PaletteType } from './ThemeTypes';

declare module '@mui/material/styles/createTheme' {
	interface Theme {
		customPalette: PaletteType;
	}

	interface ThemeOptions {
		customPalette: PaletteType;
	}
}

const AppTheme: FC<{ children: React.ReactNode }> = ({ children }) => {
	const themeOptions: PaletteType = {
		basicColors: {
			dark: '#000000',
			light: '#FFFFFF',
		},
		systemColors: {
			unchecked: '#5C5C5C',
			disabled: '#999999',
			error: {
				main: '#D72323',
				light: '#FDECEC',
			},
			success: {
				main: '#00851D',
				light: '#E9FBED',
			},
			info: {
				main: '#066EE6',
				light: '#E1EEFD',
			},
			warning: {
				main: '#E48037',
				light: '#F9E2D2',
			},
		},
		textColors: {
			dark: '#222222',
			static: '#5C5C5C',
			light: '#999999',
		},
		borderColors: {
			light: '#E9E9E9',
			dark: '#DFDFDF',
		},
		dividerColors: {
			light: '#F1F2F3',
			dark: '#EAEAEA',
		},
		surface: {
			light: '#F8F8F8',
			dark: '#F1F1F1',
		},
		primaryColors: {
			dusk: {
				1: '#000B0F',
				2: '#003345',
				3: '#004057',
				4: '#004E69',
				5: '#005B7C',
				6: '#000', //undefined
			},
			sky: {
				1: '#0076A0',
				2: '#0081AE',
				3: '#68B4CF',
				4: '#9CCEE0',
				5: '#EAF3F5',
				6: '#000', //undefined
			},
			coreGreen: {
				1: '#00A396',
				2: '#00B3A3',
				3: '#68D2C9',
				4: '#B6E9E5',
				5: '#D0F1EE',
				6: '#000', //undefined
			},
			grassGreen: {
				1: '#00645F',
				2: '#348480',
				3: '#68D2C9',
				4: '#B6E9E5',
				5: '#D0E2E2',
				6: '#9CC3C1',
			},
			orange: {
				1: '#FF5E1B',
				2: '#FF7F49',
				3: '#FFA078',
				4: '#FFB08F',
				5: '#FFE1D5',
				6: '#000', //undefined
			},
			gold: {
				1: '#E1C50F',
				2: '#F1D62C',
				3: '#F5E161',
				4: '#F7E98C',
				5: '#FAF1B7',
				6: '#FCF8D9',
			},
		},
	};
	const themes = createTheme();
	const customTheme = createTheme({
		...themes,
		palette: {
			primary: {
				main: '#0076A0',
				contrastText: '#fff',
			},
			secondary: {
				main: '#00645F',
				contrastText: '#fff',
			},
		},
		customPalette: themeOptions,
		typography: {
			fontFamily: ['Nunito', 'sans-serif'].join(','),
		},
		// components: {
		// 	MuiCssBaseline: {
		// 		'@global': {
		// 			'*::-webkit-scrollbar': {
		// 				width: '10px',
		// 			},
		// 			'*::-webkit-scrollbar-track': {
		// 				background: '#E4EFEF',
		// 			},
		// 			'*::-webkit-scrollbar-thumb': {
		// 				background: '#1D388F61',
		// 				borderRadius: '2px',
		// 			},
		// 		},
		// 	},
		// },
	});
	customTheme.components = componentsOverride(customTheme);

	return (
		<StyledEngineProvider injectFirst>
			<ThemeProvider<ITheme & { customPalette: PaletteType }>
				theme={customTheme}
			>
				<CssBaseline />
				{children}
			</ThemeProvider>
		</StyledEngineProvider>
	);
};

export default AppTheme;
