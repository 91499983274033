import { FC, useState } from 'react';
import { SxProps, IconButton, InputLabel, InputAdornment, FormControl } from '@mui/material';
import { Search as SearchIcon } from '@mui/icons-material';
import RegularTextInput from './styled/RegularTextInput';

interface SearchInputProps {
	onChange?: (value: string) => void;
	onSearch: (value: string) => void;
	value?: string;
	label?: string;
	placeholder?: string;
	fullWidth?: boolean;
	sx?: SxProps;
}

const SearchInput: FC<SearchInputProps> = (props) => {
	// eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
	const { onChange, value, fullWidth, onSearch, label, placeholder, sx } =
		props;
	const [search, setSearch] = useState('');

	return (
		<form
			onSubmit={(e) => {
				e.preventDefault();
				onSearch(search);
				!onChange && setSearch('');
			}}
		>
			<InputLabel htmlFor="standard-adornment-search">{label}</InputLabel>
			<FormControl sx={sx} fullWidth={fullWidth} size="small">
				<RegularTextInput
					id="standard-adornment-search"
					fullWidth
					value={value || search}
					onChange={(e) => {
						setSearch(e.target.value);
						onChange && onChange(e.target.value);
					}}
					InputProps={{
						endAdornment: (
							<InputAdornment position="end">
								<IconButton
									aria-label="search"
									onClick={() => {
										onSearch(search);
										!onChange && setSearch('');
									}}
								>
									<SearchIcon />
								</IconButton>
							</InputAdornment>
						),
					}}
				/>
			</FormControl>
		</form>
	);
};

export default SearchInput;
