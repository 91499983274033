import { FC } from 'react';
import * as Yup from 'yup';
import { Field, Form, Formik } from 'formik';
import { Box, Grid, InputLabel, MenuItem, useTheme } from '@mui/material';
import { useSearchParams } from 'react-router-dom';
import { Filter_USER_TYPES, USER_STATUS } from '../../../constants/userTypes';
import { SearchOutlined } from '@mui/icons-material';
import { TitleCaseHyphenatedWord } from '../../../utility/titleCaseHyphenatedWord';
import RegularTextInput from 'components/common/styled/RegularTextInput';
import RegularButton from 'components/common/styled/RegularButton';
import { Select } from 'formik-mui';

interface UserFilterProps {
	onSearchClick: (
		userType: string,
		userStatus: string,
		emailAddressOrName: string,
	) => void;
}

export const UserFilters: FC<UserFilterProps> = (props: UserFilterProps) => {
	const { onSearchClick } = props;
	const theme = useTheme();
	const [params] = useSearchParams();

	const isEditMode = params.get('edit');

	const selectStyle = {
		width: 180,
		borderRadius: 8,
		color: `${theme.customPalette.textColors.static}`,
		border: `1px solid ${theme.customPalette.borderColors.dark}`,
		height: 50,
	};

	return (
		<Formik
			validationSchema={Yup.object({
				emailAddressOrName: Yup.string(),
			})}
			initialValues={{
				userType: isEditMode ? 'all' : 'client',
				userStatus: isEditMode ? 'all' : 'active',
				emailAddressOrName: '',
			}}
			onSubmit={(values, { setSubmitting }) => {
				onSearchClick(
					values.userType,
					values.userStatus,
					values.emailAddressOrName,
				);
				setSubmitting(false);
			}}
		>
			{({ values, submitForm, resetForm, isSubmitting, touched, errors }) => (
				<Form className="filter-form">
					<Grid container spacing={3}>
						<Grid item xs="auto">
							<InputLabel id="userType">User Class</InputLabel>

							<Field
								component={Select}
								id="userType"
								name="userType"
								type="text"
								style={selectStyle}
								className="filter-field"
								inputProps={{ name: 'userType', id: 'userType' }}
							>
								{Object.values(Filter_USER_TYPES).map((ut) => (
									<MenuItem key={ut} value={ut}>
										{TitleCaseHyphenatedWord.transform(ut)}
									</MenuItem>
								))}
							</Field>
							<div className="bottom-text">Public, Client or Internal</div>
						</Grid>

						<Grid item xs="auto">
							<InputLabel id="userStatus">User Status</InputLabel>
							<Field
								component={Select}
								id="userStatus"
								name="userStatus"
								type="text"
								style={selectStyle}
								inputProps={{ name: 'userStatus', id: 'userStatus' }}
							>
								{Object.values(USER_STATUS).map((us) => (
									<MenuItem key={us} value={us}>
										{TitleCaseHyphenatedWord.transform(us)}
									</MenuItem>
								))}
							</Field>
							<div className="bottom-text">Active or Inactive</div>
						</Grid>

						<Grid
							item
							xs
							sx={{ display: 'flex', flexDirection: { xs: 'row' } }}
						>
							<Box sx={{ marginRight: 1, flexGrow: 1 }}>
								<Field
									as={RegularTextInput}
									placeholder="Email Address or Name"
									name="emailAddressOrName"
									id="emailAddressOrName"
									variant="outlined"
									size="small"
									sx={{
										width: '100%',
										minWidth: 150,
										marginTop: '20px',
									}}
									inputProps={{
										sx: {
											height: '33px',
										},
									}}
								/>
							</Box>

							<Box>
								<RegularButton
									variant="contained"
									startIcon={<SearchOutlined />}
									sx={{
										p: '6px 15px',
										cursor: 'pointer',
										height: '48px',
										marginTop: '20px',
										marginLeft: '12px',
									}}
									disabled={isSubmitting}
									onClick={submitForm}
									type="submit"
								>
									Search
								</RegularButton>
							</Box>
						</Grid>
					</Grid>
				</Form>
			)}
		</Formik>
	);
};
