import React, { ChangeEvent, useEffect, useState } from 'react';
import { Box, Grid } from '@mui/material';
import { useMutation } from 'urql';
import ModuleInstanceData from '../../types/moduleInstanceData';
import { useModuleSaveData } from '../../hooks/useModuleSaveData';
import UploadImage from './components/UploadImage';
import {
	getUploadUrlMutation,
	removeFileMutation,
	restoreFileMutation,
} from '../../api/mutation/s3.mutations';
import { fileUploadApi } from '../../api/apiClient';
import { useDeleteActionConfirmation } from 'hooks/useActionConfirmation';
import { useSnackBar } from '../../providers/SnackBarProvider';
import { TextEditor } from 'components/common/TextEditor';
import PositionSelector from 'components/common/PositionSelector';
import { toPlainText } from 'components/common/TextEditor/helpers';
import useProject from '../features/editProject/useProject';
import useNumberParams from '../../hooks/useNumberParams';
import { getObjectKey } from '../../utility/getObjectKey';
import TextButton from '../common/styled/TextButton';
import { CommunityModuleInstance } from '@coUrbanize/community-modules';
import { MODULE_TYPE } from 'types/moduleTypes';

export interface ImageContent {
	imageUrl?: string;
	fileName: string;
	altTag: string;
	lightbox?: boolean;
	imageScale?: string;
	boundingBoxPosition?: string;
}

export type ImageProperty =
	| 'imageUrl'
	| 'altTag'
	| 'lightbox'
	| 'imageScale'
	| 'boundingBoxPosition'
	| 'fileName';

export const ImageModule: React.FC<ModuleInstanceData> = (props) => {
	const { content, saveDataRef, config, setConfirmDisabled, cancelTrigger } =
		props;
	const { altTag, fileName, imageUrl, lightbox } = content || {};
	const { updateModuleSaveData } = useModuleSaveData(saveDataRef);
	const [imageContent, setImageContent] = useState<ImageContent>({
		altTag,
		fileName,
		imageUrl,
		lightbox,
	});
	const [progress, setProgress] = useState(0);
	const [imageDeleted, setImageDeleted] = useState(false);
	const [showAdvanceSettings, setShowAdvanceSettings] = useState(false);
	const [editorState, setEditorState] = useState(
		content?.overlayText?.richText,
	);
	const [overlayTextPosition, setOverlayTextPosition] = useState(
		content?.overlayTextPosition,
	);
	const { projectId } = useNumberParams();
	// TODO: below causes: Cannot update a component while rendering a different component as it is used in PreviewPageContainer
	const { project } = useProject(projectId);
	const [, getUploadUrl] = useMutation(getUploadUrlMutation);
	const [, removeImage] = useMutation(removeFileMutation);
	const [, restoreImage] = useMutation(restoreFileMutation);
	const { notify } = useSnackBar();

	const showDeleteConfirmation = useDeleteActionConfirmation();

	const handleDelete = async () => {
		try {
			const objectKey = getObjectKey(imageContent!.imageUrl!);
			const result = await removeImage({ objectKey });

			if (result?.data?.removeAwsFile) {
				setImageDeleted(true);
				setImageContent((prevImageContent: ImageContent) => {
					const { imageUrl, ...values } = prevImageContent;

					return values;
				});
			} else setImageDeleted(false);
		} catch (error) {
			console.error('Remove image failed: ', error);
		}
	};

	const restoreImageFromAws = async () => {
		try {
			const objectKey = getObjectKey(content?.imageUrl);
			await restoreImage({ objectKey });
		} catch (error) {
			console.error('Restore image failed: ', error);
		}
	};

	useEffect(
		() => {
			if (imageDeleted) restoreImageFromAws();
			// delete uploaded image from aws if user cancels
			if (
				imageContent?.imageUrl &&
				content?.imageUrl !== imageContent?.imageUrl
			) {
				handleDelete();
			}
		}, // eslint-disable-next-line react-hooks/exhaustive-deps
		[cancelTrigger],
	);

	useEffect(() => {
		const newData = {
			content: {
				...imageContent,
				overlayText: {
					richText: editorState,
					plainText: toPlainText(editorState),
				},
				overlayTextPosition,
			},
			config,
		};
		updateModuleSaveData(newData);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [updateModuleSaveData, config]);

	const handleChange = (
		property: ImageProperty,
		event?: ChangeEvent | Event,
		data?: any,
	) => {
		const value = data || (event?.target as HTMLInputElement).value;

		if (property === 'lightbox') {
			const checkbox = (event?.target as HTMLInputElement).checked;
			setImageContent({ ...imageContent, lightbox: checkbox });
		} else setImageContent({ ...imageContent, [property]: value });
	};

	const uploadFilesToAws = async (files: FileList | null) => {
		if (!files) return;

		const file = files[0];
		const result = await getUploadUrl({
			folder: `${project.slug}/modules/draft/image`,
			filename: file.name,
		});

		if (result.error) return;

		const uploadURL = result.data.getUploadUrl;

		const res = await fileUploadApi(uploadURL, file, setProgress);
		if (!res) return notify('Failed to upload image!', 'error');
		const imageUrl = res.split('?')[0];

		// store in state
		setImageContent({ ...imageContent, imageUrl, fileName: file.name });
	};

	const onDelete = async () => {
		await showDeleteConfirmation(
			{
				title: 'Image Delete',
				message: `Are you sure you want to delete ${
					imageContent?.fileName || 'this'
				} image?`,
			},
			handleDelete,
		);
	};

	return (
		<Box display="flex" flexDirection="column" px={2} pb={1}>
			<UploadImage
				handleUpload={uploadFilesToAws}
				progress={progress}
				handleChange={handleChange}
				handleDelete={onDelete}
				imageContent={imageContent}
				setConfirmDisabled={setConfirmDisabled}
				showImageScale={false}
				showImagePosition={false}
				showLightBox={true}
			/>
			<TextButton
				sx={{
					display: 'block',
					mb: 2,
					textTransform: 'none',
					alignSelf: 'start',
					fontSize: '1em',
				}}
				onClick={() => setShowAdvanceSettings(!showAdvanceSettings)}
			>
				{`${!showAdvanceSettings ? 'Show' : 'Hide'}`} advance settings
			</TextButton>
			{showAdvanceSettings && (
				<Box p={2}>
					<Grid container spacing={2}>
						<Grid item xs={6}>
							<label>Overlay Text</label>
							<TextEditor
								editorState={editorState}
								setEditorState={setEditorState}
								selectedFields={[
									'link',
									'heading-one',
									'heading-two',
									'heading-three',
									'heading-four',
									'heading-five',
									'heading-six',
									'numbered-list',
									'bulleted-list',
									'left',
									'center',
									'right',
									'justify',
								]}
							/>
						</Grid>
						<Grid item xs={6} sx={{ paddingLeft: '25px !important' }}>
							<label>Preview</label>
							<CommunityModuleInstance
								config={{}}
								content={{
									...imageContent,
									overlayText: {
										richText: editorState,
										plainText: toPlainText(editorState),
									},
									overlayTextPosition,
								}}
								type={MODULE_TYPE.IMAGE as any}
								user={null}
								useMutation={() => [null, () => ({ error: null })]}
								isAdminRendering={true}
							/>
						</Grid>
					</Grid>

					<PositionSelector
						onSelect={(value) => setOverlayTextPosition(value)}
						selected={overlayTextPosition || 'middle-center'}
						label="Overlay Text"
					/>
				</Box>
			)}
		</Box>
	);
};
