import { Box, Button } from '@mui/material';
import { useState } from 'react';
import { DragDropContext, DropResult, Draggable } from 'react-beautiful-dnd';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { FieldChoice } from '../../../types/formBuilderConfig';
import { FieldOption } from './FieldOption';
import StrictModeDroppable from 'components/common/StrictModeDroppable';

interface FieldOptionsConfigureProps {
	type: string;
	choices: Array<FieldChoice>;
	onAddChoice: () => void;
	onRemoveChoice: (optionOrdinal: number) => void;
	onUpdateChoice: (optionOrdinal: number, label: string) => void;
	onSortChoice: (startIndex: number, endIndex: number) => void;
}

export const FieldOptionsConfigure = (props: FieldOptionsConfigureProps) => {
	const { choices, onAddChoice, onUpdateChoice, onRemoveChoice, onSortChoice } =
		props;
	const [dragDisabled, setDragDisabled] = useState(true);

	const handleDragEnd = (result: DropResult) => {
		if (!result.destination) {
			return;
		}

		if (result.destination.index === result.source.index) {
			return;
		}

		onSortChoice(result.source.index, result.destination.index);
	};
	return (
		<Box
			sx={{
				marginTop: '10px',
			}}
		>
			{/* <Typography>Field Options</Typography> */}
			<Box display="flex" flexDirection="column">
				<DragDropContext onDragEnd={handleDragEnd}>
					<StrictModeDroppable droppableId="field-options">
						{(provided) => (
							<div ref={provided.innerRef} {...provided.droppableProps}>
								{choices &&
									choices.map((c, index) => {
										return (
											<Draggable
												key={index}
												draggableId={`${index}`}
												index={index}
												isDragDisabled={dragDisabled}
											>
												{(draggableProvided) => (
													<div
														ref={draggableProvided.innerRef}
														{...draggableProvided.draggableProps}
														{...draggableProvided.dragHandleProps}
													>
														<FieldOption
															onToggleDrag={(enabled: boolean) =>
																setDragDisabled(!enabled)
															}
															text={c.label}
															ordinal={c.ordinal}
															onDeleteClick={() => onRemoveChoice(c.ordinal)}
															onLabelUpdate={(label: string) =>
																onUpdateChoice(c.ordinal, label)
															}
														/>
													</div>
												)}
											</Draggable>
										);
									})}
								{provided.placeholder}
							</div>
						)}
					</StrictModeDroppable>
				</DragDropContext>
				<Button
					sx={{ textTransform: 'none', fontSize: 16, marginRight: 'auto' }}
					startIcon={<AddCircleOutlineIcon />}
					onClick={onAddChoice}
				>
					Add Field Option
				</Button>
			</Box>
		</Box>
	);
};
