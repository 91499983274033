import { Box, Button, Grid, Typography } from '@mui/material';
import { useAuth0 } from '@auth0/auth0-react';
import { useLastRedirectURL } from '../hooks/useLastRedirectUrl';
import queryString from 'query-string';
import { LoginRedirect } from '../types/loginRedirect';

export default function Login() {
	const { loginWithRedirect, isAuthenticated } = useAuth0();
	const { setLastRedirectURL } = useLastRedirectURL();

	if (!isAuthenticated) {
		const { search } = window.location;
		const parsedQueryString = queryString.parse(
			search,
		) as unknown as LoginRedirect;

		setLastRedirectURL(parsedQueryString.redirect);
	}

	return (
		<Grid item xs={12} md={6} style={{ margin: 'auto' }}>
			<Box maxWidth={350} ml={8}>
				<Typography
					align="left"
					data-testid="sign-in-greeting"
					variant="h5"
					sx={{ marginBottom: '10px' }}
				>
					coUrbanize Community App
				</Typography>
				<Typography variant="body1" align="left" sx={{ marginBottom: '10px' }}>
					Community allows you present your development project and reach out to
					the local community and mitigate any potential concerns.
				</Typography>
				<Button
					data-testid="sign-in-greeting-button"
					variant="contained"
					color="primary"
					onClick={() => loginWithRedirect({ fragment: 'cms' })}
					sx={{ marginBottom: '10px' }}
				>
					Continue to Sign In
				</Button>
			</Box>
		</Grid>
	);
}
