import { FC, SyntheticEvent, useState } from 'react';
import { Box, Button, Fade, Tab, Tabs } from '@mui/material';

import './carouselOverrides.css';
import Dialog from '../../common/Dialog';
import UserManagementForm from './forms/UserManagementForm';
import EditUserForm from './forms/EditUserForm';
import TabPanel from './forms/TabPanel';
import UserProjectForm from './forms/UserProjectForm';
import { USER_TYPES } from '../../../constants/userTypes';
import UserFollowedProjects from './forms/UserFollowedProjects';

const a11yProps = (index: number) => {
	return {
		id: `simple-tab-${index}`,
		'aria-controls': `simple-tabpanel-${index}`,
	};
};

interface EditUserModalProps {
	open: boolean;
	onClose: () => void;
	selectedUser?: any;
	onUserUpdate: (user: any) => void;
	onResetPassword: (email: string) => void;
}

const EditUserModal: FC<EditUserModalProps> = (props) => {
	const { open, onClose, selectedUser } = props;
	const [value, setValue] = useState(0);

	const handleChange = (event: SyntheticEvent, newValue: number) => {
		setValue(newValue);
	};

	const canViewProjects =
		selectedUser?.userType === USER_TYPES.CLIENT ||
		selectedUser?.userType === USER_TYPES.INTERNAL;

	const isPublicUser = selectedUser?.userType === USER_TYPES.PUBLIC;

	return (
		<Dialog
			open={open}
			title="Edit User"
			buttonContainerStyle={{ pr: 3, pb: 3 }}
			maxWidth="sm"
			LeftButton={
				<Button variant="outlined" onClick={() => onClose()}>
					Close
				</Button>
			}
			RightButton={
				value === 0 && (
					<Button form="edit-user-form" type="submit" variant="outlined">
						Save
					</Button>
				)
			}
		>
			<Fade in={open}>
				<Box height={400}>
					<Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
						<Tabs
							value={value}
							onChange={handleChange}
							aria-label="basic tabs example"
						>
							<Tab label="Details" {...a11yProps(0)} />
							{isPublicUser && <Tab label="Following" {...a11yProps(1)} />}
							{canViewProjects && <Tab label="Projects" {...a11yProps(1)} />}
							<Tab label="Management" {...a11yProps(2)} />
						</Tabs>
					</Box>
					<TabPanel value={value} index={0}>
						<EditUserForm
							onClose={props.onClose}
							selectedUser={props.selectedUser}
							onUserUpdate={(user) => {
								props.onUserUpdate(user);
							}}
						/>
					</TabPanel>
					{isPublicUser && (
						<TabPanel value={value} index={1}>
							<UserFollowedProjects userId={selectedUser.id} />
						</TabPanel>
					)}
					{canViewProjects && (
						<TabPanel value={value} index={1}>
							<UserProjectForm user={selectedUser} />
						</TabPanel>
					)}
					<TabPanel value={value} index={2}>
						<UserManagementForm
							onResetPassword={props.onResetPassword}
							user={props.selectedUser}
							onUserUpdate={(user) => {
								props.onUserUpdate(user);
								props.onClose();
							}}
							onClose={onClose}
						/>
					</TabPanel>
				</Box>
			</Fade>
		</Dialog>
	);
};
export default EditUserModal;
