import { useTheme } from '@mui/material';
import { FC, CSSProperties } from 'react';

interface MultipleSelectIconProps {
	style?: CSSProperties;
	size?: number;
	color?: string;
}

const MultipleSelectIcon: FC<MultipleSelectIconProps> = (props) => {
	const theme = useTheme();
	const {
		style,
		size = 24,
		color = theme.customPalette.basicColors.dark,
	} = props;

	return (
		<svg
			style={style}
			width={size}
			height={size}
			viewBox="0 0 18 18"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M16 0H2C0.89 0 0 0.9 0 2V16C0 17.1 0.89 18 2 18H16C17.11 18 18 17.1 18 16V2C18 0.9 17.11 0 16 0ZM7 14L2 9L3.41 7.59L7 11.17L14.59 3.58L16 5L7 14Z"
				fill={color}
				fillOpacity="0.87"
			/>
		</svg>
	);
};

export default MultipleSelectIcon;
