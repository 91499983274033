import React from 'react';
import { Env } from '../types/env';

const port: string | undefined = process.env.REACT_APP_PORT;
const baseUrl: string | undefined = process.env.REACT_APP_AUTH0_BASE_URL;
const apiServerUrl: string | undefined = process.env.REACT_APP_GRAPH_URI;
const auth0Domain: string | undefined = process.env.REACT_APP_AUTH0_DOMAIN;
const auth0ClientId: string | undefined = process.env.REACT_APP_AUTH0_CLIENT_ID;
const auth0Audience: string | undefined =
	process.env.REACT_APP_AUTH0_API_AUDIENCE;
const googleApiKey: string | undefined = process.env.REACT_APP_GOOGLE_API_KEY;
const intercomWidgetId: string | undefined =
	process.env.REACT_APP_INTERCOM_WIDGET_ID;

const isEnvValid =
	port &&
	baseUrl &&
	apiServerUrl &&
	auth0Domain &&
	auth0Audience &&
	auth0ClientId &&
	googleApiKey &&
	intercomWidgetId;

if (!isEnvValid) {
	throw new Error('Missing environment variables.');
}

const dotenv: Env = {
	port: port,
	baseUrl: baseUrl,
	apiServerUrl: apiServerUrl,
	auth0Domain: auth0Domain,
	auth0Audience: auth0Audience,
	auth0ClientId: auth0ClientId,
	googleApiKey: googleApiKey,
	intercomWidgetId: intercomWidgetId,
};

export const EnvContext = React.createContext<Env>(dotenv);

export const useEnv = () => {
	const context = React.useContext(EnvContext);
	if (!context) {
		throw new Error('useEnv must be used within a EnvProvider');
	}
	return context;
};

export const EnvProvider: React.FC<{ children: React.ReactNode }> = (props) => {
	return <EnvContext.Provider value={dotenv} {...props} />;
};
