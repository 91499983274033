import { useEffect, useState } from 'react';
import { useMutation } from 'urql';
import isEqual from 'lodash/isEqual';
import differenceWith from 'lodash/differenceWith';
import {
	publishPageMutation,
	unPublishPageMutation,
} from 'api/mutation/page.mutation';
import { useSnackBar } from 'providers/SnackBarProvider';
import { ModuleInstance, Page, TemplateInstance } from 'types/types';
import { getFormattedModuleInstances } from './publishPageUtils';

interface ModuleDetail {
	[key: string]: number;
}

const usePublishPage = (
	page: Page,
	templateInstances: TemplateInstance[],
	publishedTemplateInstances?: TemplateInstance[],
	setPublishedTemplateInstances?: any,
) => {
	const [additions, setAdditions] = useState<ModuleInstance[]>([]);
	const [additionObj, setAdditionObj] = useState<ModuleDetail>({});
	const [updateObj, setUpdateObj] = useState<ModuleDetail>({});
	const [removals, setRemovals] = useState<ModuleInstance[]>([]);
	const [removalObj, setRemovalsObj] = useState<ModuleDetail>({});
	const [isPublishing, setIsPublishing] = useState<boolean>(false);

	// TODO: When user delete and add a module on the same spot the modal should also show the deleted module (it only shows the new one)
	useEffect(() => {
		let draftModuleInstances: ModuleInstance[] = [];
		let publishedModuleInstances: ModuleInstance[] = [];
		draftModuleInstances = getFormattedModuleInstances(
			templateInstances,
			draftModuleInstances,
		);
		publishedModuleInstances = getFormattedModuleInstances(
			publishedTemplateInstances || [],
			publishedModuleInstances,
		);
		let addedModuleInstances = differenceWith(
			draftModuleInstances,
			publishedModuleInstances,
			isEqual,
		);
		let removedModuleInstances = differenceWith(
			publishedModuleInstances,
			draftModuleInstances,
			isEqual,
		);
		addedModuleInstances = addedModuleInstances.map((module: any) => {
			const index = removedModuleInstances.findIndex((item: any) => {
				return (
					item.config.sectionId === module.config.sectionId &&
					item.templateOrdinal === module.templateOrdinal
				);
			});

			module.action = 'added';
			if (index > -1) {
				module.action = 'updated';
				removedModuleInstances.splice(index, 1);
			}
			return module;
		});
		if (publishedTemplateInstances !== undefined) {
			setAdditions(addedModuleInstances);
			setRemovals(removedModuleInstances);
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [publishedTemplateInstances, templateInstances]);

	useEffect(() => {
		const obj1: ModuleDetail = {};
		const obj2: ModuleDetail = {};
		additions.forEach((item: any) => {
			if (item.action === 'added') {
				if (item?.moduleType?.name && !obj1[item.moduleType.name]) {
					obj1[item.moduleType.name] = 1;
				} else if (obj1[item.moduleType.name]) {
					obj1[item.moduleType.name] += 1;
				}
			} else if (item.action === 'updated') {
				if (item?.moduleType?.name && !obj2[item.moduleType.name]) {
					obj2[item.moduleType.name] = 1;
				} else if (obj2[item.moduleType.name]) {
					obj2[item.moduleType.name] += 1;
				}
			}
		});
		setAdditionObj(obj1);
		setUpdateObj(obj2);
	}, [additions]);

	useEffect(() => {
		const obj1: ModuleDetail = {};
		removals.forEach((item: any) => {
			if (item?.moduleType?.name && !obj1[item.moduleType.name]) {
				obj1[item.moduleType.name] = 1;
			} else if (obj1[item.moduleType.name]) {
				obj1[item.moduleType.name] += 1;
			}
		});
		setRemovalsObj(obj1);
	}, [removals]);

	const { notify } = useSnackBar();
	const [, publishPage] = useMutation(publishPageMutation('id'));
	const [, unPublishPage] = useMutation(unPublishPageMutation('id'));

	const handlePublishPage = (silent = false) => {
		setIsPublishing(true);
		publishPage({ id: page.id }).then((result) => {
			setIsPublishing(false);
			if (result.error) {
				console.error('Error publishing page', result.error);
				notify('Error publishing page', 'error');
				return;
			}
			if (silent) return;
			// update publishedTemplateInstances with all existing templateInstances
			setPublishedTemplateInstances(templateInstances);

			notify('Page successfully published');
			const baseUrl = process.env.REACT_APP_SITE_BASE_URL;
			window.open(`${baseUrl}/${page?.project?.slug}/${page.slug}`, '_blank');
		});
	};

	return {
		additions,
		additionObj,
		updateObj,
		removals,
		removalObj,
		handlePublishPage,
		isPublishing,
	};
};

export default usePublishPage;
