export const activeRoute = (id: number) => {
	if (!window.location.pathname.includes('projects/')) return;

	const projectId = window.location.pathname
		.split('projects/')[1]
		.split('/')[0];

	return id === Number(projectId);
};

export const activeProject = () => {
	if (!window.location.pathname.includes('projects/')) return;

	const projectId = window.location.pathname
		.split('projects/')[1]
		.split('/')[0];

	return Number(projectId);
};
