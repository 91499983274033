import { FC, useState } from 'react';
import { Grid, Typography } from '@mui/material';
import TextMouseOver from './TextMouseOver';

interface PositionSelectorProps {
	onSelect: (position: string | null) => void;
	selected?: string | null;
	label?: string | null;
}

const positions = [
	'top-left',
	'top-center',
	'top-right',
	'middle-left',
	'middle-center',
	'middle-right',
	'bottom-left',
	'bottom-center',
	'bottom-right',
];

const PositionSelector: FC<PositionSelectorProps> = (props) => {
	const { onSelect, selected, label } = props;
	const [active, setActive] = useState(positions.indexOf(selected as string));

	return (
		<Grid>
			<Typography mb={1}>
				Where is {label ? label : 'it'} positioned?
			</Typography>
			<Grid
				display="grid"
				gridTemplateColumns="1fr 1fr 1fr"
				gap={0.4}
				width={60}
			>
				{[...new Array(9)].map((_, i) => (
					<TextMouseOver text={positions[i]} key={i}>
						<Grid
							position="relative"
							item
							width={20}
							height={20}
							bgcolor={
								active === i || selected === positions[i]
									? '#146DFE'
									: '#D6E7FF'
							}
							borderRadius="4px"
							sx={{ cursor: 'pointer' }}
							onClick={() => {
								onSelect(positions[i]);
								setActive(i);
							}}
						>
							{(active === i || selected === positions[i]) && (
								<Grid
									borderRadius="50%"
									bgcolor="white"
									width={8}
									height={8}
									position="absolute"
									top="50%"
									left="50%"
									sx={{ transform: 'translate(-50%, -50%)' }}
								/>
							)}
						</Grid>
					</TextMouseOver>
				))}
			</Grid>
		</Grid>
	);
};

export default PositionSelector;
