export enum MODULE_TYPE {
	FREE_TEXT = 'Free Text',
	TIMELINE = 'Timeline',
	MAP = 'Map',
	FORM = 'Form',
	IMAGE = 'Image',
	VIDEO = 'Video',
	CAROUSEL = 'Carousel',
	PUBLIC_BENEFIT = 'Public Benefit',
	INFORMATION_PLANS = 'Information & Plans',
	UPDATES = 'Updates',
	CALL_TO_ACTION = 'Call To Action',
	HERO_IMAGE = 'Hero Image',
	FEEDBACK = 'Feedback',
	LOCATION_PHASE = 'Location & Phase',
	EMBED = 'Embed',
	SUBSCRIBE_BOX = 'Subscribe Box',
}
