import { FC, useState } from 'react';
import { DragDropContext, Draggable, DropResult } from 'react-beautiful-dnd';
import StrictModeDroppable from 'components/common/StrictModeDroppable';
import { Box, IconButton, Paper, useTheme } from '@mui/material';
import { Page } from 'types/types';
import MenuIcon from '@mui/icons-material/Menu';
import EditIcon from 'components/icons/EditIcon';
import ConfirmationDialog from 'components/common/ConfirmationDialog';
import { EditPage } from './EditPage';
import produce from 'immer';
import { useSaveActionConfirmation } from 'hooks/useActionConfirmation';
import { updatePageMutation } from 'api/mutation/page.mutation';
import { useMutation } from 'urql';
import { isEmpty, pick } from 'lodash';
import * as Yup from 'yup';
import { useSnackBar } from 'providers/SnackBarProvider';

interface EditPagesProps {
	sortedPages: Page[];
	setSortedPages: any;
}

const validationSchema = Yup.object().shape({
	name: Yup.string()
		.max(25, 'Must be 15 characters or less')
		.min(2, 'Must be 2 characters or more')
		.required('Required'),
	slug: Yup.string()
		.max(25, 'Must be 15 characters or less')
		.min(2, 'Must be 2 characters or more')
		.required('Required')
		.test(
			'is-alphanumeric-hyphen',
			'Must only contain alphanumeric characters and hyphens',
			(value) => /^[a-zA-Z0-9-]*$/.test(value!),
		),
	externalUrlOverride: Yup.string()
		.url('Invalid URL')
		.transform((value) => {
			if (value && !/^https?:\/\//i.test(value)) {
				return `http://${value}`;
			}
			return value;
		})
		.nullable(),
});

type StringObject = {
	[key: string]: string | undefined;
};

type BooleanObject = {
	[key: string]: boolean;
};

const generateSlug = (text: string) => {
	const trimmedText = text.trim();
	const lowercasedText = trimmedText.toLowerCase();
	return lowercasedText.replace(/\s+/g, '-').replace(/[^a-z0-9-]/g, '');
};

export const EditPages: FC<EditPagesProps> = (props) => {
	const { sortedPages, setSortedPages } = props;

	const theme = useTheme();

	const [pageEdit, setPageEdit] = useState<Page | null>(null);
	const [errors, setErrors] = useState<StringObject>({});
	const [touched, setTouched] = useState<BooleanObject>({});

	const { notify } = useSnackBar();
	const saveActionConfirmation = useSaveActionConfirmation();
	const [, updatePage] = useMutation(updatePageMutation);

	const customUrl =
		sortedPages[0]?.project?.customUrl &&
			sortedPages[0]?.project?.customUrlEnabledDate
			? sortedPages[0]?.project?.customUrl
			: null;

	const onPageSort = (sourceIndex: number, destinationIndex: number) => {
		const pages = [...sortedPages];
		const moved = pages.splice(sourceIndex, 1)[0];
		pages.splice(destinationIndex, 0, moved);

		const newOrderedPages = pages.map((p, index) => {
			return { ...p, ordinal: index + 1 };
		});
		setSortedPages(newOrderedPages);
	};

	const orderedPages = [...sortedPages].sort(
		(a, b) => (a.ordinal || 0) - (b.ordinal || 0),
	);

	const handleDragEnd = (result: DropResult) => {
		if (!result.destination) {
			return;
		}

		if (result.destination.index === result.source.index) {
			return;
		}

		onPageSort(result.source.index, result.destination.index);
	};

	const handleEditPage = async (updatedPage: any) => {
		// TODO : Update main page builder
		const res = await updatePage({
			existingPageData: pick(updatedPage, [
				'id',
				'name',
				'slug',
				'externalUrlOverride',
			]),
		});
		if (res?.data?.updatePage?.id) notify('Page saved successfully', 'success');

		setSortedPages((oldState: any) =>
			produce(oldState, (newState: any) => {
				const index = newState.findIndex((p: Page) => p.id === updatedPage.id);
				newState[index] = {
					...newState[index],
					...updatedPage,
				};
			}),
		);
		setPageEdit(null);
	};

	const validateField = async (fieldName: string, value: any) => {
		try {
			await validationSchema.validateAt(fieldName, { [fieldName]: value });
			const prevErrors = { ...errors };
			delete prevErrors[fieldName];
			setErrors(prevErrors);
		} catch (error: any) {
			setErrors((prevErrors) => ({
				...prevErrors,
				[fieldName]: error.message,
			}));
		}
	};

	const handleFieldBlur = (e: any) => {
		const { id, value } = e.target;
		validateField(id, value);
		setTouched({ ...touched, [id]: true });
	};

	const handleFieldChange = (e: any) => {
		const { id, value } = e.target;
		const newPageData = { ...pageEdit, [id]: value } as Page;
		if (id === 'name' && !['home', 'updates'].includes(newPageData.slug)) {
			newPageData.slug = generateSlug(value);
		}
		setPageEdit(newPageData);
		touched[id] && validateField(id, value);
	};

	return (
		<Box>
			<DragDropContext onDragEnd={handleDragEnd}>
				<StrictModeDroppable droppableId="pages-edit-items">
					{(provided) => (
						<div ref={provided.innerRef} {...provided.droppableProps}>
							{orderedPages &&
								orderedPages.map((page, index) => {
									return (
										<Draggable
											key={index}
											draggableId={`${index}`}
											index={index}
										>
											{(draggableProvided, snapshot) => (
												<div
													ref={draggableProvided.innerRef}
													{...draggableProvided.draggableProps}
													{...draggableProvided.dragHandleProps}
												>
													<Paper
														variant="outlined"
														sx={{
															width: 400,
															p: 1,
															mb: 1,
															display: 'grid',
															gridTemplateColumns: 'auto 1fr auto',
															alignItems: 'center',
															borderColor: `${snapshot.isDragging
																	? theme.customPalette.primaryColors
																		.coreGreen[1]
																	: ''
																}`,
															borderWidth: `${snapshot.isDragging ? '2px !important' : '1px'
																}`,
														}}
													>
														<MenuIcon />
														<Box my={0.5} ml={1}>
															{page.name}
														</Box>
														<IconButton onClick={() => setPageEdit(page)}>
															<EditIcon />
														</IconButton>
													</Paper>
												</div>
											)}
										</Draggable>
									);
								})}
							{provided.placeholder}
						</div>
					)}
				</StrictModeDroppable>
			</DragDropContext>
			<ConfirmationDialog
				open={!!pageEdit}
				title="Edit Page Link Details"
				onConfirm={async () => {
					if (!pageEdit) return;
					await saveActionConfirmation(
						{
							title: 'Edit Page Confirmation',
							message: (
								<>
									Are you sure you want to name the page: <b>{pageEdit.name}</b>{' '}
									{pageEdit.externalUrlOverride ? (
										<>
											{' '}
											with a url redirect to : <br />
											<b>{pageEdit.externalUrlOverride}</b>
										</>
									) : (
										<>
											{' '}
											using url : <br />{' '}
											<b>
												{`${customUrl
														? `https://${customUrl}`
														: process.env.REACT_APP_SITE_BASE_URL
													}/${pageEdit.slug}`}
											</b>
										</>
									)}
								</>
							),
							confirmLabel: 'Save',
						},
						async () => await handleEditPage(pageEdit),
						() => { },
					);
				}}
				onCancel={() => {
					setPageEdit(null);
				}}
				confirmLabel="Save"
				style={{ width: 600 }}
				paddingSize="large"
				confirmDisabled={!isEmpty(errors)}
			>
				<EditPage
					page={pageEdit!}
					handleFieldChange={handleFieldChange}
					handleFieldBlur={handleFieldBlur}
					errors={errors}
				/>
			</ConfirmationDialog>
		</Box>
	);
};
