import { ContentLoader } from './contentLoaderType';
import graphqlConn from '../utils/graphqlConn';
import getInfo from '../utils/getInfo';

export const publicBenefitLoader: ContentLoader = async (
	content,
	project,
	getToken,
) => {
	const query = `
		query ($projectId: Int!) {
			publicBenefitsByProject(projectId: $projectId) {
				id
				name
				description
				ordinal
				image {
					id
					fileName
					imageUrl
					altTag
				}
				imageDescription
				imageScale
				boundingBoxPosition
			}
		}
	`;

	const res = await graphqlConn(getToken, query, {
		projectId: project?.id,
	});

	return {
		data: res?.data?.publicBenefitsByProject || [],
		info: getInfo(content),
	};
};
