import { FC, MouseEvent, Dispatch, SetStateAction } from 'react';
import { Box, IconButton, Tooltip, Typography, useTheme } from '@mui/material';
import {
	DragHandle as DragHandleIcon,
	DeleteOutline as DeleteOutlineIcon,
} from '@mui/icons-material';
import { usePausedContext } from '@coUrbanize/community-modules';
import {
	Project,
	Template,
	TemplateInstance as TemplateInstanceType,
} from '../../../types/types';
import TemplateInstanceArea from './TemplateInstanceArea';
import { TemplatePickerContainer } from '../templatePicker/TemplatePickerContainer';
import { useDeleteActionConfirmation } from '../../../hooks/useActionConfirmation';
import User from 'types/user';
import { AddSectionBlock } from './addSectionBlock';

export interface TemplateInstanceProps {
	templateInstance: TemplateInstanceType;
	handle: any;
	handleDeleteTemplateInstance: (templateID: number) => void;
	anchorEl: HTMLButtonElement | null;
	setAnchorEl: (anch: HTMLButtonElement | null) => void;
	handleCreateTemplateInstance: (template: Template, ordinal?: number) => void;
	handleClick: (event: MouseEvent<HTMLButtonElement>, pickerId: string) => void;
	user: User | null;
	project: Project;
	setTemplateInstances: (tis: TemplateInstanceType[]) => void;
	pickerId: string;
	pickerOpen: string;
	handlePickerClick: Dispatch<SetStateAction<string>>;
}

const TemplateInstance: FC<TemplateInstanceProps> = (props) => {
	const {
		templateInstance,
		handle,
		anchorEl,
		setAnchorEl,
		handleCreateTemplateInstance,
		handleDeleteTemplateInstance,
		handleClick,
		user,
		project,
		setTemplateInstances,
		pickerId,
		pickerOpen,
		handlePickerClick,
	} = props;

	const { id, template, ordinal } = templateInstance;

	const layout = template.layout;
	const cols = layout.gridTemplateColumns;
	const sections = layout.gridTemplateSections;
	const theme = useTheme();
	const { isPaused } = usePausedContext();

	const showDeleteConfirmation = useDeleteActionConfirmation();
	const handleDeleteClick = async () => {
		await showDeleteConfirmation(
			{
				title: 'Delete Template Instance',
				message: 'Are you sure you want to delete this template instance?',
			},
			() => handleDeleteTemplateInstance(id),
		);
	};

	const onTemplateSelected = (template: Template) => {
		onPickerClose();
		handleCreateTemplateInstance(template, ordinal + 1);
	};

	const onPickerClose = () => {
		setAnchorEl(null);
		handlePickerClick('');
	};

	return (
		<Box textAlign="center">
			<Box
				mt={2}
				style={{ backgroundColor: 'white' }}
				border={`2px solid ${theme.customPalette.borderColors.dark}`}
				borderRadius={2}
			>
				<div
					{...handle}
					style={{
						height: '40px',
						padding: 12,
						display: 'flex',
						flexDirection: 'row',
						justifyContent: 'space-between',
						alignContent: 'center',
						alignItems: 'center',
						borderRadius: 8,
						position: 'relative',
					}}
				>
					<Typography fontSize={16} align="left">
						{template.name}
					</Typography>
					<DragHandleIcon
						htmlColor={theme.customPalette.primaryColors.sky[1]}
						sx={{
							fontSize: 25,
							position: 'absolute',
							left: '50%',
							marginLeft: '-12.5px',
						}}
					/>
					<Tooltip title="Delete Section Block" placement="top" arrow={true}>
						<IconButton disabled={!!isPaused} onClick={handleDeleteClick}>
							<DeleteOutlineIcon fontSize="small" aria-valuetext="Delete" />
						</IconButton>
					</Tooltip>
				</div>
				<Box p={2} mb={1}>
					<Box
						display="grid"
						gap={1}
						gridTemplateColumns={cols
							.map((c) => 'minmax(0, ' + c + 'fr)')
							.join(' ')}
						gridTemplateRows="max-content"
					>
						{sections.map((section, i) => (
							<TemplateInstanceArea
								key={i}
								section={section}
								user={user}
								project={project}
								templateInstance={templateInstance}
								setTemplateInstances={setTemplateInstances}
							/>
						))}
					</Box>
				</Box>
			</Box>

			<AddSectionBlock handleClick={(e) => handleClick(e, pickerId)} />

			<TemplatePickerContainer
				anchorEl={anchorEl}
				onPickerClose={onPickerClose}
				onTemplateSelected={onTemplateSelected}
				pickerOpen={pickerOpen === pickerId}
			/>
		</Box>
	);
};

export default TemplateInstance;
