import { FC, useState } from 'react';
import {
	Box,
	Chip,
	InputLabel,
	Typography,
	useTheme,
	Tooltip,
} from '@mui/material';
import { Field, FormikErrors, FormikTouched } from 'formik';
import MilestoneIconsContainer from './MilestoneIconsContainer';
import FileField from './FileField';
import { removeFileMutation } from '../../api/mutation/s3.mutations';
import { getObjectKey } from '../../utility/getObjectKey';
import { useMutation } from 'urql';
import dayjs from 'dayjs';
import { Cell, Grid } from 'styled-css-grid';
import RegularTextInput from './styled/RegularTextInput';
import TextButton from './styled/TextButton';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { CustomDayPicker } from './DatePicker/CustomDayPicker';
import { OpenYearPickerIcon } from './DatePicker/OpenYearPickerIcon';
import { LeftArrowIcon } from './DatePicker/LeftArrowIcon';
import { RightArrowIcon } from './DatePicker/RightArrowIcon';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { useDeleteActionConfirmation } from '../../hooks/useActionConfirmation';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import HelpIcon from '@mui/icons-material/Help';
import { useRouteContext } from '../../routes/Layout';

type File = { fileName: string; url: string };
export type EventFile = { files: File[] };

export enum TimeLineFormFieldsEnum {
	// eslint-disable-next-line no-unused-vars
	ID = 'id',
	// eslint-disable-next-line no-unused-vars
	NAME = 'name',
	// eslint-disable-next-line no-unused-vars
	DESCRIPTION = 'description',
	// eslint-disable-next-line no-unused-vars
	START_DATE = 'startDate',
	// eslint-disable-next-line no-unused-vars
	START_TIME = 'startTime',
	// eslint-disable-next-line no-unused-vars
	END_DATE = 'endDate',
	// eslint-disable-next-line no-unused-vars
	END_TIME = 'endTime',
	// eslint-disable-next-line no-unused-vars
	DISPLAY_DATE = 'displayDate',
	// eslint-disable-next-line no-unused-vars
	MILESTONE_ICON = 'milestoneIcon',
	// eslint-disable-next-line no-unused-vars
	FILE = 'file',
}

export interface EventInfo {
	[TimeLineFormFieldsEnum.ID]: number;
	[TimeLineFormFieldsEnum.NAME]: string;
	[TimeLineFormFieldsEnum.DESCRIPTION]?: string;
	[TimeLineFormFieldsEnum.START_DATE]: string;
	[TimeLineFormFieldsEnum.START_TIME]?: string;
	[TimeLineFormFieldsEnum.END_DATE]?: string;
	[TimeLineFormFieldsEnum.END_TIME]?: string;
	[TimeLineFormFieldsEnum.DISPLAY_DATE]?: string;
	[TimeLineFormFieldsEnum.MILESTONE_ICON]?: string;
	[TimeLineFormFieldsEnum.FILE]?: EventFile;
}

interface TimelineFormProps {
	event: EventInfo;
	touched: FormikTouched<EventInfo>;
	errors: FormikErrors<EventInfo>;
	setFieldValue: (
		field: string,
		value: any,
		shouldValidate?: boolean | undefined,
	) => void;
	setFieldTouched: (
		field: string,
		isTouched?: boolean | undefined,
		shouldValidate?: boolean | undefined,
	) => void;
}

const TimelineForm: FC<TimelineFormProps> = ({
	event: values,
	setFieldValue,
	setFieldTouched,
	touched,
	errors,
}) => {
	const {
		NAME,
		DESCRIPTION,
		START_DATE,
		START_TIME,
		END_DATE,
		END_TIME,
		DISPLAY_DATE,
		MILESTONE_ICON,
		FILE,
	} = TimeLineFormFieldsEnum;

	const [showEndDate, setShowEndDate] = useState(!!values?.endDate);
	// Performance optimization
	const [name, setName] = useState<string>(values.name);
	const [, removeAwsFile] = useMutation(removeFileMutation);
	const theme = useTheme();
	const { activeProjectDetails } = useRouteContext();
	const showDeleteConfirmation = useDeleteActionConfirmation();
	const handleClick = async (fileName: string, url: string) => {
		await showDeleteConfirmation(
			{
				title: 'Delete Attachment',
				message: 'Are you sure you want to remove this attachment?',
			},
			() => deleteFile({ fileName, url }),
		);
	};

	const deleteFile = async (file: File | undefined) => {
		if (!file) return;
		const objectKey = getObjectKey(file.url);
		const deleteFile = await removeAwsFile({ objectKey });
		if (deleteFile?.data?.removeAwsFile) {
			const updatedFiles = values?.file?.files
				? values.file.files.filter(
						(fileData: File) => fileData.fileName !== file.fileName,
				  )
				: [];
			setFieldValue(FILE, { files: updatedFiles });
		}
	};

	return (
		<Box width={700}>
			<InputLabel id={NAME}>Event Name</InputLabel>
			<RegularTextInput
				id={NAME}
				name={NAME}
				size="small"
				fullWidth
				defaultValue={values?.name!}
				// Performance optimization
				onChange={(e) => {
					setName(e?.target.value);
				}}
				onBlur={() => {
					setFieldValue(NAME, name);
				}}
				error={!!errors[NAME]}
				helperText={errors[NAME]}
			/>
			<Typography
				style={{
					fontWeight: 'lighter',
					fontSize: 11,
					marginLeft: 15,
					marginTop: 3,
				}}
			>
				{name?.length || 0}/70
			</Typography>

			<InputLabel sx={{ mt: 1 }} id={DESCRIPTION}>
				Description
			</InputLabel>
			<RegularTextInput
				id={DESCRIPTION}
				name={DESCRIPTION}
				size="small"
				required
				fullWidth
				multiline
				defaultValue={values?.description!}
				onBlur={(e) => {
					setFieldValue(DESCRIPTION, e?.target.value);
				}}
				error={!!errors[DESCRIPTION]}
				helperText={errors[DESCRIPTION]}
			/>

			<LocalizationProvider dateAdapter={AdapterDateFns}>
				<Grid
					style={{ marginTop: -15 }}
					columns={
						'minmax(auto,150px) minmax(auto,150px) 20px minmax(auto,150px) minmax(auto,150px) auto'
					}
					rowGap={'15px'}
					columnGap={'5px'}
					areas={[
						'startLabel startLabel . endLabel endLabel .',
						'startDate startTime . endDate endTime .',
						'optionalLabel optionalLabel optionalLabel  optionalLabel optionalLabel optionalLabel',
					]}
				>
					<Cell area={START_DATE}>
						<InputLabel id="start-date-label">Start Date</InputLabel>
						<DatePicker
							components={{
								SwitchViewIcon: OpenYearPickerIcon,
								LeftArrowIcon: LeftArrowIcon,
								RightArrowIcon: RightArrowIcon,
							}}
							value={values?.startDate || ''}
							onChange={(date) => {
								setFieldValue(START_DATE, date);
							}}
							renderDay={(day, selectedDays, pickerDayProps) => {
								return <CustomDayPicker {...pickerDayProps} />;
							}}
							renderInput={(params) => (
								<RegularTextInput
									{...params}
									id={START_DATE}
									name={START_DATE}
									onBlur={() => {
										setFieldTouched(START_DATE, true);
									}}
									error={!!(errors[START_DATE] && touched[START_DATE])}
									helperText={touched[START_DATE] && errors[START_DATE]}
									sx={{
										svg: { color: theme.customPalette.primaryColors.sky[1] },
									}}
									size="small"
								/>
							)}
							inputFormat="dd/MM/yyyy"
						/>
					</Cell>
					<Cell area={START_TIME}>
						<InputLabel id="start-time-label">Time</InputLabel>
						<TimePicker
							components={{
								LeftArrowIcon: LeftArrowIcon,
								RightArrowIcon: RightArrowIcon,
							}}
							value={values?.startTime}
							onChange={(date) => {
								const normalizedDate = dayjs(date)
									.set('date', 1)
									.set('month', 0)
									.set('year', 2000);
								setFieldValue(START_TIME, normalizedDate);
							}}
							renderInput={(params) => (
								<RegularTextInput
									{...params}
									error={!!(errors[START_TIME] && touched[START_TIME])}
									helperText={touched[START_TIME] && errors[START_TIME]}
									sx={{
										svg: { color: theme.customPalette.primaryColors.sky[1] },
									}}
									size="small"
								/>
							)}
						/>
					</Cell>

					<Cell area={END_DATE}>
						{!showEndDate && !values?.endDate && (
							<TextButton
								sx={{
									marginTop: '21px',
									textTransform: 'capitalize',
									fontSize: 16,
								}}
								variant="text"
								onClick={() => setShowEndDate(true)}
								startIcon={<AddCircleOutlineIcon />}
							>
								add end time
							</TextButton>
						)}
					</Cell>
					{showEndDate && (
						<>
							<Cell area={END_DATE}>
								<InputLabel id="end-date-label">End Date</InputLabel>
								<DatePicker
									components={{
										SwitchViewIcon: OpenYearPickerIcon,
										LeftArrowIcon: LeftArrowIcon,
										RightArrowIcon: RightArrowIcon,
									}}
									value={values?.endDate || ''}
									onChange={(date) => {
										setFieldValue(END_DATE, date);
									}}
									renderDay={(day, selectedDays, pickerDayProps) => {
										return <CustomDayPicker {...pickerDayProps} />;
									}}
									renderInput={(params) => (
										<RegularTextInput
											{...params}
											error={!!(errors[END_DATE] && touched[END_DATE])}
											helperText={touched[END_DATE] && errors[END_DATE]}
											sx={{
												svg: {
													color: theme.customPalette.primaryColors.sky[1],
												},
											}}
											size="small"
											id={END_DATE}
											name={END_DATE}
											onBlur={() => {
												setFieldTouched(END_DATE, true);
											}}
										/>
									)}
									inputFormat="dd/MM/yyyy"
									minDate={values?.startDate}
								/>
							</Cell>
							<Cell area={END_TIME}>
								<InputLabel id="end-time-label">End Time</InputLabel>
								<TimePicker
									components={{
										LeftArrowIcon: LeftArrowIcon,
										RightArrowIcon: RightArrowIcon,
									}}
									value={values?.endTime}
									onChange={(date) => {
										const normalizedDate = dayjs(date)
											.set('date', 1)
											.set('month', 0)
											.set('year', 2000);
										setFieldValue(END_TIME, normalizedDate);
									}}
									renderInput={(params) => (
										<RegularTextInput
											sx={{
												svg: {
													color: theme.customPalette.primaryColors.sky[1],
												},
											}}
											{...params}
											size="small"
										/>
									)}
								/>
							</Cell>
						</>
					)}
					<Cell area={'optionalLabel'}>
						<InputLabel
							id="optional-label"
							sx={{
								display: 'flex',
								height: 24,
							}}
						>
							Optional Display Date & Time &nbsp;
							<Tooltip
								title={
									<i style={{ fontWeight: 'lighter', fontSize: 14 }}>
										Override start date and time (e.g. "Fall {dayjs().year()}"
										instead of 10/1/{dayjs().year()})
									</i>
								}
							>
								<HelpIcon color="secondary" fontSize="small" />
							</Tooltip>
						</InputLabel>

						<RegularTextInput
							size="small"
							fullWidth
							defaultValue={values?.displayDate!}
							onBlur={(e) => {
								setFieldValue(DISPLAY_DATE, e?.target.value);
							}}
						/>
					</Cell>
				</Grid>
			</LocalizationProvider>

			<Typography sx={{ fontWeight: 'bold', mt: 2, mb: 1 }}>
				Event icon
			</Typography>
			<Field
				component={MilestoneIconsContainer}
				name={MILESTONE_ICON}
				value={values?.milestoneIcon}
			/>

			<Typography sx={{ fontWeight: 'bold', mt: 2, mb: 1 }}>
				Attachments
			</Typography>
			<Box display="flex" mb={1} gap={1} flexWrap="wrap">
				{values?.file?.files.length === 0 && (
					<Typography>No documents attached</Typography>
				)}
				{values?.file?.files.map(({ fileName, url }) => (
					<div key={url}>
						<Chip
							size={'small'}
							color="secondary"
							onDelete={() => handleClick(fileName, url)}
							onClick={() => window.open(url)}
							label={fileName}
						/>
					</div>
				))}
			</Box>

			<Field
				component={FileField}
				name={FILE}
				multiple
				folder={`${activeProjectDetails.slug}/events`}
			/>
		</Box>
	);
};

export default TimelineForm;
