import { FC } from 'react';
import { Box, InputLabel } from '@mui/material';
import RegularTextInput from 'components/common/styled/RegularTextInput';

interface IframeMarkupTabProps {
	embedInfo: { code: string };
	setEmbedInfo: (value: { code: string }) => void;
}

const IframeMarkupTab: FC<IframeMarkupTabProps> = (props) => {
	const { embedInfo, setEmbedInfo } = props;

	return (
		<Box sx={{ minHeight: '205px' }}>
			<InputLabel id="url-label">Code</InputLabel>
			<RegularTextInput
				size="small"
				color="secondary"
				type="url"
				rows={6}
				multiline
				sx={{ mt: 1, mb: 1, width: 318 }}
				aria-describedby="url-error-text"
				value={embedInfo?.code}
				onChange={(e) => setEmbedInfo({ code: e.target.value })}
			/>
		</Box>
	);
};

export default IframeMarkupTab;
