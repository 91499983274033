import {
	Note as NoteIcon,
	Flag as FlagIcon,
	Help as HelpIcon,
	Computer as ComputerIcon,
	PlayCircle as PlayCircleIcon,
	CalendarMonth as CalendarMonthIcon,
	MoreHoriz as MoreHorizIcon,
	Phone as PhoneIcon,
	EventAvailable as EventAvailableIcon,
	Campaign as CampaignIcon,
	Edit as EditIcon,
	ChatBubble as ChatBubbleIcon,
	PhoneIphone as PhoneIphoneIcon,
	Lightbulb as LightbulbIcon,
	Newspaper as NewspaperIcon,
	QuestionAnswer as QuestionAnswerIcon,
} from '@mui/icons-material';
import { Comment, Image } from 'types/types';
import { RichTextContent } from 'types/richTextContent';

export interface UpdateTemplate {
	id: number;
	name?: string;
	title: string;
	richText: RichTextContent;
	published?: Date;
	imageScale?: string;
	boundingBoxPosition?: string;
	image?: Image | null;
	images?: Image[] | null;
}

const templates = (projectName: string) => [
	{
		name: 'Blank',
		title: 'New Blank Update',
		Icon: NoteIcon,
		richText: [
			{
				text: '',
			},
		],
	},
	{
		name: 'Welcome',
		title: `Welcome to the ${projectName}'s outreach site!`,
		Icon: FlagIcon,
		richText: [
			{
				type: 'paragraph',
				style: 'text-align:left;',
				children: [
					{
						text: 'Thanks for visiting our new website! The purpose of this platform is to provide you with information on ',
					},
					{
						text: 'ADD PROJECT NAME HERE ',
						bold: true,
					},
					{
						text: 'and collect your ideas and feedback.',
					},
				],
			},
			{
				type: 'paragraph',
				children: [
					{
						text: '',
					},
				],
			},
			{
				type: 'paragraph',
				style: 'text-align:left;',
				children: [
					{
						text: 'Please make yourself at home! Poke around our map ',
					},
					{
						text: 'ADD A LINK TO THE MAP',
						bold: true,
					},
					{
						text: ' to see what your neighbors are discussing and be sure to comment with your own ideas. Also hit that ‘Subscribe’ button to stay in-the-loop on all project updates.',
					},
				],
			},
			{
				type: 'paragraph',
				children: [
					{
						text: '',
					},
				],
			},
			{
				type: 'paragraph',
				style: 'text-align:left;',
				children: [
					{
						text: 'Everything posted here is sent straight to us at ',
					},
					{
						text: 'ADD PROJECT NAME HERE',
						bold: true,
					},
					{
						text: '. We’re looking forward to hearing from you!',
					},
				],
			},
			{
				type: 'paragraph',
				children: [
					{
						text: '',
					},
				],
			},
		],
	},
	{
		name: 'How does it work?',
		title: 'What is ADD TYPE OF PLANNING PROCESS? (VIDEO)',
		Icon: HelpIcon,
		richText: [
			{
				type: 'paragraph',
				style: 'text-align:left;',
				children: [
					{
						text: 'The purpose of the ',
					},
					{
						text: 'PROJECT NAME ',
						bold: true,
					},
					{
						text: 'website is to share information around the planning process and to collect your feedback and ideas on how we can improve the ',
					},
					{
						text: 'PROJECT AREA ',
						bold: true,
					},
					{
						text: 'through',
					},
					{
						text: ' METHODS OF IMPLEMENTATION.',
						bold: true,
					},
				],
			},
			{
				type: 'paragraph',
				children: [
					{
						text: '',
					},
				],
			},
			{
				type: 'paragraph',
				style: 'text-align:left;',
				children: [
					{
						text: 'You may be wondering,',
					},
					{
						text: ' What is (TRANSIT-ORIENTED DEVELOPMENT, A VISIONING PROCESS, A COMPREHENSIVE MASTER PLAN, ETC.) ',
						bold: true,
					},
					{
						text: 'anyways? We’ve found a short video that presents a good description of this process ',
					},
					{
						text: '(IF NO VIDEO CAN BE FOUND, SHARE A BRIEF DESCRIPTION OR OTHER ENGAGING EDUCATIONAL RESOURCE HERE).',
						bold: true,
					},
				],
			},
			{
				type: 'paragraph',
				children: [
					{
						text: '',
					},
				],
			},
			{
				type: 'paragraph',
				style: 'text-align:left;',
				children: [
					{
						text: 'Check out the video here: ',
					},
					{
						text: '(add LINK)',
						bold: true,
					},
				],
			},
			{
				type: 'paragraph',
				children: [
					{
						text: '',
					},
				],
			},
			{
				type: 'paragraph',
				style: 'text-align:left;',
				children: [
					{
						text: 'Once you’ve watched the video, come back to this website to share your thoughts and ideas on how we can make project area a more ',
					},
					{
						text: 'VIBRANT/ACCESSIBLE, ETC. ',
						bold: true,
					},
					{
						text: 'place for the community through our interactive project map.',
					},
				],
			},
			{
				type: 'paragraph',
				children: [
					{
						text: '',
					},
				],
			},
			{
				type: 'paragraph',
				style: 'text-align:left;',
				children: [
					{
						text: 'We’ll be reading all of your comments and incorporating your feedback into the design and planning process. We can’t wait to hear from you!',
					},
				],
			},
			{
				type: 'paragraph',
				children: [
					{
						text: '',
					},
				],
			},
			{
				type: 'paragraph',
				style: 'text-align:left;',
				children: [
					{
						text: '(TYPE OF PLANNING PROCESS)',
						bold: true,
					},
				],
			},
			{
				type: 'paragraph',
				children: [
					{
						text: '',
					},
				],
			},
		],
	},
	{
		name: 'Meeting',
		title: 'Join us! ADD MEETING NAME, DATE & TIME HERE',
		Icon: CalendarMonthIcon,
		richText: [
			{
				type: 'paragraph',
				style: 'margin-left:0px;',
				children: [
					{
						text: 'We’ll be hosting a meeting on ',
					},
					{
						text: 'ADD DATE, TIME & LOCATION',
						bold: true,
					},
					{
						text: ' to discuss ',
					},
					{
						text: 'ADD PROJECT NAME.',
						bold: true,
					},
					{
						text: ' We’ll be going over ',
					},
					{
						text: 'ADD AGENDA TEASER',
						bold: true,
					},
					{
						text: ' and starting/continuing a conversation on ',
					},
					{
						text: 'ADD MEETING PURPOSE.',
						bold: true,
					},
				],
			},
			{
				type: 'paragraph',
				children: [
					{
						text: '',
					},
				],
			},
			{
				type: 'paragraph',
				style: 'margin-left:0px;',
				children: [
					{
						text: 'If you’re not able to attend the meeting, make sure to add your ideas and comments on our site ',
					},
					{
						text: 'ADD A LINK TO MAP OR FEEDBACK SECTION.',
						bold: true,
					},
					{
						text: ' We’ll also be posting a meeting recap so you don’t miss out on any new details. Thank you!',
					},
				],
			},
			{
				type: 'paragraph',
				children: [
					{
						text: '',
					},
				],
			},
			{
				type: 'paragraph',
				style: 'margin-left:0px;',
				children: [
					{
						type: 'paragraph',
						children: [
							{
								text: '',
							},
						],
					},
					{
						type: 'link',
						url: 'https://courbanize.com/projects/brownsville/updates/25416',
						target: '_blank',
						children: [
							{
								type: 'paragraph',
								children: [
									{
										text: '',
									},
								],
							},
						],
					},
				],
			},
			{
				type: 'paragraph',
				children: [
					{
						text: '',
					},
				],
			},
			{
				type: 'paragraph',
				style: 'margin-left:0px;',
				children: [
					{
						type: 'paragraph',
						children: [
							{
								text: '',
							},
						],
					},
				],
			},
			{
				type: 'paragraph',
				children: [
					{
						text: '',
					},
				],
			},
		],
	},
	{
		name: 'Post-Meeting',
		title: 'Thanks for meeting with us! What\'s next?',
		Icon: EventAvailableIcon,
		richText: [
			{
				type: 'paragraph',
				style: 'text-align:left;',
				children: [
					{
						text: 'Thank you to everyone who came out to ',
					},
					{
						text: 'ADD MEETING DETAILS.',
						bold: true,
					},
					{
						text: ' If you missed the event, don’t worry, you can still tell us your thoughts! ',
					},
					{
						text: 'ADD LINK TO MAP OR FEEDBACK SECTION',
						bold: true,
					},
				],
			},
			{
				type: 'paragraph',
				children: [
					{
						text: '',
					},
				],
			},
			{
				type: 'paragraph',
				style: 'text-align:left;',
				children: [
					{
						text: 'To recap, during the meeting we presented on ',
					},
					{
						text: 'ADD DETAILS',
						bold: true,
					},
					{
						text: ', and talked about A',
					},
					{
						text: 'DD DETAILS, e.g. the biggest items that came up during the hearing.',
						bold: true,
					},
				],
			},
			{
				type: 'paragraph',
				children: [
					{
						text: '',
					},
				],
			},
			{
				type: 'paragraph',
				style: 'text-align:left;',
				children: [
					{
						text: 'ADD A POSITIVE NOTE, e.g. about turnout or topics discussed "It was great to see such a great turnout...We really appreciate the community\'s focus on ____"',
						bold: true,
					},
				],
			},
			{
				type: 'paragraph',
				children: [
					{
						text: '',
					},
				],
			},
			{
				type: 'paragraph',
				style: 'text-align:left;',
				children: [
					{
						text: 'Please continue to share your feedback and questions on our map or in the Feedback section',
					},
					{
						text: ' ADD LINK TO MAP OR FEEDBACK SECTION.',
						bold: true,
					},
					{
						text: ' Thanks!',
					},
				],
			},
			{
				type: 'paragraph',
				children: [
					{
						text: '',
					},
				],
			},
			{
				type: 'paragraph',
				style: 'text-align:left;',
				children: [
					{
						type: 'paragraph',
						children: [
							{
								text: '',
							},
						],
					},
					{
						type: 'link',
						url: 'https://courbanize.com/projects/brownsville/updates/25448',
						target: '_blank',
						children: [
							{
								type: 'paragraph',
								children: [
									{
										text: '',
									},
								],
							},
						],
					},
				],
			},
			{
				type: 'paragraph',
				children: [
					{
						text: '',
					},
				],
			},
		],
	},
	{
		name: 'Announcing New Comment Topics',
		title: 'New Questions!',
		Icon: ChatBubbleIcon,
		richText: [
			{
				type: 'paragraph',
				style: 'text-align:left;',
				children: [
					{
						text: 'Thank you to everyone who has commented so far! ',
					},
					{
						text: 'ADD DETAILS, e.g. mention what you have done so far with the feedback.',
						bold: true,
					},
					{
						text: ' We’ve appreciated all your feedback and it’s helped us get a better understanding of the community’s interest in the project. To continue the dialogue, we’ve added some new questions about:',
					},
				],
			},
			{
				type: 'paragraph',
				children: [
					{
						text: '',
					},
				],
			},
			{
				type: 'paragraph',
				style: 'text-align:left;',
				children: [
					{
						text: 'ADD KEY TOPICS HERE',
						bold: true,
					},
				],
			},
			{
				type: 'paragraph',
				children: [
					{
						text: '',
					},
				],
			},
			{
				type: 'paragraph',
				style: 'text-align:left;',
				children: [
					{
						text: 'Click through these new topics in our Feedback section ',
					},
					{
						text: 'ADD LINK TO FEEDBACK SECTION',
						bold: true,
					},
					{
						text: ' and post your ideas to help us continually improve our project. We can’t wait to hear from you!',
					},
				],
			},
			{
				type: 'paragraph',
				children: [
					{
						text: '',
					},
				],
			},
			{
				type: 'paragraph',
				style: 'text-align:left;',
				children: [
					{
						type: 'paragraph',
						children: [
							{
								text: '',
							},
						],
					},
					{
						type: 'link',
						url: 'https://courbanize.com/projects/downtown-taunton-plan/updates/26479',
						target: '_blank',
						children: [
							{
								type: 'paragraph',
								children: [
									{
										text: '',
									},
								],
							},
						],
					},
				],
			},
			{
				type: 'paragraph',
				children: [
					{
						text: '',
					},
				],
			},
		],
	},
	{
		name: 'Virtual Meeting',
		title: 'Join us online! ADD MEETING NAME, DATE & TIME HERE',
		Icon: ComputerIcon,
		richText: [
			{
				type: 'paragraph',
				style: 'text-align:left;',
				children: [
					{
						text: 'We’ll be hosting a meeting on ',
					},
					{
						text: 'ADD DATE, TIME',
						bold: true,
					},
					{
						text: ' to discuss ',
					},
					{
						text: 'ADD PROJECT NAME HERE',
						bold: true,
					},
					{
						text: '. We’ll be going over ',
					},
					{
						text: 'ADD AGENDA TEASER HERE ',
						bold: true,
					},
					{
						text: 'and starting/continuing a conversation on ',
					},
					{
						text: 'ADD MEETING PURPOSE HERE',
						bold: true,
					},
					{
						text: '. Due to the current situation and limits on in-person gatherings, the meeting will take place online using ',
					},
					{
						text: 'ADD VIRTUAL PLATFORM',
						bold: true,
					},
					{
						text: '. You can join us virtually by following the link: ',
					},
					{
						text: 'ADD LINK',
						bold: true,
					},
					{
						text: ' or dialing in to: ',
					},
					{
						text: 'ADD CONFERENCE CALL #',
						bold: true,
					},
					{
						text: '. ',
					},
				],
			},
			{
				type: 'paragraph',
				children: [
					{
						text: '',
					},
				],
			},
			{
				type: 'paragraph',
				style: 'text-align:left;',
				children: [
					{
						text: 'If you’re not able to attend the meeting, make sure to add your ideas and comments on our site ',
					},
					{
						text: 'ADD LINK TO FEEDBACK SECTION HERE',
						bold: true,
					},
					{
						text: '. We’ll also be posting a meeting recap and recording ',
					},
					{
						text: '[IF APPLICABLE] ',
						bold: true,
					},
					{
						text: 'so you don’t miss out on any new details. Thank you!',
					},
				],
			},
			{
				type: 'paragraph',
				children: [
					{
						text: '',
					},
				],
			},
		],
	},
	{
		name: 'Live Virtual Meeting',
		title: 'We’re live! Join MEETING NAME online',
		Icon: PlayCircleIcon,
		richText: [
			{
				type: 'paragraph',
				style: 'text-align:left;',
				children: [
					{
						text: 'We\'re live at',
					},
					{
						text: ' ADD',
						bold: true,
					},
					{
						text: ' ',
					},
					{
						text: 'MEETING NAME ',
						bold: true,
					},
					{
						text: 'discussing ',
					},
					{
						text: 'ADD AGENDA ITEMS',
						bold: true,
					},
					{
						text: '! Please join us virtually by following the link: ',
					},
					{
						text: 'ADD LINK ',
						bold: true,
					},
					{
						text: 'or dialing in to: ',
					},
					{
						text: 'ADD CONFERENCE CALL #',
						bold: true,
					},
					{
						text: '.',
					},
				],
			},
			{
				type: 'paragraph',
				children: [
					{
						text: '',
					},
				],
			},
			{
				type: 'paragraph',
				style: 'text-align:left;',
				children: [
					{
						text: 'See you soon!',
					},
				],
			},
			{
				type: 'paragraph',
				children: [
					{
						text: '',
					},
				],
			},
		],
	},
	{
		name: 'Stay Tuned!',
		title: 'Thanks for your Feedback!',
		Icon: MoreHorizIcon,
		richText: [
			{
				type: 'paragraph',
				style: 'text-align:left;',
				children: [
					{
						text: 'We have appreciated hearing everyone’s thoughts and ideas on the project. We are currently (',
					},
					{
						text: 'SHARE WHAT\'S BEEN HAPPENING BEHIND THE SCENES',
						bold: true,
					},
					{
						text: '). We hope to have a (',
					},
					{
						text: 'COMMUNITY MEETING, UPDATE, ETC.) ',
						bold: true,
					},
					{
						text: 'soon.',
					},
				],
			},
			{
				type: 'paragraph',
				children: [
					{
						text: '',
					},
				],
			},
			{
				type: 'paragraph',
				style: 'text-align:left;',
				children: [
					{
						text: 'Thank you again for participating and sharing your input and questions. We’ll follow up with more updates as soon as we can. In the meantime, please visit our Feedback section ',
					},
					{
						text: '(LINK TO FEEDBACK SECTION)',
						bold: true,
					},
					{
						text: ' to continue the dialogue!',
					},
				],
			},
			{
				type: 'paragraph',
				children: [
					{
						text: '',
					},
				],
			},
		],
	},
	{
		name: 'Comment by Voicemail!',
		title: 'Comment by Voicemail!',
		Icon: PhoneIcon,
		richText: [
			{
				type: 'paragraph',
				style: 'margin-left:0px;',
				children: [
					{
						text: 'Make your voice heard - even without internet access. Share your comments by voicemail!',
					},
				],
			},
			{
				type: 'paragraph',
				children: [
					{
						text: '',
					},
				],
			},
			{
				type: 'paragraph',
				style: 'margin-left:0px;',
				children: [
					{
						text: 'Thank you to everyone who has commented so far! ',
					},
					{
						type: 'paragraph',
						children: [
							{
								text: '',
							},
						],
					},
					{
						text: ' We’ve appreciated all your feedback and it’s helped us get a better understanding of the community’s interest in the project. To allow us to hear from more of you, we’re now accepting comments by voicemail. By calling ',
					},
					{
						text: 'XXX-XXX-XXXX',
						bold: true,
					},
					{
						text: ' and leaving a voicemail, your comment will automatically be transcribed onto our platform. Feel free to share this number with your local neighbors who would like to leave feedback on the project via voicemail as well!',
					},
				],
			},
			{
				type: 'paragraph',
				children: [
					{
						text: '',
					},
				],
			},
			{
				type: 'paragraph',
				style: 'margin-left:0px;',
				children: [
					{
						text: 'We can’t wait to hear from you!',
					},
				],
			},
			{
				type: 'paragraph',
				children: [
					{
						text: '',
					},
				],
			},
		],
	},
	{
		name: 'Wahoo! Project Announcement',
		title: 'ADD PROJECT MILESTONE HEADLINE HERE',
		Icon: CampaignIcon,
		richText: [
			{
				type: 'paragraph',
				style: 'text-align:left;',
				children: [
					{
						text: 'Thank you all for the insightful input and ideas you’ve shared with us so far. Thanks to you we have accomplished so much already!',
					},
				],
			},
			{
				type: 'paragraph',
				children: [
					{
						text: '',
					},
				],
			},
			{
				type: 'paragraph',
				style: 'text-align:left;',
				children: [
					{
						text: 'We are excited to announce that we have now ',
					},
					{
						text: 'ADD DETAILS, e.g. new phase, draft plan, plan approved.',
						bold: true,
					},
				],
			},
			{
				type: 'paragraph',
				children: [
					{
						text: '',
					},
				],
			},
			{
				type: 'paragraph',
				style: 'text-align:left;',
				children: [
					{
						text: 'Your participation during this process has been more than helpful. Please continue to share your feedback and questions on our map or in the Feedback section. ',
					},
					{
						text: 'ADD LINK TO MAP OR FEEDBACK SECTION',
						bold: true,
					},
					{
						text: ' Thanks!',
					},
				],
			},
			{
				type: 'paragraph',
				children: [
					{
						text: '',
					},
				],
			},
		],
	},
	{
		name: 'Response to Feedback',
		title: 'We hear you!',
		Icon: EditIcon,
		richText: [
			{
				type: 'paragraph',
				style: 'text-align:left;',
				children: [
					{
						text: 'It’s been great to see all the new comments roll in on our site. We’ve been noticing a theme and we wanted to let you know that we hear you on ',
					},
					{
						text: 'ADD ITEM',
						bold: true,
					},
					{
						text: '.',
					},
				],
			},
			{
				type: 'paragraph',
				children: [
					{
						text: '',
					},
				],
			},
			{
				type: 'paragraph',
				style: 'text-align:left;',
				children: [
					{
						text: 'ADD DETAILS, e.g. what has or has not been done to address the concern. Why or why not are you directly addressing it and how.',
						bold: true,
					},
				],
			},
			{
				type: 'paragraph',
				children: [
					{
						text: '',
					},
				],
			},
			{
				type: 'paragraph',
				style: 'text-align:left;',
				children: [
					{
						text: 'We’ll follow up with more updates as soon as we can. If you have any additional feedback, visit our map or Feedback section ',
					},
					{
						text: 'ADD LINK TO MAP OR FEEDBACK SECTION',
						bold: true,
					},
					{
						text: ' Thank you again for participating and having an impact on your community! ',
					},
				],
			},
			{
				type: 'paragraph',
				children: [
					{
						text: '',
					},
				],
			},
			{
				type: 'paragraph',
				style: 'text-align:left;',
				children: [
					{
						type: 'paragraph',
						children: [
							{
								text: '',
							},
						],
					},
					{
						type: 'link',
						url: 'https://courbanize.com/projects/nashua-riverfront/updates/25823',
						target: '_blank',
						children: [
							{
								type: 'paragraph',
								children: [
									{
										text: '',
									},
								],
							},
						],
					},
				],
			},
			{
				type: 'paragraph',
				children: [
					{
						text: '',
					},
				],
			},
		],
	},
	{
		name: 'New SMS Signs',
		title:
			'Introducing a new way to engage with the project: Text us your ideas!',
		Icon: PhoneIphoneIcon,
		richText: [
			{
				type: 'paragraph',
				style: 'text-align:left;',
				children: [
					{
						text: 'Make your voice heard from wherever, text us your feedback and ideas! Be on the lookout for new signs that will be posted around ',
					},
					{
						text: 'ADD SIGN LOCATION(S).',
						bold: true,
					},
					{
						text: ' Respond to to the prompt and see it appear online. ',
					},
				],
			},
			{
				type: 'paragraph',
				children: [
					{
						text: '',
					},
				],
			},
			{
				type: 'paragraph',
				style: 'text-align:left;',
				children: [
					{
						text: 'Be sure to visit the project page afterwards and click the ‘Follow’ button to stay up-to-date on the conversation and continue to participate. Thanks in advance for all your feedback, we can’t wait to hear!',
					},
				],
			},
			{
				type: 'paragraph',
				children: [
					{
						text: '',
					},
				],
			},
		],
	},
	{
		name: 'Using Your Feedback',
		title: 'How we\'ve used your feedback',
		Icon: LightbulbIcon,
		richText: [
			{
				type: 'paragraph',
				style: 'text-align:left;',
				children: [
					{
						text: 'Thank you all for the insightful comments, questions, and ideas you’ve shared with us so far. We’re proud of how we’re incorporating this feedback into project plans.',
					},
				],
			},
			{
				type: 'paragraph',
				children: [
					{
						text: '',
					},
				],
			},
			{
				type: 'paragraph',
				style: 'text-align:left;',
				children: [
					{
						text: 'We are excited to announce that we have now ',
					},
					{
						text: 'ADD DETAILS, e.g. new phase, draft plan, plan approved',
						bold: true,
					},
					{
						text: '. Based on what we’ve heard, we’ve updated plans to include ',
					},
					{
						text: 'INSERT UPDATE HERE',
						bold: true,
					},
					{
						text: '.',
					},
				],
			},
			{
				type: 'paragraph',
				children: [
					{
						text: '',
					},
				],
			},
			{
				type: 'paragraph',
				style: 'text-align:left;',
				children: [
					{
						text: 'Your participation during this process has been very helpful. Please continue to share your feedback and questions here ',
					},
					{
						text: 'LINK',
						bold: true,
					},
					{
						text: '. Thank you!',
					},
				],
			},
			{
				type: 'paragraph',
				children: [
					{
						text: '',
					},
				],
			},
		],
	},
	{
		name: 'Local Press',
		title: `${projectName} in ADD PUBLICATION NAME`,
		Icon: NewspaperIcon,
		richText: [
			{
				type: 'paragraph',
				style: 'text-align:left;',
				children: [
					{
						text: 'Thank you for your thoughtful feedback! We’re excited to learn more about community needs so that we can build a more thoughtful project.',
					},
				],
			},
			{
				type: 'paragraph',
				children: [
					{
						text: '',
					},
				],
			},
			{
				type: 'paragraph',
				style: 'text-align:left;',
				children: [
					{
						text: 'We’re also proud of the recognition we’ve received from ',
					},
					{
						text: 'INSERT NEWSPAPER NAME',
						bold: true,
					},
					{
						text: '. We’re excited to have the support of the community, and we look forward to hearing more of your ideas! You can read the full story here: ',
					},
					{
						text: 'INSERT LINK TO ARTICLE',
						bold: true,
					},
					{
						text: '.',
					},
				],
			},
			{
				type: 'paragraph',
				children: [
					{
						text: '',
					},
				],
			},
			{
				type: 'paragraph',
				style: 'text-align:left;',
				children: [
					{
						text: 'INSERT SCREENSHOT OF ARTICLE',
						bold: true,
					},
				],
			},
			{
				type: 'paragraph',
				children: [
					{
						text: '',
					},
				],
			},
			{
				type: 'paragraph',
				style: 'text-align:left;',
				children: [
					{
						text: 'Thank you again for participating and having an impact on your community!',
					},
				],
			},
			{
				type: 'paragraph',
				children: [
					{
						text: '',
					},
				],
			},
		],
	},
	{
		name: 'We\'ve heard you!',
		title: 'We\'ve heard you!',
		Icon: QuestionAnswerIcon,
		richText: [
			{
				type: 'paragraph',
				style: 'text-align:left;',
				children: [
					{
						text: 'It’s been great to see all of your ideas and thoughtful feedback on the site. We’ve heard that ',
					},
					{
						text: 'INSERT TAKEAWAYS HERE',
						bold: true,
					},
					{
						text: '.',
					},
				],
			},
			{
				type: 'paragraph',
				children: [
					{
						text: '',
					},
				],
			},
			{
				type: 'paragraph',
				style: 'text-align:left;',
				children: [
					{
						text: 'We are reviewing how we can incorporate this feedback into the project plans. We hope to provide an update ',
					},
					{
						text: 'INSERT TIMEFRAME HERE',
						bold: true,
					},
					{
						text: '!',
					},
				],
			},
			{
				type: 'paragraph',
				children: [
					{
						text: '',
					},
				],
			},
			{
				type: 'paragraph',
				style: 'text-align:left;',
				children: [
					{
						text: 'If you have any additional feedback or questions, please share it in the Feedback section.',
					},
				],
			},
			{
				type: 'paragraph',
				children: [
					{
						text: '',
					},
				],
			},
			{
				type: 'paragraph',
				style: 'text-align:left;',
				children: [
					{
						text: 'We value all of the thoughts and questions you have. Thank you again for taking the time to participate in this process.',
					},
				],
			},
			{
				type: 'paragraph',
				children: [
					{
						text: '',
					},
				],
			},
		],
	},
];

export const incorporatedCommentTemplate = (comment: Comment) => { 
	return {
		id: 0,
		name: 'Using Your Feedback',
		title: 'How we\'ve used your feedback',
		richText: [
			{
				type: 'paragraph',
				style: 'text-align:left;',
				children: [
					{
						text: 'Thank you all for the insightful comments, questions, and ideas you’ve shared with us so far. We’re proud of how we’re incorporating this feedback into project plans.',
					},
				],
			},
			{
				type: 'paragraph',
				children: [
					{
						text: '',
					},
				],
			},
			{
				type: 'paragraph',
				style: 'margin-left:10%',
				children: [
					{
						text: `${comment.createdByUser.fullName}`,
						bold: true,
					},
					{
						text: `\n"${comment.commentText}"`,
					},
				],
			},
			{
				type: 'paragraph',
				children: [
					{
						text: '',
					},
				],
			},
			{
				type: 'paragraph',
				style: 'text-align:left;',
				children: [
					{
						text: 'Your participation during this process has been very helpful. Please continue to share your feedback and questions here ',
					},
					{
						text: 'LINK',
						bold: true,
					},
					{
						text: '. Thank you!',
					},
				],
			},
			{
				type: 'paragraph',
				children: [
					{
						text: '',
					},
				],
			},
		],
	};
};

export default templates;
