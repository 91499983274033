import { FC } from 'react';
import {
	Box,
	IconButton,
	Menu,
	MenuItem,
	Tooltip,
	Typography,
	useTheme,
} from '@mui/material';
import {
	DragHandle as DragHandleIcon,
	MoreVert as MoreVertIcon,
	DeleteOutline as DeleteOutlineIcon,
	Anchor as AnchorIcon,
} from '@mui/icons-material';
import { subject } from '@casl/ability';
import { usePausedContext } from '@coUrbanize/community-modules';

import { ModuleInstance, Project } from '../../../types/types';
import { MODULE_TYPE } from '../../../types/moduleTypes';
import MODULE_NAMES, {
	MODULE_RENDER,
	MODULE_DIALOGS,
} from 'constants/moduleNames';
import { useAbandonActionConfirmation } from 'hooks/useActionConfirmation';
import EditIcon from '../../icons/EditIcon';
import { Can } from '../../../providers/CaslAbilityProvider';
import {
	permissionAction,
	permissionSubject,
} from '../../../utility/defineAbilityFor';
import ModuleDialog from './ModuleDialog';
import useModuleInstance from './hook/useModuleInstance';
import isEqual from 'lodash/isEqual';
import EditAnchorModal from './EditAnchorModal';

export interface ModuleInstanceProps {
	moduleInstance: ModuleInstance;
	handle: any;
	previewComponent?: any;
	handleUpdateModule: (id: number, newContent: any) => void;
	handleDeleteModuleInstance: (tID: number) => void;
	project: Project;
	getToken: any;
}

const ModuleInstanceComponent: FC<ModuleInstanceProps> = (props) => {
	const {
		moduleInstance,
		handle,
		handleUpdateModule,
		handleDeleteModuleInstance,
		getToken,
		project,
	} = props;

	const theme = useTheme();
	const { isPaused } = usePausedContext();
	const showAbandonConfirmation = useAbandonActionConfirmation();
	const {
		open,
		setOpen,
		menuOpen,
		handleClick,
		anchorEl,
		setAnchorEl,
		handleClickOpen,
		handleDeleteClick,
		saveDataRef,
		handleClose,
		handleSaveModuleInstance,
		cancelTrigger,
		openEditAnchorModal,
		handleEditAnchor,
		handleOpenAnchorModal,
	} = useModuleInstance(
		moduleInstance,
		handleUpdateModule,
		handleDeleteModuleInstance,
		getToken,
		project,
	);

	const moduleRender =
		MODULE_RENDER[moduleInstance.moduleType.name as MODULE_TYPE];

	const handleCancel = async () => {
		if (isEqual(saveDataRef.current?.content, moduleInstance.content))
			return handleClose();

		await showAbandonConfirmation(
			{
				title: 'Abandon Changes?',
				message: (
					<Typography fontSize="16px">
						All unsaved modifications made to this{' '}
						<strong>{moduleInstance.moduleType.name}</strong> module will be
						lost!
					</Typography>
				),
			},
			() => handleClose(),
		);
	};

	return (
		<>
			<Box
				border={`2px solid ${theme.customPalette.borderColors.dark}`}
				borderRadius={2}
				overflow="hidden"
				minHeight={100}
				marginBottom={2}
				pb={1}
				mt={1}
				mx={1}
				onDoubleClick={() => setOpen(true)}
			>
				<div
					{...handle}
					style={{
						height: '30px',
						padding: 5,
						display: 'flex',
						flexDirection: 'row',
						justifyContent: 'space-between',
						alignContent: 'center',
						alignItems: 'center',
						backgroundColor: theme.customPalette.surface.light,
					}}
				>
					<Typography
						style={{ color: theme.customPalette.systemColors.disabled }}
						fontSize={16}
						align="left"
						padding="7px"
					>
						{MODULE_NAMES[moduleInstance.moduleType.name as MODULE_TYPE]}
						{moduleInstance?.anchorId && ` - #${moduleInstance.anchorId}`}
					</Typography>
					<DragHandleIcon
						htmlColor={theme.customPalette.primaryColors.sky[1]}
						sx={{
							fontSize: 20,
							position: 'absolute',
							left: '50%',
							marginLeft: '-10px',
						}}
					/>
					<Box>
						<Can
							I={permissionAction.MANAGE}
							this={subject(
								permissionSubject.MODULE,
								moduleInstance.moduleType,
							)}
						>
							<IconButton
								size="small"
								id="menu-button"
								aria-controls={open ? 'options-menu' : undefined}
								aria-haspopup="true"
								aria-expanded={open ? 'true' : undefined}
								onClick={handleClick}
								disabled={isPaused}
							>
								<MoreVertIcon />
							</IconButton>
							<Menu
								id="options-menu"
								anchorEl={anchorEl}
								open={menuOpen}
								onClose={() => setAnchorEl(null)}
								MenuListProps={{
									'aria-labelledby': 'menu-button',
								}}
							>
								{moduleRender.render !== MODULE_DIALOGS.NONE && (
									<MenuItem onClick={handleClickOpen}>
										<Tooltip
											title={`Edit ${moduleInstance.moduleType.name}`}
											placement="left"
											arrow={true}
										>
											<IconButton
												sx={{ display: 'flex', justifyContent: 'center' }}
											>
												<EditIcon style={{ marginRight: '0px' }} size={18} />
											</IconButton>
										</Tooltip>
									</MenuItem>
								)}
								<MenuItem onClick={handleOpenAnchorModal}>
									<Tooltip
										title={`Update this ${moduleInstance.moduleType.name} Anchor ID`}
										placement="left"
										arrow={true}
									>
										<IconButton>
											<AnchorIcon
												style={{ marginRight: '0px' }}
												fontSize="small"
											/>
										</IconButton>
									</Tooltip>
								</MenuItem>
								<MenuItem onClick={handleDeleteClick}>
									<Tooltip
										title={`Delete ${moduleInstance.moduleType.name}`}
										placement="left"
										arrow={true}
									>
										<IconButton>
											<DeleteOutlineIcon
												style={{ marginRight: '0px' }}
												fontSize="small"
											/>
										</IconButton>
									</Tooltip>
								</MenuItem>
							</Menu>
						</Can>
					</Box>
				</div>
				{props.previewComponent}
			</Box>
			<ModuleDialog
				open={open}
				moduleInstance={moduleInstance}
				saveDataRef={saveDataRef}
				handleClose={handleClose}
				handleSaveModuleInstance={handleSaveModuleInstance}
				handleCancel={handleCancel}
				handleUpdateModule={handleUpdateModule}
				cancelTrigger={cancelTrigger}
			/>
			{/* need condition to unmount the component on modal close (it resets the state) */}
			{openEditAnchorModal && (
				<EditAnchorModal
					open={openEditAnchorModal}
					anchorId={moduleInstance.anchorId}
					handleEditAnchor={handleEditAnchor}
					handleClose={handleClose}
				/>
			)}
		</>
	);
};

export default ModuleInstanceComponent;
