import { FC } from 'react';
import { Alert, AlertColor, Snackbar } from '@mui/material';
import Portal from '@mui/base/Portal';

interface NotificationSnackbarProps {
	snackbarOpen: boolean;
	message: string;
	variant: AlertColor;
	handleSnackbarClose: () => void;
}

const NotificationSnackbar: FC<NotificationSnackbarProps> = (props) => {
	const { snackbarOpen, message, variant, handleSnackbarClose } = props;
	return (
		<Portal>
			<Snackbar
				open={snackbarOpen}
				autoHideDuration={3000}
				onClose={handleSnackbarClose}
				message="Note archived"
			>
				<Alert onClose={handleSnackbarClose} severity={variant}>
					{message}
				</Alert>
			</Snackbar>
		</Portal>
	);
};

export default NotificationSnackbar;
