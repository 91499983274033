import { useQuery } from 'urql';
import { isNumber } from 'lodash';
import { PausedProvider as CommunityPausedProvider } from '@coUrbanize/community-modules';

import { useUserContext } from './UserProvider';
import { isProjectPausedQuery } from 'api/query/project.queries';
import { USER_TYPES } from 'constants/userTypes';
import useNumberParams from 'hooks/useNumberParams';

type PausedProviderProps = {
	children: any;
};

const context = { additionalTypenames: ['OneProject'] };

const PausedProvider = ({ children }: PausedProviderProps) => {
	const { projectId } = useNumberParams();
	const { user } = useUserContext();

	const [{ data: projectData }] = useQuery({
		query: isProjectPausedQuery(),
		variables: { projectId },
		pause: !isNumber(projectId),
		context,
	});

	const isPaused =
		projectData?.project?.pausedDate &&
		user?.projectDetails?.find((project) => project.projectId === projectId) &&
		user.userType === USER_TYPES.CLIENT;

	return (
		<CommunityPausedProvider isPaused={isPaused}>
			{children}
		</CommunityPausedProvider>
	);
};

export default PausedProvider;
