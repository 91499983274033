import { gql } from 'urql';

export const avatarByUserQuery = gql`
    query ($userId: Int!) {
        avatarsByUser(userId: $userId) {
            id
            fileName
            imageUrl
        }
    }
`;