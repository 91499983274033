import debounce from 'lodash/debounce';
import { useRef, useEffect, useMemo } from 'react';

type CallbackFunction = (...args: any[]) => any;

export default function useDebounce(
	callback: CallbackFunction,
	timeout: number = 750,
): CallbackFunction {
	const ref = useRef<CallbackFunction | null>(null);

	useEffect(() => {
		ref.current = callback;
	}, [callback]);

	const debouncedCallback = useMemo(() => {
		const func = () => {
			if (ref.current) {
				ref.current();
			}
		};

		return debounce(func, timeout);
	}, [timeout]);

	return debouncedCallback;
}
