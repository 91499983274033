import { FC } from 'react';
import { useQuery } from 'urql';
import { ModulePicker } from './ModulePicker';
import { ModuleType } from '../../../types/types';

interface ModulePickerContainerProps {
	anchorEl: any;
	setAnchorEl: any;
	instanceId: number;
	sectionId: number;
	onModuleSelected: (
		moduleType: ModuleType,
		instanceId: number,
		sectionId: number,
		config: object,
	) => void;
}

export const ModulePickerContainer: FC<ModulePickerContainerProps> = (
	props,
) => {
	const { onModuleSelected, anchorEl, setAnchorEl, instanceId, sectionId } =
		props;

	const moduleQuery = `
        query{
		  moduleTypes{
		    id
		    name
		    functionalType
		    defaultConfig
		  }
		}`;

	const [{ data }] = useQuery({
		query: moduleQuery,
	});

	if (!data?.moduleTypes) return null;

	return (
		<ModulePicker
			modules={data.moduleTypes}
			handleSelect={onModuleSelected}
			anchorEl={anchorEl}
			setAnchorEl={setAnchorEl}
			instanceId={instanceId}
			sectionId={sectionId}
		/>
	);
};
