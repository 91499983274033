import { Popover, Box } from '@mui/material';
import { TemplatePickerOption } from './TemplatePickerOption';
import { Template } from '../../../types/types';

interface TemplatePickerProps {
	templates: Array<Template>;
	handleSelect: (template: Template) => void;
	anchorEl: any;
	onPickerClose: VoidFunction;
	open: boolean;
}

export const TemplatePicker = ({
	templates,
	handleSelect,
	anchorEl,
	onPickerClose,
	open,
}: TemplatePickerProps) => {
	const handleSelectItem = (template: Template) => {
		onPickerClose();
		handleSelect(template);
	};

	return (
		<Popover
			open={open}
			anchorEl={anchorEl}
			onClose={onPickerClose}
			anchorOrigin={{
				vertical: 'center',
				horizontal: 'right',
			}}
		>
			<Box
				sx={{
					display: 'grid',
					gridTemplateColumns: 'repeat(2, 1fr)',
					gridTemplateRows: `repeat(${Math.ceil(templates.length / 2)} 1fr)`,
					columnGap: 2,
					rowGap: 3,
					width: '400px',
					padding: '20px',
				}}
			>
				{templates &&
					templates.map((template: Template, index: number) => (
						<div key={`option_${index}`}>
							<TemplatePickerOption
								template={template}
								handleClick={handleSelectItem}
							/>
						</div>
					))}
			</Box>
		</Popover>
	);
};
