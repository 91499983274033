import { FC, useEffect, useState } from 'react';
import { useMutation, useQuery } from 'urql';
import { Box, Typography, InputLabel } from '@mui/material';
import { usePausedContext } from '@coUrbanize/community-modules';
import useNumberParams from '../hooks/useNumberParams';
import TimelineTable from '../components/features/tables/TimelineTable';
import { timelinesByProjectIdQuery } from '../api/query/timeline.queries';
import {
	createTimelineMutation,
	deleteTimelineMutation,
} from '../api/mutation/timeline.mutations';
import ConfirmationDialog from '../components/common/ConfirmationDialog';
import Button from '@mui/material/Button';
import RegularTextInput from 'components/common/styled/RegularTextInput';
import { useSnackBar } from 'providers/SnackBarProvider';
import PageHeader from 'components/common/PageHeader';
import AddIcon from '@mui/icons-material/Add';
import { useDeleteActionConfirmation } from 'hooks/useActionConfirmation';

export interface TimelineProps {
	name: string;
	id: number;
}

const Timeline: FC = () => {
	const [open, setOpen] = useState(false);
	const [name, setName] = useState('');
	const [error, setError] = useState('');
	const [timelines, setTimelines] = useState<TimelineProps[]>([]);

	const { projectId } = useNumberParams();
	const { notify } = useSnackBar();
	const { isPaused } = usePausedContext();
	const showDeleteConfirmation = useDeleteActionConfirmation();

	const [, createTimeline] = useMutation(createTimelineMutation);
	const [, deleteTimeline] = useMutation(deleteTimelineMutation);

	const [{ data }] = useQuery({
		query: timelinesByProjectIdQuery,
		variables: { projectId },
		pause: timelines.length > 0,
	});

	useEffect(() => {
		if (data?.timelinesByProjectId) setTimelines(data.timelinesByProjectId);
	}, [data?.timelinesByProjectId]);

	const validateName = (e: any) => {
		if (!e.target.value) {
			setError('TimeLine Name is required');
		} else {
			setError('');
		}
	};

	const handleSetName = (e: any) => {
		setName(e.target.value);
		validateName(e);
	};

	const handleTimelineDelete = async (id: number) => {
		await showDeleteConfirmation(
			{
				title: 'Delete Timeline',
				message: 'Are you sure you want to delete this timeline?',
			},
			async () => {
				await deleteTimeline({ id });
				const newTimelines = timelines.filter((t) => t.id !== id);
				setTimelines(newTimelines);
			},
		);
	};

	return (
		<Box p={2} px={5}>
			<PageHeader>Timelines</PageHeader>
			<Typography my={2}>
				The Timeline should show both previous and upcoming project milestones,
				as well as important events. In most cases you will want to use{' '}
				<strong>one timeline</strong> with multiple events for your project.
			</Typography>
			<Button
				size="large"
				startIcon={<AddIcon />}
				onClick={() => setOpen(true)}
				disabled={!!isPaused}
				style={{ textTransform: 'none', fontWeight: 500 }}
			>
				New Timeline
			</Button>

			{timelines.map((_, i) => {
				const { id, name } = timelines[timelines.length - 1 - i];
				return (
					<TimelineTable
						key={id}
						timelineId={id}
						name={name}
						onDelete={handleTimelineDelete}
					/>
				);
			})}

			<ConfirmationDialog
				open={open}
				title="Create New Timeline"
				onConfirm={async () => {
					if (!name.trim().length)
						return notify('Timeline Name is required', 'error');
					const newTimeline = { name, projectId };
					const res = await createTimeline({ newTimeline });
					const id: number = res.data.createTimeline.id;
					if (id) setTimelines([...timelines, { name, id }]);
					if (name.length) setOpen(false);
				}}
				onCancel={() => {
					setOpen(false);
					setError('');
				}}
				confirmLabel="Create"
				style={{ maxWidth: 536 }}
				paddingSize="small"
			>
				<Typography variant="subtitle1" color="#757575" py={2}>
					You are about to create a new timeline that can be added to your
					project site.
				</Typography>
				<div>
					<InputLabel id="timelineName">Enter Timeline Name</InputLabel>
					<RegularTextInput
						value={name}
						placeholder="Timeline Name"
						fullWidth
						error={!!error}
						helperText={error}
						onChange={handleSetName}
						onBlur={validateName}
						size="small"
					/>
				</div>
			</ConfirmationDialog>
		</Box>
	);
};

export default Timeline;
