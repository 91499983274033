import { gql } from 'urql';

export const createCommentMutation = gql`
	mutation createComment($newComment: NewCommentInput!) {
		createComment(newComment: $newComment) {
			id
		}
	}
`;

export const updateCommentMutation = gql`
	mutation updateComment($existingComment: ExistingCommentInput!) {
		updateComment(existingComment: $existingComment) {
			id
		}
	}
`;

export const supportCommentMutation = gql`
	mutation supportComment($supportCommentInput: SupportCommentInput!) {
		supportComment(supportCommentInput: $supportCommentInput)
	}
`;

export const flagCommentMutation = gql`
	mutation flagComment($flagCommentInput: FlagCommentInput!) {
		flagComment(flagCommentInput: $flagCommentInput)
	}
`;
