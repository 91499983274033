import React, { FC, SetStateAction, Dispatch } from 'react';
import { Box, useTheme } from '@mui/material';
import { Filter } from 'utility/commentData';
import { Comment as CommentType } from 'types/types';
import User from 'types/user';
import { Comment } from './Comment';
import _ from 'lodash';

interface ParentCommentProps {
	comment: CommentType; // type Question
	childComments: CommentType[]; // type Question
	filter: Filter;
	user: User | null;
	likeComment: (id: number) => void;
	addComment: (comment: CommentType) => void;
	saveComment: (comment: CommentType, cancel?: boolean) => void;
	editComment: (comment: CommentType) => void;
	moderateComment: (comment: CommentType, cancel?: boolean) => void;
	specificCommentId?: number;
	canEditSpecificComment?: boolean;
	onSpecificCommentEdit?: Dispatch<SetStateAction<CommentType | undefined>>;
	showAllComments?: boolean;
	disabled?: boolean;
}

const ParentComment: FC<ParentCommentProps> = (props) => {
	const {
		comment,
		childComments,
		filter,
		user,
		addComment,
		saveComment,
		editComment,
		likeComment,
		moderateComment,
		specificCommentId,
		canEditSpecificComment,
		onSpecificCommentEdit,
		showAllComments = false,
		disabled,
	} = props;

	const childCommentsSorted = _.sortBy(childComments, ['comment'], (o) => {
		return o.created ? Number(o.created) : 50000000000;
	});

	const theme = useTheme();

	return (
		<Box mb={3}>
			<Box
				mb={2}
				py={1.5}
				px={2}
				ml={-2}
				borderRadius={3}
				border={`1px solid ${theme.customPalette.borderColors.dark}`}
				width="fit-content"
				lineHeight={1.25}
				bgcolor={theme.customPalette.primaryColors.grassGreen['5']}
				color={theme.customPalette.primaryColors.dusk[1]}
			>
				{comment.question.questionText}
			</Box>
			<Box
				display="flex"
				alignItems="center"
				justifyContent="space-between"
				pb={2}
				mb={1}
				sx={{
					border: '1px solid #EFEFEF',
					padding: '10px',
					borderRadius: '8px',
					background: 'white',
					boxShadow: '3px 3px 3px #CCC',
					...(specificCommentId === comment.id
						? {
								mb: 1,
								pl: 1,
								borderLeft: `16px solid ${theme.customPalette.primaryColors.grassGreen[2]}`,
								borderRadius: '16px',
						  }
						: {}),
				}}
			>
				<Comment
					comment={comment}
					user={user}
					addComment={addComment}
					saveComment={saveComment}
					editComment={editComment}
					likeComment={likeComment}
					moderateComment={moderateComment}
					specificCommentId={specificCommentId}
					canEditSpecificComment={canEditSpecificComment}
					onSpecificCommentEdit={onSpecificCommentEdit}
					disabled={disabled}
					childComments={childCommentsSorted}
					filter={filter}
					showAllComments={showAllComments}
				/>
			</Box>
		</Box>
	);
};

export default ParentComment;
