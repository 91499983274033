import { FC, useState } from 'react';
import { Popover, Typography } from '@mui/material';

interface TextMouseOverProps {
	text: string;
	children?: React.ReactNode;
}

const TextMouseOver: FC<TextMouseOverProps> = ({ children, text }) => {
	const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

	const open = Boolean(anchorEl);

	return (
		<div>
			<div
				aria-owns={open ? 'mouse-over-popover' : undefined}
				aria-haspopup="true"
				onMouseEnter={(e) => setAnchorEl(e.currentTarget)}
				onMouseLeave={() => setAnchorEl(null)}
			>
				{children}
			</div>
			<Popover
				id="mouse-over-popover"
				sx={{
					pointerEvents: 'none',
				}}
				open={open}
				anchorEl={anchorEl}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'right',
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'left',
				}}
				onClose={() => setAnchorEl(null)}
				disableRestoreFocus
			>
				<Typography bgcolor="#1a76d2" color="white" sx={{ p: 0.4 }}>
					{text}
				</Typography>
			</Popover>
		</div>
	);
};

export default TextMouseOver;
