import { useState, useEffect } from 'react';
import { useMutation, useQuery } from 'urql';
import { useSnackBar } from '../../../../providers/SnackBarProvider';
import {
	publicBenefitDefaults,
	publicBenefitsByProjectIdQuery,
} from '../../../../api/query/publicBenefit.queries';
import {
	createPublicBenefitMutation,
	removePublicBenefitMutation,
	reorderPublicBenefitsMutation,
	updatePublicBenefitMutation,
} from '../../../../api/mutation/publicBenefit.mutation';
import { removeImageMutation } from '../../../../api/mutation/image.mutations';
import { removeFileMutation } from '../../../../api/mutation/s3.mutations';
import { getObjectKey } from '../../../../utility/getObjectKey';
import { ImageInfo } from '../../../../types/imageInfo';
import produce from 'immer';

const usePublicBenefit = (projectId: number) => {
	const [publicBenefits, setPublicBenefits] = useState<any[]>([]);

	const { notify } = useSnackBar();
	const [, createPublicBenefit] = useMutation(
		createPublicBenefitMutation('id'),
	);
	const [, removePublicBenefit] = useMutation(removePublicBenefitMutation);
	const [, updatePublicBenefit] = useMutation(updatePublicBenefitMutation);
	const [, reorderPublicBenefits] = useMutation(reorderPublicBenefitsMutation);
	const [, removeImage] = useMutation(removeImageMutation);
	const [, removeAwsFile] = useMutation(removeFileMutation);

	const [{ data: publicBenefitsData }] = useQuery({
		query: publicBenefitsByProjectIdQuery(),
		variables: { projectId },
		pause: publicBenefits.length > 0,
	});

	const [{ data: publicBenefitsDefaultData }] = useQuery({
		query: publicBenefitDefaults,
		variables: {},
	});

	useEffect(() => {
		if (publicBenefitsData?.publicBenefitsByProject)
			setPublicBenefits(publicBenefitsData?.publicBenefitsByProject);
	}, [publicBenefitsData?.publicBenefitsByProject]);

	const updatePublicBenefitText = async (
		id: number,
		name: string,
		description: string | undefined,
	) => {
		const existingPublicBenefitData = {
			id,
			projectId,
			name,
			description: description?.trim() === '' ? null : description?.trim(),
		};
		const result = await updatePublicBenefit({ existingPublicBenefitData });

		const updatedPbs = publicBenefits.map((pb) => {
			if (pb.id === id) return { ...pb, name, description };
			else return pb;
		});
		setPublicBenefits([...updatedPbs]);

		if (result.error) return notify('Public Benefit failed to save!', 'error');
		notify('Public Benefit saved');
	};

	const handleUpdatePublicBenefit = async (
		publicBenefitId: number,
		name: string,
		description: string | undefined,
	) => {
		if (!name) return notify('Public Benefit name was not specified!', 'error');

		if (publicBenefitId === -1)
			return handleCreatePublicBenefit(name, description, 0);

		await updatePublicBenefitText(publicBenefitId, name, description);

		const updatedBenefits = publicBenefits.map((pb) => {
			if (pb.id === publicBenefitId) {
				return { ...pb, name, description };
			} else {
				return pb;
			}
		});
		setPublicBenefits(updatedBenefits);
	};

	const handleCreatePublicBenefit = async (
		name: string,
		description: string | undefined,
		ordinal?: number,
	) => {
		const res = await createPublicBenefit({
			newPublicBenefitData: {
				projectId,
				name,
				description: description?.trim() === '' ? null : description?.trim(),
				ordinal,
			},
		});

		if (res.error) return notify('Public Benefit failed to create!', 'error');
		notify('Public Benefit created');

		const newId = res.data.createPublicBenefit.id;

		const stubPb = publicBenefits.find((pb) => pb.id === -1);
		const existingPbs = publicBenefits.map((pb) => {
			if (pb?.id === -1) {
				pb.id = newId;
				pb.name = name;
				pb.description = description;
				pb.ordinal = ordinal;
			}
			return pb;
		});

		//stub public benefit is for freeform public benefits vs suggestions
		const publicBenefitsToUpdate = stubPb
			? [...existingPbs]
			: [
					{ id: newId, name, description, ordinal, project: { id: projectId } },
					...publicBenefits,
			  ];

		setPublicBenefits([...publicBenefitsToUpdate]);

		return newId;
	};

	const handleRemovePublicBenefit = async (id: number) => {
		const res = await removePublicBenefit({ id });
		const pbToDelete = publicBenefits.find((pb) => pb.id === id);
		const { image } = pbToDelete;
		if (pbToDelete && !!image) {
			const objectKey = getObjectKey(image?.imageUrl as string);
			await removeAwsFile({ objectKey });
		}

		if (res.error) return notify('Public Benefit failed to delete!', 'error');
		notify('Public Benefit deleted');

		const remainingPublicBenefits = publicBenefits.filter((pb) => pb.id !== id);

		setPublicBenefits([...remainingPublicBenefits]);
	};

	const handleImageUpdate = async (
		id: number,
		imageId: number,
		name: string,
		description: string | undefined,
		image: ImageInfo,
	) => {
		if (id === -1) {
			// eslint-disable-next-line
			return notify("Public Benefit's name was not specified!", 'error');
		}

		const res = await updatePublicBenefit({
			existingPublicBenefitData: {
				projectId,
				id,
				imageId,
				name,
				description: description ?? null,
			},
		});

		setPublicBenefits(
			produce(publicBenefits, (state) => {
				const index = state.findIndex((c) => c.id === id);
				state[index] = { ...state[index], image };
			}),
		);

		if (res.error) return notify('Image failed to update!', 'error');
		notify('Image Updated');
	};

	const handleImageDelete = async (
		id: number,
		imageId: number,
		name: string,
		imageUrl: string,
		description?: string,
	) => {
		const res = await updatePublicBenefit({
			existingPublicBenefitData: {
				projectId: projectId,
				id,
				name: name,
				description: description ?? null,
				imageId: null,
				imageDescription: null,
				imageScale: 'fit',
				boundingBoxPosition: 'middle-center',
			},
		});

		const imageRes = await removeImage({ id: imageId });
		const objectKey = getObjectKey(imageUrl);
		const result = await removeAwsFile({ objectKey });
		if (res.error || imageRes.error || result.error)
			return notify('Image failed to delete properly!', 'error');
		notify('Image Deleted');
	};

	const handleCreateBlank = () => {
		if (publicBenefits[0]?.id === -1)
			return notify(
				'An existing unsaved public benefit already exists. Cannot add another until it is saved',
				'error',
			);
		const blankPB = {
			id: -1,
			name: '',
			description: '',
			project: { id: projectId },
		};
		setPublicBenefits([blankPB, ...publicBenefits]);
	};

	const handleReorderPublicBenefit = async (
		sourceIndex: number,
		destinationIndex: number,
	) => {
		if (!destinationIndex && destinationIndex !== 0) return;

		//reorder in array
		const sortedPBs = publicBenefits;
		const moved = sortedPBs.splice(sourceIndex, 1)[0];
		sortedPBs.splice(destinationIndex, 0, moved);
		setPublicBenefits(sortedPBs);

		//reorder on backend
		const res = await reorderPublicBenefits({
			projectId,
			destinationIndex,
			sourceIndex: sourceIndex,
		});

		if (!res.error) return notify('Public Benefit reordered');
		notify('Public Benefit failed to reorder!', 'error');
	};

	return {
		notify,
		publicBenefits,
		setPublicBenefits,
		publicBenefitsDefault: publicBenefitsDefaultData?.publicBenefitDefaults,
		handleCreateBlank,
		handleCreatePublicBenefit,
		handleReorderPublicBenefit,
		handleUpdatePublicBenefit,
		handleImageUpdate,
		handleRemovePublicBenefit,
		handleImageDelete,
	};
};

export default usePublicBenefit;
