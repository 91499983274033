import { isEmpty } from 'lodash';
import { Comment } from 'types/types';
import { User } from 'types/types';
import { NavItem, navItems } from './commentData';
import { USER_TYPES } from 'constants/userTypes';

const isCommentedByPublicUser = (comment: Comment) => {
	return Boolean(
		comment.createdByUser.userType === 'public' ||
			comment.mobileUser?.mobileNumber,
	);
};

export const detNavItemsCount = (
	separatedComments: { parentComment: Comment; childComments: Comment[] }[],
) => {
	const comments = ([] as Comment[]).concat.apply(
		[],
		separatedComments.map((c) => [c.parentComment, ...c.childComments]),
	);
	if (!comments) return [{ title: 'All', name: 'all', count: 0 }] as NavItem[];

	const navItemsCount = navItems as NavItem[];
	const responseNeeded = separatedComments?.filter(
		(c) =>
			isCommentedByPublicUser(c.parentComment) &&
			c.parentComment.approved &&
			c.childComments.every((cc) => isCommentedByPublicUser(cc) || cc.id === 0) &&
			(c.childComments.length === 0 ||  c.childComments.some((cc) => cc.approved)),
	).length;
	const responded = separatedComments?.filter(
		(c) =>
			isCommentedByPublicUser(c.parentComment) &&
			c.childComments.some((cc) => !isCommentedByPublicUser(cc) && cc.published),
	).length;

	//all comments count includes public (or sms-machine) and only approved (no admin comments or rejected comments)
	navItemsCount[0] = { ...navItemsCount[0], count: comments.filter((c) => c.approved && [USER_TYPES.MACHINE,USER_TYPES.PUBLIC].includes(c.createdByUser.userType)).length };
	navItemsCount[1] = {
		...navItemsCount[1],
		count: comments.filter((c) => !c.published).length,
	};
	navItemsCount[2] = { ...navItemsCount[2], count: responseNeeded };
	navItemsCount[3] = { ...navItemsCount[3], count: responded };
	const starredComments = comments.filter((c) => c.starred).length;
	navItemsCount[4] = {
		...navItemsCount[4],
		count: starredComments,
	};
	navItemsCount[5] = {
		...navItemsCount[5],
		count: comments.filter((c) => c.markedForFollowUp).length,
	};
	navItemsCount[6] = {
		...navItemsCount[6],
		count: comments.filter((c) => c.featured).length,
	};
	navItemsCount[7] = {
		...navItemsCount[7],
		count: comments.filter((c) => c.incorporated).length,
	};

	return navItemsCount;
};

export const separateComments = (
	comments: Comment[],
	sortBy: string,
	search: string,
) => {
	if (!comments) return [];

	const commentsAfterSearch = searchComments(search, comments);

	const parentComments = commentsAfterSearch.filter((c) => !c.parentCommentId);

	const sortedParentComments = sortComments(parentComments, sortBy);
	const sortedByActs = sortCommentsByActivities(comments, sortBy);

	const parentsChildren = sortedParentComments.map((pc) => ({
		parentComment: pc,
		childComments: sortedByActs.filter((c) => c.parentCommentId === pc.id),
	}));

	return sortParentsAndChildren(parentsChildren, sortBy);
};

const sortComments = (comments: Comment[], sortBy: string) => {
	if (sortBy === 'recentComments') return comments;
	const sortedComments = [...comments];

	return sortedComments.sort((a, b) => {
		if (sortBy === 'oldest') return Number(a.created) - Number(b.created);
		if (sortBy === 'popular')
			return b.commentSupports.length - a.commentSupports.length;
		return 0;
	});
};

const sortCommentsByActivities = (comments: Comment[], sortBy: string) => {
	if (sortBy !== 'recentActivities') return comments;

	const sortedComments = [...comments];

	return sortedComments.sort((a, b) => {
		if (
			a.createdByUser.userType === 'client' &&
			b.createdByUser.userType === 'client'
		)
			return Number(a.created) - Number(b.created);
		if (a.createdByUser.userType === 'client') return -1;
		if (b.createdByUser.userType === 'client') return 1;
		return 0;
	});
};

const sortParentsAndChildren = (
	comments: { parentComment: Comment; childComments: Comment[] }[],
	sortBy: string,
) => {
	const sortedComments = [...comments];

	return sortedComments.sort((a, b) => {
		if (sortBy === 'recentComments') {
			const aCreated = isEmpty(a.childComments)
				? a.parentComment.created
				: a.childComments[a.childComments.length - 1].created;
			const bCreated = isEmpty(b.childComments)
				? b.parentComment.created
				: b.childComments[b.childComments.length - 1].created;
			return Number(bCreated) - Number(aCreated);
		}
		if (sortBy !== 'recentActivities') return 0;

		if (
			a.childComments[0]?.createdByUser.userType === 'client' &&
			b.childComments[0]?.createdByUser.userType === 'client'
		)
			return (
				Number(a.childComments[0]?.created) -
				Number(b.childComments[0]?.created)
			);
		if (a.childComments[0]?.createdByUser.userType === 'client') return -1;
		if (b.childComments[0]?.createdByUser.userType === 'client') return 1;
		return 0;
	});
};

export const searchComments = (search: string, comments: Comment[]) => {
	const searchText = search.trim().toLowerCase();

	if (!searchText.length) return comments;

	// search comment username
	if (searchText.split(' ').join('').startsWith('displayname')) {
		const exclusiveChars = ['-', ',', '&', ''];

		// remove display name from search and trim
		let fullName = searchText
			.substring(searchText.startsWith('displayname') ? 11 : 12)
			.trim();
		// get quoted phrase
		const quotedText = fullName?.split('"')[1];
		// remove quoted text from string with quotes
		fullName = fullName.replace(`"${quotedText}"`, '');
		// split each word into an array
		let names = fullName
			.split(' ')
			.filter((s) => exclusiveChars.indexOf(s) < 0);
		// push the quoted text (without quotes)
		names.push(quotedText);

		// removed any undefined name in array
		names = names.filter((name) => !!name);

		// remove unnecessary chars at the start or end of the string
		names = names.map((name) =>
			exclusiveChars.indexOf(name[0]) >= 0
				? name.substring(1)
				: exclusiveChars.indexOf(name[name.length - 1]) >= 0
				? name.slice(0, -1)
				: name,
		);

		const matchByName = (comment: Comment) => {
			const index = names.findIndex(
				(name) =>
					comment.createdByUser.firstName?.toLowerCase()?.includes(name) ||
					comment.createdByUser.lastName?.toLowerCase()?.includes(name),
			);
			return index >= 0;
		};

		return comments.filter((comment) => {
			if (comment.parentCommentId === null) {
				return (
					matchByName(comment) ||
					comments
						.filter((c) => c.parentCommentId === comment.id)
						.some((c) => matchByName(c))
				);
			}
			return matchByName(comment);
		});
	}

	// search comment text
	const exclusiveTerms = ['and', 'or', '&', ''];
	const quotedText = search?.split('"')[1];
	const textWithNoQuote = searchText.replace(`"${quotedText}"`, '');
	const terms = textWithNoQuote
		.split(' ')
		.filter((s) => exclusiveTerms.indexOf(s) < 0);

	quotedText && terms.push(quotedText);

	const textsCheck = (comment: Comment, term: string) => {
		return (
			comment.commentText?.toLowerCase()?.includes(term) ||
			comment.createdByUser.firstName?.toLowerCase()?.includes(term) ||
			comment.createdByUser.lastName?.toLowerCase()?.includes(term)
		);
	};

	const matchByTerm = (comment: Comment) => {
		if (!textWithNoQuote.toLowerCase().includes(' and ')) {
			const index = terms.findIndex((term) => textsCheck(comment, term));
			return index >= 0;
		} else {
			return terms.every((term) => textsCheck(comment, term)); // Check for others above
		}
	};

	return comments.filter((comment) => {
		if (comment.parentCommentId === null) {
			return (
				matchByTerm(comment) ||
				comments
					.filter((c) => c.parentCommentId === comment.id)
					.some((c) => matchByTerm(c))
			);
		}
		return matchByTerm(comment);
	});
};

export const freshComment = (comment: Partial<Comment>, user: User) => ({
	id: 0,
	projectId: comment.projectId!,
	commentText: '',
	parentCommentId: comment.parentCommentId ?? comment.id,
	commentSupports: [],
	commentFlags: [],
	starred: false,
	incorporated: false,
	featured: false,
	markedForFollowUp: false,
	approved: true,
	published: undefined,
	deleted: false,
	createdByUser: user,
	question: comment.question!,
	created: Date.now().toString(),
});

export const existingComment = (comment: Comment) => ({
	projectId: comment.projectId,
	commentText: comment.commentText,
	parentCommentId: comment.parentCommentId,
	commentSupports: [],
	commentFlags: [],
	starred: false,
	incorporated: false,
	featured: false,
	markedForFollowUp: false,
	approved: false,
	published: Boolean(comment.published),
	deleted: false,
	createdByUser: comment.createdByUser,
	question: comment.question,
	created: Date.now().toString(),
});

// If condition returns true - comment is valid
export const filterConditions = {
	featured: (
		comment: Comment,
		comments: Comment[],
		parentComments: Comment[],
	) => {
		const parentComment = parentComments.find(
			(c) => c.id === comment.parentCommentId,
		);
		return (
			comment.id === 0 ||
			comment.featured ||
			(!comment.featured && parentComment?.featured) ||
			comments.some((c) => c.parentCommentId === comment.id && c.featured)
		);
	},
	starred: (
		comment: Comment,
		comments: Comment[],
		parentComments: Comment[],
	) => {
		const parentComment = parentComments.find(
			(c) => c.id === comment.parentCommentId,
		);
		return (
			comment.id === 0 ||
			comment.starred ||
			(!comment.starred && parentComment?.starred) ||
			comments.some((c) => c.parentCommentId === comment.id && c.starred)
		);
	},
	incorporated: (
		comment: Comment,
		comments: Comment[],
		parentComments: Comment[],
	) => {
		const parentComment = parentComments.find(
			(c) => c.id === comment.parentCommentId,
		);
		return (
			comment.id === 0 ||
			comment.incorporated ||
			(!comment.incorporated && parentComment?.incorporated) ||
			comments.some((c) => c.parentCommentId === comment.id && c.incorporated)
		);
	},
	followUp: (
		comment: Comment,
		comments: Comment[],
		parentComments: Comment[],
	) => {
		const parentComment = parentComments.find(
			(c) => c.id === comment.parentCommentId,
		);
		return (
			comment.id === 0 ||
			comment.markedForFollowUp ||
			(!comment.markedForFollowUp && parentComment?.markedForFollowUp) ||
			comments.some(
				(c) => c.parentCommentId === comment.id && c.markedForFollowUp,
			)
		);
	},
	flagged: (
		comment: Comment,
		comments: Comment[],
		parentComments: Comment[],
	) => {
		const parentComment = parentComments.find(
			(c) => c.id === comment.parentCommentId,
		);
		return (
			comment.id === 0 ||
			comment.commentFlags?.length > 0 ||
			(comment.commentFlags?.length === 0 &&
				(parentComment?.commentFlags?.length ?? 0) > 0) ||
			comments.some(
				(c) => c.commentFlags?.length > 0 && c.parentCommentId === comment.id,
			)
		);
	},
	response: (
		comment: Comment,
		comments: Comment[],
		parentComments: Comment[],
		filterValue: string,
	) => {
		if (filterValue === 'needed') {

			const childComments = comments.filter((c) => c.parentCommentId === comment.id);
			// Filter for parent - every child should have
			// public userType
			const firstCondition =
				comment.parentCommentId === null &&
				comment.createdByUser.userType === 'public' &&
				comment.approved &&
				childComments.every((c) => isCommentedByPublicUser(c) || c.id === 0) &&
				(childComments.length === 0 || childComments.some((c) => c.approved));

			// Filter for children - if comment has public userType
			const secondCondition = parentComments.find(
				(parentComment) =>
					comment.parentCommentId === parentComment.id &&
					isCommentedByPublicUser(parentComment) &&
					isCommentedByPublicUser(comment) &&
					comment.approved &&
					parentComment.approved,
			);

			return comment.id === 0 || firstCondition || secondCondition;
		}

		if (filterValue === 'responded') {
			return (
				// Filter for parent - at least one child comment
				// should have not userType public and be published
				(comment.parentCommentId === null &&
					isCommentedByPublicUser(comment) &&
					comments
						.filter((c) => c.parentCommentId === comment.id)
						.some((c) => !isCommentedByPublicUser(c) && c.published)) ||
				// Filter for children - if comment has not public userType
				parentComments.find(
					(parentComment) =>
						comment.parentCommentId === parentComment.id &&
						isCommentedByPublicUser(parentComment) &&
						!isCommentedByPublicUser(comment) &&
						comment.published,
				)
			);
		}

		if (filterValue === 'drafted') {
			if (
				comment.parentCommentId === null &&
				comments
					.filter((c) => c.parentCommentId === comment.id)
					.some((c) => !c.published)
			)
				return true;

			return !comment.published;
		}
	},
	topicId: (
		comment: Comment,
		comments: Comment[],
		parentComments: Comment[],
		filterValue: number,
	) => {
		return comment.question.questionGroups.some(({ id }) => id === filterValue);
	},
	questionId: (
		comment: Comment,
		comments: Comment[],
		parentComments: Comment[],
		filterValue: number,
	) => {
		return comment.question.id === filterValue;
	},
};
