export const lengthRegEx = (length: number) => {
	const replace = `(?=.{${length},})`;
	return new RegExp(replace, 'g');
};

export const trim = (str: string, ch: string) => {
	let start = 0,
		end = str.length;

	while (start < end && str[start] === ch) ++start;

	while (end > start && str[end - 1] === ch) --end;

	return start > 0 || end < str.length ? str.substring(start, end) : str;
};

export const spaceBetweenCaseChange = (str: string) => {
	return str.replace(/([A-Z])/g, ' $1').trim();
};

export const validateUrl = (str: string) => {
	// eslint-disable-next-line
	return /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i.test(
		str,
	);
};

export const validateEmail = (email: string) => {
	const re = // eslint-disable-next-line
		/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	return re.test(email.toLowerCase());
};
