import { gql } from 'urql';

export const createUpdateMutation = gql`
  mutation createUpdate($newUpdate: NewUpdateInput!) {
    createUpdate(newUpdate: $newUpdate) {
      id
    }
  }
`;

export const updateUpdateMutation = gql`
  mutation updateUpdate($existingUpdate: ExistingUpdateInput!) {
    updateUpdate(existingUpdate: $existingUpdate) {
      id
    }
  }
`;

export const deleteUpdateMutation = gql`
  mutation deleteUpdate($id: Int!) {
    deleteUpdate(id: $id)
  }
`;
