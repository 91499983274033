import { FC, MouseEvent } from 'react';
import { Box, Divider, useTheme } from '@mui/material';
import { usePausedContext } from '@coUrbanize/community-modules';
import TextButton from 'components/common/styled/TextButton';
import { AddCircleOutline as AddCircleOutlineIcon } from '@mui/icons-material';

interface AddSectionBlockProps {
	handleClick: (event: MouseEvent<HTMLButtonElement>) => void;
}

export const AddSectionBlock: FC<AddSectionBlockProps> = (props) => {
	const { handleClick } = props;

	const theme = useTheme();
	const { isPaused } = usePausedContext();

	return (
		<Box
			sx={{
				display: 'flex',
				flexDirection: 'row',
				justifyContent: 'center',
				alignItems: 'center',
			}}
		>
			<Divider
				sx={{
					width: 110,
					borderStyle: 'dashed',
					borderColor: theme.customPalette.systemColors.disabled,
				}}
			/>
			<TextButton
				disabled={!!isPaused}
				startIcon={<AddCircleOutlineIcon />}
				sx={{
					fontSize: 16,
					m: 2,
					fontWeight: 500,
				}}
				onClick={handleClick}
			>
				Add Section Block
			</TextButton>
			<Divider
				sx={{
					width: 110,
					borderStyle: 'dashed',
					borderColor: theme.customPalette.systemColors.disabled,
				}}
			/>
		</Box>
	);
};
