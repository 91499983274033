import { useTheme } from '@mui/material';
import { FC, CSSProperties } from 'react';

interface LongResponseIconProps {
	style?: CSSProperties;
	size?: number;
	color?: string;
}

const LongResponseIcon: FC<LongResponseIconProps> = (props) => {
	const theme = useTheme();
	const {
		style,
		size = 24,
		color = theme.customPalette.basicColors.dark,
	} = props;

	return (
		<svg
			style={style}
			width={size}
			height={size}
			viewBox="0 0 20 22"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M4 5C4 4.44772 4.44772 4 5 4H15C15.5523 4 16 4.44772 16 5C16 5.55228 15.5523 6 15 6H5C4.44771 6 4 5.55228 4 5Z"
				fill={color}
			/>
			<path
				d="M4 9C4 8.44771 4.44772 8 5 8H15C15.5523 8 16 8.44771 16 9C16 9.55229 15.5523 10 15 10H5C4.44771 10 4 9.55229 4 9Z"
				fill={color}
			/>
			<path
				d="M5 12C4.44772 12 4 12.4477 4 13C4 13.5523 4.44771 14 5 14H15C15.5523 14 16 13.5523 16 13C16 12.4477 15.5523 12 15 12H5Z"
				fill={color}
			/>
			<path
				d="M4 17C4 16.4477 4.44772 16 5 16H9C9.55228 16 10 16.4477 10 17C10 17.5523 9.55228 18 9 18H5C4.44772 18 4 17.5523 4 17Z"
				fill={color}
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M0 3C0 1.34315 1.34315 0 3 0H17C18.6569 0 20 1.34315 20 3V19C20 20.6569 18.6569 22 17 22H3C1.34315 22 0 20.6569 0 19V3ZM3 2H17C17.5523 2 18 2.44771 18 3V19C18 19.5523 17.5523 20 17 20H3C2.44772 20 2 19.5523 2 19V3C2 2.44772 2.44771 2 3 2Z"
				fill={color}
			/>
		</svg>
	);
};

export default LongResponseIcon;
