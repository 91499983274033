import React, { useEffect, useState } from 'react';
import { Box, InputLabel, Typography } from '@mui/material';
import ModuleInstanceData from '../../types/moduleInstanceData';
import { useModuleSaveData } from '../../hooks/useModuleSaveData';
import { parseUrl } from '../../utility/videoUtils';
import RegularTextInput from 'components/common/styled/RegularTextInput';
import useNumberParams from '../../hooks/useNumberParams';
import useProject from '../features/editProject/useProject';
import queryString from 'query-string';

interface VideoContent {
	videoUrl: string;
	embedUrl: string | undefined;
	providerId: string | undefined;
	provider: string | undefined;
}

const VideoModule: React.FC<ModuleInstanceData> = (props) => {
	const { content, saveDataRef, config } = props;
	const { updateModuleSaveData } = useModuleSaveData(saveDataRef);

	const [videoInfo, setVideoInfo] = useState<VideoContent>(content);
	const [twitchUrlCustomDomain, setTwitchUrlCustomDomain] = useState('x');
	const [error, setError] = useState('');

	const { projectId } = useNumberParams();
	const { project } = useProject(projectId);

	useEffect(() => {
		updateModuleSaveData({ content: videoInfo, config });
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [updateModuleSaveData, config]);

	useEffect(() => {
		const defaultDomainName = 'courbanize.com';
		const localhost = 'localhost';
		const { hostname } = window?.location;

		// allows twitch video to play in admin and locally
		if (
			hostname &&
			videoInfo?.provider === 'twitch' &&
			(hostname.toLowerCase().includes(defaultDomainName) ||
				hostname.toLowerCase().includes(localhost))
		) {
			const { embedUrl } = videoInfo;

			if (embedUrl) {
				const parsedQS = queryString.parse(
					embedUrl.substring(embedUrl.indexOf('?')),
				);
				parsedQS.parent = hostname.toLowerCase().includes(defaultDomainName)
					? defaultDomainName
					: localhost;
				setTwitchUrlCustomDomain(
					embedUrl.substring(0, embedUrl.indexOf('?') + 1) +
						queryString.stringify(parsedQS),
				);
			}
		} else {
			setTwitchUrlCustomDomain('');
		}
	}, [videoInfo]);

	const handleChange = (value: string) => {
		const isCustomUrl = Boolean(project.customUrlEnabledDate);
		const customUrl = project.customUrl;
		const parsedUrl = parseUrl(
			value as string,
			isCustomUrl && customUrl ? customUrl : window.location.hostname,
		);
		if (value && !parsedUrl) {
			setError('Invalid Video Url');
		} else setError('');

		setVideoInfo({
			videoUrl: value,
			embedUrl: parsedUrl?.embed,
			providerId: parsedUrl?.id!,
			provider: parsedUrl?.provider!,
		});
	};
	return (
		<Box minWidth={500}>
			<InputLabel id="url-label">Url</InputLabel>
			<RegularTextInput
				size="small"
				color="secondary"
				type="url"
				sx={{ mt: 1, mb: 1, width: 318 }}
				aria-describedby="url-error-text"
				error={!!error}
				helperText={error}
				value={videoInfo?.videoUrl}
				FormHelperTextProps={{ error: !!error }}
				onChange={(e) => handleChange(e.target.value)}
			/>
			{videoInfo?.videoUrl ? (
				<Box textAlign="center" position="relative" width="100%" pb="56.25%">
					<iframe
						title={videoInfo?.provider}
						style={{
							border: 'none',
							borderRadius: '5px',
							position: 'absolute',
							width: '100%',
							height: '100%',
							top: 0,
							left: 0,
						}}
						src={
							twitchUrlCustomDomain !== ''
								? twitchUrlCustomDomain
								: videoInfo?.embedUrl
						}
					></iframe>
				</Box>
			) : (
				<Typography
					textAlign="center"
					mb={2}
					p={1}
					variant="h6"
					border="1px solid #ccc"
				>
					No Video To Preview
				</Typography>
			)}
		</Box>
	);
};

export default VideoModule;
