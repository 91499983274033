import { EventType } from '../hooks/useProjectSettings';

const settingOptions: {
	name: EventType;
	label: string;
	miniCardLabel?: string;
	description?: string;
}[] = [
	{
		name: 'pausedDate',
		label: 'Paused',
		description:
			'Pausing will freeze all site inputs, but still display its contents.',
	},
	{
		name: 'disabledDate',
		label: 'Disabled',
		description:
			'Disabling will hide all site content, freeze features and replace with a generic not available page.',
	},
	{
		name: 'archivedDate',
		label: 'Archived',
		description:
			'Archiving acts much like pausing, but is used when a project is complete. While this can be undone, it is considered a more permanent state.',
	},
	{
		name: 'emailEnabledDate',
		label: 'Enable Scheduled Email Delivery',
		description:
			'Enabling scheduled email delivery will enable all automated emails sent by this project. Best to set once the project is live and ready for activity. Invite and password change emails are always enabled.',
	},
	{
		name: 'translationEnabledDate',
		label: 'Enable Translation',
		miniCardLabel: 'Localize Key',
		description:
			'Enable language translation options for this site using a Localize key. Translation languages need to be configured on Localize\'s servers.',
	},
	{
		name: 'customUrlEnabledDate',
		label: 'Enable Custom Domain',
		miniCardLabel: 'Custom Domain',
		description:
			'Enable a custom domain for this site instead of displaying with a project slug off of coUrbanize.com. The domain owner\'s DNS A record must be set to point towards coUrbanize\'s servers.',
	},
	{
		name: 'emailSandboxEnabledDate',
		label: 'Enable Email Sandbox',
		miniCardLabel: 'Email Sandbox',
		description:
			'Enable email sandbox for this site so that all emails sent by this site are funneled to the email address provided.',
	},
	{
		name: 'smsSubscribeEnableDate',
		label: 'Enable SMS Subscribe',
		miniCardLabel: 'SMS Subscribe',
		description:
			'Enable SMS Subscribe for this site so that users can send an SMS to this number and receive text updates.',
	},
];

export default settingOptions;
