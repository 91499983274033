import { useTheme } from '@mui/material';
import { PickersDay, PickersDayProps } from '@mui/x-date-pickers/PickersDay';

export function CustomDayPicker(
	pickersDayProps: PickersDayProps<string>,
): JSX.Element {
	const theme = useTheme();
	return (
		<PickersDay
			sx={{
				borderRadius: 0,
				backgroundColor: theme.customPalette.surface.light,
				color: theme.customPalette.systemColors.disabled,
				'&:hover': {
					backgroundColor: theme.customPalette.primaryColors.sky[5],
					color: theme.customPalette.primaryColors.sky[1],
				},
				'&:focus': {
					backgroundColor: theme.customPalette.primaryColors.sky[1],
					color: theme.customPalette.basicColors.light,
				},
				'&.Mui-active': {
					backgroundColor: theme.customPalette.primaryColors.sky[1],
					color: theme.customPalette.basicColors.light,
				},
				'&.Mui-selected': {
					backgroundColor: `${theme.customPalette.primaryColors.sky[1]} !important`,
					color: `${theme.customPalette.basicColors.light} !important`,
				},
				borderTopLeftRadius: '4%',
				borderBottomLeftRadius: '4%',
				borderBottomRightRadius: '4%',
				borderTopRightRadius: '4%',
			}}
			{...pickersDayProps}
		/>
	);
}
