import { FC, useRef } from 'react';
import * as Yup from 'yup';
import { Grid, InputLabel } from '@mui/material';
import { Formik, Field } from 'formik';

import ConfirmationDialog from 'components/common/ConfirmationDialog';
import RegularTextInput from 'components/common/styled/RegularTextInput';
import User from '../../../types/user';

const validationSchema = Yup.object({
	firstName: Yup.string()
		.required('First Name required')
		.max(15, 'First Name must be 15 characters or less'),
	lastName: Yup.string()
		.required('Last Name required')
		.max(20, 'Last Name must be 20 characters or less'),
	email: Yup.string().required('Email required').email('Invalid email address'),
});

type EditProjectUserFormDialogProps = {
	onConfirm: (values: User) => void;
	onCancel: () => void;
	isOpen: boolean;
	values: User;
};

const EditProjectUserFormDialog: FC<EditProjectUserFormDialogProps> = ({
	onConfirm,
	onCancel,
	isOpen,
	values,
}) => {
	const submitRef = useRef<HTMLButtonElement>(null);

	const onInternalConfirm = () => {
		submitRef!.current!.click();
	};

	return (
		<ConfirmationDialog
			open={isOpen}
			title="Edit User"
			disableBackdropClick
			confirmLabel="Update"
			onConfirm={onInternalConfirm}
			onCancel={onCancel}
			paddingSize="small"
		>
			{({
				setDisabled,
				disabled,
			}: {
				setDisabled: (disabled: boolean) => void;
				disabled: boolean;
			}) => {
				return (
					<Formik
						enableReinitialize
						validateOnChange={false}
						validationSchema={validationSchema}
						initialValues={values}
						onSubmit={(values) => {
							onConfirm(values as User);
						}}
					>
						{({ dirty, isValid, errors, touched, values, handleSubmit }) => {
							if (isValid && disabled) {
								setDisabled(false);
							}

							if (!isValid && dirty && !disabled) {
								setDisabled(true);
							}

							return (
								<form
									onSubmit={handleSubmit}
									noValidate
									autoComplete="off"
									style={{ width: '400px' }}
								>
									<Grid container spacing={1}>
										<Grid item xs={12}>
											<InputLabel id="firstName">First Name</InputLabel>
											<Field
												as={RegularTextInput}
												sx={{ width: '400px' }}
												name="firstName"
												size="small"
												value={values.firstName}
												error={Boolean(touched.firstName && errors.firstName)}
												helperText={touched.firstName ? errors.firstName : ''}
											/>
										</Grid>
										<Grid item xs={12}>
											<InputLabel id="lastName">Last Name</InputLabel>
											<Field
												as={RegularTextInput}
												sx={{ width: '400px' }}
												name="lastName"
												size="small"
												value={values.lastName}
												error={Boolean(touched.lastName && errors.lastName)}
												helperText={touched.lastName ? errors.lastName : ''}
											/>
										</Grid>
										<Grid item xs={12}>
											<InputLabel id="email">Email</InputLabel>
											<Field
												as={RegularTextInput}
												sx={{ width: '400px' }}
												name="email"
												variant="outlined"
												size="small"
												value={values.email}
												error={Boolean(touched.email && errors.email)}
												helperText={touched.email ? errors.email : ''}
											/>
										</Grid>
										<button
											ref={submitRef}
											style={{ display: 'none' }}
											type="submit"
										>
											Submit
										</button>
									</Grid>
								</form>
							);
						}}
					</Formik>
				);
			}}
		</ConfirmationDialog>
	);
};

export default EditProjectUserFormDialog;
