import { gql } from 'urql';

export const commentsByProjectQuery = gql`
	query ($projectId: Int!) {
		commentsByProject(projectId: $projectId) {
			id
			projectId
			commentText
			parentCommentId
			commentSupports {
				id
				createdBy
			}
			commentFlags {
				id
				createdBy
			}
			starred
			featured
			incorporated
			markedForFollowUp
			approved
			published
			deleted
			createdByUser {
				id
				fullName
				firstName
				lastName
				userType
			}
			moderated
			moderatedByUser {
				id
				fullName
				firstName
				lastName
				userType
			}
			question {
				id
				questionText
				questionGroups {
					id
				}
			}
			mobileUserId
			created
			mobileUser {
				id
				mobileNumber
				mobileType
				blocked
			}
		}
	}
`;
