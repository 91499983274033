import { Button, ButtonProps, styled } from '@mui/material';
// TODO remove these important mods when transparent leaking style is handled
const RegularButton = styled(Button)<ButtonProps>(({ theme, color }) => ({
	color: theme.customPalette.basicColors.light,
	backgroundColor:
		color !== 'secondary'
			? `${theme.customPalette.primaryColors.sky[1]}`
			: `${theme.customPalette.primaryColors.grassGreen[1]}`,
	boxShadow: 'none',
	textTransform: 'capitalize',
	'&:hover': {
		backgroundColor: `${theme.customPalette.primaryColors.dusk[5]}`,
		boxShadow: 'none',
	},
	'&.Mui-disabled': {
		color: `${theme.customPalette.basicColors.light}`,
		backgroundColor: `${theme.customPalette.systemColors.disabled}`,
	},
}));

export default RegularButton;
