import { Popover, Card, Box, CardHeader, useTheme } from '@mui/material';
import { subject } from '@casl/ability';
import { Can } from '../../../providers/CaslAbilityProvider';
import {
	permissionAction,
	permissionSubject,
} from '../../../utility/defineAbilityFor';
import { ModulePickerOption } from './ModulePickerOption';
import { ModuleType } from '../../../types/types';

interface ModulePickerProps {
	modules: Array<ModuleType>;
	handleSelect: (
		moduleType: ModuleType,
		templateInstanceId: number,
		sectionId: number,
		config: object,
	) => void;
	anchorEl: any;
	setAnchorEl: any;
	instanceId: number;
	sectionId: number;
}

export const ModulePicker = ({
	modules,
	handleSelect,
	anchorEl,
	setAnchorEl,
	sectionId,
	instanceId,
}: ModulePickerProps) => {
	const open = Boolean(anchorEl);
	const theme = useTheme();

	const handleClose = () => {
		setAnchorEl(null);
	};

	const handleSelectItem = (
		moduleType: ModuleType,
		templateInstanceId: number,
		sectionId: number,
	) => {
		handleClose();
		handleSelect(moduleType, templateInstanceId, sectionId, {});
	};

	return (
		<Popover
			open={open}
			anchorEl={anchorEl}
			onClose={handleClose}
			anchorOrigin={{
				vertical: 'center',
				horizontal: 'right',
			}}
		>
			<Card
				sx={{
					columnGap: 2,
					rowGap: 3,
					width: '460px',
					padding: '20px',
				}}
			>
				<CardHeader
					sx={{
						color: theme.customPalette.primaryColors.grassGreen[2],
						fontSize: '12px',
					}}
					title="Modules"
				/>
				{modules && (
					<Box display="grid" gridTemplateColumns="1fr 1fr" gap={1}>
						{modules.map((moduleType: ModuleType) => (
							<Can
								I={permissionAction.MANAGE}
								this={subject(permissionSubject.MODULE, moduleType)}
								key={moduleType.id}
							>
								<Box>
									<ModulePickerOption
										moduleType={moduleType}
										sectionId={sectionId}
										instanceId={instanceId}
										handleClick={handleSelectItem}
									/>
								</Box>
							</Can>
						))}
					</Box>
				)}
			</Card>
		</Popover>
	);
};
