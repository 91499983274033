import { useState, useEffect } from 'react';
import { useMutation, useQuery } from 'urql';

import { userProjectsWithRoleAndNoRoleQuery } from '../../../../../api/query/project.queries';
import {
	addUserToProjectMutation,
	removeUserFromProjectMutation,
} from '../../../../../api/mutation/project.mutation';
import { useSnackBar } from '../../../../../providers/SnackBarProvider';

interface Project {
	id: number;
	name: string;
}

const useUserProjectForm = (userId: number) => {
	const [userProjects, setUserProjects] = useState([] as Project[]);
	const [userProjectsNoRole, setUserProjectsNoRole] = useState([] as Project[]);
	const [selectedProject, setSelectedProject] = useState({ id: 0, name: '' });
	const [current, setCurrent] = useState({ id: 0, name: '' });
	const [openAdd, setOpenAdd] = useState(false);
	const [openDelete, setOpenDelete] = useState(false);
	const { notify } = useSnackBar();

	const [{ data }] = useQuery({
		query: userProjectsWithRoleAndNoRoleQuery,
		variables: { userId },
	});
	const [, addUserToProject] = useMutation(addUserToProjectMutation);
	const [, removeUserFromProject] = useMutation(removeUserFromProjectMutation);

	const userProjectsWORole = data?.userProjectsWithRoleAndNoRole as {
		projectsWithRole: Project[];
		projectsWithNoRole: Project[];
	};

	useEffect(() => {
		if (userProjectsWORole) {
			setUserProjects(userProjectsWORole?.projectsWithRole);
			setUserProjectsNoRole(userProjectsWORole?.projectsWithNoRole);
		}
	}, [userProjectsWORole]);

	const handleAddUser = () => {
		if (selectedProject.id === 0)
			return notify('You must select a project from the dropdown', 'warning');
		addUserToProject({ userId, projectId: selectedProject.id }); // save in db
		setUserProjects([...userProjects, selectedProject]); // add to projects
		// remove from projects with no role
		setUserProjectsNoRole(
			userProjectsNoRole.filter((p) => p.id !== selectedProject.id),
		);
		setSelectedProject({ id: 0, name: '' }); // reset search
		setOpenAdd(false);
	};

	const handleRemoveUser = (project: Project) => {
		if (project.id === 0) return;
		removeUserFromProject({ userId, projectId: project.id }); // remove from db
		setUserProjectsNoRole([...userProjectsNoRole, project]); // add to projects with no role
		setUserProjects(userProjects.filter((p) => p.id !== project.id)); // remove from projects
		setSelectedProject({ id: 0, name: '' }); // reset search
		setOpenDelete(false);
	};

	const handleChange = (e: any, value: string | null) => {
		if (value) {
			const selectedProject = userProjectsNoRole.find((p) => p.name === value);

			if (selectedProject) {
				setSelectedProject({ id: selectedProject.id, name: value });
			}
		}
	};

	return {
		openAdd,
		openDelete,
		setOpenAdd,
		setOpenDelete,
		selectedProject,
		current,
		setCurrent,
		// sort projects in alphabetical order
		userProjects: userProjects.sort((a, b) => a.name.localeCompare(b.name)),
		userProjectsNoRole,
		handleChange,
		handleAddUser,
		handleRemoveUser,
	};
};

export default useUserProjectForm;
