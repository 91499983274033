import { createContext, useContext, useState, useEffect } from 'react';
import { useQuery } from 'urql';
import { useAuth0 } from '@auth0/auth0-react';
import User from '../types/user';
import { avatarByUserQuery } from '../api/query/avatar.queries';

export interface AvatarType {
	id: number;
	fileName: string;
	imageUrl: string;
}
interface UserContextType {
	user: User | null;
	isUserLoading: boolean;
	userAvatar: AvatarType | null | undefined;
	updateAvatarImage: (url: string) => void;
}

const USER_CONTEXT_DEFAULT = {
	user: null,
	isUserLoading: false,
	userAvatar: null,
	updateAvatarImage: () => null,
};

export const UserContext = createContext<UserContextType>(USER_CONTEXT_DEFAULT);

const UserProvider = (props: any) => {
	const { user: auth0User, isAuthenticated } = useAuth0();
	const currentUserQuery = `
    query ($email: String!) {
      currentUser(email: $email) {
        id
        firstName
        lastName
		email
        fullName
        userType
        projectDetails {
          projectId
          roleType
        }
      }
    }
  `;
	const [user, setUser] = useState(null);
	const [userAvatar, setUserAvatar] = useState<AvatarType | null>(null);

	const [{ data, fetching: userFetching }] = useQuery({
		query: currentUserQuery,
		pause: !isAuthenticated,
		variables: { email: auth0User?.email },
	});
	const [{ data: avatar, fetching: avatarFetching }] = useQuery({
		query: avatarByUserQuery,
		variables: { userId: data?.currentUser?.id },
		pause: !data?.currentUser.id,
	});

	useEffect(() => {
		if (!userFetching && !avatarFetching) {
			setUserAvatar(avatar?.avatarsByUser);
			setUser(data?.currentUser);
		}
	}, [data?.currentUser, avatar?.avatarsByUser, userFetching, avatarFetching]);

	const updateAvatarImage = (imageUrl: string) => {
		if (userAvatar) setUserAvatar({ ...userAvatar, imageUrl });
		else setUserAvatar({ id: 0, imageUrl, fileName: '' });
	};

	const isUserLoading = userFetching || avatarFetching;

	const contextValues = {
		user,
		userAvatar,
		isUserLoading,
		updateAvatarImage,
	};

	return (
		<UserContext.Provider value={contextValues}>
			{props.children}
		</UserContext.Provider>
	);
};

export const useUserContext = () => useContext(UserContext);

UserProvider.displayName = 'UserProvider';
export default UserProvider;
