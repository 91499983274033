import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import {
	createClient,
	Provider,
	dedupExchange,
	cacheExchange,
	fetchExchange,
	Exchange,
	Operation,
} from 'urql';
import { devtoolsExchange } from '@urql/devtools';
import { pipe, map, mergeMap, fromPromise, fromValue } from 'wonka';
import { useEnv } from './EnvProvider';

const AuthorizedUrqlProvider: React.FC<{ children: React.ReactNode }> = ({
	children,
}) => {
	const { getAccessTokenSilently } = useAuth0();

	const { apiServerUrl } = useEnv();

	const fetchOptionsExchange =
		(fn: any): Exchange =>
			({ forward }) =>
				// @ts-ignore
				(ops$) => {
					return pipe(
						// @ts-ignore
						ops$,
						mergeMap((operation: Operation) => {
							const result = fn(operation.context.fetchOptions);
							return pipe(
								(typeof result.then === 'function'
									? fromPromise(result)
									: fromValue(result)) as any,
								map((fetchOptions: RequestInit | (() => RequestInit)) => ({
									...operation,
									context: { ...operation.context, fetchOptions },
								})),
							);
						}),
						forward,
					);
				};

	// want to debug? -> https://github.com/FormidableLabs/urql-devtools-exchange#usage
	const client = createClient({
		url: apiServerUrl,
		exchanges: [
			// @ts-ignore
			devtoolsExchange,
			dedupExchange,
			cacheExchange,
			fetchOptionsExchange(async (fetchOptions: any) => {
				const token = await getAccessTokenSilently();

				return Promise.resolve({
					...fetchOptions,
					headers: {
						Authorization: token ? `Bearer ${token}` : '',
						'X-Client-URL': window.location.href,
					},
				});
			}),
			fetchExchange,
		],
		requestPolicy: 'network-only',
	});

	return <Provider value={client}>{children}</Provider>;
};

export default AuthorizedUrqlProvider;
