export type FilterName =
	| 'all'
	| 'needed'
	| 'drafted'
	| 'responded'
	| 'starred'
	| 'followUp'
	| 'featured'
	| 'incorporated';

export type NavItem = {
	title: string;
	name: FilterName;
	count?: number;
};

export type Filter = {
	selectedTab: string;
	topicId: number;
	questionId: number;
};

export type ActiveNav = {
	all: boolean;
	featured: boolean;
	drafted: boolean;
	needed: boolean;
	responded: boolean;
	starred: boolean;
	followUp: boolean;
	incorporated: boolean;
};

export const defaultActive: ActiveNav = {
	all: true,
	drafted: false,
	needed: false,
	responded: false,
	starred: false,
	followUp: false,
	featured: false,
	incorporated: false,
};

export const defaultFilter: Filter = {
	selectedTab: 'all',
	topicId: 0,
	questionId: 0,
};

export const sortByList = [
	{ name: 'recentComments', title: 'Recent Comments' },
	{ name: 'oldest', title: 'Oldest Comments' },
	{ name: 'recentActivities', title: 'Recent Activities' },
	{ name: 'popular', title: 'Most popular' },
];

export const navItems = [
	{ title: 'All', name: 'all' },
	{ title: 'Drafted Responses', name: 'drafted' },
	{ title: 'Response needed', name: 'needed' },
	{ title: 'Response given', name: 'responded' },
	{ title: 'Starred', name: 'starred' },
	{ title: 'Follow up', name: 'followUp' },
	{ title: 'Featured', name: 'featured' },
	{ title: 'Added to plan', name: 'incorporated'},
];
