import React, { FC, useEffect, useState } from 'react';
import { useMutation, useQuery } from 'urql';
import {
	Button,
	Chip,
	ChipProps,
	Divider,
	Grid,
	Stack,
	styled,
	Box,
	useTheme,
	InputLabel,
} from '@mui/material';
import { PostAdd as PostAddIcon } from '@mui/icons-material';
import { usePausedContext } from '@coUrbanize/community-modules';
import { useNavigate } from 'react-router-dom';
import Loading from '../../common/Loading';
import { questionGroupsByProjectQuery } from '../../../api/query/questions.queries';
import {
	addQuestionMutation,
	createQuestionGroupMutation,
	removeQuestion as removeQuestionMutation,
	reorderQuestion as reorderQuestionMutation,
	updateQuestionGroupMutation,
	removeQuestionGroup as removeQuestionGroupMutation,
	toggleHideQuestion as toggleHideQuestionMutation,
} from '../../../api/mutation/questions.mutations';
import useNumberParams from '../../../hooks/useNumberParams';
import {
	QuestionGroup as QuestionGroupComponent,
	QuestionType,
} from './QuestionGroup';
import { Page, Question, QuestionGroup } from '../../../types/types';
import { useSnackBar } from '../../../providers/SnackBarProvider';
import ConfirmationDialog from '../../common/ConfirmationDialog';
import { uniqBy } from 'lodash';
import { TitleCaseHyphenatedWord } from '../../../utility/titleCaseHyphenatedWord';
import PageHeader from 'components/common/PageHeader';
import ButtonLink from 'components/common/ButtonLink';
import RegularTextInput from 'components/common/styled/RegularTextInput';
import useComments from '../../features/commentsPage/useComments';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { QuestionMenu } from './QuestionMenu';
import { QuestionFormModal } from './QuestionFormModal';
import { DragDropContext, Draggable, DropResult } from 'react-beautiful-dnd';
import StrictModeDroppable from 'components/common/StrictModeDroppable';
import { QuestionSummaryItem } from './QuestionSummaryItem';
import _ from 'lodash';
import { produce } from 'immer';
import { useAbandonActionConfirmation } from 'hooks/useActionConfirmation';
import usePublishPage from '../../features/publishPage/hook/usePublishPage';
import { pagesQuery } from '../../../api/query/page.queries';
import HelpScoutLink from '../../common/HelpScoutLink';

export enum GroupStatus {
	// eslint-disable-next-line no-unused-vars
	ALL = 'All',
	// eslint-disable-next-line no-unused-vars
	ACTIVE = 'active',
	// eslint-disable-next-line no-unused-vars
	INACTIVE = 'inactive',
}

const GroupChip = styled(Chip, {
	shouldForwardProp: (prop) => prop !== 'active',
})<ChipProps & { active?: boolean }>(({ theme, active }) => {
	let activeStyles = {};

	if (active) {
		activeStyles = {
			backgroundColor: theme.customPalette.primaryColors.sky[1],
			color: theme.customPalette.basicColors.light,
		};
	}

	return {
		height: '38px',
		borderRadius: '12px',
		padding: '7px 12px',
		backgroundColor: 'unset',
		color: theme.customPalette.primaryColors.sky[1],
		fontWeight: '900',
		fontSize: '16px',
		lineHeight: '24px',
		'&:hover': {
			backgroundColor: theme.palette.secondary.main,
			color: theme.customPalette.basicColors.light,
		},
		...activeStyles,
	};
});

export const QuestionGroupsContainer: FC = () => {
	const { projectId } = useNumberParams();
	const [{ data }, refetch] = useQuery({
		query: questionGroupsByProjectQuery,
		variables: { projectId: projectId, active: false },
	});

	const { notify } = useSnackBar();
	const [open, setOpen] = useState(false);
	const [name, setName] = useState('');
	const [selectedGroupStatus, setSelectedGroupStatus] = useState<GroupStatus>(
		GroupStatus.ALL,
	);
	const [questionGroups, setQuestionGroups] = useState<QuestionGroup[]>([]);
	const [selectedQuestionGroupId, setSelectedQuestionGroupId] =
		useState<number>(0);
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const [selectedQuestion, setSelectedQuestion] = useState<null | Question>(
		null,
	);
	const [orderedQuestions, setOrderedQuestions] = useState<Question[]>([]);
	const [touched, setTouched] = useState(false);

	const [, removeQuestion] = useMutation(removeQuestionMutation);
	const [, toggleHideQuestion] = useMutation(toggleHideQuestionMutation);
	const [, removeQuestionGroup] = useMutation(removeQuestionGroupMutation);
	const [, reorderQuestion] = useMutation(reorderQuestionMutation);
	const [, createQuestionGroup] = useMutation(createQuestionGroupMutation);
	const [, updateQuestionGroup] = useMutation(updateQuestionGroupMutation);
	const [, addQuestion] = useMutation(addQuestionMutation('id'));

	const { isPaused } = usePausedContext();
	const navigate = useNavigate();
	const theme = useTheme();

	const [dragDisabled, setDragDisabled] = useState(true);
	const [isNew, setIsNew] = useState(true);

	const showAbandonChanges = useAbandonActionConfirmation();

	const isDraftOrArchived =
		name.toLowerCase().includes('draft') ||
		name.toLowerCase().includes('archived');

	const [{ data: pagesData }] = useQuery({
		query: pagesQuery,
		variables: { projectId: projectId },
	});

	const pages = pagesData?.pagesByProject;
	const page = pages?.find((p: Page) => p.name.toLowerCase() === 'feedback');

	const { handlePublishPage } = usePublishPage(page, []);

	useEffect(() => {
		if (data?.questionGroupsByProject) {
			setQuestionGroups(data.questionGroupsByProject);
		}
	}, [data?.questionGroupsByProject]);

	useEffect(() => {
		if (questionGroups) {
			const currentGroup = questionGroups.find(
				(g: QuestionGroup) => g.id === selectedQuestionGroupId,
			);

			setOrderedQuestions(_.sortBy(currentGroup?.questions, 'ordinal'));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [questionGroups, selectedQuestionGroupId]);

	const { commentCountPerQuestionId } = useComments();

	const addNewQuestion = async (type: string) => {
		const newQuestion = await handleAddQuestion(
			selectedQuestionGroupId,
			type,
			null,
		);
		setSelectedQuestion({ ...newQuestion, type });
	};

	const handleAddQuestion = async (
		questionGroupId: number,
		type: string | null,
		questionId: number | null,
	) => {
		const addQuestionData = questionId
			? {
				questionGroupId,
				questionId,
				type,
			}
			: {
				questionGroupId,
				type,
				projectId,
			};
		const result = await addQuestion({
			addQuestionData,
		});
		if (!result.error) {
			if (page) handlePublishPage(true);
			refetch();
		}
		return result.data.addQuestion as Question;
	};

	const handleModalCancel = async () => {
		if (isNew) {
			if (name || orderedQuestions.length > 0) {
				await showAbandonChanges(
					{
						title: 'Abandon Changes?',
						message: 'The new question group will not be created',
					},
					async () => {
						setOpen(false);
						await removeQuestionGroup({
							questionGroupId: selectedQuestionGroupId,
						});
					},
				);
			} else {
				setOpen(false);
				await removeQuestionGroup({
					questionGroupId: selectedQuestionGroupId,
				});
			}
		} else {
			setOpen(false);
		}
	};

	const handleRemoveQuestion = async (
		questionGroupId: number,
		questionId: number,
	) => {
		const result = await removeQuestion({
			questionGroupId,
			questionId,
		});
		if (!result.error) {
			notify('Question was removed');
			if (page) handlePublishPage(true);
			refetch();
		} else notify('Question could not be removed', 'error');
	};

	const handleToggleHideQuestion = async (
		questionGroupId: number,
		questionId: number,
	) => {
		const result = await toggleHideQuestion({
			questionGroupId,
			questionId,
		});
		if (!result.error) {
			notify('Question was updated');
			if (page) handlePublishPage(true);
			refetch();
		} else notify('Question could not be updated', 'error');
	};

	const handleUpdateQuestionGroup = async (
		questionGroupId: number,
		themeName: string,
	) => {
		if (!themeName) return;
		const result = await updateQuestionGroup({
			questionGroupId,
			themeName,
		});
		if (!result.error) {
			if (page) handlePublishPage(true);
			refetch();
			notify('Question Group updated');
		} else {
			notify('Question Group could not be updated', 'error');
		}
	};

	const handleUpdateQuestionGroupStatus = async (
		questionGroupId: number,
		status: string,
	) => {
		if (!status) return;
		const result = await updateQuestionGroup({
			questionGroupId,
			status,
		});
		if (!result.error) {
			if (page) handlePublishPage(true);
			refetch();
			notify('Question Group updated');
		} else {
			notify('Question Group could not be updated', 'error');
		}
	};

	const handleCreateQuestionGroup = async (themeName: string) => {
		if (!themeName) return;
		const result = await updateQuestionGroup({
			questionGroupId: selectedQuestionGroupId,
			themeName,
		});
		if (!result.error) {
			if (page) handlePublishPage(true);
			refetch();
			notify(`Question Group ${isNew ? 'created' : 'updated'}`);
		} else {
			notify('Question Group could not be created', 'error');
		}
	};

	const createEmptyQuestionGroup = async () => {
		const result = await createQuestionGroup({
			themeName: '',
			projectId,
			ordinal: 0,
		});
		if (!result.error) {
			setSelectedQuestionGroupId(result.data?.createQuestionGroup?.id);
			setIsNew(true);
		} else {
			notify('Question Group could not be created', 'error');
		}
	};

	const handleUpdateQuestion = (updatedQuestion: Question) => {
		const updatedGroup = questionGroups.map((group) => {
			return {
				...group,
				questions: group?.questions?.map((question) => {
					if (question.id === updatedQuestion.id) return updatedQuestion;
					else return question;
				}),
			};
		});
		setQuestionGroups(updatedGroup);
	};

	const handleReorderQuestion = async (
		questionGroupId: number,
		sourceIndex: number,
		destinationIndex: number,
	) => {
		const result = await reorderQuestion({
			destinationIndex,
			sourceIndex,
			questionGroupId,
		});
		if (!result.error) {
			if (page) handlePublishPage(true);
			refetch();
		}
	};

	const handleQuestionItemCountClick = (
		groupId: number,
		questionId: number,
	) => {
		navigate(
			`/projects/${projectId}/comments?topicId=${groupId}&questionId=${questionId}`,
		);
	};

	if (!data || !data.questionGroupsByProject) return <Loading />;

	//unique list of questions

	let availableQuestions: Question[] = data.questionGroupsByProject
		.map((qg: QuestionGroup) => qg.questions)
		.flat();
	availableQuestions = uniqBy(availableQuestions, 'id');

	const handleAddQuestionClick = (
		event: React.MouseEvent<HTMLButtonElement>,
	) => {
		setAnchorEl(event.currentTarget);
	};

	const handleDragEnd = (result: DropResult) => {
		if (!result.destination) {
			return;
		}

		const [sourceIndex, destinationIndex] = [
			result.source.index,
			result.destination!.index,
		];

		if (destinationIndex === sourceIndex) {
			return;
		}

		const newState = produce(orderedQuestions, (draftState: Question[]) => {
			const removed = draftState.splice(sourceIndex, 1)[0];

			draftState.splice(destinationIndex, 0, removed);
			draftState = draftState.map((q, i) => {
				return { ...q, ordinal: i + 1 };
			});
		});
		setOrderedQuestions(newState);

		handleReorderQuestion(
			selectedQuestionGroupId,
			sourceIndex,
			destinationIndex,
		);
	};

	const handleAddExistingQuestion = async (questionId: number) => {
		await handleAddQuestion(
			selectedQuestionGroupId,
			QuestionType.SHORT_ANSWER,
			questionId,
		);
		setAnchorEl(null);
	};

	return (
		<>
			<Grid item xs={12}>
				<PageHeader>Questions Management</PageHeader>
				<HelpScoutLink label='Learn Why Open Comments Get Better Results and How to Reach Community Members with SMS' articleId='66e9e95bf6180530271e1640' />
				{/* <ButtonLink
					link="https://fast.wistia.com/embed/channel/7nj7jkmpbx?wchannelid=7nj7jkmpbx&wmediaid=7wh28weycf"
					label="Learn Why Open Comments Get Better Results and How to Reach Community Members with SMS"
				/> */}
			</Grid>
			<Grid item xs={3}>
				<Stack direction="row" spacing={1}>
					<GroupChip
						active={selectedGroupStatus === GroupStatus.ALL}
						clickable
						onClick={() => setSelectedGroupStatus(GroupStatus.ALL)}
						label={TitleCaseHyphenatedWord.transform(GroupStatus.ALL)}
					/>
					<GroupChip
						active={selectedGroupStatus === GroupStatus.ACTIVE}
						clickable
						onClick={() => setSelectedGroupStatus(GroupStatus.ACTIVE)}
						label={TitleCaseHyphenatedWord.transform(GroupStatus.ACTIVE)}
					/>
					<GroupChip
						active={selectedGroupStatus === GroupStatus.INACTIVE}
						clickable
						onClick={() => setSelectedGroupStatus(GroupStatus.INACTIVE)}
						label={TitleCaseHyphenatedWord.transform(GroupStatus.INACTIVE)}
					/>
				</Stack>
			</Grid>
			<Grid item xs={7}></Grid>
			<Grid item xs={2}>
				<Button
					size="large"
					color="secondary"
					style={{ whiteSpace: 'nowrap' }}
					onClick={async () => {
						setOpen(true);
						setName('');
						await createEmptyQuestionGroup();
					}}
					startIcon={<PostAddIcon />}
					disabled={isPaused}
				>
					New question group
				</Button>
			</Grid>
			<Grid item xs={12}>
				<Divider />
			</Grid>
			{questionGroups.map((qg: QuestionGroup) => {
				const availableQuestionsPerGroup = availableQuestions.filter(
					(question) => {
						let groupIds = qg.questions?.map((q) => q.id);
						return qg.questions?.some((q) => {
							if (question.id === q.id && q.deleted) return true;
							else {
								return !groupIds?.includes(question.id);
							}
						});
					},
				);

				return (
					(qg.status === selectedGroupStatus.toString() ||
						selectedGroupStatus === GroupStatus.ALL) && (
						<QuestionGroupComponent
							onUpdateThemeName={(themeName: string) =>
								handleUpdateQuestionGroup(qg.id, themeName)
							}
							onUpdateGroupStatus={(status: string) =>
								handleUpdateQuestionGroupStatus(qg.id, status)
							}
							onDragEnd={handleReorderQuestion}
							onRemoveQuestion={handleRemoveQuestion}
							onToggleHideQuestion={handleToggleHideQuestion}
							onAddQuestion={handleAddQuestion}
							onQuestionFormClose={() => {
								refetch();
							}}
							onUpdateQuestion={(question) => handleUpdateQuestion(question)}
							onQuestionItemCountClick={handleQuestionItemCountClick}
							key={qg.id}
							questionGroup={qg}
							availableQuestions={
								qg.questions?.length
									? availableQuestionsPerGroup
									: availableQuestions
							}
							disabled={isPaused}
							commentCountPerQuestionId={commentCountPerQuestionId}
							setSelectedQuestion={setSelectedQuestion}
							onEditClick={() => {
								setName(qg.themeName);
								setSelectedQuestionGroupId(qg.id);
								setIsNew(false);
								setOpen(true);
							}}
						/>
					)
				);
			})}

			<ConfirmationDialog
				open={open}
				title={
					isDraftOrArchived
						? `${name} Questions`
						: `${isNew ? 'Create' : 'Edit'} Question Group`
				}
				onConfirm={async () => {
					// eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
					await handleCreateQuestionGroup(name);
					if (name.length) setOpen(false);
				}}
				onCancel={handleModalCancel}
				confirmLabel={`${isNew ? 'Create' : 'Save'}`}
				paddingSize="small"
				confirmDisabled={!name}
			>
				<div style={{ width: 600 }}>
					{!isDraftOrArchived && (
						<>
							<InputLabel required id="from-name-label" sx={{ mb: 0.5 }}>
								Question group theme
							</InputLabel>
							<RegularTextInput
								size="small"
								style={{ marginBottom: '10px' }}
								fullWidth
								value={name}
								required
								error={touched && !name.length}
								helperText={
									touched && !name.length && 'Question group theme required'
								}
								onChange={(e) => {
									setName(e.target.value);
								}}
								onBlur={() => setTouched(true)}
							/>
						</>
					)}

					<Box my={5}>
						<DragDropContext onDragEnd={handleDragEnd}>
							<StrictModeDroppable droppableId="question-summary-items">
								{(provided) => (
									<div ref={provided.innerRef} {...provided.droppableProps}>
										{orderedQuestions &&
											orderedQuestions.map((f, index) => {
												return (
													<Draggable
														key={index}
														draggableId={`${index}`}
														index={index}
														isDragDisabled={dragDisabled}
													>
														{(draggableProvided) => (
															<div
																ref={draggableProvided.innerRef}
																{...draggableProvided.draggableProps}
																{...draggableProvided.dragHandleProps}
															>
																<QuestionSummaryItem
																	onQuestionRemove={(questionId) =>
																		handleRemoveQuestion(
																			selectedQuestionGroupId,
																			questionId,
																		)
																	}
																	onToggleDrag={(enabled: boolean) =>
																		setDragDisabled(!enabled)
																	}
																	question={f}
																	setSelectedQuestion={setSelectedQuestion}
																/>
															</div>
														)}
													</Draggable>
												);
											})}
										{provided.placeholder}
									</div>
								)}
							</StrictModeDroppable>
						</DragDropContext>
					</Box>
					<Box
						display="flex"
						alignItems="center"
						bgcolor={`${theme.customPalette.primaryColors.sky[5]}`}
						sx={{
							borderTop: `1px solid ${theme.customPalette.primaryColors.sky[4]}`,
							borderBottom: `1px solid ${theme.customPalette.primaryColors.sky[4]}`,
							height: 80,
							marginLeft: -6,
							marginRight: -6,
							justifyContent: 'center',
						}}
					>
						<Box
							sx={{
								display: 'flex',
								alignItems: 'center',
								color: '#0076A0',
								gap: '10px',
							}}
						>
							<Button
								size="large"
								style={{ whiteSpace: 'nowrap' }}
								onClick={handleAddQuestionClick}
								startIcon={<AddCircleOutlineIcon />}
								endIcon={<KeyboardArrowDownIcon />}
							>
								Add Question
							</Button>
						</Box>
					</Box>
				</div>
			</ConfirmationDialog>
			<QuestionMenu
				availableQuestions={availableQuestions}
				onAddQuestion={addNewQuestion}
				anchorEl={anchorEl}
				setAnchorEl={setAnchorEl}
				handleAddExistingQuestion={handleAddExistingQuestion}
			/>
			{selectedQuestion && (
				<QuestionFormModal
					open={!!selectedQuestion}
					persistedQuestion={selectedQuestion}
					onClose={() => {
						setSelectedQuestion(null);
						refetch();
					}}
					onSave={(question: Question) => {
						handleUpdateQuestion(question);
					}}
				/>
			)}
		</>
	);
};
