import { FormGroup, Paper, Typography, useTheme } from '@mui/material';
import { usePausedContext } from '@coUrbanize/community-modules';
import { PublicBenefit } from '../../../types/types';
import { PublicBenefitCard } from './PublicBenefitCard';
import Loading from '../../../components/common/Loading';
import DragDropContainer from 'components/common/DragDropContainer';
import ButtonLink from '../../common/ButtonLink';
import { Language } from '@mui/icons-material';
import { ImageInfo } from '../../../types/imageInfo';

interface PublicBenefitProps {
	publicBenefits: Array<PublicBenefit>;
	onSort: (sourceIndex: number, destinationIndex: number) => {};
	onUpdate: (
		publicBenefitId: number,
		name: string,
		description: string | undefined,
	) => {};
	onDelete: (id: number) => void;
	onImageUpdate: (
		id: number,
		imageId: number,
		name: string,
		description: string | undefined,
		imageInfo: ImageInfo,
	) => void;
}

export const PublicBenefitList = (props: PublicBenefitProps) => {
	const { publicBenefits, onSort, onUpdate, onImageUpdate, onDelete } = props;
	const theme = useTheme();
	const { isPaused } = usePausedContext();

	if (!publicBenefits) return <Loading />;

	return (
		<Paper
			sx={{ padding: '20px', marginTop: '5px' }}
			style={{ backgroundColor: theme.customPalette.surface.light }}
		>
			<Typography
				variant="h6"
				mb={2}
				sx={{ color: theme.customPalette.primaryColors.grassGreen[1] }}
			>
				Active Benefits
			</Typography>
			<Typography my={2}>
				Show the great impact your project will have on the community. Add a new
				benefit on the left. Update description, add an image and re-order your
				benefits below.
				<ButtonLink
					label="Find royalty free images on Unsplash.com"
					link="https://unsplash.com"
					endIcon={<Language />}
				/>
			</Typography>
			{publicBenefits && (
				<FormGroup>
					<DragDropContainer
						droppableId="public-benefit-items"
						items={publicBenefits}
						keyExtractor={(pb) => pb.id}
						handleDragEnd={(result) =>
							onSort(result.source.index, result.destination!.index)
						}
						customDataPropName="module"
						containerStyle={{ mb: 5 }}
						isDragDisabled={isPaused}
						DraggableComponent={(publicBenefit, handle) => (
							<PublicBenefitCard
								publicBenefit={publicBenefit}
								handle={handle}
								handleUpdate={onUpdate}
								handleDelete={onDelete}
								handleImageUpdate={onImageUpdate}
								disabled={!!isPaused}
							/>
						)}
					/>
				</FormGroup>
			)}
		</Paper>
	);
};
