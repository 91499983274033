import { FC, useEffect, useState } from 'react';
import { useQuery } from 'urql';
import {
	Box,
	Typography,
	FormControl,
	Link as MUILink,
	Checkbox,
	FormControlLabel,
} from '@mui/material';
import LinkBehavior from 'components/common/links/Link';
import ModuleInstanceData from '../../types/moduleInstanceData';
import useNumberParams from '../../hooks/useNumberParams';
import { questionGroupsByProjectQuery } from 'api/query/questions.queries';
import { QuestionGroup } from 'types/types';
import Loading from 'components/common/Loading';
import { useModuleSaveData } from '../../hooks/useModuleSaveData';

const FeedbackModule: FC<ModuleInstanceData> = (props) => {
	const { projectId } = useNumberParams();

	const [groups, setGroups] = useState<QuestionGroup[]>([]);
	const [selectedGroups, setSelectedGroups] = useState<number[]>([]);

	const [{ data, fetching }] = useQuery({
		query: questionGroupsByProjectQuery,
		variables: { projectId },
		pause: groups.length > 0,
	});
	const { saveDataRef, config } = props;
	const { updateModuleSaveData } = useModuleSaveData(saveDataRef);

	useEffect(() => {
		updateModuleSaveData({ config, content: {} });
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		const groupsByProject: QuestionGroup[] = data?.questionGroupsByProject;
		if (groupsByProject) setGroups(groupsByProject);

		setSelectedGroups(
			groupsByProject
				?.filter((g: any) => g.status === 'active')
				?.map((g: { id: number }) => g.id),
		);
	}, [data?.questionGroupsByProject]);

	const handleChange = (id: number) => {
		let newSelectedGroups = [...selectedGroups];

		if (selectedGroups.includes(id))
			newSelectedGroups = newSelectedGroups.filter((g) => g !== id);
		else newSelectedGroups.push(id);

		setSelectedGroups(newSelectedGroups);
	};

	if (fetching) return <Loading />;

	return (
		<Box width="350px">
			<Box p={1} pt={0} minWidth="350px">
				<Typography mb={2}>
					Manage active topics on the Questions page found under Feedback.
				</Typography>
			</Box>
			{Boolean(groups?.length) ? (
				<FormControl
					fullWidth
					sx={{ display: 'grid', gridTemplateColumns: '1fr 1fr' }}
				>
					{groups.map((group) => (
						<FormControlLabel
							key={group.id}
							control={
								<Checkbox
									checked={selectedGroups.includes(group.id)}
									onChange={() => handleChange(group.id)}
									name={group.themeName}
									disabled={true}
								/>
							}
							label={group.themeName}
						/>
					))}
				</FormControl>
			) : (
				<Typography>
					Unfortunately, no question groups are created. Create your first{' '}
					<MUILink
						underline="none"
						href={`/projects/${projectId}/questions`}
						component={LinkBehavior}
					>
						question group
					</MUILink>
				</Typography>
			)}
		</Box>
	);
};

export default FeedbackModule;
