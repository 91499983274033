import { useContext, useCallback } from 'react';
import { noop } from 'lodash';

import { ActionConfirmationContext } from 'providers/ActionConfirmationProvider';
import {
	ActionConfirmationDialogContent,
	ConfirmationDialogActions,
} from 'types/customTypes';

export const useActionConfirmation = (action: ConfirmationDialogActions) => {
	const { showConfirmationDialog } = useContext(ActionConfirmationContext);

	const showSaveConfirmationDialog = useCallback(
		async (
			content: ActionConfirmationDialogContent,
			onConfirm?: () => void,
			onCancel?: () => void,
		) => {
			const isConfirmed = await showConfirmationDialog(content, action);

			if (isConfirmed) {
				onConfirm ? onConfirm() : noop();
			} else {
				onCancel ? onCancel() : noop();
			}
		},
		[action, showConfirmationDialog],
	);

	return showSaveConfirmationDialog;
};

export const useSaveActionConfirmation = () => useActionConfirmation('save');

export const useDeleteActionConfirmation = () =>
	useActionConfirmation('delete');

export const useAbandonActionConfirmation = () =>
	useActionConfirmation('abandon');

export const useNotifyActionConfirmation = () =>
	useActionConfirmation('notify');

export const useSelectActionConfirmation = () =>
	useActionConfirmation('select');

export const useUnPublishActionConfirmation = () =>
	useActionConfirmation('unPublish');
