import { Box, Checkbox } from '@mui/material';
import AutocompleteRenderOption from 'components/common/styled/AutocompleteRenderOption';
import { FC, useState } from 'react';
import { PublicBenefitDefault } from 'types/types';

interface PublicBenefitOptionProps {
	checked: boolean;
	renderProps: any;
	option: PublicBenefitDefault;
	onChange: (checked: boolean) => void;
}

const PublicBenefitOption: FC<PublicBenefitOptionProps> = (props) => {
	const { checked: defaultChecked, renderProps, option, onChange } = props;
	const [checked, setChecked] = useState(defaultChecked);

	return (
		<AutocompleteRenderOption {...renderProps}>
			<Box
				sx={{width: '100%'}}
				onClick={() => {
					onChange(!checked);
					setChecked(!checked);
				}}
			>
				<Checkbox checked={checked} />
				{option.benefitName}
			</Box>
		</AutocompleteRenderOption>
	);
};

export default PublicBenefitOption;
