import * as React from 'react';
import { IconButton, Typography } from '@mui/material';
import { Image as ImageIcon, Clear } from '@mui/icons-material';
import { Image } from '../../types/types';
import { useDeleteActionConfirmation } from 'hooks/useActionConfirmation';

interface ImagePlaceHolderCardProps {
	image: Image;
	handleDelete: () => void;
	deleteMessage?: string;
	deleteDisabled?: boolean;
}

const ImagePlaceHolderCard = ({
	image,
	handleDelete,
	deleteMessage,
	deleteDisabled,
}: ImagePlaceHolderCardProps) => {
	const showDeleteConfirmation = useDeleteActionConfirmation();

	const onDeleteClick = async () => {
		await showDeleteConfirmation(
			{
				title: 'Image Delete',
				message:
					deleteMessage ||
					`Are you sure you want to delete ${image.fileName} image?`,
			},
			handleDelete,
		);
	};

	return (
		<div style={{ display: 'flex' }}>
			<a
				href={image?.imageUrl}
				target="_blank"
				rel="noreferrer"
				style={{
					display: 'flex',
					alignItems: 'center',
					textDecoration: 'none',
					color: 'black',
					gap: 2,
					fontSize: 14,
				}}
			>
				<ImageIcon />
				<Typography
					noWrap
					style={{
						overflow: 'clip',
						textOverflow: 'ellipsis',
						maxWidth: '100px',
					}}
				>
					{image?.fileName.trim()}
				</Typography>
			</a>
			<IconButton disabled={deleteDisabled} onClick={onDeleteClick}>
				<Clear />
			</IconButton>
		</div>
	);
};

export default ImagePlaceHolderCard;
