import { useState } from 'react';
import { insertImage } from './helpers';
import { useMutation } from 'urql';
import { useSnackBar } from '../../../providers/SnackBarProvider';
import { ImageInfo } from '../../../types/imageInfo';
import useNumberParams from '../../../hooks/useNumberParams';
import { ImageProperty } from 'types/imageInfo';
import { getObjectKey } from '../../../utility/getObjectKey';
import {
	getUploadUrlMutation,
	removeFileMutation,
} from '../../../api/mutation/s3.mutations';
import { createImageMutation } from '../../../api/mutation/image.mutations';
import { fileUploadApi } from '../../../api/apiClient';
import { useAbandonActionConfirmation } from 'hooks/useActionConfirmation';
import { useRouteContext } from '../../../routes/Layout';

const useEditorImage = (
	editor: any,
	action?: (action: string, payload: object) => void,
) => {
	const showAbandonChanges = useAbandonActionConfirmation();
	const { notify } = useSnackBar();
	const { projectId } = useNumberParams();
	const { activeProjectDetails } = useRouteContext();
	const [imageInfo, setImageInfo] = useState<ImageInfo | null>(null);
	const [progress, setProgress] = useState(0);
	const [openImageModal, setOpenImageModal] = useState(false);
	const [, getUploadUrl] = useMutation(getUploadUrlMutation);
	const [, createImage] = useMutation(createImageMutation('id'));
	const [, removeFile] = useMutation(removeFileMutation);

	const handleUpload = async (files: FileList | null) => {
		if (!files) return;

		setProgress(1);
		const file = files[0];
		const result = await getUploadUrl({
			folder: `${activeProjectDetails.slug}/updates`,
			filename: file.name,
		});

		if (result.error) return;

		const uploadURL = result.data.getUploadUrl;

		const res = await fileUploadApi(uploadURL, file, setProgress);
		if (!res) return notify('Failed to upload image!', 'error');
		const imageUrl = res.split('?')[0];

		const newImageData = {
			imageUrl,
			fileName: file.name,
			altTag: '',
			projectId,
		};
		setImageInfo(newImageData);
	};

	const confirmUpload = async () => {
		if (!imageInfo) return setOpenImageModal(false);

		const result = await createImage({ newImageData: imageInfo });
		const { id } = result.data.createImage!;

		insertImage(editor, imageInfo.imageUrl);
		action && action('image_add', { ...imageInfo, id });

		setImageInfo(null);
		setOpenImageModal(false);
	};

	const cancelUpload = async () => {
		if (!imageInfo) return setOpenImageModal(false);

		// delete image from bucket
		const objectKey = getObjectKey(imageInfo.imageUrl);
		await removeFile({ objectKey });

		setImageInfo(null);
		setOpenImageModal(false);
	};

	const onCancel = async () => {
		await showAbandonChanges(
			{
				title: 'Abandon Changes?',
				message: 'This change will not be saved',
			},
			cancelUpload,
		);
	};

	const handleImageChange = (
		property: ImageProperty,
		event?: any,
		data?: any,
	) => {
		if (imageInfo) {
			setImageInfo((prevImageInfo) => ({
				...(prevImageInfo as ImageInfo),
				altTag: event?.target.value as string,
			}));
		} else {
			setImageInfo({ altTag: event?.target.value as string } as ImageInfo);
		}
	};

	const handleDropFile = (e: any) => {
		setOpenImageModal(true);
		handleUpload(e);
	};

	const imageAction = (actionType: string) => {
		actionType === 'insertImage' && setOpenImageModal(true);
	};

	return {
		handleDropFile,
		imageAction,
		openImageModal,
		confirmUpload,
		onCancel,
		handleUpload,
		progress,
		handleImageChange,
		imageInfo,
	};
};

export default useEditorImage;
