import React, { useEffect, useState } from 'react';
import { Typography, Chip, useTheme, Box } from '@mui/material';
import Loading from '../../common/Loading';

interface StatisticCardProps {
	title: string;
	value: number;
	delta: number;
	inDays: number;
}

export const StatisticCard: React.FC<StatisticCardProps> = ({
	title,
	value,
	delta,
	inDays,
}) => {
	const deltaSign = Math.sign(delta);
	const [loading, setLoading] = useState(true);
	useEffect(() => {
		if (value || value === 0) {
			setLoading(false);
		}
	}, [value]);

	const theme = useTheme();

	function getChipLabel() {
		if (deltaSign === 1) {
			return `+ ${delta}`;
		}
		if (deltaSign === -1) {
			return `- ${Math.abs(delta)}`;
		}

		return '+ 0';
	}

	interface IGetChipColor {
		color: string;
		bgColor: string;
	}

	function getChipColor(): IGetChipColor {
		if (deltaSign === 1) {
			return {
				color: theme.customPalette.systemColors.success.main,
				bgColor: theme.customPalette.systemColors.success.light,
			};
		}
		if (deltaSign === -1) {
			return {
				color: theme.customPalette.systemColors.error.main,
				bgColor: theme.customPalette.systemColors.error.light,
			};
		}

		return {
			color: theme.customPalette.systemColors.warning.main,
			bgColor: theme.customPalette.systemColors.warning.light,
		};
	}

	const chipColor = getChipColor();

	return (
		<>
			<Typography
				sx={{
					color: theme.customPalette.textColors.light,
					fontWeight: 500,
				}}
			>
				{title}
			</Typography>
			{loading ? (
				<Box sx={{ minHeight: '48px', my: '24px' }}>
					<Loading />
				</Box>
			) : (
				<Typography
					sx={{
						color: theme.customPalette.textColors.dark,
						fontWeight: 800,
						fontSize: '44px',
						lineHeight: '48px',
						my: '24px',
					}}
				>
					{value}
				</Typography>
			)}
			{loading ? (
				<Box sx={{ minHeight: '32px' }}></Box>
			) : (
				<Typography
					sx={{
						color: theme.customPalette.textColors.light,
						fontWeight: 500,
						fontSize: '16px',
						lineHeight: '24px',
					}}
				>
					<Chip
						label={getChipLabel()}
						variant="outlined"
						sx={{
							mr: 1,
							backgroundColor: chipColor.bgColor,
							color: chipColor.color,
							borderColor: chipColor.color,
						}}
					/>
					{`in last ${inDays} days`}
				</Typography>
			)}
		</>
	);
};
