import { FC } from 'react';
import { Dialog, DialogContent, DialogTitle, IconButton } from '@mui/material';
import { Close } from '@mui/icons-material';
import ImageDrop from 'components/common/ImageDrop';

interface DetailsUploadDialogProps {
	open: boolean;
	onClose: () => void;
	onUpload: (files: FileList | null) => void;
	progress: number;
	title?: string;
}

const DetailsUploadDialog: FC<DetailsUploadDialogProps> = (props) => {
	const { open, onClose, onUpload, progress, title } = props;

	return (
		<Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth={true}>
			<DialogTitle
				id="alert-dialog-title"
				sx={{
					display: 'flex',
					justifyContent: 'space-between',
					alignItems: 'center',
					color: 'gray',
					fontWeight: 'bold',
				}}
			>
				{title || 'Upload Project Profile Picture'}
				<IconButton onClick={onClose}>
					<Close />
				</IconButton>
			</DialogTitle>
			<DialogContent>
				<ImageDrop progress={progress} handleUpload={onUpload} />
			</DialogContent>
		</Dialog>
	);
};

export default DetailsUploadDialog;
