import { isArray, isEmpty, orderBy } from 'lodash';
import omitDeep from 'omit-deep-lodash';
import { ModuleInstance, TemplateInstance } from 'types/types';

export const getFormattedModuleInstances = (
	templateInstancesByPage: TemplateInstance[],
	moduleInstances: ModuleInstance[],
) => {
	let cleanData = omitDeep(templateInstancesByPage, [
		'id',
		'richText',
		'action',
		'imageUrl',
		'__typename',
	]);
	cleanData = orderBy(cleanData, ['ordinal'], ['asc']);
	if (isArray(cleanData)) {
		cleanData.map((page: TemplateInstance) => {
			page.moduleInstances = orderBy(
				page.moduleInstances,
				['config.sectionId'],
				['asc'],
			);
			page.moduleInstances.map((module: any) => {
				module.template = page.template.name;
				module.templateOrdinal = page.ordinal;
				module.content = isEmpty(module.content) ? null : module.content;
				return module;
			});
			moduleInstances = [...moduleInstances, ...page.moduleInstances];
			return page;
		});
	}
	return moduleInstances;
};
