import React from 'react';
import { RemoveCircleOutline } from '@mui/icons-material';
import TextButton from 'components/common/styled/TextButton';
import { useDeleteActionConfirmation } from '../../../hooks/useActionConfirmation';

interface RemovePageProps {
	handleRemoveClick: () => void;
}

export const RemovePage = ({ handleRemoveClick }: RemovePageProps) => {
	const showDeleteConfirmation = useDeleteActionConfirmation();
	const handleClick = async () => {
		await showDeleteConfirmation(
			{
				title: 'Delete Page',
				message: 'Are you sure you want to delete this page?',
			},
			async () => {
				await handleRemoveClick();
			},
		);
	};
	return (
		<>
			<TextButton onClick={handleClick} startIcon={<RemoveCircleOutline />}>
				Remove Page
			</TextButton>
		</>
	);
};
