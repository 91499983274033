import MainNav from './MainNav';
import AuthNav from './AuthNav';
import { AppBar, Box, Toolbar } from '@mui/material';

interface NavBarProps {
	activeProject: number;
}

export default function NavBar({ activeProject }: NavBarProps) {
	return (
		<Box sx={{ flexGrow: 1 }}>
			<AppBar
				position="static"
				color="inherit"
				sx={{
					borderBottom: '1px solid #e5e5e5',
					boxShadow: 'unset',
					backgroundColor: 'background.default',
				}}
			>
				<Toolbar
					color="inherit"
					sx={{ justifyContent: 'space-between', minHeight: '55px' }}
				>
					<MainNav />
					<AuthNav activeProject={activeProject} />
				</Toolbar>
			</AppBar>
		</Box>
	);
}
