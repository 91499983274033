// ==============================|| OVERRIDES - SWITCH ||============================== //

import { Theme } from '@mui/material';

export default function Switch(theme: Theme) {
	return {
		MuiSwitch: {
			styleOverrides: {
				root: {
					width: 28,
					height: 16,
					padding: 0,
					margin: '0px 10px',
					display: 'flex',
					'&:active': {
						'& .MuiSwitch-thumb': {
							width: 15,
						},
						'& .MuiSwitch-switchBase.Mui-checked': {
							transform: 'translateX(9px)',
						},
					},
					'& .MuiSwitch-switchBase': {
						padding: 2,
						'&.Mui-checked': {
							transform: 'translateX(12px)',
							color: '#fff',
							'& + .MuiSwitch-track': {
								opacity: 1,
								backgroundColor: theme.customPalette.primaryColors.sky[1],
							},
						},
					},
					'& .MuiSwitch-thumb': {
						boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
						width: 12,
						height: 12,
						borderRadius: 6,
						transition: theme.transitions.create(['width'], {
							duration: 200,
						}),
					},
					'& .MuiSwitch-track': {
						borderRadius: 16 / 2,
						opacity: 1,
						backgroundColor:
							theme.palette.mode === 'dark'
								? 'rgba(255,255,255,.35)'
								: 'rgba(0,0,0,.25)',
						boxSizing: 'border-box',
					},
				},
			},
		},
	};
}
