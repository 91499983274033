import { Menu, MenuItem, Button, Typography } from '@mui/material';
import {
	Edit as EditIcon,
	People as PeopleIcon,
	Business as BusinessIcon,
} from '@mui/icons-material';
import React from 'react';
import { Link } from 'react-router-dom';
import { useUserContext } from '../../../providers/UserProvider';
import { SmallActionButton } from '../../common/styled/SmallActionButton';
import LoginButton from './LoginButton';
import { Can } from '../../../providers/CaslAbilityProvider';
import {
	permissionAction,
	permissionSubject,
} from '../../../utility/defineAbilityFor';
import Divider from '@mui/material/Divider';
import { useAuth0 } from '@auth0/auth0-react';
import LogoutIcon from '@mui/icons-material/Logout';
import UserAvatar from 'components/common/Avatar';

interface AuthNavProps {
	activeProject: number;
}

const AuthNav = ({ activeProject }: AuthNavProps) => {
	const { user } = useUserContext();
	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
	const open = Boolean(anchorEl);
	const handleClick = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const { logout, isAuthenticated } = useAuth0();

	return (
		<div style={{ display: 'flex' }}>
			{user && (
				<>
					<Button
						variant="text"
						onClick={handleClick}
						sx={{ textTransform: 'capitalize', color: '#000000' }}
					>
						<UserAvatar />
						{user.fullName}
					</Button>
					<Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
						<div>
							<Can I={permissionAction.MANAGE} a={permissionSubject.ALL}>
								<MenuItem
									component={Link}
									selected={window.location.pathname === '/projects-admin'}
									sx={{
										width: '235px',
										height: '36px',
										fontSize: '14px',
									}}
									to={`/projects/${activeProject}/projects-admin`}
									onClick={handleClose}
								>
									<SmallActionButton
										style={{ backgroundColor: 'transparent' }}
										startIcon={<BusinessIcon color="primary" />}
										color="inherit"
									>
										<Typography>Projects</Typography>
									</SmallActionButton>
								</MenuItem>

								<MenuItem
									component={Link}
									selected={window.location.pathname === '/users'}
									sx={{
										width: '235px',
										height: '36px',
										fontSize: '14px',
									}}
									to={`/projects/${activeProject}/users`}
									onClick={handleClose}
								>
									<SmallActionButton
										style={{ backgroundColor: 'transparent' }}
										startIcon={<PeopleIcon color="primary" />}
										color="inherit"
									>
										<Typography>Users</Typography>
									</SmallActionButton>
								</MenuItem>
								<Divider />
							</Can>
							<MenuItem
								component={Link}
								selected={window.location.pathname === '/profile'}
								to={`/projects/${activeProject}/profile`}
								sx={{
									width: '235px',
									height: '36px',
									fontSize: '14px',
								}}
								onClick={handleClose}
							>
								<SmallActionButton
									style={{ backgroundColor: 'transparent' }}
									startIcon={<EditIcon color="primary" />}
									color="inherit"
								>
									<Typography>Edit Profile</Typography>
								</SmallActionButton>
							</MenuItem>
							<MenuItem
								sx={{
									width: '235px',
									height: '36px',
									fontSize: '14px',
								}}
								onClick={() =>
									logout({
										returnTo: window.location.origin,
									})
								}
							>
								<SmallActionButton
									style={{ backgroundColor: 'transparent' }}
									startIcon={<LogoutIcon color="primary" />}
									color="inherit"
								>
									<Typography>Logout</Typography>
								</SmallActionButton>
							</MenuItem>
						</div>
					</Menu>
				</>
			)}
			{!isAuthenticated && <LoginButton />}
		</div>
	);
};

export default AuthNav;
