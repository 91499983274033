import { useEffect, useState } from 'react';
import {
	Autocomplete,
	Box,
	Card,
	Typography,
	Button,
	useTheme,
} from '@mui/material';
import { usePausedContext } from '@coUrbanize/community-modules';
import { PublicBenefit, PublicBenefitDefault } from '../../../types/types';
import Loading from '../../common/Loading';
import RegularTextInput from 'components/common/styled/RegularTextInput';
import PublicBenefitOption from './PublicBenefitOption';

interface PublicBenefitDefaultProps {
	publicBenefits: PublicBenefit[];
	publicBenefitsDefault: PublicBenefitDefault[];
	handleCreateBlank: (benefitName: string) => void;
	onDelete: (id: number) => void;
	onCreate: (name: string, description: string, ordinal?: number) => any;
}

export const PublicBenefitDefaultList = (props: PublicBenefitDefaultProps) => {
	const {
		publicBenefitsDefault,
		handleCreateBlank,
		publicBenefits,
		onCreate,
		onDelete,
	} = props;

	const [options, setOptions] = useState<PublicBenefitDefault[]>([]);

	const theme = useTheme();
	const { isPaused } = usePausedContext();

	useEffect(() => {
		if (publicBenefitsDefault?.length > 0) setOptions(publicBenefitsDefault);
	}, [publicBenefitsDefault]);

	if (!publicBenefitsDefault) return <Loading />;

	return (
		<Card
			sx={{ padding: '20px', mb: 5 }}
			style={{ backgroundColor: theme.customPalette.surface.light }}
		>
			<Typography
				variant="h6"
				mb={2}
				alignItems="center"
				display="flex"
				sx={{ color: theme.customPalette.primaryColors.grassGreen[1] }}
			>
				Add Benefit
			</Typography>
			<Typography my={2}>
				Select applicable benefits listed or add a new one.
			</Typography>
			<Box flex={1} display="flex" alignItems="center" gap={4}>
				<Autocomplete
					id="select-public-benefits"
					multiple
					disabled={isPaused}
					options={options}
					getOptionLabel={(pb) => pb.benefitName}
					fullWidth
					sx={{ maxWidth: 400, '& .MuiOutlinedInput-root': { p: 0 } }}
					renderTags={() => null}
					disableClearable
					renderInput={(params) => (
						<RegularTextInput {...params} placeholder="Benefits" fullWidth />
					)}
					renderOption={(props, option) => (
						<PublicBenefitOption
							checked={Boolean(
								publicBenefits.find((pb) => pb.name === option.benefitName),
							)}
							key={option.benefitName}
							renderProps={props}
							option={option}
							onChange={(checked) => {
								const pbId = publicBenefits.find(
									(pb) => pb.name === option.benefitName,
								)?.id;
								if (checked) onCreate(option.benefitName, '', 0);
								else if (pbId) onDelete(pbId);
							}}
						/>
					)}
				/>
				<Button
					variant="contained"
					disabled={isPaused}
					onClick={() => handleCreateBlank('')}
				>
					Add New Benefit
				</Button>
			</Box>
		</Card>
	);
};
