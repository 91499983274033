import React, { FC } from 'react';
import { Card, InputLabel, Typography } from '@mui/material';
import { CloudUploadOutlined, CreateOutlined } from '@mui/icons-material';
import { Cell, Grid } from 'styled-css-grid';
import RegularTextInput from 'components/common/styled/RegularTextInput';
import RegularButton from 'components/common/styled/RegularButton';
import DetailsUploadDialog from './DetailsUploadDialog';
import { usePausedContext } from '@coUrbanize/community-modules';

type Developer = { name: string; logo: string; avatar: string; url: string };

interface DeveloperDetailsFormProps {
	open: boolean;
	setOpen: (open: boolean) => void;
	developer: Developer;
	progress: number;
	uploadFilesToAws: (files: FileList | null) => void;
	handleSave: (name: string, url: string) => void;
	setBtnName: (name: string) => void;
	setDeveloper: React.Dispatch<React.SetStateAction<Developer>>;
}

const DEV_NAME_CHARACTER_LIMIT = 150;
const DEV_URL_CHARACTER_LIMIT = 250;

const DeveloperDetailsForm: FC<DeveloperDetailsFormProps> = (props) => {
	const {
		open,
		setOpen,
		developer,
		progress,
		uploadFilesToAws,
		handleSave,
		setBtnName,
		setDeveloper,
	} = props;
	const { isPaused } = usePausedContext();

	return (
		<Cell style={{ maxWidth: 900 }}>
			<Typography variant="body2" pb={4} color="secondary">
				Project administrators can edit developer details for this project which
				are displayed in the site header and when internal staff respond to
				comments
			</Typography>
			<Card
				sx={(theme) => ({
					padding: theme.spacing(3),
					display: 'block',
				})}
			>
				<Grid columns={2}>
					<Cell width={2}>
						<InputLabel id="name">Developer Name</InputLabel>
						<RegularTextInput
							disabled={!!isPaused}
							name="developerName"
							variant="outlined"
							size="small"
							InputLabelProps={{ shrink: true }}
							fullWidth
							value={developer.name}
							onChange={(e) => {
								const name = e.target.value;
								if (name.length > DEV_NAME_CHARACTER_LIMIT) return;
								setDeveloper((prevDev) => ({ ...prevDev, name }));
							}}
							helperText={`${developer.name.length}/${DEV_NAME_CHARACTER_LIMIT}`}
						/>
					</Cell>
					<Cell width={2}>
						<InputLabel id="name">Developer Url</InputLabel>
						<RegularTextInput
							disabled={!!isPaused}
							name="developerUrl"
							variant="outlined"
							size="small"
							InputLabelProps={{ shrink: true }}
							fullWidth
							value={developer.url}
							onChange={(e) => {
								const url = e.target.value;
								if (url.length > DEV_URL_CHARACTER_LIMIT) return;
								setDeveloper((prevDev) => ({ ...prevDev, url }));
							}}
							helperText={`${developer.url.length}/${DEV_URL_CHARACTER_LIMIT}`}
						/>
					</Cell>
					<Cell width={2}>
						<RegularButton
							color="secondary"
							variant="contained"
							type="submit"
							onClick={() => handleSave(developer.name, developer.url)}
							disabled={isPaused}
							startIcon={<CreateOutlined />}
						>
							Save
						</RegularButton>
					</Cell>
					<Cell>
						{developer.logo && (
							<img
								src={developer.logo}
								alt="test"
								width="100%"
								style={{ objectFit: 'contain', height: 150 }}
							/>
						)}
						<RegularButton
							color="secondary"
							variant="contained"
							startIcon={<CloudUploadOutlined />}
							onClick={() => {
								setBtnName('logo');
								setOpen(true);
							}}
							disabled={!!isPaused || !developer.name}
						>
							Developer Logo
						</RegularButton>
					</Cell>
					<Cell>
						{developer.avatar && (
							<img
								src={developer.avatar}
								alt="test"
								width="100%"
								style={{ objectFit: 'contain', height: 150 }}
							/>
						)}
						<RegularButton
							color="secondary"
							variant="contained"
							startIcon={<CloudUploadOutlined />}
							onClick={() => {
								setBtnName('avatar');
								setOpen(true);
							}}
							disabled={!!isPaused || !developer.name}
						>
							Developer Avatar
						</RegularButton>
					</Cell>
				</Grid>
			</Card>

			<DetailsUploadDialog
				open={open}
				progress={progress}
				onClose={() => setOpen(false)}
				onUpload={uploadFilesToAws}
				title="Drop or Select Image to Upload"
			/>
		</Cell>
	);
};

export default DeveloperDetailsForm;
