import React, { useEffect, useRef } from 'react';
import WordCloud from 'wordcloud';

interface WordCloudProps {
    words: Array<[string, number]>;
    options?: WordCloud.Options;
}

const WordCloudComponent: React.FC<WordCloudProps> = ({ words, options }) => {
    const canvasRef = useRef<HTMLCanvasElement | null>(null);

    useEffect(() => {
        const colors = ['#00A396', '#00B3A3', '#68D2C9', '#0076A0', '#0081AE', '#68B4CF'];
        if (canvasRef.current) {
            const canvas = canvasRef.current;
            const parent = canvas.parentElement;

            if (parent) {
                canvas.width = parent.clientWidth - 25;
                canvas.height = parent.clientHeight / 2.5;
            }

            // Calculate min and max frequency
            const frequencies = words.map(([, freq]) => freq);
            const maxFrequency = Math.max(...frequencies);
            const minFrequency = Math.min(...frequencies);

            // Dynamically adjust weightFactor based on word frequency
            const weightFactor = (size: number) => {
                const minFontSize = 10; // Minimum font size for smallest word
                const maxFontSize = 100; // Maximum font size for largest word

                // Scale size to a range between minFontSize and maxFontSize
                return ((size - minFrequency) / (maxFrequency - minFrequency)) * (maxFontSize - minFontSize) + minFontSize;
            };

            const defaultOptions: WordCloud.Options = {
                list: words,
                gridSize: Math.round(40 * canvas.width / 1024), // Smaller gridSize for tighter packing
                weightFactor: weightFactor, // Large constant to force larger words
                fontFamily: 'Arial, sans-serif',
                color: function () {
                    // Randomly select a color from the colors array
                    return colors[Math.floor(Math.random() * colors.length)];
                },
                rotateRatio: 0.5,
                rotationSteps: 2,
                backgroundColor: '#ffffff',
                ...options,
            };

            WordCloud(canvas, defaultOptions);
        }
    }, [words, options]);

    return (
        <canvas
            ref={canvasRef}
            // style={{ width: '100%', height: '100%' }}
        />
    );
};

export default WordCloudComponent;
