import { useEffect, useState } from 'react';
import { useMutation } from 'urql';
import { useSnackBar } from 'providers/SnackBarProvider';
import { getUploadUrlMutation } from 'api/mutation/s3.mutations';
import { updateDeveloperDetailsMutation } from 'api/mutation/project.mutation';
import { fileUploadApi } from 'api/apiClient';
import { Project } from 'types/types';

const useProjectDev = (projectId: number, project: Project) => {
	const [developer, setDeveloper] = useState({
		name: '',
		logo: '',
		avatar: '',
		url: '',
	});
	const [btnName, setBtnName] = useState('');
	const [progress, setProgress] = useState(0);
	const [open, setOpen] = useState(false);

	useEffect(() => {
		const developer = project?.developer;
		setDeveloper({
			name: developer?.developerName || '',
			logo: developer?.developerLogo || '',
			avatar: developer?.developerAvatar || '',
			url: developer?.developerUrl || '',
		});
	}, [project?.developer]);

	const { notify } = useSnackBar();
	const [, getUploadUrl] = useMutation(getUploadUrlMutation);
	const [, updateDev] = useMutation(updateDeveloperDetailsMutation);

	const handleSave = async (name: string, url: string) => {
		if (!name.trim()) return notify('No name specified', 'error');

		const res = await updateDev({
			existingDeveloperData: {
				projectId,
				developerName: name,
				developerUrl: url,
			},
		});
		if (res.error) return notify('Developer details was not saved!', 'error');

		setDeveloper({ ...developer, name });
		notify('Developer details saved successfully!');
	};

	const uploadFilesToAws = async (files: FileList | null) => {
		if (!files) return;

		const file = files[0];
		const result = await getUploadUrl({
			folder: `${project.slug}/developer`,
			filename: file.name,
		});

		if (result.error) return;

		const uploadURL = result.data.getUploadUrl;

		const res = await fileUploadApi(uploadURL, file, setProgress);
		if (!res) return notify('Failed to upload Image!', 'error');

		const imageUrl = res.split('?')[0];

		if (btnName === 'logo') {
			setDeveloper({ ...developer, logo: imageUrl });
			await updateDev({
				existingDeveloperData: { projectId, developerLogo: imageUrl },
			});
		} else {
			setDeveloper({ ...developer, avatar: imageUrl });
			await updateDev({
				existingDeveloperData: { projectId, developerAvatar: imageUrl },
			});
		}

		setOpen(false);
		notify('Image uploaded!');
	};

	return {
		developer,
		progress,
		handleSave,
		uploadFilesToAws,
		setBtnName,
		open,
		setOpen,
		setDeveloper,
	};
};

export default useProjectDev;
