import { QuestionAnswer } from '../types/types';

export const questionAnswersWithoutDuplicates = (
    questionAnswers: QuestionAnswer[],
) => {
    const uniqueAnswers = new Map<number, QuestionAnswer[]>();
    questionAnswers.forEach((answer: QuestionAnswer) => {
        if (!uniqueAnswers.has(answer.createdBy)) {
            uniqueAnswers.set(answer.createdBy, [answer]);
        } else {
            const existingAnswers = uniqueAnswers.get(answer.createdBy)!;
            if (existingAnswers.some(a => a.created === answer.created || answer.createdByUser.firstName === 'Commenter via SMS')) {
                existingAnswers.push(answer);
            }
        }
    });
    return Array.from(uniqueAnswers.values()).flat();
};