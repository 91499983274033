import { gql } from 'urql';

export const aiInsightsByProjectQuery = gql`
	query ($projectId: Int!) {
		aiInsightsByProject(projectId: $projectId) {
			commonCommentWordCounts {
                id
                word
                count
            }
			commentSummaries {
				id
				title
				summary
			}
			themes {
				name
				percent
			}
			subThemes {
				name
				themeName
				percent
			}
    		aiInsightsLastExecutionDate
		}
	}
`;