import { gql } from 'urql';

export const timelinesByProjectIdQuery = gql`
  query ($projectId: Int!) {
    timelinesByProjectId(projectId: $projectId) {
      id
      name
    }
  }
`;

export const timelineByIdQuery = gql`
  query ($id: Int!) {
    timelineById(id: $id) {
      id
      name
    }
  }
`;
