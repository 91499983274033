export const sessionMap = () => {
	return {
		cookies: {
			auth:            {
				namedKey: 'CommunityApiAuth',
				details:  {
					expires: 1000,
				},
			},
			prefs:           {
				namedKey: 'CommunityApiPreferenceStates',
				details:  {
					expires: 7
				}
			},
			saveDataCheck:   {
				namedKey: 'CommunityApiUnsavedPageData',
				details:  {
					expires: 1000
				}
			},
			lastActivity:    {
				namedKey: 'CommunityApiLastActivityTS',
				details:  {
					expires: 1000
				}
			},
			lastRedirectUrl: {
				namedKey: 'CommunityApiRedirectURL',
				details:  {
					expires: 1000
				}
			}
		}
	};
	
};
