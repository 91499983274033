const getYoutubeId = (url: string) => {
	const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
	const match = url.match(regExp);
	return match && match[2].length === 11 ? match[2] : null;
};

const getVimeoId = (url: string) => {
	const regExp =
		/(?:http:|https:|)\/\/(?:player.|www.)?vimeo\.com\/(?:video\/|embed\/|watch\?\S*v=|v\/)?(\d*)/;
	const match = url.match(regExp);
	return match ? match[1] : null;
};

const getWistiaId = (url: string) => {
	const regExp = /(?:(?:medias|iframe)\/|wvideo=|wmediaid=)([\w-]+)/;
	const match = url.match(regExp);
	return match ? match[1] : null;
};

const getTwitchId = (url: string) => {
	const regExp = new RegExp('/clip/([^/]+)');
	const match = url.match(regExp);
	if (match && match.length > 0) return '?video=' + match[1];
	//parse channel name if video doesn't match
	const urlArray = url.split('/').reverse();
	return `?channel=${urlArray[0]}`;
};

export const parseUrl = (url: string, domain: string) => {
	if (!url) return null;
	const match = url.match(/(?:(?:https?:)?\/\/)?(?:[^.]+\.)?(\w+)\./i);
	if (!match) return null;
	const provider = match[1];
	if (provider === 'youtu' || provider === 'youtube') {
		return {
			provider: 'youtube',
			id: getYoutubeId(url),
			embed: `https://www.youtube.com/embed/${getYoutubeId(url)}?controls=1`,
		};
	} else if (provider === 'vimeo') {
		return {
			provider: 'vimeo',
			id: getVimeoId(url),
			embed: `https://player.vimeo.com/video/${getVimeoId(url)}`,
		};
	} else if (provider === 'wistia') {
		return {
			provider: 'wistia',
			id: getWistiaId(url),
			embed: `https://fast.wistia.net/embed/iframe/${getWistiaId(url)}`,
		};
	} else if (provider === 'twitch') {
		return {
			provider: 'twitch',
			id: getTwitchId(url),
			embed: `https://player.twitch.tv/${getTwitchId(url)}&parent=${domain}`,
		};
	}
};
