import { FC } from 'react';
import {
	Autocomplete,
	Box,
	ListItemIcon,
	ListItemText,
	Menu,
	MenuItem,
	TextField,
	useTheme,
} from '@mui/material';
import { Question } from '../../../types/types';
import ShortResponseIcon from '../../icons/ShortResponseIcon';
import LongResponseIcon from '../../icons/LongResponseIcon';
import MultipleSelectIcon from '../../icons/MultipleSelectIcon';
import SingleSelectIcon from '../../icons/SingleSelectIcon';
import RankedChoiceIcon from '../../icons/RankedChoiceIcon';
import SearchIcon from '@mui/icons-material/Search';

interface QuestionMenuProps {
	availableQuestions: Question[];
	onAddQuestion: (type: string) => void;
	anchorEl: HTMLElement | null;
	setAnchorEl: (el: HTMLElement | null) => void;
	handleAddExistingQuestion: (
		questionId: number,
		type: string,
		questionText: string,
	) => void;
}

export const enum QuestionType {
	// eslint-disable-next-line no-unused-vars
	SHORT_ANSWER = 'short-response',
	// eslint-disable-next-line no-unused-vars
	LONG_ANSWER = 'long-response',
	// eslint-disable-next-line no-unused-vars
	MULTIPLE_CHOICE = 'multiple-select',
	// eslint-disable-next-line no-unused-vars
	SINGLE_SELECT = 'single-select',
	// eslint-disable-next-line no-unused-vars
	RANKED_CHOICE = 'ranked-choice',
}

export const QuestionMenu: FC<QuestionMenuProps> = (props) => {
	const {
		availableQuestions,
		onAddQuestion,
		anchorEl,
		setAnchorEl,
		handleAddExistingQuestion,
		// setSelectedQuestion,
	} = props;

	const open = Boolean(anchorEl);
	const theme = useTheme();

	const handleClose = () => {
		setAnchorEl(null);
	};

	const handleAddQuestion = async (type: string) => {
		onAddQuestion(type);
		handleClose();
	};

	const getTypeIcon = (question: Question) => {
		switch (question.type) {
			case QuestionType.SHORT_ANSWER:
				return <ShortResponseIcon size={16} />;
			case QuestionType.RANKED_CHOICE:
				return <RankedChoiceIcon size={16} />;
			case QuestionType.LONG_ANSWER:
				return <LongResponseIcon size={16} />;
			case QuestionType.MULTIPLE_CHOICE:
				return <MultipleSelectIcon size={16} />;
			case QuestionType.SINGLE_SELECT:
				return <SingleSelectIcon size={16} />;
			default:
				break;
		}
	};

	return (
		<Menu
			anchorEl={anchorEl}
			open={open}
			onClose={handleClose}
			MenuListProps={{
				'aria-labelledby': 'basic-button',
			}}
		>
			<MenuItem>
				<Autocomplete
					sx={{ width: 300 }}
					options={availableQuestions}
					autoHighlight
					getOptionLabel={(option) => option.questionText ?? ''}
					onChange={(event: any, newValue: any) => {
						handleAddExistingQuestion(
							newValue.id,
							newValue.type,
							newValue.questionText,
						);
					}}
					renderOption={(props, option) => (
						<li {...props}>
							{getTypeIcon(option)}
							<span style={{ paddingLeft: '10px' }}>{option.questionText}</span>
						</li>
					)}
					renderInput={(params) => (
						<Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
							<SearchIcon sx={{ mr: 1 }} />
							<TextField
								variant="standard"
								{...params}
								label="Search Saved Questions"
								inputProps={{
									...params.inputProps,
								}}
							/>
						</Box>
					)}
				/>
			</MenuItem>
			<MenuItem onClick={() => handleAddQuestion(QuestionType.SHORT_ANSWER)}>
				<ListItemIcon>
					<ShortResponseIcon
						color={theme.customPalette.primaryColors.sky[1]}
						size={16}
					/>
				</ListItemIcon>
				<ListItemText>Short Response</ListItemText>
			</MenuItem>
			<MenuItem onClick={() => handleAddQuestion(QuestionType.LONG_ANSWER)}>
				<ListItemIcon>
					<LongResponseIcon
						color={theme.customPalette.primaryColors.sky[1]}
						size={16}
					/>
				</ListItemIcon>
				<ListItemText>Long Response</ListItemText>
			</MenuItem>
			<MenuItem onClick={() => handleAddQuestion(QuestionType.MULTIPLE_CHOICE)}>
				<ListItemIcon>
					<MultipleSelectIcon
						color={theme.customPalette.primaryColors.sky[1]}
						size={16}
					/>
				</ListItemIcon>
				<ListItemText>Checkboxes</ListItemText>
			</MenuItem>
			<MenuItem onClick={() => handleAddQuestion(QuestionType.SINGLE_SELECT)}>
				<ListItemIcon>
					<SingleSelectIcon
						color={theme.customPalette.primaryColors.sky[1]}
						size={16}
					/>
				</ListItemIcon>
				<ListItemText>Single Select</ListItemText>
			</MenuItem>
			<MenuItem onClick={() => handleAddQuestion(QuestionType.RANKED_CHOICE)}>
				<ListItemIcon>
					<RankedChoiceIcon
						color={theme.customPalette.primaryColors.sky[1]}
						size={16}
					/>
				</ListItemIcon>
				<ListItemText>Ranked Choice</ListItemText>
			</MenuItem>
		</Menu>
	);
};
