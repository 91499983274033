import { FC } from 'react';
import { TextFieldProps } from 'formik-mui';
import DropDown from './DropDown';

interface DropDownProps extends TextFieldProps {
	currentItem: string;
	items: { name: string; title: string }[];
	variant?: any;
	defaultValue?: string;
}

const DropDownField: FC<DropDownProps> = (props) => {
	const {
		form: { setFieldValue },
		field: { name, value },
		currentItem,
		items,
		variant,
		defaultValue,
	} = props;

	return (
		<DropDown
			onChange={(val) => setFieldValue(name, val)}
			currentItem={currentItem || value}
			items={items}
			variant={variant}
			defaultValue={defaultValue}
		/>
	);
};

export default DropDownField;
