import { useEffect, useState } from 'react';
import { Box, IconButton, Link, Tooltip, useTheme } from '@mui/material';
import { Cell, Grid } from 'styled-css-grid';
import { useQuery } from 'urql';
import { subject } from '@casl/ability';
import { Dashboard as DashboardIcon } from '@mui/icons-material';
import { usePausedContext } from '@coUrbanize/community-modules';
import { pagesQuery } from '../../api/query/page.queries';
import { TemplatePickerContainer } from '../../components/features/templatePicker/TemplatePickerContainer';
import { EditorCanvas } from '../../components/features/editorCanvas/EditorCanvas';
import { AddPageContainer } from '../../components/features/addPage/AddPageContainer';
import { RemovePageContainer } from '../../components/features/removePage/RemovePageContainer';
import useTemplateInstance from '../../components/features/templatePicker/hooks/useTemplateInstance';
import { PublishPage } from 'components/features/publishPage/PublishPage';
import { PreviewPageContainer } from 'components/features/previewPage/PreviewPageContainer';
import { Can } from '../../providers/CaslAbilityProvider';
import {
	permissionAction,
	permissionSubject,
} from '../../utility/defineAbilityFor';
import useNumberParams from '../../hooks/useNumberParams';
import PageHeader from '../../components/common/PageHeader';
import { PagePicker } from 'components/features/pagePicker/PagePicker';
import DesignSkeleton from 'components/common/DesignSkeleton';
import useProject from 'components/features/editProject/useProject';
import IframeTester from 'components/common/IframeTester';
import { Page, TemplateInstance } from 'types/types';
import ConfirmationDialog from 'components/common/ConfirmationDialog';
import { EditPages } from './EditPages';
import { updatePageMutation } from 'api/mutation/page.mutation';
import { useMutation } from 'urql';
import { pick } from 'lodash';
import { useSnackBar } from 'providers/SnackBarProvider';
import ListAltIcon from '@mui/icons-material/ListAlt';
import { UnPublishPage } from 'components/features/publishPage/UnPublishPage';
import { publishedTemplateInstancesByPageQuery } from 'api/query/template.queries';

export const PageBuilder = () => {
	const { projectId, pageId } = useNumberParams();
	const { project } = useProject(projectId);

	const {
		anchorEl,
		setAnchorEl,
		handleCreateTemplateInstance,
		templateInstances,
		handleDragEnd,
		handleDeleteTemplateInstance,
		setTemplateInstances,
		pickerOpen,
		setPickerOpen,
	} = useTemplateInstance(pageId);
	const theme = useTheme();
	const { isPaused } = usePausedContext();

	const [pages, setPages] = useState<Page[]>([]);
	const [sortedPages, setSortedPages] = useState<Page[]>([]);
	const [pagesEditModal, setPagesEditModal] = useState(false);

	const [publishedTemplateInstances, setPublishedTemplateInstances] = useState<
		TemplateInstance[]
	>([]);

	const { notify } = useSnackBar();

	const [{ data }, executeQuery] = useQuery({
		query: pagesQuery,
		variables: { projectId: projectId },
	});

	const [{ data: publishedTI }] = useQuery({
		query: publishedTemplateInstancesByPageQuery,
		variables: { pageId },
	});

	useEffect(() => {
		if (publishedTI?.publishedTemplateInstancesByPage)
			setPublishedTemplateInstances(
				publishedTI?.publishedTemplateInstancesByPage,
			);
	}, [publishedTI?.publishedTemplateInstancesByPage]);

	const refresh = () => {
		executeQuery({ requestPolicy: 'network-only' });
	};

	const haveOrdinalsChanged = (pages: Page[], sortedPages: Page[]): boolean => {
		const ordinalMap = pages.reduce((acc, page) => {
			acc[page.name] = page.ordinal!;
			return acc;
		}, {} as { [name: string]: number });

		return sortedPages.some((page) => {
			const ordinalValue = ordinalMap[page.name];
			return ordinalValue !== page.ordinal!;
		});
	};

	useEffect(() => {
		if (data?.pagesByProject) {
			setPages(data.pagesByProject);
			setSortedPages(data.pagesByProject);
		}
	}, [data?.pagesByProject]);

	const page = pages?.find((p: Page) => p.id === pageId);

	const onPickerClose = () => {
		setAnchorEl(null);
		setPickerOpen('');
	};
	const [, updatePage] = useMutation(updatePageMutation);

	if (!data || !pages || !page) return <DesignSkeleton />;

	return (
		<>
			<Box px={5} pt={2}>
				<PageHeader>Design</PageHeader>
			</Box>
			<Box px={5}>
				<Can
					I={permissionAction.UPDATE}
					a={subject(permissionSubject.PAGE, page as any)}
				>
					<Grid
						columns={'auto 1fr'}
						rows={'auto 1fr'}
						areas={['. page-picker', 'sidebar main']}
						rowGap={'0px'}
					>
						<Cell
							area="page-picker"
							style={{
								position: 'sticky',
								top: 0,
								background: '#fff',
								zIndex: 999,
								borderBottom: `2px solid ${theme.customPalette.borderColors.dark}`,
							}}
						>
							<Grid
								columns={
									'auto minmax(auto, 200px) 1fr auto auto auto auto auto'
								}
							>
								<Cell middle>
									<Can I={permissionAction.MANAGE} a={permissionSubject.ALL}>
										<Tooltip title={'Page Names and Ordering'}>
											<IconButton onClick={() => setPagesEditModal(true)}>
												<ListAltIcon />
											</IconButton>
										</Tooltip>
									</Can>
								</Cell>
								<Cell middle>
									<PagePicker pages={pages} projectId={projectId} />
								</Cell>
								<Cell middle />
								<Cell middle>
									<Can I={permissionAction.MANAGE} a={permissionSubject.ALL}>
										<AddPageContainer
											onAddPage={() => {
												refresh();
											}}
										/>
									</Can>
								</Cell>
								{page?.slug !== 'home' && (
									<Cell middle>
										<Can I={permissionAction.MANAGE} a={permissionSubject.ALL}>
											<RemovePageContainer />
										</Can>
									</Cell>
								)}

								{!page?.externalUrlOverride && (
									<>
										<Cell middle>
											<PreviewPageContainer pageId={pageId} project={project} />
										</Cell>
										<Cell middle>
											<PublishPage
												page={page}
												templateInstances={templateInstances}
												publishedTemplateInstances={publishedTemplateInstances}
												setPublishedTemplateInstances={
													setPublishedTemplateInstances
												}
											/>
										</Cell>
										{(publishedTemplateInstances.length > 0 && page.slug !== 'home') && (
											<Cell middle>
												<UnPublishPage
													page={page}
													onUnpublish={() => {
														setPublishedTemplateInstances([]);
													}}
												/>
											</Cell>
										)}
									</>
								)}
							</Grid>
						</Cell>

						{page.externalUrlOverride && (
							<>
								<Cell area="sidebar" style={{ minWidth: '40px' }}></Cell>
								<Cell area="main">
									<IframeTester
										title={page.name}
										src={page.externalUrlOverride}
										notAllowedMessage={
											<Box p={2}>
												<h1>Sorry</h1>
												<p>
													The external URL{' '}
													<Link
														rel="noreferrer"
														target="_blank"
														href={page.externalUrlOverride}
													>
														{page.externalUrlOverride}
													</Link>{' '}
													does not allow itself to be iframed.
												</p>
											</Box>
										}
									/>
								</Cell>
							</>
						)}
						{!page.externalUrlOverride && (
							<>
								<Cell area="sidebar">
									<IconButton
										disabled={!!isPaused}
										onClick={(e) => {
											setAnchorEl(e.currentTarget);
											setPickerOpen('Dashboard');
										}}
									>
										<DashboardIcon />
									</IconButton>
									<TemplatePickerContainer
										anchorEl={anchorEl}
										onPickerClose={onPickerClose}
										onTemplateSelected={(template) =>
											handleCreateTemplateInstance(
												template,
												templateInstances.length + 1,
											)
										}
										pickerOpen={pickerOpen === 'Dashboard'}
									/>
								</Cell>
								<Cell area="main">
									{templateInstances ? (
										<EditorCanvas
											templateInstancesByPage={templateInstances}
											setTemplateInstances={setTemplateInstances}
											pageId={pageId}
											projectId={projectId}
											handleDragEnd={handleDragEnd}
											handleDeleteTemplateInstance={
												handleDeleteTemplateInstance
											}
											anchorEl={anchorEl}
											setAnchorEl={setAnchorEl}
											handleCreateTemplateInstance={
												handleCreateTemplateInstance
											}
											handleClick={(e, newPickerId) => {
												setAnchorEl(e.currentTarget);
												setPickerOpen(newPickerId);
											}}
											pickerOpen={pickerOpen}
											handlePickerClick={setPickerOpen}
										/>
									) : (
										<DesignSkeleton />
									)}
								</Cell>
							</>
						)}
					</Grid>
				</Can>
			</Box>
			<ConfirmationDialog
				open={pagesEditModal}
				title="Page Names and Ordering"
				onConfirm={async () => {
					if (haveOrdinalsChanged(pages, sortedPages)) {
						for (const update of sortedPages) {
							await updatePage({
								existingPageData: pick(update, [
									'id',
									'ordinal',
									'name',
									'slug',
								]),
							});
						}
						notify('Tabs have been reordered', 'success');
						setPagesEditModal(false);
						setPages(
							sortedPages.map((p) => {
								return { ...p, __caslSubjectType__: 'Page' };
							}),
						);
					} else {
						notify('Tabs maintained position', 'success');
						setPagesEditModal(false);
					}
				}}
				onCancel={() => {
					setPagesEditModal(false);
				}}
				confirmLabel="Save"
				paddingSize="large"
			>
				<EditPages sortedPages={sortedPages} setSortedPages={setSortedPages} />
			</ConfirmationDialog>
		</>
	);
};
