import React, { FC } from 'react';
import { Box, SxProps } from '@mui/material';
import {
	DragDropContext,
	Draggable,
	DraggableProvidedDragHandleProps,
	DropResult,
} from 'react-beautiful-dnd';
import StrictModeDroppable from 'components/common/StrictModeDroppable';

interface DragDropContainerProps {
	droppableId: number | string;
	handleDragEnd: (result: DropResult) => void;
	items: any[];
	DraggableComponent: (
		item: any,
		handle?: DraggableProvidedDragHandleProps | null | undefined,
	) => React.ReactElement;
	keyExtractor: (item: any) => any;
	childProps?: any;
	customDataPropName?: any;
	containerStyle?: SxProps;
	direction?: 'horizontal' | 'vertical';
	noHandle?: boolean;
	isDragDisabled?: boolean;
}

const DragDropContainer: FC<DragDropContainerProps> = (props) => {
	const {
		droppableId,
		handleDragEnd,
		items,
		keyExtractor,
		DraggableComponent,
		containerStyle,
		direction,
		noHandle,
		isDragDisabled,
	} = props;

	return (
		<DragDropContext onDragEnd={handleDragEnd}>
			<StrictModeDroppable droppableId={`${droppableId}`} direction={direction}>
				{(provided) => (
					<Box ref={provided.innerRef} sx={{ ...containerStyle }}>
						{items.map((item: any, index: number) => (
							<Draggable
								key={keyExtractor(item)}
								draggableId={`${keyExtractor(item)}`}
								index={index}
								isDragDisabled={isDragDisabled}
							>
								{(draggableProvided) => (
									<div
										ref={draggableProvided.innerRef}
										{...draggableProvided.draggableProps}
										{...(noHandle ? draggableProvided.dragHandleProps : {})}
									>
										{DraggableComponent(
											item,
											draggableProvided.dragHandleProps,
										)}
									</div>
								)}
							</Draggable>
						))}
						{provided.placeholder}
					</Box>
				)}
			</StrictModeDroppable>
		</DragDropContext>
	);
};

export default DragDropContainer;
