import { FC } from 'react';
import * as Yup from 'yup';
import { Formik, Field } from 'formik';
import { Grid, InputLabel } from '@mui/material';
import { ForwardToInboxOutlined } from '@mui/icons-material';
import RegularButton from 'components/common/styled/RegularButton';
import RegularTextInput from 'components/common/styled/RegularTextInput';
import { usePausedContext } from '@coUrbanize/community-modules';
interface ProjectUserInviteFormProps {
	onInviteClick: (firstName: string, lastName: string, email: string) => any;
}

const validationSchema = Yup.object({
	firstName: Yup.string()
		.required('First Name required')
		.max(15, 'First Name must be 15 characters or less'),
	lastName: Yup.string()
		.required('Last Name required')
		.max(20, 'Last Name must be 20 characters or less'),
	email: Yup.string().required('Email required').email('Invalid email address'),
});

export const ProjectUserInviteForm: FC<ProjectUserInviteFormProps> = (
	props: ProjectUserInviteFormProps,
) => {
	const { onInviteClick } = props;
	const { isPaused } = usePausedContext();
	return (
		<Formik
			enableReinitialize
			validateOnChange={false}
			validationSchema={validationSchema}
			initialValues={{
				firstName: '',
				lastName: '',
				email: '',
			}}
			onSubmit={async (values, { setSubmitting, resetForm }) => {
				await onInviteClick(values.firstName, values.lastName, values.email);
				resetForm({ values: { firstName: '', lastName: '', email: '' } });
				setSubmitting(false);
			}}
		>
			{({
				dirty,
				isValid,
				isSubmitting,
				errors,
				touched,
				values,
				handleSubmit,
			}) => {
				return (
					<form onSubmit={handleSubmit} noValidate autoComplete="off">
						<Grid container spacing={1}>
							<Grid item>
								<InputLabel id="firstName">First Name</InputLabel>
								<Field
									as={RegularTextInput}
									name="firstName"
									size="small"
									disabled={isPaused}
									value={values.firstName}
									error={Boolean(touched.firstName && errors.firstName)}
									helperText={touched.firstName ? errors.firstName : ''}
								/>
							</Grid>
							<Grid item>
								<InputLabel id="lastName">Last Name</InputLabel>
								<Field
									as={RegularTextInput}
									name="lastName"
									size="small"
									disabled={isPaused}
									value={values.lastName}
									error={Boolean(touched.lastName && errors.lastName)}
									helperText={touched.lastName ? errors.lastName : ''}
								/>
							</Grid>
							<Grid item>
								<InputLabel id="email">Email</InputLabel>
								<Field
									as={RegularTextInput}
									name="email"
									variant="outlined"
									size="small"
									disabled={isPaused}
									value={values.email}
									error={Boolean(touched.email && errors.email)}
									helperText={touched.email ? errors.email : ''}
								/>
							</Grid>
							<Grid item>
								<RegularButton
									color="secondary"
									variant="contained"
									size="small"
									sx={{
										marginTop: '25px',
									}}
									startIcon={<ForwardToInboxOutlined />}
									type="submit"
									disabled={isSubmitting || !(isValid && dirty) || !!isPaused}
								>
									Invite
								</RegularButton>
							</Grid>
						</Grid>
					</form>
				);
			}}
		</Formik>
	);
};
