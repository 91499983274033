import { FC } from 'react';
import { Box, Typography } from '@mui/material';
import templateOneImage from './templatesImages/template-1.png';
import templateTwoImage from './templatesImages/template-2.png';
import templateThreeImage from './templatesImages/template-3.png';

const templateImages = [templateOneImage, templateTwoImage, templateThreeImage];

interface Template {
	id: number;
	name: string;
	url: string;
}

interface TemplatePreviewCardProps {
	template: Template;

	imageIndex: number;
	setOpenTemplate: React.Dispatch<React.SetStateAction<Template>>;
}

const TemplatePreviewCard: FC<TemplatePreviewCardProps> = ({
	template,
	imageIndex,
	setOpenTemplate,
}) => {
	return (
		<Box
			component="div"
			border="1px solid #E6E6E6"
			borderRadius={3}
			width={344}
			sx={{
				cursor: 'pointer',
			}}
			onClick={() => setOpenTemplate(template)}
		>
			<img
				loading="lazy"
				width={342}
				height={504}
				style={{ borderTopLeftRadius: 12, borderTopRightRadius: 12 }}
				src={templateImages[imageIndex]}
				alt={`template-${imageIndex}-preview`}
			/>

			<Box display="flex" justifyContent="center" p={2} mt={-1}>
				<Box>
					<Typography fontWeight="bold" lineHeight={2}>
						{template.name}
					</Typography>
				</Box>
			</Box>
		</Box>
	);
};

export default TemplatePreviewCard;
