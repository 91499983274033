import React, {
	FC,
	CSSProperties,
	MouseEvent,
	useState,
	useEffect,
} from 'react';
import {
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Typography,
	Box,
	IconButton,
	Button,
	Divider,
	Stack,
	styled,
	Breakpoint,
} from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';

const StyledDialog = styled(Dialog)(({ theme }) => ({
	'& .MuiPaper-root': {
		borderRadius: theme.spacing(3),
	},
}));

const StyledDialogTitle = styled(DialogTitle)(({ theme }) => ({
	paddingTop: theme.spacing(2.5),
	paddingLeft: theme.spacing(3),
	paddingRight: theme.spacing(3),
	paddingBottom: 0,
}));

const StyledDialogContent = styled(DialogContent)(({ theme }) => ({
	padding: 0,
}));

const StyledDialogActions = styled(DialogActions)(({ theme }) => ({
	height: '96px',
	alignItems: 'flex-end',
	paddingTop: 0,
	paddingLeft: theme.spacing(3),
	paddingRight: theme.spacing(3),
	paddingBottom: theme.spacing(2),
	'& .MuiDialogActions-root': {
		'> :not(:first-of-type)': { marginLeft: theme.spacing(3) },
	},
}));

const StyledIconButton = styled(IconButton)(({ theme }) => ({
	padding: 0,
}));

const StyledConfirmButton = styled(Button)(({ theme }) => ({
	fontWeight: 500,
	fontSize: '1rem',
	color: theme.customPalette.basicColors.light,
	textTransform: 'capitalize',
	padding: `7px ${theme.spacing(2)}`,
	minWidth: '150px',
	backgroundColor: theme.customPalette.primaryColors.sky[1],
	'&:hover': {
		backgroundColor: theme.customPalette.primaryColors.sky[2],
	},
}));

const StyledCancelButton = styled(Button)(({ theme }) => ({
	fontWeight: 500,
	fontSize: '1rem',
	textTransform: 'capitalize',
	padding: `7px ${theme.spacing(2)}`,
	minWidth: '150px',
	borderWidth: '2px',
	borderColor: theme.customPalette.primaryColors.sky[1],
	'&:hover': {
		borderWidth: '2px',
		borderColor: theme.customPalette.primaryColors.sky[2],
	},
}));

export interface FormModalRenderProps {
	setDisabled: React.Dispatch<React.SetStateAction<boolean>>;
	disabled: boolean;
}

interface FormModalProps {
	open: boolean;
	title: string;
	subTitle?: string;
	onCancel: (e: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>) => void;
	onConfirm: (e: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>) => void;
	cancelLabel?: string;
	confirmLabel?: string;
	style?: CSSProperties;
	fullScreen?: boolean;
	fullWidth?: boolean;
	maxWidth?: Breakpoint | false;
	disabled?: boolean;
	bottomDivider?: boolean;
	children?:
		| React.ReactNode
		| ((renderProps: FormModalRenderProps) => React.ReactNode);
}

const FormModal: FC<FormModalProps> = (props) => {
	const {
		title,
		subTitle,
		open,
		style,
		onConfirm,
		onCancel,
		cancelLabel,
		confirmLabel,
		fullScreen,
		fullWidth,
		maxWidth = 'lg',
		disabled: confirmDisabled,
		bottomDivider,
		children, // this is the content of the dialog
	} = props;

	const [disabled, setDisabled] = useState(false);

	useEffect(() => {
		if (!open && disabled) {
			setDisabled(false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [open]);

	return (
		<StyledDialog
			open={open}
			aria-labelledby="form-modal-title"
			aria-describedby="form-modal-content"
			maxWidth={maxWidth}
			fullWidth={fullWidth}
			sx={{
				...style,
				'& .MuiDialogContent-root': fullScreen
					? { padding: 0, overflowY: 'hidden' }
					: {},
			}}
			fullScreen={fullScreen}
		>
			<StyledDialogTitle id="form-modal-title">
				<Box
					display="flex"
					justifyContent="space-between"
					alignItems="center"
					mb={0.5}
				>
					<Typography variant="h6" fontWeight={800}>
						{title}
					</Typography>
					<StyledIconButton onClick={onCancel}>
						<CloseIcon />
					</StyledIconButton>
				</Box>
				{subTitle && (
					<Typography variant="subtitle1" lineHeight={1.25} fontWeight={400}>
						{subTitle}
					</Typography>
				)}
			</StyledDialogTitle>
			<StyledDialogContent id="form-modal-content">
				{typeof children === 'function'
					? (children({
							setDisabled,
							disabled,
					  }) as React.ReactNode)
					: children}
				{bottomDivider && <Divider />}
			</StyledDialogContent>
			<StyledDialogActions>
				<Stack
					direction="row"
					justifyContent="flex-end"
					alignItems="center"
					spacing={2.5}
					sx={{ width: '100%' }}
				>
					<StyledCancelButton variant="outlined" onClick={onCancel}>
						{cancelLabel}
					</StyledCancelButton>
					<StyledConfirmButton
						color="primary"
						variant="contained"
						disabled={disabled || confirmDisabled}
						onClick={onConfirm}
					>
						{confirmLabel}
					</StyledConfirmButton>
				</Stack>
			</StyledDialogActions>
		</StyledDialog>
	);
};

export default FormModal;
