import { useMutation } from 'urql';
import { useNavigate } from 'react-router-dom';
import useNumberParams from '../../../hooks/useNumberParams';
import { RemovePage } from './RemovePage';

export const RemovePageContainer = () => {
	const { pageId, projectId } = useNumberParams();
	const navigate = useNavigate();
	const removePageMutation = `
        mutation removePage($id: Int!){
            removePage(id: $id)
        }
    `;
	const [, removePage] = useMutation(removePageMutation);

	const handleRemovePage = () => {
		removePage({ id: pageId }).then((result) => {
			if (result.error) {
				console.error('Error deleting page', result.error);
			}
			navigate(`/projects/${projectId}/pages`);
		});
	};

	return <RemovePage handleRemoveClick={handleRemovePage} />;
};
