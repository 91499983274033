import ModuleInstanceData, {
	ModuleInstanceComponentSaveData,
} from '../types/moduleInstanceData';
import { useMutation } from 'urql';
import { updateModuleInstanceMutation } from '../api/mutation/module.mutation';
import { useEffect, useRef } from 'react';

export type UseModuleSaveDataHook = {
	updateModuleSaveData: (data: ModuleInstanceComponentSaveData) => void;
	persistSaveModuleData: (
		id: number,
		moduleInstanceData: ModuleInstanceComponentSaveData,
	) => Promise<any>;
};

const useModuleSaveData = (
	saveDataRef?: ModuleInstanceData['saveDataRef'],
): UseModuleSaveDataHook => {
	const componentSaveDataRef = useRef<ModuleInstanceComponentSaveData>({
		content: saveDataRef?.current.content,
		config: saveDataRef?.current.config,
	});

	const [, updateModuleInstance] = useMutation(updateModuleInstanceMutation);

	useEffect(() => {
		if (saveDataRef) saveDataRef.current = componentSaveDataRef.current;

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const updateModuleSaveData: UseModuleSaveDataHook['updateModuleSaveData'] = (
		data,
	) => {
		if (saveDataRef && saveDataRef.current) {
			saveDataRef.current = data;
		}
	};

	const persistSaveModuleData: UseModuleSaveDataHook['persistSaveModuleData'] =
		async (id, moduleInstanceData) => {
			const persistData = {
				id,
				content: moduleInstanceData.content ?? {},
				config: moduleInstanceData.config ?? {},
			};

			return updateModuleInstance({
				existingModuleInstanceData: persistData,
			});
		};

	return { persistSaveModuleData, updateModuleSaveData };
};

export { useModuleSaveData };
