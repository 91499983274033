import { FC } from 'react';
import { TextField, TextFieldProps } from '@mui/material';

type TextFieldNoOutlineProps = TextFieldProps;

const TextFieldNoOutline: FC<TextFieldNoOutlineProps> = (props) => {
	return (
		<TextField
			{...props}
			variant="outlined"
			InputProps={{
				sx: {
					'& fieldset': {
						borderColor: 'white',
					},
					'& input': {
						fontWeight: '400 !important',
						fontSize: '18px !important',
						lineHeight: '26px !important',
					},
					'&.Mui-focused fieldset': {
						borderColor: '#0076A0 !important',
						borderWidth: '2px',
					},
				},
			}}
		/>
	);
};

export default TextFieldNoOutline;
