import { Box, IconButton, useTheme } from '@mui/material';
import {
	DragHandle as DragHandleIcon,
} from '@mui/icons-material';
import { Grid, Cell } from 'styled-css-grid';
import RegularTextInput from 'components/common/styled/RegularTextInput';
import { useDeleteActionConfirmation } from '../../../hooks/useActionConfirmation';
import * as React from 'react';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';

interface FieldOptionProps {
	text: string;
	ordinal: number;
	onDeleteClick: () => void;
	onToggleDrag: (enabled: boolean) => void;
	onLabelUpdate: (label: string) => void;
}

export const FieldOption = (props: FieldOptionProps) => {
	const { text, onDeleteClick, onToggleDrag, onLabelUpdate } = props;
	const theme = useTheme();

	const handleOptionLabelChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		onLabelUpdate(e.target.value);
	};

	const showDeleteConfirmation = useDeleteActionConfirmation();
	const handleClick = async () => {
		await showDeleteConfirmation(
			{
				title: 'Delete Field Option',
				message: 'Are you sure you want to delete this field option?',
			},
			() => onDeleteClick(),
		);
	};

	return (
		<Box
			border="1px solid #E0E0E0"
			borderRadius={2}
			overflow="hidden"
			minHeight={70}
			marginBottom={2}
		>
			<div
				onMouseEnter={() => onToggleDrag(true)}
				onMouseLeave={() => onToggleDrag(false)}
				style={{
					backgroundColor: theme.customPalette.surface.light,
					height: '16px',
					textAlign: 'center',
				}}
			>
				<DragHandleIcon
					sx={{ fontSize: 24, color: theme.customPalette.primaryColors.sky[1] }}
				/>
			</div>

			<Grid
				columnGap="0px"
				columns={'auto 36px'}
				areas={['main-area delete']}
				style={{ padding: '10px' }}
			>
				<Cell area="main-area" middle>
					<RegularTextInput
						size="small"
						fullWidth
						value={text}
						onChange={handleOptionLabelChange}
						sx={{ backgroundColor: '#fff', borderRadius: '8px' }}
					/>
				</Cell>
				<Cell area="delete" middle>
					<IconButton onClick={handleClick}>
						<DeleteOutlinedIcon
							fontSize="small"
							sx={{ '&:hover': { color: 'red' }, fontSize: 24 }}
						/>
					</IconButton>
				</Cell>
			</Grid>
		</Box>
	);
};
