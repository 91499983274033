import React from 'react';
import { Box, CircularProgress, Typography, useTheme } from '@mui/material';
import { Item } from './Item';

interface SentimentCardProps {
  sentimentLevelPercent: number;
  sentimentType: SentimentLevelEnum;
}

// eslint-disable-next-line no-unused-vars
export enum SentimentLevelEnum {
  // eslint-disable-next-line no-unused-vars
  POSITIVE = 'POSITIVE',
  // eslint-disable-next-line no-unused-vars
  NEUTRAL = 'NEUTRAL',
  // eslint-disable-next-line no-unused-vars
  NEGATIVE = 'NEGATIVE',
}

// eslint-disable-next-line no-unused-vars
enum SentimentLevelLabelEnum {
  // eslint-disable-next-line no-unused-vars
  POSITIVE = 'Positive',
  // eslint-disable-next-line no-unused-vars
  NEUTRAL = 'Neutral',
  // eslint-disable-next-line no-unused-vars
  NEGATIVE = 'Negative',
}

export const SentimentCard: React.FC<SentimentCardProps> = (props) => {
  const { sentimentLevelPercent, sentimentType } = props;
  const theme = useTheme();

  const sentimentLevelColor = {
    // eslint-disable-next-line no-unused-vars
    POSITIVE: theme.customPalette.primaryColors.coreGreen[2],
    // eslint-disable-next-line no-unused-vars
    NEUTRAL: theme.customPalette.systemColors.disabled,
    // eslint-disable-next-line no-unused-vars
    NEGATIVE: theme.customPalette.systemColors.error.main,
  }

  return (
    <Item>
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
        <Box sx={{ position: 'relative', display: 'inline-flex' }}>
          <CircularProgress
            variant="determinate"
            value={100}
            size={150}
            thickness={6}
            sx={{
              color: '#e0e0e0', // Grey color for the unfilled portion
              position: 'absolute', // Position it behind the main progress
            }}
          />
          <CircularProgress
            sx={{
              color: sentimentLevelColor[sentimentType]
            }}
            size={150}
            thickness={6}
            variant="determinate"
            value={sentimentLevelPercent}
          />
          <Box
            sx={{
              top: 0,
              left: 0,
              bottom: 0,
              right: 0,
              position: 'absolute',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Typography
              variant="caption"
              component="div"
              sx={{ color: 'text.secondary' }}
            >
              {`${sentimentLevelPercent}%`}
            </Typography>
            <Typography
              variant="caption"
              component="div"
              sx={{ color: 'text.secondary' }}
            >
              {`${SentimentLevelLabelEnum[sentimentType]}`}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Item>
  );
};
