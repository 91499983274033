import { infoPlansLoader } from './../contentLoaders/infoPlansLoader';
import { MODULE_TYPE } from 'types/moduleTypes';
import { feedbackLoader } from '../contentLoaders/feedbackLoader';
import { locationPhaseLoader } from '../contentLoaders/locationPhaseLoader';
import { previousUpdatesLoader } from '../contentLoaders/previousUpdatesLoader';
import { publicBenefitLoader } from '../contentLoaders/publicBenefitLoader';
import { timelineLoader } from '../contentLoaders/timelineLoader';
import { Project } from 'types/types';

const contentLoader = (
	content: any,
	type: MODULE_TYPE,
	getToken: any,
	projectData: Project,
) => {
	const parsedContent = content?.info ? content?.info : content;
	switch (type) {
		case MODULE_TYPE.FEEDBACK:
			return feedbackLoader(parsedContent, projectData, getToken);
		case MODULE_TYPE.INFORMATION_PLANS:
			return infoPlansLoader(parsedContent, projectData, getToken);
		case MODULE_TYPE.LOCATION_PHASE:
			return locationPhaseLoader(content, projectData);
		case MODULE_TYPE.UPDATES:
			return previousUpdatesLoader(content, projectData, getToken);
		case MODULE_TYPE.PUBLIC_BENEFIT:
			return publicBenefitLoader(content, projectData, getToken);
		case MODULE_TYPE.TIMELINE:
			return timelineLoader(parsedContent, projectData, getToken);

		default:
			return content;
	}
};

export default contentLoader;
