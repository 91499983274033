import { FC } from 'react';
import { Typography } from '@mui/material';

const SettingsTabInfo: FC = () => {
	return (
		<Typography variant="body2" pb={4} color="secondary">
			Internal staff can edit project settings to enable the site and individual
			features
		</Typography>
	);
};

export default SettingsTabInfo;
