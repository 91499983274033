import { gql } from 'urql';

export const createModuleInstanceMutation = ( s:string ) => gql`
    mutation createModuleInstance($newModuleInstanceData: NewModuleInstanceInput!){
        createModuleInstance(newModuleInstanceData: $newModuleInstanceData) {
            ${ s }
        }
    }
`;

export const removeModuleInstanceMutation = gql`
mutation removeModuleInstance($id: Int!){
    removeModuleInstance(id: $id)
}
`;

export const updateModuleInstanceMutation = gql`
mutation updateModuleInstance($existingModuleInstanceData: ExistingModuleInstanceInput!) {
  updateModuleInstance(existingModuleInstanceData: $existingModuleInstanceData){
      id
  }
}
`;