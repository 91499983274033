import React, { useState, useCallback, useEffect } from 'react';
import ModuleInstanceData from '../../../types/moduleInstanceData';
import { Cell, Grid } from 'styled-css-grid';
import {
	AutocompleteChangeReason,
	Box,
	IconButton,
	InputLabel,
	Link,
	Typography,
} from '@mui/material';
import { MapContainer } from '../../common/MapContainer';
import { useModuleSaveData } from '../../../hooks/useModuleSaveData';
import produce from 'immer';
import { PlaceOption, PlacesAutoComplete } from './PlacesAutoComplete';
import { getGeocode, getLatLng } from 'use-places-autocomplete';
import { MapMarker } from '../../common/MarkerList';
import { Close as CloseIcon } from '@mui/icons-material';
import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
} from '../../common/styled/Accordian';
import RegularTextInput from 'components/common/styled/RegularTextInput';
import TextButton from 'components/common/styled/TextButton';
import { isEqual } from 'lodash';

const mapOptions = {
	zoomControl: true,
	mapTypeControl: false,
	scaleControl: false,
	streetViewControl: false,
	rotateControl: false,
	fullscreenControl: false,
	draggable: true,
};

export const MapModule: React.FC<ModuleInstanceData> = (props) => {
	const { saveDataRef, config, content } = props,
		emptyMarker: MapMarker = {
			location: { address: '', lat: 0, lng: 0 },
			name: '',
		},
		{ updateModuleSaveData } = useModuleSaveData(saveDataRef),
		[mapContentState, setMapContentState] = useState(content || {}),
		[mapAddressState, setMapAddressState] = useState(content?.mapAddress ?? ''),
		[mapCenter, setMapCenter] = useState(content?.mapCenter),
		[mapZoom, setMapZoom] = useState(content?.mapZoomLevel ?? 14),
		[mapMarkers, setMapMarkers] = useState<MapMarker[]>(
			content?.mapMarkers ?? [],
		),
		[currentMarker, setCurrentMarker] = useState<MapMarker>(emptyMarker),
		[clickedMarker, setClickedMarker] = useState<MapMarker>(emptyMarker),
		[markerInputAddress, setMarkerInputAddress] = useState<string>('');

	const updateSaveData = useCallback(
		(props: any) => {
			const updatedContent = produce(mapContentState, (newContent: any) => {
				for (const key of Object.keys(props)) {
					newContent[key] = props[key];
				}
			});
			setMapContentState(updatedContent);
		},
		[mapContentState],
	);

	const setMapCenterContent = (lat: number, lng: number, address: string) => {
		if (
			lat !== 0 &&
			lng !== 0 &&
			mapCenter?.lat !== lat &&
			mapCenter?.lng !== lng
		) {
			updateSaveData({ mapCenter: { lat, lng }, mapAddress: address });
		}
	};

	const handleZoomLevelChange = useCallback(
		(zoom: number) => {
			if (zoom !== mapZoom) {
				setMapZoom(zoom);
				updateSaveData({ mapZoomLevel: zoom });
			}
		},
		[mapZoom, updateSaveData],
	);

	const handleCenterLevelChange = (center: google.maps.LatLngLiteral) => {
		updateSaveData({ mapCenter: center });
	};

	const handleSelectedLocationChange = (
		event: React.SyntheticEvent,
		value: PlaceOption | null,
		reason: AutocompleteChangeReason,
	) => {
		if (reason === 'selectOption') {
			if (value) {
				const address = value.value as string;
				getGeocode({ address })
					.then((results) => getLatLng(results[0]))
					.then(({ lat, lng }) => {
						setMapCenter({ lat, lng });
						setMapAddressState(address);
						console.log('📍 Coordinates: ', { lat, lng, address: address });
						setMapCenterContent(lat, lng, address);
					})
					.catch((error) => {
						console.log('😱 Error: ', error);
					});
			}
		}
	};

	const handleMapCenterLocationInputChange = (
		event: React.SyntheticEvent,
		value: string,
		reason: string,
	) => {
		if (!value && reason !== 'reset') {
			setMapAddressState('');
		}
	};

	const handleCurrentMarkerLocationChange = (
		event: React.SyntheticEvent,
		value: PlaceOption | null,
		reason: AutocompleteChangeReason,
	) => {
		if (reason === 'selectOption') {
			if (value) {
				const address = value.value as string;
				getGeocode({ address })
					.then((results) => getLatLng(results[0]))
					.then(({ lat, lng }) => {
						const updatedMarker: MapMarker = {
							name: currentMarker.name,
							location: { address, lat, lng },
						};
						setCurrentMarker(updatedMarker);
					})
					.catch((error) => {
						console.log('😱 Error: ', error);
					});
			}
		}
	};

	const handleCurrentMarkerLocationInputChange = (
		event: React.SyntheticEvent,
		value: string,
		reason: string,
	) => {
		if (!value && reason !== 'reset') {
			const updatedMarker: MapMarker = {
				...emptyMarker,
				name: currentMarker.name,
			};
			setCurrentMarker(updatedMarker);
		}
	};

	const handleCurrentMarkerNameChange = (
		event: React.ChangeEvent<HTMLInputElement>,
	) => {
		const marker: MapMarker = { ...currentMarker, name: event.target.value };
		setCurrentMarker(marker);
	};

	const updateMarkerState = (newMarkers: MapMarker[]) => {
		updateSaveData({ mapMarkers: newMarkers });
	};

	const handleAddMarker = () => {
		if (!isEqual(currentMarker, emptyMarker)) {
			const newMarkers = mapMarkers.concat(currentMarker);
			updateMarkerState(newMarkers);
			setMapMarkers(newMarkers);
			setCurrentMarker(emptyMarker);
			setMarkerInputAddress('');
		}
	};

	const handleDeleteMarker = (marker: MapMarker) => {
		const newMarkers = mapMarkers.filter((obj) => obj !== marker);
		updateMarkerState(newMarkers);
		setMapMarkers(newMarkers);
	};

	useEffect(() => {
		updateModuleSaveData({
			content: mapContentState,
			config,
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [mapContentState]);

	return (
		<Box display="flex" flexDirection="column">
			<Grid columns={2} rows="auto 80px auto auto">
				<Cell width={2}>
					<MapContainer
						mapZoomLevel={mapZoom}
						onZoomLevelChange={handleZoomLevelChange}
						onCenterLevelChange={handleCenterLevelChange}
						options={mapOptions}
						locations={mapMarkers}
						width={content?.mapWidth || 400}
						height={content?.mapHeight || 400}
						center={mapCenter}
						selectedMarker={clickedMarker}
					/>
				</Cell>
				<Cell width={2} middle>
					<PlacesAutoComplete
						onLocationInputChange={handleMapCenterLocationInputChange}
						dropdownLabel="Map Center Address"
						inputAddress={mapAddressState}
						setInputAddress={setMapAddressState}
						fullWidth
						onSelectedLocation={handleSelectedLocationChange}
					/>
				</Cell>
				<Cell width={2} middle>
					Map Center : {mapContentState?.mapCenter?.lat},{' '}
					{mapContentState?.mapCenter?.lng}
				</Cell>
				<Cell width={2} style={{ paddingTop: '10px' }}>
					<Accordion>
						<AccordionSummary>
							<Typography>Map Markers</Typography>
						</AccordionSummary>
						<AccordionDetails>
							<Grid columns={'1fr 1fr auto'}>
								<Cell middle>
									<InputLabel
										style={{ marginTop: '10px' }}
										id="map-marker-name-label"
									>
										Name of Marker
									</InputLabel>
									<RegularTextInput
										value={currentMarker.name}
										id="markerName"
										onChange={handleCurrentMarkerNameChange}
										fullWidth
										size="small"
									/>
								</Cell>
								<Cell middle>
									<PlacesAutoComplete
										onLocationInputChange={
											handleCurrentMarkerLocationInputChange
										}
										inputAddress={markerInputAddress}
										setInputAddress={setMarkerInputAddress}
										fullWidth
										onSelectedLocation={handleCurrentMarkerLocationChange}
									/>
								</Cell>
								<Cell middle>
									<TextButton
										sx={{ marginTop: '25px' }}
										size="small"
										onClick={handleAddMarker}
									>
										Add
									</TextButton>
								</Cell>
							</Grid>
							<Grid columns={'1fr'} rowGap="15px">
								{mapMarkers.map((marker) => (
									<Grid columns={'1fr auto'} style={{ padding: '0px 20px' }}>
										<Cell middle>
											<Link
												underline="none"
												href="#"
												onClick={() => {
													setClickedMarker(marker);
												}}
											>
												<Typography>
													<strong>{marker.name}</strong>
												</Typography>
												<Typography>
													<small>{marker.location.address}</small>
												</Typography>
											</Link>
										</Cell>
										<Cell middle>
											<IconButton onClick={() => handleDeleteMarker(marker)}>
												<CloseIcon
													fontSize="small"
													sx={{ '&:hover': { color: 'red' } }}
												/>
											</IconButton>
										</Cell>
									</Grid>
								))}
							</Grid>
						</AccordionDetails>
					</Accordion>
				</Cell>
			</Grid>
		</Box>
	);
};
