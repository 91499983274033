import { gql } from 'urql';

export const getUploadUrlMutation = gql`
	mutation getUploadUrl($folder: String!, $filename: String!) {
		getUploadUrl(folder: $folder, filename: $filename)
	}
`;

export const removeFileMutation = gql`
	mutation removeAwsFile($objectKey: String!) {
		removeAwsFile(objectKey: $objectKey)
	}
`;

export const restoreFileMutation = gql`
	mutation restoreAwsFile($objectKey: String!) {
		restoreAwsFile(objectKey: $objectKey)
	}
`;
