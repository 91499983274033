import { FC, useEffect, useMemo, useState } from 'react';
import { Box } from '@mui/material';
import { ArrowBack as ArrowBackIcon } from '@mui/icons-material';
import { useNavigate, useSearchParams } from 'react-router-dom';

import PageHeader from 'components/common/PageHeader';
import ParentComment from 'components/features/commentsPage/ParentComment';
import EditCommentModal from 'components/features/commentsPage/EditCommentModal';
import Loading from 'components/common/Loading';
import RegularButton from 'components/common/styled/RegularButton';
import { Filter } from 'utility/commentData';
import { useCaslAbilityContext, Can } from 'providers/CaslAbilityProvider';
import { permissionAction, permissionSubject } from 'utility/defineAbilityFor';
import { Comment } from 'types/types';
import User from 'types/user';
import { SeparatedComment } from '../../types/separatedComment';

type SpecificCommentViewProps = {
	commentId: number;
	projectId: number;
	separatedComments: SeparatedComment[];
	user: User | null;
	filter: Filter;
	addComment: (comment: Comment) => void;
	saveComment: (
		comment: Comment,
		cancel?: boolean | undefined,
	) => Promise<number>;
	editComment: (
		comment: Comment,
		cancel?: boolean | undefined,
	) => Promise<number | undefined>;
	moderateComment: (
		comment: Comment,
		cancel?: boolean | undefined,
	) => Promise<number>;
	likeComment: (id: number) => Promise<void>;
	updateCommentFetching: boolean;
	disabled: boolean;
};

const SpecificCommentsView: FC<SpecificCommentViewProps> = ({
	commentId,
	projectId,
	updateCommentFetching,
	separatedComments,
	user,
	filter,
	addComment,
	saveComment,
	editComment,
	moderateComment,
	likeComment,
	disabled,
}) => {
	const [commentToEdit, setCommentToEdit] = useState<Comment | undefined>();
	const caslAbilityContext = useCaslAbilityContext();
	const navigate = useNavigate();
	const [params, setParams] = useSearchParams();

	const isEditMode = params.get('edit');

	const canViewSpecificComment = useMemo(
		() =>
			caslAbilityContext.can(permissionAction.VIEW, permissionSubject.COMMENTS),
		[caslAbilityContext],
	);

	// Only Internal and Developer can edit specific comment
	const canEditSpecificComment = useMemo(
		() =>
			caslAbilityContext.can(permissionAction.MANAGE, permissionSubject.ALL),
		[caslAbilityContext],
	);

	const comments = useMemo(
		() =>
			separatedComments.find(
				(separatedComment) =>
					separatedComment.parentComment.id === commentId ||
					separatedComment.childComments.find(
						(childComment) => childComment.id === commentId,
					),
			),
		[commentId, separatedComments],
	);

	const handleBackToComments = () =>
		navigate(`/projects/${projectId}/comments`);

	const handleSaveComment = (comment: Comment) => {
		editComment(comment);
	};

	useEffect(() => {
		if (!canViewSpecificComment) {
			navigate(`/projects/${projectId}/comments`, {
				replace: true,
			});
		}
	}, [canViewSpecificComment, navigate, projectId]);

	useEffect(() => {
		if (isEditMode && comments) {
			const comment =
				comments.parentComment.id === commentId
					? comments.parentComment
					: comments.childComments.find(
							(childComment) => childComment.id === commentId,
					  );
			if (comment) {
				setCommentToEdit(comment);
				setParams({ commentId: `${commentId}` });
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [comments, isEditMode]);

	return (
		<>
			<Box display="flex" alignItems="center" justifyContent="space-between">
				<PageHeader>Comment Management</PageHeader>
			</Box>
			<Box>
				<RegularButton
					onClick={handleBackToComments}
					startIcon={<ArrowBackIcon />}
					sx={{ fontSize: '12px' }}
				>
					Back to All Comments
				</RegularButton>
			</Box>
			<Box mt={4}>
				<Can I={permissionAction.VIEW} a={permissionSubject.COMMENTS}>
					{updateCommentFetching && (
						<Box
							display="flex"
							justifyContent="center"
							alignItems="center"
							width="100%"
							height="100%"
						>
							<Loading />
						</Box>
					)}
					{comments && !updateCommentFetching && (
						<ParentComment
							key={comments?.parentComment.id}
							user={user}
							comment={comments?.parentComment}
							childComments={comments?.childComments}
							filter={filter}
							addComment={addComment}
							saveComment={saveComment}
							editComment={editComment}
							moderateComment={moderateComment}
							likeComment={likeComment}
							canEditSpecificComment={canEditSpecificComment}
							specificCommentId={commentId}
							onSpecificCommentEdit={setCommentToEdit}
							showAllComments
							disabled={disabled}
						/>
					)}
				</Can>
			</Box>
			<EditCommentModal
				open={Boolean(commentToEdit)}
				comment={commentToEdit as Comment}
				handleSaveComment={handleSaveComment}
				setCommentToEdit={setCommentToEdit}
			/>
		</>
	);
};

export default SpecificCommentsView;
