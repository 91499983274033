import { FC, useContext, useEffect, useState, ChangeEvent } from 'react';
import {
	Box,
	Button,
	Checkbox,
	FormControlLabel,
	InputLabel,
	MenuItem,
	Switch,
	Alert,
} from '@mui/material';
import { FormModuleContext } from './FormModule';
import { FORM_BUILDER_FIELD_TYPE } from '../../../types/formBuilderFieldTypes';
import { FieldOptionsConfigure } from './FieldOptionsConfigure';
import { FieldChoice } from '../../../types/formBuilderConfig';
import { FieldLabelImageConfigure } from './FieldLabelImageConfigure';
import FormImage, { FormImageType } from './FormImage';
import { ImageContent } from '../ImageModule';
import Autocomplete from '../../common/Autocomplete/';
import { TextEditor } from 'components/common/TextEditor';
import TextButton from 'components/common/styled/TextButton';
import RegularTextInput from 'components/common/styled/RegularTextInput';
import SelectInput from 'components/common/styled/SelectInput';
import ImageOutlinedIcon from '@mui/icons-material/ImageOutlined';
import { useAbandonActionConfirmation } from '../../../hooks/useActionConfirmation';
import { v4 as uuidv4 } from 'uuid';
import { RichTextContent } from 'types/richTextContent';
import { useMutation } from 'urql';
import { removeFileMutation } from '../../../api/mutation/s3.mutations';
import { toPlainText } from '../../common/TextEditor/helpers';
import { getObjectKey } from '../../../utility/getObjectKey';

interface FieldConfigureProps {
	label: string;
	hiddenValue?: string;
	description: string;
	validationType?: string;
	isRequired: boolean;
	type: string;
	ordinal: number;
	choices: Array<FieldChoice>;
	hasWriteInOption?: boolean;
	labelHasImage: boolean;
	labelImage?: ImageContent;
	image?: FormImageType;
	groupName?: string;
	suggestions: string[];
	descriptionField: any;
	config: any;
	onFieldChange: (val: any) => void;
	setShowConfirm?: (n: boolean) => void;
	setShowCancel?: (n: boolean) => void;
	setFormTitle?: (n: string) => void;
	isNewField?: boolean;
	onFieldRemove: (ordinal: number) => void;
}

interface Errors {
	fieldLabel?: string;
	fieldChoices?: string;
}

export const FieldConfigure: FC<FieldConfigureProps> = (props) => {
	const {
		label,
		hiddenValue,
		labelHasImage,
		labelImage,
		image,
		choices,
		type,
		description,
		descriptionField,
		validationType,
		groupName,
		suggestions,
		config,
		isRequired,
		hasWriteInOption,
		ordinal,
		onFieldChange,
		isNewField,
		onFieldRemove,
	} = props;

	const { setCurrentField } = useContext(FormModuleContext);
	const showAbandonConfirmation = useAbandonActionConfirmation();

	const [fieldDescription, setFieldDescription] = useState(description);
	const [isChecked, setIsChecked] = useState(isRequired ?? false);
	const [isWriteInChecked, setIsWriteInChecked] = useState(
		hasWriteInOption ?? false,
	);
	const [errors, setErrors] = useState<Errors>({});
	const [fieldLabel, setFieldLabel] = useState(label);
	const [fieldChoices, setFieldChoices] = useState(choices);
	const [fieldGroupName, setFieldGroupName] = useState(groupName);
	const [fieldValue, setFieldValue] = useState(hiddenValue);
	const [fieldLabelHasImage, setLabelHasImage] = useState(labelHasImage);
	const [fieldLabelImage, setFieldLabelImage] = useState(labelImage);
	const [fieldImage, setFieldImage] = useState(image);
	const [showImageUpload, setShowImageUpload] = useState(false);
	const [validation, setValidation] = useState(validationType);
	const [deleteOnSave, setDeleteOnSave] = useState<string[]>([]);
	const [, removeImage] = useMutation(removeFileMutation);

	const handleImageDelete = async () => {
		setDeleteOnSave([...deleteOnSave, fieldImage?.imageUrl as string]);
		setFieldImage({ ...fieldImage, imageUrl: '' } as any);
	};

	const [editorState, setEditorState] = useState(() => {
		if (!descriptionField) {
			return [] as RichTextContent;
		} else {
			return descriptionField?.content?.editorState;
		}
	});
	const [saveDisabled, setSaveDisabled] = useState(true);
	const [showAdvanceSettings, setShowAdvanceSettings] = useState(false);

	useEffect(() => {
		props.setShowCancel?.(false);
		props.setShowConfirm?.(false);
	});

	useEffect(() => {
		const fieldType = FORM_BUILDER_FIELD_TYPE[type];
		let disabled = false;

		if (!fieldLabel) {
			disabled = true;
		}

		if (fieldType === FORM_BUILDER_FIELD_TYPE.IMAGE) {
			disabled = !fieldImage?.imageUrl || !fieldImage?.altTag;
		}

		if (
			fieldType === FORM_BUILDER_FIELD_TYPE.CHECKLIST &&
			(fieldChoices?.length < 1 || !fieldChoices)
		) {
			disabled = true;
		}

		if (
			(fieldType === FORM_BUILDER_FIELD_TYPE.CHOICE_SELECTION ||
				fieldType === FORM_BUILDER_FIELD_TYPE.RANKED_CHOICE) &&
			(fieldChoices?.length < 2 || !fieldChoices)
		) {
			disabled = true;
		}

		setSaveDisabled(disabled);
	}, [
		fieldLabel,
		isChecked,
		isWriteInChecked,
		validation,
		fieldDescription,
		fieldValue,
		fieldGroupName,
		fieldLabelImage,
		fieldChoices,
		fieldImage,
		type,
	]);

	useEffect(() => {
		let disabled = false;

		let text = toPlainText(editorState);

		if (!text) {
			disabled = true;
		}

		setSaveDisabled(disabled);
	}, [editorState]);

	const validateFieldLabel = (e: any) => {
		if (!e.target.value) {
			setErrors({ ...errors, fieldLabel: 'Field Label is required' });
		} else {
			setErrors({ ...errors, fieldLabel: '' });
		}
	};

	const validateFieldChoices = (newChoices: FieldChoice[]) => {
		let fieldType = FORM_BUILDER_FIELD_TYPE[type];
		const reqLength = fieldType === FORM_BUILDER_FIELD_TYPE.CHECKLIST ? 1 : 2;
		if (!newChoices || newChoices.length < reqLength) {
			setErrors({
				...errors,
				fieldChoices: `Minimum ${reqLength} options required `,
			});
		} else {
			setErrors({ ...errors, fieldChoices: '' });
		}
	};

	const handleChangeFieldName = (e: ChangeEvent<HTMLInputElement>) => {
		setFieldLabel(e.target.value);
		validateFieldLabel(e);
	};

	const handleChangeFieldValue = (e: ChangeEvent<HTMLInputElement>) => {
		setFieldValue(e.target.value);
	};

	const handleLabelImageChange = (image: ImageContent) => {
		if (image.imageUrl) {
			setLabelHasImage(true);
		}

		setFieldLabelImage(image);
	};

	const handleSave = async () => {
		const parseContent = () => {
			let contentState = editorState;
			return {
				content: {
					plainText: toPlainText(contentState),
					editorState: contentState,
				},
				config: config,
			};
		};

		if (deleteOnSave.length > 0) {
			for (const imgUrl of deleteOnSave) {
				try {
					const objectKey = getObjectKey(imgUrl);
					await removeImage({ objectKey });
				} catch (error) {
					console.error('Remove image failed: ', error);
				}
			}
		}

		onFieldChange({
			label: fieldLabel,
			isRequired: isChecked,
			hasWriteInOption: isWriteInChecked,
			validationType: validation,
			descriptionField: parseContent(),
			description: fieldDescription,
			hiddenValue: fieldValue,
			groupName: fieldGroupName,
			labelImage: fieldLabelImage,
			labelHasImage: fieldLabelHasImage,
			image: fieldImage,
			choices: fieldChoices,
		});
		// Handle Choices add/remove/update/sort

		goBack();
	};

	const goBack = () => {
		props.setFormTitle?.('');
		props.setShowCancel?.(true);
		props.setShowConfirm?.(true);
		setCurrentField(null);
	};

	const handleGoBack = async () => {
		// TODO : activate this for DESCRIPTION also , it need to compare objects or prevent first run of useEffect
		if (
			!saveDisabled &&
			FORM_BUILDER_FIELD_TYPE[type] !== FORM_BUILDER_FIELD_TYPE.DESCRIPTION
		) {
			await showAbandonConfirmation(
				{
					title: 'Abandon Changes?',
					message: `All unsaved modifications made to this field will be
						lost!`,
				},
				() => {
					if (isNewField) {
						onFieldRemove(ordinal);
						goBack();
					} else {
						goBack();
					}
				},
			);
		} else {
			if (isNewField) {
				onFieldRemove(ordinal);
				goBack();
			} else {
				goBack();
			}
		}
	};

	const handleChangeFieldDescription = (e: ChangeEvent<HTMLInputElement>) => {
		setFieldDescription(e.target.value);
	};

	const handleIsRequiredChange = (event: ChangeEvent<HTMLInputElement>) => {
		setIsChecked(event.target.checked);
		// onSetRequired(event.target.checked);
	};

	const handleWriteInOptionChanged = (event: ChangeEvent<HTMLInputElement>) => {
		setIsWriteInChecked(event.target.checked);
	};

	const handleAddFieldOption = () => {
		const newChoices = fieldChoices ? [...fieldChoices] : [];
		newChoices.push({
			id: uuidv4(),
			label: '',
			ordinal: newChoices?.length + 1,
		});
		setFieldChoices(newChoices);
		validateFieldChoices(newChoices);
	};

	const handleRemoveFieldOption = (optionOrdinal: number) => {
		const newChoices = [...fieldChoices];
		const optionIndex = fieldChoices!.findIndex(
			(c) => c.ordinal === optionOrdinal,
		);
		newChoices!.splice(optionIndex, 1);
		for (const c of newChoices!) {
			if (c.ordinal > optionOrdinal) {
				c.ordinal = c.ordinal - 1;
			}
		}
		setFieldChoices(newChoices);
		validateFieldChoices(newChoices);
	};

	const handleUpdateFieldOption = (
		optionOrdinal: number,
		labelText: string,
	) => {
		let newChoices = [...fieldChoices];
		const optionIndex = fieldChoices!.findIndex(
			(c) => c.ordinal === optionOrdinal,
		);
		const newField = { ...newChoices![optionIndex], label: labelText };
		newChoices.splice(optionIndex, 1, newField!);
		setFieldChoices(newChoices);
	};

	const handleSortFieldOption = (
		optionStartIndex: number,
		optionEndIndex: number,
	) => {
		const newChoices = [...fieldChoices];
		let movedOption = newChoices!.splice(optionStartIndex, 1)[0];
		newChoices!.splice(optionEndIndex, 0, movedOption);
		setFieldChoices(newChoices);
	};

	const renderStandardOptions = () => {
		return (
			<>
				<TextButton
					onClick={() => setShowImageUpload(true)}
					sx={{
						textTransform: 'none',
						marginLeft: 'auto',
						top: 22,
						zIndex: 9,
						fontSize: 15,
					}}
					startIcon={<ImageOutlinedIcon />}
				>
					{fieldLabelHasImageText}
				</TextButton>
				<InputLabel required id="fieldLabel">
					Field Label
				</InputLabel>
				<RegularTextInput
					size="small"
					value={fieldLabel}
					onChange={handleChangeFieldName}
					onBlur={validateFieldLabel}
					error={!!errors.fieldLabel}
					helperText={errors.fieldLabel}
					sx={{ ...whiteInput, mb: 1 }}
				/>
				<InputLabel id="descriptionText">Description Text</InputLabel>
				<RegularTextInput
					size="small"
					value={fieldDescription}
					onChange={handleChangeFieldDescription}
					sx={whiteInput}
				/>
				<FormControlLabel
					control={
						<Switch checked={isChecked} onChange={handleIsRequiredChange} />
					}
					labelPlacement="start"
					label="Required"
					sx={{
						margin: '10px auto 10px 5px',
						color: '#0076A0',
						'& .MuiFormControlLabel-label': {
							fontWeight: 500,
						},
					}}
				/>
				<TextButton
					sx={{
						display: 'block',
						mb: 1,
						textTransform: 'none',
						alignSelf: 'start',
						fontSize: '1em',
					}}
					onClick={() => setShowAdvanceSettings(!showAdvanceSettings)}
				>
					{`${!showAdvanceSettings ? 'Show' : 'Hide'}`} advance settings
				</TextButton>
				{showAdvanceSettings && (
					<Autocomplete
						groupName={fieldGroupName}
						onChangeText={setFieldGroupName}
						suggestions={suggestions}
						sx={whiteInput}
					/>
				)}
			</>
		);
	};

	const renderOptions = () => {
		return (
			<>
				<InputLabel required id="fieldLabel">
					Field Label
				</InputLabel>
				<RegularTextInput
					size="small"
					value={fieldLabel}
					onChange={handleChangeFieldName}
					onBlur={validateFieldLabel}
					error={!!errors.fieldLabel}
					helperText={errors.fieldLabel}
					sx={whiteInput}
				/>

				<FieldOptionsConfigure
					type={type}
					choices={fieldChoices}
					onAddChoice={handleAddFieldOption}
					onRemoveChoice={handleRemoveFieldOption}
					onUpdateChoice={handleUpdateFieldOption}
					onSortChoice={handleSortFieldOption}
				/>
				{!!errors.fieldChoices && (
					<Alert severity="warning">{errors.fieldChoices}</Alert>
				)}
			</>
		);
	};
	const renderValidationType = () => {
		return (
			<>
				<InputLabel id="select-label">Validation</InputLabel>
				<SelectInput
					size="small"
					labelId="select-label"
					fullWidth
					value={validation}
					onChange={(e: any) => setValidation(e.target.value)}
					sx={whiteInput}
				>
					<MenuItem value={'text'}>None</MenuItem>
					<MenuItem value={'email'}>Email</MenuItem>
					<MenuItem value={'date'}>Date</MenuItem>
					<MenuItem value={'number'}>Number</MenuItem>
				</SelectInput>
			</>
		);
	};

	const renderImageOption = () => {
		return (
			<FormImage
				fileName={fieldImage!.fileName}
				imageUrl={fieldImage!.imageUrl}
				boundingBoxPosition={fieldImage!.boundingBoxPosition}
				imageScale={fieldImage!.imageScale}
				altTag={fieldImage?.altTag}
				onImageChange={(val) => setFieldImage(val)}
				setConfirmDisabled={setSaveDisabled}
				handleImageDelete={handleImageDelete}
			/>
		);
	};

	const renderHiddenOptions = () => {
		return (
			<>
				<InputLabel required id="fieldLabel">
					Field Label
				</InputLabel>
				<RegularTextInput
					sx={{ ...whiteInput, marginBottom: '20px' }}
					size="small"
					value={fieldLabel}
					onChange={handleChangeFieldName}
					onBlur={validateFieldLabel}
					error={!!errors.fieldLabel}
					helperText={errors.fieldLabel}
				/>
				<InputLabel id="valueLabel">Value</InputLabel>
				<RegularTextInput
					size="small"
					value={fieldValue}
					onChange={handleChangeFieldValue}
					sx={whiteInput}
				/>
			</>
		);
	};

	const renderDescriptionOption = () => {
		return (
			<>
				<TextEditor editorState={editorState} setEditorState={setEditorState} />
			</>
		);
	};

	const renderFieldType = (fieldType: string) => {
		switch (FORM_BUILDER_FIELD_TYPE[fieldType]) {
			case FORM_BUILDER_FIELD_TYPE.SHORT_TEXT:
				return (
					<>
						{renderStandardOptions()}
						{showAdvanceSettings && renderValidationType()}
					</>
				);
			case FORM_BUILDER_FIELD_TYPE.LONG_SUMMARY:
				return renderStandardOptions();
			case FORM_BUILDER_FIELD_TYPE.YES_NO:
				return renderStandardOptions();
			case FORM_BUILDER_FIELD_TYPE.CHECKLIST:
				return renderOptions();
			case FORM_BUILDER_FIELD_TYPE.CHOICE_SELECTION:
				return (
					<>
						{renderOptions()}
						<FormControlLabel
							control={
								<Checkbox
									checked={isWriteInChecked}
									onChange={handleWriteInOptionChanged}
								/>
							}
							label="Write-in option available"
							sx={{ marginTop: 2, color: '#0076A0' }}
						/>
					</>
				);
			case FORM_BUILDER_FIELD_TYPE.RANKED_CHOICE:
				return renderOptions();
			case FORM_BUILDER_FIELD_TYPE.IMAGE:
				return renderImageOption();
			case FORM_BUILDER_FIELD_TYPE.DESCRIPTION:
				return renderDescriptionOption();
			case FORM_BUILDER_FIELD_TYPE.HIDDEN:
				return renderHiddenOptions();
			default:
				return <></>;
		}
	};

	const fieldLabelHasImageText = fieldLabelHasImage
		? 'Modify Label Image'
		: 'Add Label Image';

	return (
		<Box
			display="flex"
			flexDirection="column"
			minWidth={728}
			minHeight={360}
			p={3}
			bgcolor="#F8F8F8"
		>
			{showImageUpload ? (
				<FieldLabelImageConfigure
					fieldLabelImage={fieldLabelImage}
					onBackClick={() => setShowImageUpload(false)}
					onImageChange={handleLabelImageChange}
				/>
			) : (
				<>
					{renderFieldType(type)}
					<Button
						sx={(theme) => ({
							textTransform: 'capitalize',
							padding: '8px 16px',
							minWidth: '162px',
							borderWidth: '2px',
							borderColor: theme.customPalette.primaryColors.sky[1],
							'&:hover': {
								borderWidth: '2px',
								borderColor: theme.customPalette.primaryColors.sky[2],
								color: theme.customPalette.primaryColors.sky[2],
							},
							color: theme.customPalette.primaryColors.sky[1],
							position: 'absolute',
							bottom: 30,
							right: '51%',
						})}
						variant="outlined"
						onClick={handleGoBack}
					>
						Cancel
					</Button>
					<Button
						sx={(theme) => ({
							color: theme.customPalette.basicColors.light,
							textTransform: 'capitalize',
							padding: '8px 16px',
							minWidth: '162px',
							backgroundColor: theme.customPalette.primaryColors.sky[1],
							'&:hover': {
								backgroundColor: theme.customPalette.primaryColors.sky[2],
							},
							'&.Mui-disabled': {
								color: '#fff',
								backgroundColor: theme.customPalette.systemColors.disabled,
							},
							position: 'absolute',
							bottom: 30,
							right: '25%',
						})}
						variant="outlined"
						onClick={handleSave}
						disabled={saveDisabled}
					>
						Save
					</Button>
				</>
			)}
		</Box>
	);
};

const whiteInput = {
	'& .MuiInputBase-root, &.MuiInputBase-root': {
		backgroundColor: '#fff',
	},
	borderRadius: '8px',
	marginBottom: '5px',
};
