import { useAuth0 } from '@auth0/auth0-react';

import AppSkeleton from './components/common/AppSkeleton';
import AllRoutes from 'routes/AllRoutes';
import withAppProviders from 'hoc/withAppProviders';
import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';

function App() {

	const location = useLocation();

	useEffect(() => {
		// This function will be called whenever the route changes
		if (window.heap) {
			window.heap.track('pageview', { path: location.pathname });
		}
	}, [location]);
	const { isLoading } = useAuth0();

	if (isLoading) return <AppSkeleton />;

	return <AllRoutes />;
}

export default withAppProviders(App);
