import { gql } from 'urql';

export const completeRegistration = gql`
	mutation completeRegistration($id: Int!) {
		completeRegistration(id: $id) {
			id
		}
	}
`;

export const passwordReset = gql`
	mutation resetPasswordLink($email: String!) {
		resetPasswordLink(email: $email)
	}
`;

export const updateUserMutation = gql`
	mutation UpdateUser($updateUserData: ExistingAdminUserInput!) {
		updateUser(updateUserData: $updateUserData) {
			firstName
			lastName
			email
			userType
		}
	}
`;
