import { FC, useState } from 'react';
import { TextField } from '@mui/material';
import ConfirmationDialog from 'components/common/ConfirmationDialog';
import { Maybe } from 'types/types';

interface EditAnchorModalProps {
	open: boolean;
	anchorId: Maybe<string> | undefined;
	handleClose: () => void;
	handleEditAnchor: (anchorId: string | undefined | null) => void;
}

const EditAnchorModal: FC<EditAnchorModalProps> = (props) => {
	const {
		open,
		anchorId: defaultAnchorId,
		handleClose,
		handleEditAnchor,
	} = props;
	const [anchorId, setAnchorId] = useState(defaultAnchorId);

	return (
		<ConfirmationDialog
			open={open}
			title="Edit Anchor ID"
			onConfirm={() => handleEditAnchor(anchorId)}
			onCancel={handleClose}
		>
			<TextField
				value={anchorId}
				onChange={(e) => setAnchorId(e.target.value)}
			/>
		</ConfirmationDialog>
	);
};

export default EditAnchorModal;
