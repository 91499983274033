import { FC, useEffect, useState } from 'react';
import {
	List,
	ListItem,
	ListItemText,
	Box,
	Tabs,
	Tab,
	useTheme,
	Theme,
	ThemeProvider,
} from '@mui/material';
import { Page, TemplateInstance } from 'types/types';
import ConfirmationDialog from '../../common/ConfirmationDialog';
import usePublishPage from 'components/features/publishPage/hook/usePublishPage';
import TabPanel, { a11yProps } from './TabPanel';

interface PrePublishDialogProps {
	message: string;
	templateInstances: TemplateInstance[];
	page: Page;
	anchorEl: any;
	setAnchorEl: (val: any) => void;
	setIsPublishing: (val: boolean) => void;
	publishedTemplateInstances: TemplateInstance[];
	setPublishedTemplateInstances: (
		templateInstances: TemplateInstance[],
	) => void;
}

const PrePublishDialog: FC<PrePublishDialogProps> = (props) => {
	const {
		anchorEl,
		setAnchorEl,
		message,
		templateInstances,
		page,
		setIsPublishing,
		publishedTemplateInstances,
		setPublishedTemplateInstances,
	} = props;

	const {
		additions,
		additionObj,
		updateObj,
		removals,
		removalObj,
		handlePublishPage,
		isPublishing,
	} = usePublishPage(
		page,
		templateInstances,
		publishedTemplateInstances,
		setPublishedTemplateInstances,
	);

	const theme = useTheme();
	const open = Boolean(anchorEl);
	const [tabValue, setTabValue] = useState(0);

	useEffect(() => {
		setIsPublishing(isPublishing);
	}, [isPublishing, setIsPublishing]);

	const tabStyle = (theme: Theme) => {
		return {
			'&.Mui-selected': {
				color: theme.customPalette.primaryColors.sky[1],
			},
		};
	};

	return (
		<ConfirmationDialog
			open={open}
			onConfirm={() => {
				handlePublishPage();
				setAnchorEl(false);
			}}
			onCancel={() => setAnchorEl(false)}
			disableBackdropClick
			title={message}
			paddingSize={'small'}
		>
			<Box width={725}>
				<ThemeProvider theme={theme}>
					<Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
						<Tabs
							value={tabValue}
							onChange={(_, value) => setTabValue(value)}
							variant="fullWidth"
							TabIndicatorProps={{
								style: { background: theme.customPalette.primaryColors.sky[1] },
							}}
						>
							<Tab
								label="Additions"
								{...a11yProps(0)}
								sx={(theme) => tabStyle(theme)}
							/>
							<Tab
								label="Removals"
								{...a11yProps(1)}
								sx={(theme) => tabStyle(theme)}
							/>
						</Tabs>
					</Box>
					<TabPanel value={tabValue} index={0}>
						<List sx={{ minHeight: 300 }}>
							{Object.entries(additionObj)?.map((item, index) => (
								<ListItem key={index}>
									<ListItemText
										primary={`${item[1]} ${item[0]}${
											item[1] > 1 ? 's were ' : ' was '
										} added`}
									/>
								</ListItem>
							))}
							{Object.entries(updateObj)?.map((item, index) => (
								<ListItem key={index}>
									<ListItemText
										primary={`${item[1]} ${item[0]}${
											item[1] > 1 ? 's were ' : ' was '
										} updated`}
									/>
								</ListItem>
							))}
							{!additions.length && (
								<ListItem>
									<ListItemText primary="No additions" />
								</ListItem>
							)}
						</List>
					</TabPanel>
					<TabPanel value={tabValue} index={1}>
						<List sx={{ minHeight: 300 }}>
							{Object.entries(removalObj)?.map((item, index) => (
								<ListItem key={index}>
									<ListItemText
										primary={`${item[1]} ${item[0]}${
											item[1] > 1 ? 's were ' : ' was '
										} removed`}
									/>
								</ListItem>
							))}
							{!removals.length && (
								<ListItem>
									<ListItemText primary="No removals" />
								</ListItem>
							)}
						</List>
					</TabPanel>
				</ThemeProvider>
			</Box>
		</ConfirmationDialog>
	);
};

export default PrePublishDialog;
