import { FC } from 'react';
import { Box, Button } from '@mui/material';
import {
	AddCircleOutline,
	CheckBoxOutlineBlank,
	RadioButtonUnchecked,
	DragIndicator,
} from '@mui/icons-material';
import { DropResult } from 'react-beautiful-dnd';
import { QuestionChoice } from '../../../types/types';
import { QuestionChoice as QuestionChoiceComponent } from './QuestionChoice';
import { QuestionType } from './QuestionGroup';
import DragDropContainer from 'components/common/DragDropContainer';

interface QuestionChoiceListProps {
	type: string;
	choices: Array<QuestionChoice>;
	onAddChoice: (type: string) => void;
	onRemoveChoice: (id: number) => void;
	onUpdateChoice: (id: number, label: string) => void;
	onSortChoice: (startIndex: number, endIndex: number) => void;
}

export const QuestionChoiceList: FC<QuestionChoiceListProps> = (props) => {
	const {
		choices,
		onAddChoice,
		onUpdateChoice,
		onRemoveChoice,
		onSortChoice,
		type,
	} = props;

	const handleDragEnd = (result: DropResult) => {
		if (!result.destination) {
			return;
		}

		if (result.destination.index === result.source.index) {
			return;
		}

		onSortChoice(result.source.index, result.destination.index);
	};

	const getIconForType = () => {
		switch (type) {
			case QuestionType.MULTIPLE_CHOICE:
				return (
					<Box sx={{ display: 'flex', flexDirection: 'row' }}>
						<DragIndicator sx={{ verticalAlign: 'middle' }} />
						<CheckBoxOutlineBlank sx={{ verticalAlign: 'middle' }} />
					</Box>
				);
			case QuestionType.SINGLE_SELECT:
				return (
					<Box sx={{ display: 'flex', flexDirection: 'row' }}>
						<DragIndicator sx={{ verticalAlign: 'middle' }} />
						<RadioButtonUnchecked sx={{ verticalAlign: 'middle' }} />
					</Box>
				);
			case QuestionType.RANKED_CHOICE:
				return <DragIndicator sx={{ verticalAlign: 'middle' }} />;
			default:
				return <DragIndicator />;
		}
	};

	return (
		<Box
			sx={{
				marginTop: '10px',
			}}
		>
			<Box>
				<DragDropContainer
					droppableId="question-choices"
					items={choices}
					keyExtractor={(c) => c.id}
					handleDragEnd={handleDragEnd}
					customDataPropName="module"
					containerStyle={{ mb: 5 }}
					DraggableComponent={(c, handle) => (
						<QuestionChoiceComponent
							handle={handle}
							text={c.choiceText!}
							ordinal={c.ordinal!}
							onDeleteClick={() => onRemoveChoice(c.id)}
							onLabelUpdate={(label: string) => onUpdateChoice(c.id, label)}
							DragIconComponent={getIconForType()}
							choiceType={c.choiceType}
							smsChoiceNumber={c.smsChoiceNumber}
						/>
					)}
				/>
				{type === QuestionType.RANKED_CHOICE ? (
					<>
						<Button
							startIcon={<AddCircleOutline />}
							// sx={{ alignSelf: 'flex-start', my: -1 }}
							onClick={() => onAddChoice('')}
						>
							Add option
						</Button>
					</>
				) : (
					<>
						<Button
							startIcon={<AddCircleOutline />}
							// sx={{ alignSelf: 'flex-start', my: -1 }}
							onClick={() => onAddChoice('option')}
						>
							Add option
						</Button>
						or
						<Button
							// startIcon={<AddCircleOutline />}
							// sx={{ alignSelf: 'flex-start', my: -1 }}
							onClick={() => onAddChoice('writeIn')}
						>
							Add write-in
						</Button>
					</>
				)}
			</Box>
		</Box>
	);
};
