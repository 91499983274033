enum MODULE_NAMES {
	'Free Text' = 'Text Box',
	'Timeline' = 'Timeline',
	'Map' = 'Map',
	'Form' = 'Form',
	'FAQ' = 'FAQ',
	'Image' = 'Image',
	'Video' = 'Video',
	'List' = 'List',
	'Survey' = 'Survey',
	'Carousel' = 'Carousel',
	'Public Benefit' = 'Public Benefit',
	'Information & Plans' = 'Information & Plans',
	'Updates' = 'Updates',
	'Call To Action' = 'Call To Action',
	'Hero Image' = 'Hero Image',
	'Feedback' = 'Feedback',
	'Location & Phase' = 'Location & Phase',
	'Embed' = 'Iframe Embed',
	'Subscribe Box' = 'Subscribe Box',
}

export enum MODULE_DIALOGS {
	// Render module as Confirmation Dialog
	CONFIRMATION_DIALOG = 'confirmationDialog',
	// Render module as Form Modal
	FORM_DIALOG = 'formModal',
	NONE = 'none',
}

export const MODULE_RENDER = {
	'Free Text': {
		subTitle: '',
		render: MODULE_DIALOGS.CONFIRMATION_DIALOG,
		confirmLabel: '',
		cancelLabel: '',
	},
	Timeline: {
		subTitle: '',
		render: MODULE_DIALOGS.CONFIRMATION_DIALOG,
		confirmLabel: '',
		cancelLabel: '',
	},
	Map: {
		subTitle: '',
		render: MODULE_DIALOGS.CONFIRMATION_DIALOG,
		confirmLabel: '',
		cancelLabel: '',
	},
	Form: {
		subTitle: '',
		render: MODULE_DIALOGS.CONFIRMATION_DIALOG,
		confirmLabel: '',
		cancelLabel: '',
	},
	FAQ: {
		subTitle: '',
		render: MODULE_DIALOGS.CONFIRMATION_DIALOG,
		confirmLabel: '',
		cancelLabel: '',
	},
	Image: {
		subTitle: 'Add a photo to feature on your page',
		render: MODULE_DIALOGS.FORM_DIALOG,
		confirmLabel: 'Add Image',
		cancelLabel: 'Cancel',
	},
	Video: {
		subTitle: '',
		render: MODULE_DIALOGS.CONFIRMATION_DIALOG,
		confirmLabel: '',
		cancelLabel: '',
	},
	List: {
		subTitle: '',
		render: MODULE_DIALOGS.CONFIRMATION_DIALOG,
		confirmLabel: '',
		cancelLabel: '',
	},
	Survey: {
		subTitle: '',
		render: MODULE_DIALOGS.CONFIRMATION_DIALOG,
		confirmLabel: '',
		cancelLabel: '',
	},
	Carousel: {
		subTitle: '',
		render: MODULE_DIALOGS.CONFIRMATION_DIALOG,
		confirmLabel: '',
		cancelLabel: '',
	},
	'Public Benefit': {
		subTitle: '',
		render: MODULE_DIALOGS.CONFIRMATION_DIALOG,
		confirmLabel: '',
		cancelLabel: '',
	},
	'Information & Plans': {
		subTitle: '',
		render: MODULE_DIALOGS.CONFIRMATION_DIALOG,
		confirmLabel: '',
		cancelLabel: '',
	},
	Updates: {
		subTitle: '',
		render: MODULE_DIALOGS.NONE,
		confirmLabel: '',
		cancelLabel: '',
	},
	'Call To Action': {
		subTitle: '',
		render: MODULE_DIALOGS.CONFIRMATION_DIALOG,
		confirmLabel: '',
		cancelLabel: '',
	},
	'Hero Image': {
		subTitle: 'Add a hero image to your page',
		render: MODULE_DIALOGS.FORM_DIALOG,
		confirmLabel: 'Add Image',
		cancelLabel: 'Cancel',
	},
	Feedback: {
		subTitle: '',
		render: MODULE_DIALOGS.CONFIRMATION_DIALOG,
		confirmLabel: '',
		cancelLabel: '',
	},
	'Location & Phase': {
		subTitle: '',
		render: MODULE_DIALOGS.NONE,
		confirmLabel: '',
		cancelLabel: '',
	},
	Embed: {
		subTitle: '',
		render: MODULE_DIALOGS.CONFIRMATION_DIALOG,
		confirmLabel: '',
		cancelLabel: '',
	},
	'Subscribe Box': {
		subTitle: '',
		render: MODULE_DIALOGS.CONFIRMATION_DIALOG,
		confirmLabel: '',
		cancelLabel: '',
	},
};

export default MODULE_NAMES;
