import { useState, FC, useEffect, Dispatch, SetStateAction } from 'react';
import { Box, Typography } from '@mui/material';

import FormModal from 'components/common/FormModal';
import RegularTextInput from 'components/common/styled/RegularTextInput';
import { useAbandonActionConfirmation } from 'hooks/useActionConfirmation';
import { Comment } from 'types/types';
import dayjs from 'dayjs';

type EditCommentModalProps = {
	open: boolean;
	comment: Comment;
	handleSaveComment: (comment: Comment) => void;
	setCommentToEdit: Dispatch<SetStateAction<Comment | undefined>>;
};

const EditCommentModal: FC<EditCommentModalProps> = ({
	open,
	comment,
	handleSaveComment,
	setCommentToEdit,
}) => {
	const [commentText, setCommentText] = useState('');

	const showAbandonConfirmation = useAbandonActionConfirmation();

	const onCancel = () => {
		showAbandonConfirmation(
			{
				title: 'Abandon Changes?',
				message: (
					<Typography fontSize="16px">
						All unsaved modifications made to this comment will be lost!
					</Typography>
				),
			},
			() => setCommentToEdit(undefined),
		);
	};
	const onConfirm = () => {
		handleSaveComment({ ...comment, commentText });
		setCommentToEdit(undefined);
	};

	const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setCommentText(e.target.value);
	};

	const saveDisabled = !commentText;

	useEffect(() => {
		if (open) {
			setCommentText(comment?.commentText as string);
		}
	}, [comment, open]);

	return (
		<FormModal
			open={open}
			title={`Edit Comment by ${
				comment?.createdByUser?.fullName
			} posted on ${dayjs
				.unix(Number(comment?.created) / 1000)
				.format('MMM DD, YYYY hh:mm a')}`}
			onConfirm={onConfirm}
			onCancel={onCancel}
			confirmLabel="Save"
			cancelLabel="Cancel"
			bottomDivider
			fullWidth
			disabled={saveDisabled}
		>
			<Box padding={4} width="100%">
				<RegularTextInput
					sx={{ width: '100%' }}
					value={commentText}
					onChange={handleChange}
					error={saveDisabled}
					helperText={saveDisabled && 'Comment text is required'}
					rows={10}
					multiline
				/>
			</Box>
		</FormModal>
	);
};

export default EditCommentModal;
