import { FC, useState, useEffect } from 'react';
import { Grid, Stack, useTheme } from '@mui/material';
import { TitleCaseHyphenatedWord } from '../../../utility/titleCaseHyphenatedWord';
import { FormRow } from './FormResponseGroup';
import { useQuery } from 'urql';
import { formResponses } from 'api/query/formResponses.queries';
import useNumberParams from 'hooks/useNumberParams';
import { formsByProject } from 'api/query/form.queries';
import ChipItem from '../../../components/features/commentsPage/NavItem';
import { FormBuilderFieldConfig } from '../../../types/formBuilderConfig';
import { Map } from '../../../types/map';
import PageHeader from 'components/common/PageHeader';

export enum FormStatus {
	ALL = 'all',
	ACTIVE = 'active',
	ARCHIVE = 'archive',
}

export interface ParsedData {
	form_name: string;
	form_id: number;
	respondent: string;
	submitted_on: string;
	fields: FormBuilderFieldConfig[];
	responseJson: Map;
	formProcessorTypeId: string;
}

export const FormResponseGroupsContainer: FC = () => {
	const [activeCount, setActiveCount] = useState(0);
	const [archiveCount, setArchiveCount] = useState(0);
	const [selectedFormStatus, setFormStatus] = useState<FormStatus>(
		FormStatus.ACTIVE,
	);
	const { projectId } = useNumberParams();
	const [tableData, setTableData] = useState<ParsedData[]>([]);
	const [forms, setForms] = useState<any[]>([]);
	const [{ data }] = useQuery({
		query: formResponses,
		variables: {
			projectId,
		},
	});

	const [{ data: formData }] = useQuery({
		query: formsByProject,
		variables: {
			projectId,
		},
	});

	useEffect(() => {
		if (formData?.formsByProject.length) {
			if (selectedFormStatus === FormStatus.ARCHIVE) {
				const filtered = formData.formsByProject?.filter(
					(item: any) => !item.publishedModule?.id,
				);
				setForms(filtered);
			} else if (selectedFormStatus === FormStatus.ACTIVE) {
				const filtered = formData.formsByProject?.filter(
					(item: any) => item.publishedModule?.id,
				);
				setForms(filtered);
			} else {
				setForms(formData.formsByProject);
			}
		}
	}, [formData?.formsByProject, selectedFormStatus]);

	useEffect(() => {
		let activeCount = 0;
		let archiveCount = 0;
		formData?.formsByProject?.forEach((el: any) => {
			if (el.publishedModule?.id) activeCount++;
			if (!el.publishedModule?.id) archiveCount++;
		});
		setActiveCount(activeCount);
		setArchiveCount(archiveCount);
	}, [formData?.formsByProject]);

	useEffect(() => {
		const parsed = data?.formResponsesByProjectId?.map((item: any) => ({
			form_name: item?.formJson?.formName,
			form_id: item?.formJson?.formId,
			respondent: item?.createdByUser?.firstName,
			submitted_on: new Date(parseInt(item.created)).toLocaleDateString(),
			fields: item?.formJson?.fields,
			responseJson: item?.responsesJson,
			formProcessorTypeId: item.formProcessorTypeId,
		}));

		setTableData(parsed);
	}, [data?.formResponsesByProjectId]);

	let theme = useTheme();

	return (
		<>
			<Grid item xs={12}>
				<PageHeader sx={{ mb: 0 }}>Form Management</PageHeader>
			</Grid>
			<Grid item xs={3}>
				<Stack direction="row" justifyContent="space-between" spacing={1}>
					<ChipItem
						count={formData?.formsByProject?.length}
						active={selectedFormStatus === FormStatus.ALL}
						title={TitleCaseHyphenatedWord.transform(FormStatus.ALL)}
						onClick={() => setFormStatus(FormStatus.ALL)}
					/>
					<ChipItem
						count={activeCount}
						active={selectedFormStatus === FormStatus.ACTIVE}
						title={TitleCaseHyphenatedWord.transform(FormStatus.ACTIVE)}
						onClick={() => setFormStatus(FormStatus.ACTIVE)}
					/>
					<ChipItem
						count={archiveCount}
						active={selectedFormStatus === FormStatus.ARCHIVE}
						title={TitleCaseHyphenatedWord.transform(FormStatus.ARCHIVE)}
						onClick={() => setFormStatus(FormStatus.ARCHIVE)}
					/>
				</Stack>
			</Grid>

			<Grid item xs={12}>
				<hr
					style={{
						border: `1px solid ${theme.customPalette.borderColors.light}`,
					}}
				/>
			</Grid>
			<FormRow forms={forms} responses={tableData || []} />
		</>
	);
};
