import { FC, useEffect, useState } from 'react';
import { useQuery } from 'urql';
import {
	Box,
	Typography,
	Select,
	FormControl,
	MenuItem,
	SelectChangeEvent,
	Link as MUILink,
} from '@mui/material';
import ModuleInstanceData from '../../types/moduleInstanceData';
import { useModuleSaveData } from '../../hooks/useModuleSaveData';
import useNumberParams from '../../hooks/useNumberParams';
import { TimelineProps } from '../../routes/Timeline';
import { timelinesByProjectIdQuery } from '../../api/query/timeline.queries';
import LinkBehavior from 'components/common/links/Link';

const TimelineModule: FC<ModuleInstanceData> = (props) => {
	const { content, saveDataRef, config } = props;
	const timelineId = content.info?.timelineId;
	const { projectId } = useNumberParams();
	const { updateModuleSaveData } = useModuleSaveData(saveDataRef);

	const [timelines, setTimelines] = useState<TimelineProps[]>([]);
	const [currentTimeline, setCurrentTimeline] = useState<number>();

	const [{ data, fetching }] = useQuery({
		query: timelinesByProjectIdQuery,
		variables: { projectId },
		pause: timelines.length > 0,
	});

	const handleCurrentTimelineChange = (e: SelectChangeEvent) => {
		setCurrentTimeline(+e.target.value);
	};

	useEffect(() => {
		const timelineId = currentTimeline;
		const newData = { content: { timelineId }, config };
		updateModuleSaveData(newData);
	}, [updateModuleSaveData, config, currentTimeline]);

	useEffect(() => {
		const timelines: TimelineProps[] = data?.timelinesByProjectId;
		if (timelines) {
			setTimelines(timelines);
			const currentTimeline: TimelineProps | undefined = timelines.find(
				(timeline) => timeline.id === timelineId,
			);
			if (currentTimeline) setCurrentTimeline(currentTimeline.id);
		}
	}, [data?.timelinesByProjectId, timelineId]);

	return !fetching ? (
		<Box width="350px" sx={{ textAlign: 'center' }}>
			<MUILink
				underline="none"
				href={`/projects/${projectId}/timeline`}
				component={LinkBehavior}
				sx={{ mb: 2, display: 'block' }}
			>
				Manage Timeline here
			</MUILink>
			{Boolean(timelines?.length) ? (
				<FormControl fullWidth>
					<Select
						labelId="timeline-label"
						id="timeline-select"
						value={`${currentTimeline}` || ''}
						label="Timeline"
						variant="standard"
						renderValue={(value) => {
							return value !== 'undefined'
								? timelines.find((timeline) => timeline.id === +value)?.name
								: 'Select Timeline';
						}}
						onChange={handleCurrentTimelineChange}
					>
						{timelines.map((timeline: TimelineProps) => (
							<MenuItem key={timeline.id} value={timeline.id}>
								{timeline.name}
							</MenuItem>
						))}
					</Select>
				</FormControl>
			) : (
				<Typography>
					Unfortunately, no timelines are defined. Define your first{' '}
					<MUILink
						underline="none"
						href={`/projects/${projectId}/timeline`}
						component={LinkBehavior}
					>
						timeline
					</MUILink>
				</Typography>
			)}
		</Box>
	) : null;
};

export default TimelineModule;
