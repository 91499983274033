import { FC } from 'react';
import * as yup from 'yup';
import { Formik } from 'formik';
import { Cell, Grid } from 'styled-css-grid';
import { Card, Typography } from '@mui/material';
import { CreateOutlined } from '@mui/icons-material';
import { usePausedContext } from '@coUrbanize/community-modules';
import RegularButton from 'components/common/styled/RegularButton';
import { Project } from '../../../types/types';
import { GoogleAnalyticsFields } from './GoogleAnalyticsFields';

const validationSchema = yup.object({
	gaKeyId: yup
		.string()
		.min(3, 'Must be at least 3 characters')
		.max(75, 'Cannot be over 75 characters'),
	gaPropertyId: yup
		.string()
		.min(3, 'Must be at least 3 characters')
		.max(75, 'Cannot be over 75 characters'),
});

interface GoogleAnalyticsFormProps {
	initialValues: Project;
	onSubmitClick: (values: any) => void;
}

const GoogleAnalyticsForm: FC<GoogleAnalyticsFormProps> = (props) => {
	const { initialValues, onSubmitClick } = props;
	const { isPaused } = usePausedContext();

	return (
		<Card
			sx={(theme) => ({
				paddingLeft: theme.spacing(3),
				paddingRight: theme.spacing(3),
				paddingBottom: theme.spacing(2),
				display: 'block',
			})}
		>
			<h4>Google Analytics Settings</h4>
			<Typography variant="body2" pb={4} color="secondary">
				Each site with a custom domain requires that the following properties
				set to work with Google Analytics. <strong>Measurement Id</strong> is
				used to track site traffic. <strong>Property Id</strong> is for
				retrieving data collected for reporting analytics.
			</Typography>
			<Formik
				enableReinitialize
				validateOnChange={false}
				initialValues={{
					gaKeyId: initialValues.gaKey ?? '',
					gaPropertyId: initialValues.gaPropertyId ?? '',
				}}
				validationSchema={validationSchema}
				onSubmit={(values, { setSubmitting }) => {
					const project: any = {
						id: initialValues.id!,
						gaKey: values.gaKeyId.trim(),
						gaPropertyId: values.gaPropertyId.trim(),
					};
					onSubmitClick(project);
					setSubmitting(false);
				}}
			>
				{({
					dirty,
					isValid,
					isSubmitting,
					errors,
					touched,
					values,
					handleSubmit,
				}) => {
					return (
						<form onSubmit={handleSubmit} noValidate autoComplete="off">
							<Grid columns={2}>
								<GoogleAnalyticsFields
									values={{
										gaKeyId: values.gaKeyId,
										gaPropertyId: values.gaPropertyId,
									}}
									touched={touched}
									errors={errors}
									disabled={isPaused}
								/>

								<Cell width={2}>
									<Grid columns={2}>
										<Cell left={2} style={{ justifySelf: 'end' }}>
											<RegularButton
												color="secondary"
												variant="contained"
												type="submit"
												sx={{ justifySelf: 'flex-end' }}
												disabled={
													isSubmitting || !(isValid && dirty) || !!isPaused
												}
												startIcon={<CreateOutlined />}
											>
												Save
											</RegularButton>
										</Cell>
									</Grid>
								</Cell>
							</Grid>
						</form>
					);
				}}
			</Formik>
		</Card>
	);
};

export default GoogleAnalyticsForm;
