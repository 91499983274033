import { cloneElement, FC, ReactElement } from 'react';
import { Box, Typography, useTheme } from '@mui/material';

interface TemplateIconProps {
	name: string;
	Icon: ReactElement;
	onSelect: () => void;
}

const TemplateIcon: FC<TemplateIconProps> = (props) => {
	const { name, Icon, onSelect } = props;
	const theme = useTheme();

	const StyledIcon = cloneElement(Icon, {
		htmlColor: theme.customPalette.primaryColors.sky[1],
		sx: { fontSize: 34, mb: 1 },
	});

	return (
		<a href="#editor" style={{ textDecoration: 'none', color: 'inherit' }}>
			<Box
				border={`2px solid ${theme.customPalette.primaryColors.sky[1]}`}
				borderRadius={2}
				textAlign="center"
				width={155}
				height={130}
				p="10px 15px"
				onClick={onSelect}
				sx={{
					cursor: 'pointer',
					'&:hover': {
						borderColor: `${theme.customPalette.primaryColors.grassGreen[2]}`,
					},
					wordWrap: 'break-word',
					display: 'flex',
					flexDirection: 'column',
					justifyContent: 'center',
					alignItems: 'center',
				}}
			>
				{StyledIcon}
				<Typography color="primary" sx={{ lineHeight: 1.2 }}>
					{name}
				</Typography>
			</Box>
		</a>
	);
};

export default TemplateIcon;
