import { gql } from 'urql';

export const createImageMutation = ( s:string ) => gql`
    mutation createImage($newImageData: NewImageInput!){
        createImage(newImageData: $newImageData) {
            ${ s }
        }
    }
`;

export const removeImageMutation = gql`
    mutation removeImage($id: Int!){
        removeImage(id: $id)
    }
`;

export const updateImageMutation = gql`
    mutation updateImage($existingImageData: ExistingImageInput!) {
        updateImage(existingImageData: $existingImageData){
            id
        }
    }
`;
