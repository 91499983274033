import React from 'react';
import { Box, Fade } from '@mui/material';
import {
	ChevronRight as ChevronRightIcon,
	ChevronLeft as ChevronLeftIcon,
} from '@mui/icons-material';
import { Carousel } from 'react-responsive-carousel';
import './carouselOverrides.css';
import Dialog from '../../common/ConfirmationDialog';
import { ImageContent } from '../ImageModule';

interface CarouselModalProps {
	open: boolean;
	onClose: () => void;
	height?: number;
	images: ImageContent[];
}

const CarouselModal: React.FC<CarouselModalProps> = (props) => {
	const { open, onClose, images, height } = props;

	return (
		<Dialog
			open={open}
			onCancel={onClose}
			disableBackdropClick
			onConfirm={onClose}
			onClose={onClose}
			title="Carousel Preview"
			cancelLabel=""
			confirmLabel=""
		>
			{() => {
				if (!images?.length)
					return <h4 style={{ textAlign: 'center' }}>No Image To Preview.</h4>;

				return (
					<Fade in={open}>
						<Box bgcolor="black">
							<Carousel
								autoPlay
								autoFocus
								infiniteLoop
								renderArrowNext={(clickHandler) => (
									<Box
										position="absolute"
										zIndex={2}
										right="0"
										top="50%"
										sx={{ transform: 'translateY(-50%)', cursor: 'pointer' }}
										onClick={clickHandler}
									>
										<ChevronRightIcon htmlColor="white" sx={{ fontSize: 64 }} />
									</Box>
								)}
								renderArrowPrev={(clickHandler) => (
									<Box
										position="absolute"
										zIndex={2}
										left="0"
										top="50%"
										sx={{ transform: 'translateY(-50%)', cursor: 'pointer' }}
										onClick={clickHandler}
									>
										<ChevronLeftIcon htmlColor="white" sx={{ fontSize: 64 }} />
									</Box>
								)}
							>
								{images?.map(({ imageUrl, altTag }) => (
									<img
										src={imageUrl}
										key={imageUrl}
										alt={altTag}
										style={{ height, objectFit: 'cover' }}
									/>
								))}
							</Carousel>
						</Box>
					</Fade>
				);
			}}
		</Dialog>
	);
};

export default CarouselModal;
