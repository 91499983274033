import { Paper, styled } from '@mui/material';

export const Item = styled(Paper)<{ width?: string }>(({ theme, width }) => ({
	height: '100%',
	display: 'flex',
	width: width ?? 'initial',
	flexDirection: 'column',
	justifyContent: 'space-between',
	alignItems: 'stretch',
	background: theme.customPalette.basicColors.light,
	border: theme.customPalette.borderColors.light,
	borderRadius: '12px',
	padding: '15px 30px',
}));
