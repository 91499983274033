export class TitleCaseHyphenatedWord {
	/**
	 * @param value The string to transform to title case.
	 */
	static transform(value: string): string;
	static transform(value: null | undefined): null;
	static transform(value: string | null | undefined): string | null;
	static transform(value: string | null | undefined): string | null {
		if (!value) return null;
		value.toLowerCase().split('-');
		const titleCase = value.replace(
			/(\w)(\w*)/g,
			(g0, g1, g2) => g1.toUpperCase() + g2,
		);

		return titleCase.replace(/-/g, ' ');
	}
}
