import { FC, useEffect, useState } from 'react';
import { Box, Chip, Switch, Typography, Link as MUILink } from '@mui/material';
import { Cell, Grid } from 'styled-css-grid';
import ModuleInstanceData from '../../types/moduleInstanceData';
import usePublicBenefit from '../features/publicBenefitDefaultList/hooks/usePublicBenefit';
import useNumberParams from '../../hooks/useNumberParams';
import { useModuleSaveData } from '../../hooks/useModuleSaveData';
import TextButton from 'components/common/styled/TextButton';
import LinkBehavior from 'components/common/links/Link';

const PublicBenefitModule: FC<ModuleInstanceData> = (props) => {
	const { content, saveDataRef, config } = props,
		{ updateModuleSaveData } = useModuleSaveData(saveDataRef),
		{ projectId } = useNumberParams(),
		{ publicBenefits } = usePublicBenefit(projectId),
		[displayMore, setDisplayMore] = useState(content.info?.displayMore),
		[viewMore, setViewMore] = useState(false),
		moduleStyleType = content.info?.moduleStyleType || 'STYLE_1';

	useEffect(() => {
		const newData = { content: { displayMore, moduleStyleType }, config };
		updateModuleSaveData(newData);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [updateModuleSaveData, config]);

	return (
		<Box p={1} pt={0}>
			<MUILink
				underline="none"
				href={`/projects/${projectId}/public-benefits`}
				component={LinkBehavior}
				sx={{ mb: 2, display: 'block', textAlign: 'center' }}
			>
				Manage Public Benefits here
			</MUILink>

			<Box display="flex" alignItems="center" gap={2}>
				<Typography fontWeight="bold">Display View More</Typography>
				<Switch
					checked={displayMore}
					onChange={() => setDisplayMore(!displayMore)}
				/>
			</Box>
			<Grid columns={3}>
				{/* eslint-disable-next-line array-callback-return */}
				{publicBenefits.map((pb, index) => {
					if (
						(displayMore && index < 3) ||
						!displayMore ||
						(displayMore && viewMore)
					)
						return (
							<Cell width={1}>
								<Chip key={pb.id} sx={{ width: '175px' }} label={pb.name} />
							</Cell>
						);
				})}
			</Grid>

			{displayMore && (
				<Grid columns={3} style={{ marginTop: 5 }}>
					<Cell left={2} middle center>
						<TextButton onClick={() => setViewMore(!viewMore)}>
							View More...
						</TextButton>
					</Cell>
				</Grid>
			)}
		</Box>
	);
};

export default PublicBenefitModule;
