import { Typography } from '@mui/material';

type Props = {
	children: string;
	sx?: any;
};
const PageHeader = ({ children, sx = {} }: Props) => {
	return (
		<Typography
			variant="h4"
			mb={2}
			sx={{ ...sx, fontSize: '24px', fontWeight: 800 }}
		>
			{children}
		</Typography>
	);
};

export default PageHeader;
