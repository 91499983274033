import { FC } from 'react';
import { SxProps } from '@mui/material';
import OutlinedDropDown, { OutlinedDropDownProps } from './OutlinedDropDown';
import TextDropDown, { TextDropDownProps } from './TextDropDown';

interface DropDownProps {
	sx?: SxProps;
	onChange: (value: any) => void;
	items: { name: any; title: string }[];
	disabled?: boolean;
	variant?: 'text' | 'outlined';
}

interface ITextDropDownProps extends DropDownProps, TextDropDownProps {}
interface IOutlinedDropDownProps extends DropDownProps, OutlinedDropDownProps {}

const DropDown: FC<ITextDropDownProps | IOutlinedDropDownProps> = (props) => {
	const { sx, onChange, items, disabled, variant = 'text' } = props;

	if (variant === 'outlined')
		return (
			<OutlinedDropDown
				{...props}
				items={items}
				onChange={onChange}
				disabled={disabled}
				sx={sx}
			/>
		);

	return (
		<TextDropDown
			{...props}
			items={items}
			onChange={onChange}
			disabled={disabled}
			sx={sx}
		/>
	);
};

export default DropDown;
