import { FC, useState } from 'react';
import { Visibility } from '@mui/icons-material';
import { usePausedContext } from '@coUrbanize/community-modules';
import TextButton from 'components/common/styled/TextButton';
import {
	Dialog,
	DialogActions,
	Box,
	Typography,
	useTheme,
} from '@mui/material';
import Loading from 'components/common/Loading';
import RegularButton from 'components/common/styled/RegularButton';

interface PreviewProps {
	projectName: String;
	pageName: String;
	customUrl: String;
	isCustomUrl: boolean;
}
export const PreviewPage: FC<PreviewProps> = (props) => {
	const { projectName, pageName, customUrl, isCustomUrl } = props;

	const [loading, setLoading] = useState(true);
	const [preview, setPreview] = useState(false);
	const theme = useTheme();
	const { isPaused } = usePausedContext();

	const baseUrl = process.env.REACT_APP_SITE_BASE_URL;
	const secret = process.env.REACT_APP_SITES_API_SECRET;

	const url = `${
		isCustomUrl && process.env.NODE_ENV === 'production'
			? `https://${customUrl}`
			: baseUrl
	}/api/preview?secret=${secret}&projectSlug=${projectName}&pageSlug=${pageName}&isCustomUrl=${isCustomUrl}`;

	const exitPreviewUrl = `${baseUrl}/api/exit-preview`;

	const handleClick = () => {
		setLoading(true);
		setPreview(true);
	};

	const exitPreview = async () => {
		await fetch(exitPreviewUrl, {
			method: 'GET',
			credentials: 'include',
		});
		setPreview(false);
	};

	return (
		<>
			<TextButton
				disabled={!!isPaused}
				startIcon={<Visibility />}
				onClick={handleClick}
			>
				Preview
			</TextButton>
			<Dialog open={preview} fullScreen>
				{loading && (
					<Box
						position="absolute"
						top="50%"
						right="50%"
						sx={{ transform: 'translate(-50%, -50%)' }}
					>
						<Loading />
					</Box>
				)}
				<iframe
					src={url}
					title={projectName as string}
					width="100%"
					height="100%"
					style={{ border: 'none' }}
					onLoad={() => setLoading(false)}
				>
					Seems Like Your Browser Doesn't Support Iframes
				</iframe>
				<DialogActions
					sx={{
						backgroundColor: theme.customPalette.primaryColors.orange[5],
						justifyContent: 'center',
						height: 80,
					}}
				>
					<Typography fontWeight="800" fontSize={20} mr={2}>
						You are in Preview Mode
					</Typography>
					<RegularButton
						onClick={exitPreview}
						sx={{ padding: '6px 23px', textTransform: 'none', fontSize: 16 }}
					>
						Back to Editing
					</RegularButton>
				</DialogActions>
			</Dialog>
		</>
	);
};
