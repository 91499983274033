import { FC, useEffect, useState } from 'react';
import ModuleInstanceData, {
	ModuleInstanceComponentSaveData,
} from '../../types/moduleInstanceData';
import { Box, InputLabel, Typography, Paper, Checkbox } from '@mui/material';
import { useModuleSaveData } from '../../hooks/useModuleSaveData';
import RegularTextInput from 'components/common/styled/RegularTextInput';

interface CallToActionContent {
	subscribeAction?: boolean;
	header: string;
	description: string;
	ctaLabel: string;
	ctaLink: string;
}

const CallToActionModule: FC<ModuleInstanceData> = (props) => {
	const { content, saveDataRef, config } = props;
	const [callToActionInfo, setCallToActionInfo] =
		useState<CallToActionContent>(content);
	// eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
	const [error, setError] = useState('');
	const { updateModuleSaveData } = useModuleSaveData(saveDataRef);

	type CallToActionProperty =
		| 'header'
		| 'description'
		| 'ctaLabel'
		| 'ctaLink'
		| 'subscribeAction';

	const handleChange = (
		value: string | boolean,
		property: CallToActionProperty,
	) => {
		setCallToActionInfo({ ...callToActionInfo, [property]: value });
	};

	useEffect(() => {
		const newData = { content: callToActionInfo, config };

		updateModuleSaveData(newData as ModuleInstanceComponentSaveData);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [updateModuleSaveData, callToActionInfo]);

	return (
		<Box display="flex" flexDirection="column" px={1} pb={1}>
			<InputLabel htmlFor="header-label">Headline</InputLabel>
			<RegularTextInput
				id="header-label"
				size="small"
				type="text"
				sx={{ mt: 1, width: 450 }}
				aria-describedby="header-error-text"
				error={!!error}
				value={callToActionInfo?.header}
				onChange={(e) => handleChange(e.target.value, 'header')}
			/>
			<Typography id="header-error-text" color="red" sx={{ mb: 1 }}>
				{error}
			</Typography>
			<InputLabel htmlFor="description-label">Description</InputLabel>
			<RegularTextInput
				id="description-label"
				size="small"
				type="text"
				sx={{ mt: 1, width: 450 }}
				aria-describedby="description-error-text"
				error={!!error}
				value={callToActionInfo?.description}
				onChange={(e) => handleChange(e.target.value, 'description')}
			/>
			<Typography id="description-error-text" color="red" sx={{ mb: 1 }}>
				{error}
			</Typography>
			<InputLabel htmlFor="cta-label">Button Label</InputLabel>
			<RegularTextInput
				id="cta-label"
				size="small"
				type="text"
				sx={{ mt: 1, width: 450 }}
				aria-describedby="ctaLabel-error-text"
				error={!!error}
				value={callToActionInfo?.ctaLabel}
				onChange={(e) => handleChange(e.target.value, 'ctaLabel')}
			/>
			<Typography id="ctaLabel-error-text" color="red" sx={{ mb: 1 }}>
				{error}
			</Typography>
			<InputLabel sx={{ mt: 2, fontWeight: 'bold', textAlign: 'center' }}>
				Action
			</InputLabel>
			<Paper
				variant="outlined"
				sx={{
					p: '12px 16px',
					display: 'flex',
					alignItems: 'end',
					width: 450,
				}}
			>
				<Box>
					<InputLabel htmlFor="cta-link-label">Link</InputLabel>
					<RegularTextInput
						id="cta-link-label"
						size="small"
						type="text"
						sx={{ mt: 1, width: 300 }}
						aria-describedby="ctaLink-error-text"
						error={!!error}
						value={
							!callToActionInfo?.subscribeAction
								? callToActionInfo?.ctaLink
								: ''
						}
						onChange={(e) => handleChange(e.target.value, 'ctaLink')}
						disabled={callToActionInfo?.subscribeAction}
					/>
				</Box>
				<Box
					sx={{
						display: 'flex',
						alignItems: 'center',
						ml: 2,
					}}
				>
					<InputLabel htmlFor="cta-link-subscribe">Subscribe</InputLabel>
					<Checkbox
						id="cta-link-subscribe"
						checked={callToActionInfo?.subscribeAction}
						onChange={(e) => handleChange(e.target.checked, 'subscribeAction')}
						inputProps={{ 'aria-label': 'controlled' }}
					/>
				</Box>
			</Paper>
			<Typography id="ctaLike-error-text" color="red" sx={{ mb: 1 }}>
				{error}
			</Typography>
		</Box>
	);
};

export default CallToActionModule;
