import { Mutation } from 'types/types';
import {
	createImageMutation,
	updateImageMutation,
} from 'api/mutation/image.mutations';
import { useMutation } from 'urql';

const useImage = () => {
	const [, createImage] = useMutation<Mutation['createImage']>(
		createImageMutation('id'),
	);
	const [, updateImage] =
		useMutation<Mutation['updateImage']>(updateImageMutation);

	return {
		createImage,
		updateImage,
	};
};

export default useImage;
