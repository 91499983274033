import { Container } from '@mui/material';
import { AdminUserManagerContainer } from '../components/features/adminUserManager/AdminUserManagerContainer';


export const Users = () => {
	return (
		<Container maxWidth='xl' >
			<AdminUserManagerContainer />
		</Container>
	);
};
