import { FC, useEffect, useState } from 'react';
import { useMutation, useQuery } from 'urql';
import {
	Box,
	IconButton,
	Paper,
	Tooltip,
	Typography,
	Grid,
	Divider,
	TextField,
} from '@mui/material';
import {
	DeleteOutline,
	InsertDriveFile as DocumentIcon,
} from '@mui/icons-material';
import { usePausedContext } from '@coUrbanize/community-modules';
import TimelineFormModal from '../../common/TimelineFormModal';
import { deleteEventMutation } from '../../../api/mutation/event.mutations';
import { eventsByTimelineQuery } from '../../../api/query/event.queries';
import { updateTimelineMutation } from '../../../api/mutation/timeline.mutations';
import { EventFile, EventInfo } from 'components/common/TimelineForm';
import RegularButton from 'components/common/styled/RegularButton';
import { useSnackBar } from 'providers/SnackBarProvider';
import { useDeleteActionConfirmation } from 'hooks/useActionConfirmation';
import dayjs from 'dayjs';
import EditIcon from 'components/icons/EditIcon';

interface TimelineTableProps {
	timelineId: number;
	name: string;
	onDelete: (id: number) => void;
}

function compileEventDateTime(a: EventInfo) {
	const startDate = dayjs(a.startDate);
	const startTime = dayjs(a.startTime ?? '00:00');
	const constructorInput = a.startTime
		? `${startDate.format('YYYY-MM-DD')} ${startTime.format('hh:mm')}`
		: `${startDate.format('YYYY-MM-DD')}`;
	return dayjs(constructorInput);
}

const TimelineTable: FC<TimelineTableProps> = (props) => {
	const { timelineId, name, onDelete } = props;
	const { notify } = useSnackBar();
	const [events, setEvents] = useState<EventInfo[]>([]);
	const [index, setIndex] = useState<number>(-1);
	const [open, setOpen] = useState(false);
	const [pause, setPause] = useState(false);
	const [timelineName, setTimelineName] = useState(name);
	const [prevTimelineName, setPrevTimelineName] = useState(name);

	const [, deleteEvent] = useMutation(deleteEventMutation);
	const [, updateTimeline] = useMutation(updateTimelineMutation);

	const [{ data }] = useQuery({
		query: eventsByTimelineQuery,
		variables: { timelineId },
		pause,
	});
	const showDeleteConfirmation = useDeleteActionConfirmation();
	const { isPaused } = usePausedContext();

	const handleDelete = async () => {
		await showDeleteConfirmation(
			{
				title: 'Delete Event',
				message: 'Are you sure you want to delete this event?',
			},
			async () => {
				const newEvents: EventInfo[] = events.filter(
					(e) => e.id !== events[index].id,
				);
				setEvents(newEvents);
				await deleteEvent({ id: events[index].id });
			},
		);
	};

	const renderFilesIcon = (files: EventFile['files']) => {
		return (
			<Box sx={{ marginTop: '5px' }}>
				<Tooltip
					title={
						<>
							{files.map(({ fileName, url }) => (
								<Box key={url}>{fileName}</Box>
							))}
						</>
					}
				>
					<DocumentIcon fontSize="small" />
				</Tooltip>
			</Box>
		);
	};

	const sortedEvents = events.sort((a, b) => {
		const firstDate = compileEventDateTime(a);
		const secondDate = compileEventDateTime(b);
		return secondDate.unix() - firstDate.unix();
	});

	useEffect(() => {
		if (data?.eventsByTimeline) {
			setEvents(data.eventsByTimeline);
			setPause(true);
		}
	}, [data?.eventsByTimeline]);

	return (
		<Box mt={2} mb={4} bgcolor="#F8F8F8" p={2} style={{ maxWidth: 926 }}>
			<Box
				display="flex"
				justifyContent="space-between"
				alignItems="center"
				mb={1}
			>
				<Box display="flex" alignItems="center" gap={2}>
					<Tooltip
						title={`Delete ${timelineName}`}
						placement="bottom"
						arrow={true}
					>
						<IconButton onClick={() => onDelete(timelineId)}>
							<DeleteOutline />
						</IconButton>
					</Tooltip>
					<TextField
						size="small"
						sx={{
							minWidth: 200,
							width: timelineName?.length * 12,
							'& fieldset': { borderColor: '#F8F8F8' },
						}}
						disabled={!!isPaused}
						value={timelineName}
						onChange={(e) => {
							if (!e.target.value.trim().length) {
								setPrevTimelineName(timelineName);
							}
							setTimelineName(e.target.value);
						}}
						onBlur={async (e) => {
							if (!e.target.value.trim().length) {
								setTimelineName(prevTimelineName);
								return notify('Timeline Name is required', 'error');
							}

							if (prevTimelineName !== timelineName) {
								const res = await updateTimeline({
									existingTimeline: { name: e.target.value, id: timelineId },
								});

								if (!res.error) notify('Timeline Name updated!');
								else notify('Error occurred updating Timeline Name', 'error');
							}
						}}
						inputProps={{
							style: { fontSize: 20, fontWeight: '800' },
						}}
					/>
				</Box>
				<RegularButton
					variant="contained"
					size="large"
					disabled={!!isPaused}
					onClick={() => {
						setOpen(true);
						setIndex(-1);
					}}
					style={{
						textTransform: 'none',
						fontWeight: 500,
						fontSize: 18,
						padding: '12px 44px',
					}}
				>
					Add Event
				</RegularButton>
			</Box>
			{events.length === 0 && (
				<Paper elevation={1} sx={{ p: 1, m: 1 }}>
					<Typography>No Timeline Events Added Yet</Typography>
				</Paper>
			)}
			{events.length > 0 && (
				<Box>
					{sortedEvents.map((event, i) => (
						<Grid key={event.id} container spacing={2} py={2} px={2}>
							<Grid item xs={1} sx={{ marginTop: '3px' }}>
								<Typography fontWeight={800}>
									{dayjs(event.startDate).format('MMM DD')}
								</Typography>
								<Typography fontWeight={500}>
									{dayjs(event.startDate).format('YYYY')}
								</Typography>
							</Grid>
							<Grid item xs={9}>
								<Box
									sx={{
										display: 'flex',
										justifyContent: 'left',
										alignItems: 'center',
									}}
								>
									<Typography fontWeight={800} lineHeight={2}>
										{event.name}
									</Typography>
									<Box
										sx={{
											display: 'flex',
											flexDirection: 'column',
											justifyContent: 'left',
											alignItems: 'center',
											marginLeft: '10px',
										}}
									>
										{!!event.file?.files.length &&
											renderFilesIcon(event.file.files!)}
									</Box>
								</Box>
								<Typography>{event.description}</Typography>
								{event.displayDate && (
									<Typography
										fontWeight={500}
										color="text.disabled"
										lineHeight={2}
									>
										Display Date: {event.displayDate}{' '}
									</Typography>
								)}
							</Grid>
							<Grid item xs={2}>
								<Box display="flex" alignItems="center">
									<Tooltip
										title={`Edit ${event.name} event`}
										placement="bottom"
										arrow={true}
									>
										<IconButton
											onClick={() => {
												setOpen(true);
												setIndex(i);
											}}
											disabled={!!isPaused}
										>
											<EditIcon />
										</IconButton>
									</Tooltip>
									<Tooltip
										title={`Delete ${event.name} event`}
										placement="bottom"
										arrow={true}
									>
										<IconButton
											onClick={async () => {
												setIndex(i);
												await handleDelete();
											}}
											disabled={!!isPaused}
										>
											<DeleteOutline />
										</IconButton>
									</Tooltip>
								</Box>
							</Grid>
							<Grid item xs={12} mt={2}>
								<Divider variant="middle" light={true} sx={{ maxWidth: 680 }} />
							</Grid>
						</Grid>
					))}
				</Box>
			)}

			{open && (
				<TimelineFormModal
					open={open}
					onClose={() => setOpen(false)}
					event={events[index]}
					onSave={(newEvent) => {
						const updatedEvents = events;
						if (index >= 0) updatedEvents[index] = newEvent;
						else updatedEvents.push(newEvent);
						setEvents(updatedEvents);
					}}
					timelineId={timelineId}
				/>
			)}
		</Box>
	);
};

export default TimelineTable;
