import { gql } from 'urql';

const defaultPublicBenefitsQuery = `
    id
    project {
      id
      name
      slug
      address1
      address2
      city
      state
      postalCode
      projectPhase
      disabled
    }
    image {
      id
      fileName
      imageUrl
      altTag
      description
      primaryImage
    }
    imageDescription
    imageScale
    boundingBoxPosition
    name
    description
    ordinal`;

export const publicBenefitDefaults = gql`
    query{
        publicBenefitDefaults{
            id
            benefitName
            ordinal
        }
    }
`;

export const publicBenefitsByProjectIdQuery = ( query: string = defaultPublicBenefitsQuery ) => gql`
    query($projectId: Int!){
        publicBenefitsByProject(projectId: $projectId){
            ${query}
        }
    }`;

export const publicBenefitByIdQuery = ( query: string = defaultPublicBenefitsQuery ) => gql`
    query($publicBenefitId: Int!){
        publicBenefit(id: $publicBenefitId){
            ${query}
        }
    }`;
