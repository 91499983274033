import { FC, useState } from 'react';
import { Box, Input } from '@mui/material';
import useNumberParams from '../hooks/useNumberParams';
import usePublicBenefit from '../components/features/publicBenefitDefaultList/hooks/usePublicBenefit';
import { PublicBenefitList } from '../components/features/publicBenefitList/PublicBenefitList';
import { PublicBenefitDefaultList } from '../components/features/publicBenefitDefaultList/PublicBenefitDefaultList';
import ConfirmationDialog from '../components/common/ConfirmationDialog';
import PageHeader from 'components/common/PageHeader';

export const PublicBenefits: FC = () => {
	const { projectId } = useNumberParams();
	const [open, setOpen] = useState<boolean>(false);
	const [newPublicBenefitName, setNewPublicBenefitName] = useState<string>('');
	const {
		publicBenefits,
		publicBenefitsDefault,
		handleReorderPublicBenefit,
		handleUpdatePublicBenefit,
		handleImageUpdate,
		handleCreateBlank,
		handleRemovePublicBenefit,
		handleCreatePublicBenefit,
	} = usePublicBenefit(projectId);

	return (
		<>
			<Box p={2} px={5} maxWidth={800}>
				<Box display="flex" justifyContent="space-between" alignItems="center">
					<PageHeader>Public Benefits</PageHeader>
				</Box>

				<Box>
					<PublicBenefitDefaultList
						publicBenefits={publicBenefits}
						publicBenefitsDefault={publicBenefitsDefault}
						handleCreateBlank={handleCreateBlank}
						onDelete={handleRemovePublicBenefit}
						onCreate={handleCreatePublicBenefit}
					/>
					<PublicBenefitList
						publicBenefits={publicBenefits}
						onSort={handleReorderPublicBenefit}
						onUpdate={handleUpdatePublicBenefit}
						onImageUpdate={handleImageUpdate}
						onDelete={handleRemovePublicBenefit}
					/>
				</Box>
			</Box>

			<ConfirmationDialog
				open={open}
				title="Create Public Benefit"
				onConfirm={async () => {
					await handleCreatePublicBenefit(
						newPublicBenefitName,
						undefined,
						publicBenefits.length - 1,
					);
					if (newPublicBenefitName.length) setOpen(false);
				}}
				onCancel={() => setOpen(false)}
				confirmLabel="Create"
			>
				<div style={{ width: 300 }}>
					<Input
						value={newPublicBenefitName}
						placeholder="New Public Benefit Name"
						fullWidth
						error={!newPublicBenefitName.length}
						onChange={(e) => setNewPublicBenefitName(e.target.value)}
					/>
				</div>
			</ConfirmationDialog>
		</>
	);
};
