import graphqlConn from '../utils/graphqlConn';
import { ContentLoader } from './contentLoaderType';
import getInfo from '../utils/getInfo';

export const feedbackLoader: ContentLoader = async (
	content,
	project,
	getToken,
) => {
	const questionGroupsQuery = `
        query ($projectId: Int!) {
            questionGroupsByProject(projectId: $projectId, active: true) {
				id
				themeName
				status
				ordinal
				questions {
					id
					questionText
					type
					archived
					publicResponses
					ordinal
					deleted
					image {
						id
						fileName
						imageUrl
						altTag
						description
						primaryImage
					}
					imageDescription
					imageScale
					boundingBoxPosition
					questionChoices {
						id
						choiceText
						choiceType
						ordinal
					}
					questionAnswers {
						id
						questionChoiceId
						questionId
						createdBy
						rank
					}
					created
					responsePhoneNumber
					responsePhoneNumberType
				}
			}
		}
	`;

	const commentsQuery = `
		query ($projectId: Int!) {
			publicCommentsByProject(projectId: $projectId) {
				id
				projectId
				commentText
				parentCommentId
				commentSupports {
					id
					createdBy
				}
				commentFlags {
					id
					createdBy
				}
				# questionChoiceId
				starred
				featured
				markedForFollowUp
				approved
				published
				deleted
				createdByUser {
					id
					fullName
					firstName
					lastName
					userType
				}
				question {
					id
					questionText
					questionGroups {
						id
					}
				}
				created
				mobileUserId
				mobileUser {
					id
					mobileNumber
					mobileType
					blocked
				}
			}
		}
	`;

	const res = await graphqlConn(getToken, questionGroupsQuery, {
		projectId: project?.id,
		active: true,
	});
	const commentRes = await graphqlConn(getToken, commentsQuery, {
		projectId: project?.id,
	});

	const questionGroupsData = res?.data;
	const commentsData = commentRes?.data;

	return {
		data: {
			questionGroups: questionGroupsData.questionGroupsByProject,
			comments: commentsData.publicCommentsByProject || [],
			project,
		},
		info: getInfo(content),
	};
};
