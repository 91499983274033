import { FC, useState, useEffect, ChangeEvent, ReactNode } from 'react';
import {
	Box,
	Button,
	FormControlLabel,
	Grid,
	Switch,
	TextField,
	Typography,
	Select,
	MenuItem,
	FormControl,
	InputLabel,
} from '@mui/material';
import { InsertPhoto as InsertPhotoIcon } from '@mui/icons-material';
import InputMask from 'react-input-mask';
import ConfirmationDialog from 'components/common/ConfirmationDialog';
import { Question } from '../../../types/types';
import { QuestionType } from './QuestionGroup';
import {
	updateQuestionMutation,
	reorderQuestionChoiceMutation,
	createQuestionChoiceMutation,
	updateQuestionChoiceMutation,
	deleteQuestionChoiceMutation,
} from '../../../api/mutation/questions.mutations';
import { useMutation } from 'urql';
import { useSnackBar } from '../../../providers/SnackBarProvider';
import { QuestionChoiceList } from './QuestionChoiceList';
import ImagePlaceholderCard from '../../common/ImagePlaceholderCard';
import { ImageInfo } from '../../../types/imageInfo';
import produce from 'immer';
import useNumberParams from '../../../hooks/useNumberParams';
import { getObjectKey } from '../../../utility/getObjectKey';
import {
	getUploadUrlMutation,
	removeFileMutation,
} from '../../../api/mutation/s3.mutations';
import {
	createImageMutation,
	removeImageMutation,
} from '../../../api/mutation/image.mutations';
import { fileUploadApi } from '../../../api/apiClient';
import UploadImage from 'components/modules/components/UploadImage';
import { ImageContent, ImageProperty } from 'types/imageInfo';
import { useRouteContext } from '../../../routes/Layout';
import Collapse from '@mui/material/Collapse';
import TextButton from '../../common/styled/TextButton';
import { isEqual } from 'lodash';
import { useSaveActionConfirmation } from 'hooks/useActionConfirmation';

interface QuestionFormModalProps {
	open: boolean;
	onClose: () => void;
	onSave: (question: Question) => void;
	persistedQuestion: Question;
}
interface QuestionInput {
	id: number;
	questionText: string;
	publicResponses: boolean;
	responsePhoneNumber?: string | null;
	responsePhoneNumberType?: string | null;
}
export const QuestionFormModal: FC<QuestionFormModalProps> = (props) => {
	const { projectId } = useNumberParams();
	const { open, onClose, onSave, persistedQuestion } = props;
	const { notify } = useSnackBar();
	const saveActionConfirmation = useSaveActionConfirmation();

	const initialQuestionState = {
		...persistedQuestion,
		questionText: persistedQuestion.questionText ?? '',
		publicResponses: persistedQuestion.publicResponses ?? true,
		responsePhoneNumber: persistedQuestion.responsePhoneNumber ?? '',
		responsePhoneNumberType: persistedQuestion.responsePhoneNumberType,
	};

	const [question, setQuestion] = useState(initialQuestionState);
	// eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
	const [questionChoices] = useState(
		question?.questionChoices ? [...question?.questionChoices] : [],
	);
	const [orderChanged, setOrderChanged] = useState(false);
	const [imageInfo, setImageInfo] = useState<ImageInfo | null>(null);
	const [progress, setProgress] = useState(0);
	const [openImageModal, setOpenImageModal] = useState(false);
	const [selectDisabled, setSelectDisabled] = useState(true);
	const [showAdvanceSettings, setShowAdvanceSettings] = useState(false);

	const [, getUploadUrl] = useMutation(getUploadUrlMutation);
	const { activeProjectDetails } = useRouteContext();
	const [, updateQuestion] = useMutation(updateQuestionMutation);
	const [, reorderChoice] = useMutation(reorderQuestionChoiceMutation);
	const [, createChoice] = useMutation(createQuestionChoiceMutation);
	const [, updateChoice] = useMutation(updateQuestionChoiceMutation);
	const [, deleteChoice] = useMutation(deleteQuestionChoiceMutation);

	const [, createImage] = useMutation(createImageMutation('id'));
	const [, removeImage] = useMutation(removeImageMutation);
	const [, removeFile] = useMutation(removeFileMutation);

	const handleImageUpdate = async (
		id: number,
		imageId: number,
		name: string,
	) => {
		const res = await updateQuestion({
			editQuestionData: {
				imageScale: question.imageScale,
				boundingBoxPosition: question.boundingBoxPosition,
				id,
				imageId,
				questionText: name,
			},
		});

		if (res.error) return notify('Image failed to update!', 'error');
		notify('Image Updated');
	};

	const handleUpload = async (files: FileList | null) => {
		if (!files) return;

		const file = files[0];
		const result = await getUploadUrl({
			folder: `${activeProjectDetails.slug}/questions`,
			filename: file.name,
		});

		if (result.error) return;

		const uploadURL = result.data.getUploadUrl;

		const res = await fileUploadApi(uploadURL, file, setProgress);
		if (!res) return notify('Failed to upload image!', 'error');
		const imageUrl = res.split('?')[0];

		const newImageData = {
			imageUrl,
			fileName: file.name,
			altTag: '',
			projectId,
		};
		setImageInfo(newImageData);
	};

	const handleRemoveImage = async () => {
		const res = await removeImage({
			id: question.image?.id,
		});
		setImageInfo(null);
		setQuestion({ ...question, image: null });
		if (!res.error) {
			notify('Image removed');
		}
	};

	const confirmUpload = async () => {
		if (!imageInfo) return setOpenImageModal(false);

		const result = await createImage({ newImageData: imageInfo });
		const { id } = result.data.createImage!;

		setQuestion({ ...question, image: { ...imageInfo, id } });

		await handleImageUpdate(question.id, id, question.questionText);
		setImageInfo(null);
		setOpenImageModal(false);
	};

	const cancelUpload = async () => {
		if (!imageInfo) return setOpenImageModal(false);

		// delete image from bucket
		const objectKey = getObjectKey(imageInfo.imageUrl);
		await removeFile({ objectKey });

		setImageInfo(null);
		setOpenImageModal(false);
	};

	const handleConfirm = async () => {
		if (
			!isEqual(initialQuestionState, question) &&
			persistedQuestion.questionText
		) {
			await saveActionConfirmation(
				{
					title: 'Note',
					message:
						'Changing a question after it\'s received responses can negatively impact the validity of your comment data and outreach reports.',
					confirmLabel: 'Proceed anyway',
				},
				() => handleSave(),
			);
		} else {
			handleSave();
		}
	};

	const handleSave = async () => {

		const data: QuestionInput = {
			id: question.id,
			questionText: question.questionText,
			publicResponses: question.publicResponses,
			responsePhoneNumber:
				question.responsePhoneNumber !== ''
					? question.responsePhoneNumber
					: null,
			responsePhoneNumberType:
				question.responsePhoneNumberType !== ''
					? question.responsePhoneNumberType
					: 'none',
		};
		const result = await updateQuestion({
			editQuestionData: data,
		});
		if (result.error) {
			notify('Question could not be saved', 'error');
			return;
		}
		await addNewChoices();
		const orderChoices = question.questionChoices?.map((QC) => ({
			id: QC.id,
			ordinal: QC.ordinal,
		}));

		if (orderChanged) {
			const response = await reorderChoice({ questionChoices: orderChoices });
			if (response.error)
				notify('Issue sorting choices. Please try later', 'error');
		}
		onSave(question);
		onClose();
		notify('Question saved');
	};

	const handlePublicResponseChange = (event: ChangeEvent<HTMLInputElement>) => {
		setQuestion({ ...question, publicResponses: event.target.checked });
	};

	useEffect(() => {
		if (question?.responsePhoneNumber) {
			setSelectDisabled(false);
			setQuestion({ ...question, responsePhoneNumberType: 'sms' });
		} else {
			setSelectDisabled(true);
			setQuestion({ ...question, responsePhoneNumberType: 'none' });
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [question?.responsePhoneNumber]);

	const handleImageChange = (
		property: ImageProperty,
		event?: ChangeEvent | Event,
		data?: any,
	) => {
		const value = data || (event?.target as HTMLInputElement).value;

		if (['imageUrl', 'fileName', 'altTag'].includes(property)) {
			setImageInfo((prevImageInfo) => ({
				...(prevImageInfo as ImageInfo),
				[property]: value,
			}));
		} else {
			setQuestion({ ...question, [property]: value });
		}
	};

	const [imageConfirmDisabled, setImageConfirmDisabled] = useState(false);

	const onAddChoice = (choiceType: string) => {
		setQuestion(
			produce(question, (draft) => {
				if (!draft.questionChoices) draft.questionChoices = [];
				// to make sure the temporary id  we are assigning to new choice is not same as existing choices' id's
				let tempId = Math.floor(Math.random() * 1000);
				const isSame = draft.questionChoices.some((o) => o.id === tempId);
				while (isSame) {
					tempId = Math.floor(Math.random() * 1000);
				}

				const newChoice = {
					id: tempId,
					question,
					choiceText: '',
					choiceType,
					ordinal: draft.questionChoices.length + 1,
				};

				draft.questionChoices!.push(newChoice);
			}),
		);
	};
	const addNewChoices = async () => {
		if (question?.questionChoices?.length) {
			//@ts-ignore
			for (const [idx, item] of question?.questionChoices?.entries()) {
				if (item?.question) {
					const response = await createChoice({
						questionId: question.id,
					});
					if (!response.error) {
						await updateChoice({
							questionChoiceId: response.data.createQuestionChoice.id,
							choiceText: item.choiceText,
							choiceType: item.choiceType,
						});
					}
				} else if (item.choiceText !== questionChoices[idx].choiceText) {
					await updateChoice({
						questionChoiceId: item.id,
						choiceText: item.choiceText,
						choiceType: item.choiceType,
					});
				}
			}
		}
	};
	const renderResponsePhoneDetails = () => {
		return (
			<>
				<Grid item xs={12}>
					<InputMask
						mask={'+9(999)999-9999'}
						value={question.responsePhoneNumber}
						onChange={(event) =>
							setQuestion({
								...question,
								responsePhoneNumber: event.target.value
									.replaceAll('(', '')
									.replaceAll(')', '')
									.replaceAll('-', ''),
							})
						}
					>
						{
							(() => (
								<TextField fullWidth label="Response phone number" />
							)) as unknown as ReactNode
						}
					</InputMask>
				</Grid>
				<Grid item xs={12} mt={3}>
					<FormControl fullWidth>
						<InputLabel id="select-label">
							Response phone number type
						</InputLabel>
						<Select
							disabled={selectDisabled}
							labelId="select-label"
							fullWidth
							label="Response phone number type"
							value={question.responsePhoneNumberType || 'none'}
							onChange={(event) => {
								setQuestion({
									...question,
									responsePhoneNumberType: event.target.value,
								});
							}}
						>
							<MenuItem value={'none'}>
								<em>None</em>
							</MenuItem>
							<MenuItem value={'sms'}>SMS</MenuItem>
							<MenuItem value={'whatsapp'}>Whatsapp</MenuItem>
						</Select>
					</FormControl>
				</Grid>
			</>
		);
	};

	const renderQuestionTypeDetails = () => {
		switch (question.type) {
			case QuestionType.SHORT_ANSWER:
			case QuestionType.LONG_ANSWER:
				return (
					<>
						<Grid item xs={12} pb={2}>
							<FormControlLabel
								control={
									<Switch
										color="primary"
										onChange={handlePublicResponseChange}
										checked={question.publicResponses}
									/>
								}
								label="Public Response"
								labelPlacement="start"
							/>
						</Grid>
						<Grid item xs={12}>
							<TextButton
								sx={{
									display: 'block',
									mb: 2,
									textTransform: 'none',
									alignSelf: 'start',
									fontSize: '1em',
								}}
								onClick={() => setShowAdvanceSettings(!showAdvanceSettings)}
							>
								{`${!showAdvanceSettings ? 'Show' : 'Hide'}`} advance settings
							</TextButton>
						</Grid>
						<Collapse in={showAdvanceSettings} sx={{ width: '100%' }}>
							{renderResponsePhoneDetails()}
						</Collapse>
					</>
				);
			default:
				return (
					<Grid container>
						<Grid item xs={12}>
							<QuestionChoiceList
								type={question.type}
								choices={question.questionChoices ?? []}
								onAddChoice={onAddChoice}
								onRemoveChoice={async (id: number) => {
									const response = await deleteChoice({
										questionChoiceId: id,
									});
									const oldOptionIdx = questionChoices.findIndex(
										(c) => c.id === id,
									);

									if (!response.error || oldOptionIdx < 0) {
										setQuestion(
											produce(question, (draft) => {
												const index = draft.questionChoices!.findIndex(
													(c) => c.id === id,
												);
												if (index !== -1)
													draft.questionChoices!.splice(index, 1);
											}),
										);
									}
								}}
								onUpdateChoice={async (id: number, label: string) => {
									setQuestion(
										produce(question, (draft) => {
											const index = draft.questionChoices!.findIndex(
												(c) => c.id === id,
											);
											if (index !== -1)
												draft.questionChoices![index].choiceText = label;
										}),
									);
								}}
								onSortChoice={async (startIndex: number, endIndex: number) => {
									if (startIndex === endIndex) return;

									setQuestion(
										produce(question, (draft) => {
											const removed = draft.questionChoices!.splice(
												startIndex,
												1,
											)[0];

											draft.questionChoices!.splice(endIndex, 0, removed);

											for (
												let index = 0;
												index < draft.questionChoices!.length;
												index++
											) {
												draft.questionChoices![index].ordinal = index + 1;
											}
										}),
									);

									setOrderChanged(true);
								}}
							/>
						</Grid>
						{question.type === QuestionType.SINGLE_SELECT && (
							<>
								<Grid item xs={12}>
									<TextButton
										sx={{
											display: 'block',
											mb: 2,
											textTransform: 'none',
											alignSelf: 'start',
											fontSize: '1em',
										}}
										onClick={() => setShowAdvanceSettings(!showAdvanceSettings)}
									>
										{`${!showAdvanceSettings ? 'Show' : 'Hide'}`} advance settings
									</TextButton>
								</Grid>
								<Collapse in={showAdvanceSettings} sx={{ width: '100%' }}>
									{renderResponsePhoneDetails()}
								</Collapse>
							</>
						)}
					</Grid>
				);
		}
	};

	return (
		<>
			<ConfirmationDialog
				title={`${question.questionText ? 'Edit' : 'Create'} Question`}
				open={open}
				paddingSize="small"
				onConfirm={handleConfirm}
				onCancel={onClose}
			>
				<Box width={725}>
					<Grid container>
						<Grid item xs={8}>
							<Typography>Question</Typography>
						</Grid>
						<Grid item textAlign="right" justifyContent="flex-end" xs={4}>
							{question.image?.imageUrl && !imageInfo?.imageUrl ? (
								<div style={{ display: 'flex', justifyContent: 'flex-end' }}>
									<ImagePlaceholderCard
										image={question.image}
										handleDelete={async () => await handleRemoveImage()}
									/>
								</div>
							) : (
								<Button
									onClick={() => setOpenImageModal(true)}
									sx={{ fontSize: 12 }}
									startIcon={<InsertPhotoIcon />}
								>
									Add image
								</Button>
							)}
						</Grid>
						<Grid item xs={12} pb={3}>
							<TextField
								fullWidth
								value={question.questionText}
								onChange={(event) =>
									setQuestion({ ...question, questionText: event.target.value })
								}
							/>
						</Grid>
						{renderQuestionTypeDetails()}
					</Grid>
				</Box>
			</ConfirmationDialog>
			<ConfirmationDialog
				open={openImageModal}
				onConfirm={confirmUpload}
				onCancel={cancelUpload}
				confirmLabel="Upload"
				title=""
				paddingSize="small"
				confirmDisabled={imageConfirmDisabled}
			>
				<UploadImage
					handleUpload={handleUpload}
					progress={progress}
					handleChange={handleImageChange}
					imageContent={
						{
							...imageInfo,
							imageScale: question?.imageScale,
							boundingBoxPosition: question?.boundingBoxPosition,
						} as ImageContent
					}
					setConfirmDisabled={setImageConfirmDisabled}
					showLightBox={false}
				/>
			</ConfirmationDialog>
		</>
	);
};
