import { FC } from 'react';
import { Box, Chip, InputLabel, Typography, useTheme } from '@mui/material';
import { Field, Form, Formik } from 'formik';
import { useMutation } from 'urql';
import FileField from '../../common/FileField';
import { getObjectKey } from '../../../utility/getObjectKey';
import { removeFileMutation } from '../../../api/mutation/s3.mutations';
import infoPlanSchema from '../../schema/infoPlanFormSchema';
import { useSnackBar } from '../../../providers/SnackBarProvider';
import RegularButton from 'components/common/styled/RegularButton';
import OutlinedButton from 'components/common/styled/OutlinedButton';
import RegularTextInput from 'components/common/styled/RegularTextInput';
import { useRouteContext } from 'routes/Layout';

type File = { fileName: string; url: string } | null;

export interface InformationPlan {
	id: number;
	ordinal?: number;
	title: string;
	url?: string;
	file?: File;
}

interface InformationPlansFormProps {
	informationPlan?: InformationPlan;
	onClose: () => void;
	onSave: (ip: InformationPlan) => void;
}

const InformationPlansForm: FC<InformationPlansFormProps> = (props) => {
	const { informationPlan, onClose, onSave } = props;
	const [, removeFile] = useMutation(removeFileMutation);
	const { notify } = useSnackBar();
	const theme = useTheme();
	const { activeProjectDetails } = useRouteContext();
	const handleSubmit = (val: InformationPlan) => {
		onSave(val);
	};

	const deleteFile = async (file: File | undefined) => {
		if (!file) return;

		const objectKey = getObjectKey(file.url);
		await removeFile({ objectKey });
		// then notify user
	};

	return (
		<Formik
			initialValues={
				{
					id: informationPlan?.id,
					title: informationPlan?.title || '',
					url: informationPlan?.url || '',
					file: informationPlan?.file || null,
				} as InformationPlan
			}
			validationSchema={infoPlanSchema}
			onSubmit={handleSubmit}
		>
			{({ values, touched, errors, setFieldValue }) => (
				<Form>
					<Typography sx={{ fontWeight: 'bold', mb: 2 }}>
						Document Title
					</Typography>
					<InputLabel id="title">Title</InputLabel>
					<Field
						as={RegularTextInput}
						variant="outlined"
						size="small"
						fullWidth
						name="title"
						value={values.title}
						error={Boolean(touched.title && errors.title)}
						helperText={touched.title ? errors.title : ''}
					/>
					<Box display="flex" gap={3} mt={3}>
						<Box>
							<Typography sx={{ fontWeight: 'bold', mb: 2 }}>
								Upload File
							</Typography>
							{!values?.file ? (
								<Field
									component={FileField}
									name="file"
									folder={`${activeProjectDetails.slug}/info-plans`}
								/>
							) : (
								<Chip
									size={'small'}
									color="secondary"
									onDelete={() => {
										setFieldValue('file', null);
										deleteFile(values?.file).then(() => {
											notify('Document removed');
										});
									}}
									onClick={() => window.open(values?.file?.fileName)}
									label={values?.file?.fileName}
								/>
							)}
						</Box>
						<Box display="flex" alignItems="center" gap={1} alignSelf="center">
							<Box
								width={20}
								height={2}
								bgcolor={theme.customPalette.primaryColors.dusk[5]}
							/>
							<Typography
								autoCapitalize="characters"
								fontSize={18}
								color={theme.customPalette.primaryColors.sky[1]}
							>
								OR
							</Typography>
							<Box
								width={20}
								height={2}
								bgcolor={theme.customPalette.primaryColors.dusk[5]}
							/>
						</Box>
						<Box flex="1">
							<Typography sx={{ fontWeight: 'bold', mb: 2 }}>
								File Url
							</Typography>
							<Field
								as={RegularTextInput}
								disabled={values.file}
								variant="outlined"
								type="url"
								size="small"
								fullWidth
								name="url"
								value={values.url}
								error={Boolean(touched.url && errors.url)}
								helperText={touched.url ? errors.url : ''}
							/>
						</Box>
					</Box>

					<Box display="flex" gap={2} mt={3}>
						<OutlinedButton
							onClick={onClose}
							color="secondary"
							variant="outlined"
						>
							Cancel
						</OutlinedButton>

						<RegularButton variant="contained" type="submit">
							Save
						</RegularButton>
					</Box>
				</Form>
			)}
		</Formik>
	);
};

export default InformationPlansForm;
