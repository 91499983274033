import React, { FC, useState } from 'react';
import { PublishedWithChanges } from '@mui/icons-material';
import { usePausedContext } from '@coUrbanize/community-modules';
import PrePublishDialog from 'components/features/publishPage/PrePublishDialog';
import { Page, TemplateInstance } from 'types/types';
import { LoadingButton } from '@mui/lab';

interface PublishPageProps {
	page: Page;
	templateInstances: TemplateInstance[];
	publishedTemplateInstances: TemplateInstance[];
	setPublishedTemplateInstances: (
		templateInstances: TemplateInstance[],
	) => void;
}

export const PublishPage: FC<PublishPageProps> = (props) => {
	const {
		page,
		templateInstances,
		publishedTemplateInstances,
		setPublishedTemplateInstances,
	} = props;
	const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
	const { isPaused } = usePausedContext();
	const [isPublishing, setIsPublishing] = useState(false);
	return (
		<>
			<LoadingButton
				disabled={!!isPaused || isPublishing}
				onClick={(e) => setAnchorEl(e.currentTarget)}
				startIcon={<PublishedWithChanges />}
				loading={isPublishing}
				loadingPosition="start"
			>
				Publish
			</LoadingButton>
			<PrePublishDialog
				page={page}
				message="Are you sure you want to publish this page?"
				templateInstances={templateInstances}
				anchorEl={anchorEl}
				setAnchorEl={setAnchorEl}
				setIsPublishing={setIsPublishing}
				publishedTemplateInstances={publishedTemplateInstances}
				setPublishedTemplateInstances={setPublishedTemplateInstances}
			/>
		</>
	);
};
