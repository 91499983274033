import { useQuery, useMutation } from 'urql';
import { usersByProjectQuery } from 'api/query/user.queries';
import useNumberParams from 'hooks/useNumberParams';
import Loading from '../../common/Loading';
import { ProjectUserManagerLayout } from './ProjectUserManagerLayout';
import { inviteUserToProjectMutation } from 'api/mutation/project.mutation';
import { useSnackBar } from 'providers/SnackBarProvider';
import { ProjectUser } from 'types/types';
import _ from 'lodash';

export const ProjectUserManagerContainer = () => {
	const { projectId } = useNumberParams();
	const { notify } = useSnackBar();

	const [{ data }, reexecuteQuery] = useQuery({
		query: usersByProjectQuery,
		variables: { projectId: projectId },
	});
	const [, inviteUserToProject] = useMutation(inviteUserToProjectMutation);

	const refresh = () => {
		reexecuteQuery({ requestPolicy: 'network-only' });
	};

	const handleInviteClick = async (
		firstName: string,
		lastName: string,
		email: string,
	) => {
		const response = await inviteUserToProject({
			userData: {
				firstName,
				lastName,
				email,
				userType: 'client',
			},
			projectId,
		});

		if (response.error) notify('Project user could not be invited', 'error');
		else notify('Project user invited');

		refresh();
		return response.data;
	};

	if (!data?.usersByProject) return <Loading />;

	const sortedUsers = _.sortBy(
		data.usersByProject,
		(user: ProjectUser) => user.user.fullName,
	);

	return (
		<ProjectUserManagerLayout
			onInviteClick={handleInviteClick}
			projectUsers={sortedUsers}
			refreshUsers={refresh}
		/>
	);
};
