import { createContext, useContext, useState } from 'react';
import { AlertColor } from '@mui/material';
import NotificationSnackbar from '../components/common/NotificationSnackbar';

interface SnackBar {
	notify: (message: string, variant?: AlertColor) => void;
}

export const SnackBarContext = createContext<SnackBar>({ notify: () => null });

const SnackbarProvider = (props: any) => {
	const [open, setOpen] = useState(false);
	const [variant, setVariant] = useState<AlertColor>('success');
	const [message, setMessage] = useState('Message');

	const notify = (message: string, variant?: AlertColor) => {
		if (!message) return;

		setVariant(variant || 'success');
		setMessage(message);
		setOpen(true);
	};

	const handleClose = () => setOpen(false);

	return (
		<SnackBarContext.Provider value={{ notify }}>
			{props.children}

			<NotificationSnackbar
				snackbarOpen={open}
				handleSnackbarClose={handleClose}
				message={message}
				variant={variant}
			/>
		</SnackBarContext.Provider>
	);
};

export const useSnackBar = () => useContext(SnackBarContext);
SnackbarProvider.displayName = 'SnackbarProvider';
export default SnackbarProvider;
