import { FC, MouseEvent, Dispatch, SetStateAction } from 'react';
import { usePausedContext } from '@coUrbanize/community-modules';
import TemplateInstance from './TemplateInstance';
import DragDropContainer from '../../common/DragDropContainer';
import {
	Template,
	TemplateInstance as TemplateInstanceType,
} from 'types/types';
import { DropResult } from 'react-beautiful-dnd';
import { useUserContext } from 'providers/UserProvider';
import useProject from '../editProject/useProject';
import { AddSectionBlock } from './addSectionBlock';

interface EditorCanvasProps {
	templateInstancesByPage: TemplateInstanceType[];
	pageId: number;
	projectId: number;
	handleDragEnd: (res: DropResult) => void;
	handleDeleteTemplateInstance: (templateID: number) => void;
	anchorEl: HTMLButtonElement | null;
	setAnchorEl: (anch: HTMLButtonElement | null) => void;
	handleCreateTemplateInstance: (template: Template, ordinal?: number) => void;
	handleClick: (event: MouseEvent<HTMLButtonElement>, pickerId: string) => void;
	setTemplateInstances: (tis: TemplateInstanceType[]) => void;
	handlePickerClick: Dispatch<SetStateAction<string>>;
	pickerOpen: string;
}

export const EditorCanvas: FC<EditorCanvasProps> = (props) => {
	const {
		templateInstancesByPage,
		pageId,
		projectId,
		handleDragEnd,
		handleDeleteTemplateInstance,
		anchorEl,
		setAnchorEl,
		handleCreateTemplateInstance,
		handleClick,
		setTemplateInstances,
		handlePickerClick,
		pickerOpen,
	} = props;

	const { user } = useUserContext();
	const { project } = useProject(projectId);
	const { isPaused } = usePausedContext();

	if (templateInstancesByPage.length === 0) {
		return <AddSectionBlock handleClick={(e) => handleClick(e, 'Dashboard')} />;
	}

	if (!templateInstancesByPage || !project) return null;

	return (
		<DragDropContainer
			droppableId={pageId}
			items={templateInstancesByPage}
			keyExtractor={(TI) => TI.id}
			handleDragEnd={handleDragEnd}
			isDragDisabled={isPaused}
			DraggableComponent={(TI, handle) => (
				<TemplateInstance
					key={TI.id}
					templateInstance={TI}
					handle={handle}
					handleDeleteTemplateInstance={handleDeleteTemplateInstance}
					anchorEl={anchorEl}
					setAnchorEl={setAnchorEl}
					handleCreateTemplateInstance={handleCreateTemplateInstance}
					handleClick={handleClick}
					user={user}
					project={project}
					setTemplateInstances={setTemplateInstances}
					pickerId={TI.id}
					pickerOpen={pickerOpen}
					handlePickerClick={handlePickerClick}
				/>
			)}
		/>
	);
};
