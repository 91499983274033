import { FC, useEffect, useState } from 'react';
import { useClient, useMutation } from 'urql';
import { Card, CardMedia } from '@mui/material';
import { CloudUploadOutlined } from '@mui/icons-material';
import { Cell, Grid } from 'styled-css-grid';
import { usePausedContext } from '@coUrbanize/community-modules';
import { projectByIdQuery } from '../../../api/query/project.queries';
import { getUploadUrlMutation } from '../../../api/mutation/s3.mutations';
import { fileUploadApi } from '../../../api/apiClient';
import useImage from 'hooks/useImage';
import RegularButton from 'components/common/styled/RegularButton';
import { Project } from '../../../types/types';
import { useSnackBar } from 'providers/SnackBarProvider';
import DetailsUploadDialog from './DetailsUploadDialog';

interface ProjectProfileImageProps {
	initialValues: Project;
}

const ProjectProfileImage: FC<ProjectProfileImageProps> = (props) => {
	const { initialValues } = props;
	const [progress, setProgress] = useState(0);
	const [open, setOpen] = useState(false);

	const { createImage, updateImage } = useImage();
	const urqlClient = useClient();
	const { notify } = useSnackBar();

	const initialImage = initialValues.images.filter(
		(image) => image.primaryImage,
	)[0];

	const [profileImageUrl, setProfileImageUrl] = useState<any>(initialImage);

	const { isPaused } = usePausedContext();

	useEffect(() => {
		setProfileImageUrl(initialImage);
	}, [initialImage]);

	const [, getUploadUrl] = useMutation(getUploadUrlMutation);

	const handleClose = () => {
		setOpen(false);
	};

	const handleUploadClick = () => {
		setProgress(0);
		setOpen(true);
	};

	const uploadFilesToAws = async (files: FileList | null) => {
		if (!files) return;

		const file = files[0];
		const result = await getUploadUrl({
			folder: `${props.initialValues.slug}/primary`,
			filename: file.name,
		});

		if (result.error) return;

		const uploadURL = result.data.getUploadUrl;

		const res = await fileUploadApi(uploadURL, file, setProgress);
		if (!res) return notify('Failed to upload image!', 'error');
		const imageUrl = res.split('?')[0];

		try {
			const [projectResults] = await Promise.all([
				urqlClient
					.query(projectByIdQuery(), { projectId: initialValues.id })
					.toPromise(),
			]);
			const { project: persistedProject } = projectResults.data;
			const altTag = `${persistedProject.name} profile image`.trim();

			const primaryImage = persistedProject.images.filter(
				(x: any) => x.primaryImage === true,
			)[0];
			const newImageData = {
				imageUrl: imageUrl,
				fileName: file.name,
				primaryImage: true,
				projectId: initialValues.id,
				altTag: altTag,
			};
			if (primaryImage) {
				const updatedImage = { ...newImageData, id: primaryImage.id };
				await updateImage({ existingImageData: updatedImage });
				setProfileImageUrl(updatedImage);
			} else {
				const result = await createImage({ newImageData: newImageData });
				const { id } = result.data!;
				setProfileImageUrl({ ...newImageData, id: id });
			}

			setOpen(false);
		} catch (e) {
			notify('Profile image failed to save!', 'error');
		}
		notify('Project image saved');
	};
	return (
		<>
			<Grid columns={1}>
				<Cell>
					{profileImageUrl && (
						<Card
							sx={{ width: 'auto', aspectRatio: '16 / 9', height: '220px' }}
						>
							<CardMedia
								component="img"
								image={profileImageUrl.imageUrl}
								alt={profileImageUrl.altTag}
							/>
						</Card>
					)}
				</Cell>
				<Cell>
					<RegularButton
						color="secondary"
						variant="contained"
						startIcon={<CloudUploadOutlined />}
						onClick={handleUploadClick}
						disabled={!!isPaused}
					>
						Upload Project Image
					</RegularButton>
				</Cell>
			</Grid>

			<DetailsUploadDialog
				open={open}
				progress={progress}
				onClose={handleClose}
				onUpload={uploadFilesToAws}
			/>
		</>
	);
};

export default ProjectProfileImage;
