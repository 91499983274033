import React, { FC, CSSProperties } from 'react';
import { Avatar, Chip, Typography } from '@mui/material';
import { getAvatarColor } from 'utility/getAvatarColors';
import { CancelRounded } from '@mui/icons-material';

interface EmailItemProps {
	email: string;
	style?: CSSProperties;
	onClick?: () => void;
	onClose?: (email: string) => void;
	hideClose?: boolean;
}

const EmailItem: FC<EmailItemProps> = (props) => {
	const { email, onClick, hideClose, onClose } = props;

	return (
		<Chip
			avatar={
				<Avatar
					sx={{
						width: 20,
						height: 20,
						fontSize: 10,
						backgroundColor: getAvatarColor(email),
					}}
				>
					<Typography sx={{ color: '#fff' }}>
						{email[0].toUpperCase()}
					</Typography>
				</Avatar>
			}
			onClick={onClick}
			label={email}
			deleteIcon={<CancelRounded />}
			onDelete={hideClose ? undefined : () => onClose && onClose(email)}
		/>
	);
};

export default EmailItem;
