import { FC } from 'react';
import AppDialog from '../../common/Dialog';
import { Box, Grid, Typography } from '@mui/material';
import { FormBuilderFieldConfig } from 'types/formBuilderConfig';
import RegularButton from 'components/common/styled/RegularButton';

interface ResponseModalProps {
	open: boolean;
	onClose: () => void;
	fields: Array<FormBuilderFieldConfig>;
	responseJson: { [key: string]: any };
}

export const ResponseModal: FC<ResponseModalProps> = (props) => {
	const { responseJson, fields } = props;
	const { open, onClose } = props;
	return (
		<AppDialog
			open={open}
			title="Response"
			RightButton={
				<RegularButton
					variant="contained"
					onClick={onClose}
					color="secondary"
					sx={{ mr: 4 }}
				>
					Cancel
				</RegularButton>
			}
		>
			<Box width={700}>
				<Grid container>
					<Grid item xs={10}>
						{fields?.map((item: FormBuilderFieldConfig) => {
							if (item.label) {
								const answer = Array.isArray(responseJson[item.id])
									? responseJson[item.id].join(', ')
									: responseJson[item.id];
								return (
									<Box
										sx={{
											flexDirection: 'row',
											justifyContent: 'space-between',
										}}
										my={2}
										key={item.id}
									>
										<Typography sx={{ fontWeight: 'bold' }} variant="subtitle1">
											{item.label}
										</Typography>
										<Typography variant="subtitle2">{answer}</Typography>
										<hr />
									</Box>
								);
							}
							return null;
						})}
					</Grid>
				</Grid>
			</Box>
		</AppDialog>
	);
};
