import { FC, useEffect, useState } from 'react';
import { useModuleSaveData } from 'hooks/useModuleSaveData';
import ModuleInstanceData from 'types/moduleInstanceData';
import { Box, ToggleButton, ToggleButtonGroup } from '@mui/material';
import IframeUrlTab from './IframeUrlTab';
import IframeMarkupTab from './IframeMarkupTab';

export interface EmbedContent {
	url: string;
	title: string;
	width: number;
	height: number;
	permissions: Permissions;
}

const EmbedModule: FC<ModuleInstanceData> = (props) => {
	const { content, saveDataRef, config } = props;
	const defaultTab =
		content?.isIframeCode === null || content?.isIframeCode === undefined
			? true
			: content?.isIframeCode;

	const { updateModuleSaveData } = useModuleSaveData(saveDataRef);
	const [embedInfo, setEmbedInfo] = useState<any>(content);
	const [isIframeCode, setIsIframeCode] = useState(defaultTab);

	useEffect(() => {
		updateModuleSaveData({ content: { ...embedInfo, isIframeCode }, config });
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [updateModuleSaveData, config]);

	return (
		<div>
			<Box display="flex" flexDirection="row" justifyContent="center">
				<ToggleButtonGroup
					value={isIframeCode}
					exclusive
					onChange={(_, val) => setIsIframeCode(val)}
					aria-label="iframe input"
					size="small"
					sx={{ mb: 2 }}
					color="primary"
				>
					<ToggleButton
						sx={{ textTransform: 'capitalize' }}
						value={true}
						aria-label="iframe code"
					>
						Iframe HTML Markup
					</ToggleButton>
					<ToggleButton
						sx={{ textTransform: 'capitalize' }}
						value={false}
						aria-label="iframe url"
					>
						Iframe URL
					</ToggleButton>
				</ToggleButtonGroup>
			</Box>

			{isIframeCode ? (
				<IframeMarkupTab embedInfo={embedInfo} setEmbedInfo={setEmbedInfo} />
			) : (
				<IframeUrlTab embedInfo={embedInfo} setEmbedInfo={setEmbedInfo} />
			)}
		</div>
	);
};

export default EmbedModule;
