export const USER_TYPES = {
	INTERNAL: 'internal',
	DEVELOPER: 'developer',
	CLIENT: 'client',
	PARTNER: 'partner',
	PUBLIC: 'public',
	MACHINE: 'machine',
};

export const USER_STATUS = {
	ACTIVE: 'active',
	INACTIVE: 'inactive',
	ALL: 'all',
};

export const Filter_USER_TYPES = {
	INTERNAL: 'internal',
	CLIENT: 'client',
	ALL: 'all',
	PUBLIC: 'public'
};