import OutlinedButton from 'components/common/styled/OutlinedButton';
import MODULE_NAMES from 'constants/moduleNames';
import { MODULE_TYPE } from 'types/moduleTypes';
import { ModuleType } from 'types/types';

interface ModulePickerOptionProps {
	moduleType: ModuleType;
	instanceId: number;
	sectionId: number;
	handleClick: (
		id: ModuleType,
		templateInstanceId: number,
		sectionId: number,
		ordinal: number,
	) => void;
}

export const ModulePickerOption = ({
	moduleType,
	handleClick,
	sectionId,
	instanceId,
}: ModulePickerOptionProps) => {
	return (
		module && (
			<OutlinedButton
				variant="outlined"
				style={{
					width: '200px',
					height: '30px',
					marginBottom: '5px',
					fontSize: '12px',
				}}
				onClick={(e) => handleClick(moduleType, instanceId, sectionId, 1)}
			>
				{MODULE_NAMES[moduleType.name as MODULE_TYPE]}
			</OutlinedButton>
		)
	);
};
