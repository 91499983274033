import { Map } from './map';

export const FORM_BUILDER_FIELD_TYPE: Map = {
	SHORT_TEXT: 'Short Response',
	LONG_SUMMARY: 'Long Response',
	YES_NO: 'Yes/No',
	CHECKLIST: 'Checkboxes',
	CHOICE_SELECTION: 'Single Select',
	RANKED_CHOICE: 'Ranked Choice',
	HIDDEN: 'Hidden',
	DESCRIPTION: 'Description',
	DIVIDER: 'Divider',
	IMAGE: 'Image',
};
