import React, { useState, Ref, PropsWithChildren, useRef } from 'react';
import { cx, css } from '@emotion/css'; // Later : use material logic instead of this
import { useSlate, useSelected, useFocused, ReactEditor } from 'slate-react';
import { Transforms } from 'slate';

import MenuItem from '@mui/material/MenuItem';
import { default as MuiMenu } from '@mui/material/Menu';
import { Card, CardContent, Tooltip } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import { default as MuiButton } from '@mui/material/Button';
import LinkOffIcon from '@mui/icons-material/LinkOff';
import DeleteIcon from '@mui/icons-material/Delete';
import FormatSizeIcon from '@mui/icons-material/FormatSize';
import ConfirmationDialog from '../ConfirmationDialog';
import RegularTextInput from '../styled/RegularTextInput';

// Improve : use useSlateStatic for some methods
import {
	unwrapLink,
	isBlockActive,
	insertLink,
	toggleBlock,
	isMarkActive,
	toggleMark,
	changeSize,
	currentSize,
	clearFormat,
} from './helpers';
import { getTextWidth } from '../../../utility/getTextWidth';

interface BaseProps {
	className: string;
	[key: string]: unknown;
}
type OrNull<T> = T | null;

const TEXT_ALIGN_TYPES = ['left', 'center', 'right', 'justify'];

export const FontSizeComponent = () => {
	const editor = useSlate();
	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
	const open = Boolean(anchorEl);
	const handleClick = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};

	return (
		<>
			<MuiButton
				onClick={handleClick}
				size="medium"
				startIcon={<FormatSizeIcon />}
				sx={(theme) => ({
					color: !currentSize(editor)
						? theme.customPalette.textColors.light
						: theme.customPalette.basicColors.dark,
					display: 'inline-flex !important',
					marginLeft: '-10px !important',
					marginRight: '-14px !important',
					marginTop: '-12px !important',
				})}
			>
				{currentSize(editor) || 16}
			</MuiButton>
			<MuiMenu
				anchorEl={anchorEl}
				id="fontSize-menu"
				open={open}
				onClose={handleClose}
				onClick={handleClose}
			>
				{[16, 18, 24, 30, 36, 48, 64, 72, 96].map((size: number) => (
					<MenuItem key={size} onClick={() => changeSize(editor, size)}>
						{size}
					</MenuItem>
				))}
			</MuiMenu>
		</>
	);
};

export const Button = React.forwardRef(
	(
		{
			className,
			active,
			reversed,
			...props
		}: PropsWithChildren<
			{
				active: boolean;
				reversed: boolean;
			} & BaseProps
		>,
		ref: Ref<OrNull<HTMLSpanElement>>,
	) => (
		<span
			{...props}
			ref={ref as any}
			className={cx(
				className,
				css`
					cursor: pointer;
					color: ${reversed
						? active
							? 'white'
							: '#aaa'
						: active
						? 'black'
						: '#ccc'};
				`,
			)}
		/>
	),
);

export const Menu = React.forwardRef(
	(
		{ className, ...props }: PropsWithChildren<BaseProps>,
		ref: Ref<OrNull<HTMLDivElement>>,
	) => (
		<div
			{...props}
			ref={ref as any}
			className={cx(
				className,
				css`
					& > * {
						display: inline-block;
					}
					& > * + * {
						margin-left: 13px;
					}
				`,
			)}
		/>
	),
);
export const Toolbar = React.forwardRef(
	(
		{ className, ...props }: PropsWithChildren<BaseProps>,
		ref: Ref<OrNull<HTMLDivElement>>,
	) => (
		<Menu
			{...props}
			ref={ref as any}
			className={cx(
				className,
				css`
					padding: 8px 12px;
					margin: 0 -20px;
					margin-bottom: 5px;
					position: sticky;
					top: 0;
					background: #fff;
					z-index: 99;
				`,
			)}
		/>
	),
);

export const ImageComponent = ({ attributes, children, element }: any) => {
	const editor = useSlate();
	const path = ReactEditor.findPath(editor, element);

	const selected = useSelected();
	const focused = useFocused();

	const alignStyle = { display: 'flex', justifyContent: element.align };

	return (
		<div {...attributes} style={alignStyle}>
			{children}
			<div
				contentEditable={false}
				className={css`
					position: relative;
				`}
			>
				<img
					src={element.url}
					className={css`
						display: block;
						max-width: 100%;
						max-height: 20em;
						box-shadow: ${selected && focused ? '0 0 0 3px #B4D5FF' : 'none'};
					`}
					alt=""
				/>
				<Button
					active
					onClick={() => {
						Transforms.removeNodes(editor, { at: path });
					}}
					className={css`
						display: ${selected && focused ? 'inline' : 'none'};
						position: absolute;
						top: 0.5em;
						left: 0.5em;
						background-color: white;
					`}
				>
					<DeleteIcon />
				</Button>
			</div>
		</div>
	);
};

// Put this at the start and end of an inline component to work around this Chromium bug:
// https://bugs.chromium.org/p/chromium/issues/detail?id=1249405
const InlineChromiumBugfix = () => (
	<span
		contentEditable={false}
		className={css`
			font-size: 0;
		`}
	>
		${String.fromCodePoint(160) /* Non-breaking space */}
	</span>
);

export const LinkComponent = ({ attributes, children, element }: any) => {
	const selected = useSelected();
	const focused = useFocused();
	const editor = useSlate();
	const divRef = useRef<HTMLDivElement>(null);
	const slateEl =
		document && document.querySelector('.moduleInstanceMain.slateEditor');
	let leftPosition = 0;
	const textWidth = getTextWidth(element.url, '16px Nunito');
	let maxWidth = 500;
	if (divRef && divRef.current && slateEl) {
		const divRect = divRef.current.getBoundingClientRect();
		const slateRect = slateEl.getBoundingClientRect();
		maxWidth = slateRect.width;
		const currWidth = textWidth > maxWidth ? maxWidth : textWidth;
		// We are checking the posistion to make sure the link width does not overflow the main container .
		if (divRect.left > slateRect.left + slateRect.width / 2) {
			leftPosition = divRect.width - currWidth;
			if (leftPosition + divRect.left < slateRect.left)
				leftPosition = slateRect.left - divRect.left;
		} else {
			if (divRect.left + currWidth > slateRect.left + slateRect.width)
				leftPosition =
					slateRect.left + slateRect.width - (divRect.left + currWidth);
		}
	}
	return (
		<div style={{ position: 'relative', display: 'inline' }} ref={divRef}>
			<InlineChromiumBugfix />
			<a
				{...attributes}
				href={element.url}
				className={
					selected
						? css`
								box-shadow: 0 0 0 3px #ddd;
						  `
						: ''
				}
			>
				{children}
			</a>
			<InlineChromiumBugfix />
			{selected && focused && (
				<div
					style={{
						position: 'absolute',
						left: leftPosition,
						display: 'flex',
						alignItems: 'center',
						backgroundColor: 'white',
						padding: '6px 10px',
						gap: 10,
						borderRadius: 6,
						border: '1px solid lightgray',
						zIndex: 50,
						width: `${textWidth}px`,
						justifyContent: 'center',
						maxWidth,
					}}
				>
					<a
						style={{
							display: 'flex',
							alignItems: 'center',
							gap: 5,
							paddingRight: 10,
							borderRight: '1px solid lightgrey',
						}}
						href={element.url}
						rel="noreferrer"
						target="_blank"
					>
						{element.url}
					</a>
					<Tooltip title={'Remove Link'} placement="bottom" arrow>
						<IconButton
							size="small"
							onClick={() => unwrapLink(editor)}
							style={{
								padding: 3,
								color: '#747474',
								backgroundColor: 'transparent',
							}}
						>
							<LinkOffIcon />
						</IconButton>
					</Tooltip>
				</div>
			)}
		</div>
	);
};

const LinkButton = ({ icon }: any) => {
	const editor = useSlate();
	const [open, setOpen] = useState(false);
	const [url, setUrl] = useState('');
	const [error, setError] = useState('');
	const handleInsertLink = () => {
		if (url) {
			insertLink(editor, url);
			setOpen(false);
		} else {
			setError('Link is required');
		}
	};
	return (
		<>
			<Button
				active={isBlockActive(editor, 'link', 'type')}
				onMouseDown={(event: any) => {
					event.preventDefault();
					setOpen(true);
					setUrl('');
					setError('');
				}}
			>
				{icon}
			</Button>

			<ConfirmationDialog
				open={open}
				onConfirm={handleInsertLink}
				onCancel={() => setOpen(false)}
				disableBackdropClick
				title={'Enter Link URL'}
			>
				<Card variant="outlined" sx={{ borderRadius: 3 }}>
					<CardContent>
						For an <b>external site</b> link, paste a full URL including
						https://
						<br />
						<br />
						To link internally to a <b>page of this project</b>, enter the name
						of the page below.
					</CardContent>
				</Card>
				<RegularTextInput
					type="text"
					onChange={(e) => {
						setUrl(e?.target.value);
						if (e?.target.value) {
							setError('');
						}
					}}
					value={url}
					size="small"
					fullWidth
					sx={{ my: 2 }}
					error={!!error}
					helperText={error}
				/>
			</ConfirmationDialog>
		</>
	);
};

export const BlockButton = ({ format, icon, action }: any) => {
	const editor = useSlate();

	if (format === 'link') {
		return <LinkButton icon={icon} />;
	}

	return (
		<Button
			active={isBlockActive(
				editor,
				format,
				TEXT_ALIGN_TYPES.includes(format) ? 'align' : 'type',
			)}
			onMouseDown={(event: any) => {
				event.preventDefault();
				if (format === 'image') {
					action('insertImage');
				} else {
					toggleBlock(editor, format);
				}
			}}
		>
			{icon}
		</Button>
	);
};

export const MarkButton = ({ format, icon }: any) => {
	const editor = useSlate();
	return (
		<Button
			active={isMarkActive(editor, format)}
			onMouseDown={(event: any) => {
				event.preventDefault();
				toggleMark(editor, format);
			}}
		>
			{icon}
		</Button>
	);
};

export const ClearButton = ({ icon }: any) => {
	const editor = useSlate();
	return (
		<Button
			onMouseDown={(event: any) => {
				event.preventDefault();
				clearFormat(editor);
			}}
		>
			{icon}
		</Button>
	);
};
