import React, { ChangeEvent, useState, useEffect } from 'react';
import { Box, Checkbox, Typography } from '@mui/material';
import { DeleteOutlined as DeleteIcon } from '@mui/icons-material';
import { ImageContent, ImageProperty } from '../ImageModule';
import ImageDrop from '../../common/ImageDrop';
import ImageOptions from 'components/common/ImageOptions';
import RegularTextInput from 'components/common/styled/RegularTextInput';
import InfoOnHover from 'components/common/InfoOnHover';
import OutlinedButton from 'components/common/styled/OutlinedButton';

interface UploadImageProps {
	multiple?: boolean;
	progress: number;
	handleUpload: (files: FileList | null) => void;
	handleChange: (
		property: ImageProperty,
		event?: ChangeEvent | Event,
		data?: any,
	) => void;
	handleDelete?: () => void;
	imageContent?: ImageContent;
	setConfirmDisabled?: (n: boolean) => void;
	showLightBox?: boolean;
	showImageScale?: boolean;
	showImagePosition?: boolean;
}

const UploadImage: React.FC<UploadImageProps> = (props) => {
	const {
		progress,
		multiple,
		handleUpload,
		handleChange,
		handleDelete,
		imageContent,
		setConfirmDisabled,
		showLightBox,
		showImageScale,
		showImagePosition,
	} = props;
	const [imagePosition, setImagePosition] = useState<string | null | undefined>(
		imageContent?.boundingBoxPosition || 'center-center',
	);
	const [imageScale, setImageScale] = useState<string | null | undefined>(
		imageContent?.imageScale || 'fill',
	);
	const [errors, setErrors] = useState<{ imageDescription?: string }>({});

	useEffect(() => {
		if (imageContent?.imageUrl && imageContent?.altTag && setConfirmDisabled) {
			setConfirmDisabled(false);
		}
		if (
			(!imageContent?.imageUrl || !imageContent?.altTag) &&
			setConfirmDisabled
		) {
			setConfirmDisabled(true);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [imageContent?.altTag, imageContent?.imageUrl]);

	useEffect(() => {
		return () => setConfirmDisabled && setConfirmDisabled(false);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const validateAltTag = (value: string) => {
		if (!value) {
			setErrors((prevErrors) => ({
				...prevErrors,
				imageDescription: 'Image Description is required',
			}));
		} else {
			setErrors((prevErrors) => ({
				...prevErrors,
				imageDescription: '',
			}));
		}
	};

	return (
		<>
			<ImageDrop
				imageContent={imageContent}
				progress={progress}
				handleUpload={handleUpload}
				multiple={multiple}
			/>

			{imageContent?.imageUrl && (
				<Box display="flex" justifyContent="flex-end" pt={2}>
					<OutlinedButton startIcon={<DeleteIcon />} onClick={handleDelete}>
						<Typography fontWeight={500} textTransform="capitalize">
							Remove image
						</Typography>
					</OutlinedButton>
				</Box>
			)}

			<Box display="flex" flexDirection="column" alignItems="start">
				<Box display="inline-flex" gap="5px" alignItems="center" mt={1.5}>
					<Typography my={1}>Image Description</Typography>
					<InfoOnHover infoCase="imageDescription" />
				</Box>
				<RegularTextInput
					type="text"
					size="small"
					style={{ padding: 5, width: '100%' }}
					value={imageContent?.altTag}
					error={!!errors.imageDescription}
					helperText={errors.imageDescription}
					onChange={(e) => {
						handleChange('altTag', e);
						validateAltTag(e.target.value);
					}}
					onBlur={(e) => validateAltTag(e.target.value)}
				/>

				{showLightBox && (
					<Box display="flex" alignItems="center">
						<Checkbox
							checked={imageContent?.lightbox}
							onChange={(e) => handleChange('lightbox', e)}
							inputProps={{ 'aria-label': 'controlled' }}
						/>
						<Typography color="#6C6C6C" fontWeight="bold" fontSize={15} my={1}>
							Open in lightbox on click
						</Typography>
					</Box>
				)}

				<ImageOptions
					imagePosition={imagePosition}
					imageScale={imageScale}
					showImageScale={showImageScale}
					showImagePosition={showImagePosition}
					setImageScale={(imageScale) => {
						setImageScale(imageScale);
						handleChange('imageScale', undefined, imageScale);
					}}
					setImagePosition={(position) => {
						setImagePosition(position);
						handleChange('boundingBoxPosition', undefined, position);
					}}
				/>
			</Box>
		</>
	);
};

export default UploadImage;
