import { gql } from 'urql';

export const usersByProjectQuery = gql`
	query ($projectId: Int!) {
		usersByProject(projectId: $projectId) {
			receiveDigestEnabledDate
			user {
				id
				firstName
				lastName
				authUserId
				userType
				email
				registered
				lastLogin
				created
				projectDetails {
					projectId
					roleType
				}
				fullName
			}
		}
	}
`;

export const filteredUsers = gql`
	query (
		$userStatus: String!
		$userType: String!
		$searchTerm: String!
		$page: Int!
		$recordsPerPage: Int!
		$order: String
		$orderBy: String
	) {
		filteredUsers(
			userStatus: $userStatus
			userType: $userType
			searchTerm: $searchTerm
			page: $page
			recordsPerPage: $recordsPerPage
			order: $order
			orderBy: $orderBy
		) {
			id
			firstName
			lastName
			authUserId
			userType
			email
			registered
			lastLogin
			total
			created
			projectDetails {
				projectId
				roleType
			}
			projects {
				name
			}
			projectFollows {
				projectId
				project {
					id
					name
				}
			}
			fullName
			isActive
			isDeleted
		}
	}
`;

export const usersQuery = gql`
	query {
		users {
			id
			firstName
			lastName
			authUserId
			userType
			email
			registered
			created
			projectDetails {
				projectId
				roleType
			}
			fullName
		}
	}
`;

export const usersByUserTypeQuery = gql`
	query ($userType: String!) {
		usersByUserType(userType: $userType) {
			id
			firstName
			lastName
			authUserId
			userType
			email
			registered
			created
			projectDetails {
				projectId
				roleType
			}
			fullName
		}
	}
`;

export const usersByUserTypesQuery = gql`
	query ($userTypes: [String!]!) {
		usersByUserTypes(userTypes: $userTypes) {
			id
			firstName
			lastName
			authUserId
			userType
			email
			registered
			created
			projectDetails {
				projectId
				roleType
			}
			fullName
		}
	}
`;

export const userQuery = gql`
	query ($id: Int!) {
		author(id: $id) {
			id
			firstName
			lastName
			authUserId
			userType
			email
			registered
			lastLogin
			created
			projectDetails {
				projectId
				roleType
			}
			fullName
		}
	}
`;
