import { FC, useState, useRef } from 'react';
import { Box, CircularProgress, Typography } from '@mui/material';
import { TextFieldProps } from 'formik-mui';
import { useMutation } from 'urql';
import { fileUploadApi } from '../../api/apiClient';
import { getUploadUrlMutation } from '../../api/mutation/s3.mutations';
import TextButton from './styled/TextButton';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import { useSnackBar } from 'providers/SnackBarProvider';

interface FileFieldProps extends TextFieldProps {
	multiple: boolean;
	folder: string;
}

const FileField: FC<FileFieldProps> = (props) => {
	const [progress, setProgress] = useState(0);
	const inputRef = useRef<HTMLInputElement>();

	const {
		form: { setFieldValue },
		field: { name, value },
		multiple,
		folder,
	} = props;
	const [, getUploadUrl] = useMutation(getUploadUrlMutation);

	const { notify } = useSnackBar();
	const handleUpload = async (files: FileList | null) => {
		if (!files) return;

		const allFilesData = [];

		for (let i = 0; i < files.length; i++) {
			const file = files[i];
			const result = await getUploadUrl({
				folder: folder,
				filename: file.name,
			});

			if (result.error) return;

			const uploadURL = result.data.getUploadUrl;

			const res = await fileUploadApi(uploadURL, file, setProgress);
			if (!res) return notify('Failed to upload file!', 'error');
			const assetUrl = res.split('?')[0];

			allFilesData.push({ fileName: file.name, url: assetUrl });
		}

		if (multiple)
			setFieldValue(name, {
				files: [...value?.files, ...allFilesData],
			});
		else setFieldValue(name, allFilesData[0]);
	};

	const isUploading = progress > 0 && progress < 100;

	return (
		<>
			<input
				id="contained-button-file"
				type="file"
				style={{ display: 'none' }}
				onChange={(e) => handleUpload(e.target.files)}
				disabled={isUploading}
				multiple
				ref={(ref) => {
					inputRef.current = ref!;
				}}
			/>
			<label htmlFor="contained-button-file">
				<TextButton
					onClick={() => inputRef.current?.click()}
					variant="text"
					startIcon={<AttachFileIcon />}
					sx={{ p: '6px 10px', cursor: 'pointer' }}
					color="primary"
					disabled={isUploading}
				>
					Add File
				</TextButton>
				{isUploading && (
					<Box
						display="flex"
						alignItems="center"
						position="absolute"
						bottom={10}
						left={20}
						bgcolor="secondary.main"
						py={2}
						px={4}
						borderRadius={5}
						color="#FFF"
					>
						<CircularProgress
							variant="determinate"
							value={progress}
							color="inherit"
						/>
						<Typography ml={2}>
							Please don't close while the file is uploading
						</Typography>
					</Box>
				)}
			</label>
		</>
	);
};

export default FileField;
