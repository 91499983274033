import { Box, IconButton, TextField, Typography } from '@mui/material';
import { FC, ReactNode, useEffect, useState } from 'react';
import { DeleteOutline } from '@mui/icons-material';
import { Cell, Grid } from 'styled-css-grid';
import * as React from 'react';
import { useDeleteActionConfirmation } from '../../../hooks/useActionConfirmation';

interface QuestionChoiceProps {
	text: string;
	ordinal: number;
	onDeleteClick: () => void;
	onLabelUpdate: (label: string) => void;
	DragIconComponent: ReactNode;
	handle: any;
	choiceType?: string;
	smsChoiceNumber?: number;
}

export const QuestionChoice: FC<QuestionChoiceProps> = (props) => {
	const {
		text,
		onDeleteClick,
		onLabelUpdate,
		DragIconComponent,
		ordinal,
		handle,
		choiceType = 'option',
		smsChoiceNumber,
	} = props;
	const [choiceText, setChoiceText] = useState(text);
	const showDeleteConfirmation = useDeleteActionConfirmation();
	const handleClick = async () => {
		await showDeleteConfirmation(
			{
				title: 'Delete Field Option',
				message: 'Are you sure you want to delete this field option?',
			},
			() => onDeleteClick(),
		);
	};

	useEffect(() => {
		setChoiceText(text);
	}, [ordinal, text]);

	const handleOptionLabelBlur = (e: React.FocusEvent<HTMLInputElement>) => {
		onLabelUpdate(e.target.value);
	};

	const handleOptionLabelChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setChoiceText(e.target.value);
	};

	return (
		<Box overflow="hidden" marginBottom={2}>
			<Grid columnGap="0px" columns={'auto 36px'} areas={['main-area delete']}>
				<Cell area="main-area" middle>
					<Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
						<span {...handle}>{DragIconComponent}</span>
						<TextField
							sx={{ px: '10px', width: '80%' }}
							variant="standard"
							size="small"
							value={choiceText}
							onChange={handleOptionLabelChange}
							onBlur={handleOptionLabelBlur}
						/>
						{choiceType && (
							<Typography
								color="secondary"
								py={0.5}
								px={2}
								bgcolor="#EAF3F5"
								borderRadius={2}
								fontSize={11}
							>
								{choiceType === 'option' && smsChoiceNumber ? `${choiceType} - ${smsChoiceNumber}` : choiceType}
							</Typography>
						)}
					</Box>
				</Cell>
				<Cell area="delete" middle>
					<IconButton onClick={handleClick}>
						<DeleteOutline
							fontSize="small"
							sx={{ '&:hover': { color: 'red' } }}
						/>
					</IconButton>
				</Cell>
			</Grid>
		</Box>
	);
};
