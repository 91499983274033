import React from 'react';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import {
	Collapse,
	List,
	ListItemButton,
	ListItemIcon,
	ListItemText,
	SxProps,
	useTheme,
} from '@mui/material';
import { To, useNavigate } from 'react-router-dom';

export interface itemData {
	name: string | undefined;
	to: To;
	Icon?: any;
	subItem?: itemData[];
	nameStyle?: SxProps;
	isOpen?: boolean;
}

interface MenuItemProps {
	item: itemData;
	setActiveProject: (val: number) => void;
	open?: boolean;
	setOpen?: (open: boolean) => void;
}

const MenuItem: React.FC<MenuItemProps> = ({
	item,
	setActiveProject,
	open,
	setOpen,
}) => {
	const { name, to, Icon, subItem } = item;
	const active = window.location.pathname === to;
	const theme = useTheme();
	const navigate = useNavigate();

	const handleClick = () => {
		if (subItem && subItem?.length) {
			setOpen!(!open);
		} else {
			navigate(to);
		}
	};

	return (
		<>
			<ListItemButton
				sx={{
					width: '100%',
					borderRadius: '12px',
					mb: '8px',
					'&:hover': {
						background: theme.customPalette.primaryColors.grassGreen[5],
						color: theme.customPalette.primaryColors.grassGreen[1],
					},
					...(active
						? {
								background: theme.customPalette.primaryColors.grassGreen[5],
								color: theme.customPalette.primaryColors.grassGreen[1],
						  }
						: {}),
				}}
				onClick={handleClick}
			>
				{Icon && (
					<ListItemIcon>
						<Icon
							color={`${
								active ? theme.customPalette.primaryColors.grassGreen[1] : ''
							}`}
						/>
					</ListItemIcon>
				)}
				<ListItemText
					primary={name}
					primaryTypographyProps={{
						fontSize: '16px',
						fontWeight: 800,
						color: theme.customPalette.systemColors.unchecked,
					}}
				/>
				{subItem &&
					subItem?.length &&
					(open ? (
						<ExpandLess
							sx={{ color: theme.customPalette.primaryColors.grassGreen[1] }}
						/>
					) : (
						<ExpandMore
							sx={{ color: theme.customPalette.primaryColors.sky[1] }}
						/>
					))}
			</ListItemButton>
			{subItem && subItem?.length && (
				<Collapse
					in={open}
					timeout="auto"
					unmountOnExit
					style={{ width: '100%' }}
				>
					<List sx={{ marginLeft: '55px' }} component="nav" disablePadding>
						{subItem.map((item: itemData, index: number) => (
							<MenuItem
								item={item}
								key={index}
								setActiveProject={setActiveProject}
							/>
						))}
					</List>
				</Collapse>
			)}
		</>
	);
};

export default MenuItem;
