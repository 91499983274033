import { FC } from 'react';
import { Box } from '@mui/material';
import { useSearchParams } from 'react-router-dom';
import { usePausedContext } from '@coUrbanize/community-modules';

import useComments from 'components/features/commentsPage/useComments';
import CommentsView from './CommentsView';
import SpecificCommentView from './SpecificCommentView';
import Loading from 'components/common/Loading';

const Comments: FC = () => {
	const {
		projectId,
		user,
		theme,
		separatedComments,
		paginatedComments,
		search,
		filter,
		pageNumber,
		handleSearch,
		handleFilter,
		setSortBy,
		setPageNumber,
		addComment,
		saveComment,
		editComment,
		likeComment,
		moderateComment,
		updateCommentFetching,
		isLoading,
		semiFilteredSeparatedComments,
	} = useComments();

	const [params] = useSearchParams();
	const { isPaused } = usePausedContext();

	const commentId = params.get('commentId');

	if (isLoading) return <Loading />;

	return (
		<Box p={2} px={5}>
			{!commentId ? (
				<CommentsView
					projectId={projectId}
					user={user}
					theme={theme}
					semiFilteredSeparatedComments={semiFilteredSeparatedComments}
					separatedComments={separatedComments}
					paginatedComments={paginatedComments}
					search={search}
					filter={filter}
					pageNumber={pageNumber}
					handleSearch={handleSearch}
					handleFilter={handleFilter}
					setSortBy={setSortBy}
					setPageNumber={setPageNumber}
					addComment={addComment}
					saveComment={saveComment}
					editComment={editComment}
					likeComment={likeComment}
					moderateComment={moderateComment}
					disabled={isPaused}
				/>
			) : (
				<SpecificCommentView
					commentId={+commentId}
					projectId={projectId}
					updateCommentFetching={updateCommentFetching}
					separatedComments={separatedComments}
					user={user}
					filter={filter}
					addComment={addComment}
					saveComment={saveComment}
					editComment={editComment}
					likeComment={likeComment}
					moderateComment={moderateComment}
					disabled={isPaused}
				/>
			)}
		</Box>
	);
};

export default Comments;
