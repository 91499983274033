import { gql } from 'urql';

const defaultProjectQuery = `
            id
            name
            displayNameHeader
            address1
            address2
            city
            state
            country
            postalCode
            projectPhase
            slug
            disabled
            launchedDate
            pausedDate
            disabledDate
            archivedDate
            localizeProjectKey
            translationEnabledDate
            emailEnabledDate
            customUrl
            customUrlEnabledDate
            emailSandbox
            emailSandboxEnabledDate
            gaKey
            gaPropertyId
            showFeedbackCount
            images {
                id
                imageUrl
                fileName
                altTag
                primaryImage
            }
            primaryImageUrl
            pages {
                id
                name
                slug
            }
            developer {
                id
                developerName
                developerLogo
                developerAvatar
                developerUrl
            }
            siteFont
            smsSubscribeMobileNumber
            smsSubscribeEnableDate
            metaDescription
            aiInsightsEnabledDate
            autoAiInsightsEnabledDate
            aiInsightsLastExecutionDate
            aiInsightsCost
              `;

const defaultIsProjectPausedQuery = `
              id
              pausedDate
                `;

const defaultProjectSlugQuery = `
              id
              slug
              customUrl
              customUrlEnabledDate
                `;

const defaultProjectNoRelationsQuery = `
		id
		customUrl
		name
		slug
		customUrlEnabledDate
		disabledDate
		archivedDate
		primaryImageUrl
		`;

export const projectByIdQuery = (query: string = defaultProjectQuery) => gql`
    query($projectId: Int!){
        project(id: $projectId){
            ${query}
        }
    }`;

export const isProjectPausedQuery = (
	query: string = defaultIsProjectPausedQuery,
) => gql`
    query($projectId: Int!){
        project(id: $projectId){
            ${query}
        }
    }`;

export const projectSlugQuery = (
	query: string = defaultProjectSlugQuery,
) => gql`
    query($projectId: Int!){
        project(id: $projectId){
            ${query}
        }
    }`;

export const projectsQuery = (query: string = defaultProjectQuery) => gql`
  query {
		projects{
			${query}
		}
  }
`;

export const projectsNoRelationsQuery = (
	query: string = defaultProjectNoRelationsQuery,
) => gql`
	query {
		projectsNoRelations {
			${query}
		}
}`;

export const userProjectsWithRoleAndNoRoleQuery = gql`
	query ($userId: Int!) {
		userProjectsWithRoleAndNoRole(userId: $userId) {
			projectsWithRole {
				id
				name
			}
			projectsWithNoRole {
				id
				name
			}
		}
	}
`;

export const userFollowingProjectsQuery = gql`
	query ($userId: Int!) {
		userFollowingProjects(userId: $userId) {
			id
			name
		}
	}
`;
