import { FC } from 'react';
import { Box, Button, Divider, Typography, useTheme } from '@mui/material';
import { DeleteOutline, Send } from '@mui/icons-material';
import { confirm } from 'react-confirm-box';

interface UserManagementProps {
	user?: any;
	onClose: () => void;
	onUserUpdate: (user: any) => void;
	onResetPassword: (email: string) => void;
}

const UserManagementForm: FC<UserManagementProps> = (props) => {
	const theme = useTheme();
	const onClick = async (options: any) => {
		props.onClose();
		const result = await confirm(
			'Are you sure want to delete this user?',
			options,
		);
		if (result) {
			props.onUserUpdate({ ...props.user, isDeleted: true });
			return;
		}
	};

	return (
		<Box sx={{ display: 'flex', flexDirection: 'column' }}>
			<Box sx={{ display: 'flex', alignItems: 'center' }} mb={3}>
				<div>
					<Typography sx={{ fontSize: '13px' }}>Password Reset</Typography>
					<Typography
						fontStyle="italic"
						sx={{ width: '70%', marginTop: '5px' }}
					>
						Send User a password reset link to begin updating their password.
					</Typography>
				</div>
				<div>
					<Button
						size="small"
						type="submit"
						startIcon={<Send />}
						sx={{
							backgroundColor: '#bbbaba',
							padding: '10px',
							color: 'white',
							width: '200px',
							'&:hover': {
								border: '1px solid #bbbaba',
								color: '#bbbaba',
								backgroundColor: theme.customPalette.basicColors.light,
							},
						}}
						onClick={() => props.onResetPassword(props.user.email)}
					>
						Password Reset
					</Button>
				</div>
			</Box>
			<Divider variant="middle" />
			<Box sx={{ display: 'flex', alignItems: 'center' }} mt={3}>
				<div>
					<Typography sx={{ fontSize: '13px' }}>Delete User</Typography>
					<Typography
						fontStyle="italic"
						sx={{ width: '70%', marginTop: '5px' }}
					>
						Permanently deletes User from the system and removes any identifying
						submitted content.
					</Typography>
				</div>
				<div>
					<Button
						size="small"
						type="submit"
						startIcon={<DeleteOutline />}
						onClick={onClick}
						sx={{
							backgroundColor: '#FF6347',
							padding: '10px',
							color: 'white',
							width: '200px',
							'&:hover': {
								border: '1px solid #FF6347',
								color: '#FF6347',
								backgroundColor: theme.customPalette.basicColors.light,
							},
						}}
					>
						Delete User
					</Button>
				</div>
			</Box>
		</Box>
	);
};

export default UserManagementForm;
