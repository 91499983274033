import { FC } from 'react';
import { Box, InputLabel, TextField } from '@mui/material';
import { Page } from 'types/types';

interface EditPageProps {
	page: Page;
	handleFieldChange: (e: any) => void;
	handleFieldBlur: (e: any) => void;
	errors: any;
}

export const EditPage: FC<EditPageProps> = (props) => {
	const { page, handleFieldChange, handleFieldBlur, errors } = props;

	const isHomeOrUpdatesPage = ['home', 'updates'].includes(page?.slug);

	return (
		<Box sx={{ width: '100%', mt: 3 }}>
			<InputLabel htmlFor="name">Page Name</InputLabel>
			<TextField
				id="name"
				variant="outlined"
				size="small"
				value={page?.name}
				sx={{ width: '100%', mb: 1.5 }}
				onChange={handleFieldChange}
				onBlur={handleFieldBlur}
				error={!!errors.name}
				helperText={errors.name}
			/>
			<InputLabel htmlFor="slug">Page Slug</InputLabel>
			<TextField
				id="slug"
				variant="outlined"
				size="small"
				value={page?.slug}
				sx={{ width: '100%', mb: 1.5 }}
				onChange={handleFieldChange}
				onBlur={handleFieldBlur}
				error={!!errors.slug}
				helperText={
					isHomeOrUpdatesPage
						? 'Page slug can not be changed for home or updates pages.'
						: errors.slug
				}
				disabled={isHomeOrUpdatesPage}
			/>
			<InputLabel htmlFor="externalUrlOverride">
				External Url Override
			</InputLabel>
			<TextField
				id="externalUrlOverride"
				variant="outlined"
				size="small"
				value={page?.externalUrlOverride}
				sx={{ width: '100%' }}
				onChange={handleFieldChange}
				onBlur={handleFieldBlur}
				error={!!errors.externalUrlOverride}
				helperText={
					isHomeOrUpdatesPage
						? 'Page external url can not be changed for home or updates pages.'
						: errors.externalUrlOverride
				}
				disabled={isHomeOrUpdatesPage}
			/>
		</Box>
	);
};
