import React, { FC, useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { isArray } from 'lodash';
import produce from 'immer';
import { useModuleSaveData } from '../../../hooks/useModuleSaveData';
import {
	FormBuilderConfig,
	FormBuilderFieldConfig,
} from '../../../types/formBuilderConfig';
import { GenericModuleProps } from '../GenericModule';
import { FormConfigure } from './FormConfigure';
import { FieldConfigure } from './FieldConfigure';
import useNumberParams from 'hooks/useNumberParams';
import { FORM_BUILDER_FIELD_TYPE } from '../../../types/formBuilderFieldTypes';
import { getObjectKey } from '../../../utility/getObjectKey';
import { useMutation } from 'urql';
import { removeFileMutation } from '../../../api/mutation/s3.mutations';

const defaultProps: GenericModuleProps = {
	config: null,
	content: null,
};

interface FormModuleContextType {
	setCurrentField: React.Dispatch<
		React.SetStateAction<FormBuilderFieldConfig | null>
	>;
	setFormTitle: React.Dispatch<React.SetStateAction<string>>;
	setIsNewField: React.Dispatch<React.SetStateAction<boolean>>;
}

interface FormModuleProps extends GenericModuleProps {
	setFormTitle: React.Dispatch<React.SetStateAction<string>>;
	setDisabled: (confirmDisabled: boolean) => void;
	mostRecentFormFields: any;
}

export const FormModuleContext = React.createContext<FormModuleContextType>({
	setCurrentField: () => {},
	setFormTitle: () => {},
	setIsNewField: () => {},
});

const imageDefault = {
	imageUrl: '',
	fileName: '',
	altTag: '',
	aspectRatio: 0,
	lightbox: false,
};

const formImageDefault = {
	fileName: '',
	imageUrl: '',
	imageScale: 'fill',
	boundingBoxPosition: 'middle-center',
};

const FormModule: FC<FormModuleProps> = (props) => {
	const content = props.content as FormBuilderConfig;
	const { saveDataRef, setFormTitle } = props;
	const { updateModuleSaveData } = useModuleSaveData(saveDataRef);
	const [, removeImage] = useMutation(removeFileMutation);
	const { projectId } = useNumberParams();
	const ref = saveDataRef?.current?.content ?? { fields: [] }; // set fields on ref as it's assumed to exist below

	//determines which screen is shown
	const [currentField, setCurrentField] =
		useState<FormBuilderFieldConfig | null>(null);
	// Below fallback ( ref.fields ) is to handle old format of fields , the save action then will update to new format
	const [fields, setFields] = useState<Array<FormBuilderFieldConfig>>(
		(isArray(props.mostRecentFormFields) && props.mostRecentFormFields) ||
			ref?.fields,
	);
	const [formConfigState, setFormConfigState] = useState({});
	const [isNewField, setIsNewField] = useState(false);
	const [suggestions, setSuggestions] = useState<string[]>([]);

	const handleRemoveField = (ordinal: number) => {
		setCurrentField(null);
		const newFields = produce(fields, (newState) => {
			//find field index and remove it from array
			const fieldIndex: number = newState.findIndex(
				(f) => f.ordinal === ordinal,
			);

			const fieldToDelete = newState.find((f) => f.ordinal === ordinal);

			if (!!fieldToDelete && fieldToDelete.image) {
				const { imageUrl } = fieldToDelete.image;
				try {
					const objectKey = getObjectKey(imageUrl);
					removeImage({ objectKey }).then((r) => console.log(r.error));
				} catch (error) {
					console.error('Remove image failed: ', error);
				}
			}

			newState.splice(fieldIndex, 1);

			//modify ordinals of remaining fields
			for (const f of newState) {
				if (f.ordinal > ordinal) {
					f.ordinal = f.ordinal - 1;
				}
			}
		});
		setFields(newFields);

		const obj = {
			...ref,
			fields: newFields,
		};
		updateConfig(obj);
	};

	const handleAddNewField = (fieldType: string) => {
		const newField = {
			id: uuidv4(),
			type: fieldType,
			description: '',
			label: '',
			validationType: 'none',
			groupName: '',
			labelHasImage: false,
			labelImage: { ...imageDefault },
			image: { ...formImageDefault },
			ordinal: fields.length + 1,
			isRequired: false,
			descriptionField: null,
		};

		const newFields = produce(fields, (newState) => {
			newState.push(newField);
		});
		setFields(newFields);
		if (fieldType !== 'DIVIDER') {
			setCurrentField(newField);
			setIsNewField(true);
			setFormTitle(`Add ${FORM_BUILDER_FIELD_TYPE[fieldType]}`);
		}

		const obj = {
			...ref,
			fields: newFields,
		};
		updateConfig(obj);
	};

	const handleFormNameChange = (name: string) => {
		updateSaveData({ name });
	};
	const handleFormProcessorTypeChange = (type: string) => {
		updateSaveData({ formProcessorType: type });
	};

	const handleFieldChange = (fieldData: any) => {
		const newField = produce(currentField, (newState) => {
			newState!.label = fieldData.label;
			newState!.isRequired = fieldData.isRequired;
			newState!.hasWriteInOption = fieldData.hasWriteInOption;
			newState!.validationType = fieldData.validationType;
			newState!.descriptionField = fieldData.descriptionField;
			newState!.description = fieldData.description;
			newState!.hiddenValue = fieldData.hiddenValue;
			newState!.groupName = fieldData.groupName;
			newState!.labelImage = fieldData.labelImage;
			newState!.labelHasImage = fieldData.labelHasImage;
			newState!.image = fieldData.image;
			newState!.choices = fieldData.choices;
		});
		const newFields = produce(fields, (newState) => {
			const fieldIndex = fields.findIndex(
				(f) => f.ordinal === newField?.ordinal,
			);
			newState[fieldIndex].label = fieldData.label;
			newState[fieldIndex].isRequired = fieldData.isRequired;
			newState[fieldIndex].hasWriteInOption = fieldData.hasWriteInOption;
			newState[fieldIndex].validationType = fieldData.validationType;
			newState[fieldIndex].descriptionField = fieldData.descriptionField;
			newState[fieldIndex].description = fieldData.description;
			newState[fieldIndex].hiddenValue = fieldData.hiddenValue;
			newState[fieldIndex].groupName = fieldData.groupName;
			newState[fieldIndex].labelImage = fieldData.labelImage;
			newState[fieldIndex].labelHasImage = fieldData.labelHasImage;
			newState[fieldIndex].image = fieldData.image;
			newState[fieldIndex].choices = fieldData.choices;
		});
		setCurrentField(newField);
		setFields(newFields);
		const obj = {
			...ref,
			fields: newFields,
		};
		updateConfig(obj);
	};

	const handleFieldSort = (sourceIndex: number, destinationIndex: number) => {
		const newFields = produce(fields, (newFields) => {
			let movedField = newFields.splice(sourceIndex, 1)[0];

			newFields.splice(destinationIndex, 0, movedField);
		});
		setFields(newFields);

		const obj = {
			...ref,
			fields: newFields,
		};
		updateConfig(obj);
	};

	const updateConfig = (updatedContent: any) => {
		updateModuleSaveData({
			content: updatedContent,
			config: props.config,
		});
	};

	const updateSaveData = (props: any) => {
		const updatedConfig = produce(formConfigState, (newConfig: any) => {
			for (const key of Object.keys(props)) {
				newConfig[key] = props[key];
			}
		});
		const obj = {
			...ref,
			...updatedConfig,
			fields,
		};
		updateConfig(obj);
		setFormConfigState(updatedConfig);
	};

	useEffect(() => {
		const newSuggestions: string[] = [];
		if (fields?.length) {
			fields.forEach((item) => {
				if (item?.groupName && !newSuggestions.includes(item?.groupName))
					newSuggestions.push(item?.groupName);
			});
		}
		setSuggestions(newSuggestions);
	}, [fields]);

	useEffect(() => {
		if (!content?.formProcessorType) {
			updateSaveData({
				formProcessorType: 'general-submission',
				projectId: projectId,
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<FormModuleContext.Provider
			value={{ setCurrentField, setFormTitle, setIsNewField }}
		>
			{currentField ? (
				<FieldConfigure
					description={currentField.description}
					groupName={currentField.groupName}
					validationType={currentField.validationType}
					label={currentField.label}
					labelHasImage={currentField.labelHasImage}
					labelImage={currentField.labelImage ?? undefined}
					type={currentField.type}
					ordinal={currentField.ordinal}
					hasWriteInOption={currentField.hasWriteInOption}
					isRequired={currentField.isRequired}
					image={currentField.image}
					descriptionField={currentField.descriptionField}
					hiddenValue={currentField.hiddenValue}
					onFieldChange={handleFieldChange}
					choices={currentField.choices!}
					suggestions={suggestions}
					config={props.config}
					setShowCancel={(show: boolean) => props.setShowCancel?.(show)}
					setShowConfirm={(show: boolean) => props.setShowConfirm?.(show)}
					setFormTitle={setFormTitle}
					isNewField={isNewField}
					onFieldRemove={handleRemoveField}
				/>
			) : (
				<FormConfigure
					onFormNameChange={handleFormNameChange}
					onAddField={handleAddNewField}
					onFieldRemove={handleRemoveField}
					onFieldSort={handleFieldSort}
					name={ref?.name || content?.name}
					fields={fields}
					formType={ref?.formProcessorType || content?.formProcessorType}
					handleFormProcessorTypeChange={handleFormProcessorTypeChange}
					setShowCancel={(show: boolean) => props.setShowCancel?.(show)}
					setShowConfirm={(show: boolean) => props.setShowConfirm?.(show)}
					setDisabled={props.setDisabled}
				/>
			)}
		</FormModuleContext.Provider>
	);
};

FormModule.defaultProps = defaultProps;
export { FormModule };
