import React, { FC } from 'react';
import { useEnv } from '../../../providers/EnvProvider';
import { useLoadScript } from '@react-google-maps/api';

interface Props {
	children?: React.ReactNode;
}

interface ScriptLoaderOutput {
	isLoaded: boolean;
	loadError: Error | undefined;
}

const defaultProps: Props = {};
export const MapScriptLoader = React.createContext<ScriptLoaderOutput>({
	isLoaded: false,
	loadError: undefined,
});

export const useMapScriptLoader = () => {
	return React.useContext(MapScriptLoader);
};

const config = {
	id: 'google-map-script',
	libraries: ['places'] as (
		| 'places'
		| 'drawing'
		| 'geometry'
		| 'localContext'
		| 'visualization'
	)[],
};

const MapScriptLoaderProvider: FC<Props> = (props) => {
	const { googleApiKey } = useEnv(),
		{ isLoaded, loadError } = useLoadScript({
			googleMapsApiKey: googleApiKey,
			...config,
		});

	if (loadError) {
		console.log(`could not load google map external script: ${loadError}`);
	}

	return (
		<MapScriptLoader.Provider value={{ isLoaded, loadError }}>
			{props.children}
		</MapScriptLoader.Provider>
	);
};

MapScriptLoaderProvider.defaultProps = defaultProps;

export { MapScriptLoaderProvider };
