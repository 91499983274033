import { gql } from 'urql';

export const updateByProjectQuery = gql`
	query ($projectId: Int!) {
		updatesByProjectId(projectId: $projectId) {
			id
			title
			plainText
			richText
			published
			modified
			created
			images {
				id
				fileName
				imageUrl
			}
		}
	}
`;
