// ==============================|| OVERRIDES - BADGE ||============================== //

import { Theme } from '@mui/material';

export default function Badge(theme: Theme) {
	return {
		MuiBadge: {
			styleOverrides: {
				root: {
					'& .MuiBadge-badge': {
						color: theme.customPalette.primaryColors.grassGreen[1],
						backgroundColor: theme.customPalette.primaryColors.grassGreen[5],
						border: '1px solid #9CC3C1',
					},
				},
			},
		},
	};
}
