import { useEffect, useState, Dispatch, SetStateAction } from 'react';
import produce from 'immer';
import { useMutation } from 'urql';
import { DropResult } from 'react-beautiful-dnd';
import { Project, TemplateInstance } from '../../../../types/types';
import { ModuleInstance, ModuleType } from '../../../../types/types';
import {
	createModuleInstanceMutation,
	removeModuleInstanceMutation,
	updateModuleInstanceMutation,
} from '../../../../api/mutation/module.mutation';
import contentLoader from 'components/features/moduleInstanceList/utils/contentLoader';
import { MODULE_TYPE } from 'types/moduleTypes';
import { useAuth0 } from '@auth0/auth0-react';

const useModuleInstance = (
	templateInstance: TemplateInstance,
	setTemplateInstances: Dispatch<SetStateAction<TemplateInstance[]>>,
	sectionId: number,
	project: Project,
) => {
	const [modulesBySection, setModulesBySection] = useState<ModuleInstance[]>(
		[],
	);

	const [, createModuleInstance] = useMutation(
		createModuleInstanceMutation('id ordinal'),
	);
	const [, removeModuleInstance] = useMutation(removeModuleInstanceMutation);
	const [, updateModuleInstance] = useMutation(updateModuleInstanceMutation);

	const { getAccessTokenSilently } = useAuth0();

	useEffect(() => {
		const moduleInstances = templateInstance.moduleInstances;

		const modulesBySection = moduleInstances?.filter(
			(m) => m.config.sectionId === sectionId,
		);

		const orderedModulesBySection = modulesBySection.sort(
			(a, b) => (a.ordinal || 0) - (b.ordinal || 0),
		);

		const modulePromises = orderedModulesBySection?.map(async (module) => {
			const content = await contentLoader(
				module.content,
				module.moduleType.name as MODULE_TYPE,
				getAccessTokenSilently,
				project,
			);
			return { ...module, content };
		});

		if (modulePromises)
			Promise.all(modulePromises).then((res) => setModulesBySection(res));
	}, [templateInstance, project, sectionId, getAccessTokenSilently]);

	const handleCreateModuleInstance = async (
		moduleType: ModuleType,
		templateInstanceId: number,
		sectionId: number,
		config: object,
	) => {
		const configWithSectionId = { ...config, sectionId };
		const result = await createModuleInstance({
			newModuleInstanceData: {
				templateInstanceId: templateInstanceId,
				moduleTypeId: moduleType.id,
				config: JSON.stringify(configWithSectionId),
			},
		});

		const newModule = {
			id: result.data?.createModuleInstance?.id,
			content: null,
			templateInstanceId: templateInstanceId,
			moduleTypeId: moduleType.id,
			moduleType: moduleType,
			config: configWithSectionId,
			isOpen: true,
		};

		// update template instance
		setTemplateInstances((oldState) =>
			produce(oldState, (newState) => {
				const index = newState.findIndex((TI) => TI.id === templateInstance.id);
				newState[index].moduleInstances = [
					// @ts-ignore
					...newState[index].moduleInstances,
					// @ts-ignore
					{ ...newModule, ordinal: result.data?.createModuleInstance?.ordinal },
				];
			}),
		);

		if (result.error) {
			console.error('Error creating module instance', result.error);
		}
	};

	const handleUpdateModule = (
		id: number,
		newContent?: any,
		anchorId?: string,
	) => {
		// update template instance
		setTemplateInstances((oldState) =>
			produce(oldState, (newState) => {
				const tIndex = newState.findIndex(
					(TI) => TI.id === templateInstance.id,
				);
				const mIndex = newState[tIndex].moduleInstances.findIndex(
					(MI) => MI.id === id,
				);
				newState[tIndex].moduleInstances[mIndex].content = newContent;
				newState[tIndex].moduleInstances[mIndex].anchorId = anchorId;
			}),
		);
	};

	const handleDeleteModuleInstance = async (moduleInstanceId: number) => {
		try {
			const res = await removeModuleInstance({ id: moduleInstanceId });
			if (res.error) return;

			setTemplateInstances((oldState) =>
				produce(oldState, (newState) => {
					const index = oldState.findIndex(
						(TI) => TI.id === templateInstance.id,
					);
					const updatedModules = newState[index].moduleInstances.filter(
						(MI) => MI.id !== moduleInstanceId,
					);
					newState[index].moduleInstances = updatedModules;
				}),
			);
		} catch (_) {}
	};

	const handleDragEnd: (
		result: DropResult,
		templateSectionId: number,
	) => void = (result: DropResult) => {
		if (!result.destination) return;

		const { index: destinationIndex } = result.destination;

		//reorder in array
		const sortedModules = [...modulesBySection];
		const moved = sortedModules.splice(result.source.index, 1)[0];
		sortedModules.splice(destinationIndex, 0, moved);

		const orderedSortedModules = sortedModules.map((mi, index) => {
			return { ...mi, ordinal: index + 1 };
		});

		// we change order only in the moduleBySection state -> no need to update the templateInstance
		// Note 2 , since we are updating the template instances modules on module create we have to update the order too here to keep the values same , TODO : make sure there is one source of truth ( use one state instead of two)
		setTemplateInstances((oldState) =>
			produce(oldState, (newState) => {
				const index = newState.findIndex((TI) => TI.id === templateInstance.id);
				newState[index].moduleInstances = newState[index].moduleInstances.map(
					(mi) => {
						const sortedMi = orderedSortedModules.find((i) => i.id === mi.id);
						return sortedMi ? sortedMi : mi;
					},
				);
			}),
		);
		setModulesBySection(orderedSortedModules);

		sortedModules.forEach(async (mi: ModuleInstance, index: number) => {
			await updateModuleInstance({
				existingModuleInstanceData: {
					id: mi.id,
					ordinal: index + 1,
				},
			});
		});
	};

	return {
		modulesBySection,
		handleDragEnd,
		handleCreateModuleInstance,
		handleUpdateModule,
		handleDeleteModuleInstance,
	};
};

export default useModuleInstance;
