import { FC, useState, useMemo } from 'react';
import _ from 'lodash';
import {
	DeleteOutline as DeleteOutlineIcon,
	DragIndicator as DragIndicatorIcon,
	Visibility as VisibilityIcon,
	VisibilityOff as VisibilityOffIcon,
	Rule,
	Comment as CommentIcon,
} from '@mui/icons-material';
import {
	Button,
	Chip,
	IconButton,
	Table,
	TableBody,
	TableCell,
	TableRow,
	Tooltip,
	useTheme,
} from '@mui/material';
import {
	Question as QuestionType,
	QuestionAnswer,
	QuestionChoice,
} from '../../../types/types';
import { TitleCaseHyphenatedWord } from '../../../utility/titleCaseHyphenatedWord';
import { useDeleteActionConfirmation, useSaveActionConfirmation } from '../../../hooks/useActionConfirmation';
import EditIcon from '../../icons/EditIcon';
import { QuestionType as QTEnum } from './QuestionGroup';
import AppDialog from '../../common/Dialog';
import { uniqBy, partition } from 'lodash';
import { toFixedIfNecessary } from '../../../utility/toFixedIfNecessary';
import { questionAnswersWithoutDuplicates } from '../../../utility/questionAnswersWithoutDuplicates';

interface QuestionItemProps {
	groupId: number;
	question: QuestionType;
	status: string;
	disabled?: boolean;
	onDelete: (questionGroupId: number, questionId: number) => void;
	onHideToggle: (questionGroupId: number, questionId: number) => void;
	onEdit: (question: QuestionType) => void;
	onToggleDrag: (enabled: boolean) => void;
	onQuestionItemCountClick: (groupId: number, questionId: number) => void;
	commentCountPerQuestionId: any;
}

export const QuestionItem: FC<QuestionItemProps> = (props) => {
	const {
		question,
		onDelete,
		onHideToggle,
		groupId,
		onToggleDrag,
		onEdit,
		onQuestionItemCountClick,
		disabled,
		commentCountPerQuestionId,
	} = props;

	const [modalOpen, setModalOpen] = useState(false);
	let theme = useTheme();
	const showDeleteConfirmation = useDeleteActionConfirmation();
	const showSaveConfirmation = useSaveActionConfirmation();
	const handleClick = async () => {
		await showDeleteConfirmation(
			{
				title: 'Delete Question',
				message: 'Are you sure you want to remove this question?',
			},
			() => onDelete(groupId, question.id),
		);
	};

	const handleHideClick = async () => {
		await showSaveConfirmation({
			title: `${question.hidden ? 'Unhide' : 'Hide'} Question`,
			message: `Are you sure you want to ${question.hidden ? 'unhide' : 'hide'} this question?`
		}, () => onHideToggle(groupId, question.id));
	};

	const questionTypeText = TitleCaseHyphenatedWord.transform(
		question.type === QTEnum.MULTIPLE_CHOICE ? 'Checkboxes' : question.type,
	);

	const questionWithNoComments = [
		QTEnum.SINGLE_SELECT,
		QTEnum.MULTIPLE_CHOICE,
		QTEnum.RANKED_CHOICE,
	].find((t) => t === question.type);

	const commentsCount = commentCountPerQuestionId[question.id] || 0;
	const answersByQuestion = questionAnswersWithoutDuplicates(
		question.questionAnswers || [],
	);

	//We want to group by created and question_id because multi-choice and ranked choice can have many question answers per response
	const allAnswersCount = Object.keys(_.groupBy(answersByQuestion, (item) => `${item.createdBy}-${item.questionId}`)).length || 0;

	const questionChoices = useMemo(() => {
		const sortableQuestionsChoices: QuestionChoice[] = question?.questionChoices
			? JSON.parse(JSON.stringify(question.questionChoices))
			: [];

		const [writeInChoices, optionChoices] = partition(sortableQuestionsChoices, (choice) => { return choice.choiceType === 'writeIn'; });
		const writeInAnswers = answersByQuestion.filter(
			(answer) => writeInChoices.some(choice => choice.id === answer.questionChoiceId)
		);
		const uniqueWriteInAnswers = uniqBy(writeInAnswers, 'questionChoiceText');


		uniqueWriteInAnswers.forEach((answer) => {
			optionChoices.push({
				choiceText: answer.questionChoiceText,
				choiceType: 'write-in',
				id: answer.questionChoiceId!,
			} as QuestionChoice);
		});

		return optionChoices;
	}, [question, answersByQuestion]);

	return (
		<>
			<TableCell
				sx={{ border: 'unset' }}
				width="5%"
				onMouseEnter={() => onToggleDrag(true)}
				onMouseLeave={() => onToggleDrag(false)}
			>
				<DragIndicatorIcon />
			</TableCell>
			<TableCell sx={{ border: 'unset' }} width="12%">
				<Chip
					variant="outlined"
					label={questionTypeText}
					sx={{
						fontSize: '16px',
						color: `${question.hidden ? '#5C5C5C' : '#00645F'}`,
						backgroundColor: `${question.hidden ? '#DFDFDF' : '#D0E2E2'}`,
						borderColor: `${question.hidden ? '#5C5C5C' : '#9CC3C1'}`,
					}}
				/>
			</TableCell>
			<TableCell
				sx={{
					fontWeight: '500',
					fontSize: '16px',
					lineHeight: '24px',
					border: 'unset',
					color: `${question.hidden ? theme.customPalette.textColors.light : theme.customPalette.textColors.dark}`,
				}}
				width="63%"
			>
				{question.questionText}
			</TableCell>
			<TableCell
				sx={{
					fontWeight: '500',
					fontSize: '16px',
					lineHeight: '24px',
					border: 'unset',
					cursor: 'pointer',
				}}
				width="7%"
			>
				<Tooltip
					title={
						questionWithNoComments
							? `${allAnswersCount} Poll Response${allAnswersCount !== 1 ? 's' : ''
							}`
							: `${commentsCount} Comment${Number(commentsCount) !== 1 ? 's' : ''
							}`
					}
				>
					<Button
						onClick={
							questionWithNoComments
								? () => setModalOpen(true)
								: () => onQuestionItemCountClick(groupId, question.id)
						}
					>
						{questionWithNoComments ? (
							<>
								{allAnswersCount} <Rule sx={{ marginLeft: '3px' }} />
							</>
						) : (
							<>
								{commentsCount} <CommentIcon sx={{ marginLeft: '3px' }} />
							</>
						)}
					</Button>
				</Tooltip>
			</TableCell>
			<TableCell sx={{ border: 'unset', paddingX: '0px' }} width="13%" align="right">
				<Tooltip title={'Edit Question'} placement="bottom" arrow={true}>
					<IconButton disabled={!!disabled} onClick={() => onEdit(question)}>
						<EditIcon />
					</IconButton>
				</Tooltip>
				<Tooltip title={'Delete Question'} placement="bottom" arrow={true}>
					<IconButton disabled={!!disabled} onClick={handleClick}>
						<DeleteOutlineIcon />
					</IconButton>
				</Tooltip>
				<Tooltip title={question.hidden ? 'Unhide Question' : 'Hide Question'} placement="bottom" arrow={true}>
					<IconButton disabled={!!disabled} onClick={handleHideClick}>
						{
							question.hidden ? (<VisibilityOffIcon />) : (<VisibilityIcon />)
						}
					</IconButton>
				</Tooltip>
			</TableCell>
			<AppDialog
				open={modalOpen}
				title={`${question.questionText}`}
				titleStyle={{ margin: 2, maxWidth: '750px' }}
				showCloseIcon={true}
				handleClose={() => setModalOpen(false)}
			>
				<Table sx={{ width: '650px', maxWidth: '100%' }}>
					<TableBody>
						<TableRow>
							<TableCell
								component="th"
								scope="row"
								colSpan={question.type !== 'ranked-choice' ? 3 : 1}
							>
								Total Responses: {allAnswersCount}
							</TableCell>
							{question.type === 'ranked-choice' &&
								question.questionChoices?.map((choice, index) => {
									let postfix;
									switch (index + 1) {
										case 1:
											postfix = 'st';
											break;
										case 2:
											postfix = 'nd';
											break;
										case 3:
											postfix = 'rd';
											break;

										default:
											postfix = 'th';
											break;
									}
									return (
										<TableCell component="th" key={index}>
											{index + 1}
											{postfix}
										</TableCell>
									);
								})}
						</TableRow>
						{questionChoices.map((row: QuestionChoice, idx: number) => {
							const answersCount = answersByQuestion?.filter((item) => {
								if (row.choiceType === 'write-in') {
									return item.questionChoiceText === row.choiceText;
								} else {
									return item.questionChoiceId === row.id;
								}
							}).length;
							return (
								<TableRow key={idx}>
									<TableCell scope="row">
										{(row.choiceText !== '' && row.choiceType === 'option') || question.type === 'ranked-choice'
											? row.choiceText
											: row.choiceText + ' ( Write-In )'}
									</TableCell>
									{question.type !== 'ranked-choice' ? (
										<>
											<TableCell>{answersCount}</TableCell>
											<TableCell>
												{answersCount
													? toFixedIfNecessary(
														(100 * answersCount) / (answersByQuestion.length || 0),
													)
													: 0}
												%
											</TableCell>
										</>
									) : (
										<>
											{question.questionChoices?.map((item, index) => (
												<TableCell key={index}>
													{
														question.questionAnswers?.filter(
															(i) =>
																i.rank === index + 1 &&
																i.questionChoiceId === row.id,
														).length
													}
												</TableCell>
											))}
										</>
									)}
								</TableRow>
							);
						})}
					</TableBody>
				</Table>
			</AppDialog>
		</>
	);
};
