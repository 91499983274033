import React, { FC, useState, useEffect } from 'react';
import {
	Box,
	InputLabel,
	MenuItem,
	Select,
	FormControl,
	ListItemText,
	useTheme,
} from '@mui/material';
import { DragDropContext, Draggable, DropResult } from 'react-beautiful-dnd';
import { FORM_BUILDER_FIELD_TYPE } from '../../../types/formBuilderFieldTypes';
import { FormBuilderFieldConfig } from '../../../types/formBuilderConfig';
import { FieldSummaryItem } from './FieldSummaryItem';
import SelectInput from 'components/common/styled/SelectInput';
import RegularTextInput from 'components/common/styled/RegularTextInput';
import { omit } from 'lodash';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import StrictModeDroppable from 'components/common/StrictModeDroppable';

interface FormConfigureProps {
	onAddField: (fieldType: string) => void;
	onFormNameChange: (name: string) => void;
	onFieldRemove: (ordinal: number) => void;
	onFieldSort: (sourceIndex: number, destinationIndex: number) => void;
	fields: Array<FormBuilderFieldConfig>;
	name: string;
	formType: any;
	handleFormProcessorTypeChange: (type: string) => void;
	setShowCancel: (shouldShow: boolean) => void;
	setShowConfirm: (shouldShow: boolean) => void;
	setDisabled: ((n: boolean) => void) | undefined;
}

export const FormConfigure: FC<FormConfigureProps> = (props) => {
	const {
		name,
		fields,
		formType,
		onAddField,
		onFieldRemove,
		onFormNameChange,
		onFieldSort,
		handleFormProcessorTypeChange,
		setDisabled,
	} = props;
	const [formName, setFormName] = useState(name ?? '');
	const [formNameError, setFormNameError] = useState(false);
	const theme = useTheme();
	const [formProcessorType, setFormProcessorType] = useState(
		formType || 'general-submission',
	);
	const [dragDisabled, setDragDisabled] = useState(true);

	const handleFormNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setFormName(e.target.value);
		onFormNameChange(e.target.value);
		if (!e.target.value) {
			setFormNameError(true);
		} else {
			setFormNameError(false);
		}
	};

	useEffect(() => {
		if (!formName) {
			setDisabled!(true);
		} else {
			setDisabled!(false);
		}
	}, [formName, setDisabled]);

	const handleDragEnd = (result: DropResult) => {
		if (!result.destination) {
			return;
		}

		if (result.destination.index === result.source.index) {
			return;
		}

		onFieldSort(result.source.index, result.destination.index);
	};

	return (
		<Box display="flex" flexDirection="column" mt={2} minWidth={713} p={3}>
			<InputLabel required id="from-name-label" sx={{ mb: 0.5 }}>
				Form Name
			</InputLabel>
			<RegularTextInput
				size="small"
				style={{ marginBottom: '10px' }}
				fullWidth
				value={formName}
				placeholder="New Form"
				required
				error={formNameError}
				helperText={formNameError && 'Form Name is required'}
				onChange={handleFormNameChange}
			/>
			<Box marginBottom={2}>
				<InputLabel id="select-label" sx={{ mb: 0.5 }}>
					Form Type
				</InputLabel>
				<SelectInput
					size="small"
					labelId="select-label"
					fullWidth
					value={formProcessorType}
					onChange={(event: any) => {
						handleFormProcessorTypeChange(event.target.value);
						setFormProcessorType(event.target.value);
					}}
				>
					<MenuItem value={'general-submission'}>General Submission</MenuItem>
					<MenuItem value={'letter-of-intent'}>Letter of Support</MenuItem>
				</SelectInput>
			</Box>
			<Box marginBottom={2} />
			<DragDropContext onDragEnd={handleDragEnd}>
				<StrictModeDroppable droppableId="field-summary-items">
					{(provided) => (
						<div ref={provided.innerRef} {...provided.droppableProps}>
							{fields &&
								fields.map((f, index) => {
									return (
										<Draggable
											key={index}
											draggableId={`${index}`}
											index={index}
											isDragDisabled={dragDisabled}
										>
											{(draggableProvided) => (
												<div
													ref={draggableProvided.innerRef}
													{...draggableProvided.draggableProps}
													{...draggableProvided.dragHandleProps}
												>
													<FieldSummaryItem
														id={f.id}
														labelImage={f.labelImage}
														type={f.type}
														label={f.label}
														groupName={f?.groupName}
														validationType={f?.validationType}
														labelHasImage={f.labelHasImage ?? false}
														description={f.description}
														ordinal={f.ordinal}
														choices={f.choices}
														isRequired={f.isRequired ?? false}
														hasWriteInOption={f.hasWriteInOption ?? false}
														image={f.image}
														descriptionField={f.descriptionField}
														hiddenValue={f.hiddenValue}
														onFieldRemove={onFieldRemove}
														onToggleDrag={(enabled: boolean) =>
															setDragDisabled(!enabled)
														}
													/>
												</div>
											)}
										</Draggable>
									);
								})}
							{provided.placeholder}
						</div>
					)}
				</StrictModeDroppable>
			</DragDropContext>

			<Box
				display="flex"
				alignItems="center"
				bgcolor={`${theme.customPalette.primaryColors.sky[5]}`}
				sx={{
					borderTop: `1px solid ${theme.customPalette.primaryColors.sky[4]}`,
					borderBottom: `1px solid ${theme.customPalette.primaryColors.sky[4]}`,
					height: 80,
					marginLeft: -3,
					marginRight: -3,
					justifyContent: 'center',
				}}
			>
				<FormControl sx={{ m: 1, minWidth: 140, border: 'none' }} size="small">
					<Select
						value={'add'}
						onChange={(e: any) => onAddField(e.target.value)}
						sx={{
							boxShadow: 'none',
							'.MuiOutlinedInput-notchedOutline': { border: 0 },
						}}
					>
						<MenuItem value="add" sx={{ display: 'none' }}>
							<Box
								sx={{
									display: 'flex',
									alignItems: 'center',
									color: '#0076A0',
									gap: '10px',
								}}
							>
								<AddCircleOutlineIcon />
								<ListItemText>Add Form Content</ListItemText>
							</Box>
						</MenuItem>
						{Object.keys(omit(FORM_BUILDER_FIELD_TYPE, 'YES_NO')).map(
							(fieldType, index) => {
								return (
									<MenuItem key={index} value={fieldType}>
										{FORM_BUILDER_FIELD_TYPE[fieldType]}
									</MenuItem>
								);
							},
						)}
					</Select>
				</FormControl>
			</Box>
		</Box>
	);
};
