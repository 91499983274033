import { useEffect, useState } from 'react';
import { useMutation, useQuery } from 'urql';
import isEqual from 'lodash/isEqual';
import {
	createUpdateMutation,
	deleteUpdateMutation,
	updateUpdateMutation,
} from '../../../api/mutation/update.mutations';
import useNumberParams from '../../../hooks/useNumberParams';
import { useSnackBar } from '../../../providers/SnackBarProvider';
import { updateByProjectQuery } from '../../../api/query/update.queries';
import { UpdateTemplate } from './templates';
import { Image } from 'types/types';
import { ImageInfo } from 'types/imageInfo';
import { getObjectKey } from 'utility/getObjectKey';
import { removeImageMutation } from 'api/mutation/image.mutations';
import { removeFileMutation } from 'api/mutation/s3.mutations';
import { RichTextContent } from 'types/richTextContent';
import { isArray, omit } from 'lodash';

import { toPlainText } from '../../common/TextEditor/helpers';

export interface Update {
	id?: number;
	title: string;
	plainText?: string;
	richText?: RichTextContent;
	published?: string;
	sentToFollowers?: boolean;
	modified?: Date;
	created?: Date;
	images?: Image[] | null;
}

const useUpdate = () => {
	const [updates, setUpdates] = useState<Update[]>([]);
	const [openTemplates, setOpenTemplates] = useState(false);
	const [openEditor, setOpenEditor] = useState(false);
	const [currentTemplate, setCurrentTemplate] = useState<UpdateTemplate>({
		id: 0,
		name: '',
		title: '',
		richText: [] as any,
		images: [],
	});
	const [editorState, setEditorState] = useState([] as RichTextContent);
	const [errors] = useState<{ imageDescription?: string }>({});

	const { projectId } = useNumberParams();
	const { notify } = useSnackBar();

	const [{ data }] = useQuery({
		query: updateByProjectQuery,
		variables: { projectId },
		pause: updates.length > 0,
	});

	useEffect(() => {
		if (data?.updatesByProjectId) setUpdates(data.updatesByProjectId);
	}, [data?.updatesByProjectId]);

	useEffect(() => {
		if (Object.keys(currentTemplate?.richText).length !== 0)
			setEditorState(currentTemplate.richText);
		else setEditorState([] as RichTextContent);
	}, [currentTemplate?.richText]);

	const [, createUpdateM] = useMutation(createUpdateMutation);
	const [, updateUpdate] = useMutation(updateUpdateMutation);
	const [, deleteUpdate] = useMutation(deleteUpdateMutation);
	const [, removeFile] = useMutation(removeFileMutation);
	const [, removeImage] = useMutation(removeImageMutation);

	const createUpdate = async (update: {
		plainText: string;
		published?: string;
		title: string;
		richText: RichTextContent;
	}) => {
		let newUpdate = { ...update, projectId };

		const res = await createUpdateM({ newUpdate });

		if (res.error) return notify('Failed to create!', 'error');

		const newId = res?.data.createUpdate.id;

		setUpdates([
			...updates,
			{
				...newUpdate,
				id: newId,
				created: new Date(),
			},
		]);

		notify('Update created');
	};

	const handleUpdate = async (update: Update) => {
		if (!update.id) return;
		const updateIndex = updates.findIndex((u) => u.id === update.id);

		if (
			(update as any).imageId !== undefined &&
			updates[updateIndex]?.title === update.title &&
			updates[updateIndex]?.published === update.published &&
			isEqual(updates[updateIndex]?.images, update.images) &&
			isEqual(updates[updateIndex]?.richText, update.richText)
		)
			return;

		const updateObj = updates[updateIndex]?.published
			? omit(update, 'published')
			: update;
		const res = await updateUpdate({
			existingUpdate: { ...updateObj },
		});

		if (res.error) return notify('Failed to update!', 'error');

		const updatedUpdates = updates;
		updatedUpdates[updateIndex] = {
			...update,
			modified: new Date(),
			published: updates[updateIndex]?.published
				? updates[updateIndex]?.published
				: update.published,
		};

		setUpdates(updatedUpdates);

		notify('Updated!');
	};

	const handleDelete = async (id: number) => {
		if (!id) return;

		const res = await deleteUpdate({ id });
		if (res.error) return notify('Update failed to delete!', 'error');

		const newUpdates = updates.filter((update) => update.id !== id);
		setUpdates(newUpdates);

		notify('Update deleted');
	};

	const handleTitleUpdate = async (title: string) => {
		setCurrentTemplate({ ...currentTemplate, title });
	};

	const handleSave = async (published?: Date) => {
		if (currentTemplate.title.length > 70)
			return notify('Title should be 70 characters or less', 'warning');

		let plainText = toPlainText(editorState);

		let editorImages: any =
			isArray(editorState) &&
			editorState
				.filter((node: any) => node.type === 'image')
				.map((node: any) => node.url);

		const toDeleteImages = currentTemplate?.images?.filter(
			(item) => !editorImages.includes(item.imageUrl),
		);

		if (toDeleteImages) {
			for (let image of toDeleteImages) {
				await removeImage({ id: image.id });
				const objectKey = getObjectKey(image.imageUrl);
				await removeFile({ objectKey });
			}
		}

		const filteredImages = currentTemplate?.images?.filter((item) =>
			editorImages.includes(item.imageUrl),
		);

		const newUpdate = {
			title: currentTemplate.title,
			richText: editorState,
			plainText,
			published: published?.toISOString(),
			images: filteredImages?.map((image) => {
				return { id: image.id, imageUrl: image.imageUrl };
			}),
		};

		if (!currentTemplate.id) await createUpdate(newUpdate);
		else
			await handleUpdate({
				...newUpdate,
				id: currentTemplate.id,
			} as any);
		setOpenEditor(false);
	};

	const addImage = (imageInfo: ImageInfo) => {
		let images;
		if (currentTemplate.images) {
			images = [...(currentTemplate.images as Image[]), imageInfo];
		} else {
			images = [imageInfo];
		}
		setCurrentTemplate({ ...currentTemplate, images } as any);
	};

	return {
		updates,
		handleDelete,
		projectId,
		openTemplates,
		setOpenTemplates,
		openEditor,
		setOpenEditor,
		currentTemplate,
		setCurrentTemplate,
		editorState,
		setEditorState,
		handleTitleUpdate,
		handleSave,
		errors,
		addImage,
	};
};

export default useUpdate;
