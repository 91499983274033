import * as Yup from 'yup';

const infoPlanSchema = Yup.object().shape(
	{
		title: Yup.string().required('Document Title is required'),
		url: Yup.string()
			.url()
			.nullable()
			.typeError('Invalid Url')
			.test(
				'notBothAtTheSameTime',
				'You cannot include file url when file is selected',
				function (url) {
					const { file } = this.parent;
					return !(file && url);
				},
			)
			.when('file', (file, yup) =>
				file
					? yup.notRequired()
					: yup.required('Either File Url or an Uploaded File is required'),
			),
		file: Yup.mixed()
			.nullable()
			.test(
				'notBothAtTheSameTime',
				'You cannot add file when file url is included',
				function (file) {
					const { url } = this.parent;
					return !(url && file);
				},
			)
			.when('url', (url, yup) => (url ? yup.notRequired() : yup.required())),
	},
	[['url', 'file']],
);

export default infoPlanSchema;
