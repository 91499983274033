import { FC, useState, MouseEvent } from 'react';
import { Box, Menu, MenuItem, SxProps } from '@mui/material';
import { KeyboardArrowDown as ArrowDownIcon } from '@mui/icons-material';
import OutlinedButton from './styled/OutlinedButton';

export interface TextDropDownProps {
	sx?: SxProps;
	buttonStyles?: SxProps;
	iconStyles?: SxProps;
	onChange: (value: any) => void;
	currentItem?: string | number;
	items: { name: any; title: string }[];
	buttonProps?: object;
	disabled?: boolean;
}

const TextDropDown: FC<TextDropDownProps> = (props) => {
	const {
		sx,
		onChange,
		currentItem,
		items,
		buttonStyles,
		buttonProps,
		iconStyles,
		disabled,
	} = props;

	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const [current, setCurrent] = useState(items ? items[0]?.title : '');
	const open = Boolean(anchorEl);

	const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = (title: string, name: any) => {
		setAnchorEl(null);
		setCurrent(title);
		onChange(name);
	};

	return (
		<Box sx={sx}>
			<OutlinedButton
				id="basic-button"
				aria-controls={open ? 'basic-menu' : undefined}
				aria-haspopup="true"
				aria-expanded={open ? 'true' : undefined}
				onClick={handleClick}
				disabled={disabled}
				sx={{
					display: 'flex',
					justifyContent: 'space-between',
					textTransform: 'capitalize',
					...buttonStyles,
				}}
				{...buttonProps}
			>
				{currentItem || current}
				<ArrowDownIcon sx={iconStyles} />
			</OutlinedButton>
			<Menu
				id="basic-menu"
				anchorEl={anchorEl}
				open={open}
				onClose={() => setAnchorEl(null)}
				MenuListProps={{
					'aria-labelledby': 'basic-button',
				}}
			>
				{items?.map(
					({ name, title }, index) =>
						(currentItem || current) !== title && (
							<MenuItem key={index} onClick={() => handleClose(title, name)}>
								{title}
							</MenuItem>
						),
				)}
			</Menu>
		</Box>
	);
};

export default TextDropDown;
