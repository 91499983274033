import { ContentLoader } from './contentLoaderType';
import graphqlConn from '../utils/graphqlConn';
import getInfo from '../utils/getInfo';

export const infoPlansLoader: ContentLoader = async (content, _, getToken) => {
	const query = `
		query ($ids: [Int!]!) {
			informationPlans(ids: $ids) {
				id
				ordinal
				file {
					id
					fileName
					url
					description
				}
				title
				url
			}
		}
	`;

	const res = await graphqlConn(getToken, query, {
		ids: content?.informationPlansIds,
	});

	return {
		data: res?.data?.informationPlans || [],
		info: getInfo(content),
	};
};
