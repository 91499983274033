import { FC, useState, MouseEvent, useEffect } from 'react';
import {
	Box,
	Typography,
	Menu,
	RadioGroup,
	FormControlLabel,
	Radio,
	useTheme,
} from '@mui/material';
import { Tune as TuneIcon } from '@mui/icons-material';
import { useSearchParams } from 'react-router-dom';
import DropDown from 'components/common/DropDown';
import { Maybe, Question, QuestionGroup } from 'types/types';
import { useQuery } from 'urql';
import { questionGroupsByProjectQuery } from 'api/query/questions.queries';
import OutlinedButton from 'components/common/styled/OutlinedButton';
import TextButton from 'components/common/styled/TextButton';
import { defaultFilter, Filter } from '../../../utility/commentData';
import { isNumber } from 'lodash';

interface FilterProps {
	filter: Filter;
	onFilter: (filter: Filter) => void;
	projectId: number;
}

const filters = [
	{ name: 'drafted', title: 'Response Drafted' },
	{ name: 'needed', title: 'Response Needed' },
	{ name: 'responded', title: 'Responded' },
	{ name: 'starred', title: 'Starred' },
	{ name: 'followUp', title: 'Follow-up' },
	{ name: 'featured', title: 'Featured' },
	{ name: 'flagged', title: 'Flagged for moderation' },
	{ name: 'incorporated', title: 'Added to plan' }
];

const Filters: FC<FilterProps> = ({ filter, onFilter, projectId }) => {
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const [topicQuestions, setTopicQuestions] = useState<
		Maybe<Question[]> | undefined
	>([]);
	const open = Boolean(anchorEl);
	const theme = useTheme();

	const [params] = useSearchParams();

	const topicId = params.get('topicId');

	const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const [{ data }] = useQuery({
		query: questionGroupsByProjectQuery,
		variables: { projectId },
	});

	const topicsQuestions = data?.questionGroupsByProject as QuestionGroup[];

	useEffect(() => {
		const topicIdAsNumber = +topicId!;

		if (isNumber(topicIdAsNumber) && topicsQuestions) {
			const questionGrp = topicsQuestions.find((t) => t.id === topicIdAsNumber);
			if (questionGrp) {
				setTopicQuestions(questionGrp.questions);
				document
					.querySelector<HTMLButtonElement>('#comment-filter-button')
					?.click();
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [topicId, topicsQuestions]);

	return (
		<div>
			<OutlinedButton
				id="comment-filter-button"
				aria-controls={open ? 'basic-menu' : undefined}
				aria-haspopup="true"
				aria-expanded={open ? 'true' : undefined}
				onClick={handleClick}
				sx={{ borderRadius: 12, ml: 2 }}
				endIcon={<TuneIcon />}
				variant="outlined"
			>
				Filters
			</OutlinedButton>

			<Menu
				id="basic-menu"
				anchorEl={anchorEl}
				open={open}
				onClose={() => setAnchorEl(null)}
				MenuListProps={{
					'aria-labelledby': 'basic-button',
				}}
			>
				<Box width={265} px={2} pb={1}>
					<TextButton
						style={{
							textTransform: 'none',
							float: 'right',
						}}
						size="small"
						onClick={() => {
							setTopicQuestions([]);
							onFilter(defaultFilter);
						}}
					>
						Clear All Filters
					</TextButton>

					<RadioGroup
						aria-labelledby="responses-label"
						name="responses"
						onChange={(e) => {
							onFilter({ ...filter, selectedTab: e.target.value });
						}}
						sx={{ borderBottom: `1px solid ${theme}`, pb: 1, clear: 'both' }}
					>
						{filters.map(({ name, title }, index) => (
							<FormControlLabel
								key={index}
								sx={{
									marginLeft: '5px',
									':hover': {
										backgroundColor:
											theme.customPalette.primaryColors.grassGreen[5],
									},
								}}
								checked={filter.selectedTab === name}
								value={name}
								control={<Radio size="small" />}
								label={title}
							/>
						))}
					</RadioGroup>

					<Typography
						sx={{ color: theme.customPalette.systemColors.disabled }}
						variant="body2"
						my={1.5}
					>
						Topic
					</Typography>

					<DropDown
						currentItem={
							topicsQuestions?.find((t) => t.id === filter.topicId)
								?.themeName || 'Select Topic'
						}
						items={topicsQuestions?.map((t) => ({
							name: t.id,
							title: t.themeName,
						}))}
						onChange={(value) => {
							const questionGrp = topicsQuestions.find((t) => t.id === value);
							if (questionGrp) setTopicQuestions(questionGrp.questions);
							onFilter({ ...filter, topicId: value, questionId: 0 });
						}}
						buttonProps={{ variant: 'outlined', fullWidth: true }}
						buttonStyles={{ borderRadius: 2 }}
					/>

					<Typography
						sx={{ color: theme.customPalette.systemColors.disabled }}
						variant="body2"
						my={1.5}
					>
						Question
					</Typography>

					<DropDown
						disabled={!filter.topicId}
						currentItem={
							topicQuestions?.find((t) => t.id === filter.questionId)
								?.questionText || 'Select Question'
						}
						items={
							topicQuestions?.map((q) => ({
								name: q.id || 0,
								title: q.questionText || '',
							})) || []
						}
						onChange={(value) => onFilter({ ...filter, questionId: value })}
						buttonProps={{ variant: 'outlined', fullWidth: true }}
						buttonStyles={{ borderRadius: 2 }}
					/>
				</Box>
			</Menu>
		</div>
	);
};

export default Filters;
