import { Grid } from '@mui/material';
import { FC } from 'react';
import { FormResponseGroupsContainer } from '../components/routes/responses/FormResponseGroupsContainer';

export const FormResponses: FC = () => {
	return (
		<Grid py={2} px={5} rowSpacing={2} container>
			<FormResponseGroupsContainer />
		</Grid>
	);
};
