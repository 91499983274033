import { useCallback, useState } from 'react';
import { SxProps } from '@mui/material';
import produce from 'immer';
import { useDrop } from 'react-dnd';
import { useMutation } from 'urql';
import { removeFileMutation } from '../api/mutation/s3.mutations';
import { ImageContent } from '../components/modules/ImageModule';
import { getObjectKey } from '../utility/getObjectKey';

interface DragDropParams {
	items: ImageContent[];
	setItems: (items: ImageContent[]) => void;
	direction?: 'vertical' | 'horizontal';
	gap?: number;
}

const useDragDrop = (dragDropParams: DragDropParams) => {
	const { items, setItems, direction, gap } = dragDropParams;

	const [isDragging, setIsDragging] = useState(false);

	const [, removeFileInstance] = useMutation(removeFileMutation);

	const findCard = useCallback(
		(imageUrl: string) => {
			const card = items.filter((c) => `${c.imageUrl}` === imageUrl)[0];
			return {
				card,
				index: items.indexOf(card),
			};
		},
		[items],
	);

	const moveCard = useCallback(
		(id: string, atIndex: number) => {
			const { index } = findCard(id); // come here
			setItems(
				produce(items, (draft) => {
					const moved = draft.splice(index, 1)[0];
					draft.splice(atIndex, 0, moved);
				}),
			);
		},
		[findCard, items, setItems],
	);

	const deleteCard = useCallback(
		async (imageUrl: string, index: number) => {
			const objectKey = getObjectKey(imageUrl);
			const res = await removeFileInstance({ objectKey });

			if (!res.error && res.data.removeAwsFile) {
				if (items.length === 1) return setItems([]);
				setItems(
					produce(items, (draft) => {
						draft.splice(index, 1);
					}),
				);
			}
		},
		[items, setItems, removeFileInstance],
	);

	const [{ isActive, hasDropped }, drop] = useDrop(
		() => ({
			accept: 'card',
			collect: (monitor) => ({
				isActive: monitor.canDrop() && monitor.isOver(),
				hasDropped: monitor.didDrop(),
			}),
		}),
		[],
	);

	const style: SxProps =
		direction === 'horizontal'
			? {
					display: 'flex',
					flex: '0 0 20%',
					flexWrap: 'wrap',
					gap: gap || 1,
					width: 500,
			  }
			: { width: 500 };

	return {
		drop,
		style,
		moveCard,
		findCard,
		deleteCard,
		isOutOfBound: !isActive && isDragging,
		hasDropped,
		setIsDragging,
	};
};

export default useDragDrop;
