import TextButton from './styled/TextButton';
import VideoLibraryIcon from '@mui/icons-material/VideoLibrary';
import Box from '@mui/material/Box';
import { ReactNode } from 'react';

type Props = {
	label: string;
	link: string;
	sx?: any;
	endIcon?: ReactNode;
};
const ButtonLink = ({ label, link, sx = {}, endIcon }: Props) => {
	return (
		<Box my={2}>
			<a target="_blank" href={link} rel="noreferrer">
				<TextButton
					sx={{
						...sx,
						fontWeight: 800,
						textTransform: 'capitalize',
						fontSize: 16,
						marginLeft: '-12px',
						px: 2,
					}}
					endIcon={endIcon ? endIcon : <VideoLibraryIcon />}
				>
					{label}
				</TextButton>
			</a>
		</Box>
	);
};

export default ButtonLink;
