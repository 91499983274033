import React, { useState } from 'react';
import {
	KeyboardArrowDown as ArrowDownIcon,
	KeyboardArrowUp as ArrowUpIcon,
	Star as StarIcon,
	StarBorder as StarBorderIcon,
	ArrowBack as ArrowBackIcon,
} from '@mui/icons-material';
import { NavLink, useParams } from 'react-router-dom';
import {
	Box,
	Collapse,
	ListItemButton,
	useTheme,
	List,
	ListItem,
	ListItemText,
	Typography,
	styled,
} from '@mui/material';
import { activeRoute } from '../../../utility/activeRoute';
import { itemData } from './leftMenu/MenuItem';
interface SelectedMenuProps {
	options: itemData[];
	activeProject: number;
	setActiveProject: (val: number) => void;
	projects: { id: number; name: string; slug: string }[];
	open: boolean;
	setOpen: (open: boolean) => void;
}

const NavLinkStyled = styled(NavLink)({
	fontSize: '14px',
	borderRadius: 0,
	textDecoration: 'none',
	color: 'grey',
});

export const defaultProjectToken = 'defaultProjectId';

const SelectedMenu: React.FC<SelectedMenuProps> = (props) => {
	const { options, activeProject, setActiveProject, projects, open, setOpen } =
		props;
	const { projectId } = useParams();
	const theme = useTheme();

	const handleStarClick = (
		event: React.MouseEvent<SVGSVGElement, MouseEvent>,
		id: number,
	) => {
		event.stopPropagation();
		setOpen(!open);
		setActiveProject(id);
		localStorage.setItem(defaultProjectToken, id.toString());
		setStarredId(id);
	};

	const [starredId, setStarredId] = useState(
		localStorage && parseInt(localStorage.getItem(defaultProjectToken)!),
	);

	const selectedOption = projects
		? activeProject !== 0
			? projects?.filter(
					(x: any) => x.id === Number(projectId || activeProject),
			  )[0]
			: options[0]
		: null;

	const moreThanOneProjectAvailable = options?.length > 1;

	const whiteBackground = {
		'&:hover, &:active, &:focus': {
			backgroundColor: '#fff',
		},
	};
	const baseListItemStyle = {
		padding: '26px 16px 46px',
		borderBottom: `1px solid  ${theme.customPalette.dividerColors.light}`,
		cursor: moreThanOneProjectAvailable ? 'pointer' : 'default !important',
	};

	const listItemStyle = !moreThanOneProjectAvailable
		? { ...baseListItemStyle, ...whiteBackground }
		: baseListItemStyle;

	return (
		<Box borderBottom="1px solid #ECECEC">
			<List
				component="nav"
				aria-label="project selector"
				sx={{
					backgroundColor: 'background.paper',
					p: 0,
					cursor: moreThanOneProjectAvailable ? 'pointer' : 'default',
				}}
			>
				<ListItem
					button
					id="lock-button"
					aria-haspopup="listbox"
					aria-controls="lock-menu"
					aria-label="when no projects"
					aria-expanded={open ? 'true' : undefined}
					onClick={() => moreThanOneProjectAvailable && setOpen(!open)}
					sx={listItemStyle}
				>
					<ListItemText
						primary={selectedOption && selectedOption.name}
						secondary={
							!activeProject && !options?.length && 'No Project To Select'
						}
						primaryTypographyProps={{
							fontSize: 18,
							color: theme.customPalette.primaryColors.dusk[1],
							fontWeight: 900,
						}}
						secondaryTypographyProps={{ fontSize: 18 }}
					/>
					{moreThanOneProjectAvailable &&
						(open ? <ArrowUpIcon /> : <ArrowDownIcon />)}
				</ListItem>
				<ListItem
					sx={{
						position: 'absolute',
						bottom: '6px',
						width: 'initial',
					}}
				>
					<Typography
						color="primary"
						component={'a'}
						href={`${process.env.REACT_APP_SITE_BASE_URL}/${
							(selectedOption as any)?.slug
						}/home`}
						target="_blank"
						sx={{
							textDecoration: 'none',
							fontWeight: 700,
							fontSize: 16,
							marginLeft: '5px',
						}}
					>
						View Project Site
					</Typography>
				</ListItem>
			</List>
			<Collapse id="lock-button" in={open} timeout="auto">
				{options?.map((option: any, index: any) => {
					const active = projectId === String(option.id);

					return (
						<ListItemButton
							sx={(theme) => ({
								borderRadius: '12px',
								ml: '12px',
								mr: '12px',
								mb: '8px',
								padding: '12px 18px',
								'&:hover': {
									background: theme.customPalette.primaryColors.grassGreen[5],
									color: theme.customPalette.primaryColors.grassGreen[1],
								},
								...(active
									? {
											background:
												theme.customPalette.primaryColors.grassGreen[5],
											color: theme.customPalette.primaryColors.grassGreen[1],
									  }
									: {}),
							})}
							key={option.id}
						>
							{option.id === starredId ? (
								<StarIcon
									htmlColor={
										option.id === activeProject
											? theme.customPalette.primaryColors.grassGreen[1]
											: ''
									}
									onClick={(event) => handleStarClick(event, option.id)}
									sx={{ mr: 1, cursor: 'pointer' }}
								/>
							) : (
								<StarBorderIcon
									htmlColor={
										option.id === activeProject
											? ''
											: theme.customPalette.primaryColors.grassGreen[1]
									}
									onClick={(event) => handleStarClick(event, option.id)}
									sx={{ mr: 1, cursor: 'pointer' }}
								/>
							)}
							<NavLinkStyled
								onClick={() => {
									setOpen(false);
									setActiveProject(option.id);
								}}
								to={`/projects/${option.id}`}
								style={{ width: '100%' }}
								sx={{
									...(active
										? {
												color: theme.customPalette.primaryColors.grassGreen[1],
										  }
										: {
												color: theme.customPalette.primaryColors.dusk[1],
												'&:hover': {
													color:
														theme.customPalette.primaryColors.grassGreen[1],
												},
										  }),
								}}
							>
								<ListItem
									selected={activeRoute(option.id)}
									sx={{
										fontSize: 16,
										p: 0,
										backgroundColor: 'transparent !important',
									}}
								>
									{option.name}
								</ListItem>
							</NavLinkStyled>
						</ListItemButton>
					);
				})}
				<NavLinkStyled to={`/projects/${activeProject}/projects`}>
					<ListItem
						sx={{
							color: theme.customPalette.systemColors.unchecked,
							fontSize: 16,
							py: 1.5,
							pl: '30px',
							mt: '12px',
							'&:hover': { bgcolor: 'lightgrey' },
						}}
					>
						<ArrowBackIcon
							sx={{ mr: '18px' }}
							htmlColor={theme.customPalette.primaryColors.grassGreen[1]}
						/>
						View all projects
					</ListItem>
				</NavLinkStyled>
			</Collapse>
		</Box>
	);
};

export default SelectedMenu;
