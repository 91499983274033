import { FC } from 'react';
import { Box, Typography } from '@mui/material';

interface TabPanelProps {
	children?: React.ReactNode;
	index: number;
	value: number;
	style?: object;
}

const TabPanel: FC<TabPanelProps> = (props) => {
	const { children, value, index, style, ...other } = props;

	return (
		<div
			// eslint-disable-next-line jsx-a11y/aria-role
			role="tab-panel"
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			style={{ ...{ minWidth: 550 }, ...style }}
			{...other}
		>
			{value === index && (
				<Box sx={{ p: 3 }}>
					<Typography component={'span'}>{children}</Typography>
				</Box>
			)}
		</div>
	);
};

export default TabPanel;
