import { FC } from 'react';
import {
	Card,
	CardContent,
	Grid as MuiGrid,
	Typography,
	useTheme,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { Project } from 'types/types';
import { RouteContextType } from '../../../routes/Layout';
import { ProjectCard } from './ProjectCard';

interface ProjectsProps {
	projects: Project[];
	context: RouteContextType;
}

const ProjectList: FC<ProjectsProps> = ({ projects, context }) => {
	const navigate = useNavigate();
	const handleProjectClick = (projId: number) => {
		context.setActiveProject(projId);
		navigate(`/projects/${projId}`);
	};
	const theme = useTheme();

	return (
		<Card sx={{ minWidth: 1000 }} variant="outlined">
			<CardContent>
				<MuiGrid container={true} spacing={2}>
					{projects.map((p: Project) => (
						<ProjectCard key={p.id} project={p} onClick={handleProjectClick} />
					))}
					{projects.length === 0 && (
						<MuiGrid item sx={{ width: '1150px', height: '327px' }}>
							<Typography sx={{ color: theme.customPalette.primaryColors.sky }}>
								No projects available
							</Typography>
						</MuiGrid>
					)}
				</MuiGrid>
			</CardContent>
		</Card>
	);
};

export default ProjectList;
