import { InputLabel } from '@mui/material';
import RegularTextInput from 'components/common/styled/RegularTextInput';
import { Field, FormikTouched, FormikErrors } from 'formik';
import { Cell } from 'styled-css-grid';

interface PropsType {
	values: {
		gaKeyId: string;
		gaPropertyId: string;
	};
	touched: FormikTouched<{
		gaKeyId: string;
		gaPropertyId: string;
	}>;
	errors: FormikErrors<{
		gaKeyId: string;
		gaPropertyId: string;
	}>;
	disabled?: boolean;
}

export function GoogleAnalyticsFields({
	values,
	touched,
	errors,
	disabled,
}: PropsType) {
	return (
		<>
			<Cell width={2}>
				<InputLabel htmlFor="gaKeyId">Measurement Id</InputLabel>
				<Field
					id="gaKeyId"
					name="gaKeyId"
					size="small"
					value={values.gaKeyId}
					disabled={disabled}
					as={RegularTextInput}
					error={Boolean(touched.gaKeyId && errors.gaKeyId)}
					helperText={touched.gaKeyId ? errors.gaKeyId : ''}
					fullWidth
				/>
			</Cell>
			<Cell width={2} style={{ marginTop: '5px' }}>
				<InputLabel htmlFor="gaPropertyId">Property Id</InputLabel>
				<Field
					id="gaPropertyId"
					name="gaPropertyId"
					size="small"
					value={values.gaPropertyId}
					disabled={disabled}
					as={RegularTextInput}
					error={Boolean(touched.gaPropertyId && errors.gaPropertyId)}
					helperText={touched.gaPropertyId ? errors.gaPropertyId : ''}
					fullWidth
				/>
			</Cell>
		</>
	);
}
