import { FC } from 'react';
import {
	Box,
	FormControl,
	MenuItem,
	Select,
	Typography,
	Grid,
} from '@mui/material';
import { ExpandCircleDownOutlined as DropDownIcon } from '@mui/icons-material';
import PositionSelector from './PositionSelector';

interface ImageOptionsProps {
	imagePosition?: string | null;
	setImagePosition: (position: string | null) => void;
	imageScale?: string | null;
	setImageScale: (scale: string | null) => void;
	showImageScale?: boolean;
	showImagePosition?: boolean;
}

const ImageOptions: FC<ImageOptionsProps> = (props) => {
	const {
		imageScale,
		setImageScale,
		setImagePosition,
		imagePosition,
		showImageScale,
		showImagePosition,
	} = props;

	return (
		<Box mt={2} width="100%">
			<Grid container>
				{showImageScale && (
					<Grid item xs={6}>
						<Box>
							<Typography mb={1}>How's the image scaled?</Typography>
							<FormControl fullWidth>
								<Select
									labelId="demo-simple-select-label"
									id="demo-simple-select"
									value={imageScale}
									onChange={(e) => setImageScale(e.target.value)}
									sx={{
										maxWidth: 200,
									}}
									IconComponent={DropDownIcon}
									size="small"
									inputProps={{ style: { border: 0, color: 'green' } }}
								>
									<MenuItem value="fill">Scale to Fill</MenuItem>
									<MenuItem value="fit">Lock Aspect Ratio</MenuItem>
								</Select>
							</FormControl>
						</Box>
					</Grid>
				)}
				{showImagePosition && (
					<Grid item xs={6}>
						<PositionSelector
							onSelect={setImagePosition}
							selected={imagePosition || 'middle-center'}
						/>
					</Grid>
				)}
			</Grid>
		</Box>
	);
};

export default ImageOptions;
