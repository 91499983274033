import { useLayoutEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';

import { useUserContext } from '../../providers/UserProvider';
import { useEnv } from '../../providers/EnvProvider';

const shutdownIntercom = () => {
	// @ts-ignore
	typeof window?.Intercom === 'function' &&
		// @ts-ignore
		window?.Intercom('shutdown');
};

const FIVE_SECONDS = 5000;

const IntercomWidget = () => {
	const { isAuthenticated, isLoading } = useAuth0();
	const { user } = useUserContext();

	const { intercomWidgetId } = useEnv();
	useLayoutEffect(() => {
		let timeoutId: NodeJS.Timeout;

		if (!isLoading) {
			if (isAuthenticated && user) {
				timeoutId = setTimeout(() => {
					// @ts-ignore
					window.Intercom('boot', {
						app_id: intercomWidgetId,
						name: user.firstName,
					});
				}, FIVE_SECONDS);
			} else {
				timeoutId = setTimeout(() => {
					shutdownIntercom();
					// @ts-ignore
					window.Intercom('boot', {
						app_id: intercomWidgetId,
					});
				}, FIVE_SECONDS);
			}
		}

		return () => {
			if (timeoutId) {
				clearTimeout(timeoutId);
				shutdownIntercom();
			}
		};
	}, [isAuthenticated, isLoading, user, intercomWidgetId]);

	return null;
};

export default IntercomWidget;
