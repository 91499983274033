// ==============================|| OVERRIDES - ICONBUTTON ||============================== //

import { Theme } from '@mui/material';

export default function IconButton(theme: Theme) {
	return {
		MuiIconButton: {
			styleOverrides: {
				root: {
					color: theme.customPalette.primaryColors.sky[1],
				},
			},
		},
	};
}
