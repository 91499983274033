import * as Yup from 'yup';
import { lengthRegEx } from '../../utility/string';
import isValid from 'date-fns/isValid';

const timelineSchema = Yup.object().shape({
	name: Yup.string()
		.matches(lengthRegEx(2), 'Name must be at least 2 characters')
		.required('Event Name is required')
		.max(70, 'Cannot be over 70 characters'),
	description: Yup.string().matches(
		lengthRegEx(5),
		'Must be at least 5 characters',
	),
	startDate: Yup.date()
		.required('Date is required')
		.typeError('Please use the format DD/MM/YYYY'),
	startTime: Yup.date().nullable().typeError('Invalid Time'),
	endDate: Yup.date()
		.nullable()
		.typeError('Please use the format DD/MM/YYYY')
		.when('startDate', (startDate, yup) => {
			if (startDate && isValid(startDate)) {
				return yup.min(startDate, 'Date cannot be before start date');
			}
			return yup;
		}),
	endTime: Yup.date().nullable().typeError('Invalid Time'),
});

export default timelineSchema;
