import { AvatarColours } from '../constants/colorPalette';

const generateAvatarColour = (name: string) => {
	let sum = 0;
	for (let i = 0; i < name.length; i++) {
		sum += name.charCodeAt(i);
	}
	return sum % AvatarColours.length;
};

export const getAvatarColor = (name: string) => {
	return AvatarColours[generateAvatarColour(name)];
};
