import User from 'types/user';
import { Project } from 'types/types';
import { USER_TYPES } from 'constants/userTypes';

export const getProjectIds = (user: User, projects: Array<Project>) => {
	return (
		user?.projectDetails
			?.map((x) => x.projectId)
			?.filter(
				(userProjectId) =>
					// If user is client and some of his sites are disabled
					!projects.find(
						(project) =>
							userProjectId === project.id &&
							project.disabledDate &&
							user.userType === USER_TYPES.CLIENT,
					),
			) ?? []
	);
};
