import React from 'react';
import { ResponseModal } from './FormResponsesModal';
import {
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TablePagination,
	TableRow,
	IconButton,
	Collapse,
	styled,
	tableCellClasses,
	MenuItem,
	Menu,
	Theme,
	CircularProgress,
	Typography,
} from '@mui/material';
import {
	KeyboardArrowDown as KeyboardArrowDownIcon,
	KeyboardArrowUp as KeyboardArrowUpIcon,
	MoreVert as MoreVertIcon,
} from '@mui/icons-material';
import { usePausedContext } from '@coUrbanize/community-modules';
import { CSVLink } from 'react-csv';

import { ParsedData } from './FormResponseGroupsContainer';
import { FormBuilderFieldConfig } from '../../../types/formBuilderConfig';
import _, { isArray } from 'lodash';
import { useAuth0 } from '@auth0/auth0-react';
import { useSnackBar } from '../../../providers/SnackBarProvider';
import useNumberParams from '../../../hooks/useNumberParams';

interface ResponseGroupProps {
	row: ReturnType<typeof createData>;
}

const StyledTableCell = styled(TableCell)(({ theme }: { theme: Theme }) => ({
	[`&.${tableCellClasses.body}`]: {
		fontSize: 14,
	},
	[`&.${tableCellClasses.head}`]: {
		backgroundColor: theme.palette.common.white,
		color: theme.customPalette.textColors.static,
		fontWeight: 800,
		fontSize: 16,
	},
	border: 0,
}));
const StyledTableRow = styled(TableRow)(({ theme }: { theme: Theme }) => ({
	border: 0,
	'&:nth-of-type(even)': {
		backgroundColor: theme.customPalette.primaryColors.sky[5],
		border: 0,
	},
	// hide last border
	'&:last-child td, &:last-child th': {
		border: 0,
	},
}));

export const FormResponseGroup: React.FC<ResponseGroupProps> = (props) => {
	const { row } = props;
	const [openModal, setOpenModal] = React.useState(false);
	const [currentResponse, setCurrentResponse] = React.useState<any>(null);
	//only open empty by default (to notify user)
	const [open, setOpen] = React.useState(row.replies.length === 0 ? true : false);
	const [anchorEl, setAnchorEl] = React.useState<any>(null);
	const [page, setPage] = React.useState(0);
	const [rowsPerPage, setRowsPerPage] = React.useState(5);
	const { isPaused } = usePausedContext();
	const headers = row.fields?.map((item: FormBuilderFieldConfig) => ({
		label: item.label,
		key: item.id,
	}));
	const data = row.replies?.map((item) => item.responseJson);
	const { getAccessTokenSilently: getToken } = useAuth0();
	const { notify } = useSnackBar();
	const { projectId } = useNumberParams();

	const handleChangeRowsPerPage = (
		event: React.ChangeEvent<HTMLInputElement>,
	) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};

	const opened = Boolean(anchorEl);

	const handleClose = () => {
		setAnchorEl(null);
	};

	const handleRowClick = (response: ParsedData) => {
		setCurrentResponse(response);
		setOpenModal(true);
	};

	const [pdfLoading, setPdfLoading] = React.useState(false);

	const handleDownloadPDF = async () => {
		setPdfLoading(true);
		const token = await getToken();
		const response = await fetch(
			`${process.env.REACT_APP_API_URI}/processor/get-form-responses-pdf/${row.id}/${projectId}`,
			{
				method: 'GET',
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${token}`,
				},
			},
		);
		setPdfLoading(false);
		if (response.status === 200 || response.status === 201) {
			const blob = await response.blob();
			const url = window.URL.createObjectURL(blob);
			const a = document.createElement('a');
			a.href = url;
			a.download = 'form-responses.pdf';
			document.body.appendChild(a);
			a.click();
			window.URL.revokeObjectURL(url);
			document.body.removeChild(a);
		} else {
			notify('Failed to download PDF', 'error');
		}
	};

	return (
		<>
			<TableRow
				sx={{
					'& > *': { borderBottom: 'unset' },
					width: '100%',
				}}
			>
				<TableCell sx={{ width: '5%' }}>
					<IconButton
						aria-label="expand row"
						size="small"
						onClick={() => setOpen(!open)}
					>
						{open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
					</IconButton>
				</TableCell>
				<TableCell sx={{ width: '90%' }}>
					{_.startCase(row.name)} ({row?.replies?.length} replies)
				</TableCell>
				<TableCell align="right" sx={{ width: '5%' }}>
					{!isPaused ? (
						<MoreVertIcon
							sx={{ cursor: 'pointer' }}
							onClick={(e) => setAnchorEl(e.currentTarget)}
						/>
					) : null}
					<Menu
						anchorEl={anchorEl}
						keepMounted
						onClose={handleClose}
						open={opened}
					>
						{data.length ? (
							<>
								<CSVLink
									style={{ textDecoration: 'none' }}
									data={data || []}
									headers={headers}
									filename="FormResponse"
								>
									<MenuItem>Download as .CSV</MenuItem>
								</CSVLink>
								<MenuItem onClick={handleDownloadPDF}>
									{pdfLoading ? (
										<>
											<CircularProgress size={20} sx={{ mr: 1 }} />
											Loading ....
										</>
									) : (
										'Download as .PDF'
									)}
								</MenuItem>
							</>
						) : (
							<MenuItem disabled>No Actions Available</MenuItem>
						)}
					</Menu>
				</TableCell>
			</TableRow>
			<TableRow>
				<TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={12}>
					<Collapse in={open} timeout="auto" unmountOnExit>
						<TableContainer sx={{ margin: 1 }}>
							<Table size="small" aria-label="form-responses">
								<TableHead>
									<TableRow>
										{!!row.replies.length &&
											row.fields?.map(
												(titleRow: FormBuilderFieldConfig, index: number) => (
													<StyledTableCell key={index}>
														{_.startCase(titleRow.label)}
													</StyledTableCell>
												),
											)}
									</TableRow>
								</TableHead>
								<TableBody>
									{row.replies.length === 0 && (
										<Typography variant="subtitle1" sx={{ ml: 2, py: 2 }}>
											Form submissions will be listed here when received
										</Typography>

									)}
									{row.replies
										.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
										.map((replyRow, index) => (
											<StyledTableRow
												sx={{ cursor: 'pointer' }}
												onClick={() => handleRowClick(replyRow)}
												key={index}
											>
												{row.fields?.map((titleRow: FormBuilderFieldConfig) => (
													<StyledTableCell key={titleRow.id}>
														{!isArray(replyRow.responseJson[titleRow.id])
															? replyRow.responseJson[titleRow.id]
															: (
																replyRow.responseJson[titleRow.id] as any
															).join(', ')}
													</StyledTableCell>
												))}
												<StyledTableCell align="right">
													<MoreVertIcon />
												</StyledTableCell>
											</StyledTableRow>
										))}
								</TableBody>
								<TableRow>
									{!!row.replies.length && (
										<TablePagination
											sx={{ border: 'none' }}
											rowsPerPageOptions={[
												5,
												10,
												25,
												{ label: 'All', value: row?.replies?.length },
											]}
											colSpan={12}
											count={row?.replies?.length}
											rowsPerPage={rowsPerPage}
											page={page}
											SelectProps={{
												inputProps: {
													'aria-label': 'rows per page',
												},
												native: true,
											}}
											onPageChange={(_, newPage) => setPage(newPage)}
											onRowsPerPageChange={handleChangeRowsPerPage}
										/>
									)}
								</TableRow>
							</Table>
						</TableContainer>
					</Collapse>
				</TableCell>
			</TableRow>
			{openModal && (
				<ResponseModal
					open={openModal}
					onClose={() => setOpenModal(!openModal)}
					fields={currentResponse?.fields || []}
					responseJson={currentResponse?.responseJson}
				/>
			)}
		</>
	);
};

const createData = (
	id: number,
	name: string,
	status: string,
	mostRecentFormJson: any,
	responses: ParsedData[],
) => {
	const fields = isArray(mostRecentFormJson)
		? mostRecentFormJson
		: mostRecentFormJson === null
			? []
			: mostRecentFormJson['fields'] || [];
	return {
		id,
		name,
		status,
		fields,
		replies: responses?.filter((item) => item.form_id === id),
	};
};

interface formRowProps {
	forms: {
		mostRecentFormJson: any;
		id: number;
		formName: string;
		module: any;
		status: string;
	}[];
	responses: ParsedData[];
}

export const FormRow = ({ forms, responses }: formRowProps) => {
	const [rows, setRows] = React.useState<ReturnType<typeof createData>[]>([]);

	React.useEffect(() => {
		const data = forms?.map((item) =>
			createData(
				item.id,
				item?.formName,
				item.status,
				item.mostRecentFormJson,
				responses,
			),
		);
		setRows(data);
	}, [forms, responses]);

	return (
		<TableContainer>
			<Table
				aria-label="collapsible table"
				sx={{
					tableLayout: 'fixed',
				}}
			>
				<TableBody sx={{ backgroundColor: 'white' }}>
					{rows.map((row, index) => (
						<FormResponseGroup key={index} row={row} />
					))}
				</TableBody>
			</Table>
		</TableContainer>
	);
};
