import { reactLocalStorage } from 'reactjs-localstorage';
import { sessionMap } from '../constants/sessionMap';
import { isEmpty } from 'lodash';

interface UseLastRedirectHook {
	getLastRedirectURL: () => null | string;
	setLastRedirectURL: (urlValue: string) => string;
	clearLastRedirectURL: () => void;
}

export const useLastRedirectURL = (): UseLastRedirectHook => {
	// Retrieves storage name from environment
	const getPrefsStorageKeyName = (): string => {
		const { cookies } = sessionMap();
		return cookies.lastRedirectUrl.namedKey;
	};

	// Retrieves timestamp moment value
	const getLastRedirectURL: UseLastRedirectHook['getLastRedirectURL'] = () => {
		return reactLocalStorage.get(getPrefsStorageKeyName(), null);
	};

	// Sets timestamp moment value
	const setLastRedirectURL: UseLastRedirectHook['setLastRedirectURL'] = (
		urlValue: string,
	) => {
		if (!isEmpty(urlValue)) {
			return reactLocalStorage.set(getPrefsStorageKeyName(), urlValue);
		}

		return getLastRedirectURL();
	};

	// Sets timestamp to nothing
	const clearLastRedirectURL: UseLastRedirectHook['clearLastRedirectURL'] =
		() => {
			return reactLocalStorage.remove(getPrefsStorageKeyName());
		};

	return {
		getLastRedirectURL,
		setLastRedirectURL,
		clearLastRedirectURL,
	};
};
