import React, { useState, FC, useEffect } from 'react';
import { Stack, Typography } from '@mui/material';
import RegularTextInput from 'components/common/styled/RegularTextInput';
import { Comment } from 'types/types';
import dayjs from 'dayjs';
import ConfirmationDialog from '../../common/ConfirmationDialog';

type CommentModerationModalProps = {
	open: boolean;
	comment: Comment;
	onModerate: () => void;
	onModerateCancel: () => void;
};
type CommentModeration = {
	id: number;
	commentText: string | undefined | null;
	moderated: string | undefined | null;
	moderatedBy: string | undefined | null;
	approved: boolean;
};
const CommentModerationModal: FC<CommentModerationModalProps> = ({
	open,
	comment,
	onModerate,
	onModerateCancel,
}) => {
	const [commentModeration, setCommentModeration] = useState(
		{} as CommentModeration,
	);

	const [modalOpen, setModalOpen] = useState(open);

	const onCancel = () => {
		setModalOpen(false);
		onModerateCancel();
	};
	const onConfirm = () => {
		setModalOpen(false);
		onModerate();
	};

	useEffect(() => {
		if (open) {
			setCommentModeration({
				id: comment.id,
				commentText: comment.commentText,
				moderated: comment.moderated,
				moderatedBy: comment.moderatedByUser?.fullName,
				approved: comment.approved,
			});
		}
		setModalOpen(open);
	}, [comment, open]);

	return (
		<ConfirmationDialog
			open={modalOpen}
			onConfirm={onConfirm}
			onCancel={onCancel}
			title="Moderate Comment"
			confirmLabel={`${commentModeration.approved ? 'Deny' : 'Approve'}`}
			cancelLabel="Cancel"
			action={commentModeration.approved ? 'abandon' : 'save'}
		>
			<Stack
				direction={'column'}
				alignItems={'normal'}
				justifyContent={'flex-start'}
			>
				<Typography paddingX={4} width="100%">
					<strong>{comment.createdByUser?.fullName}</strong> posted the
					following comment on{' '}
					{dayjs
						.unix(Number(comment.created) / 1000)
						.format('MMM DD, YYYY hh:mm A')}
					.
				</Typography>
				<Typography paddingTop={1} paddingX={4} width="100%">
					{commentModeration.moderatedBy}{' '}
					<strong>{commentModeration.approved ? 'approved' : 'denied'}</strong>{' '}
					the comment on{' '}
					{dayjs
						.unix(Number(comment.moderated) / 1000)
						.format('MMM DD, YYYY hh:mm A')}
					.
				</Typography>
				<Typography paddingX={4} paddingY={2} width="100%">
					<RegularTextInput
						value={commentModeration.commentText}
						fullWidth
						disabled
						multiline
						sx={{ marginTop: '10px' }}
					/>
				</Typography>
				<Typography px={4} width="100%">
					Are you sure you wish to override the previous moderation and{' '}
					<strong>{commentModeration.approved ? 'deny' : 'approve'}</strong>{' '}
					this comment?
				</Typography>
			</Stack>
		</ConfirmationDialog>
	);
};

export default CommentModerationModal;
