import { Box, Typography, useTheme } from '@mui/material';
import { FC } from 'react';
import { User } from '../../../types/types';
import { UserFilters } from './UserFilters';
import { ProjectUserTable } from './AdminUserTable';

interface ProjectUserManagerLayoutProps {
	users: User[];
	onInviteClick: (
		UserType: string,
		UserStatus: string,
		EmailAddressOrName: string,
	) => void;
	onUserUpdate: (user: any) => void;
	onResetPassword: (email: string) => void;
	currentPage: number;
	setCurrentPage: (page: number) => void;
	recordsPerPage: number;
	setRecordsPerPage: (page: number) => void;
	total?: number;
	orderStates?: any;
	isLoading?: boolean;
}

const ProjectUserManagerLayout: FC<ProjectUserManagerLayoutProps> = (props) => {
	const {
		users,
		onInviteClick,
		currentPage,
		recordsPerPage,
		onResetPassword,
		onUserUpdate,
		setCurrentPage,
		setRecordsPerPage,
		total,
		orderStates,
		isLoading,
	} = props;
	const theme = useTheme();

	return (
		<Box
			display="flex"
			flexDirection="column"
			alignItems="flex-start"
			py={3}
			sx={{ height: '80vh' }}
		>
			<Typography
				sx={{
					marginBottom: '40px',
					color: theme.customPalette.textColors.dark,
				}}
				variant="h5"
			>
				User Management
			</Typography>

			<UserFilters onSearchClick={onInviteClick} />

			<ProjectUserTable
				currentPage={currentPage}
				setCurrentPage={setCurrentPage}
				recordsPerPage={recordsPerPage}
				setRecordsPerPage={setRecordsPerPage}
				onUserUpdate={(user) => onUserUpdate(user)}
				users={users}
				total={total}
				onResetPassword={onResetPassword}
				orderStates={orderStates}
				isLoading={isLoading}
			/>
		</Box>
	);
};

export { ProjectUserManagerLayout };
