import { FC } from 'react';
import { Card, Typography } from '@mui/material';
import { RocketLaunch } from '@mui/icons-material';
import SettingsTabInfo from './SettingsTabInfo';
import { Can } from 'providers/CaslAbilityProvider';
import RegularButton from 'components/common/styled/RegularButton';
import { permissionAction, permissionSubject } from 'utility/defineAbilityFor';
import { EventType } from '../hooks/useProjectSettings';

interface UnLaunchedCardProps {
	handlePublish: (type: EventType, value: string | null | boolean) => void;
}

const UnLaunchedCard: FC<UnLaunchedCardProps> = ({ handlePublish }) => {
	return (
		<>
			<SettingsTabInfo />
			<Card sx={(theme) => ({ padding: theme.spacing(3), display: 'block' })}>
				<Can I={permissionAction.MANAGE} a={permissionSubject.ALL}>
					<RegularButton
						color="secondary"
						variant="contained"
						type="button"
						startIcon={<RocketLaunch />}
						onClick={() =>
							handlePublish('launchedDate', new Date().toISOString())
						}
						size="small"
					>
						Launch Now
					</RegularButton>
					<Typography mt={2}>
						The project must be launched to make modifications.
					</Typography>
				</Can>
			</Card>
		</>
	);
};

export default UnLaunchedCard;
