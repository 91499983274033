import React, { useCallback, useMemo, useState } from 'react';
import { Box } from '@mui/material';
import { useDrop, DropTargetMonitor } from 'react-dnd';
import { NativeTypes } from 'react-dnd-html5-backend';
import { Editable, withReact, Slate } from 'slate-react';
import { createEditor, Descendant } from 'slate';
import { withHistory } from 'slate-history';
import { RichTextContent } from 'types/richTextContent';
import FileDragDrop from 'components/common/FileDragDrop';
import FormModal from 'components/common/FormModal';
import UploadImage from 'components/modules/components/UploadImage';
import isHotkey from 'is-hotkey';
import { ImageContent } from '../../../types/imageInfo';
import useEditorImage from './useEditorImage';
import {
	Toolbar,
	MarkButton,
	BlockButton,
	LinkComponent,
	ImageComponent,
	FontSizeComponent,
	ClearButton,
} from './components';
import {
	withInlines,
	withImages,
	insertBreak,
	toggleMark,
	withListsPlugin,
} from './helpers';
import { SlateElement, SlateLeaf } from '@coUrbanize/community-modules';

// Icons
import LooksOne from '@mui/icons-material/LooksOne';
import LooksTwo from '@mui/icons-material/LooksTwo';
import Looks3Icon from '@mui/icons-material/Looks3';
import Looks4Icon from '@mui/icons-material/Looks4';
import Looks5Icon from '@mui/icons-material/Looks5';
import FormatBoldIcon from '@mui/icons-material/FormatBold';
import FormatItalicIcon from '@mui/icons-material/FormatItalic';
import FormatUnderlinedIcon from '@mui/icons-material/FormatUnderlined';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import FormatAlignLeftIcon from '@mui/icons-material/FormatAlignLeft';
import FormatAlignCenterIcon from '@mui/icons-material/FormatAlignCenter';
import FormatAlignRightIcon from '@mui/icons-material/FormatAlignRight';
import FormatAlignJustifyIcon from '@mui/icons-material/FormatAlignJustify';
import ImageIcon from '@mui/icons-material/Image';
import LinkIcon from '@mui/icons-material/Link';
import FormatClearIcon from '@mui/icons-material/FormatClear';
import _ from 'lodash';
import { onKeyDown, withListsReact } from '@prezly/slate-lists';

interface TextEditorProps {
	editorState: RichTextContent;
	setEditorState: React.Dispatch<React.SetStateAction<any>>;
	action?: (action: string, payload: object) => void;
	withImage?: boolean;
	selectedFields?: string[];
}

const toolbarBlockButtons = [
	{ format: 'link', icon: <LinkIcon /> },
	{ format: 'heading-one', icon: <LooksOne /> },
	{ format: 'heading-two', icon: <LooksTwo /> },
	{ format: 'heading-three', icon: <Looks3Icon /> },
	{ format: 'heading-four', icon: <Looks4Icon /> },
	{ format: 'heading-five', icon: <Looks5Icon /> },
	{ format: 'numbered-list', icon: <FormatListNumberedIcon /> },
	{ format: 'bulleted-list', icon: <FormatListBulletedIcon /> },
	{ format: 'left', icon: <FormatAlignLeftIcon /> },
	{ format: 'center', icon: <FormatAlignCenterIcon /> },
	{ format: 'right', icon: <FormatAlignRightIcon /> },
	{ format: 'justify', icon: <FormatAlignJustifyIcon /> },
];

const HOTKEYS = {
	'mod+b': 'bold',
	'mod+i': 'italic',
	'mod+u': 'underline',
	'mod+`': 'code',
};

export const TextEditor: React.FC<TextEditorProps> = ({
	editorState,
	setEditorState,
	action,
	withImage = false,
	selectedFields = toolbarBlockButtons.map((i) => i.format),
}) => {
	const renderElement = useCallback(
		(props: any) => (
			<SlateElement
				{...props}
				LinkComp={LinkComponent}
				ImageComp={ImageComponent}
			/>
		),
		[],
	);
	const renderLeaf = useCallback((props: any) => <SlateLeaf {...props} />, []);
	const editor = useMemo(
		() =>
			withListsReact(
				withListsPlugin(
					withImages(withInlines(withHistory(withReact(createEditor())))),
				),
			),
		[],
	);

	let nodes: Descendant[];

	if (!editorState) {
		nodes = initialValue;
	} else {
		nodes = editorState.length > 0 ? editorState : initialValue;
	}
	editor.children = nodes;

	const {
		handleDropFile,
		imageAction,
		openImageModal,
		confirmUpload,
		onCancel,
		handleUpload,
		progress,
		handleImageChange,
		imageInfo,
	} = useEditorImage(editor, action);

	const [confirmDisabled, setConfirmDisabled] = useState(false);

	const [{ canDrop, isOver }, drop] = useDrop(() => ({
		accept: [NativeTypes.FILE],
		collect: (monitor: DropTargetMonitor) => ({
			isOver: monitor.isOver(),
			canDrop: monitor.canDrop(),
		}),
	}));

	const isActive = withImage && canDrop && isOver;
	editor.insertBreak = () => insertBreak(editor);

	return (
		<>
			<Box
				sx={{
					position: 'relative',
					zIndex: 35,
				}}
				ref={drop}
			>
				<FileDragDrop
					styles={{
						position: 'absolute',
						width: '100%',
						height: '100%',
						zIndex: isActive ? 99 : 0,
					}}
					handleDrop={handleDropFile}
				/>
				<Slate
					editor={editor}
					value={nodes}
					onChange={(value) => {
						const isAstChange = editor.operations.some(
							(op: any) => 'set_selection' !== op.type,
						);
						if (isAstChange) {
							setEditorState(value);
						}
					}}
				>
					<Toolbar>
						<FontSizeComponent />
						<MarkButton format="bold" icon={<FormatBoldIcon />} />
						<MarkButton format="italic" icon={<FormatItalicIcon />} />
						<MarkButton format="underline" icon={<FormatUnderlinedIcon />} />
						{withImage && (
							<BlockButton
								format="image"
								icon={<ImageIcon />}
								action={imageAction}
							/>
						)}
						{toolbarBlockButtons
							.filter((i) => selectedFields.includes(i.format))
							.map((btn) => (
								<BlockButton
									key={JSON.stringify(btn)}
									format={btn.format}
									icon={btn.icon}
								/>
							))}
						<ClearButton icon={<FormatClearIcon />} />
					</Toolbar>
					<div
						className="moduleInstanceMain slateEditor"
						style={{
							minHeight: 250,
							border: '2px solid #eee',
							borderRadius: '5px',
							padding: '8px 12px',
							margin: '0 -20px',
						}}
					>
						<Editable
							renderElement={renderElement}
							renderLeaf={renderLeaf}
							placeholder="Enter some rich text…"
							spellCheck
							autoFocus
							onKeyDown={(event) => {
								for (const hotkey in HOTKEYS) {
									if (isHotkey(hotkey, event as any)) {
										event.preventDefault();
										const mark = _.get(HOTKEYS, hotkey);
										toggleMark(editor, mark);
									}
								}
								onKeyDown(editor, event);
							}}
						/>
					</div>
				</Slate>
			</Box>
			<FormModal
				open={openImageModal}
				fullWidth
				maxWidth="md"
				title="Add/Update Image"
				onConfirm={confirmUpload}
				onCancel={onCancel}
				confirmLabel="Upload"
				cancelLabel="Cancel"
				disabled={confirmDisabled}
				bottomDivider
			>
				<Box width="100%" pl={3} pr={3} pb={4} pt={4}>
					<UploadImage
						handleUpload={handleUpload}
						progress={progress}
						handleChange={handleImageChange}
						imageContent={imageInfo as ImageContent}
						setConfirmDisabled={setConfirmDisabled}
						showLightBox={false}
					/>
				</Box>
			</FormModal>
		</>
	);
};

const initialValue: Descendant[] = [
	{
		type: 'paragraph',
		children: [{ text: '' }],
	},
];
