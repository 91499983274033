import { useModuleSaveData } from 'hooks/useModuleSaveData';
import { FC, useEffect } from 'react';
import ModuleInstanceData from 'types/moduleInstanceData';

const LocationPhaseModule: FC<ModuleInstanceData> = (props) => {
	const { saveDataRef, config } = props;
	const { updateModuleSaveData } = useModuleSaveData(saveDataRef);

	useEffect(() => {
		updateModuleSaveData({ config, content: {} });
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [updateModuleSaveData, config]);

	return <div>Location and Phase View</div>;
};

export default LocationPhaseModule;
