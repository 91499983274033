import React, { FC } from 'react';
import { Box, Typography, IconButton, Tooltip, useTheme } from '@mui/material';
import {
	DragHandle as DragHandleIcon,
	Settings as SettingsIcon,
	Close as CloseIcon,
	Image as ImageIcon,
} from '@mui/icons-material';
import { Cell, Grid } from 'styled-css-grid';
import { useDeleteActionConfirmation } from '../../../hooks/useActionConfirmation';
import { Question } from '../../../types/types';
import { QuestionType } from './QuestionGroup';
import { TitleCaseHyphenatedWord } from '../../../utility/titleCaseHyphenatedWord';

interface QuestionSummaryItemProps {
	onQuestionRemove: (ordinal: number) => void;
	onToggleDrag: (enabled: boolean) => void;
	question: Question | null;
	setSelectedQuestion: (question: Question) => void;
}

export const QuestionSummaryItem: FC<QuestionSummaryItemProps> = (props) => {
	const { onQuestionRemove, onToggleDrag, question, setSelectedQuestion } =
		props;
	const { type, image, questionText } = question || {};
	const theme = useTheme();

	const showDeleteConfirmation = useDeleteActionConfirmation();
	const handleClick = async () => {
		await showDeleteConfirmation(
			{
				title: 'Delete Question',
				message: `Are you sure you want to delete this Question : ${questionText}?`,
			},
			() => onQuestionRemove(question!.id),
		);
	};

	const questionTypeText = (type: string) => {
		return TitleCaseHyphenatedWord.transform(
			type === QuestionType.MULTIPLE_CHOICE ? 'Checkboxes' : type,
		);
	};

	return (
		<Box
			border="1px solid #E0E0E0"
			borderRadius={2}
			overflow="hidden"
			minHeight={70}
			marginBottom={2}
		>
			<div
				onMouseEnter={() => onToggleDrag(true)}
				onMouseLeave={() => onToggleDrag(false)}
				style={{
					backgroundColor: theme.customPalette.surface.light,
					height: '26px',
					textAlign: 'center',
				}}
			>
				<DragHandleIcon
					sx={{ fontSize: 24, color: theme.customPalette.primaryColors.sky[1] }}
				/>
			</div>

			<Grid
				columnGap="0px"
				columns={'auto 30px 36px'}
				areas={['main-area settings delete']}
			>
				<Cell area="main-area" middle>
					<Typography
						padding="10px"
						sx={{
							display: 'flex',
							alignItems: 'center',
						}}
					>
						<Typography
							sx={{
								whiteSpace: 'nowrap',
								textOverflow: 'ellipsis',
								maxWidth: 550,
								overflow: 'hidden',
								paddingRight: '5px',
							}}
						>
							{questionText}
						</Typography>
						<Typography>( {questionTypeText(type as string)} )</Typography>
						{image && (
							<Tooltip title="Label includes image">
								<ImageIcon color="primary" />
							</Tooltip>
						)}
					</Typography>
				</Cell>
				<Cell area="settings" middle>
					<IconButton
						onClick={() => {
							setSelectedQuestion(question as Question);
						}}
					>
						<SettingsIcon
							fontSize="small"
							sx={{ '&:hover': { color: '#82C2FF' } }}
						/>
					</IconButton>
				</Cell>

				<Cell area="delete" middle>
					<IconButton onClick={handleClick}>
						<CloseIcon fontSize="small" sx={{ '&:hover': { color: 'red' } }} />
					</IconButton>
				</Cell>
			</Grid>
		</Box>
	);
};
