import { useMutation } from 'urql';
import { useNavigate } from 'react-router-dom';
import useNumberParams from '../../../hooks/useNumberParams';
import { AddPage } from './AddPage';

interface AddPageContainerProps {
	onAddPage: () => void;
}

export interface NewPageInput {
	name: string;
	url: string | null;
}

export const AddPageContainer = ({ onAddPage }: AddPageContainerProps) => {
	const { projectId } = useNumberParams();
	const navigate = useNavigate();
	const createPageMutation = `
        mutation createPage($newPageData: NewPageInput!){
            createPage(newPageData: $newPageData) {
                id
            }
        }
    `;
	const [, createNewPage] = useMutation(createPageMutation);

	const handleCreatePage = async (newPageInput: NewPageInput) => {
		const newPagePayload = {
			name: newPageInput.name,
			projectId: projectId,
		};
		const result = await createNewPage({
			newPageData: newPageInput.url
				? {
						...newPagePayload,
						externalUrlOverride: newPageInput.url,
				  }
				: newPagePayload,
		});
		if (result.error) {
			console.error('Error creating new page', result.error);
		}
		onAddPage();
		navigate(`/projects/${projectId}/pages/${result.data.createPage.id}`);
	};

	return <AddPage handleAddClick={handleCreatePage} />;
};
