import { Box, Typography } from '@mui/material';
import { FC } from 'react';

export const NotAuthorized: FC = () => {
	return(
		<Box
			display="flex"
			flexDirection="column"
			alignItems="center"
			justifyContent="center"
			height='100%'
		>
			<Typography variant="h5">
                You do not have the required authorization to use this feature.
			</Typography>
		</Box>
	);
}; 