const baseUrl = process.env.REACT_APP_SITE_BASE_URL;

export const projectTemplates = [
	{
		id: 1,
		name: 'Real Estate',
		url: baseUrl + '/courbanize-demo-2/home',
	},
	{
		id: 2,
		name: 'Planning',
		url: baseUrl + '/planning-template/home',
	},
	{
		id: 3,
		name: 'Construction',
		url: baseUrl + '/construction/home',
	},
];
