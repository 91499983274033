import React, { ChangeEvent, useEffect, useState } from 'react';
import { Box, Fade } from '@mui/material';
import UploadImage from './UploadImage';
import { ImageContent, ImageProperty } from '../ImageModule';
import Dialog from '../../common/ConfirmationDialog';
import { useMutation } from 'urql';
import {
	getUploadUrlMutation,
	removeFileMutation,
} from '../../../api/mutation/s3.mutations';
import { fileUploadApi } from '../../../api/apiClient';
import { useAbandonActionConfirmation } from 'hooks/useActionConfirmation';
import { useSnackBar } from '../../../providers/SnackBarProvider';
import { getObjectKey } from 'utility/getObjectKey';

interface UploadImageModalProps {
	index?: number | null;
	open: boolean;
	onClose: () => void;
	images: ImageContent[];
	setImages: (images: ImageContent[]) => void;
	showLightbox?: boolean;
	s3Folder: string;
}

const UploadImageModal: React.FC<UploadImageModalProps> = (props) => {
	const { index, open, onClose, images, setImages, showLightbox } = props;

	const showLightboxOption = showLightbox === undefined ? true : showLightbox;

	const [confirmDisabled, setConfirmDisabled] = useState(true);
	const [newImageContent, setNewImageContent] = useState<ImageContent | any>(
		{},
	);
	const [progress, setProgress] = useState(0);
	const [oldImageUrl, setOldImageUrl] = useState('');

	const [, getUploadUrl] = useMutation(getUploadUrlMutation);
	const [, removeFileInstance] = useMutation(removeFileMutation);

	const showCancelConfirmation = useAbandonActionConfirmation();
	const { notify } = useSnackBar();

	useEffect(() => {
		const isValidIndex = !isNaN(Number(index));
		if (images?.length && isValidIndex) setNewImageContent(images[index!]);
	}, [images, index]);

	const saveImageInfo = () => {
		let newImages: ImageContent[];

		// if existing image - update
		if (index || index === 0) {
			newImages = images.map((img) =>
				img.imageUrl === newImageContent.imageUrl ||
				img.imageUrl === oldImageUrl
					? newImageContent
					: img,
			);
			setOldImageUrl('');
		} else {
			const newImagesContent = images && images.length ? [...images] : [];
			newImages = [...newImagesContent, newImageContent];
		}
		setImages(newImages);
		onClose();
	};

	const handleUpload = async (files: FileList | null) => {
		if (!files) return;

		const file = files[0];
		const result = await getUploadUrl({
			folder: props.s3Folder,
			filename: file.name,
		});

		if (result.error) return;

		const uploadURL = result.data.getUploadUrl;

		const res = await fileUploadApi(uploadURL, file, setProgress);
		if (!res) return notify('Failed to upload image!', 'error');
		const imageUrl = res.split('?')[0];

		// store in state
		setNewImageContent({ ...newImageContent, imageUrl, fileName: file.name });
	};

	const handleDelete = async () => {
		const oldImageUrl = newImageContent.imageUrl;
		const objectKey = getObjectKey(oldImageUrl);
		const res = await removeFileInstance({ objectKey }); // TODO: check if image actually gets deleted

		if (res.error) return notify('Image could not be deleted', 'error');

		setOldImageUrl(oldImageUrl);
		// remove image Url and name from object
		setNewImageContent({ ...newImageContent, imageUrl: '', fileName: '' });
	};

	const handleChange = (
		property: ImageProperty,
		event?: ChangeEvent | Event,
		data?: any,
	) => {
		const value = (event?.target as HTMLInputElement)?.value || data;

		if (property === 'lightbox') {
			const checkbox = (event?.target as HTMLInputElement).checked;
			setNewImageContent({ ...newImageContent, lightbox: checkbox });
			return;
		}

		setNewImageContent({ ...newImageContent, [property]: value });
	};

	const onCancel = () => {
		showCancelConfirmation(
			{
				title: 'Abandon Changes?',
				message: 'This image will not be added',
			},
			onClose,
		);
	};

	return (
		<Dialog
			open={open}
			onCancel={onCancel}
			disableBackdropClick
			onConfirm={saveImageInfo}
			title="Upload Image"
			confirmLabel={index || index === 0 ? 'Save' : 'Upload'}
			paddingSize="small"
			confirmDisabled={confirmDisabled}
			style={{ width: 900, maxWidth: '100%' }}
		>
			<Fade in={open}>
				<Box>
					<UploadImage
						handleUpload={handleUpload}
						progress={progress}
						handleChange={handleChange}
						imageContent={newImageContent}
						setConfirmDisabled={setConfirmDisabled}
						showImageScale={false}
						showImagePosition={false}
						showLightBox={showLightboxOption}
						handleDelete={handleDelete}
					/>
				</Box>
			</Fade>
		</Dialog>
	);
};

export default UploadImageModal;
