import { gql } from 'urql';

export const formResponses = gql`
	query ($projectId: Int!) {
		formResponsesByProjectId(projectId: $projectId) {
			id
			formJson
			createdByUser {
				fullName
				id
				firstName
			}
			responsesJson
			formProcessorTypeId
			created
		}
	}
`;
