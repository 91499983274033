import { FC } from 'react';
import ModuleInstanceData from '../../types/moduleInstanceData';
import { MODULE_TYPE } from '../../types/moduleTypes';
import moduleComponents from './utils/moduleComponents';

export interface GenericModuleProps extends ModuleInstanceData {
	type?: MODULE_TYPE;
	setShowCancel?(n: boolean): void;
	setShowConfirm?(n: boolean): void;
	setConfirmDisabled?(n: boolean): void;
	id?: number;
	handleClose?: () => void;
	handleUpdateModule?: (id: number, newContent: any) => void;
}

const defaultProps: GenericModuleProps = {
	type: MODULE_TYPE.FREE_TEXT,
	config: null,
	content: null,
};

const GenericModule: FC<GenericModuleProps> = (_props) => {
	const { type, ...props } = _props;
	const Module = moduleComponents.get(type as MODULE_TYPE);

	return Module ? <Module {...props} /> : null;
};

GenericModule.defaultProps = defaultProps;

export default GenericModule;
