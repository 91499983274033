import { gql } from 'urql';

export const createAvatarMutation = gql`
    mutation createAvatar($newAvatarData: NewAvatarInput!){
        createAvatar(newAvatarData: $newAvatarData) {
            id
        }
    }
`;

export const updateAvatarMutation = gql`
    mutation updateAvatar($existingAvatarData: ExistingAvatarInput!) {
        updateAvatar(existingAvatarData: $existingAvatarData){
            id
        }
    }
`;