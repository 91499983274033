import { DeleteOutline, MoreVert } from '@mui/icons-material';
import {
	Alert,
	AlertTitle,
	Link as MUILink,
	Tooltip,
	Typography,
	useTheme,
} from '@mui/material';
import EditIcon from 'components/icons/EditIcon';
import React, { useState } from 'react';
import { FC, useEffect } from 'react';
import LinkBehavior from '../../common/links/Link';
import useNumberParams from '../../../hooks/useNumberParams';

interface EmptyModuleAlertProps {
	module: any;
}

const EmptyModuleAlert: FC<EmptyModuleAlertProps> = ({ module }) => {
	const theme = useTheme();
	const [showTimelineLink, setShowTimelineLink] = useState(false);
	const [showPublicBenefitsLink, setShowPublicBenefitsLink] = useState(false);
	const { projectId } = useNumberParams();

	useEffect(() => {
		if (module && module.moduleType.name.toLowerCase() === 'timeline') {
			setShowTimelineLink(true);
		}
		if (module && module.moduleType.name.toLowerCase() === 'public benefit') {
			setShowPublicBenefitsLink(true);
		}
	}, [module]);

	return (
		<Alert
			severity="warning"
			sx={{
				'& .MuiAlert-icon': {
					color: theme.customPalette.primaryColors.sky[1],
				},
				color: theme.customPalette.primaryColors.sky[1],
				border: `1px solid ${theme.customPalette.primaryColors.sky[1]}`,
				backgroundColor: '#fff',
			}}
		>
			<AlertTitle>Heads up </AlertTitle>This widget is currently empty.{' '}
			{showTimelineLink ? (
				<>
					Manage Timeline{' '}
					<MUILink
						href={`/projects/${projectId}/timeline`}
						component={LinkBehavior}
						underline="none"
						sx={{ fontWeight: 800 }}
					>
						here
					</MUILink>
				</>
			) : showPublicBenefitsLink ? (
				<>
					Manage Public Benefits{' '}
					<MUILink
						href={`/projects/${projectId}/public-benefits`}
						component={LinkBehavior}
						underline="none"
						sx={{ fontWeight: 800 }}
					>
						here
					</MUILink>
				</>
			) : (
				<>
					Please click the
					<Tooltip arrow placement="bottom" title="Three button menu">
						<Typography
							sx={{ display: 'inline' }}
							color={theme.customPalette.primaryColors.sky[1]}
						>
							<MoreVert
								fontSize="small"
								sx={{ transform: 'translateY(4px)' }}
							/>
						</Typography>
					</Tooltip>
					on the upper right of this box and select{' '}
					<Tooltip
						arrow
						placement="bottom"
						title={`Edit ${module.moduleType.name}`}
					>
						<span style={{ padding: '0px 2px' }}>
							<EditIcon size={16} style={{ transform: 'translateY(2px)' }} />
						</span>
					</Tooltip>{' '}
					to add something or{' '}
					<Tooltip
						arrow
						placement="bottom"
						title={`Delete ${module.moduleType.name}`}
					>
						<span>
							<DeleteOutline
								fontSize="small"
								sx={{ transform: 'translateY(4px)' }}
							/>
						</span>
					</Tooltip>{' '}
					to remove it
				</>
			)}
		</Alert>
	);
};

export default EmptyModuleAlert;
