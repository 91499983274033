import React, { useEffect } from 'react';
import { Box } from '@mui/material';
import VideoLibraryIcon from '@mui/icons-material/VideoLibrary';
import TextButton from './styled/TextButton';

interface HelpScoutLinkProps {
  articleId: string;
  label: string;
  sx?: any;
  endIcon?: React.ReactNode;
}

const HelpScoutLink: React.FC<HelpScoutLinkProps> = ({ articleId, label, sx, endIcon }) => {
  useEffect(() => {
    if (!window.Beacon) {
      const script = document.createElement('script');
      script.src = 'https://beacon-v2.helpscout.net';
      script.async = true;
      document.body.appendChild(script);

      script.onload = () => {
        window.Beacon('init', '773c6b79-db9d-4bb9-a3e2-702ea3370b79');
      };
    }
  }, []);

  return (

    <Box my={2}>
      <a href='#' role="button" aria-label={label}>
        <TextButton
          data-beacon-article-inline={articleId}
          sx={{
            ...sx,
            fontWeight: 800,
            textTransform: 'capitalize',
            fontSize: 16,
            marginLeft: '-12px',
            px: 2,
          }}
          endIcon={endIcon ? endIcon : <VideoLibraryIcon />}
        >
          {label}
        </TextButton>
      </a>
    </Box>
  );
};

export default HelpScoutLink;
