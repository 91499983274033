import React from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { Box } from '@mui/material';
import {
	DeleteOutline,
	DragHandle as DragHandleIcon,
} from '@mui/icons-material';
import { ImageContent } from '../modules/ImageModule';
import IconButton from '@mui/material/IconButton';

interface ImageCardProps {
	imageInfo: ImageContent;
	onDoubleClick?: () => void;
	setIsDragging: (val: boolean) => void;
	moveCard: (id: string, atIndex: number) => void;
	findCard: (id: string) => { index: number };
	deleteCard: (imageUrl: string, index: number) => void;
	hasDropped?: boolean;
	deleteImage: (id: string) => void;
}

const ImageCard: React.FC<ImageCardProps> = (props) => {
	const {
		imageInfo,
		onDoubleClick,
		moveCard,
		findCard,
		deleteCard,
		setIsDragging,
		hasDropped,
		deleteImage,
	} = props;

	const [isDropped, setIsDropped] = React.useState(false);

	const id = imageInfo?.imageUrl;
	const originalIndex = findCard(id!).index;

	const [{ isDragging }, drag, preview] = useDrag(
		() => ({
			type: 'card',
			item: { id, originalIndex },
			collect: (monitor) => ({
				isDragging: monitor.isDragging(),
			}),
			end: (item, monitor) => {
				setIsDropped(true);
				const didDrop = monitor.didDrop();

				if (!didDrop) {
					deleteCard(id!, findCard(id!).index);
				}
			},
		}),
		[id, deleteCard, setIsDropped],
	);

	const [, drop] = useDrop(
		() => ({
			accept: 'card',
			hover({ id: draggedId }: any) {
				if (draggedId !== id) {
					const { index: overIndex } = findCard(id!);
					moveCard(draggedId, overIndex);
				}
			},
		}),
		[findCard, moveCard],
	);

	const opacity = isDragging ? 0 : 1;

	if (isDragging) setIsDragging(true);
	else if (hasDropped || isDropped) setIsDragging(false);

	return (
		<Box
			border="1px solid #E5E5E5 !important"
			borderRadius={4}
			overflow="hidden"
			onDoubleClick={onDoubleClick}
			style={{ opacity }}
			ref={(node: any) => preview(drop(node))}
		>
			<Box>
				<Box
					ref={drag}
					height="36px"
					textAlign="center"
					display="flex"
					alignItems="center"
					justifyContent="center"
					sx={(theme) => ({
						backgroundColor: theme.customPalette.surface.light,
					})}
				>
					<Box
						sx={{ width: '60%', display: 'flex', justifyContent: 'flex-end' }}
					>
						<DragHandleIcon
							sx={(theme) => ({
								fontSize: 24,
								color: theme.customPalette.primaryColors.sky[1],
							})}
						/>
					</Box>
					<Box
						sx={(theme) => ({
							fontSize: 24,
							color: theme.customPalette.primaryColors.sky[1],
							width: '50%',
							display: 'flex',
							justifyContent: 'flex-end',
							paddingRight: '10px',
						})}
					>
						<IconButton onClick={() => deleteImage(id!)}>
							<DeleteOutline />
						</IconButton>
					</Box>
				</Box>
				<Box
					sx={(theme) => ({
						cursor: 'pointer',
						backgroundColor: theme.customPalette.systemColors.disabled,
					})}
				>
					<img
						src={imageInfo?.imageUrl}
						alt={imageInfo?.altTag}
						height={144}
						width={212}
						style={{ borderRadius: 2, objectFit: 'cover' }}
					/>
				</Box>
			</Box>
		</Box>
	);
};

export default ImageCard;
