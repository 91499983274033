import React, { FC, useState } from 'react';
import { Box, Card, TextField } from '@mui/material';
import { Maybe } from 'types/types';
import { EventType } from '../hooks/useProjectSettings';
import { validateEmail } from 'utility/string';
import EmailItem from './EmailItem';

interface EmailSandBoxCardProps {
	initialValue: Maybe<string> | undefined;
	valueKey: EventType;
	handlePublish: (valueKey: EventType, value: string) => void;
}

const EmailSandBoxCard: FC<EmailSandBoxCardProps> = (props) => {
	const { initialValue, valueKey, handlePublish } = props;
	const [newEmail, setNewEmail] = useState('');
	const [emails, setEmails] = useState(initialValue?.split(',') || []);
	const [show, setShow] = useState(false);

	const handleNewEmail = (e: React.ChangeEvent<HTMLInputElement>) => {
		const currentEmail = e.target.value;
		setNewEmail(currentEmail);

		if (validateEmail(currentEmail)) setShow(true);
		else setShow(false);
	};

	const handleAddEmail = () => {
		const updatedEmails = [...emails, newEmail];
		setEmails(updatedEmails);
		setNewEmail('');
		setShow(false);

		handlePublish(valueKey, updatedEmails.join(','));
	};

	const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
		if (event.key === 'Enter' && show) {
			handleAddEmail();
		}
	};

	const handleClose = (email: string) => {
		const updatedEmails = emails.filter((e) => e !== email);
		setEmails(updatedEmails);

		handlePublish(valueKey, updatedEmails.join(','));
	};

	return (
		<Card
			sx={{
				padding: 2,
				marginBottom: 4,
			}}
		>
			<Box>
				<TextField
					placeholder={
						emails.length ? '' : 'Enter an email address and press enter to add'
					}
					label="Email Address"
					variant="outlined"
					size="small"
					style={{
						width: '100%',
						fontWeight: 'bold',
						color: '#5F6368',
					}}
					value={newEmail}
					onChange={handleNewEmail}
					onKeyDown={handleKeyDown}
					autoFocus
					sx={{ marginBottom: 2 }}
				/>
				{show && (
					<Box
						sx={{
							backgroundColor: '#E6E6E6',
							'&:hover': { backgroundColor: '#efefef' },
						}}
						onClick={handleAddEmail}
					>
						<EmailItem
							email={newEmail}
							hideClose={show}
							style={{ border: 0 }}
						/>
					</Box>
				)}
				{emails.map((email) => (
					<EmailItem email={email} onClose={handleClose} />
				))}
			</Box>
		</Card>
	);
};

export default EmailSandBoxCard;
