import { gql } from 'urql';

export const updateFormMutation = gql`
	mutation ($existingForm: ExistingFormInput!) {
		updateForm(existingForm: $existingForm) {
			status
			formName
		}
	}
`;

export const createFormMutation = gql`
	mutation ($newForm: NewFormInput!) {
		createForm(newForm: $newForm) {
			formName
			projectId
			id
			moduleInstanceId
			formProcessorTypeId
		}
	}
`;
