import React, { FC, useContext } from 'react';
import {
	Box,
	Typography,
	IconButton,
	Tooltip,
	useTheme,
} from '@mui/material';
import {
	DragHandle as DragHandleIcon,
	Settings as SettingsIcon,
	Close as CloseIcon,
	Image as ImageIcon,
} from '@mui/icons-material';
import { Cell, Grid } from 'styled-css-grid';
import { FormBuilderFieldConfig } from '../../../types/formBuilderConfig';
import { FormModuleContext } from './FormModule';
import { FORM_BUILDER_FIELD_TYPE } from '../../../types/formBuilderFieldTypes';
import { useDeleteActionConfirmation } from '../../../hooks/useActionConfirmation';

interface FieldSummaryItemProps extends FormBuilderFieldConfig {
	onFieldRemove: (ordinal: number) => void;
	onToggleDrag: (enabled: boolean) => void;
}

export const FieldSummaryItem: FC<FieldSummaryItemProps> = (props) => {
	const {
		type,
		label,
		ordinal,
		isRequired,
		descriptionField,
		image,
		onFieldRemove,
		onToggleDrag,
		labelHasImage,
	} = props;
	const { setCurrentField, setFormTitle, setIsNewField } =
		useContext(FormModuleContext);
	const desc = descriptionField?.content?.plainText;
	const theme = useTheme();

	const showDeleteConfirmation = useDeleteActionConfirmation();
	const handleClick = async () => {
		await showDeleteConfirmation(
			{
				title: 'Delete Field',
				message: `Are you sure you want to delete this ${FORM_BUILDER_FIELD_TYPE[type]} field?`,
			},
			() => onFieldRemove(ordinal),
		);
	};

	const getText = () => {
		if (FORM_BUILDER_FIELD_TYPE[type] === 'Description') return desc;
		else if (FORM_BUILDER_FIELD_TYPE[type] === 'Image')
			return image.imageUrl ? `${image?.fileName}, ${image?.altTag}` : '';
		else return label || '';
	};

	return (
		<Box
			border="1px solid #E0E0E0"
			borderRadius={2}
			overflow="hidden"
			minHeight={70}
			marginBottom={2}
		>
			<div
				onMouseEnter={() => onToggleDrag(true)}
				onMouseLeave={() => onToggleDrag(false)}
				style={{
					backgroundColor: theme.customPalette.surface.light,
					height: '26px',
					textAlign: 'center',
				}}
			>
				<DragHandleIcon
					sx={{ fontSize: 24, color: theme.customPalette.primaryColors.sky[1] }}
				/>
			</div>

			<Grid
				columnGap="0px"
				columns={'auto 30px 36px'}
				areas={['main-area settings delete']}
			>
				<Cell area="main-area" middle>
					<Typography
						padding="10px"
						sx={{
							display: 'flex',
							alignItems: 'center',
						}}
					>
						<Typography
							sx={{
								whiteSpace: 'nowrap',
								textOverflow: 'ellipsis',
								maxWidth: 550,
								overflow: 'hidden',
								paddingRight: '5px',
							}}
						>
							{getText()}
						</Typography>
						<Typography>( {FORM_BUILDER_FIELD_TYPE[type]} )</Typography>
						{labelHasImage && (
							<Tooltip title="Label includes image">
								<ImageIcon color="primary" />
							</Tooltip>
						)}
						{isRequired && (
							<Typography fontSize={12} paddingLeft="5px">
								*Required
							</Typography>
						)}
					</Typography>
				</Cell>
				{props.type !== 'DIVIDER' ? (
					<Cell area="settings" middle>
						<IconButton
							onClick={() => {
								setCurrentField(props);
								setFormTitle(`Edit ${FORM_BUILDER_FIELD_TYPE[props.type]}`);
								setIsNewField(false);
							}}
						>
							<SettingsIcon
								fontSize="small"
								sx={{ '&:hover': { color: '#82C2FF' } }}
							/>
						</IconButton>
					</Cell>
				) : null}

				<Cell area="delete" middle>
					<IconButton onClick={handleClick}>
						<CloseIcon fontSize="small" sx={{ '&:hover': { color: 'red' } }} />
					</IconButton>
				</Cell>
			</Grid>
		</Box>
	);
};
