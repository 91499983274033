import { FC, useState, useEffect } from 'react';
import { Box, Typography } from '@mui/material';
import TemplatePreviewCard from 'components/common/TemplatePreviewCard';
import RegularButton from 'components/common/styled/RegularButton';
import StyledToggleButtonGroup from 'components/common/styled/StyledToggleButtonGroup';
import TextButton from 'components/common/styled/TextButton';
import { projectTemplates } from '../constants/projectTemplates';
import { Dialog, DialogActions, ToggleButton } from '@mui/material';
import { usePausedContext } from '@coUrbanize/community-modules';
import Loading from 'components/common/Loading';
import { useMutation } from 'urql';
import { copyTemplateMutation } from 'api/mutation/projectTemplate.mutations';
import useNumberParams from 'hooks/useNumberParams';
import { useSnackBar } from 'providers/SnackBarProvider';
import { useNavigate } from 'react-router-dom';
import * as React from 'react';
import { useSelectActionConfirmation } from '../hooks/useActionConfirmation';

const Templates: FC = () => {
	const [loading, setLoading] = useState(true);
	const [template, setOpenTemplate] = useState<any>({});

	const { notify } = useSnackBar();
	const { projectId } = useNumberParams();
	const [, copyTemplate] = useMutation(copyTemplateMutation);
	const navigate = useNavigate();
	const { isPaused } = usePausedContext();

	const showActionConfirmation = useSelectActionConfirmation();
	const handleClick = async () => {
		await showActionConfirmation(
			{
				title: 'Select Site Template',
				message:
					'Selecting this template will replace all existing site content. Proceed?',
			},
			() => handleSelect(),
		);
	};

	useEffect(() => {
		if (!template.id) {
			setLoading(true);
		}
	}, [template]);

	const changeTemplate = (
		event: React.MouseEvent<HTMLElement>,
		newID: Number,
	) => {
		setLoading(true);
		if (newID) {
			const templateFromID = projectTemplates.filter((i) => i.id === newID)[0];
			setOpenTemplate(templateFromID);
		}
	};

	const handleSelect = async () => {
		// display loader
		setLoading(true);
		// run script
		const templateUrlArray = template.url.split('/');
		const templateSlug: string = templateUrlArray[templateUrlArray.length - 2];
		const res = await copyTemplate({ projectId, templateSlug });

		if (res.error) return notify('Failed to generate template', 'error');

		setOpenTemplate({});
		notify('Template Generated Successfully!');
		navigate(`/projects/${projectId}/pages`);
		// redirect to page builder once done
	};

	return (
		<Box>
			<Box paddingTop={2} display="flex" gap={3} flexWrap="wrap">
				{projectTemplates.map((t, index) => (
					<TemplatePreviewCard
						key={t.id}
						template={t}
						imageIndex={index}
						setOpenTemplate={setOpenTemplate}
					/>
				))}
			</Box>
			{template.id && (
				<Dialog open={template.id} fullScreen>
					{loading && (
						<Box
							position="absolute"
							top="50%"
							right="50%"
							sx={{ transform: 'translate(-50%, -50%)' }}
						>
							<Loading />
						</Box>
					)}
					<iframe
						src={template.url}
						title={template.name}
						width="100%"
						height="100%"
						style={{ border: 'none' }}
						onLoad={() => setLoading(false)}
					>
						Seems Like Your Browser Doesn't Support Iframes
					</iframe>
					<DialogActions
						sx={{
							backgroundColor: '#EAF3F5',
							height: 80,
							px: 7,
							justifyContent: { lg: 'center', xs: 'left' },
						}}
					>
						<Box
							display="flex"
							borderRadius="20"
							alignItems="center"
							gap={2}
							sx={{
								background: '#0081AE',
								borderRadius: 20,
								padding: '4px 6px 4px 16px',
							}}
						>
							<Typography sx={{ color: '#FFF', fontWeight: 500 }}>
								Templates Preview:
							</Typography>
							<StyledToggleButtonGroup
								color="primary"
								value={template?.id}
								exclusive
								onChange={changeTemplate}
								aria-label="Platform"
							>
								{projectTemplates.map((t) => (
									<ToggleButton key={t.id} value={t.id}>
										{t.name}
									</ToggleButton>
								))}
							</StyledToggleButtonGroup>
						</Box>
						<Box
							sx={{
								position: 'absolute',
								right: 55,
							}}
						>
							<TextButton
								sx={{ ...btnStyles, mr: 3 }}
								onClick={() => setOpenTemplate({})}
							>
								Close
							</TextButton>
							<RegularButton
								disabled={!!isPaused}
								onClick={handleClick}
								sx={btnStyles}
							>
								Select Template
							</RegularButton>
						</Box>
					</DialogActions>
				</Dialog>
			)}
		</Box>
	);
};

export default Templates;

const btnStyles = {
	padding: '6px 23px',
	textTransform: 'none',
	fontSize: 16,
};
