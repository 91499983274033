import React, { FC, ReactNode, useState } from 'react';
import { useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';

interface IframeTesterProps {
	src: string;
	title: string;
	notAllowedMessage?: ReactNode;
}
const IframeTester: FC<IframeTesterProps> = (props) => {
	const { src, notAllowedMessage, title } = props;
	const [isAllowed, setIsAllowed] = useState(true);
	const { getAccessTokenSilently: getToken } = useAuth0();
	useEffect(() => {
		const checkIframeAsync = async () => {
			try {
				const token = await getToken();
				const response = await fetch(
					`${process.env.REACT_APP_API_URI}/pages/can-iframe`,
					{
						method: 'POST',
						headers: {
							'Content-Type': 'application/json',
							Authorization: `Bearer ${token}`,
						},
						body: JSON.stringify({ url: src }),
					},
				);
				const data = await response.json();
				if (data.isAllowed === false) {
					setIsAllowed(false);
				} else {
					setIsAllowed(true);
				}
			} catch (error) {
				setIsAllowed(false);
			}
		};
		checkIframeAsync();
	}, [src, getToken]);

	return (
		<>
			{isAllowed && (
				<iframe
					//sandbox='allow-scripts allow-modal' //TODO: see what we can restrict here
					src={src}
					title={title}
					style={{ width: '100%', height: '100vh' }}
				/>
			)}
			{!isAllowed && (
				<p>
					{notAllowedMessage ? (
						notAllowedMessage
					) : (
						<>Sorry, this source does not allow iframes.</>
					)}
				</p>
			)}
		</>
	);
};

export default IframeTester;
