import { FC, Dispatch, SetStateAction } from 'react';
import { Card, TextField } from '@mui/material';
import RegularButton from 'components/common/styled/RegularButton';
import { Maybe, Project } from 'types/types';
import { EventType } from '../hooks/useProjectSettings';
import EmailSandBoxCard from './EmailSandBoxCard';

interface OptionCardProps {
	name: string;
	label: string | undefined;
	value: Maybe<string> | undefined;
	initialValue: Maybe<string> | undefined;
	valueKey: EventType;
	handlePublish: (valueKey: EventType, value: string) => void;
	setProject: Dispatch<SetStateAction<Project>>;
}

const OptionCard: FC<OptionCardProps> = (props) => {
	const {
		name,
		label,
		value,
		initialValue,
		valueKey,
		handlePublish,
		setProject,
	} = props;

	if (name === 'emailSandboxEnabledDate')
		return (
			<EmailSandBoxCard
				initialValue={initialValue}
				valueKey={valueKey}
				handlePublish={handlePublish}
			/>
		);

	return (
		<Card
			sx={{
				padding: 2,
				marginBottom: 4,
				display: 'flex',
				alignItems: 'center',
				columnGap: 2,
			}}
		>
			<TextField
				id={name}
				label={label}
				variant="outlined"
				size="small"
				fullWidth
				multiline
				value={value}
				onChange={(e) =>
					setProject((project) => ({
						...project,
						[valueKey]: e.target.value,
					}))
				}
			/>
			<RegularButton
				disabled={!value || value === initialValue}
				onClick={() => handlePublish(valueKey, value!)}
			>
				Save
			</RegularButton>
		</Card>
	);
};

export default OptionCard;
