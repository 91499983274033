import React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Divider from '@mui/material/Divider';
import UserAvatar from '../../common/Avatar';
import dayjs from 'dayjs';

interface User {
	fullName: string;
	created: string;
	avatarUrl?: string | null | undefined;
}

interface FollowersListProps {
	users: User[];
}

export const FollowersList: React.FC<FollowersListProps> = (props) => {
	const { users } = props;
	return (
		<List>
			{users
				.sort((a, b) => a.fullName.localeCompare(b.fullName))
				.map((user) => (
					<React.Fragment key={user.fullName}>
						<ListItem key={user.fullName}>
							<ListItemAvatar>
								<UserAvatar userOverride={user} />
							</ListItemAvatar>
							<ListItemText
								primary={user.fullName}
								secondary={dayjs(user.created, 'YYYY-MM-DD').format(
									'MMMM D, YYYY',
								)}
							/>
						</ListItem>
						<Divider variant="inset" component="li" />
					</React.Fragment>
				))}
		</List>
	);
};
