export const createTemplateInstanceMutation = `
    mutation createTemplateInstance($newTemplateInstanceData: NewTemplateInstanceInput!){
        createTemplateInstance(newTemplateInstanceData: $newTemplateInstanceData) {
            id
        }
    }
`;

export const removeTemplateInstanceMutation = `
mutation removeTemplateInstance($id: Int!){
    removeTemplateInstance(id: $id)
}
`;

export const updateTemplateInstanceMutation = `
  mutation updateTemplateInstance($existingTemplateInstanceData: ExistingTemplateInstanceInput!) {
    updateTemplateInstance(existingTemplateInstanceData: $existingTemplateInstanceData){
        id
    }
  }
`;
