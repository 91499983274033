import { Box } from '@mui/material';
import { useQuery } from 'urql';
import ProjectList from 'components/features/projectList/ProjectList';
import Loading from 'components/common/Loading';
import { useUserContext } from 'providers/UserProvider';
import { projectsNoRelationsQuery } from 'api/query/project.queries';
import { Project } from 'types/types';
import { useRouteContext } from './Layout';
import { getProjectIds } from 'utility/projects';
import User from 'types/user';

export const MyProjects = () => {
	const userData = useUserContext();
	const context = useRouteContext();

	const [{ data: projectData, fetching: projectsFetching }] = useQuery<{
		projectsNoRelations: Project[];
	}>({
		query: projectsNoRelationsQuery(),
	});

	if (projectsFetching) {
		return <Loading />;
	}

	const projectIds = getProjectIds(
		userData?.user as User,
		projectData?.projectsNoRelations as Project[],
	);

	const projects = projectData?.projectsNoRelations.filter((x) =>
		projectIds.includes(x.id),
	);

	return (
		<Box py={2} px={5}>
			<h2>My Projects</h2>
			{projects && <ProjectList context={context} projects={projects} />}
		</Box>
	);
};
