import { Box, Typography } from '@mui/material';
import { FC } from 'react';
import { ProjectUserInviteForm } from './ProjectUserInviteForm';
import { ProjectUserTable } from './ProjectUserTable';
import { ProjectUser } from 'types/types';

interface ProjectUserManagerLayoutProps {
	projectUsers: ProjectUser[];
	onInviteClick: (firstName: string, lastName: string, email: string) => any;
	refreshUsers: () => void;
}

export const ProjectUserManagerLayout: FC<ProjectUserManagerLayoutProps> = (
	props: ProjectUserManagerLayoutProps,
) => {
	const { projectUsers, onInviteClick, refreshUsers } = props;

	return (
		<Box display="flex" flexDirection="column" alignItems="flex-start">
			<Typography variant="body2" pb={4} color="secondary">
				Project administrators can edit page content, invite and remove other
				admins, and manage admin notifications
			</Typography>
			<ProjectUserInviteForm onInviteClick={onInviteClick} />
			<ProjectUserTable
				projectUsers={projectUsers}
				refreshUsers={refreshUsers}
			/>
		</Box>
	);
};
