import { useState } from 'react';
import { useMutation } from 'urql';
import { useSaveActionConfirmation } from 'hooks/useActionConfirmation';
import { Project } from './../../../../types/types';
import { updateProjectSettingsMutation } from './../../../../api/mutation/project.mutation';
import determineModal from '../ProjectSettings/determineModal';
import validateForm from '../ProjectSettings/validateForm';
import { useSnackBar } from 'providers/SnackBarProvider';

export type EventType =
	| 'launchedDate'
	| 'pausedDate'
	| 'disabledDate'
	| 'emailEnabledDate'
	| 'archivedDate'
	| 'localizeProjectKey'
	| 'translationEnabledDate'
	| 'customUrl'
	| 'customUrlEnabledDate'
	| 'emailSandbox'
	| 'emailSandboxEnabledDate'
	| 'smsSubscribeMobileNumber'
	| 'smsSubscribeEnableDate'
	| 'metaDescription'
	| 'showFeedbackCount'
	| 'aiInsightsEnabledDate'
	| 'autoAiInsightsEnabledDate';

const useProjectSettings = (
	initialValues: Project,
	onSubmitClick: (values: any) => void,
) => {
	const [project, setProject] = useState(initialValues);
	const showActionConfirmation = useSaveActionConfirmation();
	const { notify } = useSnackBar();

	const [, updateProjectSettings] = useMutation(updateProjectSettingsMutation);

	const handleGaUpdate = async (value: object) => {
		const res = await updateProjectSettings({
			existingProjectData: value,
		});

		if (res?.error)
			return notify('Fail to update Google Analytics variables', 'error');
		console.log(value);
		setProject({ ...project, ...value });
		return notify('Google Analytics keys updated successfully');
	};

	const handleStyleUpdate = async (values: object) => {
		const res = await updateProjectSettings({
			existingProjectData: values,
		});

		if (res?.error) return notify('Fail to update style settings', 'error');
		console.log(values);
		setProject({ ...project, ...values });
		return notify('Style settings updated successfully');
	};

	const handlePublish = async (
		type: EventType,
		value: string | null | boolean,
	) => {
		const isInvalidForm = validateForm(type, value);
		if (isInvalidForm) return notify(isInvalidForm, 'error');

		if (type === 'emailSandbox' && value) {
			const res = await updateProjectSettings({
				existingProjectData: { id: initialValues.id!, emailSandbox: value },
			});

			if (res?.error) return notify('Fail to update email sandbox', 'error');

			setProject({ ...project, emailSandbox: value as string });
			//  to save in project details state
			onSubmitClick({ ...project, [type]: value });
			return notify('Email sandbox updated successfully');
		}

		const modalInfo = determineModal(type, !!value);

		await showActionConfirmation(modalInfo, async () => {
			const res = await updateProjectSettings({
				existingProjectData: { id: initialValues.id!, [type]: value },
			});
			let message = modalInfo.title.split(' ').join('d ');
			if (type === 'launchedDate') message = 'Project Launched Successfully!';
			if (res?.error) notify(`Failed to ${modalInfo.title}`, 'error');
			else notify(`${message} Successfully!`);

			// null customUrl from state when disabled
			if (type === 'customUrlEnabledDate' && value === null)
				setProject({ ...project, [type]: value, customUrl: null });
			else setProject({ ...project, [type]: value });

			//  to save in project details state
			onSubmitClick({ ...project, [type]: value });
		});
	};

	return {
		project,
		setProject,
		handlePublish,
		handleGaUpdate,
		handleStyleUpdate,
	};
};

export default useProjectSettings;
