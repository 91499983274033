import React, { FC, useEffect, useState } from 'react';
import { Box, Card, IconButton, Tooltip } from '@mui/material';
import { InformationPlan } from './InformationPlansForm';
import {
	FilePresentTwoTone,
	DragIndicator as DragIndicatorIcon,
	DeleteOutline as DeleteOutlineIcon,
} from '@mui/icons-material';
import { Cell, Grid } from 'styled-css-grid';
import RegularTextInput from 'components/common/styled/RegularTextInput';
import { useDeleteActionConfirmation } from '../../../hooks/useActionConfirmation';

interface InfoPlanRowProps {
	informationPlan: InformationPlan;
	handle?: any;
	onTitleUpdate: (id: number, title: string) => void;
	handleDelete?: (id: number) => void;
	disabled?: boolean;
}

const InfoPlanRow: FC<InfoPlanRowProps> = (props) => {
	const { informationPlan, handle, onTitleUpdate, handleDelete, disabled } =
		props;
	const [title, setTitle] = useState(informationPlan.title || '');

	const showDeleteConfirmation = useDeleteActionConfirmation();
	const handleClick = async () => {
		await showDeleteConfirmation(
			{
				title: 'Delete Document',
				message: `Are you sure you want to delete ${informationPlan.title}?`,
			},
			() => handleDelete && handleDelete(informationPlan.id),
		);
	};

	useEffect(() => {
		setTitle(informationPlan.title);
	}, [informationPlan.title]);

	return (
		<Box mb={1.5}>
			<Card sx={{ padding: 1 }}>
				<Grid
					columns={'minmax(20px, auto) 1fr minmax(20px, auto)'}
					alignContent="center"
					justifyContent="space-between"
				>
					<Cell middle {...handle}>
						<DragIndicatorIcon htmlColor="gray" sx={{ cursor: 'pointer' }} />
					</Cell>
					<Cell middle>
						<Grid alignContent="center">
							<Cell middle>
								<a
									href={informationPlan.url || informationPlan.file?.url}
									target="_self"
									rel="noreferrer"
									title={`${informationPlan.file?.fileName}`}
									style={{
										color: '#0099cc',
										textDecoration: 'none',
										display: 'flex',
									}}
								>
									<FilePresentTwoTone />
								</a>
							</Cell>
							<Cell>
								<RegularTextInput
									value={title}
									size="small"
									sx={{
										minWidth: 200,
										maxWidth: 480,
										width: title.length * 6.2,
									}}
									onChange={(e) => setTitle(e.target.value)}
									onBlur={(e) =>
										onTitleUpdate(informationPlan.id, e.target.value)
									}
									disabled={disabled}
								/>
							</Cell>
						</Grid>
					</Cell>
					<Cell>
						{handleDelete && (
							<Tooltip
								title={`Delete ${informationPlan.file?.fileName}`}
								placement="left"
								arrow={true}
							>
								<IconButton onClick={handleClick} disabled={disabled}>
									<DeleteOutlineIcon
										style={{ marginRight: '0px' }}
										fontSize="small"
									/>
								</IconButton>
							</Tooltip>
						)}
					</Cell>
				</Grid>
			</Card>
		</Box>
	);
};

export default InfoPlanRow;
