import dayjs from 'dayjs';

export const formatDateTime = (
	date: string | undefined,
	type: 'date' | 'time',
) => {
	if (!date) return '';
	if (type === 'date') return dayjs(date).format('MM/DD/YY');
	return dayjs(date).format('hh:mm a');
};

export const getLaunchedDate = (date: string) => {
	if (!date) return '';

	return `${new Date(date).getMonth() + 1}/${new Date(
		date,
	).getDate()}/${new Date(date).getFullYear()}`;
};
