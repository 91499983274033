import { gql } from 'urql';

export const eventsByTimelineQuery = gql`
  query ($timelineId: Int!) {
    eventsByTimeline(timelineId: $timelineId) {
      id
      name
      description
      startDate
      startTime
      endDate
      endTime
      displayDate
      milestoneIcon
      file
    }
  }
`;
