import { Cell, Grid } from 'styled-css-grid';
import {
	Avatar,
	Box,
	Chip,
	IconButton,
	Stack,
	Tooltip,
	Typography,
	useTheme,
	Menu,
	MenuItem,
	Button
} from '@mui/material';
import { getAvatarColor } from '../../../utility/getAvatarColors';
import dayjs from 'dayjs';
import {
	AddCommentOutlined,
	Done,
	DoNotDisturb,
	Flag,
	Reply,
	Star,
	Edit,
	MoreVert,
	Lightbulb,
} from '@mui/icons-material';
import PushPinIcon from '@mui/icons-material/PushPin';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import TextButton from '../../common/styled/TextButton';
import React, {
	Dispatch,
	FC,
	SetStateAction,
	useEffect,
	useMemo,
	useState,
	MouseEvent,
} from 'react';
import { useNavigate } from 'react-router-dom';
import { Comment as CommentType, User as UserType } from '../../../types/types';
import { defaultFilter, Filter } from '../../../utility/commentData';
import User from '../../../types/user';
import { freshComment } from '../../../utility/commentUtils';
import RegularButton from '../../common/styled/RegularButton';
import { useSaveActionConfirmation } from '../../../hooks/useActionConfirmation';
import RegularTextInput from '../../common/styled/RegularTextInput';
import ButtonLink from '../../common/ButtonLink';
import OutlinedButton from '../../common/styled/OutlinedButton';
import { useMutation } from 'urql';
import { flagCommentMutation } from '../../../api/mutation/comment.mutations';
import Loading from '../../common/Loading';
import CommentModerationModal from './CommentModerationModal';
import {
	permissionAction,
	permissionSubject,
} from '../../../utility/defineAbilityFor';
import {
	Can,
	useCaslAbilityContext,
} from '../../../providers/CaslAbilityProvider';
import AppDialog from '../../common/Dialog';
import _ from 'lodash';

interface CommentProps {
	comment: CommentType; // type Question
	childComments: CommentType[]; // type Question
	filter: Filter;
	user: User | null;
	likeComment: (id: number) => void;
	addComment: (comment: CommentType) => void;
	saveComment: (comment: CommentType, cancel?: boolean) => void;
	editComment: (comment: CommentType, cancel?: boolean) => void;
	moderateComment: (comment: CommentType, cancel?: boolean) => void;
	specificCommentId?: number;
	canEditSpecificComment?: boolean;
	onSpecificCommentEdit?: Dispatch<SetStateAction<CommentType | undefined>>;
	showAllComments?: boolean;
	disabled?: boolean;
	showAllFilteredAsChildren?: boolean;
}

type ItemNames = 'starred' | 'markedForFollowUp' | 'featured' | 'incorporated';

export const Comment: FC<CommentProps> = (props) => {
	const {
		comment,
		childComments,
		filter,
		user,
		addComment,
		saveComment,
		editComment,
		likeComment,
		moderateComment,
		specificCommentId,
		onSpecificCommentEdit,
		showAllComments = false,
		disabled,
	} = props;

	const [commentText, setCommentText] = useState(comment.commentText);
	const [commentApproved, setCommentApproved] = useState(comment.approved);
	const [commentModeratedBy, setCommentModeratedBy] = useState(
		comment.moderatedByUser?.fullName,
	);
	const [commentModerated, setCommentModerated] = useState(
		comment.moderated
			? dayjs
				.unix(Number(comment.moderated) / 1000)
				.format('MMM DD, YYYY hh:mm a')
			: undefined,
	);

	const [showComments, setShowComments] = useState(showAllComments);
	const [editing, setEditing] = useState(comment.id === 0);
	const saveActionConfirmation = useSaveActionConfirmation();
	const [, flagComment] = useMutation(flagCommentMutation);
	const [openModerationModal, setOpenModerationModal] = useState(false);
	const [openIncorporateCommentModal, setopenIncorporateCommentModal] = useState(false);
	const caslAbilityContext = useCaslAbilityContext();

	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const open = Boolean(anchorEl);
	const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(event.currentTarget);
	};
	useEffect(() => {
		const usingFilter = !_.isEqual(filter, defaultFilter) || showAllComments;
		setShowComments(usingFilter);
	}, [showAllComments, filter]);

	const theme = useTheme();

	const navigate = useNavigate();

	const handleCreate = () => {
		setShowComments(true);
		// comment is created and added in state but not saved in DB yet
		addComment({ ...freshComment(comment, user as UserType) });
	};

	const handleEdit = () => {
		setEditing(true);
		onSpecificCommentEdit?.(comment);
		//editComment({ ...comment });
	};

	const handleSave = (published: boolean, cancel?: boolean) => {
		if (published && !cancel) {
			setCommentApproved(true);
			setCommentModeratedBy(user?.fullName);
			setCommentModerated(dayjs().format('MMM DD, YYYY hh:mm a'));
		}

		const publishedDate = published ? dayjs().toISOString() : undefined;

		const cleanseComment = _.omit(comment, 'moderated', 'published');

		const updatedComment =
			published && !cancel
				? {
					...comment,
					commentText,
					published: publishedDate,
					moderatedBy: user?.id,
					moderated: dayjs().toISOString(),
				}
				: {
					...cleanseComment,
					commentText,
				};

		// existing comment is updated in state and DB
		if (comment.id) editComment(updatedComment, cancel);
		else saveComment(updatedComment, cancel); // new comment is updated in state and saved in DB
		setEditing(false);
	};

	const handlePublish = async () => {
		await saveActionConfirmation(
			{
				title: 'Publish Comment',
				message: 'Are you sure you want to publish this comment?',
				confirmLabel: 'Publish',
			},
			() => handleSave(true),
		);
	};

	const handleModerateCancel = () => {
		setOpenModerationModal(false);
	};

	const handleFlag = async () => {
		const newComment = { ...comment };
		const res = await flagComment({
			flagCommentInput: { commentId: comment.id, flagged: !isFlagged },
		});

		if (res.error) return;
		if (isFlagged) {
			newComment.commentFlags = newComment.commentFlags.filter(
				(flag) => flag.createdBy !== user?.id,
			);
		} else {
			newComment.commentFlags = [
				{ createdBy: user?.id!, id: comment.commentFlags.length + 1 },
			];
		}
		await editComment(newComment);
	};

	const handleModify = async (name: ItemNames, value: any) => {
		const newComment = { ...comment };
		newComment[name] = !value;
		editComment(newComment);
	};

	const handleModerateComment = async () => {
		const newComment = { ...comment };
		const approved = !comment.approved;

		await moderateComment({ ...newComment, approved });
		setCommentApproved(approved);
		setCommentModeratedBy(user?.fullName);
		setCommentModerated(dayjs().format('MMM DD, YYYY hh:mm a'));
		setOpenModerationModal(false);
	};

	const canEditSpecificComment = useMemo(
		() =>
			caslAbilityContext.can(permissionAction.MANAGE, permissionSubject.ALL),
		[caslAbilityContext],
	);

	const like =
		comment.commentSupports.findIndex((cs) => cs.createdBy === user?.id) >= 0;

	const hasResponses = childComments.length > 0,
		multipleResponses = childComments.length > 1,
		isFlagged = comment.commentFlags.length > 0;

	const defaultHighlight = {
		mb: 1,
		pl: 1,
		borderLeft: `20px solid ${theme.customPalette.primaryColors.grassGreen[2]}`,
	};

	const areas = [
		'avatar name date status controls',
		'. origComment origComment origComment origComment',
		'. reactions reactions reactions reactions',
		'. comments comments comments comments',
	];
	if (!showComments) areas.pop();

	return (
		<>
			<Grid
				style={{ width: '100%' }}
				areas={areas}
				columnGap={'20px'}
				columns={'auto auto auto auto 1fr'}
				rows={'auto 1fr'}
			>
				<Cell area={'avatar'} middle>
					<Avatar
						variant={'rounded'}
						alt={comment.createdByUser.fullName}
						sx={{
							width: 28,
							height: 28,
							fontSize: 12,
							borderRadius: '50%',
							p: 0.4,
							bgcolor: getAvatarColor(comment.createdByUser.fullName),
							color: 'white',
							'&:hover': { cursor: 'pointer' },
						}}
					>
						{comment.createdByUser.userType === 'machine' &&
							comment.mobileUserId != null
							? 'SMS'
							: comment.createdByUser.firstName?.substring(0, 1) +
							comment.createdByUser.lastName?.substring(0, 1)}
					</Avatar>
				</Cell>
				<Cell area={'name'} middle>
					<Typography color={theme.customPalette.textColors.dark}>
						{comment.createdByUser.userType === 'machine' &&
							comment.mobileUserId != null
							? comment.mobileUser?.mobileNumber ?? '0000000000'
							: comment.createdByUser.fullName}
					</Typography>
				</Cell>
				<Cell area={'date'} middle>
					<Tooltip
						title={`Posted ${dayjs
							.unix(Number(comment.created) / 1000)
							.format('MMM DD, YYYY hh:mm a')}`}
						placement={'right-end'}
					>
						<Typography
							style={{ color: theme.customPalette.systemColors.disabled }}
						>
							{dayjs
								.unix(Number(comment.created) / 1000)
								.format('MMM DD, YYYY')}
						</Typography>
					</Tooltip>
				</Cell>
				<Cell area={'status'} middle>
					{!comment.published && (
						<Tooltip title={'Draft'}>
							<Chip
								icon={<Edit />}
								label="Draft"
								size={'small'}
								variant="outlined"
								color="default"
								sx={{
									px: '2px',
									height: '30px',
									width: '100%',
									display: 'flex',
									alignItems: 'center',
									justifyContent: 'center',
								}}
							/>
						</Tooltip>
					)}
					{comment.published && (
						<Tooltip
							title={`${commentApproved ? 'Approved' : 'Denied'}${canEditSpecificComment && commentModeratedBy
								? ` by ${commentModeratedBy}`
								: ''
								}${canEditSpecificComment && commentModerated
									? ` on ${commentModerated}`
									: ''
								}`}
							placement={'right'}
						>
							{commentApproved ? (
								<Chip
									icon={<Done />}
									label="Approved"
									size={'small'}
									variant="outlined"
									color="success"
									sx={{
										px: '2px',
										width: '100%',
										height: '30px',
										display: 'flex',
										alignItems: 'center',
										justifyContent: 'center',
										pointerEvents: canEditSpecificComment ? 'auto' : 'none',
									}}
									onClick={() => {
										if (canEditSpecificComment) setOpenModerationModal(true);
									}}
								/>
							) : (
								<Chip
									icon={<DoNotDisturb />}
									label="Denied"
									size={'small'}
									variant={'outlined'}
									color="error"
									sx={{
										px: '2px',
										width: '100%',
										height: '30px',
										display: 'flex',
										alignItems: 'center',
										justifyContent: 'center',
										pointerEvents: canEditSpecificComment ? 'auto' : 'none',
									}}
									onClick={() => {
										if (canEditSpecificComment) setOpenModerationModal(true);
									}}
								/>
							)}
						</Tooltip>
					)}
				</Cell>
				<Cell area={'controls'} middle>
					{!editing && comment.published && (
						<div
							style={{ textAlign: 'right', display: 'inline', alignItems: 'center', justifyContent: 'flex-end' }}
						>
							<IconButton
								size="small"
								id="basic-button"
								aria-controls={open ? 'basic-menu' : undefined}
								aria-haspopup="true"
								aria-expanded={open ? 'true' : undefined}
								onClick={handleClick}
							>
								<MoreVert />
							</IconButton>
							<Menu
								id="basic-menu"
								anchorEl={anchorEl}
								open={open}
								onClose={() => setAnchorEl(null)}
								MenuListProps={{
									'aria-labelledby': 'basic-button',
								}}
								PaperProps={{ sx: { borderRadius: 3 } }}
							>
								<div style={{ padding: '0 8px', marginTop: 4 }}>
									<MenuItem
										key={'starred'}
										onClick={() => handleModify('starred', comment.starred)}
										disabled={disabled}
										sx={{
											display: 'flex',
											alignItems: 'center',
											gap: 2,
											borderRadius: 3,
											mb: 0.7,
											py: 1,
										}}
									>
										<Star
											sx={{
												color: theme.customPalette.systemColors.disabled,
												stroke: comment.starred
													? 'transparent'
													: theme.customPalette.systemColors.disabled,
												fill: comment.starred
													? theme.customPalette.primaryColors.gold[1]
													: 'transparent',
											}}
										/>
										<Typography>{comment.starred ? 'Unstar comment' : 'Star comment'}</Typography>
									</MenuItem>
									{comment.parentCommentId == null && (
										<MenuItem
											key={'featured'}
											disabled={disabled}
											onClick={() => handleModify('featured', comment.featured)}
											sx={{
												display: 'flex',
												alignItems: 'center',
												gap: 2,
												borderRadius: 3,
												mb: 0.7,
												py: 1,
											}}
										>
											<PushPinIcon
												sx={{
													color: theme.customPalette.systemColors.disabled,
													stroke: comment.featured
														? 'transparent'
														: theme.customPalette.systemColors.disabled,
													fill: comment.featured
														? theme.customPalette.primaryColors.dusk[3]
														: 'transparent',
												}}
											/>
											<Typography>{comment.featured ? 'Remove from feature' : 'Feature on project'}</Typography>
										</MenuItem>
									)}
									<MenuItem
										key={'markedForFollowUp'}
										onClick={() => handleModify('markedForFollowUp', comment.markedForFollowUp)}
										disabled={disabled}
										sx={{
											display: 'flex',
											alignItems: 'center',
											gap: 2,
											borderRadius: 3,
											mb: 0.7,
											py: 1,
										}}
									>
										<AddCommentOutlined
											sx={{
												color: theme.customPalette.systemColors.disabled,
												stroke: comment.markedForFollowUp
													? 'transparent'
													: theme.customPalette.systemColors.disabled,
												fill: comment.markedForFollowUp
													? theme.customPalette.primaryColors.dusk[3]
													: 'transparent',
											}}
										/>
										<Typography>{comment.markedForFollowUp ? 'Unmark for follow-up' : 'Follow-up needed'}</Typography>
									</MenuItem>
									<MenuItem
										key={'incorporated'}
										onClick={async () => {
											await handleModify('incorporated', comment.incorporated);
											//if user is incorporating a comment, close the drop down and open the modal allowing them to send an update
											//negation is used as the toggle has not happened at this point
											if (!comment.incorporated) {
												setAnchorEl(null);
												setopenIncorporateCommentModal(true);
											}
										}}
										disabled={disabled}
										sx={{
											display: 'flex',
											alignItems: 'center',
											gap: 2,
											borderRadius: 3,
											mb: 0.7,
											py: 1,
										}}
									>
										<Lightbulb
											sx={{
												color: theme.customPalette.systemColors.disabled,
												stroke: comment.incorporated
													? 'transparent'
													: theme.customPalette.systemColors.disabled,
												fill: comment.incorporated
													? theme.customPalette.primaryColors.gold[3]
													: 'transparent',
											}}
										/>
										<Typography>{comment.incorporated ? 'Unmark as added to plan' : 'Mark as added to plan'}</Typography>
									</MenuItem>
									<MenuItem
										key={'flag'}
										onClick={async () => await handleFlag()}
										disabled={disabled}
										sx={{
											display: 'flex',
											alignItems: 'center',
											gap: 2,
											borderRadius: 3,
											mb: 0.7,
											py: 1,
										}}
									>
										<Flag
											sx={{
												color: theme.customPalette.systemColors.disabled,
												stroke: isFlagged
													? 'transparent'
													: theme.customPalette.systemColors.disabled,
												fill: isFlagged
													? theme.customPalette.systemColors.error.main
													: 'transparent',
											}}
										/>
										<Typography>{isFlagged ? 'Remove from moderation' : 'For moderation'}</Typography>
									</MenuItem>
								</div>
							</Menu>
						</div>
					)}
				</Cell>
				<Cell area={'origComment'}>
					<Box>
						{editing ? (
							<RegularTextInput
								id="outlined-multiline-flexible"
								placeholder="Type comment here..."
								size="small"
								multiline
								fullWidth
								autoFocus
								value={commentText}
								onChange={(e) => setCommentText(e.target.value)}
								sx={{ mt: 1, backgroundColor: 'white', wordWrap: 'break-word' }}
							/>
						) : comment.commentText ? (
							<Typography
								mt={1}
								ml={0.4}
								sx={{ wordWrap: 'break-word' }}
								color={theme.customPalette.textColors.dark}
							>
								{commentText}
							</Typography>
						) : (
							<Loading />
						)}

						{editing && (
							<Box display="flex" justifyContent="space-between">
								{!comment.createdByUser ||
									comment.createdByUser.id === user?.id ? (
									<ButtonLink
										link="https://fast.wistia.com/embed/channel/7nj7jkmpbx?wchannelid=7nj7jkmpbx&wmediaid=0n97oc9nm1"
										label="Learn How to Respond to Negative Comments"
										sx={{ my: -2 }}
									/>
								) : (
									<Box width={100}></Box>
								)}

								<div style={{ marginTop: '16px', textAlign: 'right' }}>
									{!comment.published && (
										<TextButton size="small" onClick={() => handleSave(false)}>
											Save as draft
										</TextButton>
									)}
									<OutlinedButton
										variant="outlined"
										size="small"
										sx={{ borderRadius: 6, mx: 2 }}
										onClick={() => handleSave(false, true)}
									>
										Cancel
									</OutlinedButton>
									<RegularButton
										variant="contained"
										size="small"
										sx={{ borderRadius: 6 }}
										onClick={handlePublish}
									>
										Publish
									</RegularButton>
								</div>
							</Box>
						)}
					</Box>
				</Cell>
				<Cell area={'reactions'}>
					<Stack
						direction="row"
						alignItems={'center'}
						justifyContent={'space-between'}
						width={'100%'}
					>
						{/* Like / Support */}
						{!editing && comment.published && (
							<Stack
								direction="row"
								alignItems={'center'}
								justifyContent={'flex-start'}
							>
								<Tooltip title={'Support'}>
									<IconButton
										size="small"
										onClick={async () => likeComment(comment.id)}
										disabled={disabled}
									>
										<ThumbUpIcon
											sx={{
												color: theme.customPalette.primaryColors.sky[1],
												stroke: like
													? 'transparent'
													: theme.customPalette.primaryColors.sky[1],
												fill: like
													? theme.customPalette.primaryColors.dusk[3]
													: 'transparent',
											}}
										/>

										<Typography
											ml={'5px'}
											sx={{
												color: like
													? theme.customPalette.primaryColors.dusk[3]
													: theme.customPalette.primaryColors.sky[1],
											}}
										>
											{comment.commentSupports.length
												? comment.commentSupports.length
												: ''}
										</Typography>
									</IconButton>
								</Tooltip>

								{/* Reply */}
								<Tooltip title={'Reply'}>
									<IconButton
										size="small"
										onClick={handleCreate}
										disabled={disabled}
									>
										<Reply
											sx={{
												color: theme.customPalette.primaryColors.sky[1],
												stroke: theme.customPalette.primaryColors.sky[1],
												fill: 'transparent',
											}}
										/>
										<Typography ml={'5px'}>Reply</Typography>
									</IconButton>
								</Tooltip>
								<Can I={permissionAction.MANAGE} a={permissionSubject.ALL}>
									<Tooltip title={'Edit'}>
										<IconButton
											size="small"
											onClick={handleEdit}
											disabled={disabled}
										>
											<Edit
												sx={{
													color: theme.customPalette.primaryColors.sky[1],
													stroke: theme.customPalette.primaryColors.sky[1],
													fill: 'transparent',
												}}
											/>
										</IconButton>
									</Tooltip>
								</Can>
							</Stack>
						)}

						{!editing && !comment.published && (
							<>
								<Stack />
								<Stack
									direction="row"
									alignItems={'center'}
									justifyContent={'flex-end'}
								>
									<TextButton
										size="small"
										sx={{ mr: 1 }}
										onClick={() => setEditing(true)}
										disabled={disabled}
									>
										Edit reply
									</TextButton>

									<RegularButton
										variant="contained"
										size="small"
										sx={{ borderRadius: 6 }}
										onClick={handlePublish}
										disabled={disabled}
									>
										Publish
									</RegularButton>
								</Stack>
							</>
						)}
						{!editing && comment.published && (
							<Stack direction={'row'} alignItems={'center'} justifyContent={'flex-end'}>
								{comment.incorporated && (
									<Chip
										label='Added to plan'
										size={'small'}
										variant={'outlined'}
										deleteIcon={<Lightbulb />}
										onDelete={() => { }}
										clickable={false}
										sx={{ color: '#00A396' }}
									/>
								)}
								{hasResponses && (
									<TextButton
										size="small"
										onClick={() => setShowComments(!showComments)}
									>
										{!showComments
											? `Show ${childComments.length} Response${multipleResponses ? 's' : ''
											}`
											: `Hide Response${multipleResponses ? 's' : ''}`}
									</TextButton>
								)}
							</Stack>
						)}
					</Stack>
				</Cell>
				{showComments && (
					<Cell area={'comments'}>
						<Stack spacing={2} mb={2}>
							<Box>
								<Stack spacing={2}>
									{childComments?.map((comment, index) => {
										return (
											<Box
												key={index}
												p={2}
												bgcolor={theme.customPalette.surface.light}
												sx={{
													borderRadius: 5,
													...(specificCommentId === comment.id
														? defaultHighlight
														: {}),
												}}
											>
												<Comment
													comment={comment}
													childComments={[]}
													filter={filter}
													user={user}
													likeComment={likeComment}
													addComment={addComment}
													saveComment={saveComment}
													editComment={editComment}
													moderateComment={moderateComment}
													showAllComments={false}
													onSpecificCommentEdit={onSpecificCommentEdit}
													disabled={disabled}
												/>
											</Box>
										);
									})}
								</Stack>
							</Box>
						</Stack>
					</Cell>
				)}
			</Grid>
			<CommentModerationModal
				open={openModerationModal}
				comment={comment}
				onModerate={handleModerateComment}
				onModerateCancel={handleModerateCancel}
			/>
			<AppDialog
				title='Marked as considered for plans'
				open={openIncorporateCommentModal}
				showCloseIcon={true}
				handleClose={() => setopenIncorporateCommentModal(false)}
				handleLeftButton={async () => {
					await handleModify('incorporated', true);
					setopenIncorporateCommentModal(false);
				}}
				leftButtonLabel="Undo"
				RightButton={
					<Button color='primary' variant='contained' onClick={() => {
						navigate(`/projects/${comment.projectId}/updates`, { state: { comment } });
					}}>
						Share Update
					</Button>
				}
			>
				<div style={{ display: 'flex', padding: '10px', paddingTop: '25px' }}>
					<div style={{ marginRight: '15px' }}><Lightbulb htmlColor='#6FCF97' /></div>
					<div>This comment will now show as added to plan on the feedback page</div>
				</div>
			</AppDialog>
		</>
	);
};
