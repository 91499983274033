export const AvatarColours = [
	'#80C271',
	'#EFFC93',
	'#5AACCF',
	'#0F2347',
	'#5c3026',
	'#dc8c24',
	'#c6b3b0',
	'#718699',
	'#117a65',
	'#e96060',
	'#b06dad',
	'#badf55',
];
