import { FC, useState } from 'react';
import { Box, useTheme } from '@mui/material';
import { usePausedContext } from '@coUrbanize/community-modules';
import RegularButton from '../../common/styled/RegularButton';
import useModuleInstance from '../modulePicker/hooks/useModuleInstance';
import { ModulePickerContainer } from '../modulePicker/ModulePickerContainer';
import { ModuleInstanceList } from '../moduleInstanceList/ModuleInstanceList';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import User from 'types/user';
import { Project, TemplateInstance } from 'types/types';

interface TemplateInstanceAreaProps {
	section: {
		gridColumn: number[];
		gridRow: number[];
		sectionId: number;
	};
	user: User | null;
	project: Project;
	templateInstance: TemplateInstance;
	setTemplateInstances: any;
}

const TemplateInstanceArea: FC<TemplateInstanceAreaProps> = (props) => {
	const { section, user, project, templateInstance, setTemplateInstances } =
		props;

	const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
	const theme = useTheme();
	const { isPaused } = usePausedContext();

	const {
		modulesBySection,
		handleCreateModuleInstance,
		handleUpdateModule,
		handleDragEnd,
		handleDeleteModuleInstance,
	} = useModuleInstance(
		templateInstance,
		setTemplateInstances,
		section.sectionId,
		project,
	);

	return (
		<Box
			gridColumn={section.gridColumn.join('/')}
			gridRow={section.gridRow.join('/')}
			position="relative"
			display="flex"
			flexDirection="column"
			justifyContent="space-between"
		>
			<Box
				sx={{ textAlign: 'center' }}
				border={`2px solid ${theme.customPalette.primaryColors.sky[1]}`}
				borderRadius={2}
				p={1}
				height="100%"
			>
				<ModuleInstanceList
					templateSectionId={section.sectionId}
					handleDragEnd={handleDragEnd}
					modulesBySection={modulesBySection}
					handleUpdateModule={handleUpdateModule}
					handleDeleteModuleInstance={handleDeleteModuleInstance}
					user={user}
					project={project}
				/>
			</Box>

			<RegularButton
				disabled={!!isPaused}
				sx={{
					alignSelf: 'center',
					my: 2,
					textTransform: 'none',
					fontSize: 16,
					padding: '3px 18px',
				}}
				onClick={(e) => setAnchorEl(e.currentTarget)}
				variant="contained"
				startIcon={
					<AddCircleOutlineIcon
						fontSize="large"
						sx={{
							'&:hover': {
								color: theme.customPalette.primaryColors.grassGreen[2],
							},
						}}
					/>
				}
			>
				Add widget
			</RegularButton>

			<ModulePickerContainer
				anchorEl={anchorEl}
				setAnchorEl={setAnchorEl}
				onModuleSelected={handleCreateModuleInstance}
				sectionId={section.sectionId}
				instanceId={templateInstance.id}
			/>
		</Box>
	);
};

export default TemplateInstanceArea;
