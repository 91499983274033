import { FC } from 'react';
import { Box, Card, CardContent, Typography } from '@mui/material';
import { usePausedContext } from '@coUrbanize/community-modules';
import InformationPlansForm from '../components/features/informationPlans/InformationPlansForm';
import InfoPlanRow from '../components/features/informationPlans/InfoPlanRow';
import DragDropContainer from '../components/common/DragDropContainer';
import useInfoPlan from '../components/features/informationPlans/useInfoPlan';
import { FilePresent } from '@mui/icons-material';
import OutlinedButton from 'components/common/styled/OutlinedButton';
import PageHeader from 'components/common/PageHeader';

const InformationPlans: FC = () => {
	const {
		informationPlans,
		createInfoPlan,
		handleDragEnd,
		handleTitleUpdate,
		handleDelete,
		open,
		setOpen,
		projectId,
	} = useInfoPlan();

	const { isPaused } = usePausedContext();

	return (
		<Box p={2} px={5} maxWidth={700}>
			<PageHeader>Information and Plans</PageHeader>
			<Typography my={2}>
				Upload all your project agendas, plans, presentations and documents
				here.
			</Typography>
			<DragDropContainer
				droppableId={projectId}
				items={informationPlans}
				keyExtractor={(ip) => ip.id}
				handleDragEnd={handleDragEnd}
				customDataPropName="module"
				containerStyle={{ mb: 5 }}
				noHandle
				isDragDisabled={isPaused}
				DraggableComponent={(ip, handle) => (
					<InfoPlanRow
						informationPlan={ip}
						handle={handle}
						onTitleUpdate={handleTitleUpdate}
						handleDelete={handleDelete}
						disabled={!!isPaused}
					/>
				)}
			/>
			{open ? (
				<Card sx={{ padding: 1 }} variant="outlined">
					<CardContent>
						<InformationPlansForm
							onClose={() => setOpen(false)}
							onSave={(ip: any) => {
								createInfoPlan(ip).then(() => {
									setOpen(false);
								});
							}}
						/>
					</CardContent>
				</Card>
			) : (
				<OutlinedButton
					startIcon={<FilePresent />}
					variant="outlined"
					onClick={() => setOpen(true)}
					disabled={!!isPaused}
				>
					Add Document
				</OutlinedButton>
			)}
		</Box>
	);
};

export default InformationPlans;
