import { Button, ButtonProps, styled } from '@mui/material';

const TextButton = styled(Button)<ButtonProps>(({ theme }) => ({
	color: theme.customPalette.primaryColors.sky[1],
	'&.Mui-disabled': {
		color: theme.customPalette.systemColors.disabled,
	},
}));

export default TextButton;
