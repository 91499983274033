import { cloneElement, FC, ReactElement } from 'react';
import { Box, Typography, useTheme } from '@mui/material';

interface MilestoneIconProps {
	Icon: ReactElement;
	name: string;
	active?: boolean;
	bgColor?: string;
	onSelect?: () => void;
}

const MilestoneIcon: FC<MilestoneIconProps> = (props) => {
	const { Icon, name, active, onSelect } = props;
	const theme = useTheme();

	const StyledIcon = cloneElement(Icon, {
		htmlColor: active
			? theme.customPalette.primaryColors.sky[1]
			: theme.customPalette.systemColors.unchecked,
		sx: { fontSize: 25 },
	});

	return (
		<Box
			border={`${
				active
					? `2px ${theme.customPalette.primaryColors.sky[1]}`
					: '1px #F0F0F0'
			} solid`}
			textAlign="center"
			width={65}
			p="10px 5px"
			onClick={onSelect}
			sx={{
				display: 'flex',
				flexDirection: 'column',
				justifyContent: 'space-evenly',
				alignItems: 'center',
				borderRadius: '8px',
				backgroundColor: theme.customPalette.surface.light,
				width: '90px',
				height: '90px',
				cursor: 'pointer',
				'&:hover': { borderColor: theme.customPalette.primaryColors.sky[1] },
			}}
		>
			{StyledIcon}
			<Typography
				sx={{
					fontWeight: 500,
					fontSize: 15,
					color: active
						? theme.customPalette.primaryColors.sky[1]
						: theme.customPalette.systemColors.unchecked,
				}}
			>
				{name}
			</Typography>
		</Box>
	);
};

export default MilestoneIcon;
