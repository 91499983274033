import { Navigate } from 'react-router-dom';
import { useQuery } from 'urql';
import Loading from '../components/common/Loading';
import useNumberParams from '../hooks/useNumberParams';
import { Page } from '../types/types';

export const PageBuilderRedirect = () => {
	const { projectId } = useNumberParams();

	const pagesQuery = `
        query ($projectId: Int!){
            pagesByProject (projectId: $projectId) {
                id
                name
                ordinal
            }
        }
    `;

	const [{ data }] = useQuery({
		query: pagesQuery,
		variables: { projectId: projectId },
	});

	let firstPageId = 1;
	if (data && data.pagesByProject) {
		const pages: Page[] = data.pagesByProject;
		firstPageId = pages.reduce((prev, curr) =>
			prev.ordinal! < curr.ordinal! ? prev : curr,
		).id;
	}

	return data && data.pagesByProject ? (
		<Navigate
			to={`/projects/${projectId}/pages/${firstPageId}`}
			replace={true}
		/>
	) : (
		<div>
			<Loading />
		</div>
	);
};
