export const enum PROJECT_TYPES {
	CRE = 'cre',
	CITY = 'city',
	OTHER = 'other',
}

export const PROJECT_TYPE_LIST = [
	PROJECT_TYPES.CRE,
	PROJECT_TYPES.CITY,
	PROJECT_TYPES.OTHER,
];
