import { FC } from 'react';
import { RemoveCircle as RemoveCircleIcon } from '@mui/icons-material';
import { Alert } from '@mui/material';

interface DeleteZoneProps {
	isOutOfBound?: boolean;
}

export const DeleteZone: FC<DeleteZoneProps> = (props) => {
	return (
		<>
			<div
				style={{
					width: '100vw',
					height: '100vh',
					zIndex: -1,
					position: 'fixed',
					top: '50%',
					left: '50%',
					transform: 'translate(-50%, -50%)',
					backgroundColor: 'lightgray',
					opacity: 0.1,
				}}
			>
				<div
					style={{
						display: 'flex',
						justifyContent: 'space-between',
						top: '50%',
						transform: 'translateY(-50%)',
						position: 'absolute',
						width: '100%',
					}}
				>
					<RemoveCircleIcon htmlColor='red' sx={{ fontSize: 200, ml: 10 }} />
					<RemoveCircleIcon htmlColor='red' sx={{ fontSize: 200, mr: 10 }} />
				</div>
			</div>

			<Alert
				severity='warning'
				sx={{
					position: 'fixed',
					top: 0,
					left: '50%',
					transform: 'translateX(-50%)',
				}}
			>
				Warning: This image will be deleted!
			</Alert>
		</>
	);
};
