import {
	Box,
	CircularProgressProps,
	Typography,
	SxProps,
	CircularProgress,
} from '@mui/material';
import * as React from 'react';

interface CircularProgressWithLabelProps extends CircularProgressProps {
	value: number;
	sx: SxProps;
}

const CircularProgressWithLabel: React.FC<CircularProgressWithLabelProps> = (
	props,
) => {
	if (props.value === 0 || props.value === 100) return null;

	return (
		<Box sx={{ position: 'relative', display: 'inline-flex', ...props.sx }}>
			<CircularProgress variant="determinate" {...props} sx={{}} />
			<Box
				sx={{
					top: 0,
					left: 0,
					bottom: 0,
					right: 0,
					position: 'absolute',
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
				}}
			>
				<Typography
					variant="caption"
					component="div"
					color="text.secondary"
				>{`${Math.floor(props.value || 0)}%`}</Typography>
			</Box>
		</Box>
	);
};

export default CircularProgressWithLabel;
