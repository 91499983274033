import React from 'react';
import { Typography, Box } from '@mui/material';
import Loading from '../../common/Loading';
import { Item } from './Item';
import { SvgIconComponent } from '@mui/icons-material';

interface KeyStatsCardProps {
    title: string;
    value: number | null;
    icon: SvgIconComponent;
    postfix?: string;
    marginLeft?: number;
}

export const KeyStatsCard: React.FC<KeyStatsCardProps> = ({
    title,
    value,
    icon: Icon,
    postfix,
    marginLeft
}) => {
    return (
        <Item sx={{ flexDirection: 'row', borderRadius: '25px', justifyContent: 'flex-start', alignItems: 'center', padding: '10px 20px' }}>
            <Icon color='primary' sx={{fontSize: '45px'}} />
            <Box sx={{ 
                marginLeft: `${marginLeft??25}px`,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-start',
                height:'100%'
            }}>
                <Typography variant='h5' fontWeight='bold'>
                    {value || value === 0 ? `${value}${postfix??''}` : <Loading />}
                </Typography>
                <Typography>
                    {title}
                </Typography>
            </Box>
        </Item>
    );
};
