import { EventType } from '../hooks/useProjectSettings';

const validateForm = (type: EventType, value: string | boolean | null) => {
	if (
		type === 'customUrl' && // eslint-disable-next-line
		!/^(((?!-))(xn--)?[a-z0-9\-_]{0,61}[a-z0-9]{1,1}\.)*(xn--)?([a-z0-9\-]{1,61}|[a-z0-9\-]{1,30})\.[a-z]{2,}$/.test(
			value as string,
		)
	)
		return 'Invalid Url';
	if (
		// validate a string of email addresses separated by commas
		type === 'emailSandbox' &&
		!/^([a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+,)*([a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+)$/.test(
			value as string,
		)
	)
		return 'Some email addresses are not valid';
	if (
		type === 'smsSubscribeMobileNumber' &&
		!/^\+[0-9]{9,12}$/.test(
			value as string,
		)
	)
		return 'Phone number must be entered in the following format +###########';
	return false;
};

export default validateForm;
