// ==============================|| OVERRIDES - BUTTON ||============================== //

import { Theme } from '@mui/material';

export default function InputLabel(theme: Theme) {
	return {
		MuiInputLabel: {
			styleOverrides: {
				root: {
					color: theme.customPalette.textColors.dark,
					fontSize: '16px',
					lineHeight: '20px',
				},
			},
		},
	};
}
