import React, {
	useEffect,
	useRef,
	useState,
	useMemo,
	useCallback,
	FC,
} from 'react';
import { GoogleMap, InfoWindow, MarkerF } from '@react-google-maps/api';
import Loading from './Loading';
import { MapMarker } from './MarkerList';
import { Typography } from '@mui/material';
import { useMapScriptLoader } from '../modules/mapModule/MapScriptLoaderProvider';

interface MapContainerProps {
	center?: {
		lat: number;
		lng: number;
	};
	width: number;
	height: number;
	mapZoomLevel: number;
	locations?: MapMarker[];

	options?: {
		zoomControl: boolean;
		mapTypeControl: boolean;
		scaleControl: boolean;
		streetViewControl: boolean;
		rotateControl: boolean;
		fullscreenControl: boolean;
	};
	selectedMarker?: MapMarker;
	onZoomLevelChange: (zoom: number) => void;
	onCenterLevelChange: (center: google.maps.LatLngLiteral) => void;
}

type MapProps = {
	onLoad: (map: google.maps.Map) => void;
	onZoomChanged: () => void;
	onCenterChanged: () => void;
	onClick: () => void;
	options: {
		zoomControl: boolean;
		mapTypeControl: boolean;
		scaleControl: boolean;
		streetViewControl: boolean;
		rotateControl: boolean;
		fullscreenControl: boolean;
	};
	currentPosition: google.maps.LatLngLiteral;
	zoomLevel: number;
	children?: React.ReactNode;
};

const MAP_HEIGHT = 330;
const MAP_CONTAINER_STYLE = {
	width: 'auto',
	aspectRatio: '16 / 9',
	height: `${MAP_HEIGHT}px`,
};

const Map: FC<MapProps> = ({
	options,
	currentPosition,
	zoomLevel,
	onLoad,
	onZoomChanged,
	onClick,
	children,
	onCenterChanged,
}) => {
	return (
		<GoogleMap
			options={options}
			center={currentPosition}
			onLoad={onLoad}
			onZoomChanged={onZoomChanged}
			zoom={zoomLevel}
			onClick={onClick}
			mapContainerStyle={MAP_CONTAINER_STYLE}
			onCenterChanged={onCenterChanged}
		>
			{children}
		</GoogleMap>
	);
};

const MapContainerComponent: FC<MapContainerProps> = ({
	options,
	locations,
	center,
	selectedMarker,
	onZoomLevelChange,
	onCenterLevelChange,
	mapZoomLevel,
}) => {
	const { isLoaded, loadError } = useMapScriptLoader(),
		[zoomLevel, setZoomLevel] = useState<number>(mapZoomLevel),
		[currentPosition, setCurrentPosition] = useState(
			center
				? center
				: {
						lat: 42.361145,
						lng: -71.057083,
				  },
		),
		[selectedElement, setSelectedElement] = useState<MapMarker | null>(
			selectedMarker || null,
		),
		mapRef = useRef<google.maps.Map>();

	const success = useCallback(
		(position: { coords: { latitude: number; longitude: number } }) => {
			const centerPosition = {
				lat: center?.lat ?? position.coords.latitude,
				lng: center?.lng ?? position.coords.longitude,
			};
			setCurrentPosition(centerPosition);
		},
		[center?.lat, center?.lng],
	);

	const fail = useCallback(
		(error: any) => {
			const position = {
				lat: center?.lat ?? currentPosition.lat,
				lng: center?.lng ?? currentPosition.lng,
			};
			console.log(error);
			setCurrentPosition(position);
		},
		[center?.lat, center?.lng, currentPosition],
	);

	const onMapClick = useCallback(() => {
		setSelectedElement(null);
	}, []);

	const onLoad = useCallback((map: google.maps.Map) => {
		mapRef.current = map;
	}, []);

	const onZoomChanged = useCallback(() => {
		if (mapRef && mapRef.current) {
			const currentZoomLevel: number = mapRef.current?.getZoom()!;
			setZoomLevel(currentZoomLevel);
			onZoomLevelChange(currentZoomLevel);
		}
	}, [onZoomLevelChange]);

	const onCenterChanged = useCallback(() => {
		if (mapRef && mapRef.current) {
			const currentCenter: any = mapRef.current?.getCenter()!.toJSON()!;
			onCenterLevelChange(currentCenter);
		}
	}, [onCenterLevelChange]);

	const markers = useMemo(
		() =>
			locations?.map((item) => (
				<>
					<MarkerF
						key={item.location.lat + item.location.lng}
						position={item.location}
						onClick={() => {
							setSelectedElement(item);
						}}
					/>

					{selectedElement === item && (
						<InfoWindow
							position={{ ...item.location, lat: item.location.lat + 0.002 }}
							onCloseClick={() => {
								setSelectedElement(null);
							}}
						>
							<div>
								<Typography variant="h6">{item.name}</Typography>
								<Typography>{item.location.address}</Typography>
							</div>
						</InfoWindow>
					)}
				</>
			)),
		[locations, selectedElement],
	);

	useEffect(() => {
		if (selectedMarker) {
			setSelectedElement(selectedMarker);
		}
		if (!center) {
			navigator.geolocation.getCurrentPosition(success, fail);
		} else {
			setCurrentPosition(center);
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [center, selectedMarker]);

	if (loadError) {
		return <span>Map cannot be loaded right now, Sorry.</span>;
	}

	return isLoaded ? (
		<Map
			options={options!}
			currentPosition={currentPosition}
			zoomLevel={zoomLevel}
			onClick={onMapClick}
			onLoad={onLoad}
			onZoomChanged={onZoomChanged}
			onCenterChanged={onCenterChanged}
		>
			{markers}
		</Map>
	) : (
		<Loading />
	);
};

export const MapContainer = React.memo(MapContainerComponent);
