import { styled, ToggleButtonGroup } from '@mui/material';
import { ToggleButtonGroupProps } from '@mui/material/ToggleButtonGroup/ToggleButtonGroup';

const StyledToggleButtonGroup = styled(
	ToggleButtonGroup,
)<ToggleButtonGroupProps>(({ theme }) => ({
	'& .MuiToggleButtonGroup-grouped': {
		border: 'none',
		'&.MuiToggleButton-root': {
			borderRadius: 100,
			padding: '4px 16px',
			fontWeight: 500,
			fontSize: 16,
			textTransform: 'none',
			height: 32,
			color: '#fff',
			'&:hover': {
				backgroundColor: 'rgb(0 0 0 / 11%)',
			},
		},
		'&.Mui-selected': {
			backgroundColor: theme.customPalette.primaryColors.dusk[5],
			'&:hover': {
				backgroundColor: 'rgb(0 0 0 / 50%)',
			},
		},
	},
}));

export default StyledToggleButtonGroup;
