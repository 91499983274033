import { FC, useEffect } from 'react';
import ModuleInstanceData from '../../types/moduleInstanceData';
import {
	Box,
	Typography,
} from '@mui/material';
import { useModuleSaveData } from 'hooks/useModuleSaveData';

const UpdatesModule: FC<ModuleInstanceData> = (props) => {
	const { saveDataRef, config, content } = props;
	const { updateModuleSaveData } = useModuleSaveData(saveDataRef);

	useEffect(() => {
		updateModuleSaveData({ config, content });
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return <Box p={1} pt={0} minWidth="500px">
		<Typography mb={2}>No configuration options are required here. Manage community updates on the Updates page.</Typography>
	</Box>;
};

export default UpdatesModule;
