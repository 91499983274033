import React, { FC, useState } from 'react';
import {
	DataGrid,
	GridColDef,
	GridRowsProp,
	GridValueGetterParams,
} from '@mui/x-data-grid';
import { useMutation } from 'urql';
import User from '../../../types/user';
import { IconButton, Switch, Tooltip } from '@mui/material';
import { DeleteOutline as DeleteOutlineIcon } from '@mui/icons-material';
import dayjs from 'dayjs';
import { updateUserMutation } from 'api/mutation/users.mutation';
import {
	removeUserFromProjectMutation,
	toggleDailyDigestToProjectMutation,
} from 'api/mutation/project.mutation';
import { useSnackBar } from 'providers/SnackBarProvider';
import useNumberParams from 'hooks/useNumberParams';
import EditProjectUserFormDialog from './EditProjectUserFormDialog';
import { useDeleteActionConfirmation } from '../../../hooks/useActionConfirmation';
import EditIcon from '../../icons/EditIcon';
import './ProjectUserTable.css';
import { ProjectUser } from 'types/types';
import { TitleCaseHyphenatedWord } from '../../../utility/titleCaseHyphenatedWord';
import {
	permissionAction,
	permissionSubject,
} from '../../../utility/defineAbilityFor';
import { useCaslAbilityContext } from '../../../providers/CaslAbilityProvider';

interface ProjectUserTableProps {
	projectUsers: ProjectUser[];
	refreshUsers: () => void;
}

export const ProjectUserTable: FC<ProjectUserTableProps> = (
	props: ProjectUserTableProps,
) => {
	const { projectUsers, refreshUsers } = props;
	const { projectId } = useNumberParams();

	const [userToEdit, setUserToEdit] = useState<User>({} as User);
	const [editDialogOpen, setEditDialogOpen] = useState(false);

	const [, updateUserData] = useMutation(updateUserMutation);
	const [, removeUserFromProject] = useMutation(removeUserFromProjectMutation);
	const [, toggleDailyDigest] = useMutation(toggleDailyDigestToProjectMutation);

	const showDeleteConfirmation = useDeleteActionConfirmation();

	const { notify } = useSnackBar();
	const caslAbilityContext = useCaslAbilityContext();
	const canManageUsers = caslAbilityContext.can(
		permissionAction.MANAGE,
		permissionSubject.ALL,
	);

	const handleDelete = async (user: User) => {
		try {
			await removeUserFromProject({
				projectId,
				userId: user.id,
			});
			notify('User removed successfully');
			refreshUsers();
		} catch (error) {
			notify('Something went wrong! Could not remove user');
		}
	};
	const handleToggleDigest = async (params: any) => {
		try {
			if (params.value === !!params.row.receiveDigestEnabledDate) {
				return;
			}
			await toggleDailyDigest({
				projectId,
				userId: params.row.user.id,
				enable: params.value,
			});

			if (params.value) {
				params.row.receiveDigestEnabledDate = new Date().toISOString();
				notify(`${params.row.user.fullName} subscribed to daily digest`);
			} else {
				params.row.receiveDigestEnabledDate = undefined;
				notify(`${params.row.user.fullName} unsubscribed from daily digest`);
			}
		} catch (error) {
			notify('Something went wrong! Could not update user');
		}
	};

	const handleEditCancel = () => {
		setEditDialogOpen(false);
		setUserToEdit({} as User);
	};

	const updateUser = async (user: User) => {
		try {
			if (user) {
				await updateUserData({
					updateUserData: {
						id: user.id,
						firstName: user.firstName,
						lastName: user.lastName,
						email: user.email,
					},
				});
				notify('User Updated successfully');
			}
		} catch (e) {
			notify('Something went wrong! Could not update user');
		}
	};

	const handleConfirm = async (values: User) => {
		setEditDialogOpen(false);
		setUserToEdit({} as User);
		await updateUser(values);
		refreshUsers();
	};

	const columns: GridColDef[] = [
		{
			field: 'fullName',
			headerName: 'Name',
			width: 175,
			sortable: false,
			headerClassName: 'userHideRightSeparator',
			valueGetter: (params: GridValueGetterParams) => {
				const { row: projectUser } = params;
				return projectUser.user.fullName;
			},
		},
		{
			field: 'email',
			headerName: 'Email',
			width: 250,
			sortable: false,
			headerClassName: 'userHideRightSeparator',
			valueGetter: (params: GridValueGetterParams) => {
				const { row: projectUser } = params;
				return projectUser.user.email;
			},
		},
		{
			field: 'status',
			headerName: 'Status',
			width: 150,
			sortable: false,
			headerClassName: 'userHideRightSeparator',
			valueGetter: (params: GridValueGetterParams) => {
				const { row: projectUser } = params;
				const invited = dayjs(parseInt(projectUser.user.created)).format(
					'MMM DD YYYY',
				);
				return projectUser.user.registered
					? 'Registered'
					: `Invited ${invited}`;
			},
		},
		{
			field: 'role',
			headerName: 'Role',
			width: 100,
			sortable: false,
			headerClassName: 'userHideRightSeparator',
			valueGetter: (params: GridValueGetterParams) => {
				const { row: projectUser } = params;
				return TitleCaseHyphenatedWord.transform(projectUser.user.userType);
			},
		},
		{
			field: 'lastLogin',
			headerName: 'Last Login',
			width: 150,
			sortable: false,
			headerClassName: 'userHideRightSeparator',
			valueGetter: (params: GridValueGetterParams) => {
				const { row: projectUser } = params;
				return projectUser.user.lastLogin
					? dayjs(parseInt(projectUser.user.lastLogin)).format('MMM DD YYYY')
					: 'Never';
			},
		},
		{
			field: 'receiveDigestEnabledDate',
			headerName: 'Daily Digest',
			sortable: false,
			align: 'right',
			flex: 1,
			headerClassName: 'userHideRightSeparator pr-0',
			headerAlign: 'right',
			renderCell: ({ row }) => {
				return (
					<Tooltip
						title={
							row.receiveDigestEnabledDate
								? `Subscribed since ${dayjs(row.receiveDigestEnabledDate)
										.add(1, 'd')
										.format('MMM DD YYYY')}`
								: 'Unsubscribed'
						}
						placement="bottom"
						arrow={true}
					>
						<Switch
							checked={!!row.receiveDigestEnabledDate}
							onClick={() => {
								handleToggleDigest({
									value: !row.receiveDigestEnabledDate,
									row,
								});
							}}
						/>
					</Tooltip>
				);
			},
		},
		{
			field: 'edit',
			headerName: '',
			align: 'right',
			sortable: false,
			headerClassName: 'userHideRightSeparator',
			width: 20,
			renderCell: ({ row }) => {
				return (
					<Tooltip
						title={`Edit ${
							row.user.fullName.endsWith('s')
								? `${row.user.fullName}'`
								: `${row.user.fullName}'s`
						} details`}
						placement="bottom"
						arrow={true}
					>
						<IconButton
							onClick={() => {
								setEditDialogOpen(true);
								setUserToEdit(row.user);
							}}
						>
							<EditIcon />
						</IconButton>
					</Tooltip>
				);
			},
		},
		{
			field: 'delete',
			headerName: '',
			align: 'right',
			sortable: false,
			headerClassName: 'userHideRightSeparator',
			width: 20,
			renderCell: ({ row }) => {
				return (
					<Tooltip
						title={`Remove ${row.user.fullName} from this project`}
						placement="bottom"
						arrow={true}
					>
						<IconButton
							onClick={async () => {
								await showDeleteConfirmation(
									{
										title: 'Remove User',
										message: `Are you sure you want to remove ${row.user.fullName} from this project?`,
									},
									() => handleDelete(row.user),
								);
							}}
						>
							<DeleteOutlineIcon style={{ marginRight: '0px' }} />
						</IconButton>
					</Tooltip>
				);
			},
		},
	];

	const rows: GridRowsProp = projectUsers;

	return (
		<>
			<div
				style={{
					display: 'flex',
					height: '100%',
					width: '100%',
					paddingTop: '20px',
				}}
			>
				<div style={{ flexGrow: 1 }}>
					<DataGrid
						onCellEditCommit={handleToggleDigest}
						getRowId={(row) => row.user.id}
						getRowClassName={() => 'userCellFormat'}
						sx={{
							'& .MuiDataGrid-columnHeaders': {
								backgroundColor: 'secondary.main',
								color: 'white',
							},
						}}
						columnVisibilityModel={{
							edit: canManageUsers,
						}}
						disableColumnMenu
						disableSelectionOnClick
						hideFooter
						autoHeight
						headerHeight={30}
						columns={columns}
						rows={rows}
					/>
				</div>
			</div>
			<EditProjectUserFormDialog
				onConfirm={handleConfirm}
				onCancel={handleEditCancel}
				isOpen={editDialogOpen}
				values={userToEdit}
			/>
		</>
	);
};
