import queryString from 'query-string';
import { ROUTES } from '../../constants/routeEnums';
import { KeyedObject, OneOrArrayOf } from '../../types/aliases';
import { flatten, first, compact, isObject, drop, includes } from 'lodash';

export class RouterUtility {
	static REDIRECT_PARAM = 'redirect';

	static getLoginURL(
		redirect: string | null | undefined = null,
		query: {} = {
			[RouterUtility.REDIRECT_PARAM]: redirect,
		},
	) {
		let loginRoute = ROUTES.LOGIN;

		if (redirect) {
			loginRoute = queryString.stringifyUrl({
				url: loginRoute,
				query: query,
			});
		}

		return loginRoute;
	}

	static getRoute = (
		pathInput: OneOrArrayOf<string>,
		params: KeyedObject = {},
	) => {
		return RouterUtility.placeParamsInPath(
			RouterUtility.getRoutePath(pathInput) as string,
			params,
		);
	};

	static getRoutePath = (
		pathInput: OneOrArrayOf<string>,
		asParts: boolean = false,
		routeObject: KeyedObject = ROUTES,
	): OneOrArrayOf<string> => {
		let path: string[] =
			typeof pathInput === 'string' ? pathInput.split('.') : flatten(pathInput);
		let result = routeObject[first(path) as string],
			hasMorePath = path.length > 1,
			route: OneOrArrayOf<string>;

		if (result && !isObject(result)) {
			route = result;
		} else if (result.ROOT) {
			if (hasMorePath && result.CHILDREN) {
				route = `${result.ROOT}${RouterUtility.getRoutePath(
					drop(path),
					false,
					result.CHILDREN,
				)}`;
			} else if (!hasMorePath) {
				route = `${result.ROOT}`;
			} else {
				throw new Error(`Route has no CHILDREN defined as ${path}`);
			}
		} else {
			throw new Error(`Route has no ROOT defined at ${path}`);
		}

		if (asParts && typeof route === 'string') {
			return route.split('/') as string[];
		}

		return route;
	};

	static placeParamsInPath(pathRegex: string, params: KeyedObject) {
		let segments = pathRegex.split('/');

		let newPathSegments = segments.map((segment) => {
			let offset = segment.indexOf(':') + 1;

			if (!offset) return segment;

			let key = segment.slice(offset),
				indexOfOptionalFlag = key.indexOf('?'),
				keyIsOptional = indexOfOptionalFlag > -1,
				paramsContainKey = !includes(
					[null, undefined, ''],
					params[key.substring(0, indexOfOptionalFlag)],
				);

			if (keyIsOptional) {
				return paramsContainKey
					? params[key.substring(0, indexOfOptionalFlag)]
					: '';
			} else {
				return params[key];
			}
		});

		return `/${compact(newPathSegments).join('/')}`;
	}
}
