import { useSnackBar } from '../../../providers/SnackBarProvider';
import { useEffect, useState } from 'react';
import { DropResult } from 'react-beautiful-dnd';
import { useMutation, useQuery } from 'urql';
import { getInfoAndPlansQuery } from '../../../api/query/infoPlan.queries';
import useNumberParams from '../../../hooks/useNumberParams';
import { InformationPlan } from './InformationPlansForm';
import {
	createInfoAndPlanMutation,
	removeInfoAndPlanMutation,
	reorderInfoAndPlanMutation,
	updateInfoAndPlanMutation,
} from '../../../api/mutation/infoPlan.mutations';
import { createFileMutation } from '../../../api/mutation/file.mutations';

const useInfoPlan = () => {
	const [informationPlans, setInformationPlans] = useState<InformationPlan[]>(
		[],
	);
	const [open, setOpen] = useState(false);

	const { projectId } = useNumberParams();
	const { notify } = useSnackBar();

	const [{ data }] = useQuery({
		query: getInfoAndPlansQuery,
		variables: { projectId },
		pause: informationPlans.length > 0,
	});

	const [, createInformationPlan] = useMutation(createInfoAndPlanMutation);
	const [, updateInformationPlan] = useMutation(updateInfoAndPlanMutation);
	const [, removeInformationPlan] = useMutation(removeInfoAndPlanMutation);
	const [, reorderInformationPlan] = useMutation(reorderInfoAndPlanMutation);
	const [, createFile] = useMutation(createFileMutation);

	useEffect(() => {
		if (data?.informationPlansByProject)
			setInformationPlans(data.informationPlansByProject);
	}, [data?.informationPlansByProject]);

	const handleDragEnd = async (result: DropResult) => {
		const { source, destination } = result;

		if (!destination) return;

		//reorder in array
		const sortedIPs = informationPlans;
		const moved = sortedIPs.splice(source.index, 1)[0];
		sortedIPs.splice(destination.index, 0, moved);
		setInformationPlans(sortedIPs);

		//reorder on backend
		const res = await reorderInformationPlan({
			projectId,
			destinationIndex: destination.index,
			sourceIndex: source.index,
		});

		if (!res.error) return notify('Documents reordered');
		notify('Documents failed to reorder!', 'error');
	};

	const createInfoPlan = async (infoPlan: InformationPlan) => {
		let newInfoPlan = {
			...infoPlan,
			ordinal: informationPlans.length + 1,
			projectId,
		};

		if (infoPlan.file) {
			const res = await createFile({
				newFileData: {
					fileName: infoPlan.file.fileName,
					url: infoPlan.file.url,
					projectId,
				},
			});

			const fileId = res.data.createFile.id;
			newInfoPlan = { ...newInfoPlan, fileId } as any;

			if (res.error) return notify('File could not be created!', 'error');
		}

		const { file, ...createdInfoPlan } = newInfoPlan;

		const res = await createInformationPlan({
			newInformationPlanData: createdInfoPlan,
		});
		if (res.error) return notify('Document failed to create!', 'error');

		const newId = res?.data.createInformationPlan.id;

		setInformationPlans([...informationPlans, { ...newInfoPlan, id: newId }]);

		notify('Document created');
	};

	const handleTitleUpdate = async (id: number, title: string) => {
		if (!id) return;

		const res = await updateInformationPlan({
			existingInformationPlanData: { id, title },
		});
		if (res.error) return notify('Document failed to update!', 'error');

		const updatedInfoPlans = informationPlans.map((ip) => {
			if (ip.id === id) return { ...ip, title };
			else return ip;
		});

		setInformationPlans(updatedInfoPlans);

		notify('Document updated');
	};

	const handleDelete = async (id: number) => {
		if (!id) return;

		const res = await removeInformationPlan({ id });
		if (res.error) return notify('Document failed to delete!', 'error');

		const newInfoPlans = informationPlans.filter((ip) => ip.id !== id);
		setInformationPlans(newInfoPlans);

		notify('Document deleted');
	};

	return {
		informationPlans,
		createInfoPlan,
		setInformationPlans,
		handleDragEnd,
		handleTitleUpdate,
		handleDelete,
		open,
		setOpen,
		projectId,
	};
};

export default useInfoPlan;
