import { gql } from 'urql';

export const createTimelineMutation = gql`
  mutation createTimeline($newTimeline: NewTimelineInput!) {
    createTimeline(newTimeline: $newTimeline) {
      id
    }
  }
`;

export const updateTimelineMutation = gql`
  mutation updateTimeline($existingTimeline: ExistingTimelineInput!) {
    updateTimeline(existingTimeline: $existingTimeline) {
      id
    }
  }
`;

export const deleteTimelineMutation = gql`
  mutation deleteTimeline($id: Int!) {
    deleteTimeline(id: $id)
  }
`;
