import { Box } from '@mui/material';
import { Template } from 'types/types';

interface TemplatePickerOptionProps {
	template: Template;
	handleClick: (template: Template) => void;
}

export const TemplatePickerOption = ({
	template,
	handleClick,
}: TemplatePickerOptionProps) => {
	const rowHeightPixels = 40;
	const templateHeightPixels =
		template.layout.gridTemplateRows.length * rowHeightPixels;

	// noinspection SpellCheckingInspection
	return (
		<Box
			sx={{
				display: 'grid',
				gap: 1,
				gridTemplateColumns: template.layout.gridTemplateColumns
					.map((c) => c + 'fr')
					.join(' '),
				gridTemplateRows: template.layout.gridTemplateRows
					.map((c) => c + 'fr')
					.join(' '),
				height: `${templateHeightPixels}px`,
				borderRadius: '10px',
				backgroundColor: '#fafafa',
				border: '2px solid #e7e7e7',
				padding: '8px',
				'&:hover': {
					cursor: 'pointer',
					opacity: 0.7,
				},
				'&:active': {
					opacity: 1,
				},
			}}
			onClick={(e) => handleClick(template)}
		>
			{template &&
				template.layout.gridTemplateSections &&
				template.layout.gridTemplateSections.map((section, index: number) => (
					<Box
						key={`section_${index}`}
						sx={{
							border: '3px solid #55ACFF',
							backgroundColor: 'white',
							borderRadius: '10px',
							gridColumn: `${section.gridColumn[0]} / ${section.gridColumn[1]}`,
							gridRow: `${section.gridRow[0]} / ${section.gridRow[1]}`,
						}}
					></Box>
				))}
		</Box>
	);
};
