import React from 'react';
import { FC, useEffect, useState } from 'react';
import { produce } from 'immer';
import {
	KeyboardArrowDown as KeyboardArrowDownIcon,
	MoreVert as MoreVertIcon,
	AddCircleOutline as AddCircleOutlineIcon,
} from '@mui/icons-material';
import _ from 'lodash';
import {
	Button,
	Chip,
	Divider,
	Grid,
	IconButton,
	Menu,
	MenuItem,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Typography,
} from '@mui/material';
import {
	Question,
	QuestionGroup as QuestionGroupType,
} from '../../../types/types';
import { getTextWidth } from '../../../utility/getTextWidth';
import { DragDropContext, Draggable, DropResult } from 'react-beautiful-dnd';
import { QuestionItem } from './QuestionItem';
import { QuestionMenu } from './QuestionMenu';

import { GroupStatus } from './QuestionGroupsContainer';
import TextFieldNoOutline from '../../common/TextFieldNoOutline';
import { TitleCaseHyphenatedWord } from '../../../utility/titleCaseHyphenatedWord';
import StrictModeDroppable from 'components/common/StrictModeDroppable';

interface QuestionGroupProps {
	questionGroup: QuestionGroupType;
	availableQuestions: Question[];
	onUpdateQuestion: (question: Question) => void;
	onUpdateThemeName: (themeName: string) => void;
	onDragEnd: (
		questionGroupId: number,
		sourceIndex: number,
		destinationIndex: number,
	) => void;
	onRemoveQuestion: (questionGroupId: number, questionId: number) => void;
	onToggleHideQuestion: (questionGroupId: number, questionId: number) => void;
	onAddQuestion: (
		questionGroupId: number,
		type: string | null,
		questionId: number | null,
	) => Promise<Question>;
	onQuestionFormClose: () => void;
	onUpdateGroupStatus: (status: string) => void;
	onQuestionItemCountClick: (groupId: number, questionId: number) => void;
	disabled?: boolean;
	commentCountPerQuestionId: any;
	setSelectedQuestion: (question: Question) => void;
	onEditClick: () => void;
}

export const enum QuestionType {
	// eslint-disable-next-line no-unused-vars
	SHORT_ANSWER = 'short-response',
	// eslint-disable-next-line no-unused-vars
	LONG_ANSWER = 'long-response',
	// eslint-disable-next-line no-unused-vars
	MULTIPLE_CHOICE = 'multiple-select',
	// eslint-disable-next-line no-unused-vars
	SINGLE_SELECT = 'single-select',
	// eslint-disable-next-line no-unused-vars
	RANKED_CHOICE = 'ranked-choice',
}

export const QuestionGroup: FC<QuestionGroupProps> = (props) => {
	const {
		questionGroup,
		availableQuestions,
		onUpdateThemeName,
		onUpdateGroupStatus,
		onDragEnd,
		onRemoveQuestion,
		onToggleHideQuestion,
		onAddQuestion,
		disabled,
		onQuestionItemCountClick,
		commentCountPerQuestionId,
		setSelectedQuestion,
		onEditClick,
	} = props;
	const isDraftOrArchived =
		questionGroup.themeName.toLowerCase().includes('draft') ||
		questionGroup.themeName.toLowerCase().includes('archived');
	const [questions, setQuestions] = useState(questionGroup.questions || []);
	const [dragDisabled, setDragDisabled] = useState(true);
	const [themeName, setThemeName] = useState(questionGroup.themeName);
	const [themeNamePx, setThemeNamePx] = useState(
		getTextWidth(questionGroup.themeName, '18px Nunito'),
	);
	const [optionsMenuAnchorEl, setOptionsMenuAnchorEl] =
		useState<null | HTMLElement>(null);
	useEffect(() => {
		setThemeName(questionGroup.themeName);
	}, [questionGroup.themeName]);
	const optionsMenuOpen = Boolean(optionsMenuAnchorEl);
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

	const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};
	const handleClickOptionsMenu = (
		event: React.MouseEvent<HTMLButtonElement>,
	) => {
		setOptionsMenuAnchorEl(event.currentTarget);
	};
	const handleCloseOptionsMenu = () => {
		setOptionsMenuAnchorEl(null);
	};
	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setThemeName(event.target.value);
	};

	const toggleHideQuestion = (questionGroupId: number, questionId: number) => {
		onToggleHideQuestion(questionGroupId, questionId);
		const newState = produce(
			questions,
			(draftState) => {
				const index = draftState.findIndex(
					(q) => q.id === questionId,
				);
				if (index !== -1) {
					draftState[index].hidden = !draftState[index].hidden;
				}
			},
		);
		setQuestions(newState);
	};
	const handleAddQuestion = async (type: string) => {
		const question = await onAddQuestion(questionGroup.id, type, null);
		handleClose();
		setSelectedQuestion({ ...question, type });
	};

	useEffect(() => {
		let questions: Question[] = [];
		if (questionGroup.questions) {
			questions = _.sortBy(questionGroup.questions, 'ordinal');
		}
		setQuestions(questions);
	}, [questionGroup.questions]);

	const handleSetGroupStatus = (status: string) => {
		onUpdateGroupStatus(status);
		handleCloseOptionsMenu();
	};

	const handleAddExistingQuestion = async (
		questionId: number,
		type: string,
		questionText: string,
	) => {
		const question = await onAddQuestion(
			questionGroup.id,
			QuestionType.SHORT_ANSWER,
			questionId,
		);
		handleClose();
		setQuestions(
			produce(questions, (draft) => {
				draft.push({ ...question, type, questionText });
			}),
		);
	};

	const statusToChange =
		questionGroup.status === GroupStatus.INACTIVE
			? GroupStatus.ACTIVE.toString()
			: GroupStatus.INACTIVE.toString();

	useEffect(() => {
		setThemeNamePx(getTextWidth(themeName, '18px Nunito'));
	}, [themeName]);

	return (
		<>
			<Grid container pt={4}>
				<Grid display="flex" alignItems="center" item xs={9} gap={2}>
					<Typography
						display="inline"
						sx={{ fontWeight: 400, fontSize: '18px', lineHeight: '26px' }}
					>
						{questionGroup.ordinal}.{' '}
					</Typography>
					{isDraftOrArchived ? (
						<Typography
							display="inline"
							sx={{ fontWeight: 400, fontSize: '18px', lineHeight: '26px' }}
						>
							{questionGroup.themeName}
						</Typography>
					) : (
						<TextFieldNoOutline
							size="small"
							sx={{
								outline: 'none',
								width: `${themeNamePx + 28}px`,
								minWidth: 100,
							}}
							onBlur={() => {
								if (questionGroup.themeName !== themeName) {
									onUpdateThemeName(themeName);
								}
							}}
							onChange={handleChange}
							value={themeName}
							disabled={!!disabled}
						/>
					)}
					<Chip
						sx={{ fontSize: '16px' }}
						label={TitleCaseHyphenatedWord.transform(questionGroup.status)}
						color="secondary"
						variant={
							questionGroup.status === GroupStatus.INACTIVE
								? 'outlined'
								: 'filled'
						}
					/>
				</Grid>
				<Grid item textAlign="right" xs={3} sx={{ pr: 2 }}>
					<Button
						size="large"
						color="primary"
						onClick={handleClick}
						disabled={disabled}
						startIcon={<AddCircleOutlineIcon />}
						endIcon={<KeyboardArrowDownIcon />}
					>
						New Question
					</Button>
					<IconButton disabled={!!disabled} onClick={handleClickOptionsMenu}>
						<MoreVertIcon />
					</IconButton>
				</Grid>
			</Grid>
			<TableContainer>
				<Table>
					<TableHead>
						<TableRow>
							{/* Drag icon */}
							<TableCell sx={{ border: 'unset' }} width="5%" />
							<TableCell
								sx={{
									fontWeight: '500',
									fontSize: '16px',
									lineHeight: '24px',
									border: 'unset',
								}}
								width="12%"
							>
								Type
							</TableCell>
							<TableCell
								sx={{
									fontWeight: '500',
									fontSize: '16px',
									lineHeight: '24px',
									border: 'unset',
								}}
								width="63%"
							>
								Question
							</TableCell>
							<TableCell
								sx={{
									fontWeight: '500',
									fontSize: '16px',
									lineHeight: '24px',
									border: 'unset',
								}}
								width="7%"
							>
								Responses
							</TableCell>
							{/* Trash Icon */}
							<TableCell sx={{ border: 'unset' }} width="13%" />
						</TableRow>
					</TableHead>
					<DragDropContext
						onDragEnd={(result: DropResult) => {
							if (!result.destination) return;
							const [sourceIndex, destinationIndex] = [
								result.source.index,
								result.destination!.index,
							];
							const newState = produce(questions, (draftState) => {
								const removed = draftState.splice(sourceIndex, 1)[0];

								draftState.splice(destinationIndex, 0, removed);
							});
							setQuestions(newState);

							onDragEnd(questionGroup.id, sourceIndex, destinationIndex);
						}}
					>
						<StrictModeDroppable droppableId="question-items">
							{(provided) => (
								<TableBody ref={provided.innerRef} {...provided.droppableProps}>
									{questions
										.filter((question) => !question.deleted)
										.map((q, index) => {
											return (
												<Draggable
													key={index}
													draggableId={`${index}`}
													index={index}
													isDragDisabled={dragDisabled || disabled}
												>
													{(draggableProvided) => (
														<TableRow
															ref={draggableProvided.innerRef}
															{...draggableProvided.draggableProps}
															{...draggableProvided.dragHandleProps}
														>
															<QuestionItem
																onDelete={(
																	questionGroupId: number,
																	questionId: number,
																) => {
																	onRemoveQuestion(questionGroupId, questionId);
																	const newState = produce(
																		questions,
																		(draftState) => {
																			const index = draftState.findIndex(
																				(q) => q.id === questionId,
																			);
																			if (index !== -1)
																				draftState.splice(index, 1);
																		},
																	);
																	setQuestions(newState);
																}}
																onHideToggle={toggleHideQuestion}
																onEdit={(question: Question) =>
																	setSelectedQuestion(question)
																}
																groupId={questionGroup.id}
																key={q.ordinal}
																question={q}
																status={questionGroup.status}
																onToggleDrag={(enabled: boolean) =>
																	setDragDisabled(!enabled)
																}
																onQuestionItemCountClick={
																	onQuestionItemCountClick
																}
																disabled={disabled}
																commentCountPerQuestionId={
																	commentCountPerQuestionId
																}
															/>
														</TableRow>
													)}
												</Draggable>
											);
										})}
									{provided.placeholder}
								</TableBody>
							)}
						</StrictModeDroppable>
					</DragDropContext>
				</Table>
				<Divider />
			</TableContainer>
			<QuestionMenu
				availableQuestions={availableQuestions}
				onAddQuestion={handleAddQuestion}
				anchorEl={anchorEl}
				setAnchorEl={setAnchorEl}
				handleAddExistingQuestion={handleAddExistingQuestion}
			/>
			<Menu
				anchorEl={optionsMenuAnchorEl}
				open={optionsMenuOpen}
				onClose={handleCloseOptionsMenu}
			>
				{!isDraftOrArchived && (
					<MenuItem onClick={() => handleSetGroupStatus(statusToChange)}>
						Set {statusToChange}
					</MenuItem>
				)}
				<MenuItem onClick={onEditClick}>Edit</MenuItem>
			</Menu>
		</>
	);
};
