import { FC } from 'react';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Box, Tooltip, Typography, useTheme } from '@mui/material';

const infoCases = {
	imageDescription:
		'Please try to convey the same or equivalent information that a sighted reader would get when they look this image.',
};

type infoCase = keyof typeof infoCases;

type InfoOnHoverProps = {
	infoCase: infoCase;
};

const popperProps = {
	sx: {
		'& .MuiTooltip-tooltipPlacementTop': {
			marginBottom: '0 !important',
		},
	},
};

const InfoOnHover: FC<InfoOnHoverProps> = ({ infoCase }) => {
	const theme = useTheme();

	let text;

	switch (infoCase) {
	case 'imageDescription':
		text = infoCases.imageDescription;
		break;

	default:
		text = '';
		break;
	}

	return (
		<Tooltip
			title={<Typography variant="subtitle2">{text}</Typography>}
			placement="top"
			PopperProps={popperProps}
		>
			<Box display="flex">
				<InfoOutlinedIcon
					sx={{
						color: theme.customPalette.primaryColors.sky[1],
						fontSize: '16px',
					}}
				/>
			</Box>
		</Tooltip>
	);
};

export default InfoOnHover;
