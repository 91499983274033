import { FC } from 'react';
import { Box, Skeleton, useTheme } from '@mui/material';

const DesignSkeleton: FC = () => {
	const theme = useTheme();

	return (
		<Box height="100%" width="100%">
			{[...Array(5)].map((_, i) => (
				<Box key={i} mb={2} bgcolor={theme.customPalette.basicColors.light}>
					<Box
						p={2}
						borderRadius={2}
						border={`2px solid ${theme.customPalette.borderColors.light}`}
					>
						<Box
							p={2}
							borderRadius={2}
							border={`2px solid ${theme.customPalette.primaryColors.sky[1]}`}
						>
							<Skeleton width="100%" height={150} variant="rectangular" />
						</Box>
					</Box>
				</Box>
			))}
		</Box>
	);
};

export default DesignSkeleton;
