import { FC } from 'react';
import { Typography, useTheme } from '@mui/material';
import TextButton from 'components/common/styled/TextButton';

interface NavItemProps {
	active?: boolean;
	title: string;
	count: number;
	onClick: () => void;
}

const NavItem: FC<NavItemProps> = (props) => {
	const { active, title, count, onClick } = props;
	const theme = useTheme();

	return (
		<TextButton
			disabled={count === 0}
			onClick={onClick}
			sx={{
				padding: '7px 7px 7px 12px',
				display: 'flex',
				alignItems: 'center',
				whiteSpace: 'nowrap',
				height: '38px',
				gap: '6px',
				backgroundColor: active
					? theme.customPalette.primaryColors.dusk[5]
					: 'transparent',
				textTransform: 'none',
				borderRadius: '12px',
				'&:hover': {
					backgroundColor: active
						? theme.customPalette.primaryColors.dusk[5]
						: theme.customPalette.primaryColors.grassGreen[5],
				},
			}}
			size="small"
		>
			<Typography
				fontWeight="bold"
				color={
					active
						? theme.customPalette.basicColors.light
						: theme.customPalette.primaryColors.dusk[3]
				}
			>
				{title}
			</Typography>
			<Typography
				color={
					active
						? theme.customPalette.primaryColors.sky[1]
						: theme.customPalette.primaryColors.dusk[3]
				}
				sx={{
					px: 0.4,
					backgroundColor: active
						? theme.customPalette.basicColors.light
						: theme.customPalette.surface.dark,
					borderRadius: 2,
					fontWeight: 800,
				}}
			>
				{count}
			</Typography>
		</TextButton>
	);
};

export default NavItem;
