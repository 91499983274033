import React, { useState } from 'react';
import { NoteAdd } from '@mui/icons-material';
import TextButton from 'components/common/styled/TextButton';
import { NewPageInput } from './AddPageContainer';
import AddPageModal from './AddPageModal';

interface AddPageProps {
	handleAddClick: (newPageInput: NewPageInput) => void;
}

export const AddPage = ({ handleAddClick }: AddPageProps) => {
	const [showAddPageModal, setShowAddPageModal] = useState(false);
	return (
		<>
			<TextButton
				onClick={() => setShowAddPageModal(true)}
				startIcon={<NoteAdd />}
			>
				Add New Page
			</TextButton>
			<AddPageModal
				onClose={() => setShowAddPageModal(false)}
				open={showAddPageModal}
				handleAddClick={(newPageInput: NewPageInput) => {
					if (showAddPageModal) setShowAddPageModal(false);
					handleAddClick(newPageInput);
				}}
			/>
		</>
	);
};
