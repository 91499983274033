import { Select, SelectProps, styled } from '@mui/material';

const SelectInput = styled(Select)<SelectProps>(({ theme }) => ({
	borderRadius: '8px',
	color: theme.customPalette.systemColors.disabled,
	fontSize: '16px',
	borderColor: `1px solid ${theme.customPalette.borderColors.dark}`,
	'&.Mui-disabled': {
		color: () => theme.customPalette.systemColors.unchecked,
		backgroundColor: '#F0F0F0',
		border: `1px solid ${theme.customPalette.systemColors.disabled}`,
	},
}));

export default SelectInput;
