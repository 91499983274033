import { FC } from 'react';
import ModuleInstanceData from 'types/moduleInstanceData';
import { MODULE_TYPE } from 'types/moduleTypes';
import { FreeContentModule } from '../FreeContentModule';
import { ImageModule } from '../ImageModule';
import FormModule from '../formModule';
import CarouselModule from '../CarouselModule';
import { MapModule } from '../mapModule/MapModule';
import VideoModule from '../VideoModule';
import TimelineModule from '../TimelineModule';
import PublicBenefitModule from '../PublicBenefitModule';
import InformationPlanModule from '../InformationPlanModule';
import CallToActionModule from '../CallToActionModule';
import HeroImageModule from '../HeroImageModule';
import FeedbackModule from '../FeedbackModule';
import UpdateModule from '../UpdatesModule';
import LocationPhaseModule from '../LocationPhaseModule';
import EmbedModule from '../embedModule/EmbedModule';
import SubscribeBoxModule from '../SubscribeBoxModule';

const moduleComponents: Map<string, FC<ModuleInstanceData>> = new Map([
	[MODULE_TYPE.FREE_TEXT, FreeContentModule],
	[MODULE_TYPE.IMAGE, ImageModule],
	[MODULE_TYPE.FORM, FormModule],
	[MODULE_TYPE.CAROUSEL, CarouselModule],
	[MODULE_TYPE.MAP, MapModule],
	[MODULE_TYPE.VIDEO, VideoModule],
	[MODULE_TYPE.TIMELINE, TimelineModule],
	[MODULE_TYPE.PUBLIC_BENEFIT, PublicBenefitModule],
	[MODULE_TYPE.INFORMATION_PLANS, InformationPlanModule],
	[MODULE_TYPE.UPDATES, UpdateModule],
	[MODULE_TYPE.CALL_TO_ACTION, CallToActionModule],
	[MODULE_TYPE.HERO_IMAGE, HeroImageModule],
	[MODULE_TYPE.FEEDBACK, FeedbackModule],
	[MODULE_TYPE.LOCATION_PHASE, LocationPhaseModule],
	[MODULE_TYPE.EMBED, EmbedModule],
	[MODULE_TYPE.SUBSCRIBE_BOX, SubscribeBoxModule],
]);

export default moduleComponents;
