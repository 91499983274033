// third-party
import { Theme } from '@mui/material';
import { merge } from 'lodash';
import Badge from './Badge';
import Button from './Button';
import Checkbox from './Checkbox';
import IconButton from './IconButton';
import InputLabel from './InputLabel';
import Menu from './Menu';
import Radio from './Radio';
import Switch from './Switch';
import Toolbar from './Toolbar';
import ListItemIcon from './ListItemIcon';

// project import

// ==============================|| OVERRIDES - MAIN ||============================== //

export default function ComponentsOverrides(theme: Theme) {
	return merge(
		Button(theme),
		Toolbar(theme),
		Menu(theme),
		InputLabel(theme),
		IconButton(theme),
		Badge(theme),
		Radio(theme),
		Checkbox(theme),
		Switch(theme),
		ListItemIcon(theme),
	);
}
