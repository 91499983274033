import React from 'react';
import FileDragDrop from './FileDragDrop';
import { Box, Button, Typography, useTheme } from '@mui/material';
import CircularProgressWithLabel from './CircularProgessWithLabel';
import { useNotifyActionConfirmation } from 'hooks/useActionConfirmation';

interface ImageContent {
	imageUrl?: string;
	fileName: string;
	altTag: string;
}

interface ImageDropProps {
	multiple?: boolean;
	progress: number;
	handleUpload: (files: FileList | null) => void;
	imageContent?: ImageContent;
	height?: number | string;
	maxPreviewHeight?: number | string;
}

const acceptTypes = [
	'image/jpeg',
	'image/jpg',
	'image/png',
	'image/gif',
	'image/bmp',
	'image/webp',
];

const ImageDrop: React.FC<ImageDropProps> = (props) => {
	const {
		progress,
		multiple,
		handleUpload,
		imageContent,
		height = 200,
		maxPreviewHeight = 250,
	} = props;
	const theme = useTheme();
	const imageNotAllowedPopup = useNotifyActionConfirmation();

	const checkImageDimensions = (file: File) => {
		return new Promise((resolve, reject) => {
			let img: HTMLImageElement;
			img = document.createElement('img');

			img.onload = function () {
				if (
					img.width &&
					img.height &&
					img.width <= 2000 &&
					img.height <= 2000
				) {
					resolve(true);
				} else {
					resolve(false);
				}
			};
			img.src = URL.createObjectURL(file);
		});
	};

	if (imageContent?.imageUrl)
		return (
			<Box justifyContent="center" textAlign="center" width="100%" mt={2}>
				<img
					src={imageContent.imageUrl}
					alt={imageContent?.altTag}
					style={{ maxWidth: '100%', maxHeight: maxPreviewHeight }}
				/>
				<Typography mt={2}>{imageContent?.fileName}</Typography>
			</Box>
		);

	const imageChange = async (files: FileList | null) => {
		if (files) {
			Array.from(files).forEach(async (file) => {
				const checkDimensions = await checkImageDimensions(file);

				if (!acceptTypes.includes(file.type)) {
					await imageNotAllowedPopup({
						title: 'File format is not allowed',
						message: `Image type needs to be one of the following : ${acceptTypes
							.map((type) => type.slice(6))
							.join(', ')}`,
					});
				} else if (!checkDimensions) {
					await imageNotAllowedPopup({
						title: 'Image dimensions are too large',
						message: 'Image width and height should each be less than 2000px',
					});
				} else {
					handleUpload(files);
				}
			});
		}
	};

	return (
		<FileDragDrop handleDrop={imageChange}>
			<Box
				display="flex"
				alignItems="center"
				justifyContent="center"
				flexDirection="column"
				p={1}
				border={`2px dashed ${theme.customPalette.primaryColors.sky[4]}`}
				borderRadius="10px"
				width="100%"
				minHeight={height}
				mt={2}
			>
				<Typography
					fontStyle={{ color: theme.customPalette.textColors.dark }}
					fontWeight={400}
					fontSize={15}
				>
					Drag and Drop
				</Typography>
				<Typography
					fontStyle={{ color: theme.customPalette.textColors.dark }}
					fontSize={15}
				>
					or
				</Typography>
				<label htmlFor="contained-button-file">
					<input
						accept="image/*"
						id="contained-button-file"
						multiple={multiple}
						type="file"
						onChange={(e) => imageChange(e.target.files)}
						style={{ display: 'none' }}
					/>
					<Button
						component="a"
						sx={{
							fontWeight: 700,
							fontSize: 15,
							letterSpacing: 0.1,
							textDecoration: 'underline',
							textTransform: 'none',
							color: theme.customPalette.primaryColors.sky[1],
						}}
						disabled={progress > 0 && progress < 100}
					>
						Upload from computer
					</Button>
				</label>
				<CircularProgressWithLabel value={progress} sx={{ mt: 1 }} />
			</Box>
		</FileDragDrop>
	);
};

export default ImageDrop;
