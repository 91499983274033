import { useTheme } from '@mui/material';
import { FC, CSSProperties } from 'react';

interface RankedChoiceIconProps {
	style?: CSSProperties;
	size?: number;
	color?: string;
}

const RankedChoiceIcon: FC<RankedChoiceIconProps> = (props) => {
	const theme = useTheme();
	const {
		style,
		size = 24,
		color = theme.customPalette.basicColors.dark,
	} = props;

	return (
		<svg
			style={style}
			width={size}
			height={size}
			viewBox="0 0 20 19"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M20 7.24L12.81 6.62L10 0L7.19 6.63L0 7.24L5.46 11.97L3.82 19L10 15.27L16.18 19L14.55 11.97L20 7.24ZM10 13.4L6.24 15.67L7.24 11.39L3.92 8.51L8.3 8.13L10 4.1L11.71 8.14L16.09 8.52L12.77 11.4L13.77 15.68L10 13.4Z"
				fill={color}
				fillOpacity="0.87"
			/>
		</svg>
	);
};

export default RankedChoiceIcon;
