import { ContentLoader } from './contentLoaderType';
import { Timeline } from '../../../../types/types';
import graphqlConn from '../utils/graphqlConn';
import getInfo from '../utils/getInfo';

export const timelineLoader: ContentLoader = async (content, _, getToken) => {
	const query = `
		query ($timelineId: Int!) {
			eventsByTimeline(timelineId: $timelineId) {
				id
				name
				description
				startDate
				startTime
				endDate
				endTime
				displayDate
				milestoneIcon
				file
			}
		}
	`;

	const res = await graphqlConn(getToken, query, {
		timelineId: content?.timelineId,
	});

	return {
		data: (res?.data?.eventsByTimeline || []) as Timeline,
		info: getInfo(content),
	};
};
