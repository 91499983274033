import React, { FC, useEffect } from 'react';
import * as yup from 'yup';
import { Formik } from 'formik';
import { Cell, Grid } from 'styled-css-grid';
import {
	Card,
	FormControl,
	InputLabel,
	MenuItem,
	FormHelperText,
	Typography,
	useTheme,
} from '@mui/material';
import { CreateOutlined } from '@mui/icons-material';
import { usePausedContext } from '@coUrbanize/community-modules';
import RegularButton from 'components/common/styled/RegularButton';
import { Project } from '../../../../types/types';
import SelectInput from 'components/common/styled/SelectInput';
import { useSaveActionConfirmation } from '../../../../hooks/useActionConfirmation';

const validationSchema = yup.object({
	siteFont: yup.string().required('Site Font is required'),
});

interface StyleSettingsFormProps {
	initialValues: Project;
	onSubmitClick: any;
}

const StyleSettingsForm: FC<StyleSettingsFormProps> = (props) => {
	const { initialValues, onSubmitClick } = props;
	const { isPaused } = usePausedContext();
	const saveActionConfirmation = useSaveActionConfirmation();

	const theme = useTheme();

	useEffect(() => {
		import('webfontloader').then((WebFont) => {
			WebFont.load({
				google: {
					families: ['Open Sans', 'Merriweather'],
				},
			});
		});
	}, []);

	return (
		<Card
			sx={(theme) => ({
				paddingLeft: theme.spacing(3),
				paddingRight: theme.spacing(3),
				paddingBottom: theme.spacing(2),
				display: 'block',
			})}
		>
			<h4>Style Settings</h4>
			<Typography variant="body2" pb={4} color="secondary">
				Changing this value effects all pages on the public site! Unless the
				client request otherwise, stick with the default font, Nunito.
			</Typography>
			<Formik
				enableReinitialize
				validateOnChange={false}
				initialValues={{
					siteFont: initialValues.siteFont ?? 'Nunito',
				}}
				validationSchema={validationSchema}
				onSubmit={async (values, { setSubmitting }) => {
					const project: any = {
						id: initialValues.id!,
						siteFont: values.siteFont,
					};
					await saveActionConfirmation(
						{
							title: 'Site Font Change Confirmation',
							message: `Are you sure you want to change the public site font from ${initialValues.siteFont} to ${values.siteFont}?`,
							confirmLabel: 'Proceed anyway',
						},
						() => onSubmitClick(project),
					);
					setSubmitting(false);
				}}
			>
				{({
					dirty,
					isValid,
					isSubmitting,
					errors,
					touched,
					values,
					handleSubmit,
					handleChange,
					handleBlur,
				}) => {
					return (
						<form onSubmit={handleSubmit} noValidate autoComplete="off">
							<InputLabel htmlFor="state">Site Font</InputLabel>
							<FormControl
								fullWidth
								error={Boolean(touched.siteFont && errors.siteFont)}
							>
								<SelectInput
									id="siteFont"
									name="siteFont"
									value={values.siteFont}
									onChange={handleChange}
									onBlur={handleBlur}
									size="small"
								>
									<MenuItem value="Nunito">Nunito</MenuItem>
									<MenuItem value="Open Sans">Open Sans</MenuItem>
									<MenuItem value="Merriweather">Merriweather</MenuItem>
								</SelectInput>
								{touched.siteFont && errors.siteFont && (
									<FormHelperText>{errors.siteFont}</FormHelperText>
								)}
							</FormControl>
							<h4
								style={{
									color: theme.customPalette.primaryColors.sky[1],
									fontFamily: values.siteFont,
								}}
							>
								{values.siteFont} Sample Paragraph
							</h4>
							<Typography
								variant="body1"
								style={{ fontFamily: values.siteFont, minHeight: '220px' }}
							>
								Lorem <b>ipsum dolor</b> sit amet, consectetur{' '}
								<u>adipiscing elit</u>, sed do eiusmod tempor incididunt ut
								labore et dolore magna aliqua. Ut enim ad minim veniam, quis.
								<ul>
									<li>Nostrud</li>
									<li>Eercitation</li>
									<li>Llamco laboris</li>
								</ul>
								NISI UT ALIQUIP EX EA
								<br />
								<em>
									commodo consequat. Duis aute irure dolor in reprehenderit in
									voluptate velit esse cillum dolore eu fugiat nulla pariatur.
								</em>
							</Typography>

							<Grid columns={2}>
								<Cell left={2} style={{ justifySelf: 'end', marginTop: 10 }}>
									<RegularButton
										color="secondary"
										variant="contained"
										type="submit"
										sx={{ justifySelf: 'flex-end' }}
										disabled={isSubmitting || !(isValid && dirty) || !!isPaused}
										startIcon={<CreateOutlined />}
									>
										Save
									</RegularButton>
								</Cell>
							</Grid>
						</form>
					);
				}}
			</Formik>
		</Card>
	);
};

export default StyleSettingsForm;
