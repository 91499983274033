import { useState } from 'react';
import { Box, Tab, Typography } from '@mui/material';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { ProjectUserManagerContainer } from '../components/features/projectUserManager/ProjectUserManagerContainer';
import { Cell, Grid } from 'styled-css-grid';
import useNumberParams from 'hooks/useNumberParams';
import { ExistingProjectInput } from '../types/types';
import useProject from 'components/features/editProject/useProject';
import ProjectDetailsForm from 'components/features/project/ProjectDetailsForm';
import ProjectDetailsSettings from 'components/features/project/ProjectSettings/ProjectDetailsSettings';
import ProjectProfileImage from 'components/features/project/ProjectProfileImage';
import { useSnackBar } from 'providers/SnackBarProvider';
import Loading from 'components/common/Loading';
import DeveloperDetailsForm from 'components/features/project/DeveloperDetailsForm';
import PageHeader from '../components/common/PageHeader';
import useProjectDev from 'components/features/editProject/useProjectDev';
import Templates from './Templates';

export const ProjectDetails = () => {
	const [tabValue, setTabValue] = useState('details');
	const { projectId } = useNumberParams();

	const { project, handleProjectUpdate } = useProject(projectId);
	const {
		open,
		setOpen,
		developer,
		progress,
		handleSave,
		uploadFilesToAws,
		setBtnName,
		setDeveloper,
	} = useProjectDev(projectId, project);
	const { notify } = useSnackBar();

	const handleTabChange = (_: any, newValue: string) => {
		setTabValue(newValue);
	};

	const handleFormSubmit = async (values: ExistingProjectInput) => {
		const response = await handleProjectUpdate(values);
		if (response.error) notify('Project details failed to save!', 'error');

		notify('Project details saved');
	};

	if (!project) return <Loading />;

	return (
		<>
			<Box px={5} pt={2}>
				<PageHeader>Project Details</PageHeader>
			</Box>
			<Box px={5}>
				<TabContext value={tabValue}>
					<Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
						<TabList
							onChange={handleTabChange}
							aria-label="project tabs"
							textColor="secondary"
							indicatorColor="secondary"
						>
							<Tab label="Details" value="details" />
							<Tab label="Users" value="users" />
							<Tab label="Developer" value="developer" />
							<Tab label="Settings" value="settings" />
							<Tab label="Templates" value="templates" />
						</TabList>
					</Box>
					<TabPanel value="details">
						<Typography variant="body2" pb={4} color="secondary">
							Project administrators can edit address, project phase and profile
							image to be used in the coUrbanize directory
						</Typography>
						<Grid columns={2}>
							<ProjectDetailsForm
								initialValues={project}
								onSubmitClick={handleFormSubmit}
							/>

							<Cell>
								<ProjectProfileImage initialValues={project} />
							</Cell>
						</Grid>
					</TabPanel>

					<TabPanel value="users">
						<ProjectUserManagerContainer />
					</TabPanel>

					<TabPanel value="developer">
						<DeveloperDetailsForm
							open={open}
							setOpen={setOpen}
							developer={developer}
							progress={progress}
							handleSave={handleSave}
							uploadFilesToAws={uploadFilesToAws}
							setBtnName={setBtnName}
							setDeveloper={setDeveloper}
						/>
					</TabPanel>
					<TabPanel value="settings">
						<ProjectDetailsSettings
							key={project.id}
							initialValues={project}
							onSubmitClick={handleFormSubmit}
						/>
					</TabPanel>
					<TabPanel value="templates">
						<Templates />
					</TabPanel>
				</TabContext>
			</Box>
		</>
	);
};
