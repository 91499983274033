import { Ability, AbilityBuilder, AbilityClass } from '@casl/ability';
import { USER_TYPES } from 'constants/userTypes';
import { Page, Project } from '../types/types';
import { MODULE_TYPE } from '../types/moduleTypes';
import User from '../types/user';

export enum permissionAction {
	PAUSE = 'pause',
	DISABLE = 'disable',
	UPDATE = 'update',
	CREATE = 'create',
	MANAGE = 'manage',
	VIEW = 'view',
}

export enum permissionSubject {
	PAGE = 'Page',
	ALL = 'all',
	PROJECT = 'Project',
	MODULE = 'Module',
	COMMENTS = 'Comments',
}

export type PermissionAction =
	| permissionAction.CREATE
	| permissionAction.DISABLE
	| permissionAction.UPDATE
	| permissionAction.MANAGE
	| permissionAction.PAUSE
	| permissionAction.VIEW;

export type PermissionSubject =
	| permissionSubject.ALL
	| permissionSubject.PROJECT
	| permissionSubject.MODULE
	| permissionSubject.PAGE
	| permissionSubject.COMMENTS
	| Page
	| Project;

export type AppAbility = Ability<[PermissionAction, PermissionSubject]>;

const defineAbilityFor = (user: User | null) => {
	const { can, build } = new AbilityBuilder<
		Ability<[PermissionAction, PermissionSubject]>
	>(Ability as AbilityClass<AppAbility>);
	if (!user) return build();

	// regular users use home and about in pagebuilder
	can(permissionAction.UPDATE, permissionSubject.PAGE, {
		name: { $nin: ['Updates', 'Feedback'] },
	});
	// regular users can't manage Updates and Feedback modules
	can(permissionAction.MANAGE, permissionSubject.MODULE, {
		name: { $nin: [MODULE_TYPE.UPDATES, MODULE_TYPE.FEEDBACK] },
	});

	// superusers can do anything
	if (
		user.userType === USER_TYPES.DEVELOPER ||
		user.userType === USER_TYPES.INTERNAL
	) {
		can(permissionAction.MANAGE, permissionSubject.ALL);
		// superusers can manage Updates and Feedback modules
		can(permissionAction.MANAGE, permissionSubject.MODULE, {
			name: { $in: [MODULE_TYPE.UPDATES, MODULE_TYPE.FEEDBACK] },
		});
		// superusers can view specific comments
		can(permissionAction.VIEW, permissionSubject.COMMENTS);
	}

	if (user.userType === USER_TYPES.CLIENT) {
		// clients can view specific comments
		can(permissionAction.VIEW, permissionSubject.COMMENTS);
	}

	return build();
};

export default defineAbilityFor;
