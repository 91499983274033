import { Project } from '../../../types/types';
import { FC, useEffect, useState } from 'react';
import {
	Card,
	CardContent,
	CardMedia,
	Grid as MuiGrid,
	IconButton,
	Typography,
} from '@mui/material';
import { ImagePlaceHolder } from '../../common/ImagePlaceholder';
import { Cell, Grid } from 'styled-css-grid';
import { Web } from '@mui/icons-material';
import { useInView } from 'react-intersection-observer';
interface ProjectsProps {
	project: Project;
	onClick: (projId: number) => void;
}

export const ProjectCard: FC<ProjectsProps> = ({ project, onClick }) => {
	const p = project;
	const { ref: inViewRef, inView } = useInView({
		triggerOnce: true,
		root: null,
	});
	const [showCard, setShowCard] = useState(false);

	useEffect(() => {
		if (inView) {
			setShowCard(true);
		}
	}, [inView, project]);

	return (
		<MuiGrid
			ref={inViewRef}
			item
			xs={4}
			key={`project_${p.id}`}
			sx={{ minHeight: '300px' }}
		>
			{showCard && (
				<Card
					sx={{
						height: '100%',
					}}
				>
					{p.primaryImageUrl ? (
						<div
							style={{
								position: 'relative',
								paddingBottom: '56.2%',
							}}
						>
							<CardMedia
								component="img"
								style={{
									position: 'absolute',
									height: '100%',
								}}
								image={p.primaryImageUrl}
								alt={`${p.name}`}
							/>
						</div>
					) : (
						<ImagePlaceHolder />
					)}
					<CardContent>
						<Grid columns="1fr">
							<Cell center middle>
								<IconButton
									onClick={() => onClick(p.id)}
									style={{ borderRadius: 0 }}
								>
									<Web />
									&nbsp;<Typography variant="body1">{p.name}</Typography>
								</IconButton>
							</Cell>
						</Grid>
					</CardContent>
				</Card>
			)}
		</MuiGrid>
	);
};
