import React, { memo } from 'react';
import { Box, Button, Typography } from '@mui/material';
import { RemoveRedEye as RemoveRedEyeIcon } from '@mui/icons-material';
import ModuleInstanceData from '../../types/moduleInstanceData';
import UploadImageModal from './components/UploadImageModal';
import CarouselModal from './components/CarouselModal';
import useCarousel from '../../hooks/useCarousel';
import useDragDrop from '../../hooks/useDragDrop';
import ImageCard from '../common/ImageCard';
import { DeleteZone } from '../common/DragDrop/DeleteZone';
import GalleryImageIcon from 'components/icons/GalleryImageIcon';
import { useRouteContext } from '../../routes/Layout';

const CarouselModule: React.FC<ModuleInstanceData> = memo((props) => {
	const {
		images,
		setImages,
		height,
		handleDoubleClick,
		setOpenPreviewModal,
		setOpenUploadModal,
		openPreviewModal,
		openUploadModal,
		index,
		setIndex,
		setToDeleteImages,
	} = useCarousel(props);
	const {
		drop,
		hasDropped,
		style,
		moveCard,
		findCard,
		deleteCard,
		isOutOfBound,
		setIsDragging,
	} = useDragDrop({
		items: images,
		setItems: setImages,
		direction: 'horizontal',
	});
	const deleteImage = (id: string) => {
		const img = [...images];
		const idx = img.findIndex((item) => item.imageUrl === id);

		setToDeleteImages((prevState: any) => [...prevState, img[idx].imageUrl]);

		img.splice(idx, 1);
		setImages(img);
	};
	const { activeProjectDetails } = useRouteContext();
	return (
		<Box ref={drop} m="0 -24px">
			{isOutOfBound && <DeleteZone />}

			<Box m="0 24px">
				<Typography fontSize={16} mb={1} fontWeight={400}>
					Add images to showcase in a carousel
				</Typography>
				<Box minWidth={500}>
					<Box
						sx={{
							...style,
							width: '100%',
							minWidth: 500,
							height: '100%',
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
						}}
					>
						{images?.map((card, index) => (
							<ImageCard
								key={card.imageUrl}
								imageInfo={card}
								onDoubleClick={() => handleDoubleClick(index)}
								moveCard={moveCard}
								findCard={findCard}
								deleteCard={deleteCard}
								setIsDragging={setIsDragging}
								hasDropped={hasDropped}
								deleteImage={deleteImage}
							/>
						))}
					</Box>
				</Box>
				<Box textAlign="left" mt={5} display="flex" justifyContent="flex-start">
					<Button
						variant="text"
						sx={(theme) => ({
							textTransform: 'capitalize',
							padding: '8px 16px',
							fontSize: '18px',
							minWidth: '162px',
							borderWidth: '2px',
							color: theme.customPalette.primaryColors.sky[1],
							borderColor: theme.customPalette.primaryColors.sky[1],
							'&:hover': {
								borderWidth: '2px',
								borderColor: theme.customPalette.primaryColors.sky[2],
							},
						})}
						style={{ textAlign: 'center' }}
						startIcon={<GalleryImageIcon />}
						onClick={() => {
							setIndex(null);
							setOpenUploadModal(true);
						}}
					>
						Add Image
					</Button>
					{Boolean(images?.length) && (
						<Button
							variant="text"
							sx={(theme) => ({
								textTransform: 'capitalize',
								padding: '8px 16px',
								fontSize: '18px',
								minWidth: '162px',
								borderWidth: '2px',
								color: theme.customPalette.primaryColors.sky[1],
								borderColor: theme.customPalette.primaryColors.sky[1],
								'&:hover': {
									borderWidth: '2px',
									borderColor: theme.customPalette.primaryColors.sky[2],
								},
							})}
							style={{ textAlign: 'center' }}
							startIcon={
								<RemoveRedEyeIcon sx={{ fontSize: '24px !important' }} />
							}
							onClick={() => setOpenPreviewModal(true)}
						>
							Preview Carousel
						</Button>
					)}
				</Box>
				{openUploadModal && (
					<UploadImageModal
						index={index}
						onClose={() => setOpenUploadModal(false)}
						open={openUploadModal}
						images={images}
						setImages={setImages}
						showLightbox={false}
						s3Folder={`${activeProjectDetails.slug}/modules/draft/carousel`}
					/>
				)}
				<CarouselModal
					height={height}
					open={openPreviewModal}
					onClose={() => setOpenPreviewModal(false)}
					images={images}
				/>
			</Box>
		</Box>
	);
});

export default CarouselModule;
