import { FC } from 'react';
import { PreviewPage } from './PreviewPage';
import { Project } from 'types/types';

interface PreviewPageContainerProps {
	project: Project;
	pageId: number;
}

export const PreviewPageContainer: FC<PreviewPageContainerProps> = (props) => {
	const { project, pageId } = props;
	const page = project?.pages.find((page: any) => page.id === pageId);

	return (
		<PreviewPage
			projectName={project?.slug}
			pageName={page?.slug || ''}
			isCustomUrl={Boolean(project?.customUrlEnabledDate)}
			customUrl={project?.customUrl || ''}
		/>
	);
};
