import * as React from 'react';
import { ImageProperty } from '../ImageModule';
import { useMutation } from 'urql';
import { fileUploadApi } from '../../../api/apiClient';
import { getUploadUrlMutation } from '../../../api/mutation/s3.mutations';
import UploadImage from 'components/modules/components/UploadImage';
import { useSnackBar } from 'providers/SnackBarProvider';
import { useRouteContext } from '../../../routes/Layout';
import { useDeleteActionConfirmation } from 'hooks/useActionConfirmation';

export interface FormImageType {
	imageUrl: string;
	altTag?: string;
	fileName: string;
	imageScale: string;
	boundingBoxPosition: string;
}

export interface FormImageProps extends FormImageType {
	onImageChange: (val: FormImageType) => void;
	setConfirmDisabled?(n: boolean): void;
	handleImageDelete: () => void;
}

const FormImage: React.FC<FormImageProps> = ({
	imageUrl,
	altTag,
	imageScale,
	boundingBoxPosition,
	onImageChange,
	fileName,
	setConfirmDisabled,
	handleImageDelete,
}) => {
	const [progress, setProgress] = React.useState(0);
	const [, getUploadUrl] = useMutation(getUploadUrlMutation);
	const { notify } = useSnackBar();
	const { activeProjectDetails } = useRouteContext();
	const showDeleteConfirmation = useDeleteActionConfirmation();
	const imageContent = {
		imageUrl,
		altTag,
		fileName,
		imageScale,
		boundingBoxPosition,
	} as any;

	const handleChange = (
		property: ImageProperty,
		event?: React.ChangeEvent | Event,
		data?: any,
	) => {
		const value = data || (event?.target as HTMLInputElement).value;

		onImageChange({ ...imageContent, [property]: value });
	};

	const uploadFilesToAws = async (files: FileList | null) => {
		if (!files) return;

		const file = files[0];
		const result = await getUploadUrl({
			folder: `${activeProjectDetails.slug}/forms/draft`,
			filename: file.name,
		});
		if (result.error) return;

		const uploadURL = result.data.getUploadUrl;

		const res = await fileUploadApi(uploadURL, file, setProgress);
		if (!res) return notify('Failed to upload image!', 'error');
		const imageUrl = res.split('?')[0];
		onImageChange({ ...imageContent, imageUrl, fileName: file.name });
	};

	React.useEffect(() => {
		if (imageUrl && altTag && setConfirmDisabled) {
			setConfirmDisabled(false);
		}
		if ((!imageUrl || !altTag) && setConfirmDisabled) {
			setConfirmDisabled(true);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [altTag, imageUrl]);

	React.useEffect(() => {
		return () => setConfirmDisabled && setConfirmDisabled(false);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const onImageDelete = async () => {
		await showDeleteConfirmation(
			{
				title: 'Image Delete',
				message: `Are you sure you want to delete ${imageContent?.fileName || 'this'
					} image?`,
			},
			() => handleImageDelete(),
		);
	};

	return (
		<>
			<UploadImage
				handleUpload={uploadFilesToAws}
				progress={progress}
				handleChange={handleChange}
				imageContent={imageContent}
				setConfirmDisabled={setConfirmDisabled}
				showLightBox={false}
				handleDelete={onImageDelete}
			/>
		</>
	);
};

export default FormImage;
