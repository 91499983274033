import { gql } from 'urql';

export const templateInstancesByPageQuery = gql`
	query ($pageId: Int!) {
		templateInstancesByPage(pageId: $pageId) {
			id
			ordinal
			template {
				id
				name
				ordinal
				layout {
					gridTemplateColumns
					gridTemplateRows
					gridTemplateSections {
						gridColumn
						gridRow
						sectionId
					}
				}
			}
			moduleInstances {
				id
				ordinal
				config
				content
				anchorId
				moduleType {
					name
					functionalType
					defaultConfig
				}
			}
		}
	}
`;

export const publishedTemplateInstancesByPageQuery = gql`
	query ($pageId: Int!) {
		publishedTemplateInstancesByPage(pageId: $pageId) {
			ordinal
			moduleInstances {
				config
				content
				ordinal
				anchorId
				moduleType {
					name
					functionalType
					defaultConfig
				}
			}
			template {
				id
				name
				ordinal
			}
		}
	}
`;
