import { MouseEvent, useRef, useState } from 'react';
import { useMutation } from 'urql';
import { useSnackBar } from 'providers/SnackBarProvider';
import { useModuleSaveData } from 'hooks/useModuleSaveData';
import { ModuleInstanceComponentSaveData } from 'types/moduleInstanceData';
import { useDeleteActionConfirmation } from 'hooks/useActionConfirmation';
import { removeFileMutation } from 'api/mutation/s3.mutations';
import { getObjectKey } from 'utility/getObjectKey';
import contentLoader from 'components/features/moduleInstanceList/utils/contentLoader';
import { MODULE_TYPE } from 'types/moduleTypes';
import { Project } from 'types/types';
import { updateModuleInstanceMutation } from 'api/mutation/module.mutation';
import _ from 'lodash';

const useModuleInstance = (
	moduleInstance: any,
	handleUpdateModule: (
		id: number,
		newContent: any,
		anchorId?: string | undefined | null,
	) => void,
	handleDeleteModuleInstance: (id: number) => void,
	getToken: any,
	project: Project,
) => {
	const [open, setOpen] = useState(moduleInstance.isOpen ?? false);
	const [openEditAnchorModal, setOpenEditAnchorModal] = useState(false);
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const [cancelTrigger, setCancelTrigger] = useState(0);

	const { notify } = useSnackBar();
	const menuOpen = Boolean(anchorEl);

	const { persistSaveModuleData } = useModuleSaveData();
	const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const saveDataRef = useRef<ModuleInstanceComponentSaveData>({
		content: null,
		config: null,
	});

	const handleClickOpen = () => {
		setAnchorEl(null);
		setOpen(true);
	};

	const handleClose = () => {
		setCancelTrigger(cancelTrigger + 1);
		setOpen(false);
		setOpenEditAnchorModal(false);
	};

	const [, removeFileInstance] = useMutation(removeFileMutation);
	const [, updateModuleInstance] = useMutation(updateModuleInstanceMutation);

	const handleSaveModuleInstance = async () => {
		if (
			moduleInstance.moduleType.name === 'Form' &&
			!saveDataRef.current?.content?.name &&
			!moduleInstance?.content?.name
		)
			return notify('Form Name is required', 'error');

		if (
			moduleInstance.moduleType.name === 'Carousel' &&
			saveDataRef.current?.content.toDeleteImages
		) {
			for (const image of saveDataRef.current?.content.toDeleteImages) {
				const objectKey = getObjectKey(image);
				await removeFileInstance({ objectKey }); // TODO: check if image actually gets deleted
			}
			delete saveDataRef.current?.content.toDeleteImages;
		}
		let mutationReqResult = await persistSaveModuleData(
			moduleInstance.id,
			saveDataRef.current,
		);

		const updatedModule =
			mutationReqResult.operation.variables.existingModuleInstanceData;
		const newContent = await contentLoader(
			updatedModule.content,
			moduleInstance.moduleType.name as MODULE_TYPE,
			getToken,
			project,
		);

		// update state -> with new module content
		handleUpdateModule(updatedModule.id, newContent);

		if (mutationReqResult.error) {
			console.log('[ERROR] saving module: ', mutationReqResult.error);
		} else {
			setOpen(false);
		}
	};

	const showDeleteConfirmation = useDeleteActionConfirmation();
	const handleDeleteClick = async () => {
		setAnchorEl(null);
		await showDeleteConfirmation(
			{
				title: `Delete ${moduleInstance.moduleType.name} Module`,
				message: 'Are you sure you want to delete this module instance?',
			},
			async () => {
				await handleDeleteModuleInstance(moduleInstance.id);
			},
		);
	};

	const handleOpenAnchorModal = () => {
		setAnchorEl(null);
		setOpenEditAnchorModal(true);
	};

	const handleEditAnchor = async (anchorId: string | undefined | null) => {
		if (anchorId && anchorId.trim().includes(' '))
			return notify('Anchor ID cannot contain spaces', 'error');
		const anchorData =
			!anchorId || anchorId?.replace(/\s/g, '') === ''
				? null
				: anchorId!.replace(/\s/g, '');

		const res = await updateModuleInstance({
			existingModuleInstanceData: {
				id: moduleInstance.id,
				anchorId: anchorData,
			},
		});
		if (res.error)
			return notify(
				'Error updating anchor ID. Make sure this anchor ID does not already exist',
				'error',
			);

		// update state
		handleUpdateModule(moduleInstance.id, moduleInstance.content, anchorData);
		if (_.isEmpty(anchorData)) {
			notify('Anchor removed', 'success');
		} else {
			notify(`Anchor updated to ${anchorData}`, 'success');
		}
		setOpenEditAnchorModal(false);
	};

	return {
		open,
		menuOpen,
		setOpen,
		handleClick,
		anchorEl,
		setAnchorEl,
		handleClickOpen,
		handleDeleteClick,
		saveDataRef,
		handleClose,
		handleSaveModuleInstance,
		cancelTrigger,
		openEditAnchorModal,
		handleOpenAnchorModal,
		handleEditAnchor,
	};
};

export default useModuleInstance;
