import { FC, useEffect, useState } from 'react';
import { Box, InputLabel, Typography } from '@mui/material';
import { useMutation } from 'urql';
import ModuleInstanceData from '../../types/moduleInstanceData';
import { useModuleSaveData } from '../../hooks/useModuleSaveData';
import {
	getUploadUrlMutation,
	removeFileMutation,
	restoreFileMutation,
} from '../../api/mutation/s3.mutations';
import { fileUploadApi } from '../../api/apiClient';
import PositionSelector from 'components/common/PositionSelector';
import ImageDrop from '../common/ImageDrop';
import { Cell, Grid } from 'styled-css-grid';
import { DeleteOutline as DeleteIcon } from '@mui/icons-material';
import RegularTextInput from 'components/common/styled/RegularTextInput';
import InfoOnHover from 'components/common/InfoOnHover';
import { useDeleteActionConfirmation } from 'hooks/useActionConfirmation';
import OutlinedButton from 'components/common/styled/OutlinedButton';
import { useSnackBar } from '../../providers/SnackBarProvider';
import { useRouteContext } from '../../routes/Layout';
import { getObjectKey } from '../../utility/getObjectKey';
import { TextEditor } from 'components/common/TextEditor';
import { toPlainText } from 'components/common/TextEditor/helpers';
import { CommunityModuleInstance } from '@coUrbanize/community-modules';
import { MODULE_TYPE } from 'types/moduleTypes';

export interface HeroImageContent {
	imageUrl?: string;
	fileName: string;
	altTag: string;
	heroTextPosition: string | null;
	cancelTrigger?: number;
}

export type HeroImageProperty =
	| 'imageUrl'
	| 'altTag'
	| 'fileName'
	| 'heroTextPosition';

const HeroImageModule: FC<ModuleInstanceData> = (props) => {
	const { content, saveDataRef, config, setConfirmDisabled, cancelTrigger } =
		props;
	const { updateModuleSaveData } = useModuleSaveData(saveDataRef);
	const [heroImageContent, setHeroImageContent] =
		useState<HeroImageContent>(content);
	const [progress, setProgress] = useState(0);
	const [errors, setErrors] = useState<{
		imageDescription?: string;
	}>({});
	const [editorState, setEditorState] = useState(
		content?.overlayText?.richText,
	);
	const [imageDeleted, setImageDeleted] = useState(false);

	const [, getUploadUrl] = useMutation(getUploadUrlMutation);
	const [, removeImage] = useMutation(removeFileMutation);
	const [, restoreImage] = useMutation(restoreFileMutation);

	const { notify } = useSnackBar();
	const { activeProjectDetails } = useRouteContext();

	const showDeleteConfirmation = useDeleteActionConfirmation();

	const handleChange = (
		value: string | number,
		property: HeroImageProperty,
	) => {
		if (property === 'altTag') {
			if (!value) {
				setErrors((prevErrors) => ({
					...prevErrors,
					imageDescription: 'Image Description is required',
				}));
			} else {
				setErrors((prevErrors) => ({ ...prevErrors, imageDescription: '' }));
			}
		}

		setHeroImageContent({ ...heroImageContent, [property]: value });
	};

	const handleUpload = async (files: FileList | null) => {
		if (!files) return;

		const file = files[0];
		const result = await getUploadUrl({
			folder: `${activeProjectDetails.slug}/modules/draft/hero`,
			filename: file.name,
		});

		if (result.error) return;

		const uploadURL = result.data.getUploadUrl;

		const res = await fileUploadApi(uploadURL, file, setProgress);
		if (!res) return notify('Failed to upload image!', 'error');
		const imageUrl = res.split('?')[0];

		setHeroImageContent({ ...heroImageContent, imageUrl, fileName: file.name });
	};

	const handleDelete = async () => {
		try {
			const objectKey = getObjectKey(heroImageContent!.imageUrl!);
			const result = await removeImage({ objectKey });

			if (result?.data?.removeAwsFile) {
				setImageDeleted(true);

				setHeroImageContent((prevHeroImageContent: HeroImageContent) => {
					const { imageUrl, ...values } = prevHeroImageContent;

					return values;
				});
			} else setImageDeleted(false);
		} catch (error) {
			console.error('Remove image failed: ', error);
		}
	};

	const onDelete = async () => {
		await showDeleteConfirmation(
			{
				title: 'Hero Image Delete',
				message: `Are you sure you want to delete ${
					heroImageContent?.fileName || 'this'
				} image?`,
			},
			handleDelete,
		);
	};

	const restoreImageFromAws = async () => {
		try {
			const objectKey = getObjectKey(content?.imageUrl);
			await restoreImage({ objectKey });
		} catch (error) {
			console.error('Restore image failed: ', error);
		}
	};

	useEffect(
		() => {
			if (imageDeleted) restoreImageFromAws();
			// delete uploaded image from aws if user cancels
			if (content?.imageUrl !== heroImageContent?.imageUrl) handleDelete();
		}, // eslint-disable-next-line react-hooks/exhaustive-deps
		[cancelTrigger],
	);

	useEffect(() => {
		const newData = {
			content: {
				...heroImageContent,
				overlayText: {
					richText: editorState,
					plainText: toPlainText(editorState),
				},
			},
			config,
		};

		updateModuleSaveData(newData);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [updateModuleSaveData, config, heroImageContent]);

	useEffect(() => {
		if (
			heroImageContent?.imageUrl &&
			heroImageContent?.altTag &&
			setConfirmDisabled
		) {
			setConfirmDisabled(false);
		}
		if (
			(!heroImageContent?.imageUrl || !heroImageContent?.altTag) &&
			setConfirmDisabled
		) {
			setConfirmDisabled(true);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [heroImageContent?.altTag, heroImageContent?.imageUrl]);

	useEffect(() => {
		return () => setConfirmDisabled && setConfirmDisabled(false);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<Box display="flex" flexDirection="column" py={4} px={3}>
			{!heroImageContent?.imageUrl ? (
				<ImageDrop progress={progress} handleUpload={handleUpload} />
			) : (
				<Box>
					<Grid columns={'1fr 40px'}>
						<Cell>
							<img
								alt={heroImageContent?.altTag}
								src={heroImageContent?.imageUrl || heroImageContent?.imageUrl}
								style={{ maxHeight: '300px' }}
							/>
						</Cell>
					</Grid>
					<Box display="flex" justifyContent="flex-end" pt={2}>
						<OutlinedButton startIcon={<DeleteIcon />} onClick={onDelete}>
							<Typography fontWeight={500} textTransform="capitalize">
								Remove image
							</Typography>
						</OutlinedButton>
					</Box>
				</Box>
			)}

			<Box mt="10px" mb="10px">
				<Grid columns={'1fr 1fr'}>
					<Cell>
						<Box
							display="inline-flex"
							gap="5px"
							alignItems="center"
							marginBottom="8px"
						>
							<InputLabel id="image-dsc-tag-label">
								Image Description
							</InputLabel>
							<InfoOnHover infoCase="imageDescription" />
						</Box>

						<RegularTextInput
							size="small"
							type="text"
							sx={{ width: '90%', marginBottom: 2 }}
							value={heroImageContent?.altTag}
							error={!!errors.imageDescription}
							helperText={errors.imageDescription}
							onChange={(e) => handleChange(e.target.value, 'altTag')}
						/>
					</Cell>
					<Cell>
						<PositionSelector
							onSelect={(value) =>
								setHeroImageContent({
									...heroImageContent,
									heroTextPosition: value,
								})
							}
							selected={heroImageContent?.heroTextPosition || 'middle-center'}
							label="Hero Text"
						/>
					</Cell>
				</Grid>
			</Box>
			<Grid style={{ marginTop: '16px' }} columns={'1fr 1fr'}>
				<Cell>
					<label>Overlay Text</label>
					<TextEditor
						editorState={editorState}
						setEditorState={setEditorState}
						selectedFields={[
							'link',
							'heading-one',
							'heading-two',
							'heading-three',
							'heading-four',
							'heading-five',
							'heading-six',
							'numbered-list',
							'bulleted-list',
							'left',
							'center',
							'right',
							'justify',
						]}
					/>
				</Cell>
				<Cell>
					<Box pl={3}>
						<label>Preview</label>
						<CommunityModuleInstance
							config={{}}
							content={{
								...heroImageContent,
								overlayText: {
									richText: editorState,
									plainText: toPlainText(editorState),
								},
							}}
							type={MODULE_TYPE.HERO_IMAGE as any}
							user={null}
							useMutation={() => [null, () => ({ error: null })]}
							isAdminRendering={true}
						/>
					</Box>
				</Cell>
			</Grid>
		</Box>
	);
};

export default HeroImageModule;
