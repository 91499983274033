import { create } from 'apisauce';
import { fileContentTypes } from '../types/fileContentTypes';
import * as process from 'process';

export const fileUploadApi: (
	uploadURL: string,
	file: File,
	setProgress?: (val: number) => void,
) => Promise<string> = async (
	uploadURL: string,
	file: File,
	setProgress?: (val: number) => void,
) => {
	const maxAgeTimeout = Number(
		process.env.REACT_APP_IMAGE_CACHE_CONTROL_MAX_AGE,
	);
	const fileName = file.name.replace(/\u202F/g, ' ');
	const api = create({
		baseURL: uploadURL,
		headers: {
			'Content-Type': 'multipart/form-data',
			'Content-Disposition': `filename="${fileName}"`,
			'Cache-Control': `max-age=${maxAgeTimeout}, immutable`, // 1 year
		},
	});

	const regExp = /(?:\.([^.]+))?$/;
	const extension = regExp.exec(file.name);

	if (!extension || extension.length === 0) return '';

	const mimeType = fileContentTypes.find((t) => {
		return t.extensions.includes(extension[1].toLowerCase());
	});

	const res = await api.put('', file, {
		headers: {
			'Content-Type': mimeType!.contentType,
		},
		onUploadProgress: (p) =>
			setProgress && setProgress((p.loaded / p.total) * 100),
	});

	// if upload failed
	if (!res.ok) {
		setProgress && setProgress(0);
		console.log(res.originalError);
		return '';
	}
	// once upload is done
	if (res.ok) {
		setProgress && setProgress(0);
	}
	return uploadURL.replace(
		process.env.REACT_APP_AWS_S3_DOMAIN as string,
		process.env.REACT_APP_AWS_S3_PUBLIC as string,
	);
};
