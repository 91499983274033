import { FC, useEffect, useState } from 'react';
import { useMutation, useQuery } from 'urql';
import {
	Box,
	Button,
	Typography,
	MenuItem,
	FormControl,
	InputLabel,
} from '@mui/material';
import PostAddIcon from '@mui/icons-material/PostAdd';

import {
	createFormMutation,
	updateFormMutation,
} from 'api/mutation/form.mutation';
import { useModuleSaveData } from '../../../hooks/useModuleSaveData';
import useNumberParams from '../../../hooks/useNumberParams';
import ConfirmationDialog, {
	ConfirmationDialogRenderProps,
} from '../../common/ConfirmationDialog';
import { formsByProject } from '../../../api/query/form.queries';
import { GenericModuleProps } from '../GenericModule';
import { FormModule } from './FormModule';
import SelectInput from 'components/common/styled/SelectInput';

const FormModuleWrapper: FC<GenericModuleProps> = (props) => {
	const { content, saveDataRef, config, id, handleClose, handleUpdateModule } =
		props;
	const { projectId } = useNumberParams();
	const { updateModuleSaveData, persistSaveModuleData } =
		useModuleSaveData(saveDataRef);
	const [formOpen, setFormOpen] = useState(false);
	const [formTitle, setFormTitle] = useState('');
	const [forms, setForms] = useState<any[]>([]);
	const [currentForm, setCurrentForm] = useState<any>(null);
	const [, createForm] = useMutation(createFormMutation);
	const [, updateForm] = useMutation(updateFormMutation);

	const [{ data }, reexecuteQuery] = useQuery({
		query: formsByProject,
		variables: { projectId },
	});

	useEffect(() => {
		const newData = {
			config,
		};
		let mounted = false;
		if (!mounted) {
			updateModuleSaveData({ ...newData, content });
			mounted = true;
		}
		if (currentForm && mounted) {
			updateModuleSaveData({
				...newData,
				content: currentForm?.module?.content,
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentForm]);

	useEffect(() => {
		const forms = data?.formsByProject;
		if (forms) {
			setForms(forms);
			if (content) {
				const current = forms?.find(
					(el: any) => el.module?.content?.name === content?.name,
				);
				if (current) setCurrentForm(current);
			}
		}
		if (content) {
			setFormOpen(true);
		}
	}, [data?.formsByProject, content]);

	const onCreateForm = () => {
		updateModuleSaveData({ content: null, config });
	};

	const handleFormCreation = async () => {
		const { name, projectId, formProcessorType, fields } =
			saveDataRef?.current.content;
		const res = await createForm({
			newForm: {
				projectId,
				moduleInstanceId: id,
				formName: name,
				formProcessorTypeId: formProcessorType,
				mostRecentFormJson: fields,
			},
		});
		if (!res.data) throw new Error();
		return res.data.createForm.id;
	};

	const handleFormUpdate = async () => {
		const { name, formProcessorType, fields } = saveDataRef?.current?.content;

		const res = await updateForm({
			existingForm: {
				id: currentForm.id,
				formName: name,
				formProcessorTypeId: formProcessorType,
				mostRecentFormJson: fields,
			},
		});
		if (res.data) reexecuteQuery({ requestPolicy: 'network-only' });
	};

	const handleSaveModuleInstance = async () => {
		let formId = null;
		if (!saveDataRef?.current?.content?.name && !content?.name) return '';

		// const { name, formProcessorType } = saveDataRef?.current?.content;

		// create form when module doesn't exist.
		if (!content) formId = await handleFormCreation();
		// update form name & Content in Forms table
		else {
			await handleFormUpdate();
		}
		if (formId && saveDataRef) saveDataRef.current.content.formId = formId;
		let mutationReqResult = await persistSaveModuleData(
			id!,
			saveDataRef?.current!,
		);

		const updatedModule =
			mutationReqResult.operation.variables.existingModuleInstanceData;

		// update state -> with new module content
		handleUpdateModule!(updatedModule.id, updatedModule.content);

		if (mutationReqResult.error) {
			console.log('[ERROR] saving module: ', mutationReqResult.error);
		} else {
			handleClose?.();
			setTimeout(() => {
				setFormOpen(false);
			}, 2);
		}
	};

	return (
		<Box minWidth="350px">
			<Typography fontSize={16}>{currentForm?.formName}</Typography>
			{!content && (
				<>
					<Button
						startIcon={<PostAddIcon />}
						fullWidth
						sx={{
							color: '#0076A0',
							my: 3,
							textTransform: 'none',
							fontSize: 16,
						}}
						onClick={() => {
							setFormOpen(true);
							if (!content) onCreateForm();
						}}
					>
						Create new form
					</Button>
					<Typography color="#5C5C5C" sx={{ textAlign: 'center' }}>
						Or Select Existing Form
					</Typography>
					{forms?.length > 0 ? (
						<FormControl fullWidth>
							<InputLabel sx={{ marginTop: '10px' }} id="form-name-label">
								Form Name
							</InputLabel>
							<SelectInput
								value={currentForm}
								onChange={(e) => {
									setCurrentForm(e.target.value);
									setFormOpen(true);
								}}
								size="small"
								sx={{ mt: 2 }}
							>
								{forms?.map((form: any) => (
									<MenuItem key={form.id} value={form}>
										{form?.formName}
									</MenuItem>
								))}
							</SelectInput>
						</FormControl>
					) : (
						<Typography fontSize={16}>
							No forms Available. Please create new form.
						</Typography>
					)}
				</>
			)}

			<ConfirmationDialog
				open={formOpen}
				title={
					!formTitle ? (content ? 'Edit Form' : 'Create New Form') : formTitle
				}
				cancelLabel="Cancel"
				confirmLabel="Save"
				onCancel={() => {
					if (content) {
						handleClose?.();
					}
					setFormOpen(false);
					setCurrentForm(null);
				}}
				onConfirm={() => {
					handleSaveModuleInstance();
				}}
				style={{ '& .MuiDialogContent-root': { padding: 0 } }}
			>
				{(renderProps: ConfirmationDialogRenderProps) => {
					return (
						<FormModule
							setShowCancel={(shouldShow: boolean) =>
								renderProps.setShowCancel(shouldShow)
							}
							setShowConfirm={(shouldShow: boolean) =>
								renderProps.setShowConfirm(shouldShow)
							}
							setDisabled={(confirmDisabled: boolean) =>
								renderProps.setDisabled(confirmDisabled)
							}
							setFormTitle={setFormTitle}
							mostRecentFormFields={currentForm?.mostRecentFormJson}
							{...props}
						/>
					);
				}}
			</ConfirmationDialog>
		</Box>
	);
};

export default FormModuleWrapper;
